import React from 'react';
import { book_3d } from '../../images';

const Book6Types = () => {
	return (
		<div className='book-6-types' id='buy-book-6-types-id'>
			<div className='container'>
				<div className='book-6-types-content'>
					<div className='book-6-types-text flex-1'>
						<h2 className='book-h3-bold italic'>The 6 Types of Working Genius</h2>
						<h3 className='book-h4-normal'>By Patrick Lencioni</h3>
						<h4 className='book-h4'>NEW!</h4>
						<p className='book-p'>
							Pat's new book, The Six Types of Working Genius, is on track to be his biggest and most
							impactful book yet. In classic Lencioni fashion, Pat brings his model to life in a
							page-turning fable that is as relatable as it is compelling. He tells the story of Bull
							Brooks, an entrepreneur, husband, and father who sets out to solve his own frustration at
							work and stumbles into a new way of thinking that revolutionizes the way he sees his work,
							his team, and even his marriage. Bull's story is indicative of every man and woman who is
							striving to avoid burnout and find fulfillment, dignity and success in their work.
						</p>
						<p className='book-p'>
							Beyond the personal discovery and instant relief that Working Genius provides, the model
							also gives teams a remarkably simple and practical framework for tapping into one another's
							natural gifts, which increases productivity and reduces unnecessary judgment.
						</p>
						<p className='book-p'>
							Whether you're a team leader or entrepreneur, a front-line employee or executive, or a
							school teacher or stay-at-home parent, this book will help you find more joy, fulfillment
							and success in your work and your life.
						</p>
					</div>
					<div className='book-6-types-img flex-1'>
						<img src={book_3d} alt='The 6 Types of the Working Genius' width='350' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Book6Types;
