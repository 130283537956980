import React from 'react';
import ColorGearImage from '../../ColorGearImage';

const genius_color = '#00a049';
const competency_color = '#fbc331';
const frustration_color = '#c06e60';

const FinalDetermination = () => {
	return (
		<div className='Page__content'>
			<h1>Your Final Determination</h1>

			<p>
				Using both the results of your quantitative assessment and your qualitative review,
				<br />
				assign each of the six types to one of the three genius categories.
			</p>

			<div className='determination genius'>
				<div className='gear'>
					<ColorGearImage color={genius_color} />
					<ColorGearImage color={genius_color} />
				</div>

				<div className='text'>
					<h2>Working Genius</h2>
					<p>(my most natural, energizing and joyful areas of genius; a perfect role for me)</p>
					<div className='line' />
					<div className='line' />
				</div>
			</div>

			<div className='determination competency'>
				<div className='gear'>
					<ColorGearImage color={competency_color} />
					<ColorGearImage color={competency_color} />
				</div>

				<div className='text'>
					<h2>Working Competency</h2>
					<p>(my areas of reasonable capability and/or moderate satisfaction; a pretty good role for me)</p>
					<div className='line' />
					<div className='line' />
				</div>
			</div>

			<div className='determination frustration'>
				<div className='gear'>
					<ColorGearImage color={frustration_color} />
					<ColorGearImage color={frustration_color} />
				</div>

				<div className='text'>
					<h2>Working Frustration</h2>
					<p>(my areas of drudgery and/or low competence; a very poor role for me)</p>
					<div className='line' />
					<div className='line' />
				</div>
			</div>

			<div className='apply-insights'>
				<h2>
					Now take some time to learn how to apply these insights in your life,
					<br />
					as a team and as an individual.
				</h2>
			</div>
		</div>
	);
};

export default FinalDetermination;
