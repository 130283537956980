import Axios from 'axios';
import config from '../constants/config';
import ApiService from './ApiService';
import UtilityService from './UtilityService';

const PodcastApiService = Axios.create({
	baseURL: `${config.ttg_url}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const getPodcasts = () =>
	PodcastApiService.get('wp-json/wl/v1/get_working_genius_podcast')
		.then(({ data }) => {
			return data;
		})
		.catch((error) => {
			return error;
		});

export const getPodcastPdf = (captcha, slug, name) =>
	ApiService.post('/v1/podcast/generatePdf', { captcha, slug })
		.then(({ data }) => {
			const buffer =
				data.base64 && data.base64.data ? data.base64.data : UtilityService.convertBase64ToBuffer(data.base64);
			UtilityService.downloadPdfFromBuffer(buffer, `${name}.pdf`);
		})
		.catch((error) => console.error(error));

export default {
	getPodcasts,
	getPodcastPdf
};
