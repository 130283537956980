import React from 'react';

import AppButton from '../../components/AppButton';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import EventLiveHero from '../../components/event8november/EventLiveHero';
import EventPat from '../../components/event8november/EventPat';
import Event6Types from '../../components/event/Event6Types';
import ApiService from '../../services/ApiService';
import UtilityService from '../../services/UtilityService';

const Event = () => {
    const [streamHTML, setStreamHTML] = React.useState('');
    const [resourceEnableDate, setResourceEnableDate] = React.useState(null);
    const [resourceDisasbleDate, setResourceDisasbleDate] = React.useState(null);
    const [resourceUrl, setResourceUrl] = React.useState(null);

    const getStreamLinks = React.useCallback(() => {
        ApiService.get('/v1/live/atthewhiteboardmeetingsevent')
            .then(({ data }) => {
                setStreamHTML(data.url.live);
                setResourceEnableDate(data.url.resource_enable_date);
                setResourceDisasbleDate(data.url.resource_disable_date);
                setResourceUrl(data.url.resource_url);
            })
            .catch(() => setStreamHTML(''));
    }, []);

    React.useEffect(() => {
        getStreamLinks();
    }, []);

    const isEventTimeframe = !(
        resourceEnableDate &&
        resourceDisasbleDate &&
        UtilityService.isUtcDateBetween(resourceEnableDate, resourceDisasbleDate)
    );

    return (
        <WorkshopLayout title={'The 6 Types of Working Genius: At the Whiteboard Meetings Event'}>
            <EventLiveHero streamHTML={streamHTML} />
            <div className='bg-secondary p-6'>
                <div className='flex flex-col lg:flex-row items-center justify-center text-center mx-auto'>
                    <p className='text-white text-base md:text-2xl font-medium'>
                        Download a helpful resource about Meetings.
                    </p>
                    <div className='flex flex-col items-center justify-center'>
                        <AppButton
                            id={'wg-at-the-table-live-event-btn'}
                            className={'btn w-56 mx-6 my-2'}
                            text={isEventTimeframe ? 'Free resource' : 'Download Now'}
                            disabled={isEventTimeframe}
                            onClick={() => {
                                if (resourceUrl) {
                                    window.open(
                                        resourceUrl,
                                        '_blank'
                                    );
                                }
                            }}
                        />
                        {isEventTimeframe && (
                            <span className='text-12 text-white text-center font-semibold uppercase tracking-widest'>
                                Available November 8th
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <EventPat />
            <Event6Types />
        </WorkshopLayout>
    );
};

export default Event;
