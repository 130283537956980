import React from 'react';

import Event6Types from '../../components/event/Event6Types';
import EventHero from '../../components/certwebinar/EventHero';
import EventPat from '../../components/event/EventPat';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import ApiService from '../../services/ApiService';

const Event = () => {
    const [streamHTML, setStreamHTML] = React.useState(' ');

    const getStreamLinks = React.useCallback(() => {
        ApiService.get('/v1/live/certwebinar').then(({ data }) => {
            setStreamHTML(data?.url?.live);
        });
    }, [setStreamHTML]);

    React.useEffect(() => {
        getStreamLinks();
    }, []);

    return (
        <WorkshopLayout
            title='The 6 Types of Working Genius: Exclusive Webinar'
            workshopItem={false}
            footerWorkshopItem={true}
        >
            <EventHero streamHTML={streamHTML} />
            <EventPat />
            <Event6Types />
        </WorkshopLayout>
    );
};

export default Event;
