import React from 'react';
import { useForm } from 'react-hook-form';
import AppModal from '../AppModal';
import { widget_launch_background } from '../../images';
import AppInput from '../AppInput';
import { emailRegex, phoneNumberRegex } from '../../services/UtilityService';
import WebinarService from '../../services/WebinarService';
import NotificationService from '../../services/NotificationService';
import AppButton from '../AppButton';
import AppRIIcon from '../AppRIIcon';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const RegisterForm = ({ isOpen, onClose }) => {
	const [loading, setLoading] = React.useState(false);
	const [registered, setRegistered] = React.useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	React.useEffect(() => {
		if (!isOpen) {
			reset();
			setLoading(false);
			setTimeout(() => {
				setRegistered(false);
			}, 500);
		}
	}, [isOpen]);

	const _save = (payload) => {
		setLoading(true);
		WebinarService.register(payload).then(
			() => {
				setRegistered(true);
				setLoading(false);
			},
			(error) => {
				NotificationService.error(error.message);
				setLoading(false);
			}
		);
	};

	const renderContent = () => {
		if (registered) {
			return (
				<div className='flex flex-col text-center md:mt-16 p-4'>
					<h1 className={'text-xl md:text-3xl text-primary font-normal'}>
						Thank you for registering. <br className='hidden lg:block' />
						Looking forward to seeing
						<br className='hidden lg:block' />
						you on January 25th, 2023!
					</h1>
					<p className={'text-lg text-primary font-semibold my-4 md:my-10'}>Check your email for details.</p>
				</div>
			);
		}
		return (
			<div>
				<h1 className={'text-2xl md:text-4xl text-primary font-normal'}>Register Now</h1>
				<div className='flex flex-1'>
					<p className={'text-lg text-primary italic my-4'}>You can register for yourself or your guest</p>
				</div>
				<form className={'flex flex-1 flex-col mt-4 workshop-form'} onSubmit={handleSubmit(_save)}>
					<div className='flex flex-col md:flex-row md:gap-x-2'>
						<div className='flex flex-1'>
							<AppInput
								{...register('first_name', {
									required: true,
								})}
								errors={errors}
								placeholder={'First Name'}
								containerClassName={'mb-2 w-full'}
							/>
						</div>
						<div className='flex flex-1'>
							<AppInput
								{...register('last_name', {
									required: true,
								})}
								errors={errors}
								placeholder={'Last Name'}
								containerClassName={'mb-2 w-full'}
							/>
						</div>
					</div>
					<div className='flex flex-1'>
						<AppInput
							{...register('email', {
								required: true,
								pattern: {
									value: emailRegex,
									message: 'should be a valid email',
								},
								maxLength: 96,
							})}
							className={''}
							errors={errors}
							placeholder={'Email'}
							containerClassName={'mb-2 w-full'}
						/>
					</div>
					<div className='flex flex-1'>
						<AppInput
							{...register('access_code', {
								required: true,
								pattern: {
									value: emailRegex,
									message: 'should be a valid email',
								},
								maxLength: 96,
							})}
							className={''}
							errors={errors}
							placeholder={'Invitation Access Code'}
							containerClassName={'mb-2 w-full'}
						/>
					</div>
					<div className='flex flex-1'>
						<p className={'text-lg text-primary italic my-4'}>
							You will receive an email confirmation once you click submit.
						</p>
					</div>
					<div className={'flex justify-end'}>
						<AppButton text={'Submit'} loading={loading} />
					</div>
				</form>
			</div>
		);
	};

	return (
		<AppModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
			<div
				className={'flex items-center justify-center relative p-12'}
				style={{
					maxWidth: '1000px',
					backgroundImage: `url(${widget_launch_background})`,
					border: '1px solid #263860',
				}}
			>
				<button onClick={onClose} className={'absolute right-0 top-0 mr-1 mt-1'}>
					<AppRIIcon icon='close-fill' className='text-2xl text-primary' />
				</button>
				<div className='flex items-center justify-center flex-col p-4 bg-white md:mx-16 shadow-xl'>
					{renderContent()}
				</div>
			</div>
		</AppModal>
	);
};

export default RegisterForm;
