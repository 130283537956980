import React, { useEffect, useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ResourceSlide from './ResourceSlide';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import AppIcon from '../../AppIcon';
import { getCurrentBreakpoint } from '../../../services/UtilityService';

const screenSlidesPerView = {
	sm: 1,
	md: 2,
	lg: 3,
	xl: 3,
};

const ResourceSlider = ({ items, onClick }) => {
	const [slidesPerView, setSlidesPerView] = useState(screenSlidesPerView[getCurrentBreakpoint()]);
	const navigationPrevRef = useRef(null);
	const paginationRef = useRef(null);
	const navigationNextRef = useRef(null);

	useEffect(() => {
		function handleResize() {
			setSlidesPerView(screenSlidesPerView[getCurrentBreakpoint()]);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	if (!items || items.length === 0) {
		return null;
	}

	//Workaround for properly formatting slides
	while (items.length < slidesPerView) {
		items.push({ empty: true });
	}

	const hasNavigation = items.length > slidesPerView;
	const navigationClass = hasNavigation
		? 'flex w-8 h-8 bg-white rounded-full justify-center text-center cursor-pointer'
		: '';

	return (
		<Swiper
			observer
			modules={[Pagination, Navigation]}
			spaceBetween={50}
			slidesPerView={slidesPerView}
			pagination={{
				el: paginationRef.current,
				clickable: true,
			}}
			onBeforeInit={(swiper) => {
				swiper.params.navigation.prevEl = navigationPrevRef.current;
				swiper.params.navigation.nextEl = navigationNextRef.current;
				swiper.params.pagination.el = paginationRef.current;
			}}
			navigation={{
				prevEl: navigationPrevRef.current,
				nextEl: navigationNextRef.current,
			}}
		>
			{items.map((item, key) => {
				if (item.empty) {
					return <SwiperSlide key={key} />;
				}
				return (
					<SwiperSlide key={key}>
						<ResourceSlide item={item} onClick={() => onClick(item)} />
					</SwiperSlide>
				);
			})}
			<div className='flex flex-1 flex-row justify-center mt-6'>
				<div className={navigationClass} ref={navigationPrevRef}>
					{hasNavigation && <AppIcon icon={'left-dir'} className={'block text-2xl mr-1 mt-1'} />}
				</div>
				<div style={{ width: 'auto', marginTop: '3px' }} ref={paginationRef} />
				<div className={navigationClass} ref={navigationNextRef}>
					{hasNavigation && <AppIcon icon={'right-dir'} className={'block text-2xl ml-1 mt-1'} />}
				</div>
			</div>
		</Swiper>
	);
};

export default ResourceSlider;
