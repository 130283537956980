import React from 'react';
import AppButton from '../../AppButton';
import { dca_certification_logo } from '../../../images';

const DcaCard = ({ key, language, certificationLink }) => {
  const languageMappings = {
    'en': 'English',
    'es': 'Spanish',
    'de': 'German',
  };

  return (
    <div
      className={'flex flex-col bg-white shadow-md p-4 h-100'}
      key={key}
    >
      <div className={'flex flex-1 flex-col items-center justify-center text-secondary text-center'}>
        <img className={'mb-5 mx-auto'} src={dca_certification_logo} alt='DCA Certification Logo' width='280' />
        <p className={'text-lg xl:text-xl leading-7 font-normal'}>We've partnered with Dale Carnegie to offer Working Genius Certification in </p>
        <p className={'text-xl xl:text-2xl leading-7 font-medium mt-2 uppercase'}>{languageMappings[language] || ''}</p>
      </div>
      <div className={'mt-5 w-4/5 xl:w-1/2 mx-auto'}>
        <AppButton
          className={'btn btn-secondary w-full'}
          text={'Register now'}
          onClick={() => window.open(certificationLink, '_blank')}
        />
      </div>
    </div>
  )
}

export default DcaCard;