import React from 'react';
import PageHeader from './PageHeader';
import ReportUniquePairingsDE from '../../../../constants/ReportUniquePairingsDE';
import WidgetSVG from '../../../../images/report-widget-de.inline.svg';
import PageTitle from './PageTitle';

const Geniuses = ({ assessment, type, title }) => {
    return (
        <div className='SpecificResults'>
            <PageHeader title={'ERGEBNISSE'} />

            <div className='container'>
                <PageTitle title={title} className={'mt-8'} />
                <p
                    className={'block text-12 mt-4 pb-0'}
                    dangerouslySetInnerHTML={{
                        __html: ReportUniquePairingsDE.Geniuses[type].description(
                            ReportUniquePairingsDE.getAbility(assessment.customResults[`${type}1`]),
                            ReportUniquePairingsDE.getAbility(assessment.customResults[`${type}2`]),
                            ReportUniquePairingsDE[`${type}_color`]
                        ),
                    }}
                ></p>
                <div
                    className={`widget ${type} ${assessment.customResults[`${type}1`]} ${assessment.customResults[`${type}2`]
                        }`}
                    style={{ padding: '0', margin: '0.75rem' }}
                >
                    <div className='mx-auto py-4 my-2' style={{ width: '70%' }}>
                        <WidgetSVG />
                    </div>
                </div>
                <div className='flex flex-row gap-4'>
                    <div className={'flex flex-1 flex-col'}>
                        <p
                            className='text-14 uppercase font-bold'
                            style={{ color: ReportUniquePairingsDE[`${type}_color`] }}
                        >
                            {ReportUniquePairingsDE.getAbility(assessment.customResults[`${type}1`])}
                        </p>
                        <p
                            className={'text-12 leading-normal font-medium'}
                            dangerouslySetInnerHTML={{
                                __html: ReportUniquePairingsDE.Geniuses[assessment.customResults[`${type}1`]],
                            }}
                        />
                    </div>
                    <div className={'flex flex-1 flex-col'}>
                        <p
                            className='text-14 uppercase font-bold'
                            style={{ color: ReportUniquePairingsDE[`${type}_color`] }}
                        >
                            {ReportUniquePairingsDE.getAbility(assessment.customResults[`${type}2`])}
                        </p>
                        <p
                            className={'text-12 leading-normal font-medium'}
                            dangerouslySetInnerHTML={{
                                __html: ReportUniquePairingsDE.Geniuses[assessment.customResults[`${type}2`]],
                            }}
                        />
                    </div>
                </div>
                {!!ReportUniquePairingsDE.Geniuses[type].note && (
                    <div className=''>
                        <p className='text-14 uppercase font-bold'>HINWEIS</p>
                        <p
                            className={'text-12 font-medium'}
                            dangerouslySetInnerHTML={{ __html: ReportUniquePairingsDE.Geniuses[type].note }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Geniuses;