import React, { useState } from 'react';
import AppButton from '../../AppButton';
import AssessmentsService from '../../../services/AssessmentsService';
import { useTranslation } from 'react-i18next';
import AssessmentErrorModal from '../AssessmentErrorModal';

const TieQuestions = ({
	ties,
	question,
	nextStep,
	previousStep,
	onAnswer,
	questionNumber,
	hasBackButton,
	isLastStep,
	valueOffset = 0,
	assessmentId,
	rosterId,
	setAssessmentResponse,
	studentMode,
}) => {
	const [submitted, setSubmitted] = React.useState(false);
	const [tiesAnswers, setTiesAnswers] = React.useState(question.score ? question.score : []);
	const [loading, setLoading] = React.useState(false);
	const [errorModal, setErrorModal] = useState('');
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

	const { t } = useTranslation();

	const onNext = () => {
		if (!question.score) {
			setSubmitted(true);
			const violation = document.getElementById(`question-${questionNumber}`);
			window.scrollTo({
				top: violation.offsetTop,
				behavior: 'smooth',
			});
		} else {
			if (isLastStep) {
				let scores = [];
				ties.map((tie) => {
					scores = [...scores, ...tie.options];
				});
				//Reversing score
				scores = scores.map((s) => ({
					...s,
					score: scores.length - s.score + 1,
				}));
				setLoading(true);
				AssessmentsService.breakTies(assessmentId, rosterId, scores)
					.then((response) => {
						setAssessmentResponse(response, nextStep);
					})
					.catch((e) => {
						setLoading(false);
						setErrorModal(e.code ? e.code : e.message.code);
						setIsErrorModalOpen(true);
					});
			} else {
				nextStep();
			}
		}
	};

	const onSelectTieAnswer = (answerIndex, value) => {
		const t = [...tiesAnswers];
		const currentIndex = t.indexOf(value);
		if (currentIndex > -1) {
			t[currentIndex] = 0;
		}
		t[answerIndex] = value;
		setTiesAnswers([...t]);

		const answered = t.filter((a) => !!a);

		onAnswer(
			question.options.map((q, i) => ({
				...q,
				score: t[i],
			})),
			answered.length === question.options.length ? t : null
		);
	};

	const onRadioTieAnswer = (selectedOption) => {
		onAnswer(
			question.options.map((option) => {
				return {
					label: option.label,
					score: selectedOption.label !== option.label ? 2 : 1,
				};
			}),
			1
		);
	};

	const renderQuestion = () => {
		return (
			<h1 className={`question-title ${submitted && !question.score ? 'error' : ''}`}>
				{questionNumber}.{' '}
				<span dangerouslySetInnerHTML={{ __html: studentMode ? question.verbiage : t(`assessment.take.ties.${question.verbiage}`) }} />
			</h1>
		);
	};

	const renderRadioContent = () => {
		return (
			<div className={'wg-question'}>
				<div>
					{renderQuestion()}
					{question.options.map((option, optionKey) => {
						return (
							<div
								className={'tie-radio-option'}
								key={optionKey}
								onClick={() => onRadioTieAnswer(option)}
							>
								<input
									type='radio'
									value={option.score}
									name={'tie_question'}
									checked={option.score === question.score}
									onChange={() => onRadioTieAnswer(option)}
								/>
								<p className={'ml-2 md:text-lg text-primary leading-none py-1'}>
									{studentMode ? option.label : t(`assessment.take.ties.${option.label}`)}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const renderSelectContent = () => {
		return (
			<div className={'wg-question'}>
				{renderQuestion()}
				<div>
					{question.options.map((option, optionKey) => {
						return (
							<div className={'tie-radio-option'} key={optionKey}>
								<select
									id={`tie-${optionKey}`}
									className={'px-2 py-1 border-1 border-gray-300'}
									value={tiesAnswers[optionKey]}
									onChange={(e) => onSelectTieAnswer(optionKey, parseInt(e.target.value))}
								>
									<option key={0} value={0}>&nbsp;</option>
									{question.options.map((option, i) => {
										return (
											<option key={i + 1} value={i + 1 + valueOffset} disabled={tiesAnswers.includes(i + 1 + valueOffset)}>
												{i + 1}
											</option>
										);
									})}
								</select>
								<p className={'ml-2 md:text-lg text-primary leading-none py-1'}>
									{studentMode ? option.label : t(`assessment.take.ties.${option.label}`)}
								</p>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const renderContent = () => {
		if (question.type === 'radio') {
			return renderRadioContent();
		}
		return renderSelectContent();
	};

	return (
		<div className={'flex flex-col mx-4'} id={`question-${questionNumber}`}>
			{renderContent()}
			<div className='flex justify-between my-4'>
				<div>
					{!!hasBackButton && (
						<AppButton
							className={'btn btn-secondary'}
							text={t('back')}
							onClick={previousStep}
							disabled={loading}
							id={'wg-assessment-tie-back-btn'}
						/>
					)}
				</div>
				<AppButton text={t('next')} onClick={onNext} loading={loading} id={'wg-assessment-tie-next-btn'} />
			</div>
			<AssessmentErrorModal
				isOpen={isErrorModalOpen}
				error={t(`assessment.take.welcome.error.${errorModal ? errorModal : 'UNDEFINED_ERROR'}`)}
				closeModal={() => setIsErrorModalOpen(false)}
			/>
		</div>
	);
};

export default TieQuestions;
