import React from 'react';
import { report_gear } from '../../../../images';
import PageHeader from './PageHeader';

const AsATeam = () => {
	const renderGear = (text) => {
		return (
			<div className={'flex flex-row my-3'}>
				<img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
				<div>
					<p>{text}</p>
				</div>
			</div>
		);
	};

	return (
		<div className='Application'>
			<PageHeader title={'Application'} />
			<div className={'container mt-12'}>
				<div className='font-bold uppercase mt-12'>USE WORKING GENIUS AS A TEAM</div>
				<div className={'ml-8 mr-20'}>
					<p className={'mt-4'}>
						Teams that embrace this model can experience greater levels of trust, increase morale and
						productivity, have better meetings, and become more successful in hiring the right people.
					</p>

					<p className='italic font-bold mt-8 mb-4'>Four simple steps to using Working Genius as a team:</p>

					<p className={'mb-6'}>
						<span className='font-bold uppercase text-16 pr-1'>STEP 1:</span> Have everyone take the{' '}
						<a
							href='https://player.vimeo.com/video/750402175'
							target={'_blank'}
							className={'text-secondary underline'}
						>
							Working Genius Assessment
						</a>{' '}
						as a group (10 minutes).
					</p>

					<p className={'mb-2'}>
						<span className='font-bold uppercase text-16 pr-1'>STEP 2:</span> Sit down together to reveal
						your results to one another (15 – 30 minutes).
					</p>

					<div className='mr-12'>
						{renderGear(
							'Have everyone highlight the areas of the report that were particularly insightful and convicting for them.'
						)}
						{renderGear('Have them also read the description of their Working Genius Pairings. ')}
						{renderGear(
							'For additional discussion, prompt the following questions: Where in your current job are you able to contribute using your geniuses? Where in your current job are you experiencing exasperation or burnout?'
						)}
					</div>

					<p className={'my-6'}>
						<span className='font-bold uppercase text-16 pr-1'>STEP 3:</span> Review the{' '}
						<a
							href='https://player.vimeo.com/video/853390950?h=240925ea61'
							target={'_blank'}
							className={'text-secondary underline'}
						>
							Team Map
						</a>{' '}
						which presents your collective results of the Working Genius Assessments. The Team Map displays
						a snapshot of each member's Working Geniuses and Working Frustrations. From this map, you’ll be
						able to identify areas of strength for the team, and gaps that might explain challenges you've
						experienced in the past. The Team Map will also help you identify opportunities to make
						adjustments that will lead to increased productivity and fulfillment. See page 10 for a sample
						Team Map and analysis (15 – 30 minutes).
					</p>

					<p className={'mb-6'}>
						<span className='font-bold uppercase text-16 pr-1'>STEP 4:</span> Discuss how you might
						reorganize your roles to leverage one another’s Working Geniuses, and reduce time spent in your
						areas of frustration. Consider “borrowing” people from other departments, or even hiring new
						people, to address areas where you lack necessary genius (30 – 60 minutes).
					</p>
				</div>
			</div>
		</div>
	);
};

export default AsATeam;
