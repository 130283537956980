import React from 'react';
import AppButton from '../../../components/AppButton';
import { dca_wg_logo } from '../../../images';

const DalePartnership = () => {
    return (
        <div className='py-8 bg-secondary'>
            <div className='flex flex-col md:flex-row items-center justify-center'>
                <div className='flex flex-col justify-center mx-4 text-center'>
                    <h3 className='text-2xl text-white'>
                        We've partnered with Dale Carnegie to offer Working <br className='hidden xl:block' />
                        Genius Certification in multiple languages.
                    </h3>
                    <p className='text-white text-base mt-2 mb-4'>
                        Learn more about upcoming sessions:
                    </p>
                    <div className='flex flex-col md:flex-row items-center justify-center gap-6'>
                        <AppButton
                            className={'btn btn-dark rounded-sm'}
                            text={'Spanish Certification'}
                            onClick={() => window.open('https://www.dalecarnegie.com/es/courses/6578', '_blank')}
                            style={{ textTransform: 'capitalize' }}
                        />
                        <AppButton
                            className={'btn btn-dark rounded-sm'}
                            text={'German Certification'}
                            onClick={() => window.open('https://www.dalecarnegie.com/de/courses/6578', '_blank')}
                            style={{ textTransform: 'capitalize' }}
                        />
                    </div>
                </div>
                <div className='flex items-center justify-center m-8'>
                    <img src={dca_wg_logo} className={'object-contain'} alt='DCA Partnership' width='350' />
                </div>
            </div>
        </div>
    );
};

export default DalePartnership;
