import React from 'react';
import { book_cover, patrick_plain } from '../../images';
import UtilityService from '../../services/UtilityService';

const BookHero = () => {
	return (
		<div className='book-hero'>
			<div className='container'>
				<div className='book-hero-content'>
					<div className='book-hero-cover flex flex-col items-center'>
						<img src={book_cover} alt='' width='270' />
						{UtilityService.isEventLiveDatePassed() ? (
							``
						) : (
							<h4 className='book-h4 text-center'>
								COMING
								<b className='block'>SEPTEMBER 27</b>
							</h4>
						)}
					</div>
					<div className='book-hero-text text-center'>
						<h1 className='book-h2'>Discover your gifts.</h1>
						<h2 className='book-h2-bold'>Transform your work.</h2>
						<div className='flex items-center justify-center mt-4'>
							<a href='#book-preorder-id' className='btn btn-primary mt-4' id={'wg-pre-order-book-btn'}>
								{UtilityService.isEventLiveDatePassed() ? `Order the book` : `Pre-order the book`}
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='book-hero-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
		</div>
	);
};

export default BookHero;
