import React from 'react';
import PageHeader from './PageHeader';
import ReportService from '../../../../services/ReportService';
import WidgetSVG from '../../../../images/report-widget.inline.svg';
import PageTitle from './PageTitle';

const Geniuses = ({ assessment, type, title }) => {
	return (
		<div className='SpecificResults'>
			<PageHeader title={'Results'} />

			<div className='container'>
				<PageTitle title={title} className={'mt-8'} />
				<p
					className={'block text-12 my-4'}
					dangerouslySetInnerHTML={{
						__html: ReportService.Geniuses[type].description(
							assessment.customResults[`${type}1`],
							assessment.customResults[`${type}2`],
							ReportService[`${type}_color`]
						),
					}}
				></p>
				<div
					className={`widget ${type} ${assessment.customResults[`${type}1`]} ${
						assessment.customResults[`${type}2`]
					}`}
				>
					<div className='mx-auto w-3/5'>
						<WidgetSVG />
					</div>
				</div>
				<div className='flex flex-row gap-4 mt-4'>
					<div className={'flex flex-1 flex-col'}>
						<p className='text-14 uppercase font-bold' style={{ color: ReportService[`${type}_color`] }}>
							{assessment.customResults[`${type}1`]}
						</p>
						<p
							className={'text-12 leading-normal font-medium'}
							dangerouslySetInnerHTML={{
								__html: ReportService.Geniuses[assessment.customResults[`${type}1`]],
							}}
						/>
					</div>
					<div className={'flex flex-1 flex-col'}>
						<p className='text-14 uppercase font-bold' style={{ color: ReportService[`${type}_color`] }}>
							{assessment.customResults[`${type}2`]}
						</p>
						<p
							className={'text-12 leading-normal font-medium'}
							dangerouslySetInnerHTML={{
								__html: ReportService.Geniuses[assessment.customResults[`${type}2`]],
							}}
						/>
					</div>
				</div>
				{!!ReportService.Geniuses[type].note && (
					<div className='mt-8'>
						<p className='text-14 uppercase font-bold'>NOTE</p>
						<p
							className={'text-12 font-medium'}
							dangerouslySetInnerHTML={{ __html: ReportService.Geniuses[type].note }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Geniuses;
