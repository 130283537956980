import React from 'react';
import Layout from '../../components/Layout';
import AppButton from '../../components/AppButton';
import { addToCart } from '../../services/CustomerService';
import { getCertificationDates } from '../../services/CertificationService';

const license = {
	product_id: true,
	product_key: 'add_license',
	name: 'The Six Types of Working Genius',
	description: 'The Six Types of Working Genius License Description',
	price: '$25,00',
	price_sale: '',
	price_discount_formatted: '',
	year_available: '2022',
};

const Store = () => {
	const [loading, setLoading] = React.useState(true);
	const [products, setProducts] = React.useState([]);

	React.useEffect(() => {
		getCertificationDates()
			.then(({ available }) => {
				const _p = available.map((p) => {
					return {
						product_id: p.certification_product_id,
						product_key: 'certification_product_id',
						name: 'Working Genius Certification',
						description: p.dates.join('<br/>'),
						price: p.price,
						variation_id: p.variation_id
					};
				});
				setProducts([license, ..._p]);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const renderProducts = () => {
		if (loading) {
			return (
				<div id={'wg-loading-resources'} className='relative h-40'>
					<div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
						<div className='spinner'>
							<div className='double-bounce1' />
							<div className='double-bounce2' />
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className={'container mx-auto mt-4'}>
				<div className='flex flex-wrap -mx-2'>
					{products.map((p, i) => {
						return (
							<div className={'flex w-1/3 flex-col p-2 '} key={i}>
								<div className='flex flex-1 flex-col bg-white shadow-md p-4'>
									<div className='flex flex-1 flex-col mb-4'>
										<p
											className={'text-xl text-primary font-medium'}
											dangerouslySetInnerHTML={{ __html: p.name }}
										/>
										<p
											className={'text-primary'}
											dangerouslySetInnerHTML={{ __html: p.description }}
										/>
										<p
											className={'text-primary font-medium text-lg my-2'}
											dangerouslySetInnerHTML={{ __html: p.price }}
										/>
									</div>
									<AppButton
										text={'Add To Cart'}
										onClick={() => addToCart(p.product_key, p.product_id)}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	return <Layout>{renderProducts()}</Layout>;
};

export default Store;
