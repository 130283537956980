import React from 'react'
import AppRIIcon from './AppRIIcon';
import AppIcon from './AppIcon';
import MixedGroup from './../images/mixed-group.inline.svg';
import { AssessmentModeEnum } from '../constants/AssessmentsData';

const WgIcon = ({ item, ...props }) => {
    const icon = {
        standard: {
            group: 'team-fill',
            individual: item.shared_from ? 'user-received-fill' : item.shared_to ? 'user-shared-fill' : 'user-fill',
            duplicate: 'group-fill'
        },
        student: {
            group: 'student-group',
            individual: item.shared_from ? 'student-shared' : item.shared_to ? 'student-shared-with-others' : 'student',
            duplicate: 'student-duplicated'
        },
    };

    const renderIcon = (mode = 'standard', type) => {
        const IconComponent = mode === AssessmentModeEnum.STANDARD ? AppRIIcon : AppIcon;

        return (
            <IconComponent
                className={`${props.className ? props.className : 'hidden md:inline text-3xl justify-center '} ${mode === AssessmentModeEnum.STANDARD ? 'text-secondary mx-2' : 'text-student'}`}
                icon={icon[mode][type]}
            />
        );
    };

    if (item.assessment_mode) {
        if (
            item.roster_mode &&
            ((item.roster_mode.includes(AssessmentModeEnum.STANDARD) && item.roster_mode.includes(AssessmentModeEnum.STUDENT)) ||
                (item.assessment_mode === AssessmentModeEnum.STUDENT && item.roster_mode.includes(AssessmentModeEnum.STANDARD)) ||
                (item.assessment_mode === AssessmentModeEnum.STANDARD && item.roster_mode.includes(AssessmentModeEnum.STUDENT)))
        ) {
            return <div className='ml-2'><MixedGroup /></div>;
        }
        return renderIcon(item.assessment_mode, item.type);
    }
    if (item.roster_mode) {
        return renderIcon(item.roster_mode, item.original_roster_id ? 'duplicate' : 'individual');
    }
    return renderIcon(AssessmentModeEnum.STANDARD, item.type);
};

export default WgIcon;