import React from 'react';
import UtilityService from '../../../services/UtilityService';
import RosterService from '../../../services/RosterService';
import NotificationService from '../../../services/NotificationService';
import MobileEdit from './MobileEdit';
import AppSlideOver from '../../AppSlideOver';

const geniusColor = UtilityService.geniusColor;
const competencyColor = UtilityService.competencyColor;
const frustrationColor = UtilityService.frustrationColor;

const EditResultsModal = ({ roster, identifier = 'id', isOpen, closeModal, onUpdate }) => {
	const options = ['Wonder', 'Invention', 'Discernment', 'Galvanizing', 'Enablement', 'Tenacity'];

	const defaultResults = [
		{
			title: 'Working Genius',
			color: geniusColor,
			description: 'You need to select 2 of these geniuses. ',
			props: [],
		},
		{
			title: 'Working Competency',
			color: competencyColor,
			description: 'You need to select 2 of these geniuses. ',
			props: [],
		},
		{
			title: 'Working Frustration',
			color: frustrationColor,
			description: 'You need to select 2 of these geniuses. ',
			props: [],
		},
	];

	const [results, setResults] = React.useState(defaultResults);
	const [newResults, setNewResults] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		if (!isOpen) {
			setResults(defaultResults);
			setNewResults([]);
		}
	}, [isOpen]);

	const toggleOption = (option, key) => {
		const newResults = [...results];
		const currentSection = newResults.findIndex((res) => {
			return res.props.indexOf(option) > -1;
		});
		if (currentSection > -1) {
			const currentOption = newResults[currentSection].props.indexOf(option);
			newResults[currentSection].props.splice(currentOption, 1);
		}
		newResults[key].props.push(option);
		if (newResults[key].props.length > 2) {
			newResults[key].props.shift();
		}
		setResults(newResults);
		setNewResults(RosterService.prepareNewResults(results));
	};

	const save = () => {
		setLoading(true);
		RosterService.updateWidget({
			roster_id: roster[identifier],
			wg1: newResults[0],
			wg2: newResults[1],
			wc1: newResults[2],
			wc2: newResults[3],
			wf1: newResults[4],
			wf2: newResults[5],
		}).then(
			() => {
				setLoading(false);
				NotificationService.success('The assessment results were successfully updated.');
				onUpdate({
					new_wg1: newResults[0],
					new_wg2: newResults[1],
					new_wc1: newResults[2],
					new_wc2: newResults[3],
					new_wf1: newResults[4],
					new_wf2: newResults[5],
					hasTies: false,
				});
			},
			(error) => {
				NotificationService.error(error.message);
				setLoading(false);
			}
		);
	};

	return (
		<AppSlideOver id={'wg-edit-assessment-results-modal'} isOpen={isOpen} closeModal={closeModal}>
			<MobileEdit
				roster={roster}
				results={results}
				options={options}
				loading={loading}
				closeModal={closeModal}
				toggleOption={toggleOption}
				newResults={newResults}
				onReset={() => {
					setResults(defaultResults);
					setNewResults([]);
				}}
				onSubmit={save}
			/>
		</AppSlideOver>
	);
};

export default EditResultsModal;
