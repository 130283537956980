import React from 'react';
import CoverPage from './v2/CoverPage';
import Overview from './v2/Overview';
import Footer from './v2/Footer';
import Results from './v2/Results';
import Geniuses from './v2/Geniuses';
import UniquePairingV2 from './v2/UniquePairingV2';
import WGPairings from './v2/WGPairings';
import WhatsNext from './v2/WhatsNext';
import AsATeam from './v2/AsATeam';
import TeamMapAnalyze from './v2/TeamMapAnalyze';
import GoingDeeper from './v2/GoingDeeper';

const EnglishTemplate = ({ assessment }) => {
	return (
		<div id={'wg-assessment-report'} className={'wg-pdf-assessment-report-v2'}>
			<section role='region' className='Page'>
				<CoverPage assessment={assessment} />
			</section>
			<section role='region' className='Page'>
				<Overview assessment={assessment} />
				<Footer page={1} />
			</section>
			<section role='region' className='Page'>
				<Results assessment={assessment} />
				<Footer page={2} />
			</section>
			<section role='region' className='Page'>
				<Geniuses assessment={assessment} type={'genius'} title={'Your Working Geniuses'} />
				<Footer page={3} />
			</section>
			<section role='region' className='Page'>
				<Geniuses assessment={assessment} type={'competency'} title={'Your Working Competencies'} />
				<Footer page={4} />
			</section>
			<section role='region' className='Page'>
				<Geniuses assessment={assessment} type={'frustration'} title={'Your Working Frustrations'} />
				<Footer page={5} />
			</section>
			<section role='region' className='Page'>
				<UniquePairingV2 assessment={assessment} />
				<Footer page={6} />
			</section>
			<section role='region' className='Page'>
				<WGPairings assessment={assessment} />
				<Footer page={7} />
			</section>
			<section role='region' className='Page'>
				<WhatsNext />
				<Footer page={8} />
			</section>
			<section role='region' className='Page'>
				<AsATeam />
				<Footer page={9} />
			</section>
			<section role='region' className='Page'>
				<TeamMapAnalyze />
				<Footer page={10} />
			</section>
			<section role='region' className='Page'>
				<GoingDeeper />
				<Footer page={11} />
			</section>
		</div>
	);
};

export default EnglishTemplate;
