import React from 'react';

import EventPat from '../../components/event/EventPat';
import Event6Types from '../../components/event/Event6Types';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import WebinarHero from '../../components/webinar/WebinarHero';
import ApiService from '../../services/ApiService';

const WebinarEvent = ({ stream }) => {
	const [streamHTML, setStreamHTML] = React.useState(' ');

	const getStreamLinks = React.useCallback(() => {
		ApiService.get('/v1/live/webinars').then(({ data }) => {
			setStreamHTML(data?.url?.live);
		});
	}, [setStreamHTML]);

	React.useEffect(() => {
		if (stream) getStreamLinks();
	}, [stream, getStreamLinks]);

	return (
		<WorkshopLayout
			title='The 6 Types of Working Genius: Webinars'
			description='Free Working Genius Industry-specific Webinars with Patrick Lencioni'
		>
			<div id={'wg-webinars-page'}>
				<WebinarHero streamHTML={streamHTML} shouldStream={stream} />
				<EventPat />
				<Event6Types />
			</div>
		</WorkshopLayout>
	);
};

export default WebinarEvent;
