import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import ReportWidgetEsSvg from '../../../../images/assessments/report/img/widget_gears_es.inline.svg';
import ReportUniquePairingsES from '../../../../constants/ReportUniquePairingsES';

const UniquePairingV2 = ({ assessment }) => {
	const pairing = React.useMemo(() => {
		const key = ReportUniquePairingsES.getUniquePairing(
			assessment.customResults.genius1,
			assessment.customResults.genius2
		);
		const reverse = key.split('').reverse().join('');

		return {
			key: key,
			reverse: reverse,
			info: ReportUniquePairingsES.UniqueParings[key] ?? ReportUniquePairingsES.UniqueParings[reverse],
		};
	}, []);

	return (
		<div className='UniquePairing'>
			<PageHeader title={'DESCRIPCIÓN DE LOS PARES'} />

			<div className='container en'>
				<div className='flex flex-row gap-4 py-4 mt-3'>
					<div className='flex flex-1 flex-col'>
						<PageTitle title={'Tus pares únicos'} className={'mb-1 pairing-title'} />
						<div className={'pairing-container without-border'}>
							<span className={'information'}>
								<h2 className={'key text-lg'}>
									{pairing.key} | {pairing.reverse}
								</h2>
								<p className={'title bold'}>{pairing.info.title}</p>
							</span>
						</div>

						<p className={'text-12 pt-1 pr-6'}>
							Aunque cada una de las genialidades es importante en sí misma, la combinación de tus dos
							genialidades sobresalientes puede ser igual de esclarecedora a la hora de identificar tus
							talentos y dones únicos. A continuación encontrarás una descripción de tu par de
							genialidades sobresalientes, que es la combinación de{' '}
							<span
								className='uppercase font-bold'
								style={{ color: ReportUniquePairingsES.genius_color }}
							>
								{ReportUniquePairingsES.getAbility(assessment.customResults.genius1)}
							</span>{' '}
							{ReportUniquePairingsES.getAbility(assessment.customResults.genius2)
								.toLowerCase()
								.startsWith('i')
								? ' e '
								: ' y '}
							<span
								className='uppercase font-bold'
								style={{ color: ReportUniquePairingsES.genius_color }}
							>
								{ReportUniquePairingsES.getAbility(assessment.customResults.genius2)}
							</span>
							.
						</p>
					</div>

					<div className={`flex-1 genius ${assessment.customResults.genius1} ${assessment.customResults.genius2}` }>
						<ReportWidgetEsSvg />
					</div>
				</div>
				<p className={'text-12'}> {pairing.info.description}</p>

				<div className={'crave_container'}>
					<span className={'crave_items_container'}>
						<div className={'crave_item'}>
							<span className={'title'}>ANHELAN:</span>
							{pairing.info.crave.map((crave) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crave.title}</span>
									{crave.description}
								</p>
							))}
						</div>

						<div className={'crave_item'}>
							<span className={'title'}>LOS DERRUMBA:</span>
							{pairing.info.crush.map((crush) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crush.title}</span>
									{crush.description}
								</p>
							))}
						</div>
					</span>

					<p className={'italic font-bold text-center text-12 text-gray-800 pb-2'}>
						En la siguiente página encontrarás una breve descripción de los 15 pares.
					</p>
				</div>
			</div>
		</div>
	);
};

export default UniquePairingV2;
