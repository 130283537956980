import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Layout from '../../../components/Layout';
import RequestAssessmentList from './request-assessment-list';
import RequestAssessment from './request-assessment';
import { getQueryParams } from '../../../services/UtilityService';
import { useLocation } from '@reach/router';

const Index = () => {
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [step, setStep] = React.useState(0);

	const location = useLocation();
	const params = getQueryParams(location.search);

	useEffect(() => {
		if (params?.tab === 'history') setSelectedIndex(1);
	}, [location.search]);

	const handleBack = () => (step > 0 && selectedIndex === 0 ? setStep((c) => c - 1) : navigate(-1));

	return (
		<Layout title='The 6 Types of Working Genius: Request Assessments'>
			<div className='container m-4'>
				<div className='flex my-2'>
					<a
						className='text-secondary text-xl font-semibold underline hover:text-primary cursor-pointer'
						onClick={handleBack}
						id='wg-request-assessments-back-link'
					>
						{'< back'}
					</a>
				</div>
				<Tabs forceRenderTabPanel selectedIndex={selectedIndex} onSelect={setSelectedIndex}>
					<TabList>
						<Tab className={'react-tabs__tab wg-licenses-tab mt-6'}>Request</Tab>
						<Tab className={'react-tabs__tab wg-account-orders-tab mt-6'}>History</Tab>
					</TabList>

					<TabPanel>
						<RequestAssessment step={step} setStep={setStep} />
					</TabPanel>
					<TabPanel>
						<RequestAssessmentList />
					</TabPanel>
				</Tabs>
			</div>
		</Layout>
	);
};

export default Index;
