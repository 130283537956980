import React from 'react';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import AppSlideOver from '../AppSlideOver';
import { clipboard } from '../../images';
import UtilityService from '../../services/UtilityService';
import AssessmentsService from '../../services/AssessmentsService';
import NotificationService from '../../services/NotificationService';

const RequestShare = ({ isOpen, onClose, item, onRequestShare, requestStatusPayload = {} }) => {
	const [loading, setLoading] = React.useState(false);
	const [requestLoading, setRequestLoading] = React.useState(false);
	const [history, setHistory] = React.useState('');

	React.useEffect(() => {
		setRequestLoading(false);
		setLoading(true);

		if (isOpen) {
			AssessmentsService.requestShareStatus(requestStatusPayload)
				.then((result) => {
					setHistory(result.data);
					setLoading(false);
				})
				.catch((error) => {
					NotificationService.error(error.message, null, { duration: Infinity });
					setLoading(false);
				});
		}
	}, [isOpen]);

	const onSubmit = () => {
		setRequestLoading(true);
		onRequestShare(item);
	};

	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose}>
			<div className='share-assessment-slide-over'>
				<div className='share-assessment-info'>
					<p className='share-assessment-heading'>Request to share</p>
					<p className='share-assessment-text'>
						To request the ability to share a copy of this individual's assessment results with another
						Working Genius account owner, click the request button below. The individual will receive an
						email where they can grant permission for their personal assessment results to be shared into
						another Working Genius account. The assessment results will remain in your account and once
						permission has been granted by that individual, you will receive a confirmation email and will
						then have the ability to share results.
					</p>
				</div>

				<div className='assessment-info flex flex-col items-center'>
					<img className={'object-contain mb-4'} width='40' src={clipboard} alt='' />
					<p className='share-assessment-heading text-base'>{item.fname + ' ' + item.lname}</p>
					<p className='share-assessment-text'>Individual Assessment</p>
					<p className='share-assessment-text'>
						Completed at {UtilityService.formatDate(item.completed ? item.completed : item.created)}
					</p>
				</div>

				<hr className='slide-over-break' />
				{loading ? (
					<p className='flex items-center justify-center'>
						<AppIcon icon={'loading'} className={'animate-spin text-primary text-2xl'} />
					</p>
				) : (
					<>
						{Object.keys(history).length !== 0 ? (
							<>
								<p className='share-assessment-text'>
									{history.status === 'pending' &&
										`Request sent on ${UtilityService.formatDate(
											history.requested,
											'MM/DD/YYYY hh:mm A',
											true
										)}.`}
									{history.status === 'denied' &&
										`Request was denied on ${UtilityService.formatDate(
											history.requested,
											'MM/DD/YYYY hh:mm A',
											true
										)}.`}
								</p>
								<hr className='slide-over-break' />
							</>
						) : (
							<></>
						)}
					</>
				)}

				<div className='flex justify-center items-center my-4'>
					<AppButton
						text={'Request'}
						onClick={() => onSubmit()}
						className={'btn btn-primary w-full'}
						id={'wg-request-share-btn'}
						disabled={requestLoading}
					/>
					<AppButton
						text={'Cancel'}
						onClick={onClose}
						className={'btn btn-secondary ml-2'}
						id={'wg-request-share-cancel-btn'}
					/>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default RequestShare;
