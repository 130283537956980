import React from 'react';
import { pre_work_10_minutes, pre_work_assessment } from '../../../images';

const AssessmentSection = () => {
	return (
		<div className={'pre-work-container flex-col bg-white rounded-sm shadow-lg py-6 px-10 my-10 '}>
			<div className={'flex-1 flex mx-5 justify-between'}>
				<div className={'flex text-8 md:w-full md:text-16 text-primary flex-col pt-4'}>
					<span>
						<b>STEP ONE:</b>
					</span>
					<span className={'pt-1'}> Complete the Working Genius Assessment</span>
				</div>
				<div className={'flex items-end'}>
					<img src={pre_work_10_minutes} alt='10-minutes' className={'w-12 md:w-40'} />
				</div>
			</div>

			<div className={'flex-1 m-5'}>
				<img src={pre_work_assessment} alt='assessment' />
			</div>
		</div>
	);
};

export default AssessmentSection;
