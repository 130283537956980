import React from 'react';
import { gears_play_video } from '../../images';
import AppVideoModal from '../AppVideoModal';

const BookVideo = () => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	return (
		<div className='book-video pt-12 pb-6'>
			<div className='container'>
				<div className='book-video-content flex flex-col lg:flex-row items-center'>
					<div className='book-video-src flex-1'>
						<a
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/771773151?badge=0&amp')}
							className='book-video-link cursor-pointer'
							id={'wg-book-play-video-link'}
						>
							<img src={gears_play_video} alt='Play Video Patrick' />
						</a>
					</div>
					<div className='book-video-quote flex-1 ml-0 lg:ml-12 mt-12 lg:mt-0'>
						<p className='book-h3 mb-4'>
							"Already, we’ve seen this tool used in just about every industry imaginable&#8212;from
							zookeepers to tech professionals&#8212;and it's leading to more success, fulfillment, and
							joy."
						</p>
						<h2 className='book-h4 font-semibold uppercase'>Patrick Lencioni</h2>
					</div>
				</div>
			</div>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default BookVideo;
