import React from 'react';
import ResourceSection from './ResourceSection';

const Resources = ({ loading, sections, resources }) => {
	if (loading) {
		return (
			<div id={'wg-loading-resources'} className='relative h-40'>
				<div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div>
			{sections.map((section) => {
				return <ResourceSection section={section} resources={resources[section.key]} key={section.key} />;
			})}
		</div>
	);
};

export default Resources;
