import React from 'react';
import { ttg_logo_white, about_how_it_started } from '../../images';

const AboutHowItStarted = () => {
	return (
		<div className='about-how-it-started-section' id={'wg-about-how-it-started-id'}>
			<div className='container'>
				<div className='about-pat-content'>
					<div className='event-pat-text w-full lg:w-2/3'>
						<p className='text-2xl lg:text-4xl text-primary-white font-normal my-4 text-center md:text-left'>
							How it Started
						</p>
						<p className='about-h2 text-center md:text-left'>
							The Author and Creator of the 6 Types of Working Genius
						</p>
						<div className='event-pat-mobile flex items-center justify-center lg:hidden my-4'>
							<img src={about_how_it_started} alt='Patrick Lencion' width={'400'} />
						</div>
						<p className='text-primary-white font-lg my-4'>
							For twenty-five years, Patrick Lencioni has been working with teams to help them become more
							cohesive, effective and successful. In 1997, he founded the Table Group, a firm dedicated to
							making organizations healthier and work more dignifying. He has written 13 best-selling
							business books on the topics of teamwork, leadership and culture (including{' '}
							<i>The Five Dysfunctions of a Team</i>, <i>The Advantage</i>, and{' '}
							<i>The Ideal Team Player</i>).
						</p>
						<p className='text-primary-white font-lg my-4'>
							In 2020, Pat began to question why he occasionally felt drained and frustrated in his own
							job and, in the process of figuring out why, Working Genius was born. Since that time,
							Working Genius has helped hundreds of thousands of individuals and teams discover their
							gifts and transform their work.
						</p>
						<div className='flex flex-col items-center justify-center md:items-start mt-8'>
							<a
								href='https://www.tablegroup.com/about/'
								target='_blank'
								rel='noreferrer'
								className='btn btn-primary mr-0 lg:mr-2'
								id='about-wg-learn-more-btn'
							>
								Learn More About The Table Group
							</a>
							<img
								width='200'
								src={ttg_logo_white}
								alt='Working Geniuses Primary Logo'
								className='my-8'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutHowItStarted;
