import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { getQueryParams } from '../../../services/UtilityService';

import Layout from '../../../components/Layout';
import AccountDetailsStep from '../../../components/signup/AccountDetailsStep';
import AddressDetailsStep from '../../../components/signup/AddressDetailsStep';
import SignInDetailsStep from '../../../components/signup/SignInDetailsStep';
import Progress from '../../../components/signup/Progress';


export const SignupStep = {
    SigninDetails: 1,
    AccountDetails: 2,
    AddressDetails: 3,
};

const Wizard = () => {
    const [signupStep, setSignupStep] = React.useState(SignupStep.SigninDetails);
    const [registerData, setRegisterData] = useState(null);

    const location = useLocation();
    const { redirect } = getQueryParams(location.search);

    const getPageFooter = () => {
        switch (signupStep) {
            case SignupStep.SigninDetails:
                return (
                    <p className='text-sm text-gray-600 text-center mx-auto mb-10 '>
                        Already have an account?{' '}
                        <Link
                            to={`/client/login${redirect ? `?redirect=${redirect}` : ''}`}
                            className='text-secondary font-medium'
                        >
                            Login
                        </Link>
                    </p>
                );
            case SignupStep.AccountDetails:
                return <span className=''></span>;
            case SignupStep.AddressDetails:
                return (
                    <div className='sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto px-8'>
                        <p className='text-sm text-gray-600 mb-4 w-auto text-center'>
                            By clicking "Create Account" you agree Working Genius{' '}
                            <span
                                onClick={() => navigate('/policy/terms-of-service')}
                                className='text-secondary font-medium cursor-pointer'
                            >
                                Terms of Service
                            </span>{' '}
                            and{' '}
                            <span
                                onClick={() => navigate('/policy/privacy-policy')}
                                className='text-secondary font-medium cursor-pointer'
                            >
                                Privacy Policy
                            </span>
                            .
                        </p>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderContent = () => (
        <div className='md:shadow-md sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto mt-12 mb-4 px-4 md:px-8 py-8'>
            <h1 className='uppercase text-secondary text-center text-3xl font-semibold mb-4 leading-none'>
                CREATE ACCOUNT
            </h1>
            <StepWizard
                nav={<Progress />}
                initialStep={SignupStep.SigninDetails}
                onStepChange={(e) => {
                    setSignupStep(e.activeStep);
                }}
            >
                <SignInDetailsStep registerData={registerData} setRegisterData={setRegisterData} />
                <AccountDetailsStep registerData={registerData} setRegisterData={setRegisterData} />
                <AddressDetailsStep registerData={registerData} />
            </StepWizard>
        </div>
    );

    return (
        <Layout>
            <div className={'container mx-auto'}>
                {renderContent()}
                {getPageFooter()}
            </div>
        </Layout>
    );
};

export default Wizard;
