import React from 'react';
import AppRIIcon from './../AppRIIcon';
import AppTooltip from '../AppTooltip';

const AssessmentAction = ({
	tooltipText,
	icon,
	iconClass,
	loading = false,
	actionId,
	className = 'action-btn',
	onClick,
	wrapperClass = 'action-btn',
	...props
}) => {
	const _onClick = (e) => {
		if (loading || props.disabled) {
			if (e) {
				e.preventDefault();
			}
		} else if (onClick) {
			onClick(e);
		}
	};

	const renderButton = () => {
		const extraProps = tooltipText ? {} : props;
		return (
			<button
				{...extraProps}
				className={className}
				disabled={props.disabled || loading}
				onClick={(e) => {
					if (!tooltipText) {
						_onClick(e);
					}
				}}
				type='button'
				id={actionId}
			>
				{loading ? (
					<AppRIIcon icon={'loader-4-line'} className={'action-icon animate-spin loading ' + iconClass} />
				) : (
					icon && <AppRIIcon icon={icon} className={'action-icon ' + iconClass} />
				)}
			</button>
		);
	};

	if (!tooltipText) {
		return renderButton();
	}

	return (
		<div className={wrapperClass} {...props} onClick={_onClick}>
			<AppTooltip
				content={() => <p className={'text-white text-center text-tiny font-normal'}>{tooltipText}</p>}
				type={'small'}
				wrapperClass={'action-tooltip-wrapper'}
				tooltipClass={'action-tooltip'}
			>
				{renderButton()}
			</AppTooltip>
		</div>
	);
};

export default AssessmentAction;
