import React from 'react';

const NextUpItem = ({ id, title, nextTab, subtitle = '' }) => {
	const tabs = ['tab2', 'tab3', 'tab4', 'tab5'];
	const index = tabs.indexOf(id);
	const nextTabIndex = tabs.indexOf(nextTab);

	return (
		<span className={`next-item ${nextTab === id ? 'active' : ''} ${index < nextTabIndex ? 'finished' : ''}`}>
			{title}
			<br />
			{subtitle}
		</span>
	);
};

const NextUp = ({ nextTab, children, extraMessage = '' }) => {
	return (
		<div className='toolkit-nextup-section'>
			<div style={{ maxWidth: '550px' }}>
				<span className='text-gray-800 text-lg font-semibold ml-8'>Next up:</span>
				<div className='nextup-list'>
					<NextUpItem title='1. Sending &amp; Gathering Assessments' id='tab2' nextTab={nextTab} />
					<NextUpItem title='2. Reading a Team Map' id='tab3' nextTab={nextTab} />
					<NextUpItem title='3. Leading a Team Discussion' id='tab4' nextTab={nextTab} />
					<NextUpItem title='4. Working Genius for Meetings, Hiring &amp; Staffing' id='tab5' nextTab={nextTab} />
				</div>
				<p
					className='text-base text-gray-800 font-semibold ml-8'
					dangerouslySetInnerHTML={{ __html: extraMessage }}
				/>
			</div>
			<div className='flex md:items-end'>{children}</div>
		</div>
	);
};

export default NextUp;
