const messages = {
	required: 'is required',
	minLength: 'should be least {{minLength}} characters',
	min: 'should be greater than or equal to {{data-wg-min}}',
	max: 'should be less than or equal to {{data-wg-max}}',
	maxLength: 'must not be longer than {{ttgMaxLength}} characters',
	pattern: 'should be a valid {{name}}',
};

const extractPiece = (str) => {
	const matches = /{{([^}]*)}}/.exec(str);
	if (!matches) {
		return [null, null];
	}
	return [matches[0], matches[1]];
};

const renderErrorMessage = (type, props, defaultErrorMessage = '', formMessage) => {
	let string = messages[type];
	if (['pattern', 'validate', 'required'].indexOf(type) > -1 && formMessage) {
		string = formMessage;
	}
	if (!string) {
		string = defaultErrorMessage;
	}
	let [value, prop] = extractPiece(messages[type]);
	while (value && prop) {
		const varValue = props[prop] ? props[prop] : '';
		if (!varValue) {
			console.error(`No var value set for ${value}`);
		}
		string = string.replace(value, props[prop] ? props[prop] : '');
		[value, prop] = extractPiece(string);
	}
	return string;
};

export default renderErrorMessage;
