import React from 'react';
import { navigate } from 'gatsby';

import AppRIIcon from '../AppRIIcon';
import AppButton from '../AppButton';


const ClaimResourcesCard = () => {
	const content = [
		{
			title: (
				<>
					<p>
						We are excited for you to become{' '}
						<span className={'font-medium'}>Working Genius Certified!</span>
					</p>
					<p>
						You now have 20 Working Genius Assessment licenses available. Click the button below to access
						your Administrator's Console.
					</p>
				</>
			),
			className: 'border-b-1 border-gray-200 pb-6',
			buttonText: "Administrator's Console",
			buttonUrl: '/client',
		},
		{
			title: (
					<p>
						<span className={'font-medium'}>Congratulations!</span> You now have access to the Pre-Work and
						Certification Resources needed to prepare for your certification. Click the button below to
						begin your Certification Pre-Work.
					</p>
			),
			className: 'pt-6',
			buttonText: 'Pre-Work Access',
			buttonUrl: '/client/certification/prework',
		},
	];

	const renderContent = () => content.map((item, index) => (
			<div key={index} className={`flex flex-col ${item.className}`}>
				<span className={'text-sm leading-6'}>{item.title}</span>

				<span className={'mt-6'}>
					<AppButton
						text={item.buttonText}
						onClick={() => navigate(item.buttonUrl)}
						className={'btn btn-secondary resource-button'}
						icon={'share-box-line'}
						iconClass={'text-xl text-center pl-2'}
						remixIcon
					/>
				</span>
			</div>
		));

	return (
		<div className={'dca-resources-card'}>
			<div className={'dca-resources-card-title'}>
				<AppRIIcon icon={'checkbox-circle-fill'} className={'title-icon'} />
				<span className={'text-2xl font-medium pl-1'}>Resources now available</span>
			</div>

			<div className={'dca-resources-card-content'}>{renderContent()}</div>
		</div>
	);
};

export default ClaimResourcesCard;
