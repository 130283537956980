import React from 'react';
import {
	team_application_topics_conversations,
	team_application_topics_gears,
	team_application_topics_profiles,
} from '../../../images';

const Topics = () => {
	return (
		<div className={'application-guide-topics-section'}>
			<div className={'flex flex-col items-center mb-2 container'}>
				<h2 className={'text-2xl lg:text-3xl text-primary font-semibold flex-1 pt-10'}>Topics Covered:</h2>

				<div className={'px-10'}>
					<div className={'application-guide-layout container items-center md:items-baseline pb-10'}>
						<div className={'py-5 md:py-10 px-2 lg:px-4 w-8/12'}>
							<img src={team_application_topics_gears} alt='topic-gears' />
							<h3 className={'text-lg lg:text-xl text-primary font-semibold text-center pt-4'}>
								Understanding Your Team Map
							</h3>
						</div>
						<div className={'py-5 md:py-10 px-2 lg:px-4 w-8/12'}>
							<img src={team_application_topics_conversations} alt='topic-conversations' />
							<h3 className={'text-lg lg:text-xl text-primary font-semibold text-center pt-4'}>
								Improving meetings
							</h3>
						</div>
						<div className={'py-5 md:py-10 px-2 lg:px-4 w-8/12'}>
							<img src={team_application_topics_profiles} alt='topic-profiles' />
							<h3 className={'text-lg lg:text-xl text-primary font-semibold text-center pt-4'}>
								Better hiring and staffing
							</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Topics;
