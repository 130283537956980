import React from 'react';

export const privacyPolicyConstants = {
    hero: {
        header: 'Working Genius Privacy Policy',
    },
    content: [
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Privacy Policy</span>,
        },
        {
            title: 'INTRODUCTION',
            content: (
                <div>
                    <h3>Please read this Privacy Policy carefully. </h3>
                    <span>
						Thank you for choosing to access the website of The Table Group (“Table Group”, “company”, “we”,
						“us”, or “our”). We take your privacy very seriously and are committed to protecting your
						personal information and your right to privacy. If you have any questions or concerns about our
						policy, or our practices with regards to your personal information, please contact us at{' '}
                        <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a> . As you visit our website
						via{' '}
                        <a href='https://www.tablegroup.com/' target='_blank'>
							www.tablegroup.com
						</a>{' '}
                        (the “Website”) and obtain, request and/or use our assessments, products and/or services, you
						may entrust us with your personal information. In this policy, we describe our privacy policy
						and seek to explain to you in the clearest way possible the information we collect, how we use
						it and what rights you have in relation to it. Please read this privacy policy carefully as it
						will help you make informed decisions about sharing your personal information with us. If there
						are any terms in this privacy policy that you do not agree with, please discontinue use of our
						Website and our services.
					</span>

                    <span>
						By using or accessing the Website or Sites (as defined below), you acknowledge that you have
						read, understood, and agree to be bound to all the terms and conditions of this Privacy Policy,
						and the “Terms of Service” (click here for our{' '}
                        <a href='/policy/terms-of-service' target='_blank'>
							Terms of Service
						</a>
						) or other customer agreement between you and The Table Group that is applicable to the
						particular service you are using or accessing (collectively, “User Agreements”).
					</span>

                    <span>
						This privacy policy applies to all information that may be collected from time-to-time through
						our Website and/or any related products, assessments, services, sales, marketing, or events (we
						refer to all of these collectively in this privacy policy as the “Sites”).
					</span>
                </div>
            ),
        },
        {
            title: 'Working Table User Types',
            content: (
                <div>
					<span>
						Much of our Privacy Policy is divided into sections based on the way you may interact with us.
						You are either a Website visitor, a Sites visitor, a user and/or a business partner (as defined
						below). Please determine what user type you are. For each user type we’ve explained what
						information we collect and why, what cookies and other similar technologies we use, how we share
						such information and your rights.
					</span>
                    <span>
						<u>Website or Sites visitor:</u> You are a Website or a Sites visitor when you visit and
						interact with our web sites, web pages, interactive features, blogs, and their respective
						contents at{' '}
                        <a href='https://www.tablegroup.com/' target={'_blank'}>
							www.tablegroup.com
						</a>
						.{' '}
					</span>
                    <span>
						<u>Users:</u> You are a User when you visit a page of a website or application of one of our
						partners where if you click on hyperlinked reference to Table Group you will see a detailed
						notice that enables you to navigate to Table Group and this Privacy Policy where you can opt out
						of personalized recommendations. In some instances, a partner may have white-label Table Group’s
						service for their own offering. In such an event, such partners must disclose their use of Table
						Group in their privacy policies.
					</span>
                    <span>
						<u>Business Partners:</u> You are a Business Partner when you register (or email with Table
						Group) on behalf of the company you work for to use the Table Group offerings, products or
						engage our services.
					</span>
                </div>
            ),
        },
        {
            title: '1. WHAT INFORMATION DO WE COLLECT?',
            content: (
                <div>
					<span>
						We collect personal information that you voluntarily provide to us when you engage with us,
						request our assessment, kits, videos, guides, products, or resources, register on the Sites,
						express an interest in obtaining information about us or our products, resources, and services,
						or otherwise contacting us. We want to understand what services on our Sites interest you and we
						want to remind you about the products and/or services we offer when you are not on our Sites. In
						order to do this, we may collect the following information from your device: (i) IP address;
						(ii) user agent data: device type (e.g., iPhone), browser type (e.g., Chrome), operating system
						(e.g., iOS); (iii) the pages visited on our Sites (e.g., the Table Group “About” page); (iv) the
						time of visit (and corresponding time zone); and (v) referring URLs and other information
						normally transmitted in HTTP requests (e.g. information telling us how you arrived on our
						Sites).
					</span>

                    <span>
						In addition, we may also collect your name and email address if you agree to send your details
						to us via Our Sites (e.g., by signing up to receive our newsletter).The personal information
						that we collect depends on the context of your interactions with us and the Sites, the choices
						you make and certain features you use. All personal information that you provide to us must be
						true, complete, and accurate, and you must notify us of any changes to your personal
						information. The personal information we collect may include the following:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            Name and Contact Data. If you complete a contact form on the Sites, we collect your first
                            and last name, email address, postal address, phone number, and other similar contact data.
                        </li>
                        <li>
                            Credentials. If we require password creation for access to the Sites or otherwise, at times
                            we may collect passwords, password hints, and similar security information used for
                            authentication and account access.
                        </li>
                        <li>
                            Payment Data. If you make a purchase from our Website, we do collect, but do not store,
                            payment data to process payments for your purchases via the Website and in doing so we only
                            collect data necessary for processing a payment such as your payment instrument number
                            (i.e., such as a credit card number), expiration date, and the security code associated with
                            your payment instrument. All payment data in such cases would be stored by our payment
                            processor, not Table Group and you would be directed to review its privacy policies and to
                            contact the payment processor directly to respond to your questions.
                        </li>
                        <li>
                            Transaction Information. If you conduct transactions on the Website, we may collect
                            information related to transactions, including purchases of both digital and physical
                            products.
                        </li>
                        <li>
                            User Account Information. We may collect information that identifies you to the Service.
                            This can range from simply an email address and team affiliation for Online Team Assessment
                            participants, to full name, billing and shipping addresses, email address, password, and ip
                            address. We use this information to authenticate you when you log in to the Service, provide
                            you with access to the products that you or your employer/colleague have purchased, and use
                            the IP address to help maintain your web session security while using the Service.
                        </li>
                        <li>
                            User Content. to the extent that you choose to input Personal Data as part of such content,
                            comments, and other content, information, and materials that you post to or through the
                            Service.
                        </li>
                    </ul>

                    <span>
						<u>Important Note:</u> Users of our Website and Sites should not provide us with personal
						information belonging to individuals who have not consented to the dissemination of such
						information. We are not responsible for any such disclosure made by an independent third party
						through the use of our service. If you believe that your information has been provided to The
						Table Group without your consent, please contact us immediately at support@tablegroup.com.
					</span>

                    <span>
						<b>Information automatically collected.</b> We automatically collect or review certain
						information collected by our web master when you visit, use, or navigate the Sites. This
						information does not reveal your specific identity (e.g., your name or contact information) but
						may include device and usage information, such as your IP address, browser and device
						characteristics, operating system, language preferences, referring URLs, device name, country,
						location, information about how and when you use our Website and other technical information.
						This information is primarily needed to maintain the security and operation of our Sites, and
						for our internal analytics and reporting purposes. Like many businesses, we also collect
						information through cookies and similar technologies.
					</span>

                    <span>
						In order to enhance our ability to provide relevant marketing, offers and services to you and
						update our records, we may obtain information about you from other sources, such as public
						databases, joint marketing partners, as well as from other third parties. Examples of the
						information we could potentially receive from other sources may include any information that you
						choose to make public, marketing leads and search results and links.
					</span>
                </div>
            ),
        },
        {
            title: '2. HOW DO WE USE YOUR INFORMATION?',
            content: (
                <div>
					<span>
						We may use personal information collected via our Sites for a variety of business purposes. We
						do not sell, rent, or lease its customer lists to third parties. We may, from time to time,
						contact you on behalf of external business partners about a particular offering that may be of
						interest to you. We process your personal information for these purposes in reliance on our
						legitimate business interests (“Business Purposes”), in order to enter into or perform a
						contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with our
						legal obligations (“Legal Reasons”). We indicate the specific processing grounds we rely on next
						to each purpose listed below. We use the information we collect or receive:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            To send you marketing and promotional communications for Business Purposes and/or with your
                            Consent. We and/or our third party marketing partners may use the personal information you
                            provide to us for our marketing purposes. You can opt-out of any marketing or related
                            product emails that we may send at any time.
                        </li>
                        <li>
                            To send administrative information to you for Business Purposes, Legal Reasons and/or
                            possibly Contractual. We may use your personal information to send you products, service
                            and/or information about changes to our terms, conditions, and policies.
                        </li>
                        <li>
                            To post testimonials with your Consent. Although we currently do not post testimonials, we
                            may from time to time decide to post testimonials on our Sites that may contain personal
                            information. Prior to posting a testimonial, we will obtain your Consent to use your name
                            and testimonial. If you wish to update, or delete your testimonial, please contact us at the
                            above provided email contact address and be sure to include your name, testimonial location,
                            and contact information.
                        </li>
                        <li>
                            Deliver targeted advertising to you for our Business Purposes and/or with your Consent. We
                            may use your information to develop and display content and advertising (and work with third
                            parties who do so) tailored to your interests and/or location and to measure its
                            effectiveness. For example, if you do not opt out when prompted to receiving emails when
                            completing one of our assessments, then we will market our services to you in future
                            communications until you do opt out.
                        </li>
                        <li>
                            Request Feedback for our Business Purposes and/or with your Consent. We may use your
                            information to request feedback and to contact you about your use of our Sites.
                        </li>
                        <li>
                            To protect our Sites for Business Purposes and/or Legal Reasons. We may use your information
                            as part of our efforts to keep our Sites safe and secure (for example, for fraud monitoring
                            and prevention).
                        </li>
                        <li>
                            To enforce our terms, conditions and policies for Business Purposes, Legal Reasons and/or
                            possibly Contractual.
                        </li>
                        <li>
                            To respond to legal requests and prevent harm for Legal Reasons. If we receive a subpoena or
                            other legal request, we may need to inspect the data we hold to determine how to respond.
                        </li>
                        <li>
                            For other Business Purposes. We may use your information for other Business Purposes, such
                            as data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Sites, products, services, marketing, and your
                            experience.
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: '3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?',
            content: (
                <div>
					<span>
						<b>Information Shared with Third Parties</b>: Except as set forth in this Privacy Policy, you
						will be notified when your Personal Data may be shared with third parties and will be able to
						prevent the sharing of this information. To the extent permitted or required by applicable law,
						you can opt out of having your information shared with a third party that is not our agent, by
						contacting us at <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>. However,
						please note that opting out of information sharing or collection may affect your ability to use
						some features of the Service.
					</span>

                    <span>We only share and disclose your information in the following situations:</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            Compliance with Laws. We may disclose your information where we are legally required to do
                            so in order to comply with applicable law, governmental requests, a judicial proceeding,
                            court order, or legal process, such as in response to a court order or a subpoena (including
                            in response to public authorities to meet national security or law enforcement
                            requirements).
                        </li>
                        <li>
                            Vital Interests and Legal Rights. We may disclose your information where we believe it is
                            necessary to investigate, prevent, or take action regarding potential violations of our
                            policies, suspected fraud, situations involving potential threats to the safety of any
                            person and illegal activities, or as evidence in litigation in which we are involved.
                        </li>
                        <li>
                            Vendors, Consultants and Other Third-Party Service Providers. We may share your data with
                            third party vendors, service providers, contractors or agents who may from time-to-time
                            perform services for us or on our behalf and require access to such information to do that
                            work. Examples include payment processing, data analysis, email delivery, hosting services,
                            customer service and marketing efforts. We may allow selected third parties to use tracking
                            technology on the Sites, which will enable them to collect data about how you interact with
                            the Sites over time. This information may be used to, among other things, analyze and track
                            data, determine the popularity of certain content and better understand online activity.
                            Unless described in this Policy, we do not share, sell, rent, or trade any of your
                            information with third parties for their promotional purposes.
                        </li>
                        <li>
                            Third-Party Advertisers. We may use third-party advertising companies to serve ads when you
                            visit the Sites. These companies may use information about your visits to our Sites and
                            other websites that are contained in web cookies and other tracking technologies in order to
                            provide advertisements about goods and services of interest to you.
                        </li>
                        <li>
                            Affiliates. We may share your information with our affiliates, in which case we will require
                            those affiliates to honor this privacy policy. Affiliates include our parent company and any
                            subsidiaries, joint venture partners or other companies that we control or that are under
                            common control with us.
                        </li>
                        <li>
                            Business Partners. We may share your information with our business partners to offer you
                            certain products, services, or promotions. We have informed our Business Partners and they
                            are aware of our Privacy Policy. In the event such Business Partners do not have a privacy
                            policy equal to or greater in its restrictive nature as this Privacy Policy, we have asked
                            them to comply with this policy as it relates to any information that we share with them
                            about you.
                        </li>
                        <li>
                            With your Consent. We may disclose your personal information for any other purpose with your
                            consent.
                        </li>
                    </ul>
                </div>
            ),
        },
        {
            title: '4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?',
            content: (
                <div>
					<span>
						<b>Cookies, Web Beacons, and Other Tracking Tools: </b>
					</span>
                    <span>
						<u>Cookies:</u> A “cookie” is a small data file stored by your web browser on your computer’s
						hard drive. Cookies can either be “session cookies” or “persistent cookies”. Session cookies are
						temporary cookies that are stored on your device while you are visiting our Website or using our
						Service, whereas “persistent cookies” are stored on your device for a period of time after you
						leave our Website or Service. We use persistent cookies to store your preferences so that they
						are available for the next visit, and to keep a more accurate account of how often you visit our
						Service, and how your use of the Service varies over time. For more information on cookies,
						including how to control your cookie settings and preferences, visit{' '}
                        <a href='http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm' target={'_blank'}>
							http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm
						</a>
						,{' '}
                        <a href='https://ico.org.uk/for-the-public/online/cookies/' target='_blank'>
							https://ico.org.uk/for-the-public/online/cookies/
						</a>{' '}
                        and{' '}
                        <a href='http://www.allaboutcookies.org/' target='_blank'>
							http://www.allaboutcookies.org/
						</a>
						.
					</span>

                    <span>
						A cookie allows us to recognize your computer (but not specifically who is using it) upon
						entering our site by associating the identification numbers in the cookie with other customer
						information you have provided us. Should we elect to utilize cookies, customer information will
						be stored on our secured database. We may use cookies and similar tracking technologies (like
						web beacons and pixels) to access or store information. We may also use cookies to remember you
						when you return to the Sites as well as to identify the pages you click on during your visit to
						our Sites and, in some cases, the name of the website you visited immediately before clicking to
						the Table Group Websites. We use this information to improve our site design, customer service,
						and special promotions. You can, of course, disable cookies on your computer by indicating this
						in the preferences or options menus in your browser. However, it is possible that some parts of
						our Sites will not operate correctly if you disable cookies. We may contract with third parties
						who may use cookies and collect information on our behalf or provide services such as credit
						card processing, shipping, promotional services, or data management. These third parties are
						prohibited by our contract with them from sharing that information with anyone other than us or
						our other similar third party relationships.
					</span>

                    <span>
						<u>Web Beacons/Tracking Tools:</u> We also collect information about you, your device, and your
						use of the Sites through clear gifs (a.k.a. web beacons/web bugs) (“Web Beacons”), and other
						tracking tools and technological methods (collectively, “Tracking Tools”). Tracking Tools
						collect information such as computer or device operating system type, IP address, browser type,
						browser language, mobile device ID, device hardware type, the website or application visited or
						used before or after accessing our Service, the parts of the Service accessed, the length of
						time spent on a page or using a feature, and access times for a webpage or feature. These
						Tracking Tools help us learn more about our users and analyze how users use the Service, such as
						how often users visit our Service, what features they use, what pages they visit, what emails
						they open, and what other sites or applications they used prior to and after visiting the
						Service.
					</span>

                    <span>
						Web Beacons help us better manage content on our Service by informing us what content is
						effective. Web Beacons are embedded in, or otherwise associated with, certain emails or other
						communications that you receive from us. Web Beacons help us track your responses and interests
						and deliver relevant content and services to you. For example, they may let us know when you
						take actions based on the emails that we send.
					</span>
                </div>
            ),
        },
        {
            title: '5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?',
            content: (
                <div>
					<span>
						Please note that our Sites’ servers are located in the United States. If you are accessing our
						Sites from outside the United States, please be aware that your information may be transferred
						to, stored, and processed by us in our facilities and potentially by any third parties with whom
						we may share your personal information.
					</span>
                </div>
            ),
        },
        {
            title: '6. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?',
            content: (
                <div>
					<span>
						In short, we are not responsible for the safety of any information that you share with
						third-party providers who may advertise, but are not affiliated with, our websites. The Sites
						may contain advertisements from third parties that are not affiliated with us and which may link
						to other websites, online services or mobile applications. We cannot guarantee the safety and
						privacy of data you provide to any third parties. Any data collected by third parties is not
						covered by this privacy policy. We are not responsible for the content or privacy and security
						practices and policies of any third parties, including other websites, services or applications
						that may be linked to or from the Sites. You should review the policies of such third parties
						and contact them directly to respond to your questions.
					</span>
                </div>
            ),
        },
        {
            title: '7. HOW LONG DO WE KEEP YOUR INFORMATION?',
            content: (
                <div>
					<span>
						We will only keep your personal information for as long as it is necessary for the purposes set
						out in this privacy policy unless a longer retention period is required or permitted by law.
						More specifically, we may keep certain of your information indefinitely for purposes of future
						business consultation, aircraft discussions, or related reasons. When we have no ongoing
						legitimate business need to process your personal information, we may securely store your
						personal information and isolate it from any further processing. To be clear, this does not
						relate to general emails from you. It specifically relates to personal information obtained
						during our business relationship or during responses to certain inquiries you may have made.
					</span>
                </div>
            ),
        },
        {
            title: '8. HOW DO WE KEEP YOUR INFORMATION SAFE?',
            content: (
                <div>
					<span>
						We have implemented security measures such as firewalls and other means of blocking access to
						certain of your personal information. Please know that these measures are all designed to
						protect the security of personal information we access and process. However, please also
						remember that we cannot guarantee that the internet itself is 100% secure. Although we will do
						our best to protect your personal information, your transmission of personal information to and
						from our Sites is at your own risk. You should only access the Sites and services within a
						secure environment.
					</span>
                </div>
            ),
        },
        {
            title: '9. DO WE COLLECT INFORMATION FROM MINORS?',
            content: (
                <div>
					<span>
						We do not knowingly or intentionally solicit data from or market to children under 18 years of
						age. By using the Sites, you represent that you are at least 18 or that you are the parent or
						guardian of such a minor and consent to such minor dependent’s use of the Website. If we learn
						that personal information from users less than 18 years of age has been collected, we will
						deactivate the account and take reasonable measures to promptly delete such data from our
						records. If you become aware of any data, we have collected from children under age 18, please
						contact us at the email above.
					</span>
                </div>
            ),
        },
        {
            title: '10. WHAT ARE YOUR PRIVACY RIGHTS?',
            content: (
                <div>
					<span>
						We take reasonable measures in accordance with this privacy notice/policy to protect personal
						information that we may collect. If we are relying on your previously provided consent to
						process your personal information or you if subscribed to a marketing email, you have the right
						to withdraw your consent or to unsubscribe at any time. Please notify us in either case by
						sending a message to us at <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>.
					</span>

                    <span>
						<b>
							<u>Account Information</u>
						</b>
					</span>

                    <span>
						You may at any time review or change the information in your account or terminate your account
						by contacting us using the contact information provided at the end of this Privacy Policy. Upon
						your request to terminate any account that you may have with us, we will deactivate or delete
						your account and information from our active databases. However, some information may be
						retained in our files to prevent fraud, troubleshoot problems, assist with any investigations,
						enforce our Terms of Use and/or comply with legal requirements.
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            Cookies and similar technologies: Most web browsers are set to accept cookies by default. If
                            you prefer, you can usually choose to set your browser to remove cookies and to reject
                            cookies. If you choose to remove cookies or reject cookies, this could affect certain
                            features or services of our Sites.
                        </li>
                        <li>
                            Opting out of email marketing: If we provide the opportunity to subscribe to a marketing
                            email or the like from time-to-time and you subscribe, you can later unsubscribe from such
                            marketing email list at any time by contacting us using the details provided below. You will
                            then be removed from the marketing email list – however, we will still need to send you
                            service-related emails that are necessary for the administration and use of your account. As
                            mentioned, we may use your personal information to contact and correspond with you and to
                            respond to your inquiries. If you prefer not to receive marketing messages from us, please
                            let us know by clicking on the unsubscribe link within any marketing message that you
                            receive or by sending a message to us at{' '}
                            <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>.
                        </li>
                    </ul>

                    <span>
						{' '}
                        <b>
							<u>Your Privacy Choices</u>
						</b>
					</span>

                    <span>
						<b>Opting Out of Tracking Tools</b>
					</span>

                    <span>
						Service Provider Opt Out: You can opt-out directly from some providers of Tracking Tools by
						using their opt-out tools. Some of these service providers, and links to their opt-out tools,
						are:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            Google Analytics (GDPR compliant): with a privacy policy at{' '}
                            <a href='http://www.google.com/policies/privacy/partners/' target={'_blank'}>
                                http://www.google.com/policies/privacy/partners/
                            </a>{' '}
                            and opt out at{' '}
                            <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
                                https://tools.google.com/dlpage/gaoptout
                            </a>
                        </li>
                        <li>
                            Hotjar (GDPR compliant) : opt out at{' '}
                            <a href='https://www.hotjar.com/legal/compliance/opt-out' target='_blank'>
                                https://www.hotjar.com/legal/compliance/opt-out
                            </a>
                        </li>
                    </ul>

                    <span>
						<b>Industry Opt Out Tools:</b> Some Advertising Service Providers or providers of Tracking Tools
						may participate in the Network Advertising Initiatives (NAI) Opt-Out Tool ({' '}
                        <a href='http://www.networkadvertising.org/choices/' target='_blank'>
							http://www.networkadvertising.org/choices/
						</a>{' '}
                        ) and/or the Digital Advertising Alliance (DAA) Consumer Choice Page (
						<a href='http://www.aboutads.info/choices/' target='_blank'>
							http://www.aboutads.info/choices/
						</a>{' '}
                        ), and you can opt-out of certain services and learn more about your choices by visiting the
						links included there. Users in the EU can visit{' '}
                        <a href='http://www.youronlinechoices.eu/' target='_blank'>
							http://www.youronlinechoices.eu/
						</a>{' '}
                        for more information about your choices and to opt out of participating service providers.
					</span>

                    <span>
						<b>Web Browser Controls:</b> You can prevent the use of certain Tracking Tools, such as cookies,
						on a device-by-device basis using the controls in your web browser. These controls can be found
						in the Tools > Internet Options (or similar) menu for your browser, or as otherwise directed by
						your browser’s support feature. Through your web browser, you may be able to:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>Delete existing Tracking Tools</li>
                        <li>Disable future Tracking Tools</li>
                        <li>
                            Set your browser to provide you with a warning each time a cookie or certain other Tracking
                            Tools are being set
                        </li>
                    </ul>

                    <span>
						<b>Mobile Opt Out:</b> Your mobile devices may offer settings that enable you to make choices
						about the collection, use, or transfer of mobile app information for Behavioral Advertising. You
						may also opt-out of certain Tracking Tools on mobile devices by installing the DAA’s AppChoice
						app on your mobile device (for iTunes, visit{' '}
                        <a href='https://itunes.apple.com/us/app/appchoices/id894822870?mt=8' target='_blank'>
							https://itunes.apple.com/us/app/appchoices/id894822870?mt=8
						</a>
						, for Android, visit{' '}
                        <a
                            href='https://play.google.com/store/apps/details?id=com.DAA.appchoices&hl=en'
                            target='_blank'
                        >
							https://play.google.com/store/apps/details?id=com.DAA.appchoices&hl=en
						</a>
						). For more information, please visit{' '}
                        <a href='http://support.apple.com/kb/HT4228' target='_blank'>
							http://support.apple.com/kb/HT4228
						</a>
						,{' '}
                        <a href='https://support.google.com/ads/answer/2662922?hl=en' target='_blank'>
							https://support.google.com/ads/answer/2662922?hl=en
						</a>{' '}
                        or{' '}
                        <a
                            href='http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device'
                            target='_blank'
                        >
							http://www.applicationprivacy.org/expressing-your-behavioral-advertising-choices-on-a-mobile-device
						</a>
						, as applicable.
					</span>

                    <span>
						<b>Do Not Track:</b> Your browser may offer you a “Do Not Track” option, which allows you to
						signal to operators of websites and web applications and services (including behavioral
						advertising services) that you do not wish such operators to track certain of your online
						activities over time and across different websites. The Service does not support Do Not Track
						requests at this time, which means that we collect information about your online activity both
						while you are using the Service and after you leave our Service.
					</span>

                    <span>
						<b>Emails:</b> If you are currently receiving a newsletter or other marketing communications,
						you may opt-out at any time by clicking the unsubscribe link at the bottom of any of the emails
						you have received or may receive.
					</span>
                </div>
            ),
        },
        {
            title: '11.  WHAT RIGHTS AND CHOICES DO YOU HAVE REGARDING YOUR PERSONAL DATA?',
            content: (
                <div>
					<span>
						<b>Accessing, Correcting, and Deleting Your Personal Data and Other Data Subject Rights</b>
					</span>

                    <span>
						You have certain rights with respect to your Personal Data, and we want to help you review and
						update your information to ensure it is accurate and up-to-date. We may limit or reject your
						request in certain cases, such as if it is frivolous or extremely impractical, if it jeopardizes
						the rights of others, if it is not required by law, or if the burden or expense of providing
						access would be disproportionate to the risks to your privacy in the case in question. In some
						cases, we may also need you to provide us with additional information, which may include
						Personal Data, to verify your identity and the nature of your request. We will take reasonable
						steps to respond to all requests within 30 days (or less!).
					</span>

                    <span>
						For the following, please email us at{' '}
                        <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>:
					</span>

                    <span>
						<b>Withdrawal of Consent:</b> If we are processing your Personal Data based on your consent (as
						indicated at the time of collection of such data), you have the right to withdraw your consent
						at any time. Please note, however, that if you exercise this right, you may have to then provide
						express consent on a case-by-case basis for the use or disclosure of certain of your Personal
						Data, if such use or disclosure is necessary to enable you to utilize some or all of our
						Service.
					</span>

                    <span>
						<b>Objection:</b> You can contact us to let us know that you object to the further use or
						disclosure of your Personal Data for certain purposes, such as for marketing purposes.
					</span>

                    <span>
						<b>Restriction of Processing:</b> You can ask us to restrict further processing of your Personal
						Data.
					</span>

                    <span>
						<b>Access:</b> You can request more information about the Personal Data we hold about you and
						request a copy of such Personal Data.
					</span>

                    <span>
						<b>Rectification:</b> If you believe that any Personal Data we are holding about you is
						incorrect or incomplete, you can request that we correct or supplement such data.
					</span>

                    <span>
						<b>Erasure:</b> You can request that we erase some or all of your Personal Data from our
						systems. Please note that if you request the deletion of information required to provide the
						Service to you, your User Account will be deactivated, and you will lose access to the Service.
					</span>

                    <span>
						<b>Portability:</b> You can ask for a copy of your Personal Data in a machine-readable format.
						You can also request that we transmit the data to another controller where technically feasible.
					</span>
                </div>
            ),
        },
        {
            title: '12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?',
            content: (
                <div>
					<span>
						California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our
						users who are California residents to request and obtain from us, once a year and free of
						charge, information about categories of personal information (if any) we disclosed to third
						parties for direct marketing purposes and the names and addresses of all third parties with
						which we shared personal information in the immediately preceding calendar year. If you are a
						California resident and would like to make such a request, please submit your request in writing
						to us using the contact information provided below.
					</span>
                </div>
            ),
        },
        {
            title: '13.  CHILDREN AND SENSITIVE DATA',
            content: (
                <div>
					<span>
						<u>Children</u>
					</span>

                    <span>
						None of our services are intentionally directed at children under 16. We do not knowingly
						collect personal data from anyone under 16 years of age. If we determine upon collection that a
						Website or Sites visitor, a User or a Business Partner is under 16, we will not use or maintain
						his/her personal data. If we become aware that we have unknowingly collected personal data from
						a child under the age of 16, we will make reasonable efforts to delete such information from our
						records. If you’re a kid, please go play in the yard, don’t use or interact with Outbrain!
					</span>

                    <span>
						<u>Sensitive data</u>
					</span>

                    <span>We do not collect or receive any sensitive categories of personal data.</span>
                </div>
            ),
        },
        {
            title: '14. DO WE MAKE UPDATES TO THIS POLICY?',
            content: (
                <div>
					<span>
						We may update this privacy policy from time to time. Any updated version will be indicated by an
						updated “Revised” date and the updated version will be effective as soon as it is accessible,
						but we do not have a means of advising Users of an update by way of notice. If we make material
						changes to this privacy policy, we may notify you by prominently posting a notice of such
						changes. You should check back here periodically to see if the Privacy Policy has been updated
						as we will always show the date of the latest modification of the Privacy Policy at the top of
						the page so you can tell when it was last revised. We encourage you to review this Privacy
						Notice/Policy frequently to be informed of how we are protecting your information.
					</span>
                </div>
            ),
        },
        {
            title: '15. HOW CAN YOU CONTACT US ABOUT THIS POLICY?',
            content: (
                <div>
					<span>
						If you have questions or comments about us or our policies, email us at{' '}
                        <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a> or by phone or mail at:
					</span>

                    <span className={'flex flex-col'}>
						<i>The Table Group, Inc</i>
						<i>1211 Newell Ave, Suite 150</i>
						<i>Walnut Creek, CA 94596</i>
						<i>925-299-9700</i>
					</span>
                </div>
            ),
        },
    ],
};

export const termsOfServiceConstants = {
    hero: {
        header: 'Working Genius Terms of Service',
        subHeader: (
            <div className={'flex flex-col'}>
				<span>
					These Terms of Service ("Terms") govern your access to and use of The Table Group website and
					certification portal and use of our products and resources made available through the website,
					portal or other means (the “Services”). These Terms of Service apply to all users of the Services.
				</span>
                <span className={'pt-4'}>
					Please read these Terms carefully and contact us if you have any questions. By accessing or using
					the Service, you agree to be bound by these Terms and our Privacy Policy (click{' '}
                    <a
                        className={'text-white font-medium hover:text-blue-600 break-all'}
                        href='https://www.tablegroup.com/privacy'
                        target='_blank'
                    >
						https://www.tablegroup.com/privacy
					</a>{' '}
                    for our Privacy Policy).
				</span>
            </div>
        ),
    },
    content: [
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Terms of Service</span>,
            content: (
                <div>
					<span>
						<b>Acceptance of Terms of Service</b>. By registering for and/or using the Service in any
						manner, including visiting or browsing The Table Group Website, you agree to these Terms of
						Service, and all other operating rules, policies, and procedures that we may publish from time
						to time through the Service, each of which is incorporated by reference, and each of which may
						be updated from time to time without notice to you.
					</span>

                    <span>
						<b>
							<u>Arbitration notice and class action waiver</u>
						</b>
						: Except for certain types of disputes described in the arbitration section below, you agree
						that disputes between you and us will be resolved by binding, individual arbitration and you
						waive your right to participate in a class action lawsuit or class-wide arbitration.
					</span>

                    <span>
						<b>Eligibility</b>. You represent and warrant that you are at least 18 years of age. If you are
						under age 18, you may not, under any circumstances or for any reason, register for an Account
						(defined below) or use the Service. We may, in our sole discretion, refuse to offer the Service
						to any person or entity and change its eligibility criteria at any time. You are solely
						responsible for ensuring that these Terms of Service and your use of the Service (a) are in
						compliance with all laws, rules and regulations applicable to you and (b) do not violate any
						other agreement to which you are a party. The right to access the Service is revoked where these
						Terms of Service or use of the Service is prohibited or to the extent offering, sale or
						provision of the Service conflicts with any applicable law, rule or regulation. Further, the
						Service are offered only for your use, and not for the use or benefit of any third party.
					</span>

                    <span>
						<b>Registration and User Seats</b>. To sign up yourself or others for the Service, you must
						register for an account on the Service (an "Account"). You must provide accurate and complete
						information for your Account. For Services licensed on a per-seat or per-member basis, access
						will be limited to the number of Users (defined below) for whom you have purchased seats. Unless
						you have purchased a product that expressly licenses multiple User seats (e.g. The Meeting
						Advantage), each Account shall only be used by the individual user that registered such Account.
						You may never use another person’s user Account without permission from The Table Group, or
						share your Account or access credentials with any other individual or entity. You should never
						publish, distribute, or post login information for your Account. "User" means an individual (a
						natural human!) whom you authorize to use the Service. Users may include, for example, your
						employees, consultants, contractors, and agents, and third parties with which you transact
						business. If your account is connected to a product expressly allows multiple User seats, then
						you shall designate one User for each seat you purchase. A User’s access credentials may not be
						shared with any other individual or entity for purposes of using the Services. You are
						responsible for maintaining the security of your Account, password. You shall be responsible for
						acts, omissions, or breaches hereunder by any of your Users or any other individuals using your
						Account or credentials for the Services. You must keep your Account information updated, and you
						must notify us immediately of any change in your eligibility to use the Services or breach of
						security or unauthorized use of your Account. Users may have the ability to invite other users
						to their teams, unless the applicable Account settings restrict this functionality.
					</span>
                </div>
            ),
        },
        {
            title: <u>THE SERVICES</u>,
            content: (
                <div>
					<span>
						<b>Services</b>. Subject to these Terms of Service, The Table Group will use commercially
						reasonable efforts to make the Services available to you.
					</span>

                    <span>
						<b>Content</b>. "Content" means information, data, assessments, text, videos, written posts and
						comments, software, scripts, graphics, and interactive features generated, provided, or
						otherwise made accessible on or through the Services. As between the parties, all Content,
						except for Customer Data (as defined below), is owned by The Table Group or its licensors. You
						acknowledge that all Content accessed by you using the Services is at your own risk and you will
						be solely responsible for any damage or loss to you or any other party resulting therefrom.
					</span>

                    <span>
						<b>Restrictions on Use of the Services</b>. You will not directly or indirectly:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            sell, license, sublicense, distribute, copy, rent, use or lease the Services, or include the
                            Services in a service bureau, time-share outsourcing offering, or otherwise make the
                            Services available to, or use the Services for the benefit of any third party, on any third
                            party platform, or transfer any of the rights that you receive hereunder and in particular
                            when using one of our assessments or related tools;
                        </li>

                        <li>
                            interfere with or disrupt the integrity or performance of the Services or any third-party
                            data contained therein or attempt to gain unauthorized access to the Services or its related
                            systems or networks;
                        </li>

                        <li>
                            copy, modify, translate, or create derivative works based on the Services or any underlying
                            software or any part, feature, function, or user interface thereof, including by framing
                            (except as expressly allowed by The Table Group) or mirroring any part of any Services or
                            any Content;
                        </li>

                        <li>
                            access or use the Services for benchmarking or similar competitive analysis purposes or in
                            order to build a competitive product or service on learning and development platforms;
                        </li>

                        <li>
                            decompile, disassemble, decipher, or reverse engineer the Services, or otherwise attempt to
                            derive any source code or underlying ideas or algorithms of any part of the Services,
                            (except to the extent such restriction is prohibited by applicable statutory law);
                        </li>

                        <li>
                            remove any copyright notices, information, and restrictions contained in the Services or any
                            Content;
                        </li>

                        <li>
                            bypass, circumvent, or attempt to bypass or circumvent any measures we may use to prevent or
                            restrict access to the Services (or other accounts, computer systems or networks connected
                            to the Services) or take any action that imposes or may impose (as determined by us in our
                            sole discretion) an unreasonable or disproportionately large load on our (or our third-party
                            providers’) infrastructure;
                        </li>

                        <li>
                            use manual or automated software, devices, or other processes to "crawl" or "spider" any
                            page of the The Table Group Website or Services; or
                        </li>

                        <li>
                            otherwise take any action in violation of our guidelines and policies, including these Terms
                            of Service.
                        </li>
                    </ul>

                    <span>
						<b>Availability</b>. We are not and will not be responsible or liable for any failure in the
						Platform or Services resulting from or attributable to
					</span>

                    <span>(i) Customer Data or failures to deliver Customer Data to The Table Group;</span>

                    <span>
						(ii) failures in any telecommunications, network or other service or equipment outside of The
						Table Group's facilities; or{' '}
					</span>

                    <span>(iii) any force majeure or other cause beyond The Table Group's reasonable control.</span>

                    <span>
						We do not guarantee that the Services or any Content will be available, or that any Content that
						is available is or will continue to be accurate. We reserve the right, but do not have any
						obligation, to remove, edit, modify, or block access to any Content in our sole discretion, at
						any time, without notice to you and for any reason (including upon receipt of claims or
						allegations from third parties or authorities relating to such Content or if we are concerned
						that you may have violated these Terms of Service).
					</span>

                    <span>
						<b>Limits</b>. We may impose certain limits on the use of the Services, including without
						limitation the amount of Customer Data that may be stored, features that may be accessed, number
						of users, or other rate or usage limitations at any time in our sole discretion.
					</span>

                    <span>
						<b>Proprietary Rights</b>. As between the parties, all right, title and interest in and to the
						Services and Content, and all copies, modifications and derivative works thereof is and will
						remain the sole and exclusive property of The Table Group. Except as expressly set forth herein,
						no license or other right, title or interest in the Services or Content is granted by The Table
						Group to you.
					</span>

                    <span>
						<b>Customer Data</b>
					</span>

                    <span>
						"Customer Data" means any and all electronic data, Content, and information uploaded or
						submitted to the Services by you or by a third party on your behalf. You retain all of your
						rights to your Customer Data.
					</span>

                    <span>
						License to Customer Data. You hereby grant The Table Group a non-exclusive, worldwide,
						royalty-free, fully paid-up right and license to use, copy, access, process, reproduce, perform,
						display, modify, distribute, and transmit your Customer Data in connection with the Services.
						You acknowledge and agree that:
					</span>

                    <span>
						(i) the quality of the Services and the Platform depend on the uploading or other provisioning
						of the Customer Data into the Platform or the Services, as applicable and
					</span>

                    <span>
						(ii) The Table Group will not assume any responsibility for, or undertake to verify, the
						accuracy or completeness of the Customer Data entered by you.
					</span>

                    <span>
						<b>Aggregate Data</b>. Notwithstanding anything to the contrary set forth herein, The Table
						Group may collect and use data regarding the use and performance of the Service in anonymized
						and aggregated form, to analyze and improve the Service and for distribution in general
						benchmarking data and industry reports, provided that any reported user data is aggregated and
						anonymized such that no personally identifying information of Customer or its users is revealed.
					</span>

                    <span>
						<b>Representations and Warranties</b>. You represent, warrant, and covenant that
					</span>

                    <span>
						(i) all Customer Data is accurate and compliant with all applicable laws, rules, and
						regulations;
					</span>

                    <span>
						(ii) you own all rights, title, and interest in and to the Customer Data, or have otherwise
						secured all necessary rights in the Customer Data as may be necessary to permit the access, use,
						and distribution thereof as contemplated by these Terms of Service;
					</span>

                    <span>
						(iii) you shall only use the Services in accordance with all applicable laws, rules, and
						regulations, these Terms of Service and any relevant documentation provided by The Table Group
						and
					</span>

                    <span>
						(iv) you will not, and will not permit any third party to upload, download, post, submit,
						provide, transmit, distribute, or otherwise make available to or through the Services any
						Customer Data that
					</span>

                    <span>
						(A) is unlawful, infringing, deceptive, fraudulent, invasive of another’s privacy, tortious,
						obscene, or that otherwise violates any other right of any third party, including any
						intellectual property, proprietary, or privacy rights, or that is otherwise inappropriate, as
						determined by us in our sole discretion;
					</span>

                    <span>
						(B) contains any viruses, code, files, or programs designed or intended to disrupt, damage,
						limit, or interfere with the proper function of any software, hardware, or telecommunications
						equipment or that is or can be otherwise malicious or disruptive;
					</span>

                    <span>
						(C) constitutes unauthorized or unsolicited advertising, junk, or bulk email ("spamming") or
						otherwise violates federal CAN-SPAM regulation; or
					</span>

                    <span>
						(D) contains any personally identifying information subject to specialized security regimes
						including without limitation the Health Insurance Portability and Accountability Act ("HIPAA"),
						and the standards promulgated by the PCI Security Standards Council ("PCI"). You acknowledge and
						agree that The Table Group is not a "Business Associate" under HIPAA, and you will not provide
						any protected health information to The Table Group. You will not provide The Table Group with
						any personally identifying information except for User information as necessary to allow Users
						to access the Services as set forth in these Terms of Service. You are responsible for complying
						with CAN-SPAM, including without limitation for supplying an "Unsubscribe" link in any emails
						required to include such link.
					</span>

                    <span>
						<b>Third-Party Services</b>. The Services may permit you to link to other websites, services, or
						resources on the Internet, and other websites, services, or resources may contain links to the
						Services. For more information about third-party advertising, cookies, and opt-outs, please
						visit The Table Group’s privacy policy at{' '}
                        <a href='https://www.tablegroup.com/privacy' target='_blank'>
							https://www.tablegroup.com/privacy
						</a>
						. When you access third-party resources on the Internet, you do so at your own risk. These other
						resources are not under our control, and you acknowledge that we are not responsible or liable
						for the content, functions, accuracy, legality, appropriateness, or any other aspect of such
						websites or resources. The inclusion of any such link does not imply our endorsement or any
						association between us and their operators. You further acknowledge and agree that we shall not
						be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be
						caused by or in connection with the use of or reliance on any such content, goods or services
						available on or through any such website or resource. The Table Group will also not be
						responsible or liable for any failure in the Services attributable to your or any third party’s
						products, services, negligence, willful misconduct, breach of these Terms of Service, or other
						unauthorized access or use.
					</span>
                </div>
            ),
        },
        {
            title: <u>Payments and Billing.</u>,
            content: (
                <div>
					<span>
						Please note that any payment terms presented to you in the process of using or signing up for a
						Service are deemed part of these Terms of Services. All amounts are stated in, and shall be paid
						in, U.S. dollars. If you have any questions, contact:{' '}
                        <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>. We may revise the rates for
						Services at any time, or impose additional fees or charges.
					</span>

                    <span>
						<b>Billing</b>. We use a third-party payment processor (the "Payment Processor") to bill you
						through a payment account linked to your Account on the Services (your "Billing Account") for
						use of the Services. The processing of payments will be subject to the terms, conditions, and
						privacy policies of the Payment Processor in addition to these Terms of Service. We are not
						responsible for error by the Payment Processor. We reserve the right to correct any errors or
						mistakes that the Payment Processor makes even if the Payment Processor has already requested or
						received payment. The terms of your payment will be based on your Payment Method and may be
						determined by agreements between you and the financial institution, credit card issuer, or other
						provider of your chosen Payment Method. If we, through the Payment Processor, do not receive
						payment from you, you agree to pay all amounts due on your Billing Account upon demand.
					</span>

                    <span>
						<b>Chargeback Policy; Disputes</b>. If you have a question about charges made to your Account,
						please contact us immediately. If the charges were made in error, we will credit your Billing
						Account or Payment Method for the appropriate amount.
					</span>

                    <span>
						<b>Free Trials and Other Promotions</b>. Any free trial or other promotion that provides access
						to a Service must be used within the specified time of the trial.
					</span>

                    <span>
						<b>Feedback</b>. At all times The Table Group will have the unrestricted right to use and act
						upon any suggestions, ideas, enhancement requests, feedback and recommendations you provide
						specifically relating to the features, functionality and performance of the Services.
					</span>

                    <span>
						<b>Copyright Policy</b>. We have adopted the following general policy toward copyright
						infringement in accordance with the Digital Millennium Copyright Act ({' '}
                        <a href='http://lcweb.loc.gov/copyright/legislation/dmca.pdf' target='_blank'>
							http://lcweb.loc.gov/copyright/legislation/dmca.pdf
						</a>{' '}
                        ). The address of the Designated Agent to Receive Notification of Claimed Infringement
						("Designated Agent") is listed at the end of this policy.
					</span>
                </div>
            ),
        },
        {
            title: <u>Procedure for Reporting Copyright Infringement:</u>,
            content: (
                <div>
					<span>
						If you believe that material or content residing on or accessible through our websites,
						application, or services infringes a copyright, please send a notice of copyright infringement
						containing the following information to the Designated Agent listed below:
					</span>

                    <ul className={'px-8 privacy-list-items'}>
                        <li>
                            A physical or electronic signature of a person authorized to act on behalf of the owner of
                            the copyright that has been allegedly infringed;
                        </li>

                        <li>Identification of works or materials being infringed;</li>

                        <li>
                            Identification of the material that is claimed to be infringing including information
                            regarding the location of the infringing materials that the copyright owner seeks to have
                            removed, with sufficient detail so that we are capable of finding and verifying its
                            existence;
                        </li>

                        <li>
                            Contact information about the notifier including address, telephone number and, if
                            available, email address;
                        </li>

                        <li>
                            A statement that the notifier has a good faith belief that the material is not authorized by
                            the copyright owner, its agent, or the law; and
                        </li>

                        <li>
                            A statement made under penalty of perjury that the information provided is accurate and the
                            notifying party is authorized to make the complaint on behalf of the copyright owner.
                        </li>
                    </ul>

                    <span>
						Please contact the Designated Agent to Receive Notification of Claimed Infringement for The
						Table Group at: <a href='mailto:tracy@tablegroup.com'>tracy@tablegroup.com</a>.
					</span>

                    <span>
						<b>Confidentiality</b>. “Confidential Information” means all information disclosed by The Table
						Group to you, whether orally or in writing, that is designated as confidential or that
						reasonably should be understood to be confidential given the nature of the information and the
						circumstances of disclosure. The Table Group's Confidential Information includes the Services
						and Content, as well as business and marketing plans, technology and technical information,
						product plans and designs, source code and business processes disclosed by The Table Group. You
						will use a reasonable degree of care to protect the Confidential Information. You will not use
						any Confidential Information for any purpose outside the scope of these Terms of Service or
						disclose Confidential Information to any third party. You may disclose Confidential Information
						to the extent compelled by law, provided you give The Table Group prior notice of the compelled
						disclosure (to the extent legally permitted) and reasonable assistance, at The Table Group's
						cost, if The Table Group wishes to contest the disclosure.
					</span>

                    <span>
						<b>Data Security</b>. The Table Group shall maintain appropriate administrative, physical, and
						technical safeguards designed to protect the security of the Services and Customer Data.
					</span>
                </div>
            ),
        },
        {
            title: <u>Termination.</u>,
            content: (
                <div>
					<span>
						<b>Termination by Either Party</b>. You have the option of canceling your Account at any time by
						following the instructions on The Table Group Website or through the Services or by contacting
						us at <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>. We reserve the right
						to terminate your Account or access to all or any part of the Services at any time, with or
						without cause, with or without notice, effective immediately. All provisions of these Terms of
						Service which by their nature should survive termination or expiration shall survive
						termination, including provisions regarding ownership, aggregate data use, payment (to the
						extent any payment obligations remain outstanding), warranty disclaimers, indemnity and
						limitations of liability.
					</span>

                    <span>
						<b>Customer Data After Termination</b>. Termination or cancellation of your Account by either
						party may result in the forfeiture and destruction of all information and data, including
						Customer Data, associated with your Account. Termination, cancellation, or expiration of your
						Account will result in a loss of your Customer Data; we may delete or destroy all copies of your
						Customer Data in our systems or otherwise in our possession or control, unless legally
						prohibited. We reserve the right, including after termination, to access, read, preserve, and
						disclose any information, including without limitation Customer Data, as we reasonably believe
						is necessary to (i) satisfy any applicable law, regulation, legal process or governmental
						request; (ii) enforce these Terms of Service, including investigation of potential violations
						hereof; (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv)
						respond to user support requests or (v) protect the rights, property or safety of us, our users
						and the public.
					</span>

                    <span>
						<b>Warranty Disclaimer.</b>
					</span>

                    <span>
						You release us from all liability for you having acquired or not acquired Content through the
						Services. We make no representations concerning any Content contained in or accessed through the
						Services, and we will not be responsible or liable for the accuracy, copyright compliance, or
						legality of material or Content contained in or accessed through the Services.
					</span>

                    <span>
						<b>
							THE SERVICES AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY
							KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
							NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
							IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
							DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT
							PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY
							PARTICULAR TIME OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT
							OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL
							COMPONENTS; OR (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE
							OF THE SERVICES IS SOLELY AT YOUR OWN RISK.
						</b>
					</span>

                    <span>
						You shall and hereby do waive California Civil Code Section 1542 or any other similar law of any
						jurisdiction, which says in substance: "A general release does not extend to claims which the
						creditor does not know or suspect to exist in his favor at the time of executing the release,
						which, if known by him must have materially affected his settlement with the debtor".
					</span>

                    <span>
						Some jurisdictions do not allow the exclusion of implied warranties, so the above exclusion may
						not apply to you. You may have other rights which vary from jurisdiction to jurisdiction.
					</span>

                    <span>
						<b>Indemnification</b>. You are solely responsible for your conduct and your data related to the
						Service. You shall defend, indemnify, and hold harmless us, our affiliates and each of our and
						their respective employees, contractors, directors, suppliers and representatives from all
						liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or
						relate to your use or misuse of, or access to, the Services, Content, or otherwise from your
						Customer Data, violation of these Terms of Service, or infringement by you, or any third party
						using your Account or identity in the Services, of any intellectual property or other right of
						any person or entity. We reserve the right to assume the exclusive defense and control of any
						matter otherwise subject to indemnification by you, in which event you will assist and cooperate
						with us in asserting any available defenses.
					</span>

                    <span>
						<b>
							Limitation of Liability. IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS,
							PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY,
							NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES (A) FOR ANY
							LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL,
							INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER
							(HOWEVER ARISING); (B) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE
							SOURCE OF ORIGINATION); OR (C) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE
							GREATER OF (I) FEES PAID TO US FOR THE PARTICULAR SERVICES DURING THE IMMEDIATELY PREVIOUS
							THREE (3) MONTH PERIOD OR (II) $500.00.
						</b>
					</span>

                    <span>
						<b>Governing Law and Jurisdiction</b>. These Terms of Service shall be governed by and construed
						in accordance with the laws of the State of California, including its conflicts of law rules,
						and the United States of America. You agree that any dispute arising from or relating to the
						subject matter of these Terms of Service shall be resolved in Contra Costa County, California.
					</span>

                    <span>
						<b>Modification</b>. We reserve the right, in our sole discretion, to modify or replace any of
						these Terms of Service, or change, suspend, or discontinue the Services (including without
						limitation, the availability of any feature, database, or content), with or without notice. If
						we make material changes to these Terms of Service that negatively affect you, or materially
						reduce the Services, we will notify you by posting a notice or new version of these Terms of
						Service on the Site or Service, or by sending you notice through the Services, via email or by
						another appropriate means of electronic communication. We may also impose limits on certain
						features and services or restrict your access to parts or all of the Services without notice or
						liability. While we will timely provide notice of material modifications, it is also your
						responsibility to check these Terms of Service periodically for changes. Your continued use of
						the Services following notification of any changes to these Terms of Service constitutes
						acceptance of those changes, which will apply to your continued use of the Services going
						forward. Your use of the Services is subject to the Terms of Service in effect at the time of
						such use.
					</span>
                </div>
            ),
        },
        {
            title: <u>Miscellaneous.</u>,
            content: (
                <div>
					<span>
						<b>Entire Agreement and Severability</b>. These Terms of Service are the entire agreement
						between you and us with respect to the Services, including use of the Site, and supersede all
						prior or contemporaneous communications and proposals (whether oral, written or electronic)
						between you and us with respect to the Services. If any provision of these Terms of Service is
						found to be unenforceable or invalid, that provision will be limited or eliminated to the
						minimum extent necessary so that these Terms of Service will otherwise remain in full force and
						effect and enforceable. The failure of The Table Group to exercise in any respect any right
						provided for herein shall not be deemed a waiver of any further rights hereunder.
					</span>

                    <span>
						<b>Force Majeure</b>. We shall not be liable for any failure to perform our obligations
						hereunder where such failure results from any cause beyond our reasonable control, including,
						without limitation, mechanical, electronic or communications failure or degradation.
					</span>

                    <span>
						<b>Subcontractors</b>. We use subcontractors to provide certain aspects of the Services and in
						some cases permit them to access Customer Data subject to appropriate obligations of security,
						confidentiality and compliance with applicable laws. We remain responsible for our
						subcontractors’ compliance with these Terms of Service with respect to their services provided
						hereunder.
					</span>

                    <span>
						<b>Assignment</b>. These Terms of Service are personal to you, and are not assignable,
						transferable or sublicensable by you except with our prior written consent. We may assign,
						transfer or delegate any of our rights and obligations hereunder without consent.
					</span>

                    <span>
						<b>Agency</b>. No agency, partnership, joint venture, or employment relationship is created as a
						result of these Terms of Service and neither party has any authority of any kind to bind the
						other in any respect.
					</span>

                    <span>
						<b>Notices</b>. Unless otherwise specified in these Term of Service, all notices under these
						Terms of Service will be in writing and will be deemed to have been duly given when received, if
						personally delivered or sent by certified or registered mail, return receipt requested; when
						receipt is electronically confirmed, if transmitted by facsimile or email; or the day after it
						is sent, if sent for next day delivery by recognized overnight delivery service. Electronic
						notices should be sent to <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>.
						You acknowledge and agree that The Table Group may occasionally send you communications
						regarding your account or the Services via email or by posting on the Services. You acknowledge
						and agrees that The Table Group may occasionally send notices to you regarding your Account or
						the Services via email or via the Services.
					</span>

                    <span>
						<b>No Waiver</b>. Our failure to enforce any part of these Terms of Service shall not constitute
						a waiver of our right to later enforce that or any other part of these Terms of Service. Waiver
						of compliance in any particular instance does not mean that we will waive compliance in the
						future. In order for any waiver of compliance with these Terms of Service to be binding, we must
						provide you with written notice of such waiver through one of our authorized representatives.
					</span>

                    <span>
						<b>Open Source</b>. The Service may contain or be distributed with open source software or other
						third-party software which may be covered by a different license. Notwithstanding anything to
						the contrary, the obligations of The Table Group set forth in these Terms of Service do not
						extend to any open source software or such other third-party software which may be made
						available by The Table Group, or otherwise obtained or used by you. You agree that all open
						source software or such other third-party software shall be and shall remain subject to the
						terms and conditions under which it is provided, and you shall be responsible for compliance
						with such terms. To the fullest extent possible, The Table Group disclaims all warranties and
						liability regarding such open source and third-party software in accordance with the terms of
						these Terms of Service.
					</span>

                    <span>
						<b>Federal Government End Use Provisions</b>. We provide the Services, including related
						software and technology, for ultimate federal government end use solely in accordance with the
						following: government technical data and software rights related to the Services include only
						those rights customarily provided to the public as defined in this Agreement. This customary
						commercial license is provided in accordance with FAR 12.211 (Technical Data) and FAR 12.212
						(Software) and, for Department of Defense transactions, DFAR 252.227-7015 (Technical Data
						Commercial Items) and DFAR 227.7202-3 (Rights in Commercial Computer Software or Computer
						Software Documentation). If a government agency has a need for rights not granted under these
						terms, it must negotiate with The Table Group to determine if there are acceptable terms for
						granting those rights, and a mutually acceptable written addendum specifically granting those
						rights must be included in any applicable agreement.
					</span>

                    <span>
						<b>Headings; Interpretation</b>. The section and paragraph headings in these Terms of Service
						are for convenience only and shall not affect their interpretation. Any use of "including" "for
						example" or "such as" in these Terms of Service shall be read as being followed by "without
						limitation" where appropriate.
					</span>

                    <span>
						Any claim related to these Terms of Service, the Site(s) or the Service must be brought within
						one year. The one-year period begins on the date when the claim first could be filed. If it is
						not filed, then that claim is permanently barred. This applies to you and your successors.
					</span>

                    <span>
						The parties agree that the United Nations Convention on Contracts for the International Sale of
						Goods is specifically excluded from application to these Terms of Service.
					</span>

                    <span>
						Contact. You may contact us at the following address: The Table Group Inc. at{'  '}
                        <a href='mailto:support@tablegroup.com'>support@tablegroup.com</a>.
					</span>
                </div>
            ),
        },
    ],
};

export const APITermsOfServiceConstants = {
    hero: {
        header: 'Working Genius API Terms of Use',
        subHeader: (
            <div className={'flex flex-col'}>
				<span>
					Thank you for using The Table Group’s publicly available application program interfaces (“APIs”).
                    These API Terms of Use (the “API Terms“) describe your rights and responsibilities when accessing our APIs and related <b>API documentation</b>.
                    By accessing our APIs and API documentation, you agree to these API Terms and our{' '}
                    <a
                        className={'text-white font-medium hover:text-blue-600 break-all'}
                        href='https://www.workinggenius.com/policy/terms-of-service'
                        target='_blank'
                    >
						Terms of Service
					</a>. These API Terms form a part of our{' '}
                    <a
                        className={'text-white font-medium hover:text-blue-600 break-all'}
                        href='https://www.workinggenius.com/policy/terms-of-service'
                        target='_blank'
                    >
						Terms of Service
					</a>.
				</span>
                <span className={'pt-4'}>
					Please read these API terms carefully. if you do not agree to these API terms, you are not authorized to access our APIs.
                    if you have any questions or concerns about the terms of these API terms, please contact us at{' '}
                    <a
                        className={'text-white font-medium hover:text-blue-600 break-all'}
                        href='mailto:support@workinggenius.com'
                        target='_blank'
                    >support@workinggenius.com
					</a>.
				</span>
                <span className={'pt-4'}>
					Under these API Terms, “The Table Group” means The Table Group, Inc.,
                    The Six Types of Working Genius, The 6 Types of Working Genius, Working Genius,
                    and we may refer to The Table Group as “we,” “our,” or “us.”
				</span>
            </div>
        ),
    },
    content: [
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Account/Registration</span>,
            content: (
                <div>
					<span>
						You may not use our APIs and may not accept these API Terms if
                        (a) you are not of legal age to form a binding contract or
                        (b) you are a person barred from using or receiving the APIs under the applicable laws of the United States or other countries.
                        If you are using the APIs on behalf of an entity, you represent and warrant that you have the authority to bind that entity to these API Terms,
                        you are accepting these API Terms on behalf of that entity, and all references to “you” in these API Terms refer to that entity.
                        In order to access certain APIs you may be required to register and provide certain information about yourself as part of the registration
                        process or as part of your continued use of the APIs. You may not make credentials available to others including by embedding them in
                        open-source projects and you may only access the APIs with the credentials provided to you.
                        You may not misrepresent or mask your identity or your credentials when accessing or using the APIs.
                        All registration information you provide must be accurate and up to date at all times. You will inform us promptly of any updates.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Access to our APIs</span>,
            content: (
                <div>
					<span>
						Subject to the restrictions below, we grant you a limited, non-exclusive, non-sublicensable,
                        non-assignable, non-transferable, revocable license to access our APIs and
                        {' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='/docs-api'
                            target='_blank'
                        >
						<b>API documentation</b>
					    </a>{' '}
                        for sole purposes of developing,
                        testing and supporting software, services, or modules that connect to products, software, websites and/or services offered
                        by The Table Group (the “Offerings”) or have access to the data within our Offerings (an “Integration”).
                        You may charge for your Integration; however, you may not sell, rent, lease, sublicense, redistribute, or syndicate access to any of our APIs.
                        We may terminate your access to our APIs at any time for any reason.
					</span>

                    <span>
						You will comply with all applicable laws, regulations, and third-party rights (including without limitation laws regarding the import or export of data or software, privacy, and local laws).
                        You will not use our APIs to
                        (a) encourage or promote illegal activity or violation of third-party rights;
                        (b) modify or make derivative works based upon the APIs;
                        (c) improperly use the APIs, including creating Internet “links” to the APIs, “framing” or “mirroring” any part of the APIs on any other websites or systems, or “scraping” or otherwise improperly obtaining data from the APIs;
                        (d) reverse engineer, decompile, modify, or disassemble the APIs;
                        (e) send spam or otherwise duplicative or unsolicited messages with the APIs;
                        (f) use the APIs to (x) display any offensive content or any content for which you do not have the right to share with The Table Group or to display or
                        (y) distribute unsolicited advertising or promotions, or initiate any other communication or contact with
                        The Table Group users or partners or (g) violate our{' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='https://www.workinggenius.com/policy/terms-of-service'
                            target='_blank'
                        >Terms of Service
					    </a>{' '} or our{' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='https://www.workinggenius.com/policy/privacy-policy'
                            target='_blank'
                        >Privacy Policy
					    </a>{' '}.
					</span>

                    <span>
						In addition, you shall not, and shall not direct, encourage, or assist any other party to, access or use the APIs to:
                        (i) design or develop a competitive or substantially similar product or service;
                        (ii) copy or extract any features or functionality thereof;
                        (iii) launch or cause to be launched on or in connection with the APIs a malicious automated program or script, including web spiders, crawlers, robots, indexers, bots, viruses or worms, or any program intended to overburden or hinder the operation and/or performance of the APIs;
                        (iv) attempt to gain unauthorized access to the APIs or its related systems or networks;
                        (v) include any underlying Offering with competitors in any aggregated view i.e. webpage, app, software, etc.;
                        (vi) aggregate The Table Group’s data with competitors’ data;or
                        (vii) parse or scrape any of The Table Group’s data; in each case other than as explicitly permitted by The Table Group in writing.
					</span>

                    <span>
						You will only access (or attempt to access) our APIs by the means described in our API documentation.
                        We may set and enforce limits on your use of our APIs (e.g. limiting the number of API requests that you may make) in our sole discretion.
                        You agree not to circumvent any limitations documented with our APIs. We may monitor your use of our APIs for reasons including to improve our APIs and for compliance with these API Terms.
                        We may suspend your access to our APIs without notice if we reasonably believe that you are in violation of these API Terms.
                        The Table Group may collect and use usage data and information related to your use of the APIs.
                        These API Terms are non-exclusive. You agree not to access our APIs in order to compete with our Offerings.
					</span>

                    <span>
						If you offer your application for use by others outside of your entity, you must maintain a user agreement and a
                        legally compliant privacy policy for your application that is prominently identified or located where users download or access your application.
                        You must immediately notify us in writing of any breach of your user agreement or privacy policy that impacts, or may impact, customers or users of our Offerings.
					</span>

                    <span>
						You are solely responsible for all costs incurred by you in the creation and maintenance of the Integrations.
					</span>

                    <span>
						You are solely responsible for selecting all content made available through and contained in the Integrations and
                        for ensuring that such content complies with the API Terms and our{' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='https://www.workinggenius.com/policy/terms-of-service'
                            target='_blank'
                        >Terms of Service
					    </a>{' '} and any other requirements applicable to such content.
                        You are fully responsible for any information you provide to The Table Group via the APIs.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Privacy and Security</span>,
            content: (
                <div>
					<span>
						You agree to comply with all applicable privacy laws and regulations including those applying to personally identifiable information (“PII”).
                        You agree to provide and adhere to a privacy policy for your Integrations that clearly and accurately describes to users of your Integrations what user information you collect and how you use and share such information with us and third parties. You agree to obtain express permission from users before you access their Table Group accounts.
                        You agree to immediately delete a user’s data if the user requests deletion or terminates their account with you.
					</span>

                    <span>
						You agree to implement and maintain appropriate technical and organizational security measures designed to
                        protect all user data, including PII, from unauthorized access, use loss, destruction, damage, alteration, or disclosure in the same manner that you would protect your own confidential and proprietary information
                        but in no event using less than a reasonable degree of care and you agree to notify both us and your users of any unauthorized access or use of such information to the extent required under applicable law.
                        In the event of a security deficiency or incident, outage, or any other event that may impact or compromise our users, APIs, or Service, you agree to work with us immediately to assess and remedy the situation.
                        You also agree to coordinate with us on any public statements before publication.
					</span>

                    <span>
						If your use of the APIs requires or will likely result in the provision of personal information directly to
                        The Table Group, you agree to adequately inform and obtain all necessary consents and authorizations from the applicable users to provide such personal information
                        to The Table Group and retain written records of such consents.
					</span>

                    <span>
						We reserve the right to audit, or to appoint an independent auditor under appropriate non-disclosure conditions to audit, your Integration,
                        systems, and records to confirm your compliance with these API Terms.
                        If requested, you must provide us with proof that your Integration complies with these API Terms.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Ownership and Proprietary Rights</span>,
            content: (
                <div>
					<span>
						The Table Group owns all rights, titled, and interested, including without limitation, all intellectual property rights and other rights in and to its Offerings,
                        the APIs, any intellectual property rights used in connection with the Offerings or APIs, and other proprietary technology,
                        including any data structures therein, accompanying documentation, and any updates or revisions to the foregoing.
					</span>

                    <span>
						You retain your ownership rights in your Integrations.
                        All of our rights not expressly granted by these API Terms are hereby retained.
					</span>

                    <span>
						If you provide us with feedback or suggestions about our APIs (“Feedback”), then we may use such information and you grant us an unlimited,
                        irrevocable, perpetual, sublicensable, transferable,
                        royalty-free license to use any such Feedback for any purpose without any obligation or compensation to you.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Trademarks</span>,
            content: (
                <div>
					<span>
						You may not use The Table Group brand or marks outside of what is expressed in our{' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='https://www.dropbox.com/scl/fi/oygk9pukk96rbjf66eiz2/Working-Genius-Style-Guide-2023-Spreads.pdf?rlkey=k2l45033d5bcfhzb91nihz7bw&dl=0'
                            target='_blank'
                        >
						Branding Guide
					    </a>{' '}.
                        You may not alter or remove any proprietary notices in our marks. You may not use The Table Group brand in any way that suggests that
                        The Table Group sponsors, endorses, or associates with your Integration without prior written consent.
					</span>

                    <span>
						You grant us the right to (1) produce and distribute incidental depictions, including screenshots, videos,
                        or other content from your Integrations and (2) use your company name and logo for marketing or promotional purposes on our website and in other public or private communications with our existing or potential developers and customers;
                        you hereby grant us all necessary licenses, on a royalty-free basis, for these purposes.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Updates</span>,
            content: (
                <div>
					<span>
						Our APIs may evolve and change over time. While we do our best to notify developers of major changes in advance,
                        we reserve the right to modify our APIs, cease to offer support for our APIs, or require you to use our APIs in a different manner at any time without notice.
                        We may update these API Terms from time to time by posting an updated version to {' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='http://www.workinggenius.com/terms-api'
                            target='_blank'
                        >
						http://www.workinggenius.com/terms-api
					    </a>{' '} and any such updates will be effective upon posting.
                        When we update these API Terms, the “Updated” date above will be updated to reflect the date of the most recent version.
                        Your continued use of our APIs constitutes your acceptance of the modified API Terms.
                        We encourage you to review these API Terms regularly.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Confidentiality</span>,
            content: (
                <div>
					<span>
						You may be given access to certain non-public information, software, and specifications relating to our APIs (“Confidential Information“), which may include your credentials as well as any materials,
                        communications, or other information that is marked confidential or that would reasonably be considered confidential under the circumstances. You may use Confidential Information only as necessary in exercising your rights granted under these API Terms. You may not disclose any Confidential Information to any third party without our prior written consent. You agree that you will protect any Confidential Information from unauthorized use, access,
                        or disclosure in the same manner that you would use to protect your own confidential and proprietary information.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Representations</span>,
            content: (
                <div>
					<span>
						You hereby represent and warrant that you have all authority necessary to bind yourself (including your entity) to
                        these API Terms and that you are not prohibited from accessing or using
                        the APIs in the country in which you reside.
					</span>
                    <span>
						You further represent and warrant that
                        <ul>
                            <li>(a) you have the right to distribute, or otherwise make available the Integrations to your end users,</li>
                            <li>(b) such Integrations comply with all applicable local, state, federal and international laws and regulations as well as applicable terms of service and privacy notices and</li>
                            <li>(c) the Integrations do not and will not infringe the rights of any third party.</li>
                        </ul>
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Termination</span>,
            content: (
                <div>
					<span>
                        You may stop using our APIs at any time with or without notice.
                        We may terminate these API Terms with or without cause, and without notice to you. Upon termination of these API Terms, all rights and licenses granted to you will terminate immediately. You understand that any APIs or documentation that are not made generally
                        available but that are otherwise made available to you are the confidential information of The Table Group.
					</span>
                    <span>
						We reserve the right to revoke your access to the APIs without prior notice if we determine your use violates
                        the API Terms or our{' '}
                        <a
                            className={'text-white font-medium hover:text-blue-600 break-all'}
                            href='https://www.workinggenius.com/policy/terms-of-service'
                            target='_blank'
                        >
						Terms of Service
					    </a>{' '} or is otherwise objectionable as determined in our sole discretion.
					</span>
                    <span>
						Upon any termination of these API Terms or discontinuation of your access to our APIs,
                        you will immediately stop using our APIs, cease all use of the Table Group brand and marks,
                        and destroy copies of any documentation and any other The Table Group information in your
                        possession or control that was received under these API Terms. When these API Terms come to an end,
                        those sections that by their nature are intended to continue indefinitely will continue to apply,
                        including but not limited to the sections titled “Privacy and Security,” “Ownership and Proprietary Rights,” “Confidentiality,”
                        “Representations,” “Termination,” “Warranties and Limitation of Liability,” “Indemnification,” and “General Provisions.”
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Warranties and Limitation of Liability</span>,
            content: (
                <div>
					<span>
                        Except as expressly provided for herein, the APIs, documentation, and all
                        related components and information are provided by us on an “as is” and “as available” basis without any warranties
                        of any kind, and we expressly disclaim any and all warranties, whether express or implied, including the
                        implied warranties of merchantability, title, fitness for a particular purpose and non-infringement.
                        You acknowledge that we do not warrant that the APIs will be uninterrupted, timely, complete,
                        free of viruses or other harmful components, secure, or error-free.
					</span>
                    <span>
						To the maximum extent permitted by law, in no event will The Table Group or any of its service providers,
                        suppliers or representatives (including officers or directors) be liable for any lost profits, revenues, or data;
                        financial losses; or indirect, special, consequential, exemplary, or punitive damages. these limitations of
                        liability apply regardless of the nature of any claim, whether based on warranty, contract, tort, or any other
                        legal or equitable theory, and whether or not The Table Group is advised of the possibility of such damages.
					</span>
                    <span>
						To the maximum extent permitted by law, in no event will our aggregate liability arising out of or related to these API terms
                        (whether in contract or tort or under any other theory of liability) exceed $100.
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>Indemnification</span>,
            content: (
                <div>
					<span>
                        You agree to indemnify, defend and hold The Table Group and its officers, directors, employees,
                        and agents harmless, at your expense, against any and all third-party claims, suits, actions,
                        or proceedings (each, an “Action“) to the extent that such Action is based upon or arises out of:
                        <ul>
                            <li>(a) your use of our APIs;</li>
                            <li>(b) your violation of these API Terms, or</li>
                            <li>(c) an allegation that your Integration violates or misappropriates any copyright, trademark, service mark, trade secret or patent of a third party.</li>
                        </ul>
					</span>
                </div>
            ),
        },
        {
            title: <span className={'text-primary text-3xl md:text-5xl font-medium pb-5'}>General Provisions</span>,
            content: (
                <div>
					<span>
                        These API Terms do not create a partnership, franchise, joint venture, agency, fiduciary,
                        or employment relationship between the parties.
                        There are no third-party beneficiaries to these API Terms.
					</span>
                    <span>
                        Under no circumstances will we or our suppliers be held liable for any delay or failure in performance
                        resulting directly or indirectly from acts of nature, forces, or causes beyond our reasonable control,
                        including, without limitation, internet failures, computer equipment failures, epidemics, telecommunication
                        equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots,
                        insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of God, war,
                        governmental actions, orders of domestic or foreign courts or tribunals, or non-performance of third parties.
					</span>
                    <span>
                        These API Terms and any other The Table Group terms referenced herein state the complete agreement
                        of the parties with respect to the subject matter of these API Terms and supersede any past or contemporaneous statements,
                        contracts, agreements, or understandings between the parties and may be modified only as provided in the “Updates” section above.
                        These API Terms are personal to you, and you may not transfer, assign, or delegate your rights and/or duties under these API Terms to anyone else and any attempted assignment or delegation is void.
                        These API Terms shall be governed by and interpreted in accordance with the laws of the state of California, excluding its conflict of laws provisions.
                        Each party hereby waives any right to a jury trial in connection with any action or litigation in any way arising out of or related to these API Terms.
                        If any provision of these API Terms is judged illegal, invalid, or otherwise unenforceable, that provision shall be severed and the rest of these API Terms shall remain in full force and effect.
                        No failure by us to enforce any of our rights related to the Offerings or to a breach of these
                        API Terms in a particular situation will act as a waiver of such rights.
                        To the extent of any conflict or inconsistency between the provisions in
                        these API Terms and any other documents or pages referenced in these API Terms, the following order of precedence will apply:
                        <ul>
                            <li>(A) these API Terms,</li>
                            <li>(B) our {' '} <a
                                className={'text-white font-medium hover:text-blue-600 break-all'}
                                href='https://www.workinggenius.com/policy/terms-of-service'
                                target='_blank'
                            >
                                    Terms of Service
                                    </a>{' '}, and</li>
                            <li> (C) any other documents or pages referenced in these API Terms.</li>
                        </ul>
					</span>
                </div>
            ),
        },
    ],
};