import React from 'react';
import AppButton from '../AppButton';
import { widget_hr } from '../../images';
import RegisterForm from './RegisterForm';
import UtilityService from '../../services/UtilityService';

const LaunchEventHero = () => {
	const items = ['work.', 'team.', 'life.'];
	const [index, setIndex] = React.useState(0);
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
	const itemRef = React.useRef();

	const shuffle = () => {
		const newIndex = (index + 1) % 3;
		setIndex(newIndex);
		itemRef.current.animate(
			{
				opacity: [0, 1, 0],
			},
			3000
		);
	};

	React.useEffect(() => {
		const intervalID = setInterval(shuffle, 3000);

		if (UtilityService.isEventLiveDatePassed()) {
			window.location.href = '/booklaunchevent/';
		}
		return () => clearInterval(intervalID);
	}, [shuffle]);

	return (
		<div className='launch-event-hero-section'>
			<div className='container'>
				<div className='launch-event-hero-container flex flex-col items-center justify-center text-center'>
					<h1 className='text-2xl lg:text-5xl text-white font-normal'>
						Discover your gifts. <br />
						Transform your{' '}
						<span ref={itemRef} style={{ opacity: 1 }} className='hero-changing-word'>
							{items[index]}
						</span>
					</h1>
					<img className={'w-1/2 my-8'} src={widget_hr} alt='' />
					<h1 className='text-2xl lg:text-5xl text-white font-normal'>
						The Working Genius <span className='font-semibold'>Book Launch Event</span>
					</h1>
					<p className='text-white text-xl font-semibold tracking-widest'>
						Premieres Sept 27th at 12PM PT / 3PM ET
					</p>
					<div className='flex flex-col justify-center my-8'>
						<AppButton
							className={'btn btn-primary w-56'}
							onClick={() => setOpenRegisterModal(true)}
							text={'Register now'}
							id={'wg-launch-event-register-now-btn'}
						/>
						<p className='text-white text-lg uppercase font-semibold mt-4 tracking-widest'>(IT'S FREE)</p>
					</div>
				</div>
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default LaunchEventHero;
