import React from 'react';

const headerContent = {
	title: 'Preparing for your Working Genius Workshop',
	description:
		'The workshop will be a fast-paced and practical dive into increased team and cohesion and productivity. Completing the following steps will help maximize your time together.',
};
const PreWorkHeroSection = () => {
	return (
		<div className={'pre-work-hero'}>
			<div className={'pre-work-container whitespace-pre-wrap float-left'}>
				<div className='pre-work-title w-7/12'>{headerContent.title}</div>
				<div className='pre-work-description'>{headerContent.description}</div>
			</div>
		</div>
	);
};

export default PreWorkHeroSection;
