import React from 'react';
import icons from '../../../constants/PostIcons';
import { povlarge } from '../../../images';

const ResourceSlide = ({ item, onClick }) => {
	return (
		<div className={'bg-white w-full h-full p-4 pb-8 cursor-pointer'} onClick={onClick}>
			<div className='image-container h-48 relative'>
				<span
					className={'absolute left-0 z-50 bg-white text-tiny px-2 py-1 w-20 text-center text-primary'}
					style={{ top: '15px' }}
				>
					{item.guid}
				</span>
				<div
					className={'absolute bg-cover bg-no-repeat z-30 w-full h-full'}
					style={{ backgroundColor: 'rgba(9, 33, 74, .65)' }}
				/>
				<img
					className={`${
						item.use_default_img ? 'object-cover ' : 'object-contain '
					} h-48 absolute top-0 left-0 w-full z-10`}
					src={item.image_url ? item.image_url : povlarge}
					alt=''
				/>
				{item.guid.toLowerCase() === 'discount' && (
					<div className={'flex justify-center items-center absolute w-full h-full z-50 mx-auto text-white'}>
						<p>{item.discount_code}</p>
					</div>
				)}
				{item.guid.toLowerCase() !== 'discount' && (
					<img
						className={'absolute w-16 h-16 z-50 mx-auto'}
						style={{
							top: 'calc(50% - 2rem)',
							left: 'calc(50% - 2rem)',
						}}
						src={icons[item.guid]}
						alt=''
					/>
				)}
			</div>
			{!!item.post_title && (
				<p className={'text-2xl underline mt-4 text-primary font-semibold'}>{item.post_title}</p>
			)}
			{!!item.post_excerpt && <p className={'text-lg mt-2 text-primary hover:underline'}>{item.post_excerpt}</p>}
		</div>
	);
};

export default ResourceSlide;
