import React from 'react';
import { book_3d } from '../../images';

const EventBook = () => {
	return (
		<div className='event-book-section'>
			<div className='container'>
				<div className='flex flex-col items-center lg:flex-row lg:items-stretch justify-center'>
					<div className='flex-col items-center justify-center'>
						<img src={book_3d} alt='' width='250' />
					</div>
					<div className='text-center w-full lg:w-2/5 p-12'>
						<h2 className='text-xl md:text-2xl font-normal'>
							In Pat's latest fable, Bull Brooks discovers his Working Geniuses, sheds guilt about his
							frustrations, and embraces the type of work that brings him joy and fulfillment.
						</h2>
					</div>
					<div className='flex items-end justify-center mt-12 px-6'>
						<a
							href='https://www.amazon.com/Types-Working-Genius-Understand-Frustrations/dp/1637743297'
							id={'wg-order-now-btn'}
							className={'btn btn-primary relative'}
							target='_blank'
							rel='noreferrer'
						>
							Order now
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventBook;
