import React from 'react';
import AboutHowItStarted from '../../components/about/AboutHowItStarted';
import AboutPatVideo from '../../components/about/AboutPatVideo';
import About6Types from '../../components/about/About6Types';
import AboutBook from '../../components/about/AboutBook';
import AboutSetApart from '../../components/about/AboutSetApart';
import LaunchEventReviews from '../../components/launchevent/LaunchEventReviews';
import AboutJoinTeam from '../../components/about/AboutJoinTeam';
import EmailCollector from '../../components/homepage/EmailCollector';
import BookService from '../../services/BookService';

const AboutPage = () => (
	<>
		<AboutHowItStarted />
		<AboutPatVideo />
		<About6Types />
		<AboutBook />
		<EmailCollector
			title={'Get a free sample of the book'}
			successMessage={'Thanks for requesting a sample of the book. Look for it in your inbox!'}
			onSubmit={BookService.sendSampleBook}
		/>
		<AboutSetApart />
		<LaunchEventReviews />
		<AboutJoinTeam />
	</>
);

export default AboutPage;
