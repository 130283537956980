import React from 'react';
import AppButton from './AppButton';
import AppIcon from './AppIcon';
import AppModal from './AppModal';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const AppNotificationModal = ({ isOpen, closeModal }) => {
	return (
		<AppModal id={'app-notification-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='bg-primary py-4 px-8' style={{ maxWidth: '690px' }}>
				<h1 className={'flex flex-1 text-3xl text-center text-white font-bold mx-2'}>
					{' '}
					NEW FEATURE: Share Your Assessment Results!{' '}
				</h1>
				<div className='absolute right-0 top-0 mt-4 mr-4'>
					<AppIcon
						icon={'cancel'}
						className={'text-white text-2xl cursor-pointer hover:text-secondary'}
						onClick={closeModal}
					/>
				</div>
				<p className={'text-white mt-10 px-4'}>
					You can now send your assessment results to other Working Genius users. Click “Share Assessment”
					under the Actions section to send your results. The results will remain in your portal, but this
					feature allows others to see your results and use them for the “Create Team Map” functionality.
				</p>
				<div className='flex justify-center my-4'>
					<AppButton onClick={closeModal} text={'Close'} id={'wg-close-notification-btn'} />
				</div>
			</div>
		</AppModal>
	);
};

export default AppNotificationModal;
