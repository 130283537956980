import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Layout from '../../../components/Layout';
import CustomerService from '../../../services/CustomerService';
import Error from '../../../components/assessments/Error';
import { useUser } from '../../../hooks/UserProvider';
import { getQueryParams, setAuthCookie } from '../../../services/UtilityService';

const EmailChangeVerification = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState('');
	const location = useLocation();
	const { setUser, loadUser } = useUser();
	const { token } = getQueryParams(location.search);

	const verifyChangeToken = () => {
		CustomerService.verifyChangeToken(token)
			.then((data) => {
				if (data.token) {
					setAuthCookie(data.token);

					return loadUser();
				}

				setAuthCookie(null);
				setUser(null);
				return navigate('/client/login?confirm=true');
			})
			.catch((e) => setError(e.message))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		verifyChangeToken();
	}, []);

	const renderContent = () => {
		if (error) {
			return <Error message={error} />;
		}
		return (
			<div className={'mt-8 text-center'}>
				<h1 className={'text-4xl text-primary font-bold'}>Operation completed</h1>
				<p className={'text-primary'}>The email was changed successfully!</p>
			</div>
		);
	};

	if (loading) {
		return (
			<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		);
	}

	return <Layout>{renderContent()}</Layout>;
};

export default EmailChangeVerification;
