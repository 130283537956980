import React from 'react';
import Event6Types from '../event/Event6Types';

const About6Types = () => {
	return (
		<div className='about-6-types-component'>
			<Event6Types />
		</div>
	);
};

export default About6Types;
