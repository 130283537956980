import React, { useState } from 'react';
import AppButton from '../../AppButton';
import { navigate } from '@reach/router';
import AppVideoModal from '../../AppVideoModal';

const AssessmentAboutFAQ = () => {
	const [vimeoUrl, setVimeoUrl] = useState(null);

	return (
		<div className={'about-assessment-faq-wrapper'}>
			<div
				className={'flex flex-col md:flex-row justify-center md:justify-around container py-10 md:py-16 px-10'}
			>
				<div className={'flex flex-col items-center m-6'}>
					<h2 className={'text-primary text-2xl md:text-3xl font-thin pb-4'}>
						Frequently Asked Questions:
					</h2>
					<div className={'flex flex-col w-11/12 md:w-10/12'}>
						<AppButton
							text={'Explore FAQs'}
							className={'btn btn-secondary'}
							onClick={() => navigate('/faq')}
						/>
						<AppButton
							text={'Administrator Console Guide'}
							className={'btn btn-secondary my-6'}
							onClick={() => navigate('/using-administrator-console')}
						/>
					</div>
				</div>
				<div className={'flex flex-col items-center m-6'}>
					<h2 className={'text-primary text-2xl md:text-3xl font-thin pb-4'}>Helpful "How To" Videos</h2>
					<div className={'flex flex-col w-11/12 md:w-10/12'}>
						<AppButton
							text={'Buy & Send The Assessment'}
							className={'btn btn-secondary'}
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/750402175?h=6102028612')}
						/>
						<AppButton
							text={'Find Your Assessment'}
							className={'btn btn-secondary my-6'}
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/752979920?h=4935769852')}
						/>
					</div>
				</div>
			</div>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default AssessmentAboutFAQ;
