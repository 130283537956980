import React from 'react';
import { application_guide_buy } from '../../../images';
import AppButton from '../../AppButton';
import { addToCart } from '../../../services/CustomerService';

const Buy = () => {
	return (
		<div className={'application-guide-buy'}>
			<div className={'flex-col-reverse md:flex-row container py-8'}>
				<div className={'flex-1 flex flex-col-reverse md:flex-row items-center justify-start'}>
					<img
						src={application_guide_buy}
						alt='buy'
						className={'w-7/12 md:w-3/12  md:ml-20 md:mr-10 px-10'}
					/>

					<div className={'flex flex-col p-5 md:px-6 lg:px-10'}>
						<h2 className={'text-white flex-1 text-xl lg:text-3xl xl:text-5xl pb-5 text-center'}>
							Buy today for $250
						</h2>
						<div className={'flex-1 flex justify-center text-lg md:text-2xl'}>
							<AppButton
								text={'Purchase 1 Hr Application Guide'}
								onClick={() => addToCart('team_application_product_id', true)}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Buy;
