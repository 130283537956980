import React, { useState, useEffect } from 'react';

import Layout from '../../../components/Layout';
import Hero from '../../../components/trainees/resources/Hero';
import Resources from '../../../components/trainees/resources/Resources';
import AppFAQ from '../../../components/AppFAQ';
import { getTraineeResources } from '../../../services/TraineeService';
import NotFoundPage from '../../404';
import { useUser } from '../../../hooks/UserProvider';

const FAQ = [
	{
		title: 'Discount Code Use',
		answer: "As a certified Working Genius facilitator, this code is for your use only. We aren't exclusive often, but this is one of those times. You've earned it!",
	},
	{
		title: 'Will the Table Group be offering more Certification Resources?',
		answer: 'As you know, we are constantly refining and exploring new ways to apply the Working Genius model. The Certification Portal will be your go-to spot for all updated materials and new resources. As we add more items, we will email you regarding updates or webinar opportunities.',
	},
	{
		title: "Can I move someone's assessment results into my portal?",
		answer: 'If you need an individual’s assessment results in your portal to create a Team Map, please ask them to click “Share Assessment Report” from their Working Genius console. In the event they don’t have a console, they can create one by clicking “Login” and “Find my Assessment.”',
	},
	{
		title: 'How can I connect with other Working Genius Certified participants on LinkedIn?',
		answer: "We've created a <a style='text-decoration: underline;' href='https://www.linkedin.com/groups/13965517/' target='_blank'>LinkedIn group</a> so that anyone who is Working Genius Certified can share stories or discuss their experiences with the tool.",
	},
	{
		title: 'How much should I charge for a Working Genius Session?',
		answer: 'Because of the variables associated with Working Genius Sessions, we are not able to advise on rates. The size of the group, type of organization, geographics of your location in the world, demographics of the people you are working with, and even your level of experience and expertise will all impact what you charge.',
	},
	{
		title: 'Can I buy an assessment for someone?',
		answer: 'Yes, you can purchase one or more licenses, and then from the home page within the Administrator\'s Console, you can select the blue icon that reads, "Send Assessment to Others."',
	},
	{
		title: 'How do I add additional license(s) to an open group?',
		answer: '<p>1. Purchase the additional assessment license(s) if you do not have any remaining. </p> <p>2. Choose the group you wish to modify on the assessments page in the Console.</p> <p>3. After selecting the appropriate team, select the add licenses to this group in the right corner of the page.</p> <p>4. Send an email containing the unique link to the new group/team members.</p>',
	},
	{
		title: 'Can I generate a team map in my portal?',
		answer: "<p> Short answer - yes. Long answer - you have two options. </p><p>1. If you don't have people's assessment results in your portal, you can manually enter names onto a blank team map that is under your <a href=\"/client/tools/\">Resources</a> Section.</p> <p>2. If you purchased and sent assessments to a group or individuals, then you can automatically generate a team map listing their geniuses and frustrations. The only caveat is that your team needs to be less than 16 people, otherwise it's too many to fit on the document. </p>",
	},
];

const sections = [
	{
		key: 'certification_session_materials',
		title: 'Certification Session Materials',
		info: 'We have included the Slide Deck and Companion Guide from the Working Genius Certification Session for your reference.',
	},
	{
		key: 'facilitator_training_tool_kit',
		title: 'Facilitator Training Tool Kit',
		info: 'As you apply the Working Genius model for individual coaching, client meetings and team development, you can use any of these templates to create and deliver training session content.',
	},
	{
		key: 'videos',
		title: 'Videos',
		info: 'These videos were created specifically for Certification. You viewed them for pre-work, but we also wanted them to be available for you to use with a client or during a training. Please select the post to view or download each video.',
	},
	{
		key: 'marketing_resources',
		title: 'Marketing Resources',
		info: 'We hope that Certification training and all of the tools above help you feel equipped to work with individuals and teams. Here are just a couple of resources and guides to reference as you share about your Certification and the Working Genius assessment.',
	},
	{
		key: 'recordings',
		title: 'Additional Resources',
		info: 'In case you missed any of the Q&A sessions for Certified Facilitators, we have included recordings of the calls for you to view.',
	},
];

const Trainee = () => {
	const { user } = useUser();
	const [loading, setLoading] = useState(true);
	const [resources, setResources] = useState([]);

	useEffect(() => {
		getInitialData();
	}, []);

	const getInitialData = () => {
		if (user.is_wg_trainee) {
			getTraineeResources()
				.then((res) => {
					setResources(res);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	if (user.is_wg_trainee) {
		return (
			<Layout containerClass={'cert-resources bg-gray-100'}>
				<Hero />
				<div className='mx-4 md:mx-24'>
					<Resources loading={loading} sections={sections} resources={resources} />
				</div>
				<AppFAQ items={FAQ} />
			</Layout>
		);
	}

	return <NotFoundPage path='*' />;
};

export default Trainee;
