import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('body');

const AppModal = ({ children, ...props }) => {
	const { closeTimeout } = props;

	return (
		<Modal closeTimeoutMS={closeTimeout ?? 300} {...props}>
			{children}
		</Modal>
	);
};

export default AppModal;
