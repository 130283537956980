import React from 'react';

import Event6Types from '../../components/event/Event6Types';
import EventBook from '../../components/event/EventBook';
import EventHero from '../../components/event/EventHero';
import EventPat from '../../components/event/EventPat';
import EventDeeper from '../../components/event/EventDeeper';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import ApiService from '../../services/ApiService';

const Event = () => {
	const [streamHTML, setStreamHTML] = React.useState(' ');

	const getStreamLinks = React.useCallback(() => {
		ApiService.get('/v1/book/launch/live').then(({ data }) => {
			setStreamHTML(data.url.live);
		});
	}, []);

	React.useEffect(() => {
		getStreamLinks();
	}, []);

	return (
		<WorkshopLayout title={'The 6 Types of Working Genius: Book Launch Event'}>
			<EventHero streamHTML={streamHTML} />
			<EventBook />
			<EventDeeper />
			<EventPat />
			<Event6Types />
		</WorkshopLayout>
	);
};

export default Event;
