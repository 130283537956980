import React from 'react';
import AppVideoModal from '../AppVideoModal';
import { book_play_video } from '../../images';

const AboutPatVideo = () => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	return (
		<div className='book-video py-8 pb-0' id='wg-about-6-types-id'>
			<div className='container'>
				<h1 className='types-title'>
					About The <span className='font-bold'>6</span> Types of Working Genius
				</h1>
				<hr className='my-6 w-full' />
				<div className='book-video-content flex flex-col lg:flex-row items-center'>
					<div className='book-video-src flex-1'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/722108706?h=7736a0c545&amp;')}
							id='wg-about-play-main-video'
						>
							<img src={book_play_video} alt='Play Video Patrick' />
						</a>
					</div>
					<div className='book-video-quote flex-1 ml-0 lg:ml-12 mt-12 lg:mt-0'>
						<p className='pat-video-explenation'>
							The 6 types of Working Genius is a new model that helps people discover their natural gifts
							and thrive in their work and life. When people are able to better understand the types of
							work that bring them more energy and fulfillment and avoid work that leads to frustration
							and failure, they can be more self-aware, more productive and more successful.
						</p>
						<br />
						<p className='pat-video-explenation'>
							Working Genius identifies the six fundamental activities that are required for any type of
							work and provides a simple framework for how work gets done. Too many people feel
							frustrated, underutilized or misunderstood at work. Far too many teams experience failure,
							feel stuck or are confused because they don't know how to tap into the people around them.
							Working Genius is changing the way people are thinking about their work and teams, and is
							leading to more dignified, fulfilled and successful work.
						</p>
					</div>
				</div>
			</div>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default AboutPatVideo;
