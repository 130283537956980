import React from 'react';
import AppButton from '../AppButton';
import AppFlipCard from '../AppFlipCard';
import { addToCart } from '../../services/CustomerService';
import { formatPrice } from '../../services/UtilityService';
import AppIcon from '../AppIcon';

const Product = ({ product, key }) => {
    return (
        <AppFlipCard
            key={key}
            cardFront={
                <div className='store-item-front'>
                    <div className='flex items-center justify-center p-2 mt-auto'>
                        <img src={product.images} width={'350'} />
                    </div>

                    <h3 className='title leading-0'>
                        <span className='flex flex-row items-center title leading-0'>
                            <AppIcon icon={product.metadata.store_icon_name || 'gear-store-icon'} className={'text-2xl xl:text-3xl text-white px-2'} />
                            {product.name}
                        </span>
                        <span className='text-base text-white font-thin px-4'>
                            {formatPrice(product.defaultPrice.unit_amount / 100)}
                        </span>
                    </h3>
                </div>
            }
            cardBack={
                <div className='store-item-back'>
                    <AppIcon icon={product.metadata.store_icon_name || 'gear-store-icon'} className={'text-3xl text-white pb-2'} />
                    <h3 className='title'>{product.name}</h3>
                    <span className='text-base text-white font-thin mb-1'>
                        {formatPrice(product.defaultPrice.unit_amount / 100)}
                    </span>
                    <div className='description' dangerouslySetInnerHTML={{ __html: product.metadata.store_description }} />
                    <div className='mt-auto'>
                        {product.metadata.url && (
                            <AppButton
                                onClick={() => {
                                    window.open(product.metadata.url);
                                }}
                                className={'btn btn-primary w-full mb-2 xl:mb-4'}
                                text={'View Details'}
                            />
                        )}
                        <AppButton
                            onClick={() => {
                                addToCart('other', product.id);
                            }}
                            className={'btn btn-primary w-full'}
                            text={'Add to cart'}
                        />
                    </div>
                </div>
            }
        />
    );
};

export default Product;
