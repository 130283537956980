import React from 'react';
import AppButton from '../../AppButton';
import { navigate } from 'gatsby';
import { family_with_student, newly_released, for_students_green_sticker } from '../../../images';

const AssessmentStudent = () => {
	return (
		<div className='bg-primary py-8'>
			<div className='container'>
				<div className='flex flex-col-reverse lg:flex-row'>
					<div className='flex-1 justify-center relative px-8 py-4'>
						<img src={family_with_student} alt='Workshop image' />
						<img
							src={for_students_green_sticker}
							alt='For Students Sticker'
							className='hidden sm:block absolute top-0 left-0 my-8 mx-12'
							width='150'
						/>
					</div>
					<div className='flex-1 flex flex-col justify-center items-center text-white text-center px-12'>
						<img src={newly_released} alt='Newly released' width='280' />
						<h2 className='text-xl lg:text-3xl font-normal my-4' style={{ maxWidth: '450px' }}>
							Working Genius <span className='block font-semibold'>Student Assessment</span>
						</h2>
						<p className='text-lg lg:text-xl my-4' style={{ maxWidth: '400px' }}>
							The fastest and simplest way to discover your gifts and thrive in school and life.
						</p>
						<AppButton
							className={'btn btn-student'}
							onClick={() => {
								navigate('/students');
							}}
							text={'Learn more'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AssessmentStudent;
