import React from 'react';
import { wg_what_makes_us_different } from '../../images';

const AboutSetApart = () => {
	return (
		<div className='about-set-apart' id='wg-about-what-makes-it-different-id'>
			<div className='container'>
				<div className='about-set-apart-content'>
					<div className='about-set-apart-text flex-1'>
						<h2 className='about-set-apart-h3-bold'>
							What Sets Working Genius <b>Apart</b>
						</h2>
						<p className='about-set-apart-p'>
							First, its simplicity. The Working Genius model distills any kind of work down to six
							fundamental activities. And in relatively short order, it helps people identify the type of
							work that makes them thrive.
						</p>
						<p className='about-set-apart-p'>
							Second, Working Genius is the only tool of its kind that is actually applied to work. Most
							personality instruments help people better understand how they are wired, but fail to
							explain how that fits together with others in the process of work. Working Genius solves
							this problem. Not only do individuals walk away with a greater understanding of the type of
							work that makes them thrive, but Working Genius provides teams with a better way to think
							about their dynamics, projects, meetings and even their hiring.
						</p>
						<p className='about-set-apart-p'>
							Simply put, Working Genius is part personality assessment and part productivity tool for
							teams. And it is the simplest and fastest way to discover your gifts and transform your
							life.
						</p>
					</div>
					<div className='about-set-apart-img flex-1 hidden md:block'>
						<img src={wg_what_makes_us_different} alt='The 6 Types of the Working Genius' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSetApart;
