import React from 'react';
import AppButton from '../../AppButton';
import { team_app_guide_1, tag_logo_white } from '../../../images';
import { addToCart } from '../../../services/CustomerService';

const HeroSection = () => {
	return (
		<div className={'application-guide-hero-wrapper'}>
			<div className={'application-guide-layout container items-center'}>
				<div className={'flex-1 p-10 m-2'}>
					<img src={team_app_guide_1} alt='team-guide' />
				</div>
				<div className={'flex-1 p-5 m-2'}>
					<div className={'container flex flex-col items-center'}>
						<img src={tag_logo_white} alt='guide-logo' className={'flex-1 pb-2'} width='400' />
						<h1 className={'text-white text-center text-14 lg:text-xl p-4'}>
							Your digital toolkit for team adoption and impact
						</h1>
						<AppButton
							text={'Buy Now'}
							className={'btn btn-primary p-4'}
							onClick={() => addToCart('team_application_product_id', true)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
