import React from 'react';

const Progress = ({ currentStep }) => {
    const steps = [
        { number: 1, label: 'Login Details' },
        { number: 2, label: 'Account Details' },
        { number: 3, label: 'Address Details' },
    ];

    if (currentStep > 3) {
        return null;
    }

    return (
        <div className='flex flex-col items-center'>
            <span className='mb-2 text-sm text-gray-700'>Step {currentStep} of 3</span>
            <span className='mb-6 text-lg text-gray-900 font-medium'>
                {steps.find((step) => step.number === currentStep)?.label}
            </span>
        </div>
    );
};

export default Progress;
