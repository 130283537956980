import ApiService from './ApiService';

const register = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/wg_book_launch/add`, payload)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const registerEvent = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/wg_book_event_registration/add`, payload)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const sendSampleBook = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/sample_book`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	register,
	registerEvent,
	sendSampleBook,
};
