import Axios from 'axios';
import config from '../constants/config';
import { getAuthCookie, setAuthCookie } from './UtilityService';

const unauthorizedCodes = [401, 403];

const skipUnAuthRedirectionRoutes = ['v1/login', 'v1/customer', 'v1/notifications', '/v1/roster/find'];

const isUnAuthorized = (error) => {
	try {
		return unauthorizedCodes.indexOf(error.response.status) > -1;
	} catch (e) {
		return false;
	}
};

const onUnauthorizedResponse = (error) => {
	try {
		setAuthCookie('');
		const url = error.response.config.url.split('?')[0];
		if (skipUnAuthRedirectionRoutes.indexOf(url) === -1) {
			window.open('/client/login/', '_self'); //Changed to window open in order to force reload the entire browser tab.
		}
	} catch (e) {}
};

const ApiService = Axios.create({
	baseURL: config.api_url,
	headers: {
		'Content-Type': 'application/json',
		'x-application-id': 'WG'
	},
});

ApiService.interceptors.request.use(
	(config) => {
		const token = getAuthCookie();
		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	(error) => {
		if (isUnAuthorized(error)) {
			onUnauthorizedResponse(error);
		}
		return Promise.reject(error.response.data);
	}
);

ApiService.interceptors.response.use(
	(response) => response,
	(error) => {
		if (isUnAuthorized(error)) {
			onUnauthorizedResponse(error);
		}
		return Promise.reject(error.response ? error.response.data : error);
	}
);

export default ApiService;
