import React from 'react';
import { dave_ramsey, john_maxwell, jon_gordon, michelle_rashid } from '../../images';

const BookWhatPeopleSay = () => {
	return (
		<div className='book-what-people-say-section'>
			<div className='container'>
				<div className='flex items-center justify-center'>
					<h2 className='book-h3-bold'>What People are Saying </h2>
				</div>
				<div className='flex flex-wrap items-center justify-around'>
					<div className='review-item'>
						<img src={jon_gordon} alt='Jon Gordon' />
						<div className='review-content'>
							<p className='book-review-quote'>
								"This is going to be bigger than The Five Dysfunctions of a Team. Working Genius will
								revolutionize the way you approach your team, your work, and even your life."
							</p>
							<p className='book-review-author'>
								Jon Gordon, best-selling author of <span className='italic'>The Energy Bus</span> and{' '}
								<span className='italic'>The Power of Positive Leadership</span>
							</p>
						</div>
					</div>
					<div className='review-item'>
						<img src={john_maxwell} alt='John Maxwell' />
						<div className='review-content'>
							<p className='book-review-quote'>
								"Whether you're joining the workforce, volunteering at church, or figuring out what to
								do in retirement, right now is the time to begin living in your genius."
							</p>
							<p className='book-review-author'>
								John Maxwell, author, speaker and founder, Maxwell Leadership
							</p>
						</div>
					</div>
					<div className='review-item'>
						<img src={michelle_rashid} alt='Michelle Rashid' />
						<div className='review-content'>
							<p className='book-review-quote'>
								"The Working Genius changed how I think about myself and my team. It's a game changer
								for how to hire and develop employees. Crazy!"
							</p>
							<p className='book-review-author'>
								Michelle Rashid, senior vice president, culture and human resources, Virtuoso
							</p>
						</div>
					</div>
					<div className='review-item'>
						<img src={dave_ramsey} alt='Dave Ramsey' />
						<div className='review-content'>
							<p className='book-review-quote'>
								"Lencioni has come up with another idea that is going to transform the way people see
								themselves and their jobs, whether they work in a corporation or from their living room.
								Every person I know needs to read this book."
							</p>
							<p className='book-review-author'>
								Dave Ramsey, nationally syndicated talk show host and{' '}
								<span className='italic'>New York Times</span> best-selling author of{' '}
								<span className='italic'>The Total Money Makeover</span>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookWhatPeopleSay;
