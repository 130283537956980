import React from 'react';

import OutsideAlerter from '../OutsideAlerter';
import AppIcon from '../AppIcon';
import Notifications from './Notifications';
import AppRIIcon from '../AppRIIcon';
import { useNotifications } from '../../hooks/NotificationProvider';

const Widget = () => {
	const [isOpen, setIsOpen] = React.useState(false);

	const {
		notifications: { unreadAll },
	} = useNotifications();

	return (
		<OutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
			<div className='notifications-container'>
				<a
					id='wg-top-bar-notifications'
					className='flex items-center cursor-pointer px-2 text-lg leading-6 font-medium text-white hover:text-secondary'
					onClick={() => setIsOpen(!isOpen)}
				>
					<span className='relative'>
						<AppRIIcon icon='notification-2-line' className='text-2xl px-1' />
						{!!unreadAll && <span className='badge'>{unreadAll}</span>}
					</span>
				</a>
				<div className={`notification-content ${isOpen ? 'opened' : ''}`}>
					<div className='md:hidden absolute right-0 top-0 mt-4 mr-4'>
						<AppIcon
							icon={'cancel'}
							className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
							onClick={() => setIsOpen(false)}
						/>
					</div>
					<Notifications toggleWidgetVisibility={setIsOpen} />
				</div>
			</div>
		</OutsideAlerter>
	);
};

export default Widget;
