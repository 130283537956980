import React from 'react';
import { Helmet } from 'react-helmet';

let interval;

const AppActiveCampaignForm = ({ src, onClose }) => {
	const [hasListener, setHasListener] = React.useState(false);

	React.useEffect(() => {
		interval = setInterval(() => {
			const closeButton = document.querySelector('._form ._close');
			if (closeButton && !hasListener) {
				closeButton.addEventListener('click', () => {
					onClose();
				});
				setHasListener(true);
				clearInterval(interval);
			}
		}, 300);
	}, []);

	return (
		<Helmet>
			<script src={src} async />
		</Helmet>
	);
};

export default AppActiveCampaignForm;
