import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import { ToolsData } from '../../constants/ToolsItems';
import { file, internal } from '../../services/UtilityService';

const Tool = (props) => {
	return (
		<div className='tools-block container'>
			<div className='tools-icon'>
				<img src={props.data.iconUrl} alt={'tools-icon'} />
			</div>
			<div className='tools-info py-5'>
				<span className='tools-info--title'>{props.data.title}</span>
				<span className='tools-info--text'>
					{props.data.links.map((link, index) =>
						internal(link.url) && !file(link.url) ? (
							<Link to={link.url} key={index}>
								{link.title}
							</Link>
						) : (
							<a href={link.url} key={index} target='_blank'>
								{link.title}
							</a>
						)
					)}
				</span>
			</div>
		</div>
	);
};

const Tools = () => {
	const language = localStorage.getItem('wglang') || 'en';

	return (
		<Layout title='The 6 Types of Working Genius: Administrator Console' wrapperClass={'tools'}>
			<div className='container'>
				<h2 className='tools-heading'>Tools</h2>
			</div>
			{ToolsData.map((data, index) => (
				<Tool data={data[language]} key={index} />
			))}
		</Layout>
	);
};

export default Tools;
