import React, { useEffect, useState } from 'react';
import { Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCurrentBreakpoint } from '../../services/UtilityService';
import { full_focus, mixbook, orange_theory, linkage, vanderbilt } from '../../images';

const LaunchEventReviews = () => {
	const screenSlidesPerView = {
		sm: 1,
		md: 2,
		lg: 2,
		xl: 3,
	};

	const [slidesPerView, setSlidesPerView] = useState(screenSlidesPerView[getCurrentBreakpoint()]);
	const [my_swiper, set_my_swiper] = useState({});

	useEffect(() => {
		function handleResize() {
			setSlidesPerView(screenSlidesPerView[getCurrentBreakpoint()]);
		}

		window.addEventListener('resize', handleResize);
	}, []);

	const manageSlideClick = () => {
		if (my_swiper.clickedIndex == my_swiper.activeIndex - 1) {
			my_swiper.slidePrev();
		} else if (my_swiper.clickedIndex == my_swiper.activeIndex + 1) {
			my_swiper.slideNext();
		}
	};

	return (
		<div className='launch-event-reviews-slider-section bg-gray-swiper'>
			<h2 className='text-primary text-2xl font-semibold'>What People are Saying</h2>
			<Swiper
				observer
				observeParents
				onInit={(ev) => {
					set_my_swiper(ev);
				}}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={slidesPerView}
				coverflowEffect={{
					scale: 0.8,
					rotate: 0,
					stretch: 0,
					depth: 1,
					modifier: 1,
					slideShadows: true,
				}}
				loop={true}
				loopFillGroupWithBlank={true}
				pagination={{
					clickable: true,
				}}
				modules={[EffectCoverflow, Pagination]}
				className='launch-event-swiper'
			>
				<SwiperSlide>
					<div className='review-slider' onClick={() => manageSlideClick()}>
						<img src={full_focus} width='150' alt='New York Times' />
						<p className='review-quote'>
							“Nothing gave me an ‘aha’ moment like the Working Genius. It dialed in something for me
							that’s been a question for years. It gave me a sense of freedom.”
						</p>
						<h3 className='review-author'>Michael Hyatt, New York Times best-selling author</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='review-slider' onClick={() => manageSlideClick()}>
						<img src={mixbook} width='150' alt='Mixbook' />
						<p className='review-quote'>
							“The simple ten-minute assessment completely changed the way we think about our work and our
							people.”
						</p>
						<h3 className='review-author'>Andrew Laffoon, CEO &#38; Co-founder, Mixbook</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='review-slider' onClick={() => manageSlideClick()}>
						<img src={orange_theory} width='150' alt='Orange Theory' />
						<p className='review-quote' style={{ marginTop: '-5px' }}>
							“After many years in marriage and business together, I questioned if my wife hated all my
							ideas. Before the Working Genius, I saw her discernment as conflict instead of complement.
							Taking the assessment was the best anniversary gift!”
						</p>
						<h3 className='review-author'>Heath Ellenberger, operational partner, OrangeTheory Fitness</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='review-slider' onClick={() => manageSlideClick()}>
						<img src={linkage} width='150' alt='Linkage' />
						<p className='review-quote'>
							“This transformative book aligns perfectly with our evolving understanding of leadership,
							that the best teams and organizations have leaders who intentionally discover, honor, and
							leverage the strengths—or Genius—of their team members. I can’t wait to use this simple and
							actionable framework with my team.”
						</p>
						<h3 className='review-author'>Jennifer McCollum, CEO, Linkage, Inc.</h3>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='review-slider' onClick={() => manageSlideClick()}>
						<img src={vanderbilt} width='150' alt='Vanderbilt' />
						<p className='review-quote' style={{ marginTop: 0 }}>
							“Patrick Lencioni does it again! In The 6 Types of Working Genius, he uses the power of
							story to draw in the reader, then issues an important call to action. He challenges leaders
							to help their people unpack their innate talents and leverage them, not just for the benefit
							of the organization, but for the direct benefits they can reap by living a more fulfilled
							life.”
						</p>
						<h3 className='review-author'>
							Kelly Goldsmith, PhD, professor of marketing, Vanderbilt University
						</h3>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};

export default LaunchEventReviews;
