import React from 'react';

const UsingSection = ({ data }) => {
	const { content, image, imgPosition } = data;
	const dataPosition = imgPosition === 'left' ? 'right' : 'left';

	return (
		<div className={`wrapper-div flex-col ${imgPosition === 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}>
			<div className={`inside-div-${imgPosition} before-gear border-div`}>
				<img src={image} alt='Working Genius Resources' width='650' />
			</div>
			<div
				className={`inside-div-${dataPosition} flex flex-col justify-center items-center lg:items-start border-div`}
			>
				{content}
			</div>
		</div>
	);
};

export default UsingSection;
