import React from 'react';
import ResourceSlider from './ResourceSlider';

const ResourceSection = ({ section, resources }) => {
	const onSlideClick = (slide) => {
		if (slide.file_url) {
			window.open(slide.file_url, '_blank');
		} else {
			window.open(`/client/certification/resources/post/?id=${slide.ID}`, '_blank').focus();
		}
	};

	if (!resources || resources.length === 0) {
		return null;
	}

	return (
		<div className={'flex flex-1 flex-col lg:flex-row pb-6 border-b-1 border-gray-300 mt-6'}>
			<div className='flex-col w-full lg:w-1/6 lg:mr-2'>
				<h1 className={'text-primary text-2xl'}>{section.title}</h1>
				<p className={'text-primary mt-4'}>{section.info}</p>
			</div>
			<div className='flex w-full lg:w-5/6 lg:ml-2'>
				<ResourceSlider items={resources} onClick={onSlideClick} />
			</div>
		</div>
	);
};

export default ResourceSection;
