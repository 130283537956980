import React from 'react';
import ReportUniquePairingsDE from '../../../../constants/ReportUniquePairingsDE';
import {
    report_overview_gear_h,
    report_overview_gear_e,
    report_overview_gear_i,
    report_overview_gear_m,
    report_overview_gear_u,
    report_overview_gear_b,
} from '../../../../images';
import PageTitle from './PageTitle';

const WGPairings = ({ }) => {
    const pairings = React.useMemo(() => {
        return Object.keys(ReportUniquePairingsDE.UniqueParings)
            .map((key) => ({
                key,
                reverse: key.split('').reverse().join(''),
                info: ReportUniquePairingsDE.UniqueParings[key],
            }))
            .sort((a, b) => {
                return a.info.sort - b.info.sort;
            });
    }, []);

    const renderGear = (gear) => {
        gear = gear.toLowerCase();
        return (
            <>
                {gear === 'h' && <img src={report_overview_gear_h} alt={''} />}
                {gear === 'e' && <img src={report_overview_gear_e} alt={''} />}
                {gear === 'i' && <img src={report_overview_gear_i} alt={''} />}
                {gear === 'm' && <img src={report_overview_gear_m} alt={''} />}
                {gear === 'u' && <img src={report_overview_gear_u} alt={''} />}
                {gear === 'b' && <img src={report_overview_gear_b} alt={''} />}
            </>
        );
    };

    return (
        <div className='UniquePairing all'>
            <div className='container'>
                <PageTitle title={'Working-Genius-Kombinationen'} className={'mb-4'} />

                <div className='grid grid-cols-3 gap-4'>
                    {pairings.map((pairing) => (
                        <div className={'pairing-container small'} key={pairing.info.sort}>
                            <div className={'gears'}>
                                {renderGear(pairing.key[0])}
                                {renderGear(pairing.key[1])}
                                <hr />
                                {renderGear(pairing.reverse[0])}
                                {renderGear(pairing.reverse[1])}
                            </div>
                            <div className={'information'}>
                                <h1 className={'key'}>
                                    {pairing.key} | {pairing.reverse}
                                </h1>
                                <p className={'title'}>{pairing.info.title}</p>
                                <p className={'brief'}>{pairing.info.brief}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default WGPairings;
