import React from 'react';
import { navigate } from 'gatsby';
import AboutLayout from '../../components/about/AboutLayout';
import ResourcesService from '../../services/ResourcesService';
import AppScrollableNavigation from '../../components/ScrollableNavigation';
import AppScrollableHeader from '../../components/AppScrollableHeader';
import MarriageIsALotOfWork from '../../components/resources/articles/MarriageIsALotOfWork';
import { useLocation } from '@reach/router';
import { getQueryParams } from '../../services/UtilityService';
import AppTooltip from '../../components/AppTooltip';
import AppRIIcon from '../../components/AppRIIcon';
import AppCopyToClipboard from '../../components/AppCopyToClipboard';
import { isMobile } from 'react-device-detect';

const ArticleNavigation = () => {
	return (
		<div
			style={{ minWidth: '300px' }}
			className={'text-secondary underline mt-4 cursor-pointer'}
			onClick={() => navigate('/resources#articles')}
		>
			<p className={'font-semibold'}>{'< Back To Articles'}</p>
		</div>
	);
};

const ShareArticle = ({ title }) => {
	const [share, setShare] = React.useState(false);
	return (
		<div className='relative'>
			<AppTooltip
				content={() => <p className={'text-white text-center text-tiny font-normal'}>{'Share'}</p>}
				type={'small'}
			>
				<AppRIIcon
					icon='share-line'
					className={'text-3xl text-primary hover:text-secondary p-1 m-2'}
					onClick={() => {
						if (isMobile && navigator.share) {
							navigator
								.share({
									title: title,
									text: 'Check out this article by Author Patrick Lencioni',
									url: window.location.href,
								})
								.then(() => {
									console.log('Successfully shared');
								})
								.catch((error) => {
									console.log(error);
								});
						} else {
							setShare(!share);
						}
					}}
				/>
			</AppTooltip>
			{share && (
				<div className='article-share-link-box'>
					<div className='arrow-up'></div>
					<p className='text-gray-700 text-base md:text-xl border-b-2 border-gray-700 mb-2'>
						Share this article
					</p>
					<p className='text-gray-700 text-base md:text-lg font-semibold'>Copy this link</p>
					<h1 className='text-primary text-base md:text-lg bg-white my-2'>
						<AppCopyToClipboard value={`${window.location.href}`} id={'wg-copy-paste-article-link'} />
					</h1>
					<div className='box-cancel'>
						<AppRIIcon
							icon={'close-fill'}
							className={'text-gray-700 text-2xl cursor-pointer hover:text-gray-900'}
							onClick={() => setShare(false)}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

const ArticleDetails = () => {
	const location = useLocation();
	const queryParams = getQueryParams(location.search);

	const article = React.useMemo(() => {
		return ResourcesService.getArticleBySlug(queryParams.id);
	}, []);

	if (!article) {
		return null;
	}

	const renderArticleDetails = {
		'marriage-is-a-lot-of-work': <MarriageIsALotOfWork />,
	};
	return (
		<AboutLayout>
			<AppScrollableNavigation
				containerClassName={'bg-gray-custom'}
				wrapperClassName={'container pt-8'}
				navigation={<ArticleNavigation />}
			>
				<AppScrollableHeader
					title={article.title}
					subtitle={article.author}
					titleClassName={'ml-4'}
					pdfLink={article.pdfLink}
				/>
				{renderArticleDetails[article.slug]}
				<div className='flex items-center justify-center mb-8'>
					<ShareArticle title={article.title} />
				</div>
			</AppScrollableNavigation>
		</AboutLayout>
	);
};

export default ArticleDetails;
