import React from 'react';
import ColorGearImage from '../../assessments/ColorGearImage';

const gearSize = '56px';

const PersonRow = ({ member }) => {
	const geniusColor = '#00a049';
	const competencyColor = '#fbc331';
	const frustrationColor = '#CB695B';

	const renderGear = (person, type, color, style = {}) => {
		const hasTies = person.ties && person.ties[type];
		if (hasTies) {
			const ties = person.ties[type];
			return (
				<ColorGearImage type={''} color={color} height={gearSize} width={gearSize} style={style}>
					{ties.length > 3 ? (
						<span style={{ fontSize: '35px', color: '#9e9e9e', paddingTop: '15px' }}>*</span>
					) : (
						<span style={{ color: '#9e9e9e', fontSize: '10px', fontWeight: 500 }}>
							{ties.map((tie) => tie[0].toUpperCase()).join('/')}
						</span>
					)}
				</ColorGearImage>
			);
		}
		return <ColorGearImage type={person[type]} color={color} height={gearSize} width={gearSize} style={style} />;
	};

	return (
		<tr className='AllMembersList__personrow'>
			<th className='bold' scope='row'>
				{(member.fname.length > 25 ? member.fname.substr(0, 24) + '...' : member.fname) +
					' ' +
					member.lname.charAt(0) +
					'.'}
			</th>
			<td>
				<div className='Gear Gear--genius'>
					{renderGear(member, 'wg1', geniusColor, { marginRight: '10px' })}
					{renderGear(member, 'wg2', geniusColor)}
				</div>
			</td>
			<td>
				<div className='Gear Gear--competency'>
					{renderGear(member, 'wc1', competencyColor, { marginRight: '10px' })}
					{renderGear(member, 'wc2', competencyColor)}
				</div>
			</td>
			<td>
				<div className='Gear Gear--frustration'>
					{renderGear(member, 'wf1', frustrationColor, { marginRight: '10px' })}
					{renderGear(member, 'wf2', frustrationColor)}
				</div>
			</td>
		</tr>
	);
};

export default PersonRow;
