import React from 'react';
import AppTooltip from '../AppTooltip';
import UtilityService from '../../services/UtilityService';

const geniusColor = UtilityService.geniusColor;
const competencyColor = UtilityService.competencyColor;
const frustrationColor = UtilityService.frustrationColor;

const ResultsRow = ({ member }) => {
	const renderResult = (person, type, color) => {
		return (
			<>
				{person[type] && person[type][0] && (
					<AppTooltip
						type={'small'}
						placement={'top'}
						content={() => {
							if (person[`new_${type}`]) {
								return <p className={'text-white text-tiny'}>{person[`new_${type}`]}</p>;
							}
							const text = person[type].includes('(tie)')
								? person[type].replace(
									'(tie)',
									'. This result was tied with other geniuses. For more information please download the report and check the results.'
								)
								: person[type];
							return <p className={'text-white text-tiny'}>{text}</p>;
						}}
					>
						<span className={'text-sm lg:text-lg font-bold pr-1'} style={{ color: color }}>
							{person[`new_${type}`]
								? person[`new_${type}`][0].toUpperCase()
								: person[type][0].toUpperCase()}
						</span>
					</AppTooltip>
				)}
			</>
		);
	};

	return (
		<div className='results-row flex flex-wrap items-center justify-center' id={'wg-assessment-results'}>
			<span className='results-genius flex flex-row mr-2'>
				{renderResult(member, 'wg1', geniusColor)}
				{renderResult(member, 'wg2', geniusColor)}
			</span>
			<span className='results-competency flex flex-row mr-2'>
				{renderResult(member, 'wc1', competencyColor)}
				{renderResult(member, 'wc2', competencyColor)}
			</span>
			<span className='results-frustration flex flex-row mr-2'>
				{renderResult(member, 'wf1', frustrationColor)}
				{renderResult(member, 'wf2', frustrationColor)}
			</span>
		</div>
	);
};

export default ResultsRow;
