import React from 'react';
import 'swagger-ui-react/swagger-ui.css';
import SwaggerUI from 'swagger-ui-react';

import AppCopyToClipboard from '../../components/AppCopyToClipboard';
import config from '../../constants/config';
import NotFoundPage from '../404';
import { useUser } from '../../hooks/UserProvider';

const APIDocs = () => {
	const { user } = useUser();
	const requestInterceptor = (req) => {
		if (user?.working_genius_token) {
			req.headers['x-working-genius-token'] = user.working_genius_token;
		}
		return req;
	};
	const onComplete = (swaggerUi) => {
		swaggerUi.preauthorizeApiKey('DeveloperToken', user?.working_genius_token || undefined);
	};

	if (!user?.working_genius_token) {
		return <NotFoundPage />;
	}

	return (
		<>
			<div className='container mx-auto p-6' style={{ maxWidth: '1100px' }}>
				<div className={'mb-4'}>
					<p>Developer Key</p>
					<div className={'hidden md:flex md:flex-row'}>
						<div className={'w-full'}>
							<h1 className='text-error text-xl font-bold'>
								<AppCopyToClipboard
									value={`${user?.working_genius_token}`}
									id={'wg-copy-paste-wg-api-developer-key'}
								/>
							</h1>
						</div>
					</div>
				</div>
			</div>
			<section data-reactroot='true' className='swagger-ui container'>
				<SwaggerUI
					url={`${config.api_url}/docs/integration/`}
					onComplete={onComplete}
					requestInterceptor={requestInterceptor}
				/>
			</section>
		</>
	);
};

export default APIDocs;
