import React from 'react';
import CoverPage from './es/CoverPage';
import Overview from './es/Overview';
import Footer from './es/Footer';
import GoingDeeper from './es/GoingDeeper';
import Results from './es/Results';
import Geniuses from './es/Geniuses';
import WGPairings from './es/WGPairings';
import WhatsNext from './es/WhatsNext';
import AsATeam from './es/AsATeam';
import TeamMapAnalyze from './es/TeamMapAnalyze';
import UniquePairingV2 from './es/UniquePairingV2';

const SpanishTemplate = ({ assessment }) => {
	return (
		<div id={'wg-assessment-report'} className={'wg-pdf-assessment-report-v2'}>
			<section role='region' className='Page'>
				<CoverPage assessment={assessment} />
			</section>
			<section role='region' className='Page'>
				<Overview assessment={assessment} />
				<Footer page={1} />
			</section>
			<section role='region' className='Page'>
				<Results assessment={assessment} />
				<Footer page={2} />
			</section>
			<section role='region' className='Page'>
				<Geniuses
					assessment={assessment}
					type={'genius'}
					title={'Genialidades en las que eres sobresaliente'}
				/>
				<Footer page={3} />
			</section>
			<section role='region' className='Page'>
				<Geniuses
					assessment={assessment}
					type={'competency'}
					title={'Genialidades en las que eres competente'}
				/>
				<Footer page={4} />
			</section>
			<section role='region' className='Page'>
				<Geniuses assessment={assessment} type={'frustration'} title={'Genialidades que te frustran'} />
				<Footer page={5} />
			</section>
			<section role='region' className='Page'>
				<UniquePairingV2 assessment={assessment} />
				<Footer page={6} />
			</section>
			<section role='region' className='Page'>
				<WGPairings assessment={assessment} />
				<Footer page={7} />
			</section>
			<section role='region' className='Page'>
				<WhatsNext />
				<Footer page={8} />
			</section>
			<section role='region' className='Page'>
				<AsATeam />
				<Footer page={9} />
			</section>
			<section role='region' className='Page'>
				<TeamMapAnalyze />
				<Footer page={10} />
			</section>
			<section role='region' className='Page'>
				<GoingDeeper />
				<Footer page={11} />
			</section>
		</div>
	);
};

export default SpanishTemplate;
