import React, { useEffect } from 'react';
import AppInput from '../AppInput';
import debounce from 'debounce-promise';

const RequestAssessmentHistoryFilters = ({ filters, setFilters }) => {
	const [search, setSearch] = React.useState(filters.search);

	useEffect(() => {
		if (filters.search === '') setSearch('');
	}, [filters.search]);

	const changeHandler = (value) => {
		setFilters('search', value);
	};

	const debouncedChangeHandler = React.useMemo(() => {
		return debounce(changeHandler, 500);
	}, []);

	const onInputChange = (event) => {
		setSearch(event.target.value);
		debouncedChangeHandler(event.target.value);
	};

	return (
		<div className={'flex-1 flex flex-col md:flex-row pr-3'}>
			<AppInput
				containerClassName={'no-margin w-full'}
				value={search}
				placeholder={'Search'}
				onChange={onInputChange}
				showErrorMessage={false}
			/>
		</div>
	);
};

export default RequestAssessmentHistoryFilters;
