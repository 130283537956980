import React, { useState, useRef, useEffect } from 'react';
import {navigate, useLocation} from '@reach/router';
import DuringTraining from '../../../components/alumni-intensives/register/DuringTraining';
import CourseDetails from '../../../components/alumni-intensives/register/CourseDetails';
import Events from '../../../components/alumni-intensives/register/Events';
import {
	getAlumniIntensiveDates,
} from '../../../services/CertificationService';
import CustomerService, { addToCart } from '../../../services/CustomerService';
import WorkshopLayout from '../../../components/workshop/WorkshopLayout';
import AlumniIntensivesHero from '../../../components/alumni-intensives/register/AlumniIntensivesHero';
import config from '../../../constants/config';
import { useUser } from "../../../hooks/UserProvider";
import NotFoundPage from '../../404';
import NotificationService from "../../../services/NotificationService";
import Home from "../../home";

const AlumniIntensives = () => {

	const [loading, setLoading] = useState(true);
	const [availableEvents, setAvailableEvents] = useState([]);
	const registerEl = useRef();
	const { hash } = useLocation();
	const { user } = useUser();
	const getInitialData = () => {
		getAlumniIntensiveDates()
			.then(({ available, soldOut }) => {
				setAvailableEvents(available);
				checkForHash();
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getInitialData();
	}, []);

	if(!config.alumni_intensive_feature){
		return <NotFoundPage />;
	}

	if(!user){
		navigate(`/client/login?redirect=${encodeURIComponent('/client/alumni-intensives')}`);
	}

	if(!user?.hasPurchasedCertification){
		return <Home />;
	}

	const checkForHash = () => {
		if (hash === '#register') {
			redirectTo();
		}
	};

	const handleGetAlumniIntensivesClick = () => {
		CustomerService.triggerGoogleTagManagerEvent({
			value: availableEvents?.at(0)?.defaultPrice,
			products: availableEvents?.map(event => {
				return {
					id: `${event?.variation_id}_${event?.alumni_intensives_product_id}`,
					name: 'Working Genius Alumni Intensive',
					value: event?.defaultPrice || 0,
					quantity: 1,
					type: 'Working Genius Alumni Intensive',
				}
			})
		});
		redirectTo();
	}


	const redirectTo = () => {
		registerEl.current.scrollIntoView();
	};

	const onRegisterClick = (event) =>{
		addToCart('alumni_intensives_product_id', event.alumni_intensives_product_id, event.variation_id).catch((error) => {
			NotificationService.error('Oops', error?.message || 'Something went wrong while adding Alumni Intensives to cart. Please try again later.');
		})
	}

	return (
		<>
			<WorkshopLayout
				title={'Become An Alumni Intensive | The 6 Types Of Working Genius'}
				description={
					'Registration and resources for virtual intensives, exclusive for Certified Facilitators.'
				}
			>
				<AlumniIntensivesHero redirectTo={handleGetAlumniIntensivesClick} />
				<DuringTraining />
				<CourseDetails />
				<div style={{background: '#dedfe1', padding: '3rem 0'}} ref={registerEl}>
					<Events
						title={'Available Dates'}
						events={availableEvents}
						onRegister={onRegisterClick}
						loading={loading}
					/>
				</div>
			</WorkshopLayout>

		</>
	);
};

export default AlumniIntensives;
