import React, {useEffect} from 'react';
import AppButton from '../AppButton';
import {navigate} from "gatsby";
import AppAudioPlayer from "../AppAudioPlayer";
import {podcastHelpers} from "./podcastHelpers";

const PodcastItem = ({ item, id }) => {
    const {getAudioDuration} = podcastHelpers;
    const [audioDuration, setAudioDuration] = React.useState(0);

    useEffect(() => {
        getAudioDuration(item.mp3_link, setAudioDuration);
    }, [])

    const [showAudioPlaying, setShowAudioPlaying] = React.useState(false);

    return (
        <div id={id} className={'flex flex-col shadow-md bg-white p-8 md:p-5 lg:p-10'}>
            <div className='flex flex-1 flex-col'>
                <div className={'flex flex-col'}>
                    <div className={'flex flex-row justify-between'}>
                        <span className={'text-lg text-primary tracking-wider text-md lg:text-xl uppercase'}>Episode {item.episode_no}:</span>
                        <span className={'text-lg text-primary tracking-wider text-md lg:text-xl'}>{audioDuration !== 0 && `${audioDuration} min`} </span>
                    </div>

                    <div className={'flex flex-1 flex-col'}>
                        <h2 className={'text-xl text-primary font-bold'}>{item.title}</h2>
                        <span className='border-secondary border-t-4 my-4' />
                    </div>
                </div>
                <span className={'text-primary my-4 break-words whitespace-break-spaces'} dangerouslySetInnerHTML={{ __html: item.excerpt }} />
            </div>

            {
                showAudioPlaying &&
                <div className={'bg-primary w-full p-3 lg:p-5 rounded-md mt-2 mb-8'}>
                    <AppAudioPlayer
                        customClassName={'md:w-full'}
                        title={'The Working Genius Podcast'}
                        subtitle={item.title}
                        audioSrc={item.mp3_link}
                        mobile
                    />
                </div>
            }


            <div className='flex flex-col lg:flex-row justify-between  gap-4'>
                <AppButton
                    className={'btn btn-dark rounded-sm w-full'}
                    text={'Listen now'}
                    onClick={() => {
                        setShowAudioPlaying(!showAudioPlaying);
                    }}
                />

                    <AppButton
                        className={'btn btn-secondary rounded-sm w-full'}
                        text={'Learn More'}
                        onClick={() => {
                           navigate(`${item.slug}`);
                        }}
                    />

            </div>
        </div>
    );
};

export default PodcastItem;
