import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

import Layout from '../../components/Layout';
import Hero from '../../components/assessments/Hero';
import AppTable from '../../components/AppTable';
import AssessmentsFilters from '../../components/assessments/AssessmentsFilters';
import AppIcon from '../../components/AppIcon';
import { list } from '../../services/AssessmentsService';
import UtilityService, { formatDate } from '../../services/UtilityService';
import DownloadReport from '../../components/DownloadReport';
import NotificationService from '../../services/NotificationService';
import AssessmentsService from '../../services/AssessmentsService';
import CustomerService from '../../services/CustomerService';
import AssessmentAction from '../../components/assessments/AssessmentAction';
import ShareAssessment from '../../components/assessments/ShareAssessment';
import AssessmentAppIcon from '../../components/assessments/AssessmentAppIcon';
import MoveAssessment from '../../components/assessments/MoveAssessment';
import ResultsRow from '../../components/assessments/ResultsRow';
import EditResultsModal from '../../components/assessments/edit/EditResultsModal';
import AssessmentListTour from '../../components/tours/AssessmentListTour';
import SelectReportLanguageModal from '../../components/assessments/SelectReportLanguageModal';
import BreakTheTiesModal from '../../components/assessments/BreakTheTiesModal';
import RequestShare from '../../components/assessments/RequestShare';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';
import { useUser } from '../../hooks/UserProvider';
import RosterActionsDropdown from './assessment/RosterActionsDropdown';

const initialFilters = {
	search: '',
	type: '',
	sort_key: 'created',
	sort_dir: 'desc',
	page: 0,
	limit: 25,
};

const languageMappings = {
	en: 'English',
	es: 'Español',
	de: 'Deutsch',
};

const Index = () => {
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [filters, setFilters] = useState(initialFilters);
	const [assessments, setAssessments] = useState({
		meta: {},
		data: [],
	});
	const [generateReport, setGenerateReport] = useState(false);
	const [generateReportData, setGenerateReportData] = useState({});

	const [shareAssessment, setShareAssessment] = useState(false);
	const [shareAssessmentItem, setShareAssessmentItem] = useState({});

	const [requestShare, setRequestShare] = useState(false);
	const [requestShareItem, setRequestShareItem] = useState({});

	const [moveAssessment, setMoveAssessment] = useState(false);
	const [moveAssessmentItem, setMoveAssessmentItem] = useState({});

	const [editingAssessment, setEditingAssessment] = useState(false);
	const [isEditingAssessmentResults, setIsEditingAssessmentResults] = useState(false);

	const [shouldSelectLanguage, setShouldSelectLanguage] = useState(false);
	const [breakTheTies, setBreakTheTies] = useState(false);

	const { user, hasPermission } = useUser();

	useEffect(() => {
		getAssessments({
			...filters,
			page: 0,
		});
	}, [filters.type]);

	useEffect(() => {
		if (user.email_change_request) {
			NotificationService.info(
				'Update pending verification',
				`We just need you to check your email <b>${user.email_change_request}</b> and to verify it’s you and complete the update.`,
				{
					duration: Infinity,
					id: 'update-pending-verification',
				},
				[
					{
						text: 'RESEND EMAIL',
						action: () => CustomerService.requestEmailChange(user.email_change_request),
					},
				]
			);
		}
	}, []);

	const getAssessments = (_filters = filters, loader = setLoading, merge = false) => {
		loader(true);
		_filters.search = _filters.search.trim();
		list(_filters)
			.then((data) => {
				const _assessments = merge
					? {
						meta: data.meta,
						data: [...assessments.data, ...data.data],
					}
					: data;
				setAssessments(_assessments);
			})
			.finally(() => loader(false));
	};

	const onShareAssessment = (index, emails, item) => {
		AssessmentsService.shareAssessment({ email: emails[index], assessment_id: item.id })
			.then(() => {
				NotificationService.success(`Assessment successfully shared with <b>${emails[index]}</b>`);
				var result = assessments.data.find((obj) => {
					return obj.id === item.id;
				});
				result.shared_to = result.shared_to ? `${result.shared_to},${emails[index]}` : emails[index];
			})
			.catch((error) => {
				NotificationService.error(error.message, null, { duration: Infinity });
			})
			.finally(() => {
				setShareAssessment(false);
				if (emails[index + 1]) {
					onShareAssessment(index + 1, emails, item);
				}
			});
	};

	const onRequestShare = (item) => {
		AssessmentsService.requestShare({ assessment_id: item.id, roster_id: item.roster })
			.then(() => {
				NotificationService.success(`Share request successfully sent to <b>${item.roster_email}</b>!`);
			})
			.catch((error) => {
				NotificationService.error(error.message, null, { duration: Infinity });
			})
			.finally(() => {
				setRequestShare(false);
			});
	};

	const onSendReport = (item) => {
		if (item.allow_share === 1 || item.roster_email === user.customers_email_address) {
			setShareAssessmentItem(item);
			setShareAssessment(true);
		} else {
			setRequestShareItem(item);
			setRequestShare(true);
		}
	};

	const onShareActionClick = (item) => {
		if (item.allow_share === 1 || item.roster_email === user.customers_email_address) {
			setShareAssessmentItem(item);
			setShareAssessment(true);
		} else {
			setRequestShareItem(item);
			setRequestShare(true);
		}
	};

	const columns = [
		{
			sortable: false,
			header: (col, item) => {
				if (!item) {
					return '';
				}
				return (
					<AppIcon
						className={'text-3xl text-white md:text-secondary justify-center'}
						icon={
							item.type === 'group' ? 'team-fill' : item.shared_from ? 'user-received-fill' : 'user-fill'
						}
					/>
				);
			},
			key: 'icon',
			className: 'w-full md:w-8 lg:w-10 bg-primary md:bg-white',
			render: (item) => {
				return (
					<>
						<AssessmentAppIcon
							className={'hidden md:inline text-3xl text-secondary justify-center'}
							item={item}
						/>
						<span className='md:hidden text-white font-medium uppercase'>
							{item.type === 'group' ? 'Group' : item.shared_from ? 'Shared with me' : 'Individual'}
						</span>
					</>
				);
			},
		},
		{
			header: 'Name',
			key: 'fullname',
			render: (item) => {
				let name = item.group;
				if (item.type !== 'group') {
					if (item.fname || item.lname) {
						name = `${item.fname} ${item.lname}`;
					} else {
						name = '[ No Name ]';
					}
				}
				return (
					<Link
						to={`/client/assessment/?id=${item.id}`}
						className={'underline cursor-pointer text-sm lg:text-base'}
					>
						{name}
					</Link>
				);
			},
		},
		{
			header: 'Company',
			key: 'company',
			className: 'md:w-12/100',
			render: (item) => {
				return <p className={'font-thin text-primary text-sm lg:text-base'}>{item.company || 'N/A'}</p>;
			},
		},
		{
			header: 'Created',
			key: 'created',
			className: 'md:w-13/100',
			render: (item) => {
				return <p className={'font-thin text-primary text-sm lg:text-base'}>{formatDate(item.created)}</p>;
			},
		},
		{
			header: 'Status',
			key: 'completed',
			className: 'md:w-12/100',
			render: (item) => {
				if (item.complete) {
					return <p className='font-bold text-primary text-sm lg:text-base'>Complete</p>;
				} else {
					if (item.type === 'group') {
						return (
							<p className={'font-bold text-primary text-sm lg:text-base'}>
								{item.num_scores} of {item.licenses} completed
							</p>
						);
					} else {
						return <p className='font-bold text-primary text-sm lg:text-base'>Incomplete</p>;
					}
				}
			},
		},
		{
			header: 'Language',
			key: 'language',
			sortable: false,
			className: 'md:w-1/10 ellipse-text',
			render: (item) => {
				if (item.type === 'individual') {
					return (
						<p className='font-normal text-primary text-sm lg:text-base'>
							{languageMappings[item.language] || ''}
						</p>
					);
				} else if (item.type === 'group') {
					return (
						<p className='font-normal text-primary text-sm lg:text-base'>
							{item.multi_language === 1 ? 'Multilingual' : 'English'}
						</p>
					);
				}
			},
		},
		{
			header: 'Results',
			key: 'results',
			sortable: false,
			className: 'sm:w-1/10',
			render: (item) => {
				if (item.complete && item.type === 'individual') {
					return <ResultsRow member={item} />;
				} else {
					return '';
				}
			},
		},
		{
			header: 'Actions',
			key: 'actions',
			sortable: false,
			className: 'md:w-1/6 lg:w-60',
			render: (item) => {
				if (item.type === 'group') {
					return (
						<>
							<AssessmentAction
								actionId={'wg-assessment-details-action'}
								icon='arrow-right-line'
								tooltipText={'View Assessment Details'}
								onClick={() => {
									navigate(`/client/assessment/?id=${item.id}`);
								}}
							/>
							<AssessmentAction
								actionId={'wg-assessment-team-map-action'}
								icon='file-settings-line'
								data-wg={'assessment-generate-team-map'}
								tooltipText={
									item.num_scores < 2
										? 'You can generate Team Map for team with at least 2 completed assessments'
										: 'Generate Team Map'
								}
								disabled={item.num_scores < 2}
								onClick={() => {
									navigate(`/client/teammap/?id=${item.id}`);
								}}
							/>
						</>
					);
				}
				if (item.complete) {
					return (
						<div className="flex flex-row items-center gap-2">
							<AssessmentAction
								data-wg={'wg-download-roster-action'}
								actionId={'wg-assessment-download-report-action'}
								icon='file-download-line'
								tooltipText={'Download Assessment Report'}
								onClick={() => {
									if (
										(item.multi_language || item.language === 'es') &&
										item.assessment_mode === AssessmentModeEnum.STANDARD
									) {
										setEditingAssessment(item);
										setShouldSelectLanguage(true);
									} else {
										setGenerateReportData({
											roster_id: item.roster,
											assessment_id: item.id,
											name: `${item.fname} ${item.lname}`,
										});
										if (item.hasTies) {
											setBreakTheTies(true);
										} else setGenerateReport(true);
									}
								}}
								disabled={!hasPermission('WG_ROSTER.GET.REPORT')}
							/>
							<RosterActionsDropdown
								item={item}
								onSendReport={onSendReport}
								hasPermission={hasPermission}
								onShareActionClick={onShareActionClick}
								setIsEditingAssessmentResults={setIsEditingAssessmentResults}
								setMoveAssessment={setMoveAssessment}
								setMoveAssessmentItem={setMoveAssessmentItem}
								setSelectedRoster={setEditingAssessment}
								user={user}
								isAssessmentPage={true}
							/>
						</div>
					);
				}
				return (
					<AssessmentAction
						actionId={'wg-assessment-details-action'}
						icon='arrow-right-line'
						tooltipText={'View Assessment Details'}
						onClick={() => {
							navigate(`/client/assessment/?id=${item.id}`);
						}}
					/>
				);
			},
		},
	];

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir: sort_dir,
			page: 0,
		};
		setFilters(newFilters);
		getAssessments(newFilters);
	};

	const onPageChange = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getAssessments(newFilters);
	};

	const onLoadMore = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getAssessments(newFilters, setLoadingMore, true);
	};

	const onSearch = () => {
		getAssessments({
			...filters,
			page: 0,
		});
	};

	const onReset = () => {
		setFilters(initialFilters);
		getAssessments(initialFilters);
	};

	const onWidgetUpdate = (newWidget) => {
		const copy = UtilityService.clone(assessments);
		const idx = copy.data.findIndex((d) => d.roster === editingAssessment.roster);
		copy.data[idx] = {
			...copy.data[idx],
			...newWidget,
		};
		setAssessments(copy);
		setIsEditingAssessmentResults(false);
	};

	const assessmentsTableData = assessments.data;
	const assessmentsTableMeta = assessments.meta;

	return (
		<Layout>
			<Hero />
			<div className={'container mx-auto '} id={'wg-assessments-table'}>
				<AppTable
					title={`${user.original_customer ? (user.fullName + '\'s') : ''} Assessments`}
					renderFilters={AssessmentsFilters}
					columns={columns}
					data={assessmentsTableData}
					loading={loading}
					onSort={onSort}
					filters={filters}
					setFilters={setFilters}
					onPageChange={onPageChange}
					onLoadMore={onLoadMore}
					loadingMore={loadingMore}
					onSearch={onSearch}
					onReset={onReset}
					pagination={assessmentsTableMeta}
				/>
				<DownloadReport
					isOpen={generateReport}
					closeModal={() => setGenerateReport(false)}
					ids={generateReportData}
				/>
				<ShareAssessment
					isOpen={shareAssessment}
					onClose={() => {
						setShareAssessment(false);
						setTimeout(() => {
							setShareAssessmentItem({});
						}, 200);
					}}
					item={shareAssessmentItem}
					onShare={onShareAssessment}
				/>
				<RequestShare
					isOpen={requestShare}
					onClose={() => {
						setRequestShare(false);
						setTimeout(() => {
							setRequestShareItem({});
						}, 200);
					}}
					item={requestShareItem}
					onRequestShare={onRequestShare}
					requestStatusPayload={{ assessment_id: requestShareItem.id, roster_id: requestShareItem.roster }}
				/>
				<MoveAssessment
					isOpen={moveAssessment}
					onClose={(res) => {
						setMoveAssessment(false);
						setTimeout(() => {
							setMoveAssessmentItem({});
						}, 200);
						if (res && res.reload) {
							getAssessments(filters);
						}
					}}
					item={moveAssessmentItem}
				/>
				<EditResultsModal
					isOpen={isEditingAssessmentResults}
					roster={editingAssessment}
					identifier={'roster'}
					closeModal={() => setIsEditingAssessmentResults(false)}
					onUpdate={(newData) => onWidgetUpdate(newData)}
				/>
				<SelectReportLanguageModal
					isOpen={shouldSelectLanguage}
					onClose={() => setShouldSelectLanguage(false)}
					onConfirm={(language) => {
						setShouldSelectLanguage(false);
						setGenerateReportData({
							roster_id: editingAssessment.roster,
							assessment_id: editingAssessment.id,
							name: `${editingAssessment.fname} ${editingAssessment.lname}`,
							language: language,
						});
						setGenerateReport(true);
					}}
					languageTaken={editingAssessment.language}
				/>
				<BreakTheTiesModal
					isOpen={breakTheTies}
					onClose={() => setBreakTheTies(false)}
					onConfirm={() => {
						setBreakTheTies(false);
						setGenerateReport(true);
					}}
				/>
			</div>
			<AssessmentListTour />
		</Layout>
	);
};

export default Index;
