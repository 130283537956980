import React from 'react';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';

const TeamWorkQuiz = () => {
	return (
		<WorkshopLayout
			title={'Teamwork Quiz | The 6 Types of Working Genius'}
			description={'Learn if starting with The 6 Types of Working Genius is right for your organization'}
		>
			<h1 className={'hidden'}>The Six Types of Working Genius Teamwork Quiz</h1>
			<div id={'wg-teamwork-quiz'} className='container py-12'>
				<iframe
					src='https://tally.so/embed/m6ZooY?transparentBackground=1&dynamicHeight=1'
					loading='lazy'
					width='100%'
					height='253'
					frameBorder='0'
					marginHeight='0'
					marginWidth='0'
					title='Teamwork Quiz'
				></iframe>
			</div>
		</WorkshopLayout>
	);
};

export default TeamWorkQuiz;
