import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import AppInput from '../AppInput';
import AppDropdown from '../AppDropdown';

import { emailRegex } from '../../services/UtilityService';
import { useUser } from "../../hooks/UserProvider";

const GiveAccessFields = ({ disabledEmail = false }) => {
    const { user } = useUser();
    const { control, register, formState: { errors } } = useFormContext();

    return (
        <>
            <AppInput
                {...register('email', {
                    required: true,
                    validate: (value) => value.toLowerCase().trim() !== user.customers_email_address.toLowerCase() || 'Cannot give access to own account. Please choose another address!',
                    pattern: {
                        value: emailRegex,
                        message: 'should be a valid email',
                    },
                    maxLength: 96,
                })}
                containerClassName={'w-full md:w-72 lg:w-96 mr-4'}
                errors={errors}
                placeholder={'Email'}
                label={'Email'}
                disabled={disabledEmail}
            />
            <Controller
                control={control}
                name={'permission'}
                rules={{ required: true }}
                render={({ field, formState }) => (
                    <AppDropdown
                        id={'wg-permission-level-select'}
                        name={'wg-permission-level-select'}
                        {...field}
                        label={'Permission Level'}
                        isClearable
                        options={[{ label: 'Admin', value: 'ADMIN' }, { label: 'Limited', value: 'LIMITED' }]}
                        errors={formState.errors}
                        containerClassName={'mb-4 w-full md:w-44 mr-4'}
                        shouldShowErrorMessage
                        styles={{
                            option: (provided, { isFocused }) => ({
                                ...provided,
                                backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
                                color: 'text-gray-700',
                            }),
                        }}
                    />
                )}
            />
        </>
    );
};

export default GiveAccessFields;
