import React from 'react';

import AppButton from '../../components/AppButton';
import CareersEventHero from '../../components/careersevent/CareersEventHero';
import CareersPat from '../../components/careersevent/CareersPat';
import Event6Types from '../../components/event/Event6Types';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import ApiService from '../../services/ApiService';
import { save_the_date } from '../../images';
import UtilityService from '../../services/UtilityService';

const CareersEvent = () => {
	const [streamHTML, setStreamHTML] = React.useState('');
	const [resourceEnableDate, setResourceEnableDate] = React.useState(null);
	const [resourceDisasbleDate, setResourceDisasbleDate] = React.useState(null);

	const getStreamLinks = React.useCallback(() => {
		ApiService.get('/v1/live/jobscareersandhiringevent')
			.then(({ data }) => {
				setStreamHTML(data.url.live);
				setResourceEnableDate(data.url.resource_enable_date);
				setResourceDisasbleDate(data.url.resource_disable_date);
			})
			.catch(() => setStreamHTML(''));
	}, []);

	React.useEffect(() => {
		getStreamLinks();
	}, []);

	const isEventTimeframe = !(
		resourceEnableDate &&
		resourceDisasbleDate &&
		UtilityService.isUtcDateBetween(resourceEnableDate, resourceDisasbleDate)
	);

	return (
		<WorkshopLayout title={'The 6 Types of Working Genius: At the Whiteboard Event'}>
			<CareersEventHero streamHTML={streamHTML} />
			<div className='bg-secondary p-6'>
				<div className='flex flex-col lg:flex-row items-center justify-center text-center mx-auto'>
					<p className='text-white text-base md:text-2xl font-medium'>
						Download the new <span className='italic'>Working Genius Hiring Guide</span>.
					</p>
					<div className='flex flex-col items-center justify-center'>
						<AppButton
							id={'wg-at-the-table-live-event-btn'}
							className={'btn w-56 mx-6 my-2'}
							text={isEventTimeframe ? 'Free resource' : 'Download Now'}
							disabled={isEventTimeframe}
							onClick={() => {
								window.open(
									'https://files.tablegroup.com/wp-content/uploads/2023/05/04100359/Working-Genius-Hiring-Guide-3.pdf',
									'_blank'
								);
							}}
						/>
						{isEventTimeframe && (
							<span className='text-12 text-white text-center font-semibold uppercase tracking-widest'>
								Available May 17th
							</span>
						)}
					</div>
				</div>
			</div>
			<CareersPat />
			<div className='bg-primary px-6 py-12'>
				<div className='flex flex-col items-center justify-center text-center mx-auto'>
					<img src={save_the_date} alt='' width='270' />
					<p className='text-white text-lg md:text-3xl font-medium py-1'>
						Pat will be back at the Whiteboard
					</p>
					<p className='text-14 text-white text-center font-semibold uppercase tracking-widest'>
						November 8th
					</p>
				</div>
			</div>
			<Event6Types />
		</WorkshopLayout>
	);
};

export default CareersEvent;
