import React from 'react';
import { navigate } from 'gatsby';

import Layout from '../../../components/Layout';
import Hero from '../../../components/certification/prework/Hero';
import PreWork from '../../../components/certification/prework/PreWork';
import AppFAQ from '../../../components/AppFAQ';
import { useUser } from '../../../hooks/UserProvider';

const FAQ = [
	{
		title: 'Refund Policy',
		answer: 'We will honor all refunds up to 14 days before your scheduled Certification Session, should you need to cancel. If you wish to reschedule to a new date, please reach out to us via email at <a href="mailto:certification@workinggenius.com" class="font-bold">certification@workinggenius.com</a> or call us at (925) 299-9700. We are able to accommodate re-schedules once and up until max. 7 days in advance of an open session to allow for certification pre-work requirements.',
	},
	{
		title: 'Schedule for Training',
		answer: "Our Certification Sessions are designed to be interactive and dynamic! While the start times vary depending on each training (and your location in the world), each day will be roughly 4 hours of virtual instruction and discussion. We have planned a couple of 5-10 minute breaks for you to grab a snack etc., but we want to maximize our time together so we don't have large break times built in to the sessions.",
	},
	{
		title: 'Pre-Work',
		answer: 'The videos for pre-work were all created specifically for Working Genius Certification so that you have a great foundation before we start training. The pre-work portal has all of the videos and PDF documents that we are asking you to view prior to your training. That said, completing all of the pre-work will ensure that you feel most equipped to participate each day. ',
	},
	{
		title: 'Certification Training Access',
		answer: 'Please check your email for the zoom link for your training! We will send this about a week prior to your scheduled training.',
	},
	{
		title: 'Resources',
		answer: 'We are excited about all the ways that you will be able to use Working Genius with individuals and teams. To enable you to do so, we will be providing you with a slide deck, debriefing tools, and plenty of other resources following your certification training!',
	},
	{
		title: 'Facilitators',
		answer: 'We have hand selected a group of talented Working Genius Master Facilitators to conduct our public training. They are experts in how to apply the Working Genius to have the most impact with teams and individuals. They look forward to spending two energizing days with you to equip you to do the same!',
	},
];

const CertificationPrework = () => {
	const { user } = useUser();
	if (!user.hasPurchasedCertification) {
		navigate('/client/certification/', { replace: true });
		return null;
	}

	return (
		<Layout footerTheme={'dark'}>
			<Hero customer={user} />
			<PreWork customer={user} />
			<AppFAQ items={FAQ} />
			{user.hasCompletedPreWork && (
				<div className='flex flex-col justify-center bg-gray-200 px-4 py-12 md:py-24 text-center'>
					<h1 className='text-primary text-3xl leading-none pb-6'>Post-Training Resources</h1>
					<p className={'container text-primary'}>
						Upon completing the Online Certification Session, you will be able to access the Post-Training
						Resources.
					</p>
				</div>
			)}
		</Layout>
	);
};

export default CertificationPrework;
