import React from 'react';
import { report_li } from '../../../../images';

const PrintUl = ({ items }) => {
	return (
		<table className='ul' style={{ marginBottom: '1rem', fontSize: '14px' }}>
			<tbody>
				{items.map((item, key) => (
					<tr key={key}>
						<td className='bullet'>
							<img src={report_li} style={{ borderTop: '1px solid white', width: '13px' }} alt={''} />
						</td>
						<td style={{ paddingTop: '0.5rem' }}>&nbsp;&nbsp;&nbsp;{item}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default PrintUl;
