import React from 'react';
import { next_page_sticker, WidgetReportStudent, gear_rays_icon } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const Overview = () => {
    return (
        <div className='Overview'>
            <PageHeader title={'Introduction'} />

            <div className={'container brief'}>
                <div className='flex flex-row items-center gap-2'>
                    <img src={gear_rays_icon} width='70' />
                    <PageTitle title={'Why This Matters'} />
                </div>
                <p>
                    Here’s the thing: we are all naturally good at some things, and not so good at others. Knowing your strengths—and weaknesses—will not only enhance your academic and social experiences but will also contribute to your success in life. The Working Genius Assessment is the fastest and easiest way to discover your natural gifts. These insights will transform how you approach studying, engage with friends and family, select courses, determine your college major, and even navigate your future career.
                </p>
            </div>

            <div className='container six-types'>
                <h1 className='title mb-2'>THE SIX TYPES</h1>

                <hr />

                <p className='mt-3'>
                    Take a minute or two to understand the geniuses of Wonder, Invention, Discernment, Galvanizing,
                    Enablement, and Tenacity. And then, check out your results and learn which of the two come
                    naturally to you, meaning that you are good at them and that they give you energy and joy.
                    We call these your areas of <span className='italic'>Working Genius</span>. Two of them are
                    neither natural nor energizing for you. We call these your areas of <span className='italic'>Working Frustrations</span>.
                    Finally, two types fall in between. These are your areas of <span className='italic'>Working Competency</span>.
                </p>

                <img src={WidgetReportStudent} alt='' className={'w-3/4 mx-auto px-4 py-6'} />

                <div className='relative'>
                    <p>
                        <strong>WONDER</strong>: you naturally ponder things and ask big questions
                    </p>
                    <p>
                        <strong>INVENTION</strong>: you naturally create new and original ideas and solutions to problems
                    </p>
                    <p>
                        <strong>DISCERNMENT</strong>: you naturally use intuition to refine and assess ideas
                    </p>
                    <p>
                        <strong>GALVANIZING</strong>: you naturally rally people around actions or ideas
                    </p>
                    <p>
                        <strong>ENABLEMENT</strong>: you naturally provide help and support around ideas and projects
                    </p>
                    <p>
                        <strong>TENACITY</strong>: you naturally drive projects and tasks to completion
                    </p>
                    <img src={next_page_sticker} alt='Results in next page' width='140' className={'absolute top-0 right-0 mt-6'} />
                </div>
            </div>
        </div >
    );
};

export default Overview;
