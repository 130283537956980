import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { navigate, Link } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../components/Layout';
import CustomerService from '../../services/CustomerService';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import { getQueryParams, emailRegex, passwordRegex } from '../../services/UtilityService';
import NotificationService from '../../services/NotificationService';
import AppRIIcon from '../../components/AppRIIcon';
import ReCaptcha from '../../components/ReCaptcha';
import AppLabel from '../../components/AppLabel';
import AppRadioGroup from '../../components/AppRadioGroup';
import AppDropdown from '../../components/AppDropdown';
import { useUser } from '../../hooks/UserProvider';
import { useNotifications } from '../../hooks/NotificationProvider';

const Register = () => {
	const [loading, setLoading] = React.useState(false);
	const [linkError, setLinkError] = React.useState(false);
	const [registering, setRegistering] = React.useState(false);
	const [optIn, setOptIn] = React.useState(false);
	const captchaRef = React.useRef('captcha');
	const { setUser } = useUser();
	const { loadNotifications } = useNotifications();
	const location = useLocation();
	const { token, redirect, certification_token, permission_token, type, email } = getQueryParams(location.search);
	const decodeEmail = decodeURIComponent(email);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		clearErrors,
		control,
		watch,
	} = useForm();

	const companyValue = watch('company');

	useEffect(() => {
		if (!companyValue && !type) {
			clearErrors('role');
		}
	}, [companyValue]);

	const _verifyToken = () => {
		setLoading(true);
		const tokenToVerify = token ?? certification_token ?? permission_token;
		CustomerService.verifyInvite(tokenToVerify)
			.then(({ first_name, last_name, email }) => {
				setValue('first_name', first_name ?? '');
				setValue('last_name', last_name ?? '');
				setValue('email', email);
			})
			.catch(() => {
				setLinkError(true);
			});
	};

	React.useEffect(() => {
		if (token || certification_token || permission_token) _verifyToken();
		if (email) setValue('email', decodeEmail);
		setLoading(false);
	}, []);

	const _register = async (payload) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setRegistering(true);

		const basePayload = {
			email: payload.email,
			first_name: payload.first_name,
			last_name: payload.last_name,
			password: payload.password,
			captcha: recaptchaValue,
			opt_in: payload.opt_in,
			role: payload?.role?.value || 'OTHER',
			...(payload.company ? { company: payload.company } : {}),
		};

		let registerPayload;

		if (token) {
			registerPayload = {
				token,
				password: payload.password,
				company: payload.company || null,
				captcha: recaptchaValue,
				opt_in: payload.opt_in,
				role: payload?.role?.value || 'OTHER',
			};
		} else if (permission_token) {
			registerPayload = {
				...basePayload,
				permission_token,
			};
		} else {
			registerPayload = {
				...basePayload,
				certification_token,
				...(type && {
					type,
					has_taken_wg_assessment: 'No',
					working_genius_results: '',
				}),
			};
		}

		CustomerService.register(registerPayload)
			.then((u) => {
				setUser(u);
				loadNotifications();

				if (redirect) {
					return navigate(decodeURIComponent(redirect).replace('//', '/'), { replace: true });
				}

				navigate('/client/', { replace: true });
			})
			.catch((e) => NotificationService.error(e.message))
			.finally(() => setRegistering(false));
	};

	const renderRegisterOptInLabel = () => (
		<div>
			I’d like to receive helpful content from{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.workinggenius.com/'>
				Working Genius
			</a>{' '}
			and{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.tablegroup.com/'>
				The Table Group
			</a>
		</div>
	);

	if (loading) {
		return (
			<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		);
	}

	const renderContent = () => {
		if (linkError) {
			return (
				<div className={'flex flex-col justify-center mx-4 mt-10'}>
					<AppRIIcon icon={'link-unlink-m'} className={'text-red-600 text-6xl text-center pb-4'} />
					<h1 className={'text-primary text-xl text-center font-bold mb-4'}>Expired Link</h1>
					<p className='text-primary text-center w-160 max-w-full mx-auto mb-10'>
						Your create account link has expired or has already been used, you can access your account or
						initiate the create account process by clicking the button below.
					</p>
					<AppButton
						loading={loading}
						className={'btn btn-primary w-96 max-w-full mx-auto'}
						text={'Login'}
						onClick={() => navigate('/client/login')}
					/>
					<ReCaptcha ref={captchaRef} />
				</div>
			);
		}
		return (
			<>
				<div className='md:shadow-md sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto mt-12 mb-4 px-4 md:px-12 py-8 text-center'>
					<h1 className='uppercase text-secondary text-center text-3xl font-semibold mb-4 leading-none'>
						CREATE ACCOUNT
					</h1>
					<form
						className={'w-full'}
						onSubmit={handleSubmit(_register)}
						autoComplete='autocomplete_off_hack_xfr4!k'
					>
						<AppInput
							{...register('first_name', {
								required: true,
								maxLength: 32,
							})}
							ttgMaxLength={32}
							label={'First name'}
							errors={errors}
							disabled={token}
						/>
						<AppInput
							{...register('last_name', {
								required: true,
								maxLength: 32,
							})}
							ttgMaxLength={32}
							label={'Last name'}
							errors={errors}
							disabled={token}
						/>
						<AppInput
							{...register('email', {
								required: true,
								pattern: {
									value: emailRegex,
								},
								maxLength: 96,
							})}
							ttgMaxLength={96}
							label={'Email address'}
							errors={errors}
							disabled={token || certification_token || type || permission_token}
						/>
						<AppInput
							{...register('company', {
								required: !!type,
								maxLength: 64,
							})}
							ttgMaxLength={64}
							label={
								<span>
									Company Name{' '}
									<span className='text-gray-400 font-thin'>{!type ? '(Optional)' : ''}</span>
								</span>
							}
							errors={errors}
						/>
						<Controller
							control={control}
							name={'role'}
							rules={{ required: !!type || !!companyValue }}
							containerClassName={'mb-4'}
							render={({ field, formState }) => (
								<AppDropdown
									id={'wg-signup-role-select'}
									name={'wg-signup-role-select'}
									{...field}
									label={'Role'}
									isClearable
									options={CustomerService.CustomerBusinessRoles}
									errors={formState.errors}
									loading={false}
									containerClassName={'mb-4 text-left'}
									shouldShowErrorMessage
									styles={{
										option: (provided, { isFocused }) => {
											return {
												...provided,
												backgroundColor: isFocused
													? 'bg-blue-200 !important'
													: 'white !important',
												color: 'text-gray-700',
											};
										},
									}}
								/>
							)}
						/>
						<AppInput
							label={'Password'}
							type={'password'}
							errors={errors}
							{...register('password', {
								required: true,
								pattern: {
									value: passwordRegex,
									message:
										'Password must contain at least 8 characters, 1 upper case character, 1 lowercase character and 1 number.',
								},
							})}
						/>
						<AppInput
							label={'Confirm password'}
							type={'password'}
							errors={errors}
							{...register('confirm_password', {
								required: true,
								validate: (value) => value === getValues('password') || 'Passwords do not match',
							})}
						/>

						<div id={'register-opt-in'} className={'flex flex-col mb-3'}>
							<AppLabel
								label={renderRegisterOptInLabel()}
								labelIcon={'info-circled'}
								hasError={!!errors['opt_in']}
								customClassName={'inline-block text-left pb-1'}
							/>
							<AppRadioGroup
								{...register('opt_in', {
									required: 'is required',
								})}
								name={'opt_in'}
								errors={errors}
								value={optIn}
								options={[
									{
										label: 'Yes',
										value: 1,
									},
									{
										label: 'No',
										value: 0,
									},
								]}
								onChange={(selectedOption) => {
									setOptIn(selectedOption);
									setValue('opt_in', selectedOption);
									clearErrors('opt_in');
								}}
							/>
						</div>

						<AppButton
							type='submit'
							className={'btn btn-primary w-full my-6'}
							text={'Create account'}
							loading={registering}
							id={'wg-create-account-submit-btn'}
						/>
						<ReCaptcha ref={captchaRef} />
					</form>
					<span className='text-sm text-gray-600 mb-4 w-auto'>
						By clicking "Create Account" you agree Working Genius{' '}
						<span
							onClick={() => navigate('/policy/terms-of-service')}
							className='text-secondary font-medium cursor-pointer'
						>
							Terms of Service
						</span>{' '}
						and{' '}
						<span
							onClick={() => navigate('/policy/privacy-policy')}
							className='text-secondary font-medium cursor-pointer'
						>
							Privacy Policy
						</span>
						.
					</span>
				</div>
				<p className='text-sm text-gray-600 text-center mx-auto mb-10 '>
					Already have an account?{' '}
					<Link
						to={`/client/login${redirect ? `?redirect=${redirect}` : ''}`}
						className='text-secondary font-medium'
					>
						Login
					</Link>
				</p>
			</>
		);
	};

	return (
		<Layout>
			<div className={'container mx-auto'}>{renderContent()}</div>
		</Layout>
	);
};

export default Register;
