import React from 'react';
import { live_webinar, meetings_event_heading_white, at_the_whiteboard_live_placeholder } from '../../images';

const EventLiveHero = ({ streamHTML }) => {
    return (
        <div className='at-the-whiteboard-meetings-hero-section'>
            <div className='container'>
                <div className='webinar-container'>
                    <div className='relative flex flex-col items-center'>
                        <img className={'live-webinar-badge'} src={live_webinar} alt='' width='180' />
                        <img className={'relative p-4'} src={meetings_event_heading_white} alt='' width='580' />
                    </div>
                    <div className='flex flex-col justify-center items-center my-6'>
                        <p className='text-white text-xl font-semibold tracking-wide'>November 8th at 11:00AM Pacific Time</p>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: streamHTML }} />
                    {!streamHTML && (
                        <div className='w-full lg:w-3/4 mx-auto bg-white flex flex-col items-center justify-center my-8'>
                            <img src={at_the_whiteboard_live_placeholder} alt='At the Whiteboard live Event' />
                            <div className={'whiteboard-placeholder-content'}>
                                <span className={'lg:text-2xl'}>
                                    Live Webinar will begin <br /> at 11 AM PT/ 2 PM ET
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EventLiveHero;
