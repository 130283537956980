import React from 'react'

const StoreHero = () => {
    return (
        <div className='store-hero'>
            <h1 className='title'>Working Genius Store</h1>
        </div>
    )
}

export default StoreHero