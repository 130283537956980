import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import AppRIIcon from '../../AppRIIcon';
import AppModal from '../../AppModal';
import AppButton from '../../AppButton';
import { formatDate } from '../../../services/UtilityService';
import WgIcon from '../../WgIcon';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
		maxHeight: '95vh',
	},
};

const SharePreviousAssessmentModal = ({ isOpen, loading, onContinue, assessments, closeModal, language }) => {
	i18n.changeLanguage(language);
	const { t } = useTranslation();

	return (
		<AppModal id={'wg-share-previous-assessment-modal'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='bg-white rounded-lg py-4 px-8'>
				<p className='flex'>
					<span className='text-lg leading-6 font-medium flex-1'>
						{t('assessment.take.welcome.previous_assessments.working_genius_assessments')}
					</span>
					<AppRIIcon
						icon='close-line'
						className='text-gray-400 text-lg h-100 px-2 cursor-pointer'
						onClick={closeModal}
					/>
				</p>
				<span className='text-sm leading-5 font-normal text-gray-500 mt-2'>
					{t('assessment.take.welcome.previous_assessments.located_your_assessments')}
				</span>
				<p className='text-base leading-6 font-medium my-2'>
					{t('assessment.take.welcome.previous_assessments.recent_assessments')}
				</p>
				{assessments.map((assessment, key) => {
					const isGroup = assessment.type === 'group';
					return (
						<div
							className={'flex flex-1 flex-col items-center border-1 border-gray-300 p-4 mb-2'}
							key={key}
						>
							<div className={'flex flex-1 items-center'}>
								<WgIcon item={assessment} className={'text-primary text-2xl'} />
								<div className='flex flex-1 flex-col overflow-hidden mx-2'>
									<p className={'font-medium text-primary truncate break-all'}>
										{assessment.fname} {assessment.lname}
									</p>
									<p className={'text-primary truncate break-all'}>
										{isGroup
											? assessment.group
											: t('assessment.take.welcome.previous_assessments.individual_assessment')}
									</p>
									<p className={'font-medium text-primary'}>
										{t('assessment.take.welcome.previous_assessments.completed')}:{' '}
										<span className={'font-normal'}>{formatDate(assessment.completed)}</span>
									</p>
								</div>
							</div>
							<AppButton
								className={'btn btn-primary w-full mt-2'}
								text={t('assessment.take.welcome.previous_assessments.share_results')}
								loading={loading}
								id={'wg-share-existing-assessment'}
								onClick={() => onContinue(assessment)}
							/>
						</div>
					);
				})}
				<AppButton
					className={'btn btn-secondary w-full mt-2'}
					text={t('assessment.take.welcome.previous_assessments.retake_assessment')}
					loading={loading}
					id={'wg-retake-assessment-btn'}
					onClick={() => onContinue(null)}
				/>
			</div>
		</AppModal>
	);
};

export default SharePreviousAssessmentModal;
