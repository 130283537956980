const craves = {
	consideration: {
		title: 'Consideración. ',
		description:
			'Quieren que las personas consideren genuinamente lo que están pensando y que escuchen sus preguntas. «Oye, esa es una excelente pregunta».',
	},
	freedom: {
		title: 'Libertad. ',
		description: 'Quieren libertad para crear e inventar sin ataduras o restricciones.',
	},
	trust: {
		title: 'Confianza. ',
		description: 'Quieren que confíes en su juicio, instintos y su manera de evaluar las cosas.',
	},
	reaction: {
		title: 'Reacción. ',
		description: 'Quieren compromiso y confirmación de que eso por lo que están abogando es real y vale la pena.',
	},
	appreciation: {
		title: 'El agradecimiento. ',
		description:
			'Quieren ser reconocidos por su valor inherente, pero no necesariamente buscan reconocimiento, atención o recibir el crédito. «Eso marcó una gran diferencia. Ayudaste mucho».',
	},
	clarity: {
		title: 'Claridad. ',
		description:
			'Quieren fechas de entrega y parámetros. «Dime exactamente qué necesitas; después, déjame hacer lo que sea necesario para terminar la tarea».',
	},
};

const crushes = {
	being_dismissed: {
		title: 'No ser tomados en cuenta. ',
		description:
			'No les gusta escuchar: «¿A quién le importa eso?» o «No tenemos tiempo para detenernos a pensar en eso».',
	},
	constraint: {
		title: 'Sentirse limitados. ',
		description: 'No les gustan los parámetros restrictivos o ser forzados a quedarse «dentro de la caja».',
	},
	prove_it: {
		title: 'Que les digan: «¡Pruébalo!». ',
		description: 'No les gusta tener que argumentar para que la gente crea eso que su instinto les está diciendo.',
	},
	apathy: {
		title: 'La apatía. ',
		description:
			'Odian que nadie responda a su llamado a la acción o que su entusiasmo sea respondido con silencio.',
	},
	being_overlooked: {
		title: 'Ser ignorados. ',
		description:
			'No les gusta que sus esfuerzos no sean valorados, que no reconozcan su ayuda o que ignoren el impacto de su trabajo.',
	},
	ambiguity: {
		title: 'La ambigüedad. ',
		description:
			'No les gustan los estándares y las instrucciones poco claras ni los cambios repentinos de expectativas.',
	},
};

const PC = {
	sort: 1,
	title: 'El soñador creativo',
	brief: 'Un idealista apasionado con una fuente inagotable de preguntas importantes e ideas que considerar. Se siente cómodo con la cabeza en las nubes.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de contemplar el mundo a su alrededor y de generar una fuente de ideas nuevas.  Son por lo general idealistas y orientados al futuro. Sienten pasión por las nuevas posibilidades que rompen con límites innecesarios.  No valoran la practicidad, el enfoque o la implementación, tanto como el idealismo y la inventiva.  En ocasiones, esto conduce al estrés o al caos entre las personas a su alrededor, quienes pueden estar buscando más realismo y ejecución.',
	crave: [craves.consideration, craves.freedom],
	crush: [crushes.being_dismissed, crushes.constraint],
};

const CD = {
	sort: 2,
	title: 'El analizador creativo',
	brief: 'Un generador de ideas nuevas, creativo, intuitivo y seguro. Usa sus instintos y pensamiento integrador para solucionar problemas reales.',
	description:
		'Las personas con este par obtienen mucha energía y alegría al usar su intuición y habilidad instintiva para desarrollar nuevas ideas desde cero. Además, dependen más de su capacidad de pensar de manera integradora y de reconocer patrones, que de la información o su pericia.  Como resultado de esto, son particularmente efectivos para solucionar problemas a través de soluciones prácticas y ejecutables, con un nivel de éxito especialmente alto.  En ocasiones, pueden parecer impacientes y arrogantes cuando experimentan rechazo a sus ideas, y a menudo pierden interés una vez que se termina la fase inicial de Creatividad.',
	crave: [craves.freedom, craves.trust],
	crush: [crushes.constraint, crushes.prove_it],
};

const DF = {
	sort: 3,
	title: 'El colaborador perspicaz',
	brief: 'Un consejero y jugador de equipo intuitivo y empático. Altruista y compasivo capaz de proveerles a los demás lo que necesitan inmediatamente.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de ayudar a otros con astucia y empatía, basándose en su intuición e instintos.  Son jugadores de equipo por excelencia y aportan consejos excepcionales, oportunos y astutos, de una manera desinteresada.  En ocasiones, pueden dudar al momento de presionar a otras personas para que hagan caso de sus consejos y son respetuosos cuando sienten resistencia.  Estas tendencias pueden a menudo hacerlos sentirse poco apreciados.',
	crave: [craves.trust, craves.appreciation],
	crush: [crushes.prove_it, crushes.being_overlooked],
};
const PD = {
	sort: 4,
	title: 'El consejero contemplativo',
	brief: 'Un consejero considerado, perspicaz y de criterio amplio. Lento para afirmar que tiene certeza sobre algo, pero con una profunda sabiduría, intuición y lógica.',
	description:
		'Las personas con este par obtienen verdadera alegría y satisfacción de usar su intuición e instintos para evaluar ideas y planes, y para ofrecerles a los demás retroalimentación a conciencia.  Tienden a ser extremadamente observadores y profundos en sus pensamientos, y expresan sus ideas a través de palabras impactantes.  Debido a su propensión a ver matices, en ocasiones pueden dudar al momento de expresar certeza o confianza en sus propios juicios.',
	crave: [craves.consideration, craves.trust],
	crush: [crushes.being_dismissed, crushes.prove_it],
};

const CI = {
	sort: 5,
	title: 'El innovador convincente',
	brief: 'Un generador y promotor entusiasta y convincente de ideas nuevas. Combina la curiosidad y la confianza con un entusiasmo contagioso.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía a partir de generar ideas nuevas y de convencer a los demás del mérito de sus ideas.  Son exuberantes y entusiastas. No se dejan desanimar fácilmente por el rechazo inicial y disfrutan el desafío que significa compartir ideas para convencer a los demás de que deben actuar.  Pueden apresurarse para vender una idea, antes de tomarse el tiempo para considerar a fondo la prudencia de la misma, e inicialmente pueden ser percibidos como alguien ligeramente exagerado en su manera de abordar a los demás.',
	crave: [craves.freedom, craves.reaction],
	crush: [crushes.constraint, crushes.apathy],
};

const DT = {
	sort: 6,
	title: 'El productor sensato',
	brief: 'Un hacedor confiable, prudente y enfocado. Una combinación única de urgencia práctica y juicio intuitivo.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de hacer las cosas correctas de manera eficiente y efectiva, y usan su juicio para evitar perder tiempo y recursos.  Logran hacer mucho a través de identificar las prioridades más altas y apegarse a estándares altos de ejecución.  Son prudentes, enfocados y diligentes.  A menudo sienten angustia innecesariamente ante la posibilidad de fallar o cuando trabajan con otros que no comparten sus estándares elevados y empuje por la finalización.',
	crave: [craves.trust, craves.clarity],
	crush: [crushes.prove_it, crushes.ambiguity],
};

const PI = {
	sort: 7,
	title: 'El motivador filosófico',
	brief: 'Un proponente emocionado, curioso y entusiasta de ideas y personas. Una combinación única de entusiasmo y cuidado.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de contemplar el potencial del mundo que los rodea, y al exhortar y animar a otros.  Son idealistas y entusiastas.  En ocasiones, su combinación los lleva a experimentar y representar un sentido de impulsividad e imprevisibilidad, ya que se mueven constantemente entre las acciones apasionadas y la reflexión cautelosa.',
	crave: [craves.consideration, craves.reaction],
	crush: [crushes.being_dismissed, crushes.apathy],
};

const CF = {
	sort: 8,
	title: 'El diseñador adaptable',
	brief: 'Un generador de ideas nuevas como respuesta a las necesidades de los demás. Una combinación única de creatividad y flexibilidad.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de proveer soluciones e ideas nuevas que satisfacen las necesidades únicas de otras personas.  Tienen la rara habilidad de equilibrar su pasión por la inventiva con la capacidad de ajustarse a los deseos de las personas a las que están sirviendo.  Es fácil trabajar con ellas, pues son creativas, flexibles, abiertas a la retroalimentación.  En su deseo de complacer a otros, se adaptan rápidamente y evitan promover sus propias ideas.  Esto puede terminar por hacerlos sentirse subestimados.',
	crave: [craves.freedom, craves.appreciation],
	crush: [crushes.constraint, crushes.being_overlooked],
};

const FI = {
	sort: 9,
	title: 'El animador entusiasta',
	brief: 'Alguien capaz de afirmar a los demás de manera cálida y positiva. Rápido para apoyar e inspirar a las personas que necesitan energía o afirmación.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de inspirar y ayudar a otros a crecer, mejorar y sentirse bien consigo mismos.  Son positivos, exuberantes y optimistas, demuestran alegremente su entusiasmo por el potencial de los demás.  Son animadores consumados en el sentido más estricto de la palabra.   En su deseo por ser positivos, pueden apresurarse a la hora de afirmar a otros y darles cumplidos injustificados, lo que los pone en una posición en la que potencialmente pueden ser ignorados o percibidos como ingenuos o poco sinceros.',
	crave: [craves.reaction, craves.appreciation],
	crush: [crushes.apathy, crushes.being_overlooked],
};

const PF = {
	sort: 10,
	title: 'El ayudante idealista',
	brief: 'Un colaborador leal, modesto y atento. Considera cuidadosamente las necesidades de los demás y se rehúsa a ser el centro de atención.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de ayudar de manera significativa a las causas que se alinean con sus valores idealistas y sentido de pertenencia.  A menudo son modestas con relación a sus habilidades, son leales y altruistas, demostrando ser miembros extremadamente valiosos y poco demandantes.  Debido a su profundidad y deseo de complacer a otros, en ocasiones pueden ser demasiado respetuosos, a menudo dudan para iniciar un cambio y prefieren que sea alguien más quien dé el primer paso.',
	crave: [craves.consideration, craves.appreciation],
	crush: [crushes.being_dismissed, crushes.being_overlooked],
};

const CT = {
	sort: 11,
	title: 'El arquitecto metódico',
	brief: 'Un solucionador de problemas preciso, confiable y planificado. Una combinación única de pensamiento innovador e implementación práctica.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de diseñar soluciones prácticas a problemas, basándose en la inventiva, la razón y la precisión.  Trabajan de manera constante y confiable a la hora de generar ideas nuevas y no se distraen fácilmente ante posibilidades que pueden resultar poco factibles desde un punto de vista logístico.  En su búsqueda obstinada de una solución factible, en ocasiones pueden perder de vista los matices de una cierta situación o pasar por alto las necesidades humanas.',
	crave: [craves.freedom, craves.clarity],
	crush: [crushes.constraint, crushes.ambiguity],
};

const IT = {
	sort: 12,
	title: 'El conductor asertivo',
	brief: 'Un supervisor extraordinario. Dispuesto a empujar a los demás y a mantenerlos enfocados, e incluso poner manos a la obra para asegurarse de que se cumplan los objetivos.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de hacer que las cosas sucedan, así como de reunir a otros para que los acompañen en esa ejecución.  No se sienten intimidados por tener que superar obstáculos e incluso se sienten cómodos cuando, en ocasiones, es necesario ignorar protocolos sociales para llevar una tarea hasta el final.  Son personas con convicciones y estándares elevados.  Por tanto, es comprensible que en ocasiones su insistencia parezca agresiva, y a menudo se impacientan cuando hay demasiada planificación y lluvia de ideas, pues prefieren ir directo al trabajo.',
	crave: [craves.reaction, craves.clarity],
	crush: [crushes.apathy, crushes.ambiguity],
};

const PT = {
	sort: 13,
	title: 'El implementador cuidadoso',
	brief: 'Un colaborador minucioso, práctico y cuidadoso. Una combinación única de confiabilidad constante y profundidad silenciosa.',
	description:
		'Las personas con este par obtienen verdadera alegría y satisfacción al hacer algo de manera minuciosa y confiable, siempre y cuando entiendan las razones y la importancia de lo que están haciendo.  Son una mezcla misteriosa de practicidad y curiosidad, acción y reflexión.  Como resultado de la divergencia entre Pensamiento y Tenacidad (pues son los extremos opuestos del proceso de trabajo), ellos a menudo experimentan y proyectan angustia en medio de un proyecto, lo que los lleva a sentir una casi abrumadora necesidad de bajar la velocidad y hacer preguntas que puedan mitigar problemas potenciales.',
	crave: [craves.consideration, craves.clarity],
	crush: [crushes.being_dismissed, crushes.ambiguity],
};

const DI = {
	sort: 14,
	title: 'El activador intuitivo',
	brief: 'Alguien capaz de tomar decisiones de manera intuitiva y confiada. Capaz de evaluar situaciones rápido y con precisión, y de reunir a las personas para ponerlas en acción.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de evaluar ideas y situaciones de manera instintiva, e identifican rápidamente el curso de acción correcto.  Por lo general hacen evaluaciones rápidas y sorprendentemente precisas, a menudo sin información o conocimientos específicos y reúnen a otras personas de manera ingeniosa para que estén de acuerdo con ellos y su propensión a la acción.  Gracias a sus habilidades y confianza, en ocasiones pueden ser displicentes en sus declaraciones y parecer impacientes o despectivos al momento de considerar los detalles esenciales para la implementación.',
	crave: [craves.trust, craves.reaction],
	crush: [crushes.prove_it, crushes.apathy],
};

const FT = {
	sort: 15,
	title: 'El finalizador leal',
	brief: 'Un hacedor receptivo y confiable. Dispuesto a dar un paso al frente cuando se necesite algo y determinado a cumplir con sus compromisos.',
	description:
		'Las personas con este par obtienen verdadera alegría y energía de finalizar tareas y responder a las necesidades de otros, mientras buscan hacer que las cosas sucedan.  Responden rápidamente ante un llamado a la acción, y le asignan igual valor a la capacidad de respuesta y a la confiabilidad.  Son colaboradores poco demandantes, quienes se hacen responsables de cumplir con lo que han prometido.  Les cuesta decir «no» a las peticiones, por lo que se comprometen en exceso y son propensos a agotarse y a sentirse poco valorados.',
	crave: [craves.appreciation, craves.clarity],
	crush: [crushes.being_overlooked, crushes.ambiguity],
};

const getAbility = (ability) => {
	const mapping = {
		Wonder: 'Pensamiento',
		Invention: 'Creatividad',
		Discernment: 'Discernimiento',
		Galvanizing: 'Influencia',
		Enablement: 'Facilitación',
		Tenacity: 'Tenacidad',
	};

	return mapping[ability];
};

const getCategory = (category) => {
	const mapping = {
		genius: 'Genialidades',
		competency: 'Competente',
		frustration: 'Frustran',
	};

	return mapping[category];
};

const getUniquePairing = (a, b) => {
	const sortWord = 'WIDGET';
	const first_letter = a[0];
	const second_letter = b[0];
	const first_idx = sortWord.indexOf(first_letter);
	const second_idx = sortWord.indexOf(second_letter);
	const mapping = { W: 'P', I: 'C', D: 'D', G: 'I', E: 'F', T: 'T' };
	return first_idx < second_idx
		? `${mapping[first_letter]}${mapping[second_letter]}`
		: `${mapping[second_letter]}${mapping[first_letter]}`;
};

const ReportUniquePairingsES = {
	genius_color: '#00a049',
	competency_color: '#fbc331',
	frustration_color: '#c06e60',
	getUniquePairing,
	getAbility,
	getCategory,
	UniqueParings: {
		FT,
		IT,
		PT,
		CT,
		DT,
		CI,
		PC,
		DI,
		CD,
		PF,
		CF,
		FI,
		DF,
		PD,
		PI,
	},
	Results: {
		Genialidades: (a, b, color) =>
			`Las genialidades en las que eres sobresalientes son <span class="font-semibold" style="color: ${color}">${a}</span> ${
				b.toLowerCase().startsWith('i') ? 'e' : 'y'
			} <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Competente: (a, b, color) =>
			`Las Genialidades en las que eres competente son <span class="font-semibold" style="color: ${color}">${a}</span> ${
				b.toLowerCase().startsWith('i') ? 'e' : 'y'
			} <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Frustran: (a, b, color) =>
			`Las áreas de genialidad que te frustran son <span class="font-semibold" style="color: ${color}">${a}</span> ${
				b.toLowerCase().startsWith('i') ? 'e' : 'y'
			} <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Pensamiento: {
			genius: `Estás naturalmente diseñado para dar u obtener energía y alegría a través ponderar la posibilidad de que exista un mayor potencial y oportunidad ante una situación específica.`,
			competency: `Eres capaz de ponderar la posibilidad de que exista un mayor potencial y oportunidad ante una situación específica, y no tienes problema con hacerlo.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía a través de ponderar la posibilidad de que exista un mayor potencial y oportunidad ante un situación específica.`,
		},
		Creatividad: {
			genius: `Estás naturalmente diseñado para dar u obtener energía y alegría a través de crear ideas y soluciones originales y novedosas.`,
			competency: `Eres capaz de reunir a las personas e inspirarlas a tomar acción en torno a un proyecto, tarea o idea, y no tienes problema con hacerlo.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía y alegría a través de crear ideas y soluciones originales y novedosas.`,
		},
		Discernimiento: {
			genius: `Estás naturalmente diseñado para usar tu intuición e instintos para evaluar y revisar ideas o planes.`,
			competency: `Eres capaz de usar tu intuición e instintos para evaluar y revisar ideas o planes, y no tienes problema con hacerlo.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía y alegría a través usar tu intuición e instintos para evaluar y revisar ideas o planes.`,
		},
		Influencia: {
			genius: `Estás naturalmente diseñado para dar u obtener energía y alegría a través de reunir a las personas e inspirarlas a tomar acción en torno a un proyecto, tarea o idea.`,
			competency: `Eres capaz de reunir a las personas e inspirarlas a tomar acción en torno a un proyecto, tarea o idea, y no tienes problema con hacerlo.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía y alegría a través de reunir a las personas e inspirarlas a tomar acción en torno a un proyecto, tarea o idea.`,
		},
		Facilitación: {
			genius: `Estás naturalmente diseñado para dar u obtener energía y alegría a través de animar y ayudar en proyectos e ideas.`,
			competency: `Eres capaz de animar y ayudar en proyectos e ideas, y no tienes problema con hacerlo.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía y alegría a través animar y ayudar en proyectos e ideas.`,
		},
		Tenacidad: {
			genius: `Estás naturalmente diseñado para dar u obtener energía y alegría a través de impulsar proyectos y tareas hasta su finalización para garantizar que se logren los resultados deseados.`,
			competency: `Eres capaz de impulsar proyectos y tareas hasta su finalización para garantizar que se logren los resultados deseados.`,
			frustration: `No estás naturalmente diseñado para dar u obtener energía y alegría a través de impulsar proyectos y tareas hasta su finalización para garantizar que se logren los resultados deseados.`,
		},
	},
	Geniuses: {
		genius: {
			description: (a, b, color) =>
				`De acuerdo con tu evaluación, tienes la genialidad ${
					a.toLowerCase().startsWith('d') || a.toLowerCase().startsWith('p') ? 'del' : 'de la'
				} <span class="font-bold uppercase" style="color: ${color}">${a}</span> ${
					b.toLowerCase().startsWith('d') || b.toLowerCase().startsWith('p') ? 'y el' : 'y la'
				} <span class="font-bold uppercase" style="color: ${color}">${b}</span>, y te las describimos a continuación. Estas son actividades que te alegran, energizan y apasionan. Como resultado de esto, eres bastante habilidoso en estas áreas.`,
			note: ``,
		},
		competency: {
			description: (a, b, color) =>
				`De acuerdo con tu evaluación, tus áreas competentes son <span class="font-bold uppercase" style="color: ${color}">${a}</span> ${
					b.toLowerCase().startsWith('i') ? 'e' : 'y'
				} <span class="font-bold uppercase" style="color: ${color}">${b}</span>, y te las describimos a continuación. Estas son actividades que no te hacen miserable, pero tampoco te llenan de alegría. Es importante que entiendas tus competencias porque la mayoría de las personas operan en estas áreas bastante bien durante un tiempo. Sin embargo, con el tiempo te desgastarás, si no tienes la oportunidad de ejercitar tus verdaderas genialidades.`,
			note: `Vale la pena resaltar que las personas se vuelven bastante buenas en las actividades que caen dentro de sus áreas competentes, ya sea porque las circunstancias de su profesión o de la vida los han obligado a serlo, o porque tienen una fuerte propensión para sobresalir en casi todo lo que hacen. Sin embargo, al compararlas con sus genialidades Sobresalientes, estas actividades no les dan la misma energía o alegría y no tienen un don <span class="italic">natural</span> para hacerlas. Adicionalmente, si pasas mucho tiempo en tus áreas de competencia, al final terminarás exasperado o agotado.`,
		},
		frustration: {
			description: (a, b, color) =>
				`De acuerdo con tu evaluación, tus áreas de frustración son <span class="font-bold uppercase" style="color: ${color}">${a}</span> ${
					b.toLowerCase().startsWith('i') ? 'e' : 'y'
				} <span class="font-bold uppercase" style="color: ${color}">${b}</span>, y te las describimos a continuación. Es muy importante que conozcas tus frustraciones por dos razones: primero, debes evitar pasar mucho tiempo haciendo actividades en estas áreas porque normalmente te desgastarán y te harán sentir insatisfecho; segundo, no querrás sentirte innecesariamente avergonzado o culpable por no tener un don natural en estas áreas.`,
			note: `Recuerda que algunas personas pueden convertirse en expertos haciendo actividades que caen en sus áreas de Frustración, ya sea porque sus profesiones o circunstancias los han forzado a hacerlo, o porque tienen un inagotable deseo de sobresalir en casi todo lo que hacen. Sin embargo, al compararlas con sus genialidades Sobresalientes, estas actividades no les dan verdadera energía o alegría y no tienen un don <span class="italic">natural</span> para hacerlas. Adicionalmente, invertir una cantidad <span class="italic">significativa</span> de tiempo en tus áreas de frustración te llevarán a la exasperación y al agotamiento.`,
		},
		Wonder: `La genialidad del Pensamiento se distingue por ponderar, hacer preguntas y contemplar las razones por las que las cosas son como son. Las personas con esta genialidad obtienen su energía y alegría de observar reflexivamente su entorno y de preguntarse si existe una manera mejor o diferente. Comparada con las demás genialidades, el pensamiento no es la más evidente, porque es un proceso mayormente interno. Sin embargo, casi toda nueva iniciativa, programa o proyecto, comienza porque alguien se hace una pregunta. Los beneficios de esta genialidad incluyen hacer preguntas importantes, incitar a las personas a considerar suposiciones que requieren ser cuestionadas y desafiar el statu quo.`,
		Invention: `La genialidad de la Creatividad se distingue por desarrollar ideas y soluciones nuevas para resolver y abordar problemas. Esta genialidad incluye la capacidad de inventiva y de concebir ideas originales, sin la necesidad de mucha dirección. Las personas con esta genialidad se sienten confiadas e inspiradas ante un problema que no tiene solución aparente, y obtienen energía y alegría al recibir la oportunidad de ser los primeros en desarrollar una idea. Los beneficios de esta genialidad normalmente son bien comprendidos en la sociedad e incluyen todo lo que está relacionado con la innovación, el pensamiento novedoso y la búsqueda de una solución original a un problema.`,
		Discernment: `La genialidad del Discernimiento se distingue por emitir juicios sensatos con base en el instinto o la intuición ante una gran diversidad de situaciones. Recurre al reconocimiento de patrones y al pensamiento integrador, más que a la pericia, el conocimiento o datos. Como tal, las personas con discernimiento tienen la facilidad para evaluar si una idea es buena o no, o si requiere modificaciones y ajustes, lo que les da energía y alegría. El discernimiento tiene muchos beneficios, aunque algunos sean difíciles de señalar como resultado de la naturaleza intuitiva de este don. Ellos incluyen organizar, evaluar e identificar las mejores ideas, evitar aquellas que no sean buenas o refinar las que aún no están listas.`,
		Galvanizing: `La genialidad de la Influencia se distingue por reunir y motivar a las personas en torno a proyectos, ideas o iniciativas. Las personas con el don de la influencia obtienen su energía y alegría a partir de inspirar y persuadir a otros a tomar acción para poner algo en movimiento, incluso si eso significa convencerlos de repensar algo o cambiar sus planes. La influencia es relativamente fácil de identificar ya que es una actividad observable y a menudo pública. Los beneficios de esta genialidad incluyen alimentar el entusiasmo de las personas con relación al potencial que existe, a través de generar energía e ímpetu en torno a un programa o idea.`,
		Enablement: `La genialidad de la Facilitación se distingue por responder al llamado a la acción y proveer de manera fluida el apoyo y la ayuda incondicional que los demás necesitan. Las personas con esta genialidad obtienen su energía y alegría cuando proveen el apoyo que los demás necesitan para iniciar una actividad y cuando son esa especie de pegamento que permite que las personas se mantengan unidas. Aunque las personas con la genialidad de la Facilitación son a menudo jugadores ideales de equipo, a veces subestiman la importancia de su genialidad y erróneamente creen que simplemente tienen disposición o son amables. Los beneficios de esta genialidad incluyen un alto sentido moral y un mayor agradecimiento y apoyo interpersonal.`,
		Tenacity: `La genialidad de la Tenacidad se distingue por la ejecución. Incluye la capacidad para hacer que las cosas sucedan, lograr resultados y alcanzar el impacto deseado en una tarea o proyecto. Las personas con esta genialidad obtienen su energía y alegría de completar las tareas en una lista, y de saber que cumplen con los estándares para llevarlas a cabo. También se sienten cómodas con superar obstáculos. Los beneficios de esta genialidad giran en torno a asegurarse de que se cumplan las metas y que los proyectos se completen a tiempo, incluso cuando surjan problemas inevitables.`,
	},
};

export default ReportUniquePairingsES;
