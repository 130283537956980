import React from 'react';
import AppTour from './AppTour';

const AssessmentListTour = () => {
	const steps = [
		{
			selector: '#wg-assessment-results',
			content: `This are your assessment results. Each color indicates a Working Genius group. `,
		},
		{
			selector: '[data-wg="wg-download-roster-action"]',
			content: `You can also download the report and check out the results of the assessment.`,
		},
		{
			selector: '[data-wg="wg-assessment-edit-results-action"]',
			content: `You can now edit your Assessment Results`,
		},
		{
			selector: '[data-wg="assessment-share"]',
			content: `Did you know that you could send your assessment results to other Working Genius users? Click “Share Assessment” under the Actions section to send your results. The results will remain in your portal, but this feature allows others to see your results and use them for the “Create Team Map” functionality.`,
		},
		{
			selector: '[data-wg="wg-move-assessment-action"]',
			content: `You can now move an assessment into a Group, by entering the Group Name or selecting Create a New Group. Once you click “Move Assessment” it will be relocated in your console into that Group.`,
		},
		{
			selector: '[data-wg="assessment-generate-team-map"]:not([disabled])',
			content: `You can now easily generate a Team Map by clicking this button.`,
		},
	];

	return <AppTour steps={steps} cookie={'WGAssessmentListTourCOOKIE'} />;
};

export default AssessmentListTour;
