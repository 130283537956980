import React from 'react';
import ReCaptcha from '../ReCaptcha';
import AppIcon from '../AppIcon';
import AppRIIcon from './../AppRIIcon';
import NotificationService from '../../services/NotificationService';

const EmailCollector = ({ title, onSubmit, successMessage }) => {
    const [submitted, setSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const captchaRef = React.useRef('captcha');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const recaptchaValue = await captchaRef.current.getCaptchaValue();
        setLoading(true);
        onSubmit({ email: e.target.email.value, captcha: recaptchaValue })
            .then(() => {
                setSubmitted(true);
            })
            .catch((error) => {
                NotificationService.error(error.message, null, { duration: Infinity });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className='email-collector'>
            <div className='flex flex-col lg:flex-row items-center justify-center mx-auto'>
                {!submitted ? (
                    <>
                        <p className='text-base md:text-2xl text-white font-medium px-8'>{title}</p>
                        <form className='input-button-combined' onSubmit={handleSubmit} method='post'>
                            <input required type='email' name='email' placeholder='Your email' />
                            <button type='submit' className='relative' disabled={loading}>
                                {loading ? (
                                    <p className='absolute top-0 left-0 right-0 bottom-0'>
                                        <AppIcon icon={'loading'} className={'animate-spin'} />
                                    </p>
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </form>
                        <ReCaptcha ref={captchaRef} />
                    </>
                ) : (
                    <>
                        <AppRIIcon icon='checkbox-circle-fill' className='text-white font-xl mx-2' />
                        <span className='text-base text-white'>
                            {successMessage}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailCollector;
