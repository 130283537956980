import React, { createContext, useContext, useEffect, useState } from 'react';

import NotificationService from '../services/NotificationService';

export const NotificationContext = createContext();
const defaultNotifications = {
	today: [],
	later: [],
	wg: [],
	unreadLocal: 0,
	unreadGlobal: 0,
	unreadAll: 0,
};

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState(defaultNotifications);
	const [loading, setLoading] = useState(true);

	const loadNotifications = () => {
		setLoading(true);
		NotificationService.get()
			.then((notifications) => setNotifications(notifications))
			.catch(() => setNotifications(defaultNotifications))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		loadNotifications();
	}, []);

	return <NotificationContext.Provider value={{ notifications, loading, setNotifications, loadNotifications }}>{children}</NotificationContext.Provider>;
};

export const useNotifications = () => useContext(NotificationContext);

export const NotificationContextLayout = ({ children }) => <NotificationProvider>{children}</NotificationProvider>;
