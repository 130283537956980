import React from 'react';
import AppButton from '../AppButton';
import CustomerService from '../../services/CustomerService';

const AboutJoinTeam = () => {
	return (
		<div className='about-join-team'>
			<div className='container'>
				<div className='about-join-team-content'>
					<h2 className='about-join-team-h2'>
						Understand your gifts,
						<br />
						your frustrations & your team.
						<br />
						Join the movement today!
					</h2>
					<AppButton
						onClick={() => CustomerService.addToCart('add_license', true)}
						className={'btn btn-primary'}
						text={'Buy The Assessment'}
						id={'wg-about-take-assessment-2'}
					/>
				</div>
			</div>
		</div>
	);
};

export default AboutJoinTeam;
