import React, { useEffect } from 'react';
import { resource_apple_podcasts, resource_spotify_podcasts } from '../../images';
import ResourcesService from '../../services/ResourcesService';
import { podcastHelpers } from "./podcastHelpers";

const PodcastHeaderSection = ({ type, podcast }) => {
	const { TypeEnum, getAudioDuration } = podcastHelpers;
	const [audioDuration, setAudioDuration] = React.useState(0);

	useEffect(() => {
		if (podcast)
			getAudioDuration(podcast.mp3_link, setAudioDuration);
	}, [])

	const renderHeaderTitles = (type) => {
		if (TypeEnum.MAIN === type) return (
			<>
				<h1 className={'text-2xl lg:text-4xl font-thin pb-4'}>The Working Genius Podcast with Patrick Lencioni</h1>
				<span className={'text-md lg:text-lg font-thin'}>What is your Working Genius? The Working Genius Podcast is designed to help people identify their natural gifts and joy and fulfillment in their work and life.</span>
			</>
		)
		return (
			<>
				<span className={'text-lg tracking-wider font-thin uppercase mt-8'}>Episode {podcast?.episode_no}:</span>
				<h1 className={'text-3xl font-thin'}>{podcast?.episode_name}</h1>
				<span className={'text-lg tracking-wider font-thin mb-4'}>{audioDuration !== 0 && `${audioDuration} min`}</span>
			</>
		)
	}

	return (
		<div className={'podcast-header-section'}>

			<div className={'flex flex-col items-start justify-center text-white w-full md:w-7/12'}>
				{renderHeaderTitles(type)}
			</div>

			<div className={'flex flex-col justify-center pt-6 md:pt-0'}>
				<div className={'flex flex-row md:flex-col gap-3'}>
					<a target={'_blank'} href={ResourcesService.APPLE_PODCAST_URL}>
						<img className={'w-40'} alt={''} src={resource_apple_podcasts} />
					</a>
					<a target={'_blank'} href={ResourcesService.SPOTIFY_PODCAST_URL}>
						<img className={'w-40'} alt={''} src={resource_spotify_podcasts} />
					</a>
				</div>
			</div>
		</div>
	)

}

export default PodcastHeaderSection;
