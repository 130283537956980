import React from 'react';
import AppTooltip from '../AppTooltip';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';
import WgIcon from '../WgIcon';

const AssessmentAppIcon = ({ item, ...props }) => {
	const tooltipText = {
		standard: {
			duplicated: 'Duplicated Assessment',
			group: 'Group Assessment',
			individual: item.shared_from
				? 'Individual Assessment shared with me'
				: item.shared_to
					? 'Individual Assessment shared with others'
					: 'Individual Assessment',
		},
		student: {
			duplicated: 'Duplicated Assessment',
			group: 'Student Group Assessment',
			individual: item.shared_from
				? 'Individual Student Assessment shared with me'
				: item.shared_to
					? 'Individual Student Assessment shared with others'
					: 'Individual Student Assessment',
		},
	};

	const isStandard = item.assessment_mode === AssessmentModeEnum.STANDARD;
	const isStudent = item.assessment_mode === AssessmentModeEnum.STUDENT;
	const hasStandardMode = item.roster_mode && item.roster_mode.includes(AssessmentModeEnum.STANDARD);
	const hasStudentMode = item.roster_mode && item.roster_mode.includes(AssessmentModeEnum.STUDENT);
	const isMixed = (hasStandardMode && hasStudentMode) || (isStudent && hasStandardMode) || (isStandard && hasStudentMode);

	return (
		<AppTooltip
			type={'small'}
			placement={'right'}
			content={() => {
				return (
					<p className={'text-white text-tiny'}>
						{
							isMixed ? `Mixed Group Configured as ${isStudent ? 'Student' : 'Standard'}` :
								tooltipText[item.assessment_mode || item.roster_mode || AssessmentModeEnum.STANDARD][item.type || (item.original_roster_id ? 'duplicated' : 'individual')]
						}
					</p>
				);
			}}
			wrapperClass={'flex justify-center items-center'}
			{...props}
		>
			<WgIcon item={item} />
		</AppTooltip>
	);
};

export default AssessmentAppIcon;
