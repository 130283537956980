import React, { useEffect, useState } from 'react';
import { navigate, useLocation } from '@reach/router';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import PodcastService from '../../services/PodcastService';

export const PodcastPageContext = React.createContext({});

const Podcast = ({ children }) => {
	const location = useLocation();

	const [loading, setLoading] = useState(true);
	const [podcasts, setPodcasts] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		if (location?.pathname === '/podcast' || location?.pathname === '/podcast/') {
			setLoading(true);
			navigate('/wg-podcast-post', { replace: true });
		}
		if (podcasts.length > 0) return;
		PodcastService.getPodcasts()
			.then((response) => {
				setPodcasts(response);
			})
			.catch(({ message }) => {
				console.error('Error getting podcasts:', message);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (podcasts.length > 0 && categories.length === 0) {
			const categories = [];
			podcasts?.map(({ tags }) => {
				tags.map((tag) => {
					if (categories?.find((item) => item?.term_id === tag?.term_id)) return;
					categories.push(tag);
				});
			});

			setCategories(categories);
		}
	}, [podcasts]);

	const renderLoading = () => (
		<div className='spinner'>
			<div className='double-bounce1' />
			<div className='double-bounce2' />
		</div>
	);

	return (
		<PodcastPageContext.Provider value={{ podcasts, categories }}>
			<WorkshopLayout
				title={'Podcast | The 6 Types of Working Genius'}
				description={
					'The Working Genius Podcast is designed to help people identify their natural gifts and joy and fulfillment in their work and life.'
				}
			>
				{loading ? (
					renderLoading()
				) : (
					<div id={'wg-podcast-page'} className={'podcast-page podcast-page-background'}>
						{children}
					</div>
				)}
			</WorkshopLayout>
		</PodcastPageContext.Provider>
	);
};

export default Podcast;
