import React from 'react';
import { report_overview_gear_e, report_overview_gear_g, report_overview_gear_t } from '../../../../images';

const TeamImpactContinued = () => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h3>The Impact on Teams Continued</h3>

				<div className='buckets'>
					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_g} alt={''} />
						</div>

						<div>
							<b>Galvanizing</b> - When a team is underrepresented in the Genius of Galvanizing, it will
							often fail to act on ideas or initiatives that once seemed so promising. Teams with this
							problem will wonder why less-innovative competitors are experiencing more success than they
							are. Without Galvanizing, teams fail to generate the enthusiasm and support necessary to get
							a new idea or program off the ground, leaving them feeling conceptually brilliant and
							practically deficient. The Genius of Galvanizing ensures that good ideas get the attention
							and mobilization they need.
						</div>
					</div>

					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_e} alt={''} />
						</div>

						<div>
							<b>Enablement</b> - When a team is underrepresented in the Genius of Enablement, it will
							experience a lack of enthusiasm and camaraderie, leaving programs and projects to wilt or
							wither. Teams without Enablement fail to respond to the passion and energy of the
							Galvanizer. Good ideas fail to get traction, leaving team members to feel frustrated and
							isolated. The Genius of Enablement ensures that teams have the needed support and
							volunteerism that moves ideas and projects into the first stages of implementation.
						</div>
					</div>

					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_t} alt={''} />
						</div>

						<div>
							<b>Tenacity</b> - When a team is underrepresented in the Genius of Tenacity, it will often
							fail to complete projects according to desired standards, or to complete them at all. Teams
							without Tenacity find that their collective energy and focus diminishes as soon as an
							endeavor loses its novelty, and people move on to the next shiny object or emergency. The
							Genius of Tenacity ensures that teams finish well and allows them to see the full impact of
							an idea or project realized in the world.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamImpactContinued;
