import React from 'react';
import { launch_event_widgets } from '../../images';

const LaunchEventTypes = ({ longTitle = false }) => {
	return (
		<div className='launch-event-types-section'>
			<div className='container'>
				<h2 className='text-primary text-4xl font-normal'>
					The <span className='font-bold'>6</span> Types {longTitle ? 'of Working Genius' : ''}
				</h2>
				<hr className='my-2 w-full' />
				<div className='flex flex-col lg:flex-row items-center justify-center'>
					<img src={launch_event_widgets} alt='Widgets' className='w-auto lg:w-2/5 px-4' />
					<div className='launch-event-types'>
						<div>
							<h3 className='font-semibold inline-block'>Wonder</h3>: identifies the need for improvement or change
						</div>
						<div>
							<h3 className='font-semibold inline-block'>Invention</h3>: confirms the importance of that need, and
							generates an idea or solution
						</div>
						<div>
							<h3 className='font-semibold inline-block'>Discernment</h3>: assesses the merit and workability of
							the idea or solution
						</div>
						<div>
							<h3 className='font-semibold inline-block'>Galvanizing</h3>: generates enthusiasm and action around
							the idea or solution
						</div>
						<div>
							<h3 className='font-semibold inline-block'>Enablement</h3>: initiates support and assists in the
							implementation of the idea or solution
						</div>
						<div>
							<h3 className='font-semibold inline-block'>Tenacity</h3>: commits to ensuring that the idea or
							solution gets completed and that results are achieved
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LaunchEventTypes;
