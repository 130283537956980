import React from 'react';
import { genius_team_scale, genius_team_widget } from '../../../../images';

const GeniusTeam = ({}) => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h1>Putting Your Genius to Work on Teams</h1>

				<p>
					The Six Types of Working Genius create an interdependent model and provide a comprehensive process
					for accomplishing any type of work. Each type of genius receives and/or gives something to adjacent
					types, creating a work flow leading from Ideation (Wonder and Invention) to Activation (Discernment
					and Galvanizing) to Implementation (Enablement and Tenacity).
				</p>

				<div>
					<img src={genius_team_widget} alt={''} />
				</div>

				<div className='scale-container'>
					<img src={genius_team_scale} alt={''} />
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>WONDER</div>
						<div className='text'>identifies the need for improvement or change</div>
					</div>
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>INVENTION</div>
						<div className='text'>
							confirms the importance of that need, and generates an idea or solution
						</div>
					</div>
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>DISCERNMENT</div>
						<div className='text'>assesses the merit and workability of the idea or solution</div>
					</div>
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>GALVANIZING</div>
						<div className='text'>generates enthusiasm and action around the idea or solution</div>
					</div>
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>ENABLEMENT</div>
						<div className='text'>
							agrees to support and assist in the implementation of the idea or solution
						</div>
					</div>
				</div>

				<div className='genii'>
					<div className='row'>
						<div className='genius'>TENACITY</div>
						<div className='text'>
							commits to ensuring that the idea or solution gets completed and that results are achieved
						</div>
					</div>
				</div>

				<p>
					When teammates better understand their own and their coworkers&rsquo; relative areas of Genius,
					Competency and Frustration, they are immediately more capable of doing five things:
				</p>

				<ol>
					<li>Understanding why they have been successful or unsuccessful in past endeavors.</li>
					<li>Avoiding making unfair and inaccurate judgments about one another&rsquo;s motivation.</li>
					<li>Alleviating their own guilt about struggles they&rsquo;ve had in work.</li>
					<li>
						Making quick and concrete adjustments to their roles and responsibilities to better tap into one
						another&rsquo;s strengths, and avoid one another&rsquo;s weaknesses.
					</li>
					<li>Getting more done in less time.</li>
				</ol>
			</div>
		</div>
	);
};

export default GeniusTeam;
