import React from 'react';
import AppButton from '../../AppButton';
import AppText from '../../AppText';
import { formatDate } from '../../../services/UtilityService';
import { useTranslation } from 'react-i18next';

const Instructions = ({ assessment, currentStep, nextStep, previousStep, studentMode }) => {
	const { t } = useTranslation();

	return (
		<div className={'flex flex-col mx-4'}>
			<div>
				<h1 className={'uppercase text-primary text-2xl font-bold my-4'}>
					{t('assessment.take.instructions.title')}
				</h1>
				<AppText text={t('assessment.take.instructions.description')} />
				<h1 className={'uppercase text-primary text-xl font-bold mt-3'}>
					{t('assessment.take.instructions.note')}:
				</h1>
				<AppText text={studentMode ? 'If you are unsure how to answer a specific question, consider the joy and fulfillment you experience during that activity rather than what you are required to do at school or home.' : t('assessment.take.instructions.notes')} />
				<h1 className={'uppercase text-primary text-xl font-bold mt-5 mb-3'}>
					{t('assessment.take.instructions.rating')}
				</h1>
				{studentMode ? <ul className={'list-disc ml-6 text-primary'}>
					<li>
						<AppText text={'Probably/Definitely not true'} />
					</li>
					<li>
						<AppText text={'Sometimes true'} />
					</li>
					<li>
						<AppText text={'Probably/Definitely true'} />
					</li>
				</ul> :
					<ul className={'list-disc ml-6 text-primary'}>
						<li>
							<AppText text={t('assessment.take.instructions.def_not')} />
						</li>
						<li>
							<AppText text={t('assessment.take.instructions.sometimes')} />
						</li>
						<li>
							<AppText text={t('assessment.take.instructions.def')} />
						</li>
					</ul>}
			</div>

			{assessment.responses && assessment.responses.length > 0 && (
				<div className={'mt-4'} id={'wg-previously-taken-notice'}>
					<p
						className='text-primary text-lg'
						dangerouslySetInnerHTML={{
							__html: t('assessment.take.instructions.previously_taken', {
								number: assessment.responses.length,
								date: formatDate(assessment.started, 'ddd, MMMM DD, YYYY', true),
								time: formatDate(assessment.started, 'h:mm:ss a UTC', true),
							}),
						}}
					/>
				</div>
			)}

			<div className='flex justify-between my-4'>
				<div>
					{currentStep > 1 && (
						<AppButton
							className={'btn btn-secondary'}
							text={t('back')}
							onClick={previousStep}
							id={'wg-take-assessment-back-btn'}
						/>
					)}
				</div>
				<AppButton text={t('next')} onClick={nextStep} id={'wg-take-assessment-next-btn'} />
			</div>
		</div>
	);
};

export default Instructions;
