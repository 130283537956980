import React from 'react';
import moment from 'moment';
import { wg_logo, poweredby } from '../../../images';

const TeamMapFooter = () => {
	const currentYear = moment().format('YYYY');

	return (
		<div className='PageFooter'>
			<div className='PageFooter__layout'>
				<div className='PageFooter__logo'>
					<img width='111' src={wg_logo} />
				</div>

				<div className='PageFooter__textContainer'>
					<p className='PageFooter__paragraph'>www.WorkingGenius.com</p>
					<p className='PageFooter__paragraph'> © Copyright {currentYear} The Table Group, Inc. </p>
				</div>

				<div className='powered-by'>
					<img width='115' src={poweredby} />
				</div>
			</div>
		</div>
	);
};

export default TeamMapFooter;
