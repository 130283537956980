import React from 'react';
import AppButton from '../AppButton';
import { wg_summary_book_tablet } from '../../images';
import { addToCart } from '../../services/CustomerService';

const WhyWorkingGenius = () => {
	return (
		<div className='home-why-wg-section'>
			<div className='container'>
				<div className='md:grid md:grid-cols-2 flex flex-col-reverse items-center justify-center ml-4'>
					<div className='flex flex-col gap-8'>
						<h2 className='text-white text-xl md:text-3xl font-semibold  my-4'>Working Genius is:</h2>
						<div className='flex flex-col md:flex-row items-start md:items-center mb-8'>
							<ul className='why-wg-ul'>
								<li>
									<p>Simple&nbsp;</p>- Easy to understand and use.
								</li>
								<li>
									<p>Practical&nbsp;</p>- Designed for individuals and teams at work.
								</li>
								<li>
									<p>Fast&nbsp;</p>- Take it in 10 minutes and apply it today.
								</li>
							</ul>
						</div>
						<AppButton
							id={'wg-home-why-wg-buy-assessment-btn'}
							text={'Take the Assessment Now'}
							onClick={() => addToCart('add_license', true)}
							className='xl:w-1/2 md:w-2/3 btn btn-primary'
						/>
					</div>
					<div>
						<img src={wg_summary_book_tablet} alt='Working Genius' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhyWorkingGenius;
