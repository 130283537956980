import React from 'react';
import { navigate } from 'gatsby';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {useLocation} from "@reach/router";

import {getQueryParams} from "../../../services/UtilityService";
import Licenses from './licenses';
import Layout from '../../../components/Layout';
import OrdersTable from '../../../components/account/OrdersTable';
import LicenseService from '../../../services/LicenseService';
import NotificationService from '../../../services/NotificationService';

const Index = () => {
	const [orders, setOrders] = React.useState(0);
	const [adjustments, setAdjustments] = React.useState(0);
	const [assigned, setAssigned] = React.useState(0);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const location = useLocation();
	const {tab} = getQueryParams(location?.search);

	React.useEffect(() => {
		if (tab === 'orders') setSelectedIndex(1);
	}, [location?.search]);
	const getTotalLicenses = () => {
		LicenseService.usage()
			.then((data) => {
				setOrders(data.orders);
				setAdjustments(data.adjustments);
				setAssigned(data.assigned);
			})
			.catch((err) => {
				NotificationService.error('Error', err.message);
			});
	};

	React.useMemo(() => {
		getTotalLicenses();
	}, []);

	return (
		<Layout title='The 6 Types of Working Genius: Licenses'>
			<div className='container m-4'>
				<div className='flex my-2'>
					<a
						className='text-secondary text-xl font-semibold underline hover:text-primary cursor-pointer'
						onClick={() => navigate(-1)}
						id='wg-licenses-back-link'
					>
						{'< back'}
					</a>
				</div>
				<Tabs selectedIndex={selectedIndex} onSelect={setSelectedIndex}>
					<TabList>
						<Tab className={'react-tabs__tab wg-licenses-tab mt-6'}>Licenses</Tab>
						<Tab className={'react-tabs__tab wg-account-orders-tab mt-6'}>Orders</Tab>
					</TabList>

					<TabPanel>
						<Licenses
							orders={orders}
							adjustments={adjustments}
							assigned={assigned}
							setAdjustments={setAdjustments}
						/>
					</TabPanel>
					<TabPanel>
						<OrdersTable ordersCount={orders} />
					</TabPanel>
				</Tabs>
			</div>
		</Layout>
	);
};

export default Index;
