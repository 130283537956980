import React from 'react';
import AppButton from '../AppButton';

const WorkshopRequest = ({ onWorkWithUsClick }) => {
	return (
		<div className='workshop-request-section'>
			<div className='container'>
				<div className={'flex flex-col md:flex-row items-center justify-start'}>
					<h2
						className={
							'flex-1 text-4xl text-primary text-center font-normal md:text-right border-blue-600 border-b-2 md:border-b-0 md:border-r-2 p-6'
						}
					>
						Ready to book?
					</h2>
					<div className={'flex-1 p-6 flex-col'}>
						<div className={'text-primary text-xl md:text-2xl text-left font-semibold'}>
							Schedule a time to meet with <br className='hidden lg:block' /> a Master Facilitator today.
						</div>
					</div>
				</div>

				<AppButton
					onClick={onWorkWithUsClick}
					className={'btn btn-primary workshop-primary-btn my-8 w-56 mx-auto'}
					text={'Work with us'}
					id={'wg-workshop-request-btn'}
				/>
			</div>
		</div>
	);
};

export default WorkshopRequest;
