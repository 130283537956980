import ApiService from './ApiService';

const register = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/workshop/register`, payload)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	register,
};
