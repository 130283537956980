import React from 'react';
import {
	wg_meeting_pov_2
} from '../../../images';

const items = [
	"4 hour virtual session",
	"Designed by the Working Genius Certification Team",
	"Led by Master Facilitators experienced at growing their own businesses",
	"Exclusively offered to Certified Facilitators",
	"$995"
];

const CourseDetails = () => {
	return (
		<>
			<div className='bg-secondary p-1'/>
			<div className={'flex bg-primary justify-center items-center content-center py-12'}>
				<div className='container flex mx-auto flex-col items-center justify-center lg:flex-row'>
					<div className='flex flex-col lg:flex-row mt-4 py-6'>
						<div className='flex flex-2 flex-col justify-center mx-12'>
							<h2 className='text-xl font-bold leading-none mb-4 text-white'>
								Session Details:
							</h2>
							<ul className='pl-3 course_details'>
								{items.map((item, key) => {
									return (
										<li className={'text-lg mt-2 text-white'} key={key}>
											{item}
										</li>
									);
								})}
							</ul>
						</div>
						<div className='course-details-image'>
							<img src={wg_meeting_pov_2} className={'object-contain'} alt='Course Details Alumni'/>
						</div>
					</div>
				</div>
			</div>
			<div className='bg-secondary p-1'/>
		</>
	);
};

export default CourseDetails;
