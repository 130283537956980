import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

import Layout from '../../../components/Layout';
import AppInput from '../../../components/AppInput';
import AppButton from '../../../components/AppButton';
import AppErrorMessage from '../../../components/AppErrorMessage';
import AppText from '../../../components/AppText';
import { emailRegex } from '../../../services/UtilityService';
import { forgotPassword } from '../../../services/CustomerService';
import AppSuccessMessage from '../../../components/AppSuccessMessage';
import ReCaptcha from '../../../components/ReCaptcha';

const Reminder = () => {
	const location = useLocation();

	const error = location.state?.error ? location.state.error : '';
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(error);
	const [successMessage, setSuccessMessage] = useState('');
	const captchaRef = React.useRef('captcha');

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();

	const _remind = async (data) => {
		setErrorMessage('');
		setSuccessMessage('');
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		forgotPassword({ ...data, captcha: recaptchaValue })
			.then(() => {
				setSuccessMessage('Password reminder sent');
				reset();
			})
			.catch((error) => {
				setErrorMessage(error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	return (
		<Layout>
			<div className={'container mx-auto'}>
				<div className='md:shadow-md sm:w-full md:w-1/2 lg:w-5/12 xl:w-2/5 md:mx-auto my-12 px-8 md:px-12 py-8'>
					<AppErrorMessage message={errorMessage} />
					<AppSuccessMessage message={successMessage} />
					<h1 className='uppercase text-secondary text-center text-4xl font-semibold mb-4 leading-none'>
						PASSWORD
						<br />
						REMINDER
					</h1>
					<AppText
						text={
							'Please enter the email address associated with your account in the field provided below.'
						}
						className='text-center mb-6'
					/>
					<form
						className={'w-full'}
						onSubmit={handleSubmit(_remind)}
						autoComplete='autocomplete_off_hack_xfr4!k'
					>
						<AppInput
							{...register('email', {
								required: true,
								pattern: {
									value: emailRegex,
								},
							})}
							label={'Email address'}
							errors={errors}
							onBlur={(e) => {
								setValue('email', e.target.value.trim());
							}}
						/>
						<AppButton
							type='submit'
							className={'btn btn-primary w-full mb-4'}
							text={'Submit'}
							loading={loading}
							id={'wg-reminder-submit-btn'}
						/>
						<ReCaptcha ref={captchaRef} />
					</form>
					<Link to={'/client/login/'} className={'text-primary'} id={'wg-back-to-login-link'}>
						<span className={'font-medium text-lg'}>Back to Administrator Login</span>
					</Link>
				</div>
			</div>
		</Layout>
	);
};

export default Reminder;
