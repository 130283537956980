import { resource_pat_podcast } from '../../images';
import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { podcastHelpers } from './podcastHelpers';

const PodcastNavigation = ({ type, categories = null, selectedCategory, setSelectedCategory }) => {
	const { TypeEnum } = podcastHelpers;

	const onClick = (category) => setSelectedCategory(category);

	const renderNavigationContent = useMemo(() => {
		if (TypeEnum.DETAILS === type)
			return (
				<div className={'mt-6 w-full'} onClick={() => navigate('/wg-podcast-post')}>
					<span className={'text-primary text-md cursor-pointer font-medium underline hover:text-secondary'}>
						{'< Back To Podcasts'}
					</span>
				</div>
			);

		return (
			<div className={'flex flex-1 flex-col text-sm md:text-md md:w-full mt-0 md:mt-6 pt-0 md:pt-2'}>
				<span className={' text-primary text-md md:text-lg font-semibold mb-1 md:mb-2 pl-1 md:pl-2'}>
					Filter By Categories:
				</span>

				<span
					className={`navigation-menu-item ${selectedCategory === 'all' ? 'selected' : ''}`}
					onClick={() => onClick('all')}
				>
					All Podcasts
				</span>

				{categories?.map(
					(category, key) =>
						category?.slug && (
							<span
								className={`navigation-menu-item ${
									selectedCategory === category?.slug ? 'selected' : ''
								}`}
								key={key}
								onClick={() => onClick(category?.slug)}
							>
								{category?.name}
							</span>
						)
				)}
			</div>
		);
	}, [categories, selectedCategory, type, TypeEnum.DETAILS]);

	return (
		<div
			className={
				'flex flex-1 flex-row md:flex-col items-center w-full md:w-2/5 mb-5 md:mb-0 px-0 md:pl-2 md:pr-6 py-3 md:py-0 md:mr-6'
			}
		>
			<img className={'rounded-md w-2/5 md:w-full mr-6 md:mr-0'} src={resource_pat_podcast} alt={''} />
			{renderNavigationContent}
		</div>
	);
};

export default PodcastNavigation;
