import React from 'react';

import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

import {
	free_tool_and_resources,
	report_book,
	report_certification,
	report_podcast,
	report_qr_section,
} from '../../../../images';

const items = [
	{
		image: report_book,
		title: 'The Book',
		text: 'BUY NOW',
		link: 'https://www.workinggenius.com/book',
	},
	{
		image: report_podcast,
		title: 'The Podcast',
		text: 'LISTEN NOW',
		link: 'https://www.workinggenius.com/wg-podcast-post',
	},
	{
		image: report_certification,
		title: 'Certification',
		text: 'LEARN MORE',
		link: 'https://www.workinggenius.com/client/certification/',
	},
	{
		image: free_tool_and_resources,
		title: 'Free Tools \n& Resources',
		text: 'LEARN MORE',
		link: 'https://www.workinggenius.com/resources/',
	},
];

const GoingDeeper = () => {
	return (
		<div className='GoingDeeper'>
			<PageHeader title={'Application'} />
			<div className={'container mt-12'}>
				<PageTitle title={'Want to Go Deeper?'} />

				<p className={'mt-5 mb-8 pr-4'}>
					Taking the Working Genius Assessment is the first step in discovering your gifts and experiencing
					how it can transform your work, your team, and your life. The Working Genius team has created
					several resources to help individuals, teams, and organizations leverage this model in both personal
					and professional life. Discover how Working Genius applies to meetings, hiring, project management,
					productivity, relationships, family, and more with the resources below:
				</p>

				<div className={'grid grid-cols-4 gap-4 mb-12'}>
					{items.map((item, idx) => (
						<div key={idx} className={'item-container spaced-items pb-4'}>
							<img src={item.image} alt='' style={{ minHeight: '140px',maxHeight: '140px' }} />
							<p className={'small-title pt-6 pb-3'}>{item.title}</p>

							<a href={item.link} className={'btn btn-primary btn-small item-button'}>
								{item.text}
							</a>
						</div>
					))}
				</div>

				<img src={report_qr_section} alt='qr_section' />
			</div>
		</div>
	);
};

export default GoingDeeper;
