import React, { useState } from 'react';

import AppIcon from '../AppIcon';
import AppButton from '../AppButton';
import AppModal from '../AppModal';
import { close } from '../../services/AssessmentsService';
import { useUser } from '../../hooks/UserProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const CloseAssessmentModal = ({ isOpen, assessment, closeModal, hasIncompleteAssessments }) => {
	const [loading, setLoading] = useState(false);
	const { setUser } = useUser();

	const closeAssessment = () => {
		setLoading(true);
		close({ assessment_id: assessment.id })
			.then(({ licenses_removed, assessment }) => {
				setUser((u) => ({ ...u, licenses: u.licenses + parseInt(licenses_removed) }));
				closeModal({
					saved: true,
					assessment,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<AppModal id={'app-confirm-close-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='bg-white py-4 px-8' style={{ maxWidth: '690px' }}>
				<h1 className={'flex flex-1 text-3xl text-center text-primary font-bold'}>Confirm Close</h1>
				<div className='absolute right-0 top-0 mt-4 mr-4'>
					<AppIcon
						icon={'cancel'}
						className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
						onClick={closeModal}
					/>
				</div>
				<div>
					<p className={'text-primary my-2'}>
						Closing this assessment will deactivate the link to the assessment and put the unassigned
						licenses back into your account.
					</p>
					{!!hasIncompleteAssessments && (
						<p className={'text-primary font-bold my-2'}>
							Any incomplete assessment will be deleted and the progress will be lost.
						</p>
					)}
					<p className={'text-primary my-2'}>Are you sure you wish to close this assessment?</p>
				</div>
				<div className='flex justify-end'>
					<AppButton
						className={'btn btn-secondary mr-4'}
						text={'Cancel'}
						onClick={closeModal}
						disabled={loading}
						id={'wg-close-assessment-cancel-btn'}
					/>
					<AppButton
						text={'Close'}
						onClick={closeAssessment}
						loading={loading}
						id={'wg-close-assessment-close-btn'}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default CloseAssessmentModal;
