import React from 'react';
import { report_hr_sm } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Tenacity tend to think and say:',
		items: [
			'"Come on, everyone, we need to raise our standards here."',
			'"I’m concerned that we’re not going to hit our deadline."',
			'"I think we need to stay later and work harder."',
			'"If we miss our goal, I’m going to be really disappointed."',
		],
	},
	{
		title: 'What others think and say about people with the T genius:',
		items: [
			'"I don\'t think he’s ever missed his number."',
			'"She doesn\'t want to hear any excuses."',
			'"Don\'t get in his way. He’s going to do whatever it takes to get it done."',
			'"Failure isn\'t a word in her vocabulary."',
		],
	},
];

const Tenacity = ({}) => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF TENACITY</h2>

			<p>
				People with the Genius of Tenacity are all about execution, making sure that whatever they're working on
				is accomplished and achieves the desired results. They want to know that they have met or exceeded the
				required standards of performance, and that the work has had the expected impact.
			</p>

			<p>
				The T genius is fairly easy to identify as it is greatly valued in society. However, it is not easily
				recognized as a genius. Those who have it often see their T as a matter of simple discipline and work
				ethic. Those who don't have the Genius of Tenacity often feel guilty about it, seeing themselves as
				missing a key characteristic of success. Both of these groups fail to recognize that the drive for
				completion, impact, and accomplishment is a natural and real gift.
			</p>

			<p>
				Tenacity differs from Enablement in that it is more about the work being done than meeting the needs of
				others who are asking for help. People with the Tenacity genius want and need to continue doing what is
				required even if a project or program has lost its novelty, or if attention has shifted to something
				else. And while all people need and deserve human appreciation for their work, those with the Genius of
				Tenacity get most of their satisfaction from knowing that the work is finished, was done well, and has
				achieved its desired result.
			</p>

			<img src={report_hr_sm} className='hr hr-sm' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Tenacity;
