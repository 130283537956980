import React from 'react';
import AppIcon from './AppIcon';
import renderErrorMessage from '../constants/ErrorMessages';

const AppCheckBox = React.forwardRef(({ label, containerId, errors, showErrorMessage = true, ...props }, ref) => {
	const { name } = props;
	const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

	const renderLabel = () => {
		if (!label) {
			return null;
		}
		if (typeof label === 'string' || typeof label === 'number') {
			return label;
		}
		return label();
	};

	return (
		<label className={`app-checkbox-container ${props.disabled ? 'disabled' : ''}`} id={containerId}>
			<span>{renderLabel()}</span>
			<input type='checkbox' ref={ref} {...props} />
			<p className='checkmark'>
				<AppIcon className={'checkmark-icon'} icon={'ok'} />
			</p>
			<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
				{errors && errors[name] && renderErrorMessage(errors[name].type, props, '', errors[name].message)}
			</span>
		</label>
	);
});

export default AppCheckBox;
