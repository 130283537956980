import React from 'react';
import PreWorkItem from './PreWorkItem';
import AppVideoModal from '../../AppVideoModal';
import CertificationService from '../../../services/CertificationService';
import StepWizard from 'react-step-wizard';
import PreWorkTimeline from './PreWorkTimeline';

const PreWork = ({ customer }) => {
	const status_id = customer.wg_certification_status.status_id;
	const [items, setItems] = React.useState([]);
	const [loading, setLoading] = React.useState(true);
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	React.useEffect(() => {
		CertificationService.getPreworkItems()
			.then(setItems)
			.finally(() => setLoading(false));
	}, []);

	const onMediaClick = (item) => {
		if (item.type === 'pdf') {
			window.open(item.resource_url, '_blank').focus();
		} else {
			setVimeoUrl(item.resource_url);
		}
	};

	const onItemComplete = (key) => {
		const newItems = [...items];
		newItems[key].completed = true;
		setItems(newItems);
	};

	if (loading) {
		return (
			<div id={'wg-prework-loading'} className='relative h-screen'>
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	const firstNotCompleted = items.findIndex((i) => !i.completed);

	return (
		<div className={'overflow-x-hidden'}>
			<div className={'container mx-auto'}>
				<h1 className={'text-3xl text-center text-primary mt-12 mb-8'}>Prework</h1>
				<p className={'md:mx-24 text-primary font-semibold'}>
					These videos have been created so that you have consolidated overviews of the Working Genius model
					in order to increase your fluency and confidence in the model. The Certification Sessions are
					interactive and will include conversations and activities tied to all of the topics covered in these
					videos.
				</p>
			</div>

			<StepWizard
				nav={<PreWorkTimeline items={items} />}
				isLazyMount={true}
				initialStep={firstNotCompleted > -1 ? firstNotCompleted + 1 : items.length}
			>
				{items.map((item, key) => {
					return (
						<PreWorkItem
							item={item}
							key={key}
							onMediaClick={onMediaClick}
							isLast={key === items.length - 1}
							onItemComplete={() => onItemComplete(key)}
						/>
					);
				})}
			</StepWizard>

			{status_id === 2 && (
				<div className={'flex flex-col justify-center text-center py-12 md:py-24 container'}>
					<h1 className={'text-primary text-3xl leading-none pb-6'}>
						Congratulations! You are now ready for your Online Certification Session. No further action is
						required until then.
					</h1>
					<p>Please don't hesitate to contact us with questions or visit our FAQ.</p>
					<a href='mailto:workinggenius@tablegroup.com' className={'btn btn-primary w-40 mx-auto mt-6'}>
						Contact us
					</a>
				</div>
			)}

			{status_id < 3 && (
				<div className='flex flex-col justify-center bg-gray-200 px-4 py-12 md:py-24 text-center mb-8'>
					<h1 className='text-primary text-3xl leading-none pb-6'>Post-Training Resources</h1>
					<p className={'container text-primary'}>
						Please check off each step of the Pre-work Process by clicking CHECK below each video and
						Companion Guide. Upon completing the Online Certification Session, you will be able to access
						the Post-Training Resources.
					</p>
				</div>
			)}

			<AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />
		</div>
	);
};

export default PreWork;
