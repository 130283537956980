import React from 'react';
import Result from './Result';
import UtilityService from '../../../services/UtilityService';

const geniusColor = UtilityService.geniusColor;
const competencyColor = UtilityService.competencyColor;
const frustrationColor = UtilityService.frustrationColor;

const Results = ({ results, isNew = false }) => {
	return (
		<div className={'flex flex-row'}>
			<Result
				letter={isNew ? results.new_wg1[0] : results.wg1[0]}
				className={'mr-2'}
				backgroundColor={geniusColor}
			/>
			<Result
				letter={isNew ? results.new_wg2[0] : results.wg2[0]}
				className={'mr-4'}
				backgroundColor={geniusColor}
			/>
			<Result
				letter={isNew ? results.new_wc1[0] : results.wc1[0]}
				className={'mr-2'}
				backgroundColor={competencyColor}
			/>
			<Result
				letter={isNew ? results.new_wc2[0] : results.wc2[0]}
				className={'mr-4'}
				backgroundColor={competencyColor}
			/>
			<Result
				letter={isNew ? results.new_wf1[0] : results.wf1[0]}
				className={'mr-2'}
				backgroundColor={frustrationColor}
			/>
			<Result
				letter={isNew ? results.new_wf2[0] : results.wf2[0]}
				className={'mr-4'}
				backgroundColor={frustrationColor}
			/>
		</div>
	);
};

export default Results;
