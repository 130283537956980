import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import AppIcon from '../AppIcon';
import AppModal from '../AppModal';
import AppSwitchingProfile from '../AppSwitchingProfile';
import PermissionsService from '../../services/PermissionsService';
import NotificationService from '../../services/NotificationService';
import CustomerService from '../../services/CustomerService';
import { useUser } from '../../hooks/UserProvider';
import { useNotifications } from '../../hooks/NotificationProvider';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        maxHeight: '75vh',
    },
};

const SwitchAccountModal = ({ isOpen, onClose }) => {
    const { user, loadUser } = useUser();
    const { loadNotifications } = useNotifications();
    const [switching, setSwitching] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        if (isOpen) {
            setAccounts([{
                role: "My account",
                customers_id: user.id,
                customers_email_address: user.customers_email_address,
                customers_name: user.fullName,
            }]);
            setSelectedUser(user.id);
            PermissionsService.getPermissionsAccess()
                .then((data) => {
                    setAccounts(prevAccounts => [...prevAccounts, ...data]);
                })
                .catch((error) => {
                    console.error("Error fetching permissions", error);
                    NotificationService.error('Oops', 'Something went wrong, please try again');
                });
        } else {
            setSwitching(false);
            setAccounts([]);
        }
    }, [isOpen, user]);

    const handleSwitch = (id) => {
        if (user.id === id) return null;
        setSwitching(true);
        setSelectedUser(id);
        CustomerService.switchUser(id.toString())
            .then(() => {
                loadUser();
                loadNotifications();
                navigate(`/client`);
            })
            .catch((error) => {
                console.error("Error switching user", error);
                NotificationService.error('Oops', 'Something went wrong, please try again');
            })
            .finally(() => {
                setSwitching(false);
                onClose();
            });
    };

    if (switching) {
        return <AppSwitchingProfile />
    }

    return (
        <AppModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
            <div className='bg-white p-6' style={{ maxWidth: '550px' }}>
                <h1 className={'flex flex-1 text-lg text-primary font-medium'}>Switch Account</h1>
                <p className={'text-sm leading-5 font-normal text-gray-500 mb-6'}>
                    You have permission to use other accounts. Choose the one you want to switch to.
                </p>
                <div className='absolute right-0 top-0 mt-4 mr-4'>
                    <AppIcon
                        icon={'cancel'}
                        className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
                        onClick={onClose}
                    />
                </div>
                {accounts.map((account, key) => (
                    <div
                        className={
                            'flex flex-row items-center justify-between p-4 cursor-pointer hover:bg-blue-100 rounded-lg border-gray-400 border-1 my-4'
                        }
                        key={key}
                        onClick={() => handleSwitch(account.customers_id)}
                    >
                        <div className='flex flex-col sm:flex-row sm:items-center flex-wrap rounded-lg'>
                            <span className='text-sm text-gray-800 leading-5 font-medium'>
                                {account.customers_name}
                            </span>
                            <span className={`permission-level-role ${account.role.toLowerCase()} sm:inline sm:mx-2 order-2 sm:order-1 w-auto`}>
                                {account.role === 'ADMIN' ? 'Admin Access' : account.role === 'LIMITED' ? 'Limited Access' : account.role}
                            </span>
                            <span className='text-gray-500 font-normal my-2 order-1 sm:order-2 sm:w-full'>
                                {account.customers_email_address}
                            </span>
                        </div>
                        <input
                            className={'cursor-pointer'}
                            type='radio'
                            value={account.customers_id}
                            name="account"
                            checked={account.customers_id == selectedUser}
                            onChange={() => handleSwitch(account.customers_id)}
                        />
                    </div>
                ))}
            </div>
        </AppModal>
    );
};

export default SwitchAccountModal;
