import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import AppButton from '../AppButton';
import GiveAccessFields from './GiveAccessFields';
import GiveAccessConfirmationModal from './GiveAccessConfirmationModal';
import CustomerService from '../../services/CustomerService';
import PermissionsService from '../../services/PermissionsService';
import NotificationService from '../../services/NotificationService';

const GiveAccessForm = ({ onSuccess }) => {
    const [loading, setLoading] = React.useState(false);
    const [existingCustomer, setExistingCustomer] = React.useState(false);
    const [confirmGiveAccessModal, setConfirmGiveAccessModal] = React.useState(false);
    const [formPayload, setFormPayload] = React.useState({ email: '', permission: '' });

    const confirmText = existingCustomer
        ? `Are you sure you wish to give <span class='capitalize'>${formPayload.permission.label} access</span> to <b>${formPayload.email}</b>? <br/><br /> <b>Note:</b> They will receive a request by email. Once they accept it, they will be able to access your account.`
        : `Looks like <b>${formPayload.email}</b> does not have an account. <br /><br /> Do you wish to proceed with sending them an invitation to create an account and give ${formPayload.permission.label} Access to your account?`;

    const methods = useForm();

    const onSubmit = (payload) => {
        setLoading(true);
        setFormPayload(payload);
        CustomerService.verifyEmail(payload.email)
            .then((exists) => {
                setExistingCustomer(exists);
                setConfirmGiveAccessModal(true);
            })
            .catch((e) => NotificationService.error('Error', e.message))
            .finally(() => setLoading(false));
    };

    const onConfirm = (payload) => {
        setLoading(true);
        const registerPayload = {
            customers_email_address: payload.email,
            role: payload.permission.value,
        }
        PermissionsService.requestPermission(registerPayload)
            .then((result) => {
                NotificationService.success('Permission request sent!');
                methods.reset({ email: '', permission: '' });
                setConfirmGiveAccessModal(false);
                onSuccess();
            })
            .catch((result) => NotificationService.error('Oops', result.message))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className='flex flex-col pt-6 pb-2 border-t-1 border-b-1 border-gray-200'>
            <h3 className={'text-xl text-primary font-medium'}>Give users access</h3>
            <p className={'text-gray-700 text-primary font-normal mb-4'}>Give users access by using their email address and the permission options listed below.</p>
            <FormProvider {...methods}>
                <form className='w-full flex flex-col md:flex-row items-start' autoComplete='autocomplete_off_hack_xfr4!k' onSubmit={methods.handleSubmit(onSubmit)}>
                    <GiveAccessFields />
                    <AppButton
                        type='submit'
                        className={'btn btn-primary'}
                        loading={loading}
                        text={'Give Access'}
                        style={{ marginTop: '22px' }}
                    />
                </form>
                <GiveAccessConfirmationModal
                    loading={loading}
                    isOpen={confirmGiveAccessModal}
                    closeModal={() => setConfirmGiveAccessModal(false)}
                    onConfirm={() => onConfirm(formPayload)}
                    text={confirmText}
                    confirmText={existingCustomer ? 'Give Access' : 'Send Invitation and Give Access'}

                />
            </FormProvider>
        </div>
    );
};

export default GiveAccessForm;
