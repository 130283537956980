import React from 'react';
import AppIcon from '../../AppIcon';

const PreWorkTimeline = ({ items, currentStep }) => {
	const firstToCompleteIndex = items.findIndex(({ completed }) => !completed);

	return (
		<>
			<div className={'container mx-auto hidden md:flex flex-row mt-4'}>
				{items.map((item, key) => {
					const isCurrentStep = currentStep - 1 === key;
					const isCompleted = !!item.completed;
					const completed = isCompleted ? 'completed' : '';
					const activeStep = isCurrentStep ? 'active' : '';
					const firstToComplete = firstToCompleteIndex === key ? 'first-to-complete' : '';

					return (
						<div key={key} className={`relative flex flex-1 justify-center items-center`}>
							<div className={`prework-timeline ${completed} ${activeStep} ${firstToComplete}`}>
								{isCompleted && <AppIcon icon={'ok'} />}
								{firstToComplete && <div className={'dot'} />}
							</div>
							{key !== items.length - 1 && (
								<div
									className={`absolute w-full border-1 ${
										isCompleted ? 'border-secondary' : 'border-gray-300'
									}`}
									style={{ marginLeft: '100%' }}
								/>
							)}
						</div>
					);
				})}
			</div>
			<div className='container mx-auto flex flex-row md:hidden mt-4'>
				{items.map((item, key) => {
					const isCurrentStep = currentStep - 1 === key;
					const isCompleted = !!item.completed;
					const completed = isCompleted ? 'completed' : '';
					const activeStep = isCurrentStep ? 'active' : '';
					const firstToComplete = firstToCompleteIndex === key ? 'first-to-complete' : '';

					return (
						<div key={key} className={`relative flex flex-1 justify-center items-center`}>
							<div className={`mobile-prework-timeline ${completed} ${activeStep} ${firstToComplete}`}>
								<div className='dot' />
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default PreWorkTimeline;
