import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

import Layout from '../../../components/Layout';
import AppInput from '../../../components/AppInput';
import AppButton from '../../../components/AppButton';
import AppRIIcon from '../../../components/AppRIIcon';
import ReCaptcha from '../../../components/ReCaptcha';
import { getAuthCookie, getQueryParams, passwordRegex } from '../../../services/UtilityService';
import NotificationService from '../../../services/NotificationService';
import LicenseService from '../../../services/LicenseService';
import AppLabel from '../../../components/AppLabel';
import AppRadioGroup from '../../../components/AppRadioGroup';
import AppDropdown from '../../../components/AppDropdown';
import CustomerService from '../../../services/CustomerService';
import { useUser } from '../../../hooks/UserProvider';
import { useNotifications } from '../../../hooks/NotificationProvider';

const RegisterInvitation = () => {
	const [loading, setLoading] = React.useState(true);
	const [linkMessage, setLinkMessage] = React.useState('');
	const [linkMessageTitle, setLinkMessageTitle] = React.useState('');
	const [submitting, setSubmitting] = React.useState(false);
	const [optIn, setOptIn] = React.useState(false);
	const captchaRef = React.useRef('captcha');
	const location = useLocation();
	const { setUser } = useUser();
	const { loadNotifications } = useNotifications();
	const { token } = getQueryParams(location.search);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		clearErrors,
		control,
		watch,
	} = useForm();
	const companyValue = watch('company');

	useEffect(() => {
		if (!companyValue) {
			clearErrors('role');
		}
	}, [companyValue]);

	const handleVerifyToken = async () => {
		LicenseService.verifyLicenseClaimInvite(token)
			.then(({ email, message }) => {
				if (email) return setValue('email', email);

				setLinkMessageTitle('');
				setLinkMessage(message);
			})
			.catch(() => {
				setLinkMessageTitle('Expired Link');
				setLinkMessage(
					'Your account creation link has expired or has already been used, you can access your account or initiate the create account process by clicking the button below.'
				);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleRegister = async (payload) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setSubmitting(true);

		LicenseService.registerClaimLicense({
			token,
			first_name: payload.first_name,
			last_name: payload.last_name,
			password: payload.password,
			opt_in: payload.opt_in,
			captcha: recaptchaValue,
			role: payload.role?.value || 'OTHER',
		})
			.then((user) => {
				setUser(user);
				loadNotifications();
				navigate('/client/', { replace: true });
			})
			.catch((e) => NotificationService.error(e.message))
			.finally(() => setSubmitting(false));
	};

	const trimInputValue = (name, e) => setValue(name, e.target.value.trim());

	const renderLicenseRegisterOptIn = () => (
		<div>
			I’d like to receive helpful content from{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.workinggenius.com/'>
				Working Genius
			</a>{' '}
			and{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.tablegroup.com/'>
				The Table Group
			</a>
		</div>
	);

	const renderContent = () => {
		if (loading) {
			return (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			);
		}

		if (linkMessage) {
			return (
				<div className='flex flex-col justify-center mx-4 py-8'>
					<AppRIIcon icon='link-unlink-m' className='text-red-600 text-6xl text-center' />
					<h1 className='text-primary text-xl text-center font-bold mb-4'>{linkMessageTitle}</h1>
					<p className='text-primary text-center w-160 max-w-full mx-auto mb-1'>{linkMessage}</p>
					<AppButton
						loading={loading}
						className='btn btn-primary w-96 max-w-full mx-auto'
						text={getAuthCookie() ? 'Go to account' : 'Login'}
						onClick={() => navigate('/client/login')}
					/>
				</div>
			);
		}

		return (
			<div className='md:shadow-md sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto my-12 px-4 md:px-12 py-8'>
				<h1 className='uppercase text-secondary text-center text-3xl font-semibold mb-4 leading-none'>
					CREATE ACCOUNT
				</h1>
				<form
					className='w-full'
					onSubmit={handleSubmit(handleRegister)}
					autoComplete='autocomplete_off_hack_xfr4!k'
				>
					<AppInput {...register('email')} label='Email address' disabled={true} />
					<AppInput
						label='First Name'
						data-ttg-max-length={32}
						minLength={2}
						onBlur={(e) => trimInputValue('first_name', e)}
						errors={errors}
						{...register('first_name', {
							required: 'You must enter a first name',
							maxLength: 32,
							minLength: 2,
						})}
					/>

					<AppInput
						label='Last Name'
						data-ttg-max-length={32}
						minLength={2}
						onBlur={(e) => trimInputValue('last_name', e)}
						errors={errors}
						{...register('last_name', {
							required: 'You must enter a last name',
							maxLength: 32,
							minLength: 2,
						})}
					/>

					<AppInput
						{...register('company', {
							maxLength: 64,
						})}
						ttgMaxLength={64}
						label={
							<span>
								Company Name <span className='text-gray-400 font-thin'>(Optional)</span>
							</span>
						}
						errors={errors}
						onBlur={(e) => trimInputValue('company', e)}
					/>

					<Controller
						control={control}
						name={'role'}
						rules={{ required: !!companyValue }}
						containerClassName={'mb-4'}
						render={({ field, formState }) => (
							<AppDropdown
								id={'wg-signup-role-select'}
								name={'wg-signup-role-select'}
								{...field}
								label={'Role'}
								isClearable
								options={CustomerService.CustomerBusinessRoles}
								shouldShowErrorMessage
								errors={formState.errors}
								loading={false}
								containerClassName={'mb-4'}
								styles={{
									option: (provided, { isFocused }) => {
										return {
											...provided,
											backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
											color: 'text-gray-700',
										};
									},
								}}
							/>
						)}
					/>

					<AppInput
						label='Password'
						type='password'
						errors={errors}
						{...register('password', {
							required: true,
							pattern: {
								value: passwordRegex,
								message:
									'Password must contain at least 8 characters, 1 upper case character, 1 lowercase character and 1 number.',
							},
						})}
					/>
					<AppInput
						label='Confirm password'
						type='password'
						errors={errors}
						{...register('confirm_password', {
							required: true,
							validate: (value) => value === getValues('password') || 'Passwords do not match',
						})}
					/>

					<div id={'license-register-opt-in'} className={'flex flex-col mb-3'}>
						<AppLabel
							label={renderLicenseRegisterOptIn()}
							labelIcon={'info-circled'}
							hasError={!!errors['opt_in']}
							customClassName={'inline-block text-left pb-1'}
						/>
						<AppRadioGroup
							{...register('opt_in', {
								required: 'is required',
							})}
							name={'opt_in'}
							errors={errors}
							value={optIn}
							options={[
								{
									label: 'Yes',
									value: 1,
								},
								{
									label: 'No',
									value: 0,
								},
							]}
							onChange={(selectedOption) => {
								setOptIn(selectedOption);
								setValue('opt_in', selectedOption);
								clearErrors('opt_in');
							}}
						/>
					</div>

					<AppButton
						type='submit'
						className='btn btn-primary w-full my-6'
						text='Create account'
						loading={submitting}
						id='wg-create-account-submit-btn'
					/>
				</form>

				<ReCaptcha ref={captchaRef} />
			</div>
		);
	};

	React.useEffect(handleVerifyToken, [setValue, token]);

	return (
		<Layout title='Register to claim licenses | The Six Types of Working Genius'>
			<div className='container mx-auto'>{renderContent()}</div>
		</Layout>
	);
};

export default RegisterInvitation;
