import React from 'react';
import AppButton from '../AppButton';
import { workshop_broll, workshop_logo } from '../../images';

const WorkshopHero = ({ onWorkWithUsClick }) => {
	return (
		<header className='workshop-hero-header'>
			<div className='video-wrapper'>
				<video aria-hidden={true} autoPlay={true} muted={true} loop={true} id='hero-video'>
					<source src={workshop_broll} type='video/webm' />
				</video>
				<div className='overlay-color'></div>
				<div className='hero-content-container'>
					<img src={workshop_logo} alt='Working Genius Workshop' width='400' />
					<AppButton
						onClick={onWorkWithUsClick}
						className={'btn btn-primary workshop-primary-btn mt-6 py-2 z-100'}
						text={'Inquire today'}
						id={'wg-workshop-hero-request-btn'}
					/>
				</div>
			</div>
		</header>
	);
};

export default WorkshopHero;
