import React from 'react';
import Layout from '../../../components/Layout';
import HomepageFooter from '../../../components/homepage/HomepageFooter';

const Contact = () => {
	return (
		<Layout title='The 6 Types of Working Genius: Administrator Console'>
			<div className='contact-page'>
				<HomepageFooter />
			</div>
		</Layout>
	);
};

export default Contact;
