import React from 'react';
import Header from './Header';
import NextUp from './NextUp';
import AppButton from '../AppButton';
import AppVideoModal from '../AppVideoModal';
import UtilityService from '../../services/UtilityService';
import { tag_6types_thumbnail } from '../../images';
import DownloadResource from './DownloadResource';

const Leading = ({ setActiveTab }) => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	const headerContent = {
		title: 'Leading a Team Discussion',
		subtitle: 'You can have a meaningful conversation about Working Genius in about an hour',
		description: `Working Genius comes alive when team members are able to better understand one another's geniuses and frustrations. You can use the Discussion Guide and Team Map to help a team build trust, maximize strengths, and increase productivity.`,
	};
	return (
		<div>
			<Header
				title={headerContent.title}
				subtitle={headerContent.subtitle}
				desription={headerContent.description}
				containerClass={'wg-toolkit-leading-container'}
			/>
			<div className='toolkit-content'>
				<p className='text-lg'>
					<b>STEP ONE: Watch this video from Pat About The 6 Types of Working Genius with Your Team</b>
				</p>
				<div className='flex items-center justify-center'>
					<a
						className='book-video-link cursor-pointer'
						onClick={() => setVimeoUrl('https://player.vimeo.com/video/782485296?autoplay=1')}
						id='wg-about-play-main-video'
					>
						<img src={tag_6types_thumbnail} alt='Working Genius Resources' width='550' />
					</a>
				</div>
			</div>

			<div className='toolkit-content'>
				<p className='text-lg pl-6'>
					<b>STEP TWO: Lead the team through a discussion about their results</b>
				</p>
				<div className='ml-6'>
					<div className='flex flex-col items-center lg:flex-row lg:items-start'>
						<div className='flex flex-col'>
							<p>
								<b>Ask your team the following questions (10-20 minutes):</b>
							</p>
							<ul className='list-disc mb-8 toolkit-leading-list'>
								<li>What are your Working Geniuses &amp; Working Frustrations?</li>
								<li>What is one “a-ha” insight from reading your results?</li>
								<li>Where in your work do you get to use your geniuses?</li>
								<li>
									Where in your work do you experience guilt or exhaustion based on your Working
									Frustrations?
								</li>
							</ul>
							<p>
								<b>Hand out your Team Map and discuss (20-30 minutes):</b>
							</p>
							<ul className='list-disc mb-8 toolkit-leading-list'>
								<li>What are our team’s areas of strength and what frustrates our team?</li>
								<li>
									{' '}
									Do we have any genius gaps? In other words, is there a genius missing or
									underrepresented on the team?
								</li>
								<li>
									{' '}
									Does our Team Map explain anything about the outcomes, successes, or obstacles we
									experienced during past projects or initiatives?
								</li>
								<li>
									How does Working Genius &amp; our map explain times where we have worked well
									together or times where we have frustrated one another in the past?
								</li>
								<li>
									Where in our current roles and responsibilities could we leverage one another’s
									geniuses better?
								</li>
								<li>How could we help each other avoid too much work in our frustration?</li>
							</ul>
							<p>
								<b>Conclude your discussion with these conversations (5-10 minutes):</b>
							</p>
							<ul className='list-disc mb-8 toolkit-leading-list'>
								<li>
									Everyone write down one thing that you can commit to do differently based on
									today’s discussion, and then go around the room and share.
								</li>
								<li>What (if anything) did we decide today?</li>
								<li>When will we revisit any topics that weren’t resolved?</li>
								<li>Is there anything we talked about that we should communicate outside this room?</li>
								<li>
									Is there anything we talked about today that we shouldn’t share outside this room?
								</li>
							</ul>
						</div>
						<div className='m-4'>
							<div className='toolkit-highlighted-section' style={{ minWidth: '260px' }}>
								<p style={{ margin: '0px' }}>
									<b>
										Tip: When sharing, lead the way with vulnerability. When discussing
										solutions, ensure everyone has the opportunity to <br /> weigh in.
									</b>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='my-6' style={{ maxWidth: '500px' }}>
					<DownloadResource title={'PRINTABLE DISCUSSION GUIDE OUTLINE'} file={'https://files.tablegroup.com/wp-content/uploads/2023/03/01073615/Working-Genius-Leading-the-Working-Genius-Team-Discussion.pdf'} />
				</div>
			</div>
			<NextUp
				nextTab={'tab5'}
			>
				<AppButton
					className={'btn btn-primary my-4 mx-auto'}
					text={'MEETINGS, HIRING, & STAFFING'}
					onClick={() => {
						UtilityService.scrollToTop();
						setActiveTab('tab5');
					}}
					id={'wg-toolkit-leading-btn'}
				/>
			</NextUp>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default Leading;
