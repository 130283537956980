import React from 'react';
import { cert_section_1 } from '../../../images';

const Hero = () => {
	return (
		<div
			className={'text-center py-12'}
			style={{
				backgroundImage: `url(${cert_section_1})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
			}}
		>
			<div className={'container mx-auto'}>
				<h1 className={'text-3xl  xl:text-5xl text-white tracking-wider'}>Working Genius Trainee Resources</h1>
				<p className={'w-4/5 mx-auto text-white text-lg mx-8 mt-4 tracking-widest font-light'}>
					Welcome to Working Genius Trainee Resources
				</p>
			</div>
		</div>
	);
};

export default Hero;
