import React from 'react';
import AppIcon from './AppIcon';

const AppAudioControls = ({ isPlaying, onPlayPauseClick, onPrevClick, onNextClick, onSpeedChange, speed }) => (
	<div className='flex justify-center items-center'>
		<div className='control' onClick={onPrevClick} id={'player-to-start-button'}>
			<AppIcon className={'control-icon cursor-pointer'} icon={'to-start'} />
		</div>

		<div className='control' onClick={onNextClick} id={'player-to-end-button'}>
			<AppIcon className={'control-icon cursor-pointer'} icon={'to-end'} />
		</div>

		<div className='control cursor-pointer ' onClick={onSpeedChange}>
			<span className={'font-medium px-2'}>{speed}x</span>
		</div>

		<div className='control play' onClick={() => onPlayPauseClick(!isPlaying)} id={'player-play-pause-button'}>
			<AppIcon className={'control-icon cursor-pointer'} icon={isPlaying ? 'pause' : 'play'} />
		</div>
	</div>
);

export default AppAudioControls;
