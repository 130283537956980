import React from 'react';
import CoverPage from './en/CoverPage';
import Overview from './en/Overview';
import Footer from './en/Footer';
import QuantitativeResults from './en/QuantitativeResults';
import QuantitativeReview from './en/QuantitativeReview';
import Wonder from './en/Wonder';
import Invention from './en/Invention';
import Discernment from './en/Discernment';
import Galvanizing from './en/Galvanizing';
import Enablement from './en/Enablement';
import Tenacity from './en/Tenacity';
import FinalDetermination from './en/FinalDetermination';
import GeniusTeam from './en/GeniusTeam';
import TeamApplicationExercise from './en/TeamApplicationExercise';
import TeamImpact from './en/TeamImpact';
import TeamImpactContinued from './en/TeamImpactContinued';
import GeniusIndividual from './en/GeniusIndividual';
import GoingDeeper from './en/GoingDeeper';

const EnglishTemplate = ({ assessment }) => {
	return (
		<div id={'wg-assessment-report'} className={'wg-pdf-assessment-report'}>
			<section role='region' className='Page CoverPage'>
				<CoverPage assessment={assessment} />
			</section>
			<section role='region' className='Page Overview'>
				<Overview assessment={assessment} />
				<Footer page={1} />
			</section>
			<section role='region' className='Page QuantitativeResults'>
				<QuantitativeResults assessment={assessment} />
				<Footer page={2} />
			</section>
			<section role='region' className='Page QualitativeReview'>
				<QuantitativeReview assessment={assessment} />
				<Footer page={3} />
			</section>
			<section role='region' className='Page Genius Wonder'>
				<Wonder assessment={assessment} />
				<Footer page={4} />
			</section>
			<section role='region' className='Page Genius Invention'>
				<Invention assessment={assessment} />
				<Footer page={5} />
			</section>
			<section role='region' className='Page Genius Discernment'>
				<Discernment assessment={assessment} />
				<Footer page={6} />
			</section>
			<section role='region' className='Page Genius Galvanizing'>
				<Galvanizing assessment={assessment} />
				<Footer page={7} />
			</section>
			<section role='region' className='Page Genius Enablement'>
				<Enablement assessment={assessment} />
				<Footer page={8} />
			</section>
			<section role='region' className='Page Genius Tenacity'>
				<Tenacity assessment={assessment} />
				<Footer page={9} />
			</section>
			<section role='region' className='Page FinalDetermination'>
				<FinalDetermination />
				<Footer page={10} />
			</section>
			<section role='region' className='Page ApplicationSection GeniusTeams'>
				<GeniusTeam assessment={assessment} />
				<Footer page={11} />
			</section>
			<section role='region' className='Page ApplicationSection TeamApplicationExercise'>
				<TeamApplicationExercise assessment={assessment} />
				<Footer page={12} />
			</section>
			<section role='region' className='Page ApplicationSection TeamImpact'>
				<TeamImpact assessment={assessment} />
				<Footer page={13} />
			</section>
			<section role='region' className='Page ApplicationSection TeamImpact TeamImpactContinued'>
				<TeamImpactContinued assessment={assessment} />
				<Footer page={14} />
			</section>
			<section role='region' className='Page ApplicationSection GeniusIndividual'>
				<GeniusIndividual />
				<Footer page={15} />
			</section>
			<section role='region' className='Page ApplicationSection GoingDeeper'>
				<GoingDeeper />
				<Footer page={16} />
			</section>
			<section role='region' className='Page Outro' />
		</div>
	);
};

export default EnglishTemplate;
