import React from 'react';
import PageHeader from './PageHeader';
import ColorGearImage from '../../ColorGearImage';
import ReportService from '../../../../services/ReportService';
import PageTitle from './PageTitle';

const Results = ({ assessment }) => {
    const renderGenii = (genii, color) => {
        return (
            <>
                {genii.map((g, key) => {
                    return (
                        <div className='result' key={key}>
                            <div className='gear'>
                                <ColorGearImage
                                    type={g.label}
                                    color={color}
                                    height={'50px'}
                                    width={'50px'}
                                    style={{ marginLeft: '45px' }}
                                />
                            </div>
                            <div className='text'>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: ReportService.StudentResults[g.label][g.level],
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className='QuantitativeResults'>
            <PageHeader title={'Results'} />
            <div className='container'>
                <PageTitle title={'Your Results'} className={'mt-10'} />
                <p className={'font-bold text-14 mt-4 mb-6'}>
                    {assessment.participant.fname}, the information below summarizes the results of your assessment.
                </p>

                <div className='resultset genius'>
                    <h2 className={'text-xl font-bold'}>WORKING GENIUS:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Genius(
                                assessment.customResults.genius1,
                                assessment.customResults.genius2,
                                ReportService.genius_color
                            ),
                        }}
                    />

                    {renderGenii(
                        [assessment.results.genii[0], assessment.results.genii[1]],
                        ReportService.genius_color
                    )}
                </div>

                <div className='resultset competency'>
                    <h2 className={'text-xl font-bold'}>WORKING COMPETENCY:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Competency(
                                assessment.customResults.competency1,
                                assessment.customResults.competency2,
                                ReportService.competency_color
                            ),
                        }}
                    />

                    {renderGenii(
                        [assessment.results.genii[2], assessment.results.genii[3]],
                        ReportService.competency_color
                    )}
                </div>

                <div className='resultset frustration'>
                    <h2 className={'text-xl font-bold'}>WORKING FRUSTRATION:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Frustration(
                                assessment.customResults.frustration1,
                                assessment.customResults.frustration2,
                                ReportService.frustration_color
                            ),
                        }}
                    />

                    {renderGenii(
                        [assessment.results.genii[4], assessment.results.genii[5]],
                        ReportService.frustration_color
                    )}
                </div>

                <p className={'font-semibold text-12 mt-4 mb-6'}>
                    On the next page you’ll learn more about your unique Genius pairing (along with a description of all 15 pairings).
                </p>
            </div>
        </div>
    );
};

export default Results;
