import React, { useState } from 'react';
import { navigate } from 'gatsby';

import Sidebar from '../../components/toolkit/Sidebar';
import ToolkitLayout from '../../components/toolkit/ToolkitLayout';
import ToolkitMenuContent from '../../components/toolkit/ToolkitMenuContent';
import Welcome from '../../components/toolkit/Welcome';
import Prework from '../../components/toolkit/Prework';
import Preparing from '../../components/toolkit/Preparing';
import Leading from '../../components/toolkit/Leading';
import AdditionalTools from '../../components/toolkit/AdditionalTools';
import HamburgerMenu from '../../components/toolkit/HamburgerMenu';
import AppButton from '../../components/AppButton';
import { useUser } from '../../hooks/UserProvider';

const Toolkit = () => {
	const { user } = useUser();
	const [activeTab, setActiveTab] = useState('tab1');

	if (!user.show_team_application_guide) {
		navigate('/teamapplicationguide');
	}

	return (
		<ToolkitLayout>
			<HamburgerMenu setActiveTab={setActiveTab} />
			<div className={'wg-toolkit-layout-content'}>
				<div className={'hidden md:block'}>
					<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />
				</div>
				<div className={'wg-toolkit-layout-inner-content'}>
					<ToolkitMenuContent id='tab1' activeTab={activeTab}>
						<Welcome name={user.fullName} setActiveTab={setActiveTab} />
					</ToolkitMenuContent>
					<ToolkitMenuContent id='tab2' activeTab={activeTab}>
						<Prework setActiveTab={setActiveTab} />
					</ToolkitMenuContent>
					<ToolkitMenuContent id='tab3' activeTab={activeTab}>
						<Preparing setActiveTab={setActiveTab} />
					</ToolkitMenuContent>
					<ToolkitMenuContent id='tab4' activeTab={activeTab}>
						<Leading setActiveTab={setActiveTab} />
					</ToolkitMenuContent>
					<ToolkitMenuContent id='tab5' activeTab={activeTab}>
						<AdditionalTools />
						<div className={'flex items-center justify-center px-6 py-10'}>
							<AppButton text={'Return to Assessment Console'} onClick={() => navigate('/client/')} />
						</div>
					</ToolkitMenuContent>
				</div>
			</div>
		</ToolkitLayout>
	);
};

export default Toolkit;
