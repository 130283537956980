import Axios from 'axios';
import ApiService from './ApiService';
import UtilityService, { serializeQueryString } from './UtilityService';

export const list = (filters) => {
	return new Promise((resolve, reject) => {
		const queryParams = serializeQueryString(filters);
		ApiService.get(`v1/assessments?${queryParams}`)
			.then(({ data }) => {
				data.data = data.data.map((item) => {
					if (
						item.type === 'individual' &&
						item.complete &&
						item.new_wc1 == null &&
						(item.wc1.includes('(tie)') ||
							item.wc2.includes('(tie)') ||
							item.wf1.includes('(tie)') ||
							item.wf2.includes('(tie)') ||
							item.wg1.includes('(tie)') ||
							item.wg2.includes('(tie)'))
					) {
						item.hasTies = true;
					}
					return item;
				});
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const details = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/assessment/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const create = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/assessment/add`, {
			...payload,
			send: payload.send ? 0 : 1,
			cc_emails: payload.cc_emails?.map(({ value }) => value).join(',') ?? null,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const update = (id, payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/assessment/${id}`, {
			...payload,
			send: payload.send ? 0 : 1,
		})
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const updateCompanyLogo = (slug, payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`v1/assessment/${slug}/company_logo`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const getCompanyLogoSignedUrl = (slug, file_name) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/assessment/${slug}/company_logo_signed_url?file_name=${file_name}`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const uploadCompanyLogo = (key, url, file) => {
	return new Promise((resolve, reject) => {
		Axios.put(url, file, {
			headers: {
				'x-amz-acl': 'public-read',
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		})
			.then(() => {
				resolve({
					key,
					url,
				});
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const addLicenses = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/license/add`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const close = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/close`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const prepareQuestions = (data) => {
	return data.map(({ num, sort, question, category }) => ({
		id: num,
		question: `${sort}. ${question}`,
		category: category,
		answer: -1,
		sort: sort,
	}));
};

const prepareResponses = (data) => {
	return data.map(({ value, label }) => ({
		value,
		answer: label,
	}));
};

export const getQuestions = (filters) => {
	const queryParams = serializeQueryString(filters);
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/questions?${queryParams}`).then(({ data }) => {
			const questions = prepareQuestions(data.questions);
			const responses = prepareResponses(data.responses);
			resolve({ questions, responses });
		}, reject);
	});
};

export const predefinedResponses = [
	{ scores: '70,63,56,49,42,35', value: 'no-ties', label: 'No ties 2-2-2' },
	{ scores: '70,63,63,56,49,42', value: '2-3', label: 'Ties between 2nd and 3rd categories' },
	{ scores: '70,70,70,63,56,49', value: '1-3', label: 'Ties between the top 3 categories' },
	{ scores: '70,63,56,49,49,42', value: '4-5', label: 'Ties between 4th and 5th categories' },
	{ scores: '70,63,63,63,56,49', value: '2-4', label: 'Ties between the 2nd, 3rd and 4th categories' },
	{ scores: '70,63,56,49,49,49', value: '4-6', label: 'Ties between the bottom 3 categories' },
	{ scores: '70,70,70,70,63,56', value: '1-4', label: 'Ties between the top four' },
	{ scores: '70,63,63,63,63,56', value: '2-5', label: 'Ties between the middle four' },
	{ scores: '70,63,56,56,56,56', value: '3-6', label: 'Ties between the bottom four' },
	{ scores: '70,70,70,70,70,63', value: '1-5', label: 'Ties between the top five' },
	{ scores: '70,63,63,63,63,63', value: '2-6', label: 'Ties between the bottom five' },
	{ scores: '70,70,70,70,70,70', value: '1-6', label: 'Tie between all six' },
	{
		scores: '70,63,63,56,56,49',
		value: '2-3,4-5',
		label: 'Ties between 2nd and 3rd categories AND 4th and 5th categories',
	},
	{
		scores: '70,70,70,63,63,63',
		value: '1-3,4-6',
		label: 'Ties between the top 3 categories AND the bottom 3 categories',
	},
	{
		scores: '70,63,63,56,56,56',
		value: '2-3,4-6',
		label: 'Ties between 2nd and 3rd categories AND bottom 3 categories',
	},
	{
		scores: '70,70,70,63,63,56',
		value: '1-3,4-5',
		label: 'Ties between the top 3 categories AND 4th and 5th categories',
	},
];

const getQuestionsMap = (array) => {
	const map = {};
	array.map((item) => {
		item.category = item.category.toLowerCase();
		if (!map[item.category]) {
			map[item.category] = [];
		}
		map[item.category].push(item.id);
	});
	return map;
};

export const shuffle = (array) => {
	var currentIndex = array.length,
		temporaryValue,
		randomIndex;

	// while there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// and swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}

	return array;
};

export const fillAnswers = (questions, selection, options) => {
	if (!selection || !selection.value) {
		return questions.map((q) => ({
			...q,
			answer: options[Math.floor(options.length * Math.random())].value,
		}));
	}
	const responsesMap = {
		responses_70: [10, 10, 10, 10, 10, 10, 10],
		responses_63: [10, 10, 10, 10, 10, 10, 3],
		responses_56: [10, 10, 10, 10, 10, 3, 3],
		responses_49: [10, 10, 10, 10, 3, 3, 3],
		responses_42: [10, 10, 10, 3, 3, 3, 3],
		responses_35: [10, 10, 3, 3, 3, 3, 3],
	};
	const questionsMap = getQuestionsMap(questions);
	const categories = shuffle(Object.keys(questionsMap));
	const scoreSet = selection.scores.split(',');

	for (let x = 0; x < categories.length; x++) {
		const category = categories[x];
		const score = scoreSet[x];
		const responses = responsesMap['responses_' + score];

		questionsMap[category].map((question, key) => {
			const i = questions.findIndex(({ id }) => id === question);
			questions[i].answer = responses[key];
		});
	}
	return questions;
};

export const getAssessmentDetails = (assessment_id, roster_id, extraParams = {}, language) => {
	return new Promise((resolve, reject) => {
		ApiService.post('v1/assessment/take', {
			assessment_id: assessment_id,
			roster_id: roster_id,
			language: language,
			...extraParams,
		}).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const sharePreviousAssessment = (
	to_assessment_id,
	to_roster_id,
	from_assessment_id,
	from_roster_id,
	allow_share
) =>
	ApiService.post(`v1/assessment/share/${to_assessment_id}/${to_roster_id}`, {
		from_assessment_id,
		from_roster_id,
		allow_share,
	});

export const getAssessmentBySlug = (slug) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/assessment/take?slug=${slug}`).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const setPreviousResponses = (questions, responses) => {
	const mappedResponses = {};
	responses.map((res) => {
		if (res.name) {
			mappedResponses[res.name.toString().replace('q', '')] = parseInt(res.value);
		}
	});
	return questions.map((q) => ({
		...q,
		answer: mappedResponses[q.id] !== undefined ? mappedResponses[q.id] : -1,
	}));
};

export const updateProgress = (assessment_id, roster_id, step, responses, isLastStep) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/roster/progress`, {
			assessment_id,
			roster_id: roster_id,
			step,
			responses,
		}).then(({ data }) => {
			if (isLastStep) {
				ApiService.post(`/v1/assessment/end`, {
					assessment_id,
					roster_id: roster_id,
					responses,
				}).then(({ data }) => {
					resolve(data);
				}, reject);
			} else {
				resolve(data);
			}
		}, reject);
	});
};

export const breakTies = (assessment_id, roster_id, scores) => {
	return new Promise((resolve, reject) => {
		ApiService.post('/v1/assessment/break_ties', {
			assessment_id,
			roster_id,
			scores,
		}).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const prepareTies = (ties) => {
	let valueOffset = 0;
	return ties.map((tie) => {
		const params = {
			...tie,
			valueOffset,
			options: tie.options.map((option) => ({
				label: option,
				score: 0,
			})),
		};
		valueOffset += tie.options.length;
		return params;
	});
};

export const deleteRoster = (roster_id, assessment_id, is_duplicated) => {
	return new Promise((resolve, reject) => {
		ApiService.delete(`/v1/${is_duplicated ? 'duplicated_roster' : 'roster'}`, {
			data: {
				roster_id,
				assessment_id,
			},
		}).then(resolve, reject);
	});
};

export const getReportData = (params) => {
	return new Promise((resolve, reject) => {
		const query = serializeQueryString(params);
		ApiService.get(`/v1/roster/reportData?${query}`).then(({ data }) => {
			data.customResults = {};
			data.results.genii = data.results.genii.map((item) => {
				if (item.level === 'tie') {
					item.matchIndexes = item.matches.map((match) => {
						return data.results.genii.find(({ label }) => label.toLowerCase() === match.toLowerCase())
							.index;
					});
					data.hasTies = true;
				}
				let key = `${item.level}1`;
				if (data.customResults[key]) {
					key = `${item.level}2`;
				}
				data.customResults[key] = item.label;
				return item;
			});
			resolve(data);
		}, reject);
	});
};

export const generateReport = (payload, name, language) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/roster/generateReport`, {
			roster_id: payload.roster_id,
			assessment_id: payload.assessment_id,
			language: language,
		}).then(({ data }) => {
			const buffer =
				data.base64 && data.base64.data ? data.base64.data : UtilityService.convertBase64ToBuffer(data.base64);
			UtilityService.downloadPdfFromBuffer(buffer, `${name} Working Genius Assessment Report.pdf`);
			resolve();
		}, reject);
	});
};

export const sendReport = (payload) => {
	let roster_ids = payload.roster_ids;
	if (payload.roster_id) {
		roster_ids = [payload.roster_id];
	}
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/roster/sendReport`, {
			roster_ids,
			assessment_id: payload.assessment_id,
		}).then(resolve, reject);
	});
};

export const prepareSlug = (slug, slug2, slug3, slug4) => {
	const pieces = [slug];
	if (slug2) pieces.push(slug2);
	if (slug3) pieces.push(slug3);
	if (slug4) pieces.push(slug4);
	return pieces.join('/');
};

export const shareAssessment = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/share`, {
			email: payload.email,
			assessment_id: payload.assessment_id,
			roster_ids: payload.roster_ids,
		}).then(resolve, reject);
	});
};

export const bulkShareAssessment = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/bulk_share`, {
			email: payload.email,
			assessment_id: payload.assessment_id,
			roster_ids: payload.roster_ids,
		}).then(resolve, reject);
	});
};

export const requestShareStatus = (payload) => {
	return new Promise((resolve, reject) => {
		const query = serializeQueryString({
			...payload,
		});
		ApiService.get(`/v1/roster/share?${query}`).then(resolve, reject);
	});
};

export const requestShare = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/roster/share`, {
			assessment_id: payload.assessment_id,
			roster_id: payload.roster_id,
		}).then(resolve, reject);
	});
};

export const sendRequestShareResponse = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/roster/share`, {
			assessment_id: payload.assessment_id,
			roster_id: payload.roster_id,
			allow_share: payload.allow_share,
			token: payload.token,
		}).then(resolve, reject);
	});
};

export const getRequestShareValidity = (payload) => {
	return new Promise((resolve, reject) => {
		const query = serializeQueryString({
			...payload,
		});
		ApiService.get(`/v1/roster/share/verify?${query}`).then(resolve, reject);
	});
};

export const getGroupsForMove = (payload) => {
	return new Promise((resolve, reject) => {
		const query = serializeQueryString({
			limit: 10,
			...payload,
		});
		ApiService.get(`/v1/assessments_light?${query}`).then(({ data }) => {
			resolve(data.data);
		}, reject);
	});
};

export const move = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/move`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const duplicate = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/duplicate`, payload).then(({ data }) => {
			resolve(data);
		}, reject);
	});
};

export const getOriginalRosterAssessmentId = (payload) => {
	const query = serializeQueryString({
		...payload,
	})

	return ApiService.get(`/v1/roster/original_assessment?${query}`).then(({ data }) => data);
}

export const getDuplicatedInAssessments = (payload) => {
	const query = serializeQueryString({
		...payload,
	})

	return ApiService.get(`/v1/roster/duplicated_in?${query}`).then(({ data }) => data);
}

export const sendSampleReport = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/sample_report`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const addToExistingAccount = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/assessment/add_to_existing_account`, payload)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export default {
	list,
	details,
	create,
	update,
	updateCompanyLogo,
	getCompanyLogoSignedUrl,
	uploadCompanyLogo,
	addLicenses,
	close,
	prepareQuestions,
	prepareResponses,
	getQuestions,
	predefinedResponses,
	getQuestionsMap,
	shuffle,
	fillAnswers,
	getAssessmentDetails,
	getAssessmentBySlug,
	getDuplicatedInAssessments,
	setPreviousResponses,
	updateProgress,
	breakTies,
	prepareTies,
	deleteRoster,
	getReportData,
	generateReport,
	sendReport,
	prepareSlug,
	shareAssessment,
	bulkShareAssessment,
	requestShareStatus,
	requestShare,
	sendRequestShareResponse,
	getRequestShareValidity,
	getGroupsForMove,
	move,
	duplicate,
	getOriginalRosterAssessmentId,
	sendSampleReport,
	addToExistingAccount
};
