import React from 'react';
import { book_3d, cert_logo_de } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const GoingDeeper = () => {
    return (
        <div className='GoingDeeper'>
            <PageHeader title={'ANWENDUNG'} />
            <div className={'container mt-6'}>
                <PageTitle title={'Sie möchten tiefer einsteigen? '} />

                <p className={'my-4'}>
                    Das Working Genius Assessment ist der erste Schritt, um Ihre Gaben zu entdecken und erleben,
                    wie es Ihre Arbeit, Ihr Team und Ihr Leben verändern kann. Das Working Genius Team hat
                    verschiedene Ressourcen entwickelt, um Einzelpersonen, Teams und Organisationen dabei
                    zu helfen, dieses Modell sowohl im persönlichen als auch im beruflichen Leben zu nutzen.
                    Entdecken Sie mit den folgenden Ressourcen, wie Working Genius auf Meetings, Einstellungen,
                    Projektmanagement, Produktivität, Beziehungen, Familie und vieles mehr angewendet werden kann:
                </p>

                <div className={'grid grid-cols-1 gap-4 mx-12'}>
                    <div className={'german-item-container'}>
                        <img src={book_3d} alt='Book image' style={{ minHeight: '220px', maxHeight: '220px', padding: ' 0 8px' }} />
                        <div className='flex flex-col items-center justify-center pl-8'>
                            <p className={'title mx-10'}>Das Buch</p>
                            <a href={'https://www.wiley-vch.de/de/fachgebiete/finanzen-wirtschaft-recht/wirtschaft-und-management-13ba/management-13ba1/management-f-teams-13ba15/die-6-grossen-talente-im-team-978-3-527-51142-6'} className={'btn btn-primary btn-small'}>
                                JETZT KAUFEN
                            </a>
                        </div>
                    </div>
                    <div className={'german-item-container'}>
                        <img src={cert_logo_de} alt='Certification image' style={{ minHeight: '190px', maxHeight: '190px' }} />
                        <div className='flex flex-col items-center justify-center px-8'>
                            <p className={'title'}>Zertifizierung</p>
                            <a href={'https://www.dalecarnegie.com/de/courses/6578'} className={'btn btn-primary btn-small'}>
                                MEHR ERFAHREN
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GoingDeeper;
