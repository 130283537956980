import React from 'react';
import AppButton from '../AppButton';
import { addToCart } from '../../services/CustomerService';
import { wg_students_assessments } from '../../images';

const WhyWorkingGenius = () => {
    return (
        <div className='students-why-wg-section'>
            <div className='container'>
                <div className='flex flex-col-reverse md:flex-row items-center justify-center'>
                    <div className='w-4/5 md:w-2/5'>
                        <h2 className='text-white text-xl md:text-3xl font-medium my-4'>Why Working Genius?</h2>
                        <p className='text-white text-2xl font-thin my-6'>
                            The assessment that has helped nearly one million
                            people discover their gifts and thrive at work is <span className='font-medium'>now designed for students</span>!
                        </p>
                        <AppButton
                            id={'wg-students-why-wg-buy-assessment-btn'}
                            className={'btn btn-student relative mt-20'}
                            text={'Take the Assessment Now'}
                            onClick={() => addToCart('add_student_license', true)}
                        />
                    </div>
                    <img src={wg_students_assessments} alt='Working Genius Students Assessments' width='500' />
                </div>
            </div>
        </div>
    );
};

export default WhyWorkingGenius;
