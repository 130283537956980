import React from 'react';
import ReportService from '../../../../services/ReportService';
import {
	report_overview_gear_d,
	report_overview_gear_e,
	report_overview_gear_g,
	report_overview_gear_i,
	report_overview_gear_t,
	report_overview_gear_w,
} from '../../../../images';
import PageTitle from './PageTitle';

const WGPairings = ({}) => {
	const pairings = React.useMemo(() => {
		return Object.keys(ReportService.UniqueParings)
			.map((key) => ({
				key,
				reverse: key.split('').reverse().join(''),
				info: ReportService.UniqueParings[key],
			}))
			.sort((a, b) => {
				return a.info.sort - b.info.sort;
			});
	}, []);

	const renderGear = (gear) => {
		gear = gear.toLowerCase();
		return (
			<>
				{gear === 'w' && <img src={report_overview_gear_w} alt={''} />}
				{gear === 'i' && <img src={report_overview_gear_i} alt={''} />}
				{gear === 'd' && <img src={report_overview_gear_d} alt={''} />}
				{gear === 'g' && <img src={report_overview_gear_g} alt={''} />}
				{gear === 'e' && <img src={report_overview_gear_e} alt={''} />}
				{gear === 't' && <img src={report_overview_gear_t} alt={''} />}
			</>
		);
	};

	return (
		<div className='UniquePairing all'>
			<div className='container'>
				<PageTitle title={'Working Genius Pairings'} className={'mb-4'} />

				<div className='grid grid-cols-3 gap-4'>
					{pairings.map((pairing) => (
						<div className={'pairing-container small'} key={pairing.info.sort}>
							<div className={'gears'}>
								{renderGear(pairing.key[0])}
								{renderGear(pairing.key[1])}
								<hr />
								{renderGear(pairing.reverse[0])}
								{renderGear(pairing.reverse[1])}
							</div>
							<div className={'information'}>
								<h1 className={'key'}>
									{pairing.key} | {pairing.reverse}
								</h1>
								<p className={'title'}>{pairing.info.title}</p>
								<p className={'brief'}>{pairing.info.brief}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default WGPairings;
