import React from 'react';
import AppIcon from '../AppIcon';
import { formatDate } from '../../services/UtilityService';
import AppRIIcon from '../AppRIIcon';

const BuilderPersonRow = (props) => {
	let selectedClass = '';
	let showButton = '';

	const handleAddMember = (props) => {
		let member = {
			assessment: props.assessment,
			fname: props.fname,
			lname: props.lname,
			email: props.email,
			id: props.id,
			type: props.type,
			completed: props.completed,
		};
		props.handleSelect(member);
	};

	if (props.selectedMembers[props.id]) {
		selectedClass = 'BuilderTable__row--selected';
	} else {
		showButton = (
			<td className='BuilderTable__assessmentInfo__controls'>
				<button
					type={'button'}
					onClick={() => handleAddMember(props)}
					className='BuilderTable__assessmentInfo__controlBtn Button btn-link'
					id={'wg-teammap-person-add-btn'}
				>
					<span className='ToolBar__Tools'>
						Add <AppIcon icon='right' />
					</span>
				</button>
			</td>
		);
	}

	const assessmentName = props.group ? (
		<p className={`BuilderTable__assessmentInfo__assmtName groupName ellipse-text flex items-center mt-2`}>
			{props.fname}&nbsp;{props.lname}
		</p>
	) : (
		<p id={'wg-teammap-name'}
		   className={`BuilderTable__assessmentInfo__assmtName individualName ellipse-text flex items-center mt-2`}
		>
			<AppRIIcon
				className={'text-25 text-secondary justify-center'}
				icon={props.shared_from ? 'user-received-fill' : 'user-fill'}
			/>
			{props.fname}&nbsp;{props.lname}
		</p>
	);

	return (
		<tr className={`BuilderTable__row fade-in ${selectedClass}`}>
			<td className='BuilderTable__row__infocell'>
				<table className='BuilderTable__innerTable'>
					<tr>
						<td id={'wg-team-map-assessment-info'} className={`BuilderTable__assessmentInfo`}>
							{assessmentName}
							<span className={`BuilderTable__assessmentInfo__email ${props.group ? '' : 'pl-4'}`}>
								{props.email}
							</span>
							<span className={`BuilderTable__assessmentInfo__created ${props.group ? '' : 'pl-4'}`}>
								{formatDate(props.completed)}
							</span>
						</td>

						{showButton}
					</tr>
				</table>
			</td>
		</tr>
	);
};

export default BuilderPersonRow;
