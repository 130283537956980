import React, { useEffect } from 'react';
import StepWizard from 'react-step-wizard';
import UtilityService from '../../../services/UtilityService';
import Instructions from './Instructions';
import Questions from './Questions';
import ConfirmationStep from './ConfirmationStep';
import AssessmentProgress from './AssessmentProgress';
import AppSelect from '../../AppSelect';
import AppButton from '../../AppButton';
import AssessmentsService from '../../../services/AssessmentsService';
import TieQuestions from './TieQuestions';
import Error from '../Error';
import i18n from 'i18next';
import config from '../../../constants/config';
import AddToAccount from "../AddToAccount";

const TakeWizard = ({ assessmentId, rosterId, slug }) => {
	const [questions, setQuestions] = React.useState([]);
	const [splitQuestions, setSplitQuestions] = React.useState([]);
	const [responses, setResponses] = React.useState([]);
	const [assessment, setAssessment] = React.useState({});
	const [assessmentResponse, setAssessmentResponse] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [tiesQuestions, setTiesQuestions] = React.useState([]);
	const [answered, setAnswered] = React.useState(0);
	const [predefinedResponse, setPredefinedResponse] = React.useState('');
	const [error, setError] = React.useState('');
	const [studentMode, setStudentMode] = React.useState(false);

	useEffect(() => {
		function onBeforeLeave(e) {
			e.returnValue = `Changes that you made may not be saved.`;
			return false;
		}

		if(assessment && assessment?.source !== 'API'){
			window.addEventListener('beforeunload', onBeforeLeave);
			return () => window.removeEventListener('beforeunload', onBeforeLeave);
		}

	}, [assessment]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			let assessment = await AssessmentsService.getAssessmentDetails(assessmentId, rosterId);
			let { questions, responses } = await AssessmentsService.getQuestions({ roster_mode: assessment.mode });

			setStudentMode(assessment.mode === 'student');
			if (assessment.responses && assessment.responses.length > 0) {
				questions = AssessmentsService.setPreviousResponses(questions, assessment.responses);
			}
			setQuestions(questions);
			setSplitQuestions(UtilityService.splitArray(questions, 5));
			setResponses(responses);
			setAssessment(assessment);
			if (assessment.language) {
				i18n.changeLanguage(assessment.language);
			} else {
				i18n.changeLanguage('en');
			}
			setLoading(false);
		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	};

	const onStepChange = ({ activeStep }) => {
		UtilityService.scrollToTop();
		const nonQuestionSteps = 1;
		const currentQuestionStep = activeStep - nonQuestionSteps;
		let answeredQuestions = tiesQuestions.filter((q) => !!q.score).length;
		for (let i = 0; i < currentQuestionStep - 1; i++) {
			if (splitQuestions[i]) {
				answeredQuestions += splitQuestions[i].filter((q) => q.answer > -1).length;
			}
		}
		setAnswered(answeredQuestions);
	};

	const onAnswer = (questionId, answer) => {
		const newQuestions = [...questions];
		const i = newQuestions.findIndex(({ id }) => id === questionId);
		newQuestions[i].answer = answer;
		setQuestions(newQuestions);
	};

	const onTieAnswer = (key, options, score) => {
		const newQuestions = [...tiesQuestions];
		newQuestions[key].options = options;
		newQuestions[key].score = score;
		setTiesQuestions(newQuestions);
	};

	const setPredefinedResponses = () => {
		const selection = AssessmentsService.predefinedResponses.find((r) => r.value === predefinedResponse);
		const _q = AssessmentsService.fillAnswers(questions, selection, responses);
		setQuestions([..._q]);
		setSplitQuestions(UtilityService.splitArray(_q, 5));
	};

	if (loading) {
		return (
			<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		);
	}

	if (error) {
		return <Error message={error} />;
	}

	return (
		<div id={'wg-assessment-take'} className={`wg-take-assessment ${config.name}`}>
			{config.name !== 'prod' && (
				<div className={'m-4'} id={'answers-op'}>
					<AppSelect
						value={predefinedResponse}
						propValue={'value'}
						options={AssessmentsService.predefinedResponses}
						renderText={({ label }) => label}
						placeholder={'Random answers'}
						onSelect={setPredefinedResponse}
					/>
					<AppButton onClick={setPredefinedResponses} text={'Set'} id={'wg-take-assessment-set'} />
				</div>
			)}

			<StepWizard
				nav={<AssessmentProgress
					progress={(answered * 100) / (questions.length + tiesQuestions.length)}
				/>}
				isLazyMount={true}
				onStepChange={onStepChange}
			>
				<Instructions assessment={assessment} studentMode={studentMode} />
				{splitQuestions.map((q, key) => (
					<Questions
						studentMode={studentMode}
						assessmentId={assessmentId}
						rosterId={rosterId}
						slug={slug}
						responses={responses}
						questions={q}
						allQuestions={questions}
						onAnswer={onAnswer}
						key={key}
						step={key + 1}
						totalQuestionSteps={splitQuestions.length}
						setTies={(ties, callback) => {
							setTiesQuestions(AssessmentsService.prepareTies(ties));
							setTimeout(() => {
								callback();
							}, 100);
						}}
						setAssessmentResponse={(response, callback) => {
							setAssessmentResponse(response);
							setTimeout(() => {
								callback();
							}, 100);
						}}
					/>
				))}
				{tiesQuestions.map((tie, key) => (
					<TieQuestions
						key={key}
						valueOffset={tie.valueOffset}
						questionNumber={questions.length + key + 1}
						question={tie}
						ties={tiesQuestions}
						onAnswer={(options, score) => onTieAnswer(key, options, score)}
						hasBackButton={key !== 0}
						isLastStep={key === tiesQuestions.length - 1}
						assessmentId={assessmentId}
						rosterId={rosterId}
						setAssessmentResponse={(response, callback) => {
							setAssessmentResponse(response);
							setTimeout(() => {
								callback();
							}, 100);
						}}
						studentMode={studentMode}
					/>
				))}
				<ConfirmationStep assessment={assessmentResponse} assessmentId={assessmentId} rosterId={rosterId} studentMode={studentMode} />
				<AddToAccount />
			</StepWizard>
		</div>
	);
};

export default TakeWizard;
