import React from 'react';
import { useTranslation } from 'react-i18next';

const AssessmentProgress = ({ progress, currentStep }) => {
	const { t } = useTranslation();

	if (currentStep === 1) {
		return null;
	}
	const roundedProgress = `${Math.ceil(progress)}%`;

	const renderProgressValue = () => {
		if (roundedProgress === '100%') {
			return `${t('finished')}!`;
		}
		return `${roundedProgress} ${t('completed')}`;
	};

	return (
		<div className={'m-4'} id={'assessment-progress-bar'}>
			<p className={'text-right font-thin text-primary py-2'} id={'assessment-progress-bar-value'}>
				{renderProgressValue()}
			</p>
			<div className='w-full bg-gray-300'>
				<div
					className='h-2 bg-secondary transition duration-500 ease-in-out '
					style={{ width: roundedProgress }}
				/>
			</div>
		</div>
	);
};
export default AssessmentProgress;
