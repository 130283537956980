import React from 'react';
import {
    report_team_map_analyze_de,
    team_map_large_report_arrow,
    team_map_small_report_arrow,
} from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const TeamMapAnalyze = () => {
    return (
        <div className='Application'>
            <PageHeader title={'ANWENDUNG'} />
            <div className={'container mt-12'}>
                <PageTitle title={'Analysieren einer Team Map'} />

                <p className={'mt-5 text-12 leading-snug'}>Hier sind ein paar Fragen für den Anfang:</p>

                <ol className={'list-decimal ml-4'}>
                    <li className={'pl-2'}>Welche Stärken hat unser Team?</li>
                    <li className={'pl-2'}>Welche Lücken hat unser Team?</li>
                    <li className={'pl-2'}>
                        Wie erklärt diese Team Map frühere Misserfolge und/oder Erfolge des Teams?
                    </li>
                    <li className={'pl-2'}>
                        Gibt es etwas, das wir bei unserem nächsten Projekt oder unserer nächsten Priorität ändern oder überdenken sollten,
                        um effektiver zu sein?
                    </li>
                </ol>

                <div className={'grid grid-cols-8 mt-6 gap-4 -mx-4'}>
                    <div className={'col-span-2 explanation-container'}>
                        <p>
                            Weil nur ein Mitglied über das Genius des Hinterfragens verfügt
                            und drei es als Frustration empfinden, muss dieses Team sicherstellen, dass
                            es sich die Zeit nimmt, die richtigen Fragen zu stellen und die richtigen
                            Probleme zu erkennen, bevor es zum Handeln übergeht.
                        </p>
                        <p>
                            Dieses Team sollte fragen:
                            <ul>
                                <li>Nutzen wir die Gabe von Yasmine für unsere Projekte und Initiativen?</li>
                                <li>Lösen wir wirklich die richtigen Probleme?</li>
                            </ul>
                        </p>
                        <div className={'report-arrow left'}>
                            <img src={team_map_small_report_arrow} alt='' />
                        </div>
                    </div>
                    <div className={'col-span-4 pt-12'}>
                        <img src={report_team_map_analyze_de} alt='' className={'shadow-md mb-8'} width='360' />
                    </div>
                    <div className={'col-span-2 explanation-container'}>
                        <p>
                            Motivation ist in diesem
                            Team stark vertreten.
                            Sie wissen, wie man
                            Energie und Dynamik
                            weckt, müssen aber
                            aufpassen, dass sie andere
                            nicht für die falschen
                            Initiativen mobilisieren.
                        </p>
                        <p>
                            Dieses Team sollte fragen:
                            <ul>
                                <li>
                                    Haben wir in der
                                    Vergangenheit Ideen mit
                                    Motivation aufgegriffen,
                                    die wir nicht vollständig
                                    geprüft hatten?
                                </li>
                                <li>
                                    Wecken wir immer
                                    wieder die Energie
                                    fürdieselben Ideen und
                                    Initiativen, ohne uns die
                                    Zeit zu nehmen, diese zu
                                    hinterfragen oder neues
                                    zu entwickeln?
                                </li>
                                <li>
                                    Wie können wir
                                    sicherstellen, dass
                                    wir unsere Gabe der
                                    Motivation nur auf unsere
                                    wichtigsten Initiativen
                                    konzentrieren?
                                </li>
                            </ul>
                        </p>
                        <div className={'report-arrow right'}>
                            <img src={team_map_small_report_arrow} alt='' />
                        </div>
                    </div>
                </div>
                <div className='mx-12 mt-4'>
                    <div className='explanation-container'>
                        <p>
                            Diesem Team mangelt es an Intuition und es hat möglicherweise Schwierigkeiten, die richtigen
                            Ideen zu bewerten, auszuarbeiten oder auszuwählen.
                        </p>
                        <p>
                            Dieses Team sollte fragen:
                            <ul>
                                <li>
                                    Wie erklärt ein Mangel an Intuition vergangene Frustrationen oder Misserfolge?
                                </li>
                                <li>
                                    Wie können wir sicherstellen, dass Ideen und Initiativen gründlich geprüft werden, bevor wir mit
                                    der Umsetzung beginnen?
                                </li>
                                <li>
                                    Können wir jemanden mit diesem Genius einstellen oder ausleihen, um uns bei unserer
                                    Entscheidungsfindung zu helfen?
                                </li>
                            </ul>
                        </p>
                        <div className={'report-arrow large'}>
                            <img src={team_map_large_report_arrow} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamMapAnalyze;
