import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SidebarCartView, RouteNames, useCheckout } from '@ttg/ui-checkout-lib';

import AppNotificationModal from './AppNotificationModal';
import CustomerService from '../services/CustomerService';
import AccountDropdown from './navbar/AccountDropdown';
import Widget from './notifications/Widget';
import { useUser } from '../hooks/UserProvider';
import SwitchAccountModal from './permissions/SwitchAccountModal';

const AppTopBar = () => {
	const { user, hasPermission, switchToOriginalUser } = useUser();
	const userMenuItems = [
		...(hasPermission('USER.PUT')
			? [
				{
					url: '/client/account/',
					label: 'My Account',
					icon: 'user-line',
				},
			]
			: []),
		...(user?.original_customer || user?.has_other_accounts
			? [
				{
					onClick: () => handleSwitch(),
					label: user?.original_customer ? 'Switch to My Account' : 'Switch Account',
					icon: 'arrow-left-right-line',
				},
			]
			: []),
		...(hasPermission('WG_LICENSE.GET.LIST')
			? [
				{
					url: '/client/licenses/',
					label: 'Licenses',
					icon: 'file-line',
				},
			]
			: []),
		...(hasPermission('WG_ASSESSMENT.GET.REQUESTS')
			? [
				{
					url: '/client/request-assessments',
					label: 'Request for Assessments',
					icon: 'user-shared-line',
				},
			]
			: []),
		...(hasPermission('USER.PERMISSION')
			? [
				{
					url: '/client/permissions',
					label: 'Permissions',
					icon: 'user-settings-line',
				},
			]
			: []),
		...(hasPermission('TTG_TOOLS')
			? [
				{
					label: 'Tablegroup Tools',
					tablegroup_url: 'select',
				},
			]
			: []),
		...(hasPermission('TTG_ORDERS')
			? [
				{
					label: 'Orders',
					tablegroup_url: 'client/account/orders',
				},
			]
			: []),
		{
			url: '/client/assessment/contact/',
			label: 'Contact Us',
			icon: 'phone-line',
		},
		{
			label: 'Logout',
			icon: 'logout-box-r-line',
			logout: true,
		},
	];

	const [isNotificationModalOpen, setIsNotificationModalOpen] = React.useState(false);
	const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = React.useState(false);
	const {
		screensSize: { isMobileView },
	} = useCheckout();
	const language = localStorage.getItem('wglang') || 'en';
	const { t } = useTranslation();
	const sidebarCartRef = useRef(null);

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [language]);

	if (!user || !user.id) {
		return null;
	}

	const onCheckoutClick = () => (window.location.href = '/checkout-view');
	const onViewCartClick = () => (window.location.href = '/shopping-cart-view');
	const onContinueShopping = () => (window.location.href = '/store');
	window.addItemToCart = (id, variationId) =>
		hasPermission('CHECKOUT') && sidebarCartRef.current.onAddPreviewItem(id, variationId);
	window.logoutClick = (userId) => hasPermission('CHECKOUT') && sidebarCartRef.current.resetCart(userId);
	const shouldOpenSidebar = () =>
		!(
			window.location.href.includes(RouteNames.cartDetails) ||
			window.location.href.includes(RouteNames.checkout) ||
			isMobileView
		);

	const getHeaderTitle = () => {
		return (
			<span className='text-white'>
				Welcome, <span id={'wg-top-bar-customer-name'}>{user.fullName}</span>. You have{' '}
				<span className='font-semibold' id='wg-top-bar-customer-licenses'>
					{user.licenses}
				</span>{' '}
				license{user.licenses !== 1 && 's'} in your account.&nbsp;
				{hasPermission('CHECKOUT') && (
					<>
						<a
							id='wg-top-bar-add-to-cart'
							className='cursor-pointer font-medium hover:underline'
							onClick={() => CustomerService.addToCart('add_license', true)}
						>
							{t('header.purchase_more_button')}
						</a>
						.
					</>
				)}
			</span>
		);
	};

	const getTopRightNavigation = () => {
		return (
			<>
				{hasPermission('NOTIFICATION.GET') && <Widget />}
				<Link to='/store' className='text-white hover:text-secondary text-lg font-normal justify-center px-4'>
					Store
				</Link>
				<div className='hidden md:flex'>
					<AccountDropdown items={userMenuItems} type='secondary' />
				</div>
				{hasPermission('CHECKOUT') && (
					<SidebarCartView
						onCheckoutClick={onCheckoutClick}
						onViewCartClick={onViewCartClick}
						onContinueShopping={onContinueShopping}
						onOpenClick={!shouldOpenSidebar() ? onViewCartClick : undefined}
						userId={user?.id ? user.id : undefined}
						ref={sidebarCartRef}
					/>
				)}
			</>
		);
	};

	const handleSwitch = () => {
		if (user?.original_customer) {
			switchToOriginalUser();
		} else return setIsSwitchAccountModalOpen(true);
	};
	return (
		<div id='wg-top-bar' className='flex relative bg-primary'>
			<div className='flex flex-1 flex-col md:flex-row container mx-auto'>
				<div className='flex flex-1 items-center'>
					<p className='py-3 text-center md:text-left'>{getHeaderTitle()}</p>
				</div>
				<div className='flex items-center justify-center'>{getTopRightNavigation()}</div>
			</div>
			<AppNotificationModal
				isOpen={isNotificationModalOpen}
				closeModal={() => setIsNotificationModalOpen(false)}
			/>
			<SwitchAccountModal isOpen={isSwitchAccountModalOpen} onClose={() => setIsSwitchAccountModalOpen(false)} />
		</div>
	);
};

export default AppTopBar;
