import ApiService from './ApiService';
import { serializeQueryString } from './UtilityService';

export const list = (filters) => {
	return new Promise((resolve, reject) => {
		const queryParams = serializeQueryString(filters);
		ApiService.get(`v1/orders?${queryParams}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const details = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/orders/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	list,
	details,
};
