import React from 'react';

import AppTable from '../AppTable';
import AppButton from '../AppButton';
import ActionFeedbackModal from '../assessments/ActionFeedbackModal';
import ChooseAssessmentModal from './ChooseAssessmentModal';
import ExcelService from '../../services/ExcelService';
import UtilityService from '../../services/UtilityService';
import AppTooltip from '../AppTooltip';
import WgIcon from '../WgIcon';

const SelectEmails = ({ onContinue, loading, completedAssessments, incompleteAssessments }) => {
	const columns = [
		{
			header: 'Name',
			key: 'name',
			className: 'flex-1',
			sortable: false,
			render: (item) => {
				return (
					<div className='flex flex-row items-center'>
						<WgIcon item={item} />
						<div className='ml-2 flex flex-col justify-center'>
							{item?.fname && (
								<p className='text-primary font-medium'>
									{item?.fname} {item?.lname}
								</p>
							)}
							{item.type === 'group' && item?.group && (
								<p className='text-primary font-normal'>{item?.group}</p>
							)}
							<p className='text-gray-500 ellipse-text w-auto pr-2 items-center'>{item.email}</p>
						</div>
					</div>
				);
			},
		},
		{
			header: 'Date Taken',
			key: 'taken',
			className: 'flex-1',
			sortable: false,
			render: (item) => {
				return (
					<p className='text-primary flex-1'>
						{item.completed ? UtilityService.formatDate(item.completed) : 'N/A'}
					</p>
				);
			},
		},
	];

	const [isShowingFeedback, setIsShowingFeedback] = React.useState(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState([]);
	const modalData = {
		loading: {
			title: 'Exporting...',
			message: "Please wait while we're processing your request...",
		},
		done: {
			title: 'Exported!',
			message: 'Emails exported successfully!',
		},
	};

	const selectData = (data, selected) => data.filter((item) => selected.indexOf(item.id) !== -1);

	const handleConfirm = (group, selectedAssessment) => {
		setIsLoadingAction(true);
		onContinue(group, selectedAssessment);
	};

	const renderButton = (text, onClick, selected) => (
		<AppButton
			text={text}
			onClick={() => {
				if (selected?.length > 0) onClick(selected);
			}}
			disabled={!selected?.length > 0}
		/>
	);

	const handleCompletedOnClick = (selected) => {
		setIsLoadingAction(false);
		setSelectedData(selectData(completedAssessments, selected));
	};

	const handleNotCompletedOnClick = (selected) => {
		setIsShowingFeedback(true);
		const excelData = selectData(incompleteAssessments, selected).map((el) => ({ Email: el.email }));
		ExcelService.exportToExcel(excelData, 'RequestToShareEmails', 'csv');
		setIsLoadingAction(false);
	};

	return (
		<div className={'flex flex-1 flex-col w-full items-center'}>
			<AppTable
				title='Completed Assessments'
				disableSelection={() => false}
				selectable={true}
				wrapperClassname='mx-auto w-full'
				columns={columns}
				data={completedAssessments}
				noDataMessage={'We’re sorry, there were no results associated with this email address.'}
				loading={loading}
				onSearchVisible={false}
				renderBulkActions={({ selected }) =>
					selected.length === 0 ? (
						<AppTooltip
							content={() => (
								<p className={'text-white text-center text-tiny font-normal w-48'}>
									{'Select one or more assessments to activate button'}
								</p>
							)}
							type={'small'}
						>
							{renderButton('Send Request', handleCompletedOnClick)}
						</AppTooltip>
					) : (
						renderButton('Send Request', handleCompletedOnClick, selected)
					)
				}
				preSelected={completedAssessments
					.filter((v, i) => completedAssessments.findIndex(({ email }) => email === v.email) === i)
					.map((c) => c.id)}
			/>

			{incompleteAssessments.length > 0 && (
				<AppTable
					title='Not Taken The Assessment'
					wrapperClassname='mx-auto w-full'
					disableSelection={() => false}
					selectable={true}
					columns={columns}
					data={incompleteAssessments}
					loading={loading}
					onSearchVisible={false}
					renderBulkActions={({ selected }) =>
						selected.length < 1 ? (
							<AppTooltip
								content={() => (
									<p className={'text-white text-center text-tiny font-normal w-48'}>
										{'Select one or more assessments to activate button'}
									</p>
								)}
								type={'small'}
							>
								{renderButton('Export Selected Emails to CSV', handleNotCompletedOnClick)}
							</AppTooltip>
						) : (
							renderButton('Export Selected Emails to CSV', handleNotCompletedOnClick, selected)
						)
					}
				/>
			)}

			<ActionFeedbackModal
				isOpen={isShowingFeedback}
				title={isLoadingAction ? modalData.loading.title : modalData.done.title}
				message={isLoadingAction ? modalData.loading.message : modalData.done.message}
				loading={isLoadingAction}
				closeModal={() => setIsShowingFeedback(false)}
			/>

			<ChooseAssessmentModal
				isOpen={selectedData.length > 0}
				onClose={() => setSelectedData([])}
				loading={isLoadingAction}
				onConfirm={handleConfirm}
				assessments={selectedData}
			/>
		</div>
	);
};

export default SelectEmails;
