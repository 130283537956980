import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { AssessmentModeEnum } from '../../../constants/AssessmentsData';
import AppButton from '../../AppButton';
import AppIcon from '../../AppIcon';
import AppRIIcon from '../../AppRIIcon';
import SelectStudentTypeModal from './SelectStudentTypeModal';
import { useUser } from '../../../hooks/UserProvider';
import { useAssessments } from '../../../hooks/AssessmentProvider';

const SelectType = ({ nextStep, setMode }) => {
	const { setAssessmentType } = useAssessments();
	const { user } = useUser();
	const [isOpenStudentTypeModal, setIsOpenStudentTypeModal] = useState(false);

	const onTypeSelected = async (type) => {
		if (type === 'group') {
			if (user.licenses > 1) {
				setAssessmentType(type);
				nextStep();
			}
			return;
		}

		setAssessmentType(type);
		nextStep();
	};

	return (
		<div className={'container mx-auto pt-8'}>
			<a
				className={'cursor-pointer text-secondary font-medium text-lg'}
				onClick={() => {
					navigate(-1);
				}}
			>
				&#60; back
			</a>
			<h1 className='font-medium text-4xl text-primary'>Send assessment to others</h1>
			<p className={'font-normal text-base text-gray-600 mt-3'}>
				Use the buttons to send standard {!!user.wg_student_enabled && 'or student'} assessment to group or
				individuals. After that you will continue to assessment setup.
			</p>
			{!!user.wg_student_enabled && (
				<p className={'font-normal text-base text-gray-600 mt-3'}>
					<span className={'font-semibold text-gray-700'}>Note:</span> You can not send a combination of
					standard and student assessments. Please choose the Student button if you intend to send the Student
					Assessment to an individual or group of students.
				</p>
			)}
			<div className='flex flex-col md:flex-row gap-6 mt-8'>
				<div className='assessment-type-tile'>
					<AppRIIcon icon='team-fill' className='text-4xl text-secondary leading-9' />
					<p className='description'>
						Use the group button to send an assessment to multiple people at one time.
					</p>
					<AppButton
						text={'Group'}
						onClick={() => {
							setMode(AssessmentModeEnum.STANDARD);
							onTypeSelected('group');
						}}
						disabled={user.licenses < 2}
						id={'wg-send-assessment-group-btn'}
						className={'btn btn-primary w-full mt-auto'}
						tooltipText={
							user.licenses < 2
								? 'You need a minimum of 2 licenses in your account to activate this option.'
								: undefined
						}
						tooltipWrapperClass={'w-full mt-auto'}
					/>
				</div>
				<div className='assessment-type-tile'>
					<AppRIIcon icon='user-fill' className='text-4xl text-secondary leading-9' />
					<p className='description'>
						Use the individual button to send an assessment to one person at a time.
					</p>
					<AppButton
						text={'Individual'}
						onClick={() => {
							setMode(AssessmentModeEnum.STANDARD);
							onTypeSelected('individual');
						}}
						id={'wg-send-assessment-individual-btn'}
						className={'btn btn-primary w-full mt-auto'}
					/>
				</div>
				{!!user.wg_student_enabled && (
					<div className='assessment-type-tile'>
						<AppIcon icon='student' className='text-4xl text-student' />
						<p className='description'>
							Use the student button to send a student assessment to one or multiple students at a time.
						</p>
						<AppButton
							text={'Student'}
							onClick={() => {
								setMode(AssessmentModeEnum.STUDENT);
								setIsOpenStudentTypeModal(true);
							}}
							id={'wg-send-assessment-student-btn'}
							className={'btn btn-primary w-full mt-auto'}
						/>
					</div>
				)}
			</div>
			<SelectStudentTypeModal
				isOpen={isOpenStudentTypeModal}
				closeModal={() => setIsOpenStudentTypeModal(false)}
				onTypeSelected={onTypeSelected}
				licenses={user.licenses}
			/>
		</div>
	);
};

export default SelectType;
