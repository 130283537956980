import React, { useEffect, useState } from 'react';
import RequestAssessment from '../../../services/RequestAssessment';
import NotificationService from '../../../services/NotificationService';
import AppRIIcon from '../../../components/AppRIIcon';
import UtilityService, { getQueryParams } from '../../../services/UtilityService';
import AppTable from '../../../components/AppTable';
import RequestAssessmentHistoryFilters from '../../../components/request-assessment/RequestAssessmentHistoryFilters';
import RequestHistoryModal from '../../../components/request-assessment/RequestHistoryModal';
import { useLocation } from '@reach/router';
import WgIcon from '../../../components/WgIcon';

const RequestAssessmentList = () => {
	const columns = [
		{
			header: 'Name',
			key: 'assessment_name',
			className: 'flex-1',
			render: (item) => {
				return (
					<div id={'wg-request-assessment-name-col'} className='flex flex-row'>
						<WgIcon item={item} />
						<div className='ml-2 flex flex-col justify-center'>
							<p className='text-primary'>{item?.assessment_name}</p>
						</div>
					</div>
				);
			},
		},
		{
			header: 'Request Date',
			key: 'created',
			className: 'md:w-3/12',
			render: (item) => {
				return <p className='text-primary flex-1'>{UtilityService.formatDate(item.created)}</p>;
			},
		},
		{
			header: 'Request Status',
			key: 'status',
			className: 'md:w-1/6',
			render: (item) => renderAssessmentStatus(item),
		},
		{
			header: 'Actions',
			key: 'actions',
			className: 'md:w-1/6',
			sortable: false,
			render: (item) => {
				return (
					<p
						className='flex-1 text-blue-700 cursor-pointer hover:underline'
						onClick={() => {
							getAssessmentDetails(item.id);
						}}
					>
						View Details
					</p>
				);
			},
		},
	];

	const [requestsHistory, setRequestsHistory] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingMore, setLoadingMore] = useState(false);
	const [assessmentDetails, setAssessmentDetails] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const location = useLocation();

	const initialFilters = {
		search: '',
		sort_key: 'created',
		sort_dir: 'desc',
		page: 0,
		limit: 25,
	};

	const [filters, setFilters] = React.useState({ ...initialFilters });
	const params = getQueryParams(decodeURI(location.search));

	useEffect(() => {
		if (params.id) getAssessmentDetails(params.id);
	}, [location.search]);

	const getHistoryData = (_filters = filters, loader = setLoading, merge = false) => {
		loader(true);
		RequestAssessment.getRequestHistory(_filters)
			.then((data) => {
				const _requests = merge
					? {
						meta: data.meta,
						data: [...requestsHistory.data, ...data.data],
					}
					: data;
				setRequestsHistory(_requests);
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			})
			.finally(() => loader(false));
	};

	const getAssessmentDetails = (id) => {
		RequestAssessment.getAssessmentDetails(id)
			.then((data) => {
				setAssessmentDetails(data);
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			});
	};

	useEffect(() => {
		getHistoryData();
	}, [filters.status, filters.search, refresh]);

	const handleModalClose = (canceled) => {
		setAssessmentDetails(null);
		if (canceled) setRefresh((c) => !c);
	};

	const onChangeFilter = (cb) => {
		setFilters((c) => ({ ...c, ...cb(), page: 0 }));
	};

	const onPageChange = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getHistoryData(newFilters, setLoadingMore, true);
	};

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir,
		};
		setFilters(newFilters);
		getHistoryData(newFilters);
	};

	const onReset = () => {
		setFilters(initialFilters);
		getHistoryData(initialFilters);
	};

	const noDataMessage = () => {
		if (!filters.search)
			return (
				<div className={'flex flex-col justify-center pt-4'}>
					<AppRIIcon icon='history-line' className='text-6xl text-gray-600 pb-4' />
					Your request history is empty.
				</div>
			);
	};

	const renderAssessmentStatus = (item) => {
		if (item.type === 'group') {
			const statuses = item.roster_statuses.split(',');
			const completed = item.roster_count - statuses.filter((v) => v === 'Pending').length;
			return (
				<div className='flex-1 font-semibold text-primary'>
					{completed} of {item.roster_count} responded
				</div>
			);
		}
		return <div className='flex-1 font-semibold text-primary'>{item.roster_statuses}</div>;
	};

	return (
		<div className={'flex-col'}>
			<AppTable
				title='Request History'
				wrapperClassname='mx-auto w-full'
				columns={columns}
				data={requestsHistory.data}
				noDataMessage={noDataMessage()}
				loading={loading}
				pagination={requestsHistory.meta}
				onSort={onSort}
				renderFilters={RequestAssessmentHistoryFilters}
				filters={filters}
				setFilters={onChangeFilter}
				onLoadMore={onPageChange}
				loadingMore={loadingMore}
				onSearch={() => {
					setFilters({ ...filters, page: 0 });
					getHistoryData();
				}}
				onReset={onReset}
			/>
			<RequestHistoryModal
				isOpen={!!assessmentDetails?.rosters}
				onCloseModal={handleModalClose}
				assessmentDetails={assessmentDetails}
				setAssessmentDetails={setAssessmentDetails}
			/>
		</div>
	);
};
export default RequestAssessmentList;
