import React from 'react';
import AppLabel from './AppLabel';
import renderErrorMessage from '../constants/ErrorMessages';

const AppTextarea = React.forwardRef(
	(
		{
			label,
			showErrorMessage = true,
			errors,
			containerClassName,
			defaultErrorMessage = '',
			successMessage = '',
			labelTooltip = '',
			...props
		},
		ref
	) => {
		const { name } = props;

		const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

		return (
			<div className={`mb-4 ${containerClassName ? containerClassName : ''}`}>
				<AppLabel label={label} hasError={errors && errors[name]} labelTooltip={labelTooltip} />
				<textarea
					ref={ref}
					{...props}
					className={`app-input textarea ${errors && errors[name] ? 'error' : ''} ${
						props.className ? props.className : ''
					}`}
					autoComplete='autocomplete_off_hack_rsp!b'
					onChange={(e) => {
						if (!props.disabled) {
							props.onChange(e);
						}
					}}
				/>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors && errors[name] && renderErrorMessage(errors[name].type, props, defaultErrorMessage)}
				</span>
				<span className={`app-input-success-message ${successMessage ? 'visible' : 'invisible'}`}>
					{successMessage}
				</span>
			</div>
		);
	}
);

export default AppTextarea;
