import React from 'react';
import AppButton from '../../AppButton';
import { team_application_notebook } from '../../../images';
import { addToCart } from '../../../services/CustomerService';

const LastBuy = () => {
	return (
		<div className={'bg-white'}>
			<div className={'application-guide-layout container md:items-center py-10'}>
				<div className={'flex-1 px-8 m-2'}>
					<img src={team_application_notebook} alt='guide-notebook' />
				</div>

				<div className={'flex-1 flex-col'}>
					<h2 className={'flex-1 text-md md:text-xl lg:text-2xl text-primary font-semibold ml-2'}>
						Perfect for:
					</h2>
					<div className={'flex-1 application-check-list ml-2'}>
						<span>Team leaders who want to increase productivity and morale</span>
						<span>Teams looking to decrease inefficiencies, burnout, and turnover</span>
						<span>Offsites, retreats, and team development sessions</span>
					</div>
					<div className={'flex-1 justify-center md:justify-start flex pt-2 ml-2'}>
						<AppButton text={'Buy Now'} onClick={() => addToCart('team_application_product_id', true)} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default LastBuy;
