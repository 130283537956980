import React, { useState, useEffect } from 'react';
import AppIcon from '../components/AppIcon';
import AppButton from '../components/AppButton';
import AppModal from '../components/AppModal';
import AssessmentsService from '../services/AssessmentsService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const DownloadReport = ({ isOpen, closeModal, ids }) => {
	const [downloaded, setDownloaded] = useState(false);

	useEffect(() => {
		if (isOpen) {
			setDownloaded(false);
			AssessmentsService.generateReport(ids, ids.name, ids.language ? ids.language : 'en').then(() => {
				setDownloaded(true);
			});
		}
	}, [isOpen]);

	return (
		<AppModal id={'app-download-report-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='max-w-screen-sm bg-white py-4 px-8' style={{ width: '400px' }}>
				<h1 className={'text-2xl text-center text-primary text-center font-semibold'}>
					{downloaded ? 'Downloaded!' : 'Downloading...'}
				</h1>
				<p className={'my-4 text-center'}>
					{downloaded
						? 'Report downloaded successfully!'
						: "Please wait while we're processing your request..."}
				</p>

				<AppButton
					className={'btn btn-primary w-full'}
					text={'Done'}
					onClick={() => closeModal()}
					loading={!downloaded}
					id={'wg-download-report-btn'}
				/>
			</div>
		</AppModal>
	);
};

export default DownloadReport;
