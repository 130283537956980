import React from 'react';

const WorkshopFacilitator = () => {
	return (
		<div className='workshop-facilitator-section'>
			<div className='container'>
				<h1 className='text-4xl font-thin my-4'>Better Teams, Better Results</h1>
				<p className='text-lg font-thin mb-8 lg:w-1/2 mx-auto'>
					<h2 className={'block font-bold pb-1'}>We can help.</h2>
					<h3>Our Working Genius Master Facilitators help you...</h3>
				</p>
				<div className={'flex flex-col items-center'}>
					<div className='workshop-list pl-5 md:pl-0'>
						<span>Maximize productivity</span>
						<span>Decrease burnout</span>
					</div>
					<div className='workshop-list'>
						<span>Better meetings</span>
						<span>Increased innovation</span>
						<span>More effective hiring</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WorkshopFacilitator;
