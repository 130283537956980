import React from 'react';
import AppButton from '../../AppButton';
import {wg_flag, wg_alumni_intensives_header, wg_grow_your_business} from '../../../images';

const AlumniIntensivesHero = ({ redirectTo }) => {
	return (
		<>
			<div className={'flex bg-primary justify-center items-center content-center py-12'}>
				<div className='container flex mx-auto flex-col items-center justify-center'>
					<div className='flex justify-center my-4'>
						<img className={'alumni-intensives-logo-header'}  src={wg_alumni_intensives_header} alt='Patrick Lencioni'/>
					</div>
					<div className='flex justify-center mt-12 alumni-intensives-flag'>
						<img src={wg_flag} alt='Patrick Lencioni'/>
					</div>
					<div className='flex justify-center my-4 alumni-intensives-grow-your-business'>
						<img src={wg_grow_your_business} alt='Grow your business'/>
					</div>
					<div className='flex flex-col mx-auto mt-4 w-full lg:w-3/5'>
						<p className='text-white text-lg text-center'>
							We're launching a brand new virtual intensive focused on growing your business.
							This half day session uses models and principles from Patrick Lencioni and the
							Table Group to maximize your coaching or consulting practice.
							Sessions are jam-packed with valuable insights and are led by Master Facilitators
							who will share their real life experience.
						</p>
					</div>
					<div className='flex justify-center my-8 w-auto h-1/4'>
						<AppButton
							className={'btn btn-primary w-auto self-center'}
							onClick={redirectTo}
							text={'View Available Dates'}
							id={'wg-alumni-intensives-register-now-btn'}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default AlumniIntensivesHero;
