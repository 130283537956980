import React from 'react';

import AppButton from '../AppButton';
import OutsideAlerter from '../OutsideAlerter';
import ActionFeedbackModal from './ActionFeedbackModal';
import RosterService from '../../services/RosterService';
import NotificationService from '../../services/NotificationService';
import AppRIIcon from '../AppRIIcon';
import AssessmentsService from '../../services/AssessmentsService';
import MoveAssessment from './MoveAssessment';
import TeammapService from '../../services/TeammapService';
import AppTooltip from '../AppTooltip';
import SelectReportLanguageModal from './SelectReportLanguageModal';
import { useUser } from '../../hooks/UserProvider';
import BulkShareAssessments from './BulkShareAssessments';

const defaultModalData = { loading: {}, done: {} };

const RosterActions = ({
	assessment = {},
	selected,
	assessment_id,
	onMoveComplete,
	assessment_name,
	data = [],
	identifier,
	hasIncompleteAssessments,
	onSingleAssessmentShare,
}) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isShowingFeedback, setIsShowingFeedback] = React.useState(false);
	const [isLoadingAction, setIsLoadingAction] = React.useState(false);
	const [isSendLoading, setIsSendLoading] = React.useState(false);
	const [modalData, setModalData] = React.useState(defaultModalData);
	const [moveAssessment, setMoveAssessment] = React.useState(false);
	const [shareAssessments, setShareAssessments] = React.useState(false);
	const [movingAllAssessmentNotice, setMovingAllAssessmentNotice] = React.useState(false);
	const [shouldSelectLanguage, setShouldSelectLanguage] = React.useState(false);
	const { user, hasPermission } = useUser();

	const selectedData = React.useMemo(() => {
		return data.filter((item) => selected.indexOf(item[identifier]) > -1);
	}, [data, selected]);

	const canSendEmail = React.useMemo(() => {
		return selectedData.every((item) => !item.shared_from || item.email === user.customers_email_address);
	}, [selectedData]);

	const hasDuplicates = React.useMemo(() => {
		return selectedData.filter((item) => item.original_roster_id).length > 0;
	}, [selectedData]);

	const downloadReports = (language) => {
		let promise;
		if (selected.length > 1) {
			promise = RosterService.generateReports({ roster_ids: selected, assessment_id, language });
		} else {
			const _item = data.find((item) => item[identifier] === selected[0]);
			promise = AssessmentsService.generateReport(
				{
					assessment_id,
					roster_id: selected[0],
				},
				`${_item.fname} ${_item.lname}`,
				language
			);
		}
		promise
			.then(() => {
				setIsLoadingAction(false);
			})
			.catch((e) => {
				NotificationService.error(e.message);
			});
	};

	const actions = [
		{
			id: 'roaster-send-assessment',
			icon: 'mail-send-line',
			label: 'Email Selected Reports',
			loading: isSendLoading,
			disabled: !hasPermission('WG_ROSTER.POST.REPORT') || !canSendEmail || hasDuplicates,
			action: () => {
				setIsSendLoading(true);
				AssessmentsService.sendReport({ roster_ids: selected, assessment_id })
					.then(() => {
						NotificationService.success(`Successfully sent the reports.`);
					})
					.catch((e) => {
						NotificationService.error(e.message);
					})
					.finally(() => {
						setIsSendLoading(false);
						setIsOpen(false);
					});
			},
		},
		{
			id: 'roaster-action-download-assessment',
			icon: 'file-download-line',
			label: 'Download Selected Reports',
			action: () => {
				if (assessment.multi_language) {
					setShouldSelectLanguage(true);
				} else {
					setModalData({
						loading: {
							title: 'Downloading...',
							message: "Please wait while we're processing your request...",
						},
						done: {
							title: 'Downloaded!',
							message: `${selected.length > 1 ? 'Reports' : 'Report'} downloaded successfully!`,
						},
					});
					setIsShowingFeedback(true);
					setIsLoadingAction(true);
					downloadReports('en');
				}
			},
			disabled: !hasPermission('WG_ROSTER.GET.REPORT'),
		},
		{
			id: 'roaster-move-assessment',
			icon: 'file-transfer-line',
			label: 'Move Selected Assessments',
			disabled: hasDuplicates,
			action: () => {
				setMoveAssessment(true);
				setMovingAllAssessmentNotice(
					assessment.complete &&
					hasIncompleteAssessments &&
					parseInt(assessment.num_scores) === selected.length
				);
			},
		},
		{
			id: 'roaster-export-excel',
			icon: 'file-excel-line',
			label: 'Export Selected Reports to Excel',
			modalData: {
				loading: {
					title: 'Exporting...',
					message: "Please wait while we're processing your request...",
				},
				done: {
					title: 'Exported!',
					message: 'Reports exported successfully!',
				},
			},
			action: () => {
				TeammapService.create({
					name: assessment_name,
					roster_ids: selected,
					xls: true,
				})
					.then(() => {
						setIsLoadingAction(false);
					})
					.catch((e) => {
						NotificationService.error(e.message);
					});
			},
		},
		{
			id: 'roster-share-assessments',
			icon: 'share-box-line',
			label: 'Share Selected Assessments',
			disabled: hasDuplicates,
			action: () => {
				if (selected.length === 1) onSingleAssessmentShare(selectedData[0]);
				else setShareAssessments(true);
			},
		},
	];

	let extraAssessmentData = {};
	if (selected && selected.length === 1) {
		extraAssessmentData = data.find((item) => item[identifier] === selected[0]);
		if (!extraAssessmentData) {
			extraAssessmentData = {};
		}
	}

	const renderButton = () => {
		return (
			<AppButton
				id={'roster-actions-dropdown-button'}
				text={'Bulk Actions'}
				icon='down-micro'
				onClick={() => setIsOpen(!isOpen)}
				disabled={selected.length === 0}
			/>
		);
	};

	return (
		<div className={'flex flex-1'}>
			<div className='dropdown-container'>
				<OutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
					{selected.length === 0 ? (
						<AppTooltip
							content={() => (
								<p className={'text-white text-center text-tiny font-normal w-48'}>
									{'Select 1 or more assessments to activate bulk actions'}
								</p>
							)}
							type={'small'}
						>
							{renderButton()}
						</AppTooltip>
					) : (
						renderButton()
					)}
					<div className={`action-container ${isOpen ? '' : 'hidden'}`}>
						{actions.map(({ id, icon, label, action, modalData: labels, disabled, loading }, index) => {
							return (
								<div
									id={id}
									loading={loading}
									onClick={() => {
										if (disabled) {
											return;
										}
										if (labels) {
											setModalData(labels);
											setIsShowingFeedback(true);
											setIsLoadingAction(true);
										}
										action();
									}}
									key={index}
									className={`flex flex-row items-center py-2 px-2 pr-4 ${disabled ? 'cursor-not-allowed bg-gray-200' : 'cursor-pointer'
										}`}
								>
									<AppRIIcon icon={icon} className={'pr-2 text-secondary text-2xl'} />
									<p>
										{loading ? (
											<AppRIIcon
												icon={'loader-4-line'}
												className={'action-icon animate-spin loading '}
											/>
										) : (
											label
										)}
									</p>
								</div>
							);
						})}
					</div>
				</OutsideAlerter>
			</div>
			<ActionFeedbackModal
				isOpen={isShowingFeedback}
				title={isLoadingAction ? modalData.loading.title : modalData.done.title}
				message={isLoadingAction ? modalData.loading.message : modalData.done.message}
				loading={isLoadingAction}
				closeModal={() => setIsShowingFeedback(false)}
			/>
			<BulkShareAssessments
				isOpen={shareAssessments}
				assessment={assessment}
				selectedItems={selectedData}
				onClose={() => setShareAssessments(false)}
			/>
			<MoveAssessment
				isOpen={moveAssessment}
				isMovingAll={movingAllAssessmentNotice}
				redirectOnDelete={true}
				totalSelected={selected.length}
				isGroupAssessment={assessment.type === 'group'}
				onClose={(res) => {
					if (res && res.reload) {
						onMoveComplete();
					}
					setMoveAssessment(false);
				}}
				item={{
					...extraAssessmentData,
					roster_ids: selected,
					id: assessment_id,
				}}
			/>
			<SelectReportLanguageModal
				isOpen={shouldSelectLanguage}
				onClose={() => setShouldSelectLanguage(false)}
				onConfirm={(language) => {
					setShouldSelectLanguage(false);
					setModalData({
						loading: {
							title: 'Downloading...',
							message: "Please wait while we're processing your request...",
						},
						done: {
							title: 'Downloaded!',
							message: `${selected.length > 1 ? 'Reports' : 'Report'} downloaded successfully!`,
						},
					});
					setIsShowingFeedback(true);
					setIsLoadingAction(true);
					downloadReports(language);
				}}
			/>
		</div>
	);
};

export default RosterActions;
