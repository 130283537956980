import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AppInput from '../AppInput';
import AppModal from '../AppModal';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import AppDropdown from '../AppDropdown';
import AppRIIcon from '../AppRIIcon';
import AppTextarea from '../AppTextarea';

import CustomerService from '../../services/CustomerService';
import WebinarService from '../../services/WebinarService';
import NotificationService from '../../services/NotificationService';

import { emailRegex } from '../../services/UtilityService';
import ReCaptcha from '../ReCaptcha';

const RegisterModal = ({ isOpen, onClose, event }) => {
	const customStyles = {
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			background: 'transparent',
			maxHeight: '95vh',
		},
	};

	const [loading, setLoading] = React.useState(false);
	const [step, setStep] = React.useState(2);
	const captchaRef = React.useRef('captcha');

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
		control,
	} = useForm();

	useEffect(() => {
		if (!isOpen) {
			setStep(1);
			reset();
		}
		clearErrors();
	}, [isOpen]);

	const trimInputValue = (name, e) => setValue(name, e.target.value.trim());

	const onSubmit = async (payload) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		const [first_name, last_name] = payload.name?.split(' ');
		const registerPayload = {
			first_name,
			last_name: last_name ?? '',
			email: payload.email,
			company: payload.company,
			type: event?.type,
			role: payload.role?.value || 'OTHER',
			custom_field1: payload.custom_field1,
			custom_field2: payload.custom_field2,
			custom_field3: payload.custom_field3,
			captcha: recaptchaValue,
		};

		WebinarService.register(registerPayload)
			.then(() => {
				setLoading(false);
				setStep(2);
			})
			.catch((error) => NotificationService.error(error.message))
			.finally(() => {
				setLoading(false);
			});
	};

	const renderFormContent = () => (
		<>
			<div className={'flex flex-col items-left'}>
				<p className={'text-base leading-6 font-medium text-left pb-4'}>
					({event?.start_date} at {event?.event_modal_time})
				</p>

				<span className={'modal-description'}>{event?.description}</span>

				<span className={'text-base leading-6 font-medium text-left py-4 '}>
					To register for this webinar, complete the form below
				</span>
			</div>

			<form className='w-full' autoComplete='autocomplete_off_hack_xfr4!k' onSubmit={handleSubmit(onSubmit)}>
				<div className={'flex flex-col sm:flex-row'}>
					<AppInput
						{...register('name', {
							required: true,
							maxLength: 32,
						})}
						containerClassName={'flex-1 pr-0 sm:pr-6'}
						ttgMaxLength={64}
						label={'Full Name'}
						errors={errors}
						onBlur={(e) => trimInputValue('name', e)}
					/>

					<AppInput
						{...register('email', {
							required: true,
							maxLength: 80,
							pattern: {
								value: emailRegex,
								message: 'should be a valid email',
							},
						})}
						containerClassName={'flex-1'}
						ttgMaxLength={96}
						label='Email address'
						errors={errors}
						onBlur={(e) => trimInputValue('email', e)}
					/>
				</div>

				<div className={'flex flex-col sm:flex-row'}>
					<AppInput
						{...register('company', {
							required: true,
							maxLength: 64,
						})}
						containerClassName={'flex-1 pr-0 sm:pr-6'}
						ttgMaxLength={64}
						label='Company Name'
						errors={errors}
						onBlur={(e) => trimInputValue('company', e)}
					/>
					<Controller
						control={control}
						name={'role'}
						rules={{ required: true }}
						containerClassName={''}
						render={({ field, formState }) => (
							<AppDropdown
								id={'wg-signup-role-select'}
								name={'wg-signup-role-select'}
								{...field}
								label={'Role'}
								isClearable
								options={CustomerService.CustomerBusinessRoles.filter(
									(option) => option.value !== 'STUDENT'
								)}
								errors={formState.errors}
								containerClassName={'mb-4 text-left flex-1'}
								shouldShowErrorMessage
								styles={{
									option: (provided, { isFocused }) => {
										return {
											...provided,
											backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
											color: 'text-gray-700',
										};
									},
								}}
							/>
						)}
					/>
				</div>

				<AppTextarea
					{...register('custom_field1', {
						required: true,
						maxLength: 1000,
					})}
					errors={errors}
					label={'What is the greatest challenge in your industry right now?'}
					ttgMaxLength={1000}
					onBlur={(e) => trimInputValue('custom_field1', e)}
				/>

				<AppTextarea
					{...register('custom_field2', {
						required: true,
						maxLength: 1000,
					})}
					errors={errors}
					label={'What is the greatest challenge you or your team is currently facing?'}
					ttgMaxLength={1000}
					onBlur={(e) => trimInputValue('custom_field2', e)}
				/>

				<AppTextarea
					{...register('custom_field3', {
						required: true,
						maxLength: 1000,
					})}
					errors={errors}
					label={'What professional organization or association do you lean on most?'}
					ttgMaxLength={1000}
					onBlur={(e) => trimInputValue('custom_field3', e)}
				/>

				<div className={'flex flex-row justify-end'}>
					<AppButton
						type='submit'
						className={'btn btn-primary modal-submit-button'}
						loading={loading}
						text={'Register'}
						id={'wg-webinar-register-btn'}
					/>
				</div>
			</form>
			<ReCaptcha ref={captchaRef} />
		</>
	);

	const renderConfirmationContent = () => (
		<div className={'flex flex-col items-center'}>
			<span className='text-lg leading-6 font-medium pb-2'>Registration successful!</span>

			<span className='flex flex-col items-center text-center text-sm leading-5 font-normal text-gray-600'>
				<span>Thank you for registering for the webinar!</span>

				<span className={'pt-2 px-0 sm:px-6'}>
					Please check your email for your link to access and a code for one free Working Genius Assessment!
				</span>
			</span>
		</div>
	);

	const renderModalHeader = () => {
		if (step === 1) {
			return (
				<div className={'w-full'}>
					<span className={'flex flex-row justify-between'}>
						<span className={'text-lg font-medium text-center pb-1'}>Webinar for {event?.title}</span>

						<AppIcon
							icon={'cancel'}
							className={'text-gray-700 text-2xl cursor-pointer modal-close-icon'}
							onClick={onClose}
						/>
					</span>
				</div>
			);
		}

		return (
			<div className={'w-full'}>
				<span className={'flex flex-col'}>
					<span className={'flex justify-end'}>
						<AppIcon
							icon={'cancel'}
							className={'text-gray-700 text-2xl cursor-pointer modal-close-icon'}
							onClick={onClose}
						/>
					</span>

					<span className={'flex items-center justify-center pb-5 w-full'}>
						<span className={'modal-header-icon'}>
							<AppRIIcon icon={'check-line'} className={'text-green-500 text-2xl'} />
						</span>
					</span>
				</span>
			</div>
		);
	};

	return (
		<AppModal isOpen={isOpen} closeTimeout={0} onRequestClose={onClose} style={customStyles}>
			<div className={`webinar-register-modal ${step === 2 ? 'modal-complete' : ''}`}>
				{renderModalHeader()}
				{step === 1 ? renderFormContent() : renderConfirmationContent()}
			</div>
		</AppModal>
	);
};

export default RegisterModal;
