import React from 'react';
import debounce from 'lodash.debounce';

import AppInput from '../AppInput';
import AppSelect from '../AppSelect';
import AppButton from '../AppButton';

const options = [
	{ label: 'Pending Licenses', value: 'Pending' },
	{ label: 'Transferred', value: 'Transferred' },
	{ label: 'Assigned', value: 'Assigned' },
	{ label: 'Received', value: 'Received' },
];

const LicenseFilters = ({ filters, setFilters }) => {
	const [search, setSearch] = React.useState(filters.search);
	const changeHandler = (value) => {
		setFilters('search', value);
	};

	const debouncedChangeHandler = React.useMemo(() => {
		return debounce(changeHandler, 300);
	}, []);

	const onInputChange = (event) => {
		setSearch(event.target.value);
		debouncedChangeHandler(event.target.value);
	};

	const resetFilters = () => {
		setSearch('');
		setFilters('search', '');
		setFilters('status', '');
	};

	return (
		<div className='license-filter-container'>
			<AppInput
				id='wg-license-table-search'
				containerClassName='license-search'
				value={search}
				placeholder='Search'
				onChange={onInputChange}
				showErrorMessage={false}
			/>
			<AppSelect
				id='wg-table-status-filter'
				containerClassName='no-margin'
				options={options}
				placeholder='All License Usage'
				value={filters.status}
				renderText={({ label }) => label}
				propValue='value'
				onSelect={(value) => setFilters('status', value)}
			/>

			{(search || filters.status) && (
				<AppButton
					onClick={resetFilters}
					text={'Clear Filters'}
					className={'btn btn-secondary clear-filters-btn'}
					id={'wg-reset-filters'}
					type={'button'}
				/>
			)}
		</div>
	);
};

export default LicenseFilters;
