import React from 'react';
import { pre_work_categories_thumbnail, pre_work_overview_thumbnail } from '../../../images';
import AppVideoModal from '../../AppVideoModal';

const VideoSection = () => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	return (
		<div className={'pre-work-container flex-col bg-white rounded-sm shadow-lg py-6 px-10 mb-10 '}>
			<div className={'flex flex-col text-8 md:w-full md:text-16 text-primary mx-5'}>
				<span>
					<b>STEP THREE:</b>
				</span>
				<span className={'pt-1'}>Watch these short videos from Patrick Lencioni,</span>
				<span className={'pt-1'}>creator of the Six Types of Working Genius.</span>
			</div>

			<div className={'flex-1 flex flex-col m-5'}>
				<div
					className={'pre-work-review-6-minutes cursor-pointer'}
					onClick={() => {
						setVimeoUrl('https://player.vimeo.com/video/575386322?h=27dd77ef5e&amp');
					}}
				>
					<span>
						<img src={pre_work_categories_thumbnail} alt='overview-thumbnail' />
					</span>
				</div>

				<div
					className={'pre-work-categories-thumbnail cursor-pointer'}
					onClick={() => {
						setVimeoUrl('https://player.vimeo.com/video/575386497?h=c6f263235d&amp');
					}}
				>
					<img src={pre_work_overview_thumbnail} alt='categories-thumbnail' />
				</div>
			</div>

			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default VideoSection;
