import React from 'react';
import { pre_work_results } from '../../../images';

const ReviewSection = () => {
	return (
		<div className={'pre-work-container flex-col bg-white rounded-sm shadow-lg py-6 pl-10 mb-10 '}>
			<div className={'flex flex-col text-8 md:w-full md:text-16 text-primary mx-5'}>
				<span>
					<b>STEP TWO:</b>
				</span>
				<span className={'pt-1'}> Review your results</span>
			</div>

			<div className={'flex-1 flex flex-col md:flex-row m-5'}>
				<div className={'pre-work-review-10-minutes mb-5'}>
					<span>
						<img src={pre_work_results} alt='assessment' />
					</span>
				</div>

				<div className={'flex flex-col justify-evenly w-full md:w-5/12 md:mx-10 md:my-9'}>
					<div className={'flex-col main-grey text-primary p-5'}>
						<div className={'font-semibold text-lg'}>Reviewing Your Results</div>

						<div className={'text-md'}>
							If you are unclear about your results, use this supplemental resource to clarify your type
						</div>

						<div className={'download-resources-list'}>
							<a
								href='https://files.tablegroup.com/wp-content/uploads/2022/09/29014215/Reviewing-Your-Results.pdf'
								target='_blank'
							>
								Reviewing Your Results
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewSection;
