import React from 'react';
import { report_hr_sm } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Discernment tend to think and say:',
		items: [
			'"Now, that\'s a great idea."',
			'"Tell me more about your proposal; I think you\'re on to something."',
			'"I feel like you need to think this through a little more."',
			'"I think I know how to make that idea work."',
		],
	},
	{
		title: 'What others think and say about people with the D genius:',
		items: [
			'"Ask her. She always seems to have the best advice."',
			'"He has uncanny intuition. I trust his gut."',
			'"She\'ll know what’s good or bad about your idea."',
			'"He has great judgment and taste when it comes to almost anything."',
		],
	},
];

const Discernment = () => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF DISCERNMENT</h2>

			<p>
				People with the Genius of Discernment have a knack for instinctive, intuitive judgment and evaluation of
				ideas and options. They think in a humanly integrative way, naturally discriminating between good and
				bad ideas, plans or advice. Though their genius is rightly attributed to "gut feel," it is not based on
				random guesses at all, but on pattern recognition and trend identification.
			</p>

			<p>
				This is perhaps the hardest of the genius types to identify, and because of the positive nature of the
				word "Discernment", it is the hardest one for some people to admit they do not have. In fact, some of
				those who have very little discernment struggle to understand why this is not one of their geniuses,
				even while the people around them are clear that it is not.
			</p>

			<p>
				The guiding principle of the D genius is the pursuit of truth or the best answer. People who have it are
				good at quickly evaluating an idea, proposal or suggestion - whether they came up with it or not - even
				without data. They have a natural and uncanny ability to assess whether something has the potential to
				work, and what adjustments might be necessary to make it successful. They do this by balancing
				practicality and common sense with emotional intelligence and intuition. Their talent lies at the heart
				of innovation, even if not at the beginning of it.
			</p>

			<img src={report_hr_sm} className='hr hr-sm' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Discernment;
