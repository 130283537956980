import React from 'react';
import renderErrorMessage from '../constants/ErrorMessages';
import AppLabel from './AppLabel';

const AppSelect = React.forwardRef(
	(
		{
			label,
			value,
			placeholder,
			options = [],
			propValue = 'id',
			renderText,
			onSelect,
			showErrorMessage = true,
			errors,
			containerClassName,
			...props
		},
		ref
	) => {
		const { name } = props;
		const shouldShowErrorMessage = showErrorMessage && errors && errors[name];

		const _onSelect = (e) => {
			if (props.disabled) {
				return;
			}
			if (onSelect) {
				onSelect(e.target.value);
			}
		};

		return (
			<div className={`mb-4 ${containerClassName ? containerClassName : ''}`}>
				<AppLabel label={label} hasError={errors && errors[name]} />
				<select
					className={`app-select ${errors && errors[name] ? 'error' : ''}`}
					value={value}
					onChange={_onSelect}
					ref={ref}
					{...props}
				>
					<option value={''}>{placeholder}</option>
					{options.map((option, i) => {
						return (
							<option key={i} value={option[propValue]}>
								{renderText(option)}
							</option>
						);
					})}
				</select>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors && errors[name] && renderErrorMessage(errors[name].type, props)}
				</span>
			</div>
		);
	}
);

export default AppSelect;
