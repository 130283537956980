import React, { useState } from 'react';
import i18n from 'i18next';
import { navigate } from 'gatsby';

import AppIcon from '../AppIcon';
import AppButton from '../AppButton';
import AppModal from '../AppModal';
import AppDropdown from '../AppDropdown';
import { create } from '../../services/AssessmentsService';
import AppRIIcon from '../AppRIIcon';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';
import { texts } from './setup/constants';
import { useUser } from '../../hooks/UserProvider';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const options = [
	{
		value: 'en',
		label: 'English (US)',
		icon: 'hello-en',
	},
	{
		value: 'es',
		label: 'Español',
		icon: 'hola-es',
	},
	{
		value: 'de',
		label: 'Deutsch',
		icon: 'halo-de',
	},
];

const assessmentOptions = [
	{
		value: AssessmentModeEnum.STANDARD,
		label: 'Working Genius Assessment',
		icon: <AppRIIcon icon={'user-fill'} className={`text-3xl text-secondary m-2`} />,
	},
	{
		value: AssessmentModeEnum.STUDENT,
		label: 'Working Genius for Students Assessment',
		description: 'This is best for students between the ages of 14-20.',
		icon: <AppIcon icon={'student'} className={`text-3xl text-student`} />,
	},
];

const ConfirmIndividualAssessmentModal = ({ isOpen, closeModal }) => {
	const [loading, setLoading] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState(options[0]);
	const [selectedAssessmentVersion, setSelectedAssessmentVersion] = useState(assessmentOptions[0]);
	const { user, setUser } = useUser();

	const _create = (language) => {
		setLoading(true);
		create({
			auto_create: true,
			language: language,
			multi_language: selectedAssessmentVersion.value === AssessmentModeEnum.STUDENT ? 0 : 1,
			assessment_mode: selectedAssessmentVersion.value,
			welcome: texts[selectedAssessmentVersion.value].welcome + user.fullName,
		})
			.then(async (assessment) => {
				setUser(u => ({...u, licenses: u.licenses - 1 }))
				closeModal();
				navigate(`/assessments/take?a=${assessment.assessment_id}&r=${assessment.roster_id}`);
			})
			.finally(() => setLoading(false));
	};

	const onCreate = () => {
		i18n.changeLanguage(selectedLanguage.value);
		_create(selectedLanguage.value);
	};

	React.useEffect(() => {
		if (selectedAssessmentVersion.value === AssessmentModeEnum.STUDENT) {
			setSelectedLanguage(options[0]);
		}
	}, [selectedAssessmentVersion]);

	return (
		<>
			<AppModal
				id={'app-confirm-ind-assessment-creation-modal-id'}
				isOpen={isOpen}
				style={customStyles}
				onRequestClose={closeModal}
			>
				<div className='bg-white py-4 px-8' style={{ maxWidth: '690px' }}>
					<h1 className={'flex flex-1 text-lg text-center text-primary font-medium'}>Create assessment</h1>
					{!!user.wg_student_enabled && (
						<p className={'text-sm text-gray-500 leading-5 font-normal'}>
							Choose what version of Working Genius assessment you want to create.
						</p>
					)}
					<div className='absolute right-0 top-0 mt-2 mr-2 md:mt-4 md:mr-4'>
						<AppIcon
							icon={'cancel'}
							className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
							onClick={closeModal}
						/>
					</div>
					{!!user.wg_student_enabled && (
						<div className={'flex flex-col justify-around pt-6'}>
							{assessmentOptions.map((option, optionKey) => {
								return (
									<div
										className={
											'flex flex-row items-center justify-between p-3 cursor-pointer hover:bg-blue-100'
										}
										key={option.value}
										onClick={() => setSelectedAssessmentVersion(option)}
									>
										<div className='flex flex-row items-center'>
											<input
												className={'cursor-pointer'}
												type='radio'
												value={option.value}
												name={optionKey}
												checked={option.value === selectedAssessmentVersion.value}
												onChange={() => setSelectedAssessmentVersion(option)}
											/>
											<p className='text-sm text-gray-800 leading-5 font-medium mx-2'>
												{option.label}{' '}
												<span className='block text-gray-500 font-normal'>
													{option.description}
												</span>
											</p>
										</div>
										{option.icon}
									</div>
								);
							})}
						</div>
					)}
					<div className='flex flex-1 flex-col pt-6'>
						<AppDropdown
							placeholder={'Please select the language you want to complete the assessment'}
							label={<p className={'text-sm text-gray-800 font-medium mb-2'}>Select language</p>}
							options={options}
							value={selectedLanguage}
							getOptionValue={(option) => option.value}
							getOptionLabel={(option) => (
								<div className={'flex flex-row items-center cursor-pointer'}>
									<AppIcon icon={option.icon} />
									<p>{option.label}</p>
								</div>
							)}
							styles={{
								option: (provided, { isFocused }) => {
									return {
										...provided,
										backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
									};
								},
							}}
							isSearchable={false}
							cacheOptions={false}
							hideSelectedOptions={true}
							onChange={(i) => {
								i18n.changeLanguage(i.value);
								setSelectedLanguage(i ? i : {});
							}}
							isDisabled={selectedAssessmentVersion.value === AssessmentModeEnum.STUDENT}
							tooltipText={
								selectedAssessmentVersion.value === AssessmentModeEnum.STUDENT &&
								'Working Genius for Students Assessment is only available in English'
							}
						/>

						<div className='flex flex-row mt-6'>
							<div className='shadow-sm py-4 px-6' style={{ background: '#F0F8FF' }}>
								<p className='text-sm leading-5 font-semibold text-gray-800'>Note</p>
								<p className='text-sm leading-5 font-normal text-gray-800 mt-2'>
									Clicking the ‘Create’ button below will create an individual assessment, assign you
									as the participant and use an assessment license.
								</p>
							</div>
						</div>
						<p className='text-sm leading-5 text-gray-800 mt-4'>
							Are you sure you wish to create this assessment?
						</p>
						<div className='flex justify-end mt-6'>
							<AppButton
								className={'btn btn-secondary mr-4'}
								onClick={closeModal}
								text={'Cancel'}
								disabled={loading}
								id={'wg-confirm-assessment-cancel-btn'}
							/>
							<AppButton
								onClick={onCreate}
								text={'Create'}
								loading={loading}
								id={'wg-confirm-assessment-create-btn'}
							/>
						</div>
					</div>
				</div>
			</AppModal>
		</>
	);
};

export default ConfirmIndividualAssessmentModal;
