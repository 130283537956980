import React from 'react';

import AppButton from '../AppButton';
import { addToCart } from '../../services/CustomerService';
import { widget_hr, live_webinar } from '../../images';
import CountdownPlayer from '../event/CountdownPlayer';

const EventHero = ({ streamHTML }) => {
	const eventDate = new Date('2023-01-25T19:00:00Z');
	return (
		<div className='fyf-event-hero-section'>
			<div className='container flex flex-col'>
				<div className='flex flex-col items-center justify-center text-center'>
					<h1 className='text-2xl lg:text-4xl text-white font-normal'>
						Welcome to The Working Genius Exclusive Webinar
					</h1>
					<h1 className='text-2xl lg:text-4xl text-white font-bold'>Teamwork Simplified</h1>
					<img className={'w-1/3 my-6'} src={widget_hr} alt='' />
					<p className='text-white text-lg font-semibold tracking-widest mb-8'>
						Premieres January 25th at 11AM PT / 2PM ET
					</p>
					<div dangerouslySetInnerHTML={{ __html: streamHTML }} className='w-full md:w-3/5' />

					{!streamHTML && <CountdownPlayer eventDate={eventDate} waitingTitle='Webinar will begin here in' />}

					<div className='flex flex-col justify-center'>
						<p className='text-white text-lg uppercase font-semibold my-4 tracking-widest'>What's next?</p>
						<div className='flex flex-col lg:flex-row items-center justify-center'>
							<AppButton
								className='btn btn-primary m-2'
								onClick={() => addToCart('add_license', true)}
								text='Take the 10-minute assessment today'
								id='wg-event-take-assessment-btn'
							/>
						</div>
					</div>
				</div>
				<div className='live-webinar-content'>
					<div className='live-webinar-icon'>
						<img src={live_webinar} alt='Live webinar' width={200} />
					</div>
					<div className='live-webinar-description'>
						<span className='text-base md:text-xl text-white font-normal'>
							Patrick Lencioni and The Working Genius Team will be explaining how his newest model, The
							Six Types of Working Genius, can fix frustration with teams, meetings, and productivity.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventHero;
