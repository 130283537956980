import React from 'react';
import AppInput from '../AppInput';
import AppSelect from '../AppSelect';

const options = [
	{
		value: 'individual',
		label: 'Individual Assessments',
	},
	{
		value: 'group',
		label: 'Group Assessments',
	},
	{
		value: 'shared',
		label: 'Shared Assessments',
	},
	{
		value: 'student',
		label: 'Student Assessments',
	},
];

const AssessmentsFilters = ({ filters, setFilters }) => {
	return (
		<div className={'flex flex-1 flex-col md:flex-row'}>
			<div className='flex flex-1 md:mr-4'>
				<AppSelect
					id={'wg-assessment-table-type-filter'}
					placeholder={'All Assessments'}
					containerClassName={'no-margin w-full md:mr-4'}
					options={options}
					value={filters.type}
					renderText={({ label }) => label}
					propValue={'value'}
					onSelect={(value) => setFilters('type', value)}
				/>
			</div>
			<div className='flex flex-1 md:mr-4 mt-4 md:mt-0'>
				<AppInput
					id={'wg-assessment-table-search'}
					containerClassName={'no-margin w-full md:mr-4'}
					value={filters.search}
					placeholder={'Search'}
					onChange={(e) => setFilters('search', e.target.value)}
					showErrorMessage={false}
				/>
			</div>
		</div>
	);
};
export default AssessmentsFilters;
