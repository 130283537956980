import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';

import AppTabTitle from '../AppTabTitle';
import HomepageHeader from '../homepage/HomepageHeader';
import HomeNavHamburger from '../homepage/HomeNavHamburger';
import HomeFooter from '../homepage/HomeFooter';
import PublicTopBar from '../homepage/PublicTopBar';
import config from '../../constants/config';
import { normalizePathName } from '../../services/UtilityService';

const WorkshopLayout = ({
	children,
	title = 'Book A Workshop | The 6 Types Of Working Genius',
	description,
	workshopItem = true,
	studentLogo = false,
	footerWorkshopItem = workshopItem,
}) => {
	const location = useLocation();

	return (
		<div className={`flex flex-col min-h-screen`}>
			<Helmet>
				<link rel='canonical' href={`${config.base_url}${normalizePathName(location.pathname)}`} />
			</Helmet>
			<AppTabTitle title={title} description={description}/>
			<PublicTopBar />
			<HomepageHeader workshopItem={workshopItem} studentLogo={studentLogo} />
			<HomeNavHamburger workshopItem={workshopItem} studentLogo={studentLogo} />
			<div className={`flex flex-1 flex-col`}>{children}</div>
			<HomeFooter workshopItem={footerWorkshopItem} />
		</div>
	);
};

export default WorkshopLayout;
