import React from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex } from '../../services/UtilityService';
import AppInput from '../AppInput';
import AssessmentAction from './AssessmentAction';
import AppButton from '../AppButton';

const ShareAssessmentWith = ({ onCancel, onShare, item }) => {
	const [emails, setEmails] = React.useState([{ email: '' }]);
	const [loading, setLoading] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = () => {
		setLoading(true);
		const result = emails.filter((email) => !email.hidden).map(({ email }) => email.trim().toLowerCase());
		onShare(0, result, item);
	};

	function handleChange(i, event) {
		let values = [...emails];
		values[i] = { email: event.target.value };
		setEmails(values);
	}

	function handleAdd() {
		setEmails([...emails, { email: '' }]);
	}

	function handleRemove(i) {
		let values = [...emails];
		values[i] = { email: '', hidden: true };
		setEmails(values);
	}

	return (
		<>
			<p className='share-assessment-heading mt-6'>Share Assessment With</p>
			<form onSubmit={handleSubmit(onSubmit)}>
				{emails.map((email, idx) => {
					return (
						!email.hidden && (
							<div key={idx} className='flex flex-row justify-between'>
								<AppInput
									{...register(`email_${idx}`, {
										required: true,
										pattern: {
											value: emailRegex,
											message: 'should be a valid email',
										},
										maxLength: 96,
									})}
									placeholder={'Please enter email address'}
									errors={errors}
									onChange={(e) => handleChange(idx, e)}
									containerClassName='w-4/5 mt-2 mb-2'
									value={email.email || ''}
								/>
								<AssessmentAction
									icon='delete-bin-line'
									onClick={() => handleRemove(idx)}
									disabled={emails.filter((email) => !email.hidden).length === 1}
								/>
							</div>
						)
					);
				})}

				<a className='share-assessment-add-btn cursor-pointer' onClick={() => handleAdd()}>
					+ Add email address
				</a>
				<hr className='slide-over-break' />

				<div className='flex justify-end my-4'>
					<AppButton
						type={'submit'}
						text={'Share assessment'}
						className={'btn btn-primary btn-share-assessment w-full'}
						id={'wg-share-assessment-with-share-btn'}
						disabled={loading}
					/>
					<AppButton
						type={'button'}
						className={'btn btn-secondary ml-2'}
						onClick={onCancel}
						text={'Cancel'}
						id={'wg-share-assessment-with-cancel-btn'}
					/>
				</div>
			</form>
		</>
	);
};

export default ShareAssessmentWith;
