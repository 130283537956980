import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const AssessmentErrorModal = ({ isOpen, error, closeModal }) => {
	return (
		<AppModal id={'app-add-licenses-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='max-w-screen-sm bg-white py-4 px-8'>
				<p className='text-2xl text-primary font-medium my-4'>Error</p>
				<p className='text-lg'>{error}</p>
				<div className='flex justify-end my-4'>
					<AppButton text={'Close'} onClick={closeModal} id={'wg-assessment-error-btn'} />
				</div>
			</div>
		</AppModal>
	);
};

export default AssessmentErrorModal;
