import React from 'react';
import AppButton from '../AppButton';

const Navigation = ({
    backText,
    backClass = 'btn btn-secondary',
    nextText,
    nextClass = 'btn btn-primary',
    nextDisabled = false,
    canGoBack = true,
    onBack,
    loading,
}) => {
    return (
        <div className='flex flex-1 flex-col justify-between md:flex-row pt-2'>
            {!!canGoBack && (
                <AppButton
                    type='button'
                    className={`${backClass} mt-2 md:mr-3 md:mt-0`}
                    text={backText}
                    onClick={onBack}
                    disabled={loading}
                />
            )}
            <AppButton
                type='submit'
                className={`${nextClass} mt-2 md:mt-0`}
                text={nextText}
                loading={loading}
                disabled={nextDisabled}
            />
        </div>
    );
};

export default Navigation;
