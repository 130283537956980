import React from 'react';
import { useLocation } from '@reach/router';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';

const PolicyPage = ({ children }) => {
	const location = useLocation();

	const meta = [
		{
			path: '/policy/privacy-policy',
			title: 'Privacy Policy | The 6 Types Of Working Genius',
			description: 'Read our Privacy Policy for our website.'
		},
		{
			path: '/policy/terms-of-service',
			title: 'Terms & Conditions | The 6 Types Of Working Genius',
			description: 'Read our Terms & Conditions for our website.'
		}
	];
	const metaIndex = meta.findIndex(m => location.pathname.startsWith(m.path));

	return (
		<WorkshopLayout title={meta[metaIndex].title} description={meta[metaIndex].description}>
			{children}
		</WorkshopLayout>
	);
};

export default PolicyPage;
