import React, {useEffect} from 'react';
import { useForm } from 'react-hook-form';

import ConfirmTransfer from './ConfirmTransferModal';
import AppButton from '../AppButton';
import AppInput from '../AppInput';
import AppSlideOver from '../AppSlideOver';
import AppWarningMessage from '../AppWarningMessage';
import LicenseService from '../../services/LicenseService';
import NotificationService from '../../services/NotificationService';
import { emailRegex } from '../../services/UtilityService';
import CustomerService from '../../services/CustomerService';
import { useUser } from '../../hooks/UserProvider';

const TransferLicensesModal = ({ isOpen, onCloseModal, totalLicenses }) => {
	const [licenses, setLicenses] = React.useState(0);
	const [email, setEmail] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [existingCustomer, setExistingCustomer] = React.useState(true);
	const [confirmTransferModal, setConfirmTransferModal] = React.useState(false);
	const { setUser } = useUser();
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if(!isOpen){
			setValue('email', '');
			setEmail('');
			setLicenses(0);
		}
	}, [isOpen])

	const onSubmit = () => {
		setLoading(true);

		CustomerService.verifyEmail(email)
			.then((exists) => {
				setExistingCustomer(exists);
				setConfirmTransferModal(true);
			})
			.catch((e) => NotificationService.error('Error', e.message))
			.finally(() => setLoading(false));
	};

	const _transfer = () => {
		const payload = {
			licenses,
			email: email.trim(),
		};
		setLoading(true);

		LicenseService.transfer(payload)
			.then(() => {
				setUser((u) => ({ ...u, licenses: u.licenses - licenses }));
				setExistingCustomer(false);
				setConfirmTransferModal(false);
				onCloseModal({
					licenses,
					email,
				});
			})
			.catch((e) => {
				setConfirmTransferModal(false);
				onCloseModal();
				NotificationService.error('Error', e.message);
			})
			.finally(() => setLoading(false));
	};

	const hasLicenses = totalLicenses > 0;

	return (
		<AppSlideOver
			id='app-add-licenses-modal-id'
			containerClassName={'slide-modal transfer-license-modal'}
			isOpen={isOpen}
			closeModal={() => onCloseModal()}
		>
			<form className='share-assessment-slide-over' onSubmit={handleSubmit(onSubmit)}>
				{!hasLicenses && (
					<AppWarningMessage
						showIcon={false}
						title='You have no licenses on your account'
						message='It looks like you have run out of licenses.'
					/>
				)}
				<div className='mb-4'>
					<p className='text-lg text-primary font-semibold leading-5 mb-2'>Transfer licenses</p>
					<p className='slide-over-text'>Transfer licenses to a user.</p>
				</div>
				<p className='text-primary text-16 font-medium'>My licenses</p>
				<hr className='text-gray-400 my-2' />
				<div className='flex flex-1 mb-4'>
					<p className='slide-over-text flex flex-1'>Total unused licenses</p>
					<p className='slide-over-text semibold'>{totalLicenses}</p>
				</div>

				<hr className='text-gray-400 my-2' />
				<p className='text-primary text-16 mt-8 font-medium'>Transfer licenses to</p>
				<hr className='text-gray-400 mt-2 mb-4' />
				<div className='flex flex-1 items-center'>
					<div className='w-full'>
						<AppInput
							{...register(`email`, {
								required: true,
								pattern: {
									value: emailRegex,
									message: 'should be a valid email',
								},
								maxLength: 96,
							})}
							label='Email Address'
							placeholder='Please enter email address'
							errors={errors}
							onChange={(event) => setEmail(event.target.value.trim())}
							containerClassName='w-full'
							value={email || ''}
						/>
					</div>
				</div>
				<hr className='text-gray-400 my-2' />

				<div className='flex flex-1 items-center'>
					<p className='slide-over-text flex flex-1 mr-4'>Number of licenses to transfer</p>
					<div className='w-20'>
						<AppInput
							min={1}
							max={totalLicenses}
							disabled={!hasLicenses}
							containerClassName='no-margin'
							type='number'
							value={licenses}
							onChange={(e) => setLicenses(Math.min(e.target.value, totalLicenses))}
						/>
					</div>
				</div>

				<div className='flex flex-1 mt-5'>
					<AppButton
						disabled={!hasLicenses || licenses <= 0}
						type='submit'
						loading={loading}
						text='Transfer'
						className='btn btn-primary flex flex-1 mr-2 w-1/2'
						id='wg-adjust-license-confirm-btn'
					/>
					<AppButton
						type='button'
						className='btn btn-secondary flex flex-1'
						onClick={() => onCloseModal()}
						loading={loading}
						text='Cancel'
						id='wg-adjust-license-cancel-btn'
					/>
				</div>
			</form>
			<ConfirmTransfer
				loading={loading}
				isOpen={confirmTransferModal}
				onClose={() => setConfirmTransferModal(false)}
				onConfirm={() => _transfer()}
				licenses={licenses}
				text={
					existingCustomer
						? `Are you sure you wish to transfer <b>${licenses}</b> license${
								licenses > 1 ? 's' : ''
						  } to <b>${email}</b>?`
						: `Looks like <b>${email}</b> does not have an account. Do you wish to proceed with the transfer and send an invitation to create an account?`
				}
				confirmText={existingCustomer ? 'Yes, Transfer' : 'Send Invitation and Transfer'}
			/>
		</AppSlideOver>
	);
};

export default TransferLicensesModal;
