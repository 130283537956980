import React from 'react';
import Switch from 'react-switch';

const AppSwitch = ({ onChange, value, label, className, ...props }) => {
	return (
		<div className={`flex items-center ${className || ''}`}>
			<Switch
				onChange={onChange}
				checked={value}
				checkedIcon={false}
				uncheckedIcon={false}
				onColor={'#628AC7'}
				offColor={'#E5E7EB'}
				{...props}
			/>
			{label && <span className={'ml-2 text-primary font-semibold'}>{label}</span>}
		</div>
	);
};

export default AppSwitch;
