import React, { useMemo } from 'react';
import { navigate } from '@reach/router';

import AppButton from '../AppButton';
import WebinarEvent from './WebinarEvent';
import RegisterModal from './RegisterModal';
import { webinars_stream_slide, widget_hr } from '../../images';

const WebinarHero = ({ streamHTML, shouldStream }) => {
	const events = [
		{
			title: 'Coaches and Consultants ',
			start_date: 'May 07, 2024',
			event_date: '2024-05-07T17:00:00Z',
			event_end_date: '2024-05-07T18:00:00Z',
			event_time: '10:00 - 11:00 AM Pacific Time',
			event_recording: 'https://vimeo.com/workinggenius/coacheswebinar2024',
			type: 'ExecutiveCoachesConsultants',
			event_modal_time: '10:00 - 11:00 a.m. Pacific Time',
			description:
				'A conversation about using Working Genius as coaches and consultants. Join Pat and the team as they talk about how to use his newest model to enhance and transform your coaching and consulting business. Pat will share real stories, practical advice, and take live questions.\nAll attendees will receive a free Working Genius assessment and a complimentary guide for using Working Genius with your clients.',
		},
		{
			title: 'Project Managers',
			start_date: 'May 14, 2024',
			event_date: '2024-05-14T17:00:00Z',
			event_end_date: '2024-05-14T18:00:00Z',
			event_time: '10:00 - 11:00 AM Pacific Time',
			event_recording: 'https://vimeo.com/workinggenius/pmwebinar2024',
			type: 'ProjectManager',
			event_modal_time: '10:00 - 11:00 a.m. Pacific Time',
			description:
				'A conversation about transforming project management using Working Genius. Join Pat and the team as they talk about how to quickly move projects from inception to production. You’ll learn how to effortlessly use the gifts of the people around you to maximize productivity. Pat will unpack the model, share practical examples, and take live questions.\nAll attendees will receive a free Working Genius assessment and a complimentary Working Genius discussion guide.',
		},
		{
			title: 'Staffing Companies and Hiring Managers',
			start_date: 'May 21, 2024',
			event_date: '2024-05-21T17:00:00Z',
			event_end_date: '2024-05-21T18:00:00Z',
			event_time: '10:00 - 11:00 AM Pacific Time',
			event_recording: 'https://vimeo.com/workinggenius/staffingwebinar2024',
			type: 'StaffingHiringManagers',
			event_modal_time: '10:00 - 11:00 a.m. Pacific Time',
			description:
				'A conversation about how to improve hiring and staffing using Working Genius. Join Pat and the team as they help you improve job placement and evaluate candidate fit. Pat will unpack the model, share practical examples, and take live questions.\nAll attendees will receive a free Working Genius assessment and a complimentary access to the Working Genius Hiring Guide.',
		},
		{
			title: 'Hospital Administrators',
			start_date: 'June 04, 2024',
			event_date: '2024-06-04T17:00:00Z',
			event_end_date: '2024-06-04T18:00:00Z',
			event_time: '10:00 - 11:00 AM Pacific Time',
			event_recording: 'https://vimeo.com/workinggenius/hospitalwebinar2024',
			type: 'HospitalAdministrators',
			event_modal_time: '10:00 - 11:00 a.m. Pacific Time',
			description:
				'Join Pat and the team as they talk about how to improve teamwork among administrators, physicians, nurses and staff. Pat will unpack the model, share practical examples, and take live questions.\nAll attendees will receive a free Working Genius assessment and a complimentary guide for discussing Working Genius with your team.',
		},
		{
			title: 'Educators',
			start_date: 'June 18, 2024',
			event_date: '2024-06-18T17:00:00Z',
			event_end_date: '2024-06-18T18:00:00Z',
			event_time: '10:00 - 11:00 AM Pacific Time',
			event_recording: 'https://vimeo.com/workinggenius/educatorswebinar2024',
			type: 'Educators',
			event_modal_time: '10:00 - 11:00 a.m. Pacific Time',
			description:
				'Join Pat and the team as they talk about how to create and improve teamwork in school systems. Pat will address the unique challenges of teamwork in schools among principals, staff, and teachers. Pat will unpack the model, share practical examples, and take live questions. \n' +
				'All attendees will receive a free Working Genius assessment and a complimentary guide for discussing Working Genius with your staff.',
		},
	];

	const [selectedEvent, setSelectedEvent] = React.useState(null);
	const [openModal, setOpenModal] = React.useState(false);

	const getCurrentEventDate = () => {
		const currentUTCDate = new Date().toUTCString();
		const currentEvent = events.find((event) => new Date(event.event_end_date) > new Date(currentUTCDate));

		return currentEvent?.event_date || currentUTCDate;
	};

	const getEvents = (isPastEvent = false) => {
		const eventList = events.filter((event) => {
			const eventDate = new Date(event?.event_date);
			const currentEventDate = new Date(getCurrentEventDate());

			return isPastEvent ? eventDate < currentEventDate : eventDate >= currentEventDate;
		});

		return eventList?.length > 0 ? eventList : null;
	};

	const onRegister = (event) => {
		setSelectedEvent(event);
		setOpenModal(true);
	};

	const renderEventsItems = useMemo(() => {
		const currentEvents = getEvents();

		return (
			currentEvents && (
				<>
					<span className={'text-white text-2xl md:text-4xl text-center uppercase pb-8'}>
						TUNE IN TO THE LIVE CONVERSATION
					</span>

					<div className={`webinar-event-item-container pb-16 ${currentEvents?.length === events?.length ? 'align-items-all' : 'align-items'}`}>
						{currentEvents.map((event, key) => (
							<React.Fragment key={key}>
								<WebinarEvent event={event} onRegister={() => onRegister(event)} />
							</React.Fragment>
						))}
					</div>
				</>
			)
		);
	}, [events]);

	const renderPastEvents = useMemo(() => {
		const pastEvents = getEvents(true);

		return (
			pastEvents && (
				<>
					<span className={'text-white text-2xl md:text-4xl text-center uppercase pb-8'}>PAST EVENTS</span>

					<div className={`webinar-event-item-container pb-16 ${pastEvents?.length === events?.length ? 'align-items-all' : 'align-items'}`}>
						{pastEvents.map((event, key) => (
							<React.Fragment key={key}>
								<WebinarEvent event={event} onRegister={() => onRegister(event)} isPastEvent />
							</React.Fragment>
						))}
					</div>
				</>
			)
		);
	}, [events]);

	const renderList = () => (
		<div className={'flex flex-col items-start md:items-center pb-8'}>
			<div className='webinar-list pt-8 pb-0 md:pb-4'>
				<h3>Consulting</h3>
				<h3>Project Management</h3>
			</div>
			<div className='webinar-list'>
				<h3>Staffing</h3>
				<h3>Hospital Administrators</h3>
				<h3>Educators</h3>
			</div>
		</div>
	);

	const renderContent = () => {
		if (shouldStream)
			return (
				<>
					<div dangerouslySetInnerHTML={{ __html: streamHTML }} className='w-full' />

					{!streamHTML && <img src={webinars_stream_slide} alt={'Webinar Series'} />}

					<AppButton
						id={'wg-webinar-register-button'}
						className={'btn btn-primary mx-auto mt-12'}
						text={'REGISTER FOR OTHER WEBINARS'}
						onClick={() => navigate('/webinars')}
					/>
				</>
			);

		return (
			<>
				{renderEventsItems}
				{renderPastEvents}
			</>
		);
	};

	return (
		<div className='webinar-hero' id={'wg-webinars'}>
			<div className={'container flex flex-col'}>
				<div className='flex flex-col items-center justify-center text-center py-8 container'>
					<h1 className='text-2xl lg:text-4xl text-white font-normal pb-2'>
						Working Genius Industry Webinars
					</h1>

					<h2 className={'text-xl lg:text-2xl text-white font-medium'}>with Patrick Lencioni</h2>

					{renderList()}

					<img className={'hero_image'} src={widget_hr} alt='' />

					<p className='hero_description'>
						For the first time, hear Pat apply everything we know about Working Genius in your industry.
					</p>
				</div>
				{renderContent()}
				<RegisterModal isOpen={openModal} event={selectedEvent} onClose={() => setOpenModal(false)} />
			</div>
		</div>
	);
};

export default WebinarHero;
