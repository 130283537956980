import React, { useState } from 'react';
import { careers_network, patrick_plain, spread_icon } from '../../images';

const CareersPat = () => {
	return (
		<div className='careers-pat'>
			<div className='container'>
				<div className='careers-pat-content'>
					<div className='careers-pat-cover flex flex-col items-center'>
						<img src={careers_network} alt='Networking' width='320' />
					</div>
					<div className='careers-pat-text text-center py-6 lg:w-1/3'>
						<h2 className='text-base md:text-lg text-primary font-normal'>
							Watch with friends, clients,
							<br />
							&amp; co-workers
						</h2>
						<img src={spread_icon} width='30' className='my-4 mx-auto' />
						<h2 className='text-base lg:text-lg text-primary font-semibold uppercase text-center leading-1 tracking-wide'>
							Exclusive Broadcast time <br />
							May 17th at <br />
							11 am pacific time /<br />2 pm eastern time
						</h2>
					</div>
				</div>
			</div>
			<div className='careers-pat-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
		</div>
	);
};

export default CareersPat;
