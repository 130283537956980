import React from 'react';
import AppRIIcon from './AppRIIcon';

const AppWarningMessage = ({ title, message, showIcon = true, actions = [], ...props }) => {
	return (
		<div className={`flex flex-row bg-yellow-100 rounded-lg p-4 mb-4 shadow-md`} {...props}>
			{showIcon && (
				<div className={'mr-4'}>
					<AppRIIcon icon={'error-warning-fill'} className={`text-yellow-800 text-xl`} />
				</div>
			)}
			<div>
				<p className={`text-yellow-800 text-16 font-medium`}>{title}</p>
				<p className={`text-yellow-800 text-14 mt-2`}>{message}</p>
				{actions && actions.length > 0 && (
					<div className={' flex flex-1 flex-row mt-4 '}>
						{actions.map((action, key) => {
							return (
								<p
									id={`ttg-app-warning-message-action-${key}`}
									className={'text-yellow-800 font-medium cursor-pointer mr-8'}
									key={key}
									onClick={action.action}
								>
									{action.text}
								</p>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};

export default AppWarningMessage;
