import React from 'react';
import { report_hr } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Galvanizing tend to think and say:',
		items: [
			'"Come on everyone; this is a huge opportunity."',
			'"Let\'s figure out how to get this going."',
			'"I need you to join my team."',
			'"Does everyone here understand why we need to do this?"',
		],
	},
	{
		title: 'What others think and say about people with the G genius:',
		items: [
			'"He\'s got so much energy and passion for what we\'re doing."',
			'"She can\'t hide her excitement."',
			'"Be careful. It\'s hard to resist his call to action."',
			'"You can always count on her to get us going."',
		],
	},
];

const Galvanizing = ({}) => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF GALVANIZING</h2>

			<p>
				People with the Genius of Galvanizing bring energy and movement to an idea, project or function. They
				naturally rally others to get involved through inspiration, enthusiasm and an initial call to action.
			</p>

			<p>
				Galvanizing often involves selling, evangelizing, recruiting and instigating. Once a person with the G
				genius is convinced that something is worth doing, they feel the need to get others on board and move
				things forward.
			</p>

			<p>
				This is one of the easier types of genius to identify, in ourselves and others, because it is so public
				and observable. As such, people who don&rsquo;t galvanize will readily admit it, and those who galvanize
				others have a hard time denying their gift because it is so apparent, and valuable.
			</p>

			<img src={report_hr} className='hr' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Galvanizing;
