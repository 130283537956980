import React from 'react';

import AppButton from '../../components/AppButton';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import CareersHero from '../../components/careersevent/CareersHero';
import CareersPat from '../../components/careersevent/CareersPat';
import RegisterForm from '../../components/careersevent/RegisterForm';
import LaunchEventTypes from '../../components/launchevent/LaunchEventTypes';

const Event = () => {
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

	return (
		<WorkshopLayout title={'The 6 Types of Working Genius: At the Whiteboard'}>
			<CareersHero />
			<div className='bg-secondary p-6'>
				<div className='flex flex-col lg:flex-row items-center justify-center text-center mx-auto'>
					<p className='text-white text-base md:text-2xl font-medium'>
						Register and get access to our new <span className='italic'>Working Genius Hiring Guide </span>
						<br />
						for free at the Exclusive Broadcast!
					</p>
					<AppButton
						id={'wg-at-the-table-register-now-btn'}
						className={'btn w-56 m-6'}
						onClick={() => setOpenRegisterModal(true)}
						text={'Register now'}
					/>
				</div>
			</div>
			<CareersPat />
			<LaunchEventTypes />
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</WorkshopLayout>
	);
};

export default Event;
