import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const BreakTheTiesModal = ({ isOpen, onClose, onConfirm, loading }) => {
	return (
		<AppModal id={'app-break-ties-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
			<div className={`bg-white p-6`} style={{ maxWidth: '600px' }}>
				<h1 className={'flex flex-1 justify-center text-primary text-lg leading-6 font-medium'}>Notice!</h1>
				<div className={'flex flex-row justify-around'}>
					<span className='text-base text-primary font-normal my-4'>
						To download the newest version of the report, you can edit your results to break the tie or
						email{' '}
						<a href='mailto:support@workinggenius.com' id={'wg-break-ties-support-link'}>
							support@workinggenius.com
						</a>{' '}
						to request a re-take.
					</span>
				</div>
				<div className='flex my-4 justify-end'>
					<AppButton
						onClick={onClose}
						text={'Cancel'}
						className={'btn btn-secondary mr-4'}
						id={'wg-break-ties-cancel-btn'}
						loading={loading}
					/>
					<AppButton
						onClick={onConfirm}
						text={'Download report'}
						className={'btn btn-primary'}
						id={'wg-break-ties-confirm-btn'}
						loading={loading}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default BreakTheTiesModal;
