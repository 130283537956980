import React from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';

import { SummaryReportPage } from '@ttg/ui-common-lib';

import config from '../../constants/config';
import { getQueryParams } from '../../services/UtilityService';

const SummaryReportPdf = () => {
	const location = useLocation();
	const handleError = () => {
		console.log('QueryParams', getQueryParams(location.search));
	};

	return (
		<>
			<Helmet>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta httpEquiv='X-UA-Compatible' content='ie=edge' />
				<link rel='stylesheet' href='/assessments/report/styles/pdf.css' />
				<title>The 6 Types of Working Genius Podcast Pdf</title>
			</Helmet>
			<SummaryReportPage
				apiEndpoint={config.ttg_url}
				appUrl={config.sm_api}
				threadId={getQueryParams(location.search).threadId}
				token={getQueryParams(location.search).token}
				onError={handleError}
			/>
		</>
	);
};

export default SummaryReportPdf;
