import React from 'react';
import AppTour from './AppTour';
import UtilityService, { getBreakpointValue, getCurrentBreakpoint } from '../../services/UtilityService';

const createTeamMap = {
	selector: '#wg-clientpage-create-teammap-btn',
	content: `You can now easily generate a Team Map by clicking this button.`,
};

const exportExcel = {
	selector: '#wg-clientpage-export-results-btn',
	content: `You can now export your Team Roster results into excel by clicking this button.`,
};

const moveRoster = {
	selector: '[data-wg="wg-move-roster-action"]',
	content: `You can now move an assessment into a Group, by entering the Group Name or selecting Create a New Group. Once you click “Move Assessment” it will be relocated in your console into that Group.`,
};

const editResults = {
	selector: '[data-wg="wg-assessment-edit-results-action"]',
	content: `You can now edit your Assessment Results`,
};

const shareRoster = {
	selector: '[data-wg="wg-share-roster-action"]',
	content: `Did you know that you could send your assessment results to other Working Genius users? Click “Share Assessment” under the Actions section to send your results. The results will remain in your portal, but this feature allows others to see your results and use them for the “Create Team Map” functionality.`,
};

const sendEmail = {
	selector: '[data-wg="wg-send-email-roster-action"]',
	content: `Did you know that you could send the report via email to the person that completed the assessment?`,
};

const downloadReport = {
	selector: '[data-wg="wg-download-roster-action"]',
	content: `You can also download the report and check out the results of the assessment.`,
};

const deleteRoster = {
	selector: '[data-wg="wg-delete-roster-action"]',
	content: `Did you know that you could delete started but not completed assessments from the group?`,
};

const selectTableRow = {
	selector: '[data-wg="wg-table-select-row"]',
	content: `You can now select the users you want and do bulk actions.`,
};

const selectTableAll = {
	selector: '[data-wg="wg-table-select-all"]',
	content: `You can use this checkbox to select them all.`,
};

const selectTableAllMobile = {
	selector: '[data-wg="wg-table-select-all-mobile"]',
	content: `You can use this checkbox to select them all.`,
};

const mobileSteps = [
	exportExcel,
	createTeamMap,
	selectTableAllMobile,
	selectTableRow,
	downloadReport,
	editResults,
	sendEmail,
	shareRoster,
	moveRoster,
	deleteRoster,
];

const webStep = [
	exportExcel,
	createTeamMap,
	selectTableAll,
	selectTableRow,
	downloadReport,
	editResults,
	sendEmail,
	shareRoster,
	moveRoster,
	deleteRoster,
];

const RosterTour = () => {
	const [steps, setSteps] = React.useState([]);

	React.useEffect(() => {
		let _steps = mobileSteps;
		const breakpoint = UtilityService.getCurrentBreakpoint();
		if (breakpoint !== 'sm') {
			_steps = webStep;
		}
		setSteps(_steps);
	}, []);

	return <AppTour steps={steps} cookie={'WGRosterTourCOOKIE'} />;
};

export default RosterTour;
