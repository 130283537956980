import React, { useRef } from 'react';
import { Link } from 'gatsby';
import { RouteNames, SidebarCartView, useCheckout } from "@ttg/ui-checkout-lib";

import logo from './../../images/logo.svg';
import AppIcon from './../AppIcon';
import AppButton from '../AppButton';
import AppModal from './../AppModal';
import CustomerService from '../../services/CustomerService';
import { AppLogout } from '../AppLogout';
import { wg_students_logo } from '../../images';
import { useUser } from "../../hooks/UserProvider";

const HomeNavHamburger = ({ workshopItem = true, studentLogo = false }) => {
	const { user, hasPermission } = useUser();
	const [openModal, setOpenModal] = React.useState(false);
	const [navScroll, setNavScroll] = React.useState(false);
	const onViewCartClick = () => (window.location.href = '/shopping-cart-view');
	const onCheckoutClick = () => (window.location.href = '/checkout-view');
	const onContinueShopping = () => (window.location.href = '/store');

	const sidebarCartRef = useRef(null);
	const { screensSize: { isMobileView } } = useCheckout();
	const shouldOpenSidebar = () => {
		if (window.location.href.includes(RouteNames.cartDetails) || window.location.href.includes(RouteNames.checkout) || isMobileView)
			return false;
		return true;
	};
	const listenScrollEvent = () => {
		window.scrollY > 100 ? setNavScroll(true) : setNavScroll(false);
	};

	React.useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);
		return () => {
			window.removeEventListener('scroll', listenScrollEvent);
		};
	}, []);

	return (
		<nav className={navScroll ? 'navbar home-navbar scroll' : 'navbar home-navbar'}>
			<div className='nav-header p-4'>
				{studentLogo ? (
					<Link to='/students'>
						<img src={wg_students_logo} alt='Working Genius Studentsx' />
					</Link>
				) : (
					<Link to='/'>
						<img src={logo} alt='Working Genius' />
					</Link>
				)}
				<button
					className='homenav-btn'
					onClick={() => setOpenModal(true)}
					id={'wg-homepage-homenav-hamburger-btn'}
				>
					<AppIcon icon='align-justify' className='burger-menu' />
				</button>
			</div>

			<AppModal isOpen={openModal} className='nav-modal' overlayClassName='overlay'>
				<div className={'modal-header'}>
					<button onClick={() => setOpenModal(false)} className='close-btn'>
						<AppIcon icon='cancel' className='modal-cancel' />
					</button>
				</div>

				<div className={'modal-nav-links'}>
					<Link to={'/about/'} className={'modal-nav-link'}>
						About
					</Link>

					<Link to={'/about/assessment'} className={'modal-nav-link'}>
						The Assessment
					</Link>

					<Link to={'/client/certification/'} className={'modal-nav-link'}>
						Get certified
					</Link>

					{!!workshopItem && (
						<Link to={'/workshop/'} className={'modal-nav-link'}>
							WG Workshop
						</Link>
					)}

					<Link to={'/resources/'} className={'modal-nav-link'}>
						Resources
					</Link>
					<Link to={'/wg-podcast-post'} className={'modal-nav-link'}>
						Podcast
					</Link>
					<Link to={'/store'} className={'modal-nav-link'}>
						Store
					</Link>
					{user && user.id ? (
						<Link id={'wg-homepage-menu-account-link'} to={'/client/'} className={'modal-nav-link'}>
							Account
						</Link>
					) : (
						<Link id={'wg-homepage-menu-login-link'} to={'/client/login/'} className={'modal-nav-link'}>
							Login
						</Link>
					)}

					{user && user.id && <AppLogout className='modal-nav-link' label='Logout' />}

					{hasPermission('CHECKOUT') && (isMobileView && (
						<SidebarCartView
							onCheckoutClick={onCheckoutClick}
							onViewCartClick={onViewCartClick}
							onContinueShopping={onContinueShopping}
							userId={user?.id || undefined}
							onOpenClick={!shouldOpenSidebar() ? onViewCartClick : undefined}
							ref={sidebarCartRef}
						/>
					))}

					<AppButton
						className={'btn btn-primary homenav-link-btn'}
						text={'Buy the Assessment Now'}
						icon={'right'}
						onClick={() => CustomerService.addToCart('add_license', true)}
						id={'wg-homepage-homenavhamburger-btn'}
					/>
				</div>
			</AppModal>
		</nav>
	);
};

export default HomeNavHamburger;
