import React, { useEffect } from 'react';
import {Controller, useForm, useFormState} from 'react-hook-form';

import { phoneNumberRegex } from '../../services/UtilityService';

import AppInput from '../AppInput';
import Navigation from './Navigation';
import AppDropdown from "../AppDropdown";
import CustomerService from "../../services/CustomerService";

const AccountDetailsStep = ({ nextStep, previousStep, registerData, setRegisterData }) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors,
        control,
        watch
    } = useForm();

    const { isSubmitted } = useFormState({ control });
    const companyValue = watch('company');

    useEffect(() => {
        if(!companyValue) {
            clearErrors('role');
        }
    }, [companyValue])

    const _next = (payload) => {
        setRegisterData({ ...registerData, ...payload });
        nextStep();
    };

    const trimInputValue = (name, e) => {
        setValue(name, e.target.value.trim(), {
            shouldValidate: isSubmitted,
            shouldDirty: true,
        });
    };

    return (
        <div className='flex-col'>
            <form onSubmit={handleSubmit(_next)} autoComplete='autocomplete_off_hack_xfr4!k'>
                <div>
                    <AppInput
                        {...register('first_name', {
                            required: true,
                            maxLength: 32,
                        })}
                        ttgMaxLength={32}
                        label={'First name'}
                        errors={errors}
                        onBlur={(e) => trimInputValue('first_name', e)}
                    />
                    <AppInput
                        {...register('last_name', {
                            required: true,
                            maxLength: 32,
                        })}
                        ttgMaxLength={32}
                        label={'Last name'}
                        errors={errors}
                        onBlur={(e) => trimInputValue('last_name', e)}
                    />
                    <AppInput
                        {...register('company', {
                            maxLength: 64,
                        })}
                        ttgMaxLength={64}
                        label={
                            <span>
                                Company Name <span className='text-gray-400 font-thin'>(Optional)</span>
                            </span>
                        }
                        errors={errors}
                        onBlur={(e) => trimInputValue('company', e)}
                    />
                    <Controller
                        control={control}
                        name={'role'}
                        rules={{ required: !!companyValue }}
                        containerClassName={'mb-4'}
                        render={({ field, formState }) => (
                            <AppDropdown
                                id={'wg-signup-role-select'}
                                name={'role'}
                                {...field}
                                isClearable
                                label={
                                    <span>
                                        Role
                                    </span>
                                }
                                options={CustomerService.CustomerBusinessRoles}
                                errors={formState.errors}
                                shouldShowErrorMessage
                                loading={false}
                                containerClassName={'mb-4'}
                                styles={{
                                    option: (provided, { isFocused }) => {
                                        return {
                                            ...provided,
                                            backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
                                            color: 'text-gray-700',
                                        };
                                    },
                                }}
                            />
                        )}
                    />
                    <AppInput
                        {...register('phone', {
                            maxLength: 32,
                            pattern: {
                                value: phoneNumberRegex,
                            },
                        })}
                        ttgMaxLength={32}
                        label={
                            <span>
                                Phone Number <span className='text-gray-400 font-thin'>(Optional)</span>
                            </span>
                        }
                        errors={errors}
                    />
                </div>
                <Navigation
                    canGoBack={true}
                    onBack={previousStep}
                    backText='Back'
                    backClass='btn btn-secondary sm:w-full md:w-1/2'
                    nextText='Next'
                    nextClass='btn btn-primary sm:w-full md:w-1/2'
                />
            </form>
        </div>
    );
};

export default AccountDetailsStep;
