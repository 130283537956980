import React from 'react';
import { student_group } from './../../images';

const StudentsChecklist = () => {
    return (
        <div className='container students-checklist'>
            <h2 className='title'>
                Helping <span className='font-bold'>Students</span> feel:
            </h2>
            <div className='flex flex-col lg:flex-row items-stretch justify-between'>
                <div className='flex-1 flex items-center' style={{ backgroundColor: '#EFEFEF' }}>
                    <ul className='students-checklist-ul'>
                        <li>
                            <span>Confident</span>
                            <b className='strikethrough overwhelmed'>Overwhelmed</b> at school
                        </li>
                        <li>
                            <span>Known</span>
                            <b className='strikethrough misunderstood'>Misunderstood</b> by friends and family
                        </li>
                        <li>
                            <span>At ease</span>
                            <b className='strikethrough anxious'>Anxious</b> about the future
                        </li>
                    </ul>
                </div>
                <img src={student_group} width='500' className='mx-auto my-4 lg:m-0' />
            </div>
        </div>
    )
}

export default StudentsChecklist