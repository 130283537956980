import React from 'react';

const GeniusCheckboxes = () => {
	return (
		<div className='checkboxes-container'>
			<h4>Select one of the options below:</h4>

			<div className='checkboxes'>
				<div className='checkbox'>
					<b>Working Genius:</b>
					<br />
					This area of genius comes quite naturally to me and gives me energy and joy. I would be extremely
					happy if my job required me to do this often.
				</div>

				<div className='checkbox'>
					<b>Working Competency:</b>
					<br />I can operate within this area of genius fairly well. But it would eventually drain me of my
					energy if I had to do it too often.
				</div>

				<div className='checkbox'>
					<b>Working Frustration:</b>
					<br />
					This area of genius frustrates and drains me, either because I'm not naturally good at it or because
					I just don't enjoy it. Or both.
				</div>
			</div>
		</div>
	);
};

export default GeniusCheckboxes;
