import React from 'react';

const Error = ({ message }) => {
	return (
		<div className={'mt-8 text-center'}>
			<h1 className={'text-4xl text-primary font-bold'}>Error</h1>
			<p className={'text-primary'}>{message}</p>
		</div>
	);
};

export default Error;
