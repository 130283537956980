import React from 'react';
import AppSwiper from '../AppSwiper';

const StudentsReviews = () => {
    const testimonials = [
        {
            quote: `"I can't believe it took me this long to use this tool that quickly helped me better understand my students, and become a better teacher."`,
            author: 'Christian Turner',
        },
        {
            quote: `"As a family, we kept missing each other. I had taken Working Genius and shared it with my student. Now we understand one another better, our conversations are more productive (and a whole lot less confusing)."`,
            author: 'Corrina Milner',
        },
        {
            quote: `"This is not just another assessment. This was the first time our lacrosse team really understood each other. As a coach, it's the first tool that helped me guide the guys about their next step."`,
            author: 'Gabe Johnson',
        },
    ];

    return (
        <div className='students-reviews-slider-section'>
            <h3 className='text-primary text-2xl font-semibold'>What People are Saying</h3>
            <AppSwiper testimonials={testimonials}
                renderTestimonial={(testimonal) =>
                    <>
                        <p className='review-quote'>{testimonal.quote}</p>
                        <p className='review-author'>{testimonal.author}</p>
                    </>
                }
                className={'students-swiper'}
            >
            </AppSwiper>
        </div>
    );
};

export default StudentsReviews;
