import React from 'react';
import { createPopper } from '@popperjs/core';
import { isMobile } from 'react-device-detect';

const AppTooltip = ({
	type = 'large',
	placement = 'bottom',
	children,
	content,
	wrapperClass = '',
	tooltipClass = '',
}) => {
	const btnRef = React.createRef();
	const tooltipRef = React.createRef();

	const openTooltip = () => {
		createPopper(btnRef.current, tooltipRef.current, {
			placement,
		});
	};

	return (
		<>
			<div className='flex flex-wrap cursor-pointer'>
				<div className='app-wg-tooltip w-full text-center' ref={btnRef} onMouseEnter={openTooltip}>
					<div className={'inline cursor-pointer ' + wrapperClass}>{children}</div>

					{
						!!content() && (
							<div
								className={
									`app-wg-tooltip-content max-w-xs text-left no-underline break-words rounded-lg shadow-lg z-50 ${
										type === 'large' ? 'p-4 ' : 'p-1 '
									}` +
									tooltipClass +
									`${isMobile ? ' mobile' : ''}`
								}
								style={{ backgroundColor: '#263860cc' }}
								ref={tooltipRef}
							>
								{content()}
							</div>
						)
					}
				</div>
			</div>
		</>
	);
};

export default AppTooltip;
