import React, { useState } from 'react';
import AppSlideOver from '../AppSlideOver';
import AppRIIcon from '../AppRIIcon';
import AppTable from '../AppTable';
import RequestAssessment from '../../services/RequestAssessment';
import NotificationService from '../../services/NotificationService';
import UtilityService from '../../services/UtilityService';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const RequestHistoryModal = ({ isOpen, onCloseModal, assessmentDetails, setAssessmentDetails }) => {
	const columns = [
		{
			header: 'Name',
			key: 'email',
			className: 'flex-1',
			sortable: false,
			render: (item) => (
				<div className='flex flex-row'>
					<AppRIIcon icon={'user-fill'} className='text-3xl text-secondary' />
					<div className='ml-2 flex flex-col justify-center'>
						<p className='text-primary'>{item?.email}</p>
					</div>
				</div>
			),
		},
		{
			header: 'Date Taken',
			key: 'date_taken',
			className: 'md:w-1/6',
			sortable: false,
			render: (item) => <p className='text-primary flex-1'>{UtilityService.formatDate(item.completed)}</p>,
		},
		{
			header: 'Request Status',
			key: 'status',
			className: 'md:w-1/6',
			sortable: false,
			render: (item) => <div className='flex-1 font-semibold text-primary'>{item.status}</div>,
		},
		{
			header: 'Actions',
			key: 'actions',
			className: 'flex-1',
			sortable: false,
			render: (item) => {
				if (item.status === 'Pending')
					return (
						<div className={'flex-1 flex flex-row items-center'}>
							<p
								className='text-blue-700 cursor-pointer hover:underline'
								onClick={() => {
									handleResend(item.request_id, item.id);
								}}
							>
								Send Reminder
							</p>
							<p
								className='text-red-700 cursor-pointer hover:underline ml-3'
								onClick={() => {
									setSelectedRosterToDelete(item);
								}}
							>
								Cancel
							</p>
						</div>
					);
			},
		},
	];
	const [deletedRequest, setDeletedRequest] = useState(false);
	const [selectedRosterToDelete, setSelectedRosterToDelete] = useState(null);

	const removeRequest = (id) => {
		const closeModal = assessmentDetails.rosters.length === 1;
		setAssessmentDetails((a) => ({ ...a, rosters: a.rosters.filter((r) => r.id !== id) }));
		setDeletedRequest(!closeModal);

		if (closeModal) {
			onCloseModal(closeModal);
		}
	};

	const handleResend = (id, roster_id) => {
		RequestAssessment.sendRequestReminder(id, roster_id)
			.then(() => {
				NotificationService.success('Success!', 'Assessment request reminder sent');
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			});
	};

	const handleCancel = () => {
		RequestAssessment.cancelRequestAssessment(selectedRosterToDelete.request_id, selectedRosterToDelete.id)
			.then(() => {
				removeRequest(selectedRosterToDelete.id);
				NotificationService.success('Success!', 'Your request was successfully canceled');
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			})
			.finally(() => {
				setSelectedRosterToDelete(null);
			});
	};

	return (
		<AppSlideOver
			id='app-add-licenses-modal-id'
			isOpen={isOpen}
			closeModal={() => {
				onCloseModal(deletedRequest);
				setDeletedRequest(false);
			}}
			containerClassName={'history-slide-modal'}
		>
			<div className={'flex flex-col w-full'}>
				<div className={'request-details'}>
					<span className={'text-2xl'}>{assessmentDetails?.assessment.assessment_name}</span>
					<span className={'text-md  text-gray-600 font-thin'}>
						{assessmentDetails?.assessment.created
							? UtilityService.formatDate(assessmentDetails?.assessment.created)
							: 'N/A'}
					</span>
				</div>
				<AppTable
					title=''
					wrapperClassname='mx-auto w-full px-8'
					columns={columns}
					data={assessmentDetails?.rosters}
				/>

				<AppModal
					isOpen={!!selectedRosterToDelete}
					onRequestClose={() => setSelectedRosterToDelete(null)}
					className={'cancel-request-modal'}
				>
					<div className='bg-white p-6' style={{ maxWidth: '512px' }}>
						<div className='flex'>
							<div className={'flex items-center justify-center w-10 h-10 rounded-full bg-red-100'}>
								<AppRIIcon icon={'alert-line'} className={'text-lg text-red-500'} />
							</div>
							<div className='flex flex-1 flex-col ml-4'>
								<h1 className={'ttg-text-lg text-gray-900 font-medium mb-2'}>
									Cancel Request Assessment
								</h1>
								<p className={'ttg-text-sm text-gray-500 mb-4'}>
									Are you sure you wish to cancel the request for{' '}
									<span className='font-medium'>{selectedRosterToDelete?.email}</span> assessment?
								</p>
								<div className={'flex justify-end'}>
									<AppButton
										text={'No'}
										className={'btn btn-secondary mr-4'}
										onClick={() => setSelectedRosterToDelete(null)}
									/>
									<AppButton text={'Yes, Cancel'} onClick={handleCancel} />
								</div>
							</div>
						</div>
					</div>
				</AppModal>
			</div>
		</AppSlideOver>
	);
};

export default RequestHistoryModal;
