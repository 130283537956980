import React from 'react';
import { CoverBreakLine, CoverLogoV2DE, TTGWhiteReportLogo } from '../../../../images';

const CoverPage = ({ assessment }) => {
    const preparedBy = () => {
        if (!assessment.participant.company_logo) {
            return null;
        }

        return (
            <div className='prepared-by'>
                <div className='ttg-container'>
                    <p>Prepared by:</p>
                    <img src={assessment.participant.company_logo} alt={''} />
                </div>
            </div>
        );
    };

    return (
        <div className='Cover'>
            <div className='top py-8 mx-auto'>
                <div className={'w-11/12 mx-auto'}>
                    <p className={'top-title'}>ASSESSMENT REPORT</p>
                </div>
            </div>

            <div className='mt-6'>
                <img src={CoverLogoV2DE} alt={''} className={'w-3/4 mx-auto px-5'} />
            </div>

            <div className='gears my-12'>
                <img src={CoverBreakLine} alt={''} />
            </div>

            <p className='tagline'>
                Das Modell Working Genius von {assessment.participant.fname + ' ' + assessment.participant.lname} erklärt
            </p>

            <div className='footer'>
                <div className='gear'>
                    <div className='dot-spanish'>
                        <p className='pt-4'>
                            ENTDECKEN SIE <br />
                            IHR TALENT BEI <br />
                            DER ARBEIT <br />
                        </p>
                    </div>
                </div>
            </div>

            {preparedBy()}

            <div className='powered-by'>
                <div className='ttg-container'>
                    <p>Powered by:</p>
                    <img src={TTGWhiteReportLogo} alt={''} />
                </div>
            </div>
        </div>
    );
};

export default CoverPage;
