import React from 'react';
import debounce from 'lodash.debounce';
import { navigate, useLocation } from '@reach/router';

import AboutLayout from '../../components/about/AboutLayout';
import Hero from '../../components/resources/Hero';
import AppScrollableNavigation from '../../components/ScrollableNavigation';
import AppScrollableHeader from '../../components/AppScrollableHeader';
import WGResourceItem from '../../components/resources/WGResourceItem';
import {
    resource_apple_podcasts,
    resource_request_workshop,
    resource_spotify_podcasts,
} from '../../images';
import PodcastResourceItem from '../../components/resources/PodcastResourceItem';
import ResourcesService from '../../services/ResourcesService';
import { resources_podcasts, resources_team } from '../../images';
import AppButton from '../../components/AppButton';

const ResourcesNavigation = () => {
    const location = useLocation();
    const [active, setActive] = React.useState(ResourcesService.TEAM_RESOURCES_ID);
    React.useEffect(() => {
        if (location.hash === '#podcasts') {
            navigateTo(ResourcesService.PODCAST_RESOURCES_ID);
        } else if (location.hash === '#additional-resources') {
            navigateTo(ResourcesService.ADDITIONAL_RESOURCES_ID);
        }
    }, [location.hash]);

    const listenScrollEvent = () => {
        const OFFSET_DISTANCE = 400;
        const PodDistance = document.getElementById(ResourcesService.PODCAST_RESOURCES_ID).getBoundingClientRect().top;
        const AdditionalDistance = document
            .getElementById(ResourcesService.ADDITIONAL_RESOURCES_ID)
            .getBoundingClientRect().top;

        if (AdditionalDistance < OFFSET_DISTANCE) {
            setActive(ResourcesService.ADDITIONAL_RESOURCES_ID);
        } else if (PodDistance < OFFSET_DISTANCE) {
            setActive(ResourcesService.PODCAST_RESOURCES_ID);
        } else {
            setActive(ResourcesService.TEAM_RESOURCES_ID);
        }
    };

    const listenScrollEventDebounced = React.useMemo(() => {
        return debounce(listenScrollEvent, 100);
    }, []);

    React.useEffect(() => {
        window.addEventListener('scroll', listenScrollEventDebounced);
        return () => {
            window.removeEventListener('scroll', listenScrollEventDebounced);
        };
    }, []);

    const navigateTo = (to) => {
        setActive(to);
        window.scrollTo({
            top: document.getElementById(to).getBoundingClientRect().top + window.scrollY - 15,
            behavior: 'smooth',
        });
    };

    const renderNavigationItem = (text, to) => (
        <p
            className={`item ${to === active ? 'active' : ''}`}
            onClick={() => {
                navigateTo(to);
            }}
        >
            {text}
        </p>
    );

    return (
        <div className={'resources-navigation'}>
            <p className={'title'}>Navigation:</p>
            {renderNavigationItem('Team Resources', ResourcesService.TEAM_RESOURCES_ID)}
            {renderNavigationItem('Featured Podcasts', ResourcesService.PODCAST_RESOURCES_ID)}
            {renderNavigationItem('Additional Resources', ResourcesService.ADDITIONAL_RESOURCES_ID)}
        </div>
    );
};

const Resources = () => {
    const RenderPodcastPill = (text, url) => (
        <div
            className={
                'border-1 border-primary hover:border-secondary text-primary hover:text-secondary rounded-md p-2 cursor-pointer'
            }
        >
            <a target={'_blank'} className={'font-semibold text-md uppercase p-2'} href={url}>
                {text}
            </a>
        </div>
    );

    return (
        <AboutLayout
            title={'Resources & Tools | The 6 Types Of Working Genius'}
            description={
                'We offer a variety of free resources and tools for your team to read and learn. Get your free resources and start learning today!'
            }
        >
            <div id={'wg-resources'} className='main-resources'>
                <Hero />
                <AppScrollableNavigation
                    containerClassName={'resources-navigation-container'}
                    wrapperClassName={'container pt-8'}
                    navigation={<ResourcesNavigation />}
                >
                    <AppScrollableHeader
                        id={ResourcesService.TEAM_RESOURCES_ID}
                        imgIcon={resources_team}
                        title={'Working Genius Team Resources'}
                    />
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 my-4 md:my-8'>
                        {ResourcesService.items.wg.map((item, key) => (
                            <WGResourceItem key={key} item={item} />
                        ))}
                        <div className={'shadow-md bg-secondary p-12 hover:shadow-xl'}>
                            <img src={resource_request_workshop} alt='' />
                            <h2 className={'text-white text-xl font-semibold my-4'}>Buy the Team Application Guide</h2>
                            <p className={'text-white'}>
                                The Team Application Guide was designed to help your team adopt Working Genius.
                            </p>
                            <div className={'flex-1 flex justify-start pt-2 mt-2'}>
                                <AppButton
                                    text={'Buy Now'}
                                    onClick={() => {
                                        navigate('/teamapplicationguide');
                                    }}
                                    className={'btn btn-dark w-40'}
                                />
                            </div>
                        </div>
                    </div>
                    <AppScrollableHeader
                        id={ResourcesService.PODCAST_RESOURCES_ID}
                        imgIcon={resources_podcasts}
                        link={'/wg-podcast-post'}
                        linkText={'VIEW ALL PODCASTS'}
                        title={'Working Genius Podcasts'}
                    >
                        <div
                            className={
                                'flex flex-row md:flex-col gap-3 mr-4 md:mr-0 ml-2 lg:mr-8 py-4 md:py-6 lg:py-8 pr-4 md:pr-1 lg:pr-6'
                            }
                        >
                            <a target={'_blank'} href={ResourcesService.APPLE_PODCAST_URL}>
                                <img className={'w-40'} alt={''} src={resource_apple_podcasts} />
                            </a>
                            <a target={'_blank'} href={ResourcesService.SPOTIFY_PODCAST_URL}>
                                <img className={'w-40'} alt={''} src={resource_spotify_podcasts} />
                            </a>
                        </div>
                    </AppScrollableHeader>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 my-4 md:my-8'>
                        {ResourcesService.items.podcasts.map((item, key) => (
                            <PodcastResourceItem key={key} item={item} />
                        ))}
                    </div>
                    <div className={'flex flex-row items-center justify-center mt-6 md:mt-12 mb-8 md:mb-16 gap-3'}>
                        {RenderPodcastPill('View All Podcasts', '/wg-podcast-post')}
                    </div>

                    {/*View All Additional Resources*/}
                    <AppScrollableHeader
                        className={'hidden md:flex'}
                        id={ResourcesService.ADDITIONAL_RESOURCES_ID}
                        imgIcon={
                            'https://files.tablegroup.com/wp-content/uploads/2022/10/21062126/5Zw3Uxif-WIDGET-site-2022-Resource-Experience-09.svg'
                        }
                        title={'Working Genius Additional Resources'}
                    />
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 my-4 md:my-8'>
                        {ResourcesService.items.additional_resources.map((item, key) => (
                            <WGResourceItem key={key} item={item} />
                        ))}
                    </div>
                </AppScrollableNavigation>
            </div>
        </AboutLayout>
    );
};

export default Resources;
