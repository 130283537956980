import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import ReportService from '../../../../services/ReportService';
import ReportWidgetSvg from '../../../../images/assessments/report/img/widget_gears.inline.svg';

const UniquePairingV2 = ({ assessment }) => {
	const pairing = React.useMemo(() => {
		const key = ReportService.getUniquePairing(assessment.customResults.genius1, assessment.customResults.genius2);
		return {
			key: key,
			reverse: key.split('').reverse().join(''),
			info: ReportService.UniqueParings[key],
		};
	}, []);

	return (
		<div className='UniquePairing'>
			<PageHeader title={'PAIRING DESCRIPTION'} />

			<div className='container en'>
				<div className='flex flex-row gap-4 py-4 mt-3'>
					<div className='flex flex-1 flex-col'>
						<PageTitle title={'Your Unique Pairing'} className={'mb-1 pairing-title'} />
						<div className={'pairing-container without-border'}>
							<span className={'information'}>
								<h2 className={'key text-lg'}>
									{pairing.key} | {pairing.reverse}
								</h2>
								<p className={'title bold'}>{pairing.info.title}</p>
							</span>
						</div>

						<p className={'text-12 pt-1 pr-6'}>
							While each of the genius types is important on its own, the combination or pairing of your
							two Working Geniuses can be just as insightful when it comes to identifying your unique
							talents and gifts. Below is a description of your pairing, which is the combination of{' '}
							<span className='uppercase font-bold' style={{ color: ReportService.genius_color }}>
								{assessment.customResults.genius1}
							</span>{' '}
							and{' '}
							<span className='uppercase font-bold' style={{ color: ReportService.genius_color }}>
								{assessment.customResults.genius2}
							</span>
							.
						</p>
					</div>

					<div className={`flex-1 genius ${assessment.customResults.genius1} ${assessment.customResults.genius2}` }>
						<ReportWidgetSvg />
					</div>
				</div>
				<p className={'text-12'}> {pairing.info.description}</p>

				<div className={'crave_container'}>
					<span className={'crave_items_container'}>
						<div className={'crave_item'}>
							<span className={'title'}>THEY CRAVE:</span>
							{pairing.info.crave.map((crave) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crave.title}</span>
									{crave.description}
								</p>
							))}
						</div>

						<div className={'crave_item'}>
							<span className={'title'}>THEY ARE CRUSHED BY:</span>
							{pairing.info.crush.map((crush) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crush.title}</span>
									{crush.description}
								</p>
							))}
						</div>
					</span>

					<p className={'italic font-bold text-center text-12 text-gray-800 pb-2'}>
						On the following page you’ll find brief descriptions of all 15 pairings.
					</p>
				</div>
			</div>
		</div>
	);
};

export default UniquePairingV2;
