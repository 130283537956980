import React from 'react';
import AppLabel from './AppLabel';
import renderErrorMessage from '../constants/ErrorMessages';

const AppInput = React.forwardRef(
	(
		{
			label,
			showErrorMessage = true,
			errors,
			containerClassName,
			defaultErrorMessage = '',
			successMessage = '',
			message = '',
			labelIcon,
			labelTooltip,
			...props
		},
		ref
	) => {
		const { name } = props;

		const shouldShowErrorMessage = !!(showErrorMessage && errors && errors[name]);

		const renderMessage = (text) => {
			if (typeof text === 'string' || typeof text === 'number') {
				return text;
			}
			return text();
		};

		return (
			<div className={`mb-4 ${containerClassName ? containerClassName : ''}`}>
				<AppLabel
					label={label}
					hasError={errors && errors[name]}
					labelIcon={labelIcon}
					labelTooltip={labelTooltip}
				/>
				<input
					ref={ref}
					{...props}
					className={`app-input ${errors && errors[name] ? 'error' : ''} ${
						props.className ? props.className : ''
					}`}
					autoComplete='autocomplete_off_hack_rsp!b'
					onChange={(e) => {
						if (!props.disabled) {
							props.onChange(e);
						}
					}}
				/>
				<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
					{errors &&
						errors[name] &&
						renderErrorMessage(errors[name].type, props, defaultErrorMessage, errors[name].message)}
				</span>
				<span className={`app-input-success-message ${successMessage ? 'visible' : 'invisible'}`}>
					{renderMessage(successMessage)}
				</span>
				<span className={`app-input-message ${message ? 'visible' : 'invisible'}`}>
					{renderMessage(message)}
				</span>
			</div>
		);
	}
);

export default AppInput;
