import React from 'react';
import { cover_assessment_report, cover_gears, cover_logo, cover_ttg } from '../../../../images';

const CoverPage = ({ assessment }) => {
	const preparedBy = () => {
		if (!assessment.participant.company_logo) {
			return null;
		}

		return (
			<div className='prepared-by'>
				<div className='ttg-container'>
					<p>Prepared by:</p>
					<img src={assessment.participant.company_logo} alt={''} />
				</div>
			</div>
		);
	};

	const renderInsights = () => {
		if (assessment.participant.fname === 'Anonymous' && assessment.participant.lname === 'User') {
			return (
				<p className='anonymous'>
					Insights for
					<br />
					Anonymous
					<br />
					User
				</p>
			);
		}
		return (
			<p>
				Insights for
				<br />
				{assessment.participant.fname} {assessment.participant.lname[0]}.
			</p>
		);
	};

	return (
		<div className='CoverPage__content'>
			<div className='top'>
				<img src={cover_assessment_report} alt={''} />
			</div>

			<div className='logo-container'>
				<img src={cover_logo} alt={''} />
			</div>

			<div className='gears'>
				<img src={cover_gears} alt={''} />
			</div>

			<p className='tagline'>Discover the Gifts You Bring to Work</p>

			<div className='footer'>
				<div className='gear'>
					<div className='dot'>{renderInsights()}</div>
				</div>
			</div>

			{preparedBy()}

			<div className='powered-by'>
				<div className='ttg-container'>
					<p>Powered by:</p>
					<img src={cover_ttg} alt={''} />
				</div>
			</div>
		</div>
	);
};

export default CoverPage;
