import React from 'react';
import { useLocation } from '@reach/router';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';

const AboutLayout = ({ children }) => {
	const location = useLocation();

	const meta = [
		{
			path: '/about',
			title: 'About Us | The 6 Types Of Working Genius',
			description: 'Learn about how Patrick Lencioni discovered and started the 6 Types of Working Genius model for individuals and teams.'
		},
		{
			path: '/about/assessment',
			title: 'Take The Assessment | The 6 Types Of Working Genius',
			description: 'Purchase and take the online assessment to learn what your geniuses are and how to best work with them. This will revolutionize your work!'
		}
	]
	const metaIndex = meta.findIndex(m => location.pathname.startsWith(m.path));

	return (
		<WorkshopLayout title={meta[metaIndex].title} description={meta[metaIndex].description}>
			{children}
		</WorkshopLayout>
	);
};

export default AboutLayout;
