import React from 'react';
import {
	using_admin_console_assessments,
	using_admin_console_manage_assessmennt,
	using_admin_console_request_assessment,
} from '../../images';

export const usingAdminData = [
	{
		content: (
			<>
				<h4 className='text-base text-primary font-bold'>Take or Send Assessments:</h4>
				<p className='text-base text-primary font-normal my-4'>
					You will create a Working Genius account during the purchase process. Your account allows you to
					take the assessment, send the assessment to others, and gives you access to free tools.
				</p>
			</>
		),
		image: using_admin_console_assessments,
		imgPosition: 'left',
	},
	{
		content: (
			<>
				<h4 className='text-base text-primary font-bold'>Manage your Console: </h4>
				<span className='text-base text-primary font-normal my-4'>
					The Working Genius Console has a variety of features including options to: <br />
					<ul className={'list-disc list-outside pl-5 pt-2'}>
						<li>Download a Report</li>
						<li>Edit Assessment Results</li>
						<li>Share Your Assessment</li>
						<li>Create a Group</li>
						<li>Generate a Team Map</li>
					</ul>
				</span>
			</>
		),
		image: using_admin_console_manage_assessmennt,
		imgPosition: 'right',
	},
	{
		content: (
			<>
				<h4 className='text-base text-primary font-bold'>Request Another User's Results:</h4>
				<p className='text-base text-primary font-normal my-4'>
					You have the option to request the Working Genius Assessment results of another user from within the
					console. This can be helpful if you want to add their results to a Team Map.
				</p>
			</>
		),
		image: using_admin_console_request_assessment,
		imgPosition: 'left',
	},
];
