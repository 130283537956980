import React from 'react';
import { event_free_resource, event_get_certified, event_podcast } from '../../images';
import AppButton from '../AppButton';
import { Link } from 'gatsby';
import UtilityService from '../../services/UtilityService';

const EventDeeper = () => {
	return (
		<div className='event-go-deeper-section'>
			<div className='container'>
				<h3 className='text-2xl md:text-4xl text-white text-center font-normal my-4'>Want to Go Deeper?</h3>
				<div className='deeper-items'>
					<div className='deeper-item'>
						<img src={event_free_resource} alt='Free Resource' />
						<h4 className='title'>
							Free Resource <br />
							Team Discussion Guide
						</h4>
						<AppButton
							id={'wg-event-download-now-btn'}
							className={'btn btn-primary'}
							text={'Download now'}
							onClick={() => {
								window
									.open(
										'https://files.tablegroup.com/wp-content/uploads/2022/09/27154741/Working-Genius-Book-Launch-Discussion-Guide36.pdf',
										'_blank'
									)
									.focus();
							}}
							disabled={!UtilityService.isEventLiveDatePassed()}
						/>
						{UtilityService.isEventLiveDatePassed() ? (
							``
						) : (
							<span className='text-base text-primary text-center font-medium'>(available 9/27)</span>
						)}
					</div>
					<div className='deeper-item'>
						<img src={event_podcast} alt='Podcast' />
						<h4 className='title'>
							Subscribe to <br />
							the Podcast
						</h4>
						<a
							href='https://podcasts.apple.com/ph/podcast/the-working-genius-podcast-with-patrick-lencioni/id1553105854'
							className={'btn btn-primary'}
							id={'wg-event-podcast-link'}
							target='_blank'
							rel='noreferrer'
						>
							View episodes
						</a>
					</div>
					<div className='deeper-item'>
						<img src={event_get_certified} alt='Get Certified' />
						<h4 className='title'>
							Get Certified in <br />
							Working Genius
						</h4>
						<Link
							to={'/client/certification/'}
							className={'btn btn-primary'}
							id={'wg-event-certification-btn'}
						>
							LEARN MORE
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventDeeper;
