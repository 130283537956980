import React from 'react';
import { navigate } from 'gatsby';

import { clipboard, gear_overlay, send_assessment } from '../../images';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import ConfirmIndividualAssessmentModal from './ConfirmIndividualAssessmentModal';
import { addToCart } from '../../services/CustomerService';
import { useUser } from '../../hooks/UserProvider';

const Hero = () => {
	const { user, hasPermission } = useUser();
	const [isConfirmingIndAssessment, setIsConfirmingIndAssessment] = React.useState();

	return (
		<div
			className={'text-center pt-12'}
			style={{
				backgroundImage: `url(${gear_overlay})`,
				backgroundRepeat: 'repeat-x',
				backgroundSize: 'contain',
				backgroundPosition: 'bottom',
				backgroundColor: '#f0f2f3',
			}}
		>
			<div className={'container mx-auto '}>
				<div className='flex flex-col md:flex-row'>
					<div className='flex flex-1 flex-col justify-center items-center content-center my-4'>
						<img className={'w-32 h-32 object-contain mb-8'} src={clipboard} alt='' />
						<AppButton
							onClick={() => setIsConfirmingIndAssessment(true)}
							className={'btn btn-primary w-11/12 md:w-3/4 h-18'}
							text={'Take assessment now'}
							icon={'right'}
							disabled={user.licenses <= 0 || !hasPermission('WG_ASSESSMENT.TAKE.POST')}
							id={'wg-clientpage-take-assessment-btn'}
						/>
					</div>
					<div className='flex flex-1 flex-col justify-center items-center content-center my-4'>
						<img className={'w-32 h-32 object-contain mb-8'} src={send_assessment} alt='' />
						<AppButton
							onClick={() => navigate(`/client/assessment/setup/`)}
							className={'btn btn-primary w-11/12 md:w-3/4 h-18'}
							text={'Send assessment to others'}
							icon={'right'}
							disabled={user.licenses <= 0}
							id={'wg-clientpage-send-assessment-btn'}
						/>
					</div>
				</div>
				<div className='mt-8 mb-12'>
					<p className={'text-primary text-xl font-light'}>
						{user.original_customer ? (user.fullName + ' has') : 'You have'}{' '}
						<span className='font-bold' id={'wg-assessment-page-customer-licenses'}>
							{user.licenses}
						</span>{' '}
						{user.licenses !== 1 ? 'licenses' : 'license'} in {user.original_customer ? 'their' : 'your'} account.
						{user.licenses <= 0 && (
							<a
								className={'cursor-pointer font-medium'}
								onClick={() => addToCart('add_license', true)}
								id={'wg-clientpage-purchase-more-link'}
							>
								{' '}
								Purchase more.
							</a>
						)}
					</p>
				</div>
			</div>
			{hasPermission('CHECKOUT') && <div
				className='flex flex-1 bg-primary justify-center items-center py-2 hover:bg-secondary cursor-pointer'
				onClick={() => navigate('/students')}
				id={'wg-clientpage-access-tools-btn'}
			>
				<AppIcon icon={'student'} className={'text-student text-2xl bg-white py-1 rounded-lg'} />
				<p className='text-white font-medium uppercase px-2 w-3/4 sm:w-auto text-14 sm:text-base'>
					PURCHASE WORKING GENIUS ASSESSMENT FOR STUDENTS
				</p>
				<AppIcon icon={'right'} className={'text-white'} />
			</div>}
			<ConfirmIndividualAssessmentModal
				isOpen={isConfirmingIndAssessment}
				closeModal={() => setIsConfirmingIndAssessment(false)}
			/>
		</div>
	);
};

export default Hero;
