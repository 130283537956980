import React from 'react';
import AppSwiper from '../../AppSwiper';

const CertificationReviews = () => {
	const testimonials = [
		{
			title: 'The language, insights, practice, and debriefing tools we received during the Working Genius Certification were game-changers for me.',
			from: 'ELLIOT B., INDEPENDENT CONSULTANT',
		},
		{
			title: "Armed with this new knowledge, as a team, we've begun seeking out ways to leverage our unique skills and talents to ensure we are doing more work that brings us joy and less work that frustrates us. The impact of this has been reimagining our roles on the team beyond our titles and letting the needs of projects and initiatives drive our performance.",
			from: 'CORRINE T., EDUCATION DIRECTOR',
		},
		{
			title: 'The WG Certification provided me with an excellent understanding of the model and the tools to implement it with confidence. This training is great for small business owners, managers, and coaches who are looking to understand how the people in their organization work and can work better together to increase productivity and job satisfaction.',
			from: 'MEGHAN G., BUSINESS OWNER',
		},
		{
			title: "The Working Genius model is pivotal in helping leaders and teams to develop core self-awareness and understanding of each other's gifts and frustrations.",
			from: 'SCOTT S., INDEPENDENT CONSULTANT',
		},
	];

	return (
		<div className='launch-event-reviews-slider-section'>
			<h2 className='text-primary text-2xl font-semibold'>What People are Saying</h2>
			<AppSwiper testimonials={testimonials}
				renderTestimonial={(testimonal) =>
					<>
						<p className='review-quote'>{testimonal.title}</p>
						<p className='review-author'>{testimonal.from}</p>
					</>
				}
				className={'launch-event-swiper'}
			>
			</AppSwiper>
		</div>
	);
};

export default CertificationReviews;
