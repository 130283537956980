import React from 'react';

const TeamApplicationExercise = () => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h2>TEAM APPLICATION EXERCISE</h2>

				<div className='callout'>
					<p>
						<b>
							There are two fundamental ways that a team can be transformed by using The Six Types of
							Working Genius.
						</b>
					</p>
					<p>
						<b>First,</b> individual team members can increase their productivity and morale by maximizing
						the time they spend in their areas of genius and minimizing the time they spend in their areas
						of frustration.
					</p>
					<p>
						<b>Second,</b> a team can significantly increase the likelihood of success by ensuring that they
						have filled all six of the geniuses required to get something done.
					</p>
					<p>
						Thankfully, these two approaches are best accomplished together. The question is,
						&ldquo;how?&rdquo; Here are four practical steps to make it happen.
					</p>
				</div>

				<h3>Four Simple Steps</h3>

				<p>
					<b className='step'>Step 1:</b> Have every team member complete the assessment and review their
					individual reports (30 minutes).
				</p>
				<p>
					<b className='step'>Step 2:</b> Get together and have every team member reveal his/her areas of
					genius, competency and frustration (15 minutes).
				</p>
				<p>
					<b className='step'>Step 3:</b> Map out the team&rsquo;s geniuses on a whiteboard or flipchart,
					review and discuss the collective results and their implications (60 minutes).
				</p>
				<p>
					<b className='step'>Step 4:</b> Identify any adjustments that can be made to fill areas of genius
					that are lacking, and perhaps to reorganize roles and responsibilities to allow people to better
					utilize their areas of genius (60 minutes).
				</p>
				<p>
					By using The Six Types of Working Genius, a team can identify, discuss and address serious
					individual and collective problems in less than three hours.
				</p>
			</div>
		</div>
	);
};

export default TeamApplicationExercise;
