import React from 'react';
import { Link } from 'gatsby';
import { Controller, useForm, useFormState } from 'react-hook-form';

import AppInput from '../AppInput';
import AppButton from '../AppButton';
import AppSwitch from '../AppSwitch';
import { emailRegex, phoneNumberRegex } from '../../services/UtilityService';
import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import AppDropdown from '../AppDropdown';
import { useUser } from '../../hooks/UserProvider';

const Details = () => {
	const { user, setUser } = useUser();
	const initialFormValues = React.useMemo(() => ({
		first_name: user.first_name,
		last_name: user.last_name,
		email: user.customers_email_address,
		company: user.company,
		phone: user.customers_telephone,
		wg_student_enabled: user.wg_student_enabled ? 1 : 0,
		multi_lingual: true,
		role: CustomerService.CustomerBusinessRoles.find((role) => role.value === user.role) ?? null,
	}), [user]);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
		clearErrors,
		watch,
		reset,
	} = useForm({
		defaultValues: initialFormValues,
	});
	const { isDirty } = useFormState({ control });
	const companyValue = watch('company');
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		if (!companyValue) {
			clearErrors('role');
		}
	}, [companyValue, clearErrors]);

	React.useEffect(() => {
		reset(initialFormValues);
	}, [reset, initialFormValues]);

	const save = (payload) => {
		setLoading(true);
		payload.role = payload?.role?.value || 'OTHER';
		CustomerService.update(payload)
			.then((result) => {
				setUser((u) => ({
					...u,
					first_name: result.first_name,
					last_name: result.last_name,
					customers_email_address: result.email,
					company: result.company,
					customers_telephone: result.phone,
					fullName: `${result.first_name} ${result.last_name}`,
					email_change_request: result.email_change_request,
					multi_lingual: result.multi_lingual,
					wg_student_enabled: result.wg_student_enabled ? 1 : 0,
					role: result.role,
				}));
				reset({
					first_name: result.first_name,
					last_name: result.last_name,
					email: result.email,
					company: result.company,
					phone: result.phone,
					wg_student_enabled: result.wg_student_enabled,
					multi_lingual: result.multi_lingual,
					role: CustomerService.CustomerBusinessRoles.find((role) => role.value === result.role) ?? null,
				});
				NotificationService.success('Changes Successfully saved!');
			})
			.catch((result) => NotificationService.error('Oops', result.message))
			.finally(() => setLoading(false));
	};

	return (
		<div>
			<form id={'wg-account-details-form'} className={'w-full'} onSubmit={handleSubmit(save)} >
				<AppInput
					{...register('first_name', {
						required: true,
						maxLength: 32,
					})}
					label={'First name *'}
					placeholder={'Please enter your first name'}
					errors={errors}
					ttgMaxLength={32}
					onBlur={(e) => {
						setValue('first_name', e.target.value.trim());
					}}
				/>
				<AppInput
					{...register('last_name', {
						required: true,
						maxLength: 32,
					})}
					label={'Last name *'}
					placeholder={'Please enter your last name'}
					errors={errors}
					ttgMaxLength={32}
					onBlur={(e) => {
						setValue('last_name', e.target.value.trim());
					}}
				/>
				<AppInput
					{...register('email', {
						required: true,
						pattern: {
							value: emailRegex,
						},
						maxLength: 96,
					})}
					label={'Email address *'}
					labelTooltip={
						'This is your current email address. You can edit it by typing a new email address in the input field below.'
					}
					labelIcon={'info-circled'}
					placeholder={'Please enter your email address'}
					errors={errors}
					ttgMaxLength={96}
					disabled={!!user.email_change_request}
					onBlur={(e) => {
						setValue('email', e.target.value.trim());
					}}
				/>

				<AppInput
					{...register('company', {
						maxLength: 64,
					})}
					label={'Company name'}
					placeholder={'Please enter a company name'}
					errors={errors}
					ttgMaxLength={64}
					onBlur={(e) => {
						setValue('company', e.target.value.trim());
					}}
				/>

				<Controller
					control={control}
					name={'role'}
					containerClassName={'mb-4'}
					rules={{ required: !!companyValue }}
					render={({ field, formState }) => (
						<AppDropdown
							id={'wg-update-role-select'}
							name={'wg-update-role-select'}
							{...field}
							label={'Role'}
							isClearable
							options={CustomerService.CustomerBusinessRoles}
							errors={formState.errors}
							loading={false}
							containerClassName={'mb-4'}
							shouldShowErrorMessage
							styles={{
								option: (provided, { isFocused }) => {
									return {
										...provided,
										backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
										color: 'text-gray-700',
									};
								},
							}}
						/>
					)}
				/>

				<AppInput
					{...register('phone', {
						maxLength: 32,
						pattern: {
							value: phoneNumberRegex,
						},
					})}
					label={'Phone number'}
					placeholder={'Please enter a phone number'}
					errors={errors}
					ttgMaxLength={32}
					onBlur={(e) => {
						setValue('phone', e.target.value.trim());
					}}
				/>
				<div className='flex flex-row my-4'>
					<AppSwitch
						errors={errors}
						value={!!watch('wg_student_enabled')}
						onChange={(val) => setValue('wg_student_enabled', val, { shouldDirty: true })}
					/>
					<div className='ml-2'>
						<p className={'text-primary font-semibold'}>Enable Working Genius for Students</p>
						<p className={'text-gray-800 font-thin'}>
							Working Genius Assessment for students between the ages of 14-20.{' '}
							<Link to={'/students'} className={'cursor-pointer text-secondary'}>
								Learn More
							</Link>
						</p>
					</div>
				</div>
				<AppButton
					type='submit'
					className={'btn btn-primary my-4'}
					text={'Save changes'}
					loading={loading}
					disabled={!isDirty}
					id={'wg-account-save-btn'}
				/>
			</form>
		</div>
	);
};

export default Details;
