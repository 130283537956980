import React from 'react';

import TeamMapHeader from './TeamMapHeader';
import TeamMapFooter from './TeamMapFooter';
import PersonRow from './PersonRow';

const AllMembersList = ({ label, members, endText }) => {
	return (
		<section role='region' className='Page'>
			<TeamMapHeader />
			<div className='Page__content'>
				<div className='Page__callout'>
					<h1>Team Map</h1>
					<h2 className='lightblack'>{label}</h2>
				</div>
				<table className='AllMembersList'>
					<thead className='AllMembersList__thead bold'>
						<tr>
							<th scope='col' />
							<th scope='col'>Working Genius</th>
							<th scope='col'>Working Competency</th>
							<th scope='col'>Working Frustration</th>
						</tr>
					</thead>
					<tbody>
						{members.map((member, idx) => (
							<PersonRow member={member} key={idx} />
						))}
						<tr>
							<td colSpan={4} className={'text-center'} style={{ paddingTop: '20px' }}>
								{endText}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<TeamMapFooter />
		</section>
	);
};

export default AllMembersList;
