import ApiService from './ApiService';
import UtilityService, { serializeQueryString } from './UtilityService';

export const list = (filters) => {
	return new Promise((resolve, reject) => {
		const queryParams = serializeQueryString(filters);
		ApiService.get(`v1/teammap/people?${queryParams}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const create = (payload) => {
	return new Promise((resolve, reject) => {
		const config = payload.xls ? { responseType: 'blob' } : {};
		ApiService.post('/v1/teammap/create', payload, config).then(({ data }) => {
			if (payload.xls) {
				const filename = `The Working Genius Assessments Export.xlsx`;
				const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				UtilityService.downloadFileFromBuffer(data, filename, type);
			} else {
				if (data.pdf_data) {
					const buffer = UtilityService.convertBase64ToBuffer(data.pdf_data);
					UtilityService.downloadPdfFromBuffer(buffer, `The Working Genius Team Map Report.pdf`);
				}
			}
			resolve();
		}, reject);
	});
};

const all = (filters) => {
	const queryParams = serializeQueryString(filters);
	return new Promise((resolve, reject) => {
		ApiService.get(`/v1/teammap/all?${queryParams}`).then(({ data }) => {
			const _resultsetIDs = [...data.results.assessments];
			const _resultsetInfo = { ...data.results.data };
			const _selectedMembers = {};
			const _selectedRosterIDs = [];

			_resultsetIDs.forEach((assmtID) => {
				if (_resultsetInfo[assmtID].type !== 'individual') {
					_resultsetInfo[assmtID]['showall'] = true;
					_resultsetInfo[assmtID]['allAdded'] = false;
				}
			});

			if (data.preselected.assessments.length > 0) {
				data.preselected.assessments.forEach((assmtID) => {
					let members = data.preselected.data[assmtID].results;

					_resultsetInfo[assmtID] = { ...data.preselected.data[assmtID] };
					_resultsetInfo[assmtID]['showall'] = true;
					_resultsetInfo[assmtID]['allAdded'] = true;

					members.forEach((member) => {
						let rosterID = member.id;
						_selectedMembers[rosterID] = { ...member };
						_selectedRosterIDs.push(rosterID);
						_selectedMembers[rosterID]['assessmentID'] = assmtID;
					});
				});
			}

			resolve({
				_resultsetIDs,
				_resultsetInfo,
				_selectedMembers,
				_selectedRosterIDs,
				_meta: data.results.meta,
			});
		}, reject);
	});
};

const areGroupMembersAdded = (members, selectedMembers) => {
	if (!members) {
		members = [];
	}
	const memberIds = members.map(({ id }) => id.toString());
	const selectedMemberIds = Object.keys(selectedMembers);
	const intersection = UtilityService.findIntersection(memberIds, selectedMemberIds);
	return intersection.length === memberIds.length;
};

export const getReportData = (params) => {
	return new Promise((resolve, reject) => {
		const query = serializeQueryString(params);
		ApiService.get(`/v1/rosters/team_map?${query}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	list,
	create,
	all,
	areGroupMembersAdded,
	getReportData,
};
