import React from 'react'
import ReportService from '../../../../services/ReportService';
import WidgetSVG from '../../../../images/report-widget-students.inline.svg';

const Geniuses = ({ results }) => {
    const pairing = React.useMemo(() => {
        let key = '';
        if (results)
            key = ReportService.getUniquePairing(results[0].label, results[1].label);
        return {
            key: key,
            reverse: key.split('').reverse().join(''),
            info: ReportService.UniqueParings[key],
        };
    }, []);

    return (
        <div className='student-assessment-complete-geniuses'>
            <div className='flex flex-col p-6'>
                <div className={'information mb-6 text-center sm:text-left'}>
                    <p className={'text-primary font-semibold text-base md:text-xl'}>
                        Your Unique Pairing:
                    </p>
                    <p className={'text-primary font-semibold text-3xl'}>
                        {pairing.key} | {pairing.reverse}
                    </p>
                    <p className={'title text-primary font-semibold text-base md:text-lg italic mb-4'}>{pairing.info.title}</p>
                    <p className={'brief text-primary font-normal text-base '}>{pairing.info.studentDescription}</p>
                </div>
                <div
                    className={`widget genius ${results[0].label} ${results[1].label}`}
                >
                    <div className='mx-auto w-full'>
                        <WidgetSVG />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Geniuses