import React from 'react';

import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import AppModal from '../AppModal';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmTransfer = ({ loading, isOpen, onClose, onConfirm, title = 'Transfer licenses', confirmText, text }) => {
	return (
		<AppModal id={'app-confirm-license-transfer'} isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
			<div className='bg-white py-4 px-8' style={{ maxWidth: '690px' }}>
				<h1 className={'flex flex-1 text-3xl text-center text-primary font-bold'}>{title}</h1>
				<div className='absolute right-0 top-0 mt-2 mr-2 md:mt-4 md:mr-4'>
					<AppIcon
						icon={'cancel'}
						className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
						onClick={onClose}
					/>
				</div>
				<div className='flex flex-1 flex-col'>
					<div className={'flex flex-1 flex-col'}>
						<p className='mt-4 text-primary' dangerouslySetInnerHTML={{ __html: text }} />
					</div>
					<div className='flex justify-end my-4'>
						<AppButton
							className={'btn btn-secondary mr-4'}
							onClick={onClose}
							text={'Cancel'}
							disabled={loading}
							id={'wg-confirm-assessment-cancel-btn'}
						/>
						<AppButton
							onClick={onConfirm}
							text={confirmText}
							loading={loading}
							id={'wg-confirm-assessment-create-btn'}
						/>
					</div>
				</div>
			</div>
		</AppModal>
	);
};

export default ConfirmTransfer;
