import React from 'react';
import { navigate } from 'gatsby';
import ResultsetAll from './ResultsetAll';
import SelectedMembers from './SelectedMembers';
import TeammapService from '../../services/TeammapService';
import AppButton from '../AppButton';
import GeneratedMessage from './GeneratedMessage';
import ConfirmMapCreationModal from './ConfirmMapCreationModal';
import AppErrorMessage from '../AppErrorMessage';
import NotificationService from "../../services/NotificationService";
import ActionFeedbackModal from '../assessments/ActionFeedbackModal';
import AppTooltip from '../AppTooltip';

const CreateTeamMap = ({ id }) => {
	const [loading, setLoading] = React.useState(true);
	const [loadingMore, setLoadingMore] = React.useState(false);
	const [resultsetIDs, setResultsetIDs] = React.useState([]);
	const [resultsetInfo, setResultsetInfo] = React.useState({});
	const [selectedMembers, setSelectedMembers] = React.useState({});
	const [selectedRosterIDs, setSelectedRosterIDs] = React.useState([]);
	const [showFeedbackModal, setShowFeedbackModal] = React.useState(false);
	const [isExporting, setIsExporting] = React.useState(false);
	const [meta, setMeta] = React.useState({});
	const [teamName, setTeamName] = React.useState('');
	const [download, setDownload] = React.useState(false);
	const [generatedMessage, setGeneratedMessage] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState('');
	const [shouldConfirmCreation, setShouldConfirmCreation] = React.useState({
		isOpen: false,
		message: '',
	});
	const language = localStorage.getItem('wglang') || 'en';

	const getTeamMapData = () => {
		TeammapService.all({
			preselect: id,
			preselectType: 'group',
		})
			.then(({ _resultsetIDs, _resultsetInfo, _selectedMembers, _selectedRosterIDs, _meta }) => {
				setResultsetIDs(_resultsetIDs);
				setResultsetInfo(_resultsetInfo);
				setSelectedMembers(_selectedMembers);
				setSelectedRosterIDs(_selectedRosterIDs);
				setMeta(_meta);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		getTeamMapData();
		if (language !== 'en') {
			NotificationService.info('Please note', 'The format used for the date is mm-dd-yyyy', {
				duration: 5000
			});
		}
	}, []);

	const loadMore = (search) => {
		setLoadingMore(true);
		TeammapService.all({
			search,
			page: meta.page + 1,
		})
			.then(({ _resultsetIDs, _resultsetInfo, _selectedMembers, _selectedRosterIDs, _meta }) => {
				setResultsetIDs([...resultsetIDs, ..._resultsetIDs]);
				setResultsetInfo({ ...resultsetInfo, ..._resultsetInfo });
				setSelectedMembers({ ...selectedMembers, ..._selectedMembers });
				setSelectedRosterIDs([...selectedRosterIDs, ..._selectedRosterIDs]);
				setMeta(_meta);
			})
			.finally(() => {
				setLoadingMore(false);
			});
	};

	const exportTeamMapResults = () => {
		setIsExporting(true);
		setShowFeedbackModal(true);
		TeammapService.create({
			name: teamName,
			roster_ids: selectedRosterIDs,
			xls: true,
		}).then(
			() => {
				setIsExporting(false);
			},
			(error) => {
				NotificationService.error(error.message);
				setIsExporting(false);
				setShowFeedbackModal(false);
			}
		);
	}
	const createTeamMap = (acknowledged = false, asExcel = false) => {
		setDownload(true);
		setGeneratedMessage(true);
		setErrorMessage('');
		TeammapService.create({
			name: teamName,
			roster_ids: selectedRosterIDs,
			acknowledged,
			// group: shouldConfirmCreation.group,
			xls: asExcel,
		})
			.then(() => {
				setShouldConfirmCreation({
					isOpen: false,
					message: '',
				});
			})
			.catch((error) => {
				if (error.code === 'TEAM_MAP_WARNING') {
					setGeneratedMessage(false);
					setShouldConfirmCreation({
						isOpen: true,
						message: error.message,
						name: error.name,
					});
				} else {
					setErrorMessage(error.message);
				}
			})
			.finally(() => {
				setDownload(false);
			});
	};

	const resetTeamMap = () => {
		setSelectedMembers({});
		setSelectedRosterIDs([]);
		setTeamName('');
		setDownload(false);
		setGeneratedMessage(false);
	};

	const addSelected = (member) => {
		if (!selectedMembers[member.id]) {
			const newSM = { ...selectedMembers };
			const i = resultsetInfo[member.assessment].results.findIndex((r) => r.id === member.id);
			newSM[member.id] = { ...resultsetInfo[member.assessment].results[i] };
			const newSRIDs = [...selectedRosterIDs];
			newSRIDs.push(member.id);
			setSelectedMembers({ ...newSM });
			setSelectedRosterIDs([...newSRIDs]);
		}
	};

	const addSelectedGroup = (group) => {
		const newSM = { ...selectedMembers };
		const newSRIDs = [...selectedRosterIDs];
		resultsetInfo[group].results.map((res) => {
			if (!newSM[res.id]) {
				const i = resultsetInfo[res.assessment].results.findIndex((r) => r.id === res.id);
				newSM[res.id] = { ...resultsetInfo[res.assessment].results[i] };
				newSRIDs.push(res.id);
			}
		});
		setSelectedMembers({ ...newSM });
		setSelectedRosterIDs([...newSRIDs]);
	};

	const removeSelected = (member) => {
		let newSRIDs = [...selectedRosterIDs];
		const i = newSRIDs.indexOf(member.id);
		if (i > -1) {
			newSRIDs.splice(i, 1);
			setSelectedRosterIDs([...newSRIDs]);
			const newSM = { ...selectedMembers };
			delete newSM[member.id];
			setSelectedMembers({ ...newSM });
		}
	};

	if (loading) {
		return <p id={'create-team-map-loading'} />;
	}

	const minMembersAdded = selectedRosterIDs && selectedRosterIDs.length > 1;
	const nameInserted = !!teamName;

	return (
		<div className='container mx-auto pt-8' style={{ maxWidth: '1140px', padding: '1rem' }}>
			<a
				className='text-secondary text-xl font-semibold underline my-9 hover:text-primary cursor-pointer'
				onClick={() => navigate(-1)}
				id={'wg-custom-teammap-back-link'}
			>
				{'< back'}
			</a>
			<h2 className='team-map-title'>Custom Team Map</h2>
			<p className='team-map-p font-semibold'>
				Select the team members to be included in the team map from the lists below. Clicking a name on the left
				adds a member. Clicking a name on the right removes a member. When your team is ready, click the
				"Generate Team Map" button below.
			</p>

			<div className='TeamMapBuilder__tool'>
				{!!generatedMessage && (
					<GeneratedMessage createTeamMap={() => createTeamMap()} resetTeamMap={resetTeamMap} />
				)}
				<div className='TeamMapBuilder__tool__leftcol'>
					<ResultsetAll
						resultIds={resultsetIDs}
						resultInfo={resultsetInfo}
						selectedMembers={selectedMembers}
						handleSelect={addSelected}
						handleGroupAdd={addSelectedGroup}
						loadMore={loadMore}
						loadingMore={loadingMore}
						hasNextPage={meta.page + 1 < meta.totalPages}
						setResultsetIDs={setResultsetIDs}
						setResultsetInfo={setResultsetInfo}
						setMeta={setMeta}
					/>
				</div>

				<div className='TeamMapBuilder__tool__rightcol'>
					<SelectedMembers
						memberCounter={selectedRosterIDs.length}
						handleRemove={removeSelected}
						selectedMembers={selectedMembers}
						members_ids={selectedRosterIDs}
						teamName={teamName}
						setTeamName={setTeamName}
					/>
				</div>
			</div>
			<div className={'flex justify-between mt-4'}>
				<a
					className={'cursor-pointer text-secondary text-base font-semibold underline'}
					onClick={() => navigate(-1)}
					id={'wg-custom-teammap-cancel-link'}
				>
					Cancel
				</a>
				<div className={'text-right'}>
					<div className={'flex flex-col md:flex-row justify-center items-end'}>
						<AppTooltip
							content={() => (
								<p className={'text-white text-center font-normal text-tiny'}>
									Export all the group results in an Excel file.
								</p>
							)}
							type={'small'}
						>
							<AppButton
								text={'EXPORT RESULTS'}
								onClick={exportTeamMapResults}
								disabled={!minMembersAdded || !nameInserted}
								className={'btn btn-primary mb-6 md:mb-0 md:mx-6'}
							/>
						</AppTooltip>

						<AppButton
							id={'wg-custom-teammap-generate-btn'}
							text={'GENERATE TEAM MAP'}
							onClick={() => createTeamMap()}
							loading={download}
							disabled={!minMembersAdded || !nameInserted}
						/>
					</div>

					{!minMembersAdded && (
						<div className='mt-4 text-left'>
							<AppErrorMessage message={'Please choose two or more members for a report'} />
						</div>
					)}
					{!nameInserted && (
						<div className='mt-4 text-left'>
							<AppErrorMessage message={'Please add a Team Name'} />
						</div>
					)}
					{!!errorMessage && (
						<div className='mt-4 text-left'>
							<AppErrorMessage message={'An error occurred while creating your team map.'} />
						</div>
					)}
				</div>
			</div>
			<ActionFeedbackModal
				isOpen={showFeedbackModal}
				title={isExporting ? 'Exporting...' : 'Exported!'}
				message={
					isExporting
						? "Please wait while we're processing your request..."
						: 'Reports exported successfully!'
				}
				loading={isExporting}
				closeModal={() => setShowFeedbackModal(false)}
			/>
			<ConfirmMapCreationModal
				isOpen={shouldConfirmCreation.isOpen}
				message={shouldConfirmCreation.message}
				closeModal={() =>
					setShouldConfirmCreation((s) => ({
						...s,
						name: null,
						isOpen: false,
					}))
				}
				onConfirm={(asExcel) => {
					setShouldConfirmCreation((s) => ({
						...s,
						isOpen: false,
					}));
					createTeamMap(true, asExcel);
				}}
			/>
		</div>
	);
};

export default CreateTeamMap;
