import React, { useCallback, useState } from 'react';
import CountryService from '../services/CountryService';
import NotificationService from '../services/NotificationService';

export const useZones = () => {
    const [zones, setZones] = useState([]);
    const [loadingZones, setLoadingZones] = useState(false);

    const getZones = useCallback((country_code) => {
        setLoadingZones(true);
        CountryService.getZones(country_code)
            .then((response) => setZones(response))
            .catch((error) => NotificationService.error(error.message))
            .finally(() => setLoadingZones(false));
    }, []);

    return {
        zones,
        getZones,
        loadingZones,
    };
};
