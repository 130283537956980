import React, { useEffect } from 'react';

import ClaimResourcesCard from './ClaimResourcesCard';
import { useLocation } from '@reach/router';
import { getQueryParams } from '../../services/UtilityService';
import { navigate } from 'gatsby';

const DCAResources = () => {
	const location = useLocation();
	const { type } = getQueryParams(location.search);

	useEffect(() => {
		if(!type){
			navigate('/dca-private-certification-registration', { replace: true });
		}
	}, [])

	return (
		<div className={'dca-resources'}>
			<ClaimResourcesCard />
		</div>
	)
}

export default DCAResources;