import React from 'react';
import { CoverLogoV2Student, TTGWhiteReportLogo, for_students_sticker } from '../../../../images';

const CoverPage = ({ assessment }) => {
    const preparedBy = () => {
        if (!assessment.participant.company_logo) {
            return null;
        }

        return (
            <div className='prepared-by'>
                <div className='ttg-container'>
                    <p>Prepared by:</p>
                    <img src={assessment.participant.company_logo} alt={''} />
                </div>
            </div>
        );
    };

    return (
        <div className='Cover'>
            <div className='top py-8 mx-auto'>
                <div className={'mx-auto'}>
                    <p className={'top-title student'}>Student Assessment Report</p>
                </div>
            </div>

            <div className='my-12 relative'>
                <img src={CoverLogoV2Student} alt={'The 6 Types'} className={'w-3/4 mx-auto px-5'} />
                <img src={for_students_sticker} alt={'For Students'} width='210' className={'absolute right-0 mr-12'} />
            </div>

            <p className='tagline'>
                {assessment.participant.fname} {assessment.participant.lname}
            </p>

            <div className='footer student'>
                <div className='gear student'>
                    <div className='dot-student'>
                        <p>
                            DISCOVER <br />
                            YOUR GIFTS
                        </p>
                    </div>
                </div>
            </div>

            {preparedBy()}

            <div className='powered-by'>
                <div className='ttg-container'>
                    <p>Powered by:</p>
                    <img src={TTGWhiteReportLogo} alt={''} />
                </div>
            </div>
        </div>
    );
};

export default CoverPage;
