import React from 'react';
import WGTeamNotificationsItem from './WGTeamNotificationsItem';

const WGTeamNotificationsList = ({ notifications, onStatusChange }) => {
	return (
		<div id={'wg-team-notification-list'}>
			{notifications.map((notification, index) => {
				return (
					<WGTeamNotificationsItem
						notification={notification}
						onStatusChange={() => onStatusChange(notification)}
						key={index}
					/>
				);
			})}
		</div>
	);
};

export default WGTeamNotificationsList;
