import React from 'react';
import PageHeader from './PageHeader';

const Descriptions = ({ assessment }) => {
    const descriptions = [
        {
            title: 'WONDER',
            text: 'The Genius of Wonder is all about asking questions and thinking about why things are the way they are. People with this genius get energy and joy from thoughtfully observing the world around them and contemplating whether there’s a different or better way to do things. Almost every idea or project starts with Wonder because it provokes people to think about things they might not have considered before.',
        },
        {
            title: 'INVENTION',
            text: 'The Genius of Invention is all about being creative and coming up with new ideas to solve problems or deal with issues. Inventors are often the first to propose something new because they have a knack for thinking outside the box. They get energy and joy when coming up with ideas from scratch, especially when there’s no obvious solution.',
        },
        {
            title: 'DISCERNMENT',
            text: 'The Genius of Discernment is all about having good judgment and relying on gut feelings and instincts. Discerners have a gift for recognizing patterns and get energy and joy from evaluating whether an idea is solid or if it needs some tweaking. They can be trusted to pick out the best ideas while avoiding the ones that aren’t so great.',
        },
        {
            title: 'GALVANIZING',
            text: 'The Genius of Galvanizing is all about getting people on board for projects, activities, and ideas. People with this genius get energy and joy from inspiring and persuading others to take action. It’s easy to spot a galvanizer because they naturally share their excitement with others in an effort to generate momentum.',
        },
        {
            title: 'ENABLEMENT',
            text: 'The Genius of Enablement is all about providing others with the help they need for any given project or activity. People with this genius get joy and energy from making the people around them feel supported and have a gift for doing so in just the right way. As a result, they are often the first to volunteer when somebody asks for assistance.',
        },
        {
            title: 'TENACITY',
            text: 'The Genius of Tenacity is all about pushing tasks across the finish line and getting things done. People with Tenacity get joy and energy from reaching goals, meeting standards, and checking things off their lists. They are highly motivated to work through obstacles to ensure results are achieved on time.',
        },
    ];
    const renderDescriptions = (descriptions) => {
        return (
            <>
                {descriptions.map((description, key) => {
                    return (
                        <div className='mb-8' key={key}>
                            <h2 className={'text-xl font-bold uppercase mb-1'}>{description.title}</h2>
                            <p className={'text-14'}>
                                {description.text}
                            </p>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className='DescriptionsStudent'>
            <PageHeader title={'WORKING GENIUS DESCRIPTIONS'} />
            <div className='container'>
                {renderDescriptions(descriptions)}
            </div>
        </div>
    );
};

export default Descriptions;
