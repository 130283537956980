import React from 'react';
import Header from './Header';
import DownloadResource from './DownloadResource';
import AppVideoModal from '../AppVideoModal';
import { tag_hiring_thumbnail, tag_meetings_thumbnail } from '../../images';

const AdditionalTools = () => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	const headerContent = {
		title: 'Meetings, Hiring & Staffing',
		subtitle: 'Understanding the Working Genius can help reduce confusion in meetings and create clarity around hiring',
		description:
			'While we recommend doing the previous steps in order, this section is an a la carte collection of resources to use as you apply the Working Genius to your meetings, hiring, and staffing. These tools will help you increase productivity and morale and decrease frustration and burnout.',
	};
	return (
		<div>
			<Header
				title={headerContent.title}
				subtitle={headerContent.subtitle}
				desription={headerContent.description}
				containerClass={'toolkit-leading-header'}
			/>
			<div className='toolkit-content'>
				<p className='text-lg'>
					<b>Working Genius and Meetings</b>
				</p>
				<div className='flex flex-col items-center mb-10 w-full'>
					<div className='flex flex-col mb-4'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() =>
								setVimeoUrl('https://player.vimeo.com/video/803510846?h=7f31568f9e&autoplay=1')
							}
							id='wg-about-play-main-video'
						>
							<img src={tag_meetings_thumbnail} alt='Play Video Patrick' width='595' />
						</a>
					</div>
					<div className='flex flex-col lg:flex-row w-full'>
						<div className='flex-1 lg:mr-4 my-4'>
							<DownloadResource title='Outline from Video' file='https://files.tablegroup.com/wp-content/uploads/2023/03/27104732/Working-Genius-Meetings.pdf' />
						</div>
						<div className='flex-1 my-4'>
							<DownloadResource title='The Altitude of Geniuses' file='https://files.tablegroup.com/wp-content/uploads/2023/03/01073617/Working-Genius-Altitude-of-Genius.pdf' />
						</div>
					</div>
				</div>
				<p className='text-lg'>
					<b>Working Genius and Hiring &amp; Staffing</b>
				</p>
				<div className='flex flex-col items-center mb-10 w-full'>
					<div className='flex flex-col mb-4'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() =>
								setVimeoUrl('https://player.vimeo.com/video/803510490?h=0ee0c6f66a&autoplay=1&amp;')
							}
							id='wg-about-play-main-video'
						>
							<img src={tag_hiring_thumbnail} alt='Play Video Patrick' width='595' />
						</a>
					</div>
					<div className='flex flex-col lg:flex-row items-center w-full'>
						<div className='flex-1 lg:mr-4 my-4 w-full'>
							<DownloadResource title='Outline from Video' file='https://files.tablegroup.com/wp-content/uploads/2023/03/27104733/Working-Genius-Hiring-Staffing.pdf' />
						</div>
						<div className='flex-1 my-4 w-full'>
							<DownloadResource title='Hiring Guide' file='https://files.tablegroup.com/wp-content/uploads/2023/03/27104730/Working-Genius-Hiring-Guide.pdf' />
						</div>
					</div>
				</div>
			</div>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default AdditionalTools;
