import React from 'react';
import Header from './Header';
import NextUp from './NextUp';
import AppButton from '../AppButton';
import AppVideoModal from '../AppVideoModal';
import UtilityService from '../../services/UtilityService';
import { tag_welcome_thumbnail } from '../../images';

const Welcome = ({ name, setActiveTab }) => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	const headerContent = {
		title: `Welcome, ${name}`,
		description:
			'Working Genius can transform the way you work by helping your team increase productivity, morale, and trust. Teams have also used the model and principles to improve their meetings, hiring and staffing. This self-directed guide will give you tools and insights to show you how.',
	};

	return (
		<div>
			<Header
				title={headerContent.title}
				desription={headerContent.description}
				containerClass={'toolkit-welcome-description'}
			/>

			<div className='toolkit-content'>
				<div className='flex flex-col lg:flex-row'>
					<div>
						<p className={'text-lg text-primary font-semibold'}>
							A short welcome from Pat
						</p>
						<div className='flex flex-col lg:flex-row items-center justify-between'>
							<a
								className='book-video-link cursor-pointer'
								onClick={() =>
									setVimeoUrl('https://player.vimeo.com/video/803510463?h=be7931c31e&autoplay=1&amp;')
								}
								id='wg-about-play-main-video'
							>
								<img src={tag_welcome_thumbnail} alt='Play Video Patrick' width='600' />
							</a>
							<div className='toolkit-highlighted-section m-4' style={{ minWidth: '220px', padding: '16px', lineHeight: '1' }}>
								<span className='block text-base font-semibold pb-4'>
									“Working Genius has forever changed how I think about meetings, my teammates,
									and our hiring."
								</span>
								<span className='text-14 font-medium'>-Gabe Dunbar, Bowling Green State University</span>
							</div>
						</div>
						<p>
							First, you'll <b>introduce the Working Genius</b> model to your team. You'll review your own results,
							send the assessment to your team, and review your Team Map.
						</p>
						<p>
							Then, you'll <b>lead a Team Discussion</b> about Working Genius. We've included a video from Patrick Lencioni, creator of Working Genius.
						</p>
						<p>
							Finally, we've included tools for using Working Genius in <b>meetings, hiring and staffing</b>.
						</p>
					</div>
				</div>
			</div>

			<NextUp nextTab={'tab2'}>
				<AppButton
					className={'btn btn-primary my-4 mx-auto relative'}
					text={"Let's get started"}
					onClick={() => {
						UtilityService.scrollToTop();
						setActiveTab('tab2');
					}}
					id={'wg-toolkit-welcome-btn'}
				/>
			</NextUp>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default Welcome;
