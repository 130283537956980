import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import config from '../../constants/config';
import AppTabTitle from '../AppTabTitle';
import HomepageHeader from '../homepage/HomepageHeader';
import HomeNavHamburger from '../homepage/HomeNavHamburger';
import HomeFooter from '../homepage/HomeFooter';
import PublicTopBar from '../homepage/PublicTopBar';
import { normalizePathName } from '../../services/UtilityService';

const ShoppingCartDetailsLayout = ({ children }) => {
	const location = useLocation();

	return (
		<div className={`flex flex-col min-h-screen`}>
			<Helmet>
				<link rel='canonical' href={`${config.base_url}${normalizePathName(location.pathname)}`} />
			</Helmet>
			<AppTabTitle title={'Shopping Cart Details | The Six Types of Working Genius'} />
			<PublicTopBar />
			<HomepageHeader workshopItem={true} />
			<HomeNavHamburger workshopItem={true} />
			<div className={`flex flex-1 flex-col container py-8`}>{children}</div>
			<HomeFooter workshopItem={true} />
		</div>
	);
};

export default ShoppingCartDetailsLayout;
