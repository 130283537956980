import React from 'react';
import AppRIIcon from './AppRIIcon';

const AppSwitchingProfile = () => {
    return (
        <div
            style={{
                bottom: 0,
                left: 0,
                top: 0,
                right: 0,
                position: 'fixed',
                zIndex: 9999,
                backgroundColor: 'rgba(38, 56, 96, 0.95)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <AppRIIcon icon='arrow-left-right-line' className='text-3xl text-primary bg-white rounded-full flex items-center justify-center w-16 h-16' />
            <br />
            <p className='text-white text-base'>Switching profile</p>
        </div>
    );
};

export default AppSwitchingProfile;
