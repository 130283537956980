import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../../components/Layout';
import TakeWizard from '../../components/assessments/take';
import { getQueryParams } from '../../services/UtilityService';
import { useLocation } from '@reach/router';

const Take = () => {
	const location = useLocation();
	const { a, r } = getQueryParams(location.search);

	if (!a || !r) {
		navigate(-1);
		return null;
	}

	return (
		<Layout title={'Assessment | The Six Types of Working Genius'}>
			<TakeWizard assessmentId={a} rosterId={r} />
		</Layout>
	);
};

export default Take;
