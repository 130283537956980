import React from 'react';
import AppButton from '../../../AppButton';
import { widget_secondary } from '../../../../images';

const ReportWithheld = ({ administrator, onDone }) => {
    return (
        <div className='flex flex-col items-center justify-center py-12' style={{ height: '300px' }}>
            <img src={widget_secondary} alt='Widget' width='150' />
            <p className='text-primary text-xl font-semibold mt-6'>Thank you for completing The 6 Types of Working Genius Assessment for Students</p>
            <p className='text-primary text-base font-normal'>
                A copy of your full report will be e-mailed to your administrator, <span className='font-semibold'>{administrator}</span>, and they will follow up with you regarding your results.
            </p>
            <div className='flex justify-center my-4'>
                <AppButton text={'Done'} onClick={onDone} className='btn btn-student' />
            </div>
        </div>
    )
}

export default ReportWithheld