import React from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { Helmet } from 'react-helmet';
import AppTabTitle from '../components/AppTabTitle';
import HomeHero from '../components/homepage/HomeHero';
import HomeNavHamburger from '../components/homepage/HomeNavHamburger';
import AppRIIcon from '../components/AppRIIcon';
import HomepageHeader from '../components/homepage/HomepageHeader';
import HomeFooter from '../components/homepage/HomeFooter';
import PublicTopBar from '../components/homepage/PublicTopBar';
import HomePat from '../components/homepage/HomePat';
import LaunchEventReviews from '../components/launchevent/LaunchEventReviews';
import WhyWorkingGenius from '../components/homepage/WhyWorkingGenius';
import Home6Types from '../components/homepage/Home6Types';
import HomeBook from '../components/homepage/HomeBook';
import HomeTeam from '../components/homepage/HomeTeam';
import HomeResources from '../components/homepage/HomeResources';
import HomeJoinTeam from '../components/homepage/HomeJoin';
import HomeStickyNav from '../components/homepage/HomeStickyNav';
import UtilityService from '../services/UtilityService';
import config from '../constants/config';
import MeetingsPopup from '../components/event8november/MeetingsPopup';
import EmailCollector from '../components/homepage/EmailCollector';
import AssessmentsService from '../services/AssessmentsService';

const isEventOnGoing = UtilityService.isUtcDateBefore('2023-11-08 18:00:00');

const Home = () => {
	return (
		<>
			<AppTabTitle
				title={'6 Types Of Working Genius: Discover Your Gifts & Transform Your Work'}
				description={
					"Discover your working genius and giftings, along with your working frustrations, to revolutionize your team's work, effectiveness, and morale!"
				}
			/>
			<Helmet>
				<link rel='canonical' href={`${config.base_url}`} />
			</Helmet>
			<div className='hidden lg:block'>
				<button
					className='home-scroll-top-bottom'
					onClick={() => document.getElementById('home-pat-section-id').scrollIntoView()}
				>
					<AppRIIcon icon={'arrow-down-line'} className={'text-secondary text-2xl'} />
				</button>
				<ScrollToTop
					smooth
					width='40'
					top='200'
					component={<AppRIIcon icon={'arrow-up-line'} className={'text-secondary text-2xl'} />}
					className={'home-scroll-top-bottom'}
				/>
			</div>
			<PublicTopBar />
			<HomepageHeader />
			<HomeStickyNav />
			<HomeNavHamburger />
			<HomeHero />
			<HomePat />
			<div className='home-review-slider' style={{ backgroundColor: '#dedfe1' }}>
				<LaunchEventReviews />
			</div>
			<WhyWorkingGenius />
			<EmailCollector
				title={'Get a free sample report'}
				successMessage={'Thanks for requesting a sample report. Look for it in your inbox!'}
				onSubmit={AssessmentsService.sendSampleReport}
			/>
			<Home6Types />
			<HomeBook />
			<HomeTeam />
			<HomeResources />
			<HomeJoinTeam />
			<section id='home-footer'>
				<HomeFooter />
			</section>
			{isEventOnGoing && <MeetingsPopup />}
		</>
	);
};


export default Home;
