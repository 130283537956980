import React from 'react';
import { report_overview_gear_d, report_overview_gear_i, report_overview_gear_w } from '../../../../images';

const TeamImpact = () => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h3>The Impact on Teams</h3>

				<p>
					Every team is different. That&rsquo;s because they have different objectives, and because they are
					made up of people with different areas of working genius, competency and frustration. However, the
					problems that teams face when they lack one or more of the areas of genius tend to be quite similar.
					Let&rsquo;s look at those problems now, assuming just one of the areas of genius is missing.
				</p>

				<div className='buckets'>
					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_w} alt={''} />
						</div>

						<div>
							<b>Wonder</b> - When a team is underrepresented in the Genius of Wonder, it will often fail
							to identify serious problems or take advantage of major opportunities. Teams without Wonder
							don&rsquo;t take the time to collectively ponder and discuss what is going on in its
							environment. They prefer to &ldquo;get things done.&rdquo; Unfortunately, they often fail to
							recognize that what they are &ldquo;getting done&rdquo; may not be addressing the most
							important needs within the organization or industry. The Genius of Wonder allows a team to
							spend adequate time with uncertainty and ambiguity, allowing it to dream, speculate,
							brainstorm and contemplate what matters most. Without Wonder, that team may focus too much
							on being efficient, but without being effective.
						</div>
					</div>

					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_i} alt={''} />
						</div>

						<div>
							<b>Invention</b> - When a team is underrepresented in the Genius of Invention, it may find
							itself revisiting the same problems again and again, and frustrated that little progress is
							being made. Teams without Invention may keep relying on the same product or service or idea,
							hoping that it will somehow work &ldquo;this time.&rdquo; They may become burned out and
							helpless as competitors are overtaking them in spite of all the hours and energy being
							spent. The Genius of Invention allows teams to generate novel solutions and ideas so that
							their hard work brings about real benefits.
						</div>
					</div>

					<div className='bucket'>
						<div className='gear'>
							<img src={report_overview_gear_d} alt={''} />
						</div>

						<div>
							<b>Discernment</b> - When a team is underrepresented in the Genius of Discernment, it will
							find itself trying to implement ideas or solutions that turn out to be inadequate or
							ineffective. Teams without Discernment often experience high rates of failure in new
							products, services, or initiatives, wondering why they don&rsquo;t notice problems or issues
							before launching those initiatives. They often experience tension and blaming between the
							people who come up with new ideas and those tasked with implementing them. The Genius of
							Discernment allows teams to evaluate ideas and programs, making it possible to avoid bad
							ones before it&rsquo;s too late, and tweak good ones to bring about the best possible
							outcome.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamImpact;
