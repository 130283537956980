import React from 'react';
import NotificationService from '../../services/NotificationService';
import AppMoreOptions from '../AppMoreOptions';
import AppRIIcon from '../AppRIIcon';
import AppIcon from '../AppIcon';

const MarkAsReadToggleMobile = ({ notification, onComplete }) => {
	const [loading, setLoading] = React.useState(false);

	const isRead = notification.read;

	const changeNotificationReadStatus = () => {
		setLoading(true);
		NotificationService.toggleMarkAsRead({ id: notification.id, read: !isRead }).then(
			() => {
				onComplete();
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				NotificationService.error(error.message);
			}
		);
	};

	if (loading) {
		return (
			<div className={'relative'} id={'wg-notifications-mark-as-read-loading'}>
				<p className='absolute top-0' style={{ right: '-2px', top: '-7px' }}>
					<AppIcon icon={'loading'} className={'animate-spin'} style={{ fontSize: '10px' }} />
				</p>
			</div>
		);
	}

	return (
		<AppMoreOptions
			actions={[{ text: isRead ? 'Mark as Unread' : 'Mark as Read', onClick: changeNotificationReadStatus }]}
		>
			<AppRIIcon icon={'more-2-fill'} />
		</AppMoreOptions>
	);
};

export default MarkAsReadToggleMobile;
