import React from 'react';

import UsingSection from './UsingSection';
import AppButton from '../AppButton';

import CustomerService from '../../services/CustomerService';

import { usingAdminData } from './usingAdminSectionData';
import { navigate } from '@reach/router';

const UsingAdminConsole = () => (
	<div className='about-using-wg-section'>
		<div className='container'>
			<h2 className='text-2xl md:text-4xl font-normal text-primary text-center mb-4'>
				Using the Working Genius Administrator's Console
			</h2>

			{usingAdminData.map((pageSection) => (
				<UsingSection data={pageSection} />
			))}

			<div className='flex flex-col mx-10 lg:flex-row lg:items-center justify-center pt-5'>
				<AppButton
					className={'btn btn-primary my-4 mr-0 lg:mr-5'}
					text={'Buy the assessment now'}
					onClick={() => CustomerService.addToCart('add_license', true)}
					id={'wg-livestream-buy-assessment-btn'}
				/>

				<AppButton
					className={'btn btn-primary my-4'}
					text={'Already Have a Console: Login Now'}
					onClick={() => navigate('/client/login')}
					id={'wg-livestream-buy-assessment-btn'}
				/>
			</div>
		</div>
	</div>
);

export default UsingAdminConsole;
