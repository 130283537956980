import React from 'react';
import {
	report_team_map_analyze,
	team_map_analyze,
	team_map_large_report_arrow,
	team_map_small_report_arrow,
} from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const TeamMapAnalyze = () => {
	return (
		<div className='Application'>
			<PageHeader title={'Application'} />
			<div className={'container mt-12'}>
				<PageTitle title={'Analyzing a Team Map'} />

				<p className={'mt-5 text-12 leading-snug'}>Here are a few questions to get you started:</p>

				<ol className={'list-decimal ml-4'}>
					<li className={'pl-2'}>What are our team’s strengths?</li>
					<li className={'pl-2'}>What are our team’s gaps?*</li>
					<li className={'pl-2'}>
						How does this team map explain past failures and/or successes of the team?
					</li>
					<li className={'pl-2'}>
						Is there anything we should adjust or reconsider about our next project or priority to maximize
						our effectiveness?
					</li>
				</ol>

				<div className={'grid grid-cols-8 mt-6 gap-4 -mx-4'}>
					<div className={'col-span-2 explanation-container'}>
						<p>
							With only one member having the Genius of Wonder and three with it as a frustration, this
							team needs to ensure they are making time to ask the right questions and identifying the
							right problems before moving to action.
						</p>
						<p>
							This team should ask:
							<ul>
								<li>Are we tapping into Yasmine’s gift for our projects and initiatives?</li>
								<li>Are we really solving for the right problems?</li>
							</ul>
						</p>
						<div className={'report-arrow left'}>
							<img src={team_map_small_report_arrow} alt='' />
						</div>
					</div>
					<div className={'col-span-4'}>
						<img src={report_team_map_analyze} alt='' className={'shadow-md mb-8'} />
					</div>
					<div className={'col-span-2 explanation-container'}>
						<p>
							This team has a strong representation in Galvanizing. They know how to stir up energy and
							momentum but need to be careful to not rally others around the wrong initiatives.
						</p>
						<p>
							This team should ask:
							<ul>
								<li>In the past, have we galvanized ideas that we had not fully discerned?</li>
								<li>
									Do we keep stirring up energy around the same ideas and initiatives without taking
									the time to wonder or invent?
								</li>
								<li>
									How do we ensure that we focus our gift of Galvanizing around only our most
									important initiatives?
								</li>
							</ul>
						</p>
						<div className={'report-arrow right'}>
							<img src={team_map_small_report_arrow} alt='' />
						</div>
					</div>
				</div>
				<div className='mx-12'>
					<div className='explanation-container'>
						<p>
							This team lacks Discernment and may have trouble evaluating, refining, or selecting the
							right ideas.
						</p>
						<p>
							This team should ask:
							<ul>
								<li>How does a lack of Discernment explain past frustrations or failure?</li>
								<li>
									How can we ensure ideas and initiatives are properly vetted before we start
									implementing?
								</li>
								<li>
									Can we hire or borrow someone with this genius to help us with our decision-making?
								</li>
							</ul>
						</p>
						<div className={'report-arrow large'}>
							<img src={team_map_large_report_arrow} alt='' />
						</div>
					</div>
				</div>
				<div style={{ marginTop: '80px' }}>
					<p>
						*TIP: Learn more about the implications of when a genius is missing on a team in{' '}
						<a
							href='https://files.tablegroup.com/wp-content/uploads/2022/09/20012550/Impact-on-Teams.pdf'
							target={'_blank'}
							className={'text-secondary underline'}
						>
							The Impact on Teams
						</a>{' '}
						handout.
					</p>
				</div>
			</div>
		</div>
	);
};

export default TeamMapAnalyze;
