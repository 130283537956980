import React from 'react';
import { application_guide_step_gif, patric_and_application } from '../../../images';
const StepGuide = () => {
	return (
		<div className={'bg-white step-guide-background'}>
			<div className={'flex flex-col container py-5'}>
				<div className={'flex-1 application-guide-layout md:items-center'}>
					<div className={'flex-1 p-5 my-8 mx-4'}>
						<img src={application_guide_step_gif} alt='guide-gif' className={'rounded-md'} />
					</div>

					<div className={'flex-1 p-2 mt-5'}>
						<div className={'flex-col container text-primary'}>
							<div className={'flex-1 ml-2 pb-2 md:text-md lg:text-xl'}>
								Teams that adopt Working Genius experience <b>increased morale and productivity</b>{' '}
								while also <b>decreasing frustration and turnover</b>. In this guide we show you how.
							</div>

							<div className={'flex-1 application-check-list'}>
								<span>Maximize Your Team's Geniuses</span>
								<span>Address Frustrations and Inefficiencies</span>
								<span>Improve Meetings, Hiring, & Staffing</span>
							</div>
						</div>
					</div>
				</div>

				<div className={'flex-1 application-guide-layout md:items-center'}>
					<div className={'flex-1 items-center'}>
						<div className={'flex-col container text-primary'}>
							<div className={'flex-1 md:text-xl lg:text-2xl font-semibold'}>
								Implement Working Genius on Your Team:
							</div>

							<div className={'flex-1 lg:pt-5'}>
								<div className={'flex-1 ml-2 flex-col text-12 lg:text-14 '}>
									<div className={'flex-1 application-gear-list'}>
										<h2>Exclusive Video Content</h2>
									</div>
									<div className={'flex-1 ml-2'}>
										<div className={'ml-5 step-guide-list'}>
											<h3>Debriefing Your Team Map</h3>
											<h3>Turbulence in Meetings</h3>
											<h3>Effective Hiring, and Staffing</h3>
										</div>
									</div>
								</div>
							</div>

							<div className={'flex-1 pb-4 lg:pt-5'}>
								<div className={'flex-col ml-2 text-12 lg:text-14 flex-1'}>
									<div className={'flex-1 application-gear-list'}>
										<h2>PDF Resources</h2>
									</div>

									<div className={'flex-1 ml-2'}>
										<div className={'ml-5 step-guide-list'}>
											<h3>Leading The Working Genius Team Discussion</h3>
											<h3>Hiring Guide</h3>
											<h3>Altitude of Genius</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={'flex-1 ml-2 p-5'}>
						<img src={patric_and_application} alt='patric-and-application' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default StepGuide;
