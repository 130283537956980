import React from 'react';
import AppButton from '../AppButton';
import RegisterForm from './RegisterForm';

const LaunchEventAbout = () => {
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

	return (
		<div className='launch-event-about-section'>
			<div className='container'>
				<h3 className='text-white text-4xl font-normal py-4'>About the Event</h3>
				<hr className='my-4 w-full' />
				<p className='text-white text-lg lg:w-2/3 mx-auto p-4'>
					Hear best-selling author Patrick Lencioni share about his ground-breaking new model, The Six Types
					of Working Genius, which is changing the way people think about their work and their team. In this
					75-minute event, Pat will share insights from his book and the Working Genius team will provide tips
					and application for individuals and teams. Register now to experience the book launch premiere.
					Registration includes on-demand access beginning 9/27.
				</p>
				<div className='about-list'>
					<span>Premieres 9/27</span>
					<span>Free</span>
					<span>Includes On-demand Access</span>
				</div>
				<AppButton
					className={'btn btn-primary w-56 my-8 mx-auto'}
					onClick={() => setOpenRegisterModal(true)}
					text={'Register now'}
					id={'wg-launch-event-register-now-btn'}
				/>
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default LaunchEventAbout;
