import React from 'react';
import AppRIIcon from '../AppRIIcon';

const NoData = ({ icon, message, className }) => {
	return (
		<div className={`flex flex-col items-center justify-center ${className}`}>
			<AppRIIcon icon={icon} className={'text-4xl text-secondary'} />
			<p className={'w-2/3 text-center text-primary'}>{message}</p>
		</div>
	);
};

export default NoData;
