import React from 'react';
import { StudentReportGearsHeader } from '../../../../images';

const PageHeader = ({ title, children, ...props }) => {
    return (
        <div className={'page-header student'} {...props}>
            <img src={StudentReportGearsHeader} alt='Report Gears' />
            {title ? <p className='title'>{title}</p> : children}
        </div>
    );
};

export default PageHeader;
