import React from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';

import SingleEmailInput from '../assessments/SingleEmailInput';
import AppButton from '../AppButton';
import MultipleEmailInput from '../assessments/MultipleEmailInput';
import AppSwitch from '../AppSwitch';

const SearchEmails = ({ save, loading }) => {
	const language = localStorage.getItem('wglang') || 'en';
	const [singleInputMode, setSingleInputMode] = React.useState(language !== 'en');
	const multipleRef = React.useRef();
	const {
		register,
		unregister,
		handleSubmit,
		watch,
		control,
		formState: { errors },
		setValue,
		getValues,
		setError,
		clearErrors,
	} = useForm({
		defaultValues: {
			emails: [],
			emailSize: 0,
		},
	});

	React.useEffect(() => {
		if (!singleInputMode) {
			getValues('emails').map((val) => {
				unRegisterSingleEmailInput(val.fakeId);
			});
		}
	}, [singleInputMode]);
	const { isSubmitted } = useFormState({ control });

	const unRegisterSingleEmailInput = (i) => {
		clearErrors(`tmp_email_${i}`);
		unregister(`tmp_email_${i}`);
	};

	return (
		<form onSubmit={handleSubmit(save)} className={'mt-4'}>
			<div className='short-form'>
				{singleInputMode ? (
					<>
						<SingleEmailInput
							register={register}
							name={'emails'}
							label={
								<p className='text-tiny leading-5 font-medium text-gray-800 mb-1'>Team Member Emails</p>
							}
							onChange={(members) => {
								setValue('emails', members);
							}}
							onRemove={(i, size) => {
								setValue('emailSize', size);
								unRegisterSingleEmailInput(i);
							}}
							setValue={setValue}
							values={watch('emails')}
							placeholder={'Enter emails separated by a comma “,”'}
							errors={errors}
							limit={watch('emailSize') || 1}
							min={1}
							setError={setError}
							clearErrors={clearErrors}
							isSubmitted={!!isSubmitted}
						/>
						<Controller
							control={control}
							name={'emailSize'}
							render={({ field: { onChange } }) => (
								<AppButton
									type='button'
									text='Add email'
									className='btn btn-secondary flex flex-1'
									icon='add-fill'
									onClick={() => {
										onChange((getValues('emailSize') || 1) + 1);
									}}
								/>
							)}
						/>
					</>
				) : (
					<MultipleEmailInput
						name={'emails'}
						ref={multipleRef}
						label={
							<p className='text-tiny leading-5 font-medium text-gray-800 mb-1 mt-5'>Email Address(es)</p>
						}
						onChange={(members) => {
							setValue('emails', members);
							setValue('emailSize', members.length);
						}}
						values={watch('emails')}
						placeholder={'Enter emails separated by a comma “,”'}
						errors={errors}
						setError={setError}
						clearErrors={clearErrors}
						isSubmitted={!!isSubmitted}
					/>
				)}
			</div>

			<AppSwitch
				className='mt-4 pt-4'
				value={singleInputMode}
				onChange={setSingleInputMode}
				id={'wg-single-input-values'}
				label="I'd rather enter email addresses in separate fields"
			/>
			<div className='mt-4 pt-4'>
				<AppButton
					text={'Search'}
					type={'submit'}
					disabled={!getValues('emails').length || Object.keys(errors).length > 0}
					loading={loading}
					id={'wg-search-assessments'}
				/>
			</div>
		</form>
	);
};

export default SearchEmails;
