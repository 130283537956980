import React, { useEffect, useState, useMemo } from 'react';
import Layout from '../../../../components/Layout';
import {formatDate, getQueryParams} from '../../../../services/UtilityService';
import { getResourceDetails } from '../../../../services/CertificationService';
import { cert_section_1 } from '../../../../images';
import AppAudioPlayer from '../../../../components/AppAudioPlayer';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import {useUser} from "../../../../hooks/UserProvider";
import moment from "moment/moment";

const AlumniIntensivesResourceDetails = () => {
	const location = useLocation();
	const { user } = useUser();
	const { id } = getQueryParams(location.search);
	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState({});
	const [alumniIntensive, setAlumniIntensive] = useState({});
	const currentTime = moment().tz('America/Los_Angeles');
	const hasAccess = useMemo(() => currentTime.isAfter(moment.tz(alumniIntensive?.expected_resources_at, 'America/Los_Angeles')), [currentTime, alumniIntensive?.expected_resources_at]);

	useEffect(() => {
		getResourceDetails(id)
			.then(resource => {

				if(resource?.type === 'alumni_intensives'){
					const resource_alumni_intensive_ids = resource?.alumni_intensives_variation_ids || [];
					const purchased_alumni_intensive = user
						?.wg_alumni_intensives
						?.find(item => resource_alumni_intensive_ids?.includes(item?.variation_id));

					setAlumniIntensive(purchased_alumni_intensive);
				}

				setDetails(resource);
			})
			.finally(() => setLoading(false));
	}, [user, id]);


	if (loading) {
		return (
			<div className='relative h-screen'>
				<div className='absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	const renderHero = () => {
		return (
			<>
				<Helmet>
					<script
						src='https://cdn.commoninja.com/sdk/latest/commonninja.js'
						defer
						type='text/javascript'
					></script>
				</Helmet>

				<div
					className={'text-center py-9'}
					style={{
						backgroundImage: `url(${cert_section_1})`,
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
					}}
				>
					<div className={'mx-auto'}>
						<h1 className={'text-4xl md:text-5xl text-white font-thin'}>{details.title}</h1>
						{!!details.excerpt && (
							<p className={'text-white text-xl font-thin my-6 w-3/5 mx-auto'}>{details.excerpt}</p>
						)}
						<p className={'text-white text-xl font-thin'}>{alumniIntensive?.expected_resources_at && formatDate(alumniIntensive?.expected_resources_at, 'MMM YYYY', false)}</p>
					</div>
				</div>
			</>
		);
	};

	const renderPodcast = () => {
		return (
			<div>
				<h1 className={'text-5xl text-primary font-bold mb-4'}>Listen to the Episode</h1>

				<AppAudioPlayer
					title={details.podcast.title}
					description={details.podcast.description}
					image={details.podcast.img}
					audioSrc={details.podcast.url}
				/>
			</div>
		);
	};
	const renderVideo = () => {
		return (
			<>
				<div className='video-container'>
					<iframe
						id='player-iframe'
						title='video-player-iframe'
						src={details.video.url}
						frameBorder='0'
						allow='autoplay; fullscreen'
						allowFullScreen
					/>
				</div>
				{renderHtml()}
			</>
		);
	};

	const renderHtml = () => {
		return (
			<>
				<div
					id={'wg-resource-alumni-intensive-prework-content'}
					className='wg-resource-content'
					dangerouslySetInnerHTML={{ __html: details.alumni_intensives_prework }}
				/>
				{
					details && alumniIntensive && hasAccess &&
					(
						<div
							id={'wg-resource-alumni-intensive-post-content'}
							className='wg-resource-content'
							dangerouslySetInnerHTML={{__html: details.content}}
						/>
					)
				}
			</>
		);
	};

	const renderContent = () => {
		if (details.is_podcast.toLowerCase() === 'yes') {
			return renderPodcast();
		}
		if (details.is_video.toLowerCase() === 'yes') {
			return renderVideo();
		}
		return renderHtml();
	};

	return (
		<Layout containerClass={'wg-resource-details'}>
			{renderHero()}
			<div className='container mx-auto my-12'>{renderContent()}</div>
			{
				!hasAccess &&
					(
						<>
							<div className={'certification-bar py-3'}>
								<p className='text-primary text-3xl pb-5'>Post Resources</p>

								<p className='text-primary text-xl'>
									Upon completing the course, you will be able to access the Post-Training Resources
								</p>
							</div>
							<div className={'whitespace-normal'}></div>
						</>
					)
			}
		</Layout>
	);
};

export default AlumniIntensivesResourceDetails;
