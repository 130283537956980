import React from 'react';
import { gears } from '../../../images';

const Quote = () => {
	return (
		<div className={'application-guide-quote-section'}>
			<div className={'flex flex-col items-center justify-center text-center p-10 container'}>
				<img src={gears} alt='guide-gears' className={'flex-1'} />
				<div
					className={
						'flex-1 text-primary text-xl md:text-2xl italic w-7/12 py-4 lg:px-10 application-guide-quote-width '
					}
				>
					"Working Genius has forever changed how I think about meetings, my teammates, and our hiring."
				</div>
				<div className={'flex-1 text-primary text-base font-semibold'}>-GABE DUNBAR,</div>
				<div className={'flex-1 text-primary text-base font-semibold'}>BOWLING GREEN STATE UNIVERSITY</div>
			</div>
		</div>
	);
};

export default Quote;
