import React from 'react';
import Header from './Header';
import LeadersTodo from './LeadersTodo';
import NextUp from './NextUp';
import {
	tag_sending_assessment_thumbnail,
	tag_share_results,
	tag_assessment_take
} from '../../images';
import AppButton from '../AppButton';
import AppVideoModal from '../AppVideoModal';
import UtilityService from '../../services/UtilityService';

const Prework = ({ setActiveTab }) => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	const headerContent = {
		title: 'Sending and Gathering Assessments',
		subtitle: 'A frustration-free overview of how to send or organize assessment results',
		description: `Before we dive into applying Working Genius, here are a couple of helpful tips to manage assessments:`,
	};
	return (
		<div>
			<Header
				title={headerContent.title}
				subtitle={headerContent.subtitle}
				desription={headerContent.description}
				containerClass={'wg-toolkit-pre-work-container'}
			>
				<ul className={'list-disc text-white leading-8 ml-16 -mt-8'}>
					<li>Take the Working Genius assessment</li>
					<li>Send the assessment to others</li>
					<li>Find or share assessment results</li>
				</ul>
			</Header>
			<div className='toolkit-content highlighted-note'>
				<span className='text-lg'>
					<b>IMPORTANT NOTE:</b>
				</span>
				<span className='block italic'>
					If you and your team have already completed the Working Genius Assessment,{' '}
					<a
						className='block underline cursor-pointer hover:no-underline hover:text-white'
						onClick={() => {
							UtilityService.scrollToTop();
							setActiveTab('tab3');
						}}
					>
						skip to "Reading a Team Map".
					</a>
				</span>
			</div>
			<div className='toolkit-content'>
				<p className='text-lg'>
					<b>Take the Working Genius Assessment</b>
				</p>
				<p>
					<span className='block italic'>
						If you haven't already purchased and taken the Working Genius Assessment, go to the
					</span>{' '}
					<span
						className='text-secondary font-semibold underline cursor-pointer hover:no-underline'
						onClick={() => window.location.href = '/about/assessment'}
					>
						Assessment page
					</span>{' '}
					and click "<span className='font-semibold'>Take the Assessment</span>".
				</p>
				<div className='px-8 py-16 flex justify-center w-full' style={{ backgroundColor: '#e7e9ea' }}>
					<img src={tag_assessment_take} alt='Working Genius Resources' width='500' />
				</div>
			</div>
			<div className='toolkit-content'>
				<div className='flex flex-col lg:flex-row items-center'>
					<div className='flex-1 lg:mr-6'>
						<p className='text-lg'>
							<b>Send the Working Genius Assessment to your Team</b>
						</p>
						<p>
							If your team has not already taken the Working Genius Assessment, watch this video for
							instructions on how to send the assessment.
						</p>
					</div>
					<div className='flex-1 flex items-center justify-center'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/788317243?autoplay=1')}
							id='wg-about-play-main-video'
						>
							<img src={tag_sending_assessment_thumbnail} alt='Play Video Patrick' />
						</a>
					</div>
				</div>
			</div>
			<div className='toolkit-content'>
				<div className='flex flex-col lg:flex-row items-center'>
					<div className='flex-1 lg:mr-6'>
						<p className='text-lg'>
							<b>Find or Share Results</b>
						</p>
						<p>
							If individuals on your team have already taken the assessment but the results are not in your
							Working Genius console,{' '}
							<span
								className='text-secondary font-semibold underline cursor-pointer hover:no-underline'
								onClick={() => window.open('https://files.tablegroup.com/wp-content/uploads/2023/11/08032851/How-to-Request-Assessment-Results.pdf', '_blank')}
							>
								click here for step-by-step instructions for requesting results
							</span>.
						</p>
					</div>
					<div className='flex-1 flex items-center justify-center'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/788317243?autoplay=1')}
							id='wg-about-play-main-video'
						>
							<img src={tag_share_results} alt='Play Video Patrick' />
						</a>
					</div>
				</div>
			</div>
			<div className='toolkit-content'>
				<span className='text-lg'>
					<b>IMPORTANT NOTE:</b>
				</span>
				<span className='block italic'>
					Preparing for the team discussion will be most productive if everyone first completes their Working
					Genius Assessment to create the most accurate Team Map.
				</span>
			</div>
			<NextUp nextTab={'tab3'}>
				<AppButton
					className={'btn btn-primary my-4 mx-auto relative'}
					text={'Your team results '}
					onClick={() => {
						UtilityService.scrollToTop();
						setActiveTab('tab3');
					}}
					id={'wg-toolkit-prework-btn'}
				/>
			</NextUp>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default Prework;
