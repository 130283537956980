import React from 'react';
import { CoverBreakLine, CoverLogoV2ES, TTGWhiteReportLogo } from '../../../../images';

const CoverPage = ({ assessment }) => {
	const preparedBy = () => {
		if (!assessment.participant.company_logo) {
			return null;
		}

		return (
			<div className='prepared-by'>
				<div className='ttg-container'>
					<p>Preparado por:</p>
					<img src={assessment.participant.company_logo} alt={''} />
				</div>
			</div>
		);
	};

	return (
		<div className='Cover'>
			<div className='top py-8 mx-auto'>
				<div className={'w-11/12 mx-auto'}>
					<p className={'top-title'}>INFORME DE LA EVALUACIÓN</p>
				</div>
			</div>

			<div className='mt-6'>
				<img src={CoverLogoV2ES} alt={''} className={'w-3/4 mx-auto px-5'} />
			</div>

			<div className='gears my-12'>
				<img src={CoverBreakLine} alt={''} />
			</div>

			<p className='tagline'>
				Una explicación completa de Working Genius de {assessment.participant.fname}{' '}
				{assessment.participant.lname}
			</p>

			<div className='footer'>
				<div className='gear'>
					<div className='dot-spanish'>
						<p>
							DESCUBRE <br />
							LOS DONES <br />
							QUE APORTAS <br />
							EN EL TRABAJO <br />
						</p>
					</div>
				</div>
			</div>

			{preparedBy()}

			<div className='powered-by'>
				<div className='ttg-container'>
					<p>Desarrollado por:</p>
					<img src={TTGWhiteReportLogo} alt={''} />
				</div>
			</div>
		</div>
	);
};

export default CoverPage;
