import React, { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Layout from '../../../components/Layout';
import Details from '../../../components/account/Details';
import ChangePassword from '../../../components/account/ChangePassword';
import CustomerService from '../../../services/CustomerService';
import NotificationService from '../../../services/NotificationService';
import Developer from '../../../components/account/Developer';
import config from '../../../constants/config';
import { useUser } from '../../../hooks/UserProvider';

const Index = () => {
	const [loading, setLoading] = useState(false);
	const [updating, setUpdating] = useState(false);

	const { user, setUser } = useUser();

	const save = (payload) => {
		setUpdating(true);
		CustomerService.update(payload)
			.then((result) => {
				setUpdating(false);
				setUser((u) => ({
					...u,
					first_name: result.first_name,
					last_name: result.last_name,
					customers_email_address: result.email,
					company: result.company,
					customers_telephone: result.phone,
					fullName: `${result.first_name} ${result.last_name}`,
					email_change_request: result.email_change_request,
					multi_lingual: result.multi_lingual,
					wg_student_enabled: result.wg_student_enabled,
					role: result.role,
				}));
				NotificationService.success('Changes Successfully saved!');
			})
			.catch((result) => {
				setUpdating(false);
				NotificationService.error('Oops', result.message);
			});
	};

	const resendEmail = () => {
		setLoading(true);
		CustomerService.requestEmailChange(user.email_change_request)
			.then(() => {
				NotificationService.success(
					'Verify email address',
					'We have resend the verification process in your email address.'
				);
			})
			.catch((error) => {
				NotificationService.success('Oops', error.message);
			})
			.finally(() => setLoading(false));
	};

	const undoEmailChange = () => {
		setLoading(true);
		CustomerService.undoEmailChange()
			.then(() => setUser((u) => ({ ...u, email_change_request: null })))
			.catch((error) => {
				NotificationService.success('Oops', error.message);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Layout title='The 6 Types of Working Genius: Administrator Console'>
			<div className='container mx-auto p-6' style={{ maxWidth: '1100px' }}>
				{user.email_change_request && (
					<div className={'bg-blue-200 p-4 rounded-sm'}>
						<p className={'text-primary text-lg mb-3'}>UPDATE PENDING VERIFICATION</p>
						<p className={'text-primary'}>
							We just need you to check your email{' '}
							<span className='font-bold'>{user.email_change_request}</span> and to verify it's you and
							complete the update.
						</p>
						<div className={'flex flex-row mt-4'}>
							<div
								onClick={() => {
									if (!loading) {
										resendEmail();
									}
								}}
								className={`text-secondary cursor-pointer mr-6 ${loading ? 'opacity-50' : ''}`}
							>
								RESEND EMAIL
							</div>
							<div
								onClick={() => {
									if (!loading) {
										undoEmailChange();
									}
								}}
								className={`text-secondary cursor-pointer ${loading ? 'opacity-50' : ''}`}
							>
								UNDO EMAIL CHANGE
							</div>
						</div>
					</div>
				)}
				<h2 className='account-heading my-6'>Account</h2>
				<Tabs>
					<TabList>
						<Tab className={'react-tabs__tab wg-account-details-tab'}>Details</Tab>
						<Tab className={'react-tabs__tab wg-account-password-tab'}>Password</Tab>
						{user?.working_genius_token && (
							<Tab className={'react-tabs__tab wg-account-developer-tab'}>Developer</Tab>
						)}
					</TabList>
					<TabPanel>
						<Details save={save} loading={updating} />
					</TabPanel>
					<TabPanel>
						<ChangePassword />
					</TabPanel>
					{user?.working_genius_token && (
						<TabPanel>
							<Developer />
						</TabPanel>
					)}
				</Tabs>
			</div>
		</Layout>
	);
};

export default Index;
