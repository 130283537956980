import React, { useEffect, useState, useCallback } from 'react';

import AppButton from '../AppButton';
import AppRIIcon from '../AppRIIcon';
import AppTable from '../AppTable';

import UsersFilters from './UsersFilters';
import AddEditPermissionSliveOver from './AddEditPermission';
import RemoveAccessModal from './RemoveAccessModal';

import UtilityService from '../../services/UtilityService';
import PermissionsService from '../../services/PermissionsService';
import NotificationService from '../../services/NotificationService';

const UserManagementList = () => {
    const columns = [
        {
            header: 'Name',
            key: 'customers_name',
            className: 'md:w-1/3 lg:w-1/4',
            render: (item) => {
                switch (item.status) {
                    case 'APPROVED':
                        return (
                            <div className='flex items-center'>
                                <AppRIIcon icon='user-fill' className='hidden md:inline text-2xl text-secondary mr-4' />
                                <div className='flex flex-col'>
                                    <p className='text-primary block'>{item?.customers_name}</p>
                                    <p className='text-gray-500 text-sm'>{item.customers_email_address}</p>
                                </div>
                            </div>
                        );

                    case 'PENDING':
                        return (
                            <div className='flex items-center'>
                                <AppRIIcon icon='mail-send-fill' className='hidden md:inline text-2xl text-secondary mr-4' />
                                <p className='text-primary'>{item?.customers_email_address}</p>
                            </div>
                        );
                }
                return (
                    <div className='flex flex-row items-center'>
                        <AppRIIcon icon='user-fill' className='hidden md:inline text-2xl text-secondary mr-4' />
                        <p className='text-primary'>{item?.customers_email_address}</p>
                    </div>
                );
            },
        },
        {
            header: 'Created',
            key: 'created',
            render: (item) => {
                return <p className='text-primary'>{UtilityService.formatDate(item.created)}</p>;
            },
        },
        {
            header: 'Permission level',
            key: 'role',
            render: (item) => {
                return <p className={`permission-level-role ${item.role.toLowerCase() || ''} mx-6 md:mx-0`}>{item.role.toLowerCase()}</p>;
            },
        },
        {
            header: 'Request Status',
            key: 'status',
            render: (item) => {
                return <p className='text-primary capitalize'>{item.status.toLowerCase()}</p>;
            },
        },
        {
            header: 'Actions',
            key: 'actions',
            sortable: false,
            render: (item) => {
                switch (item.status) {
                    case 'APPROVED':
                        return (
                            <>
                                <span
                                    className='text-blue-700 cursor-pointer hover:underline mr-2'
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setIsOpenEditUserModal(true);
                                    }}
                                >
                                    Edit
                                </span>
                                <span
                                    className='text-red-500 leading-5 cursor-pointer hover:underline inline-block'
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setIsOpenRemoveAccessModal(true);
                                    }}
                                >
                                    Remove
                                </span>
                            </>
                        );

                    case 'PENDING':
                        return (
                            <>
                                <span
                                    className={`text-blue-700 cursor-pointer hover:underline mr-2 ${loadingReminder === item.customers_email_address ? 'pointer-events-none' : ''}`}
                                    onClick={() => {
                                        handleResend(item, 'reminder');
                                    }}
                                >
                                    {loadingReminder === item.customers_email_address ? (
                                        <AppRIIcon icon={'loader-4-line'} className={'action-icon animate-spin loading '} />
                                    ) : (
                                        'Send Reminder'
                                    )}
                                </span>
                                <span
                                    className='text-red-500 leading-5 cursor-pointer hover:underline inline-block'
                                    onClick={() => {
                                        setSelectedItem(item);
                                        setIsOpenRemoveAccessModal(true);
                                    }}
                                >
                                    Cancel
                                </span>
                            </>
                        );

                    case 'DENIED':
                        return (
                            <>
                                <span
                                    className={`text-blue-700 cursor-pointer hover:underline mr-2 ${loadingResend === item.customers_email_address ? 'pointer-events-none' : ''}`}
                                    onClick={() => {
                                        handleResend(item, 'resend');
                                    }}
                                >
                                    {loadingResend === item.customers_email_address ? (
                                        <AppRIIcon icon={'loader-4-line'} className={'action-icon animate-spin loading '} />
                                    ) : (
                                        'Resend Invitation'
                                    )}
                                </span>
                            </>
                        );
                }
            },
        },
    ];

    const [users, setUsers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [loadingReminder, setLoadingReminder] = useState('');
    const [loadingResend, setLoadingResend] = useState('');
    const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);
    const [isOpenRemoveAccessModal, setIsOpenRemoveAccessModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ customers_email_address: '', role: '' });

    const initialFilters = {
        search: '',
        sort_key: 'created',
        sort_dir: 'desc',
    };

    const [filters, setFilters] = React.useState({ ...initialFilters });

    const getUsers = () => {
        setLoading(true);
        PermissionsService.list()
            .then((data) => {
                setUsers(data);
                setFilteredData(data);
            })
            .catch(() => {
                NotificationService.error('Oops', 'Something went wrong, please try again');
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        applyFilters();
    }, [filters, users]);

    const applyFilters = () => {
        let filteredData = [...users];

        if (filters.search) {
            filteredData = filteredData.filter(
                (user) =>
                    user.customers_name?.toLowerCase().includes(filters.search.toLowerCase()) ||
                    user.customers_email_address.toLowerCase().includes(filters.search.toLowerCase())
            );
        }

        filteredData.sort((a, b) => {
            if (filters.sort_dir === 'asc') {
                return a[filters.sort_key] > b[filters.sort_key] ? 1 : -1;
            } else {
                return a[filters.sort_key] < b[filters.sort_key] ? 1 : -1;
            }
        });

        setFilteredData(filteredData);
    };

    const onChangeFilter = useCallback((cb) => {
        setFilters((c) => ({ ...c, ...cb() }));
    }, []);

    const onSort = useCallback((col, sort_dir) => {
        setFilters((prev) => ({
            ...prev,
            sort_key: col.key,
            sort_dir,
        }));
    }, []);

    const onReset = useCallback(() => {
        setFilters(initialFilters);
    }, []);

    const handleResend = (item, type = 'reminder') => {
        if (type === 'resend') {
            setLoadingResend(item.customers_email_address);
        } else {
            setLoadingReminder(item.customers_email_address);
        }
        PermissionsService.requestPermission({
            customers_email_address: item.customers_email_address,
            role: item.role,
        })
            .then(() => {
                if (type === 'reminder')
                    NotificationService.success('Success!', 'Permission request reminder sent');
                else if (type === 'resend') {
                    NotificationService.success('Success!', `Invitation to <b>${item.customers_email_address}</b> was resent successfully!`);
                    getUsers();
                }
            })
            .catch(() => {
                NotificationService.error('Oops', 'Something went wrong, please try again');
            })
            .finally(() => {
                if (type === 'resend') {
                    setLoadingResend('');
                } else {
                    setLoadingReminder('');
                }
            });
    };

    const handleRemove = async (item) => {
        setLoadingRemove(true);
        try {
            if (item.target_customers_id) {
                await PermissionsService.deletePermission(item.target_customers_id);
                NotificationService.success(`Access was successfully removed from <b>${item.customers_email_address}</b>`);
                setIsOpenRemoveAccessModal(false);
                getUsers();
            } else {
                await PermissionsService.deleteRequest({ customers_email_address: item.customers_email_address });
                NotificationService.info('Permission request Cancelled', 'The pending permission request has been cancelled');
                setIsOpenRemoveAccessModal(false);
                getUsers();
            }
        } catch (error) {
            NotificationService.error('Oops', error.message);
        } finally {
            setLoadingRemove(false);
            setTimeout(() => {
                setSelectedItem({ customers_email_address: '', role: '' });
            }, 200);
        }
    };

    const noDataMessage = () => {
        if (!filters.search)
            return (
                <div className={'flex flex-col justify-center pt-4'}>
                    <AppRIIcon icon='history-line' className='text-6xl text-gray-600 pb-4' />
                    Your permissions management history is empty.
                </div>
            );
    };
    const renderHeader = useCallback(
        () => (
            <div className='permissions-container'>
                <div className='permissions-header-container'>
                    <div className='flex flex-col items-start sm:flex-row sm:items-center justify-between my-4'>
                        <h1 className='text-primary text-2xl font-medium'>User management</h1>
                        <AppButton
                            className='btn btn-primary'
                            text='Give Access'
                            remixIcon
                            icon='share-box-fill'
                            iconClass='mx-2 my-0'
                            onClick={() => setIsOpenEditUserModal(true)}
                        />
                    </div>
                    <p className='text-lg leading-7 font-normal text-gray-700'>
                        You can manage access for all users below. If you want to give access to additional users, click
                        the "Give Access" button above.
                    </p>
                </div>
            </div>
        ),
        []
    );

    return (
        <div className={'flex-col'}>
            {renderHeader()}
            <AppTable
                wrapperClassname='mx-auto w-full'
                columns={columns}
                data={filteredData}
                noDataMessage={noDataMessage()}
                loading={loading}
                onSort={onSort}
                renderFilters={UsersFilters}
                filters={filters}
                setFilters={onChangeFilter}
                onSearch={() => applyFilters()}
                onReset={onReset}
            />
            <AddEditPermissionSliveOver
                isOpen={isOpenEditUserModal}
                onClose={() => {
                    setIsOpenEditUserModal(false);
                    setTimeout(() => {
                        setSelectedItem({ customers_email_address: '', role: '' });
                    }, 200);
                }}
                onSuccess={() => getUsers()}
                user={selectedItem}
            />
            <RemoveAccessModal
                loading={loadingRemove}
                isOpen={isOpenRemoveAccessModal}
                onClose={() => setIsOpenRemoveAccessModal(false)}
                onRemove={() => handleRemove(selectedItem)}
                title={selectedItem.target_customers_id ? 'Remove Access ' : 'Cancel invitation'}
                text={
                    selectedItem.target_customers_id
                        ? `Are you sure you wish to remove <span class='capitalize'>${selectedItem.role} access</span> for <b>${selectedItem.customers_email_address}</b>?`
                        : `Are you sure you wish to cancel the invitation?`
                }
                confirmText={selectedItem.target_customers_id ? 'Remove' : 'Yes, cancel'}
                cancelText={selectedItem.target_customers_id ? 'Cancel' : 'No'}
            />
        </div>
    );
};
export default UserManagementList;
