import React from 'react';
import { WidgetReportV2 } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const Overview = () => {
	return (
		<div className='Overview'>
			<PageHeader title={'Overview'} />

			<div className={'container brief'}>
				<PageTitle title={'A Brief Overview of Working Genius'} />

				<p>
					Everyone has natural, God-given talents when it comes to work. As it turns out, there are six
					different types of talents, or geniuses, and each of them is required for accomplishing any kind of
					endeavor.
				</p>
				<p>
					Each of us has two areas that are considered our true geniuses or gifts. These are the activities
					that give us joy, energy, and passion. We call these our areas of{' '}
					<span className='font-bold'>Working Genius</span>. Two of the six types are what we call our{' '}
					<span className='font-bold'>Working Frustrations</span>, activities that rob us of joy and energy.
					Most of us aren’t very skilled in these areas. The final two are what we refer to as{' '}
					<span className='font-bold'>Working Competencies</span>, activities that neither feed nor drain us
					and which we can do fairly well for a limited period of time.
				</p>
				<p>
					The Six Types of Working Genius form an interdependent model, a comprehensive process for
					accomplishing any type of work. Each type of genius receives and/or gives something to adjacent
					types, creating a natural workflow starting with Wonder and culminating in Tenacity. The model below
					depicts the flow of the six types and includes a short definition of each genius.
				</p>
			</div>

			<div className='container six-types'>
				<h1 className='title'>THE SIX TYPES</h1>

				<hr />

				<img src={WidgetReportV2} alt='' className={'w-2/3 mx-auto p-4'} />

				<p>
					<strong>WONDER</strong>: identifies the need for improvement or change
				</p>
				<p>
					<strong>INVENTION</strong>: confirms the importance of that need, and generates an idea or solution
				</p>
				<p>
					<strong>DISCERNMENT</strong>: assesses the merit and workability of the idea or solution
				</p>
				<p>
					<strong>GALVANIZING</strong>: generates enthusiasm and action around the idea or solution
				</p>
				<p>
					<strong>ENABLEMENT</strong>: initiates support and assists in the implementation of the idea or
					solution
				</p>
				<p>
					<strong>TENACITY</strong>: commits to ensuring that the idea or solution gets completed and that
					desired results are achieved
				</p>
			</div>
		</div>
	);
};

export default Overview;
