import React from 'react';
import AppButton from '../AppButton';
import { addToCart } from '../../services/CustomerService';

const DiscoverGenius = () => {
	return (
		<div className='discover-genius-section flex flex-col justify-center'>
			<div className='container mx-auto'>
				<div className='w-4/5 mx-auto'>
					<h3 className='homepage-heading text-primary'>
						Discover your working Genius today<span className='text-secondary'>!</span>
					</h3>
					<AppButton
						className={'btn btn-primary my-4'}
						text={'Buy the assessment now'}
						icon={'right'}
						onClick={() => addToCart('add_license', true)}
						id={'wg-livestream-buy-assessment-btn'}
					/>
				</div>
			</div>
		</div>
	);
};

export default DiscoverGenius;
