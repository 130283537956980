import React from 'react';
import UtilityService from '../../services/UtilityService';
import AppInput from '../AppInput';
import AppButton from '../AppButton';

const SingleEmailInput = ({
	name,
	min,
	limit,
	values,
	register,
	errors,
	setError,
	clearErrors,
	onChange,
	onRemove,
	isSubmitted,
	setValue,
}) => {
	React.useEffect(() => {
		if (values.length > limit) {
			const diff = values.length - limit;
			values.splice(values.length - diff, diff);
			onChange(values);
		} else {
			clearErrors(name);
			Object.keys(errors).map((key) => {
				setError(key, errors[key]);
			});
			let newValues = [...values];
			for (let i = newValues.length; i < limit; i++) {
				newValues.push({
					value: '',
					label: '',
					isValidEmail: false,
					fakeId: UtilityService.generateRandomString(30),
				});
			}
			onChange(newValues);
		}
	}, [values.length, limit, isSubmitted]);

	const handleChange = (i, event, fakeId) => {
		event.preventDefault();
		let newValues = [...values];
		let val = event.target.value;
		if (val) {
			val = val.trim().toLowerCase();
		}
		setValue(`tmp_email_${fakeId}`, val);
		newValues[i] = {
			value: val,
			label: val,
			isValidEmail: UtilityService.isValidEmail(val),
			fakeId: newValues[i].fakeId,
		};
		onChange(newValues);

		if (val) {
			clearErrors(`tmp_email_${fakeId}`);
		}

		if (val && UtilityService.isEmailDuplicated(newValues, val, i)) {
			setError(`tmp_email_${fakeId}`, {
				type: 'validate',
				message: `Email address already inserted`,
			});
		}

		if (!val && isSubmitted) {
			setError(`tmp_email_${fakeId}`, {
				type: 'required',
				message: 'is required',
			});
		}
	};

	const validateInput = (val, idx) => {
		if (!UtilityService.isValidEmail(val)) return 'should be a valid email';

		return !UtilityService.isEmailDuplicated(values, val, idx) || 'is already entered';
	};

	return (
		<>
			{values.map((email, idx) => {
				return (
					<div key={idx} className='flex flex-row justify-between'>
						<div className={'flex flex-1'}>
							<AppInput
								{...register(`tmp_email_${email.fakeId}`, {
									required: true,
									validate: (v) => validateInput(v, idx),
									maxLength: 100,
								})}
								containerClassName={'w-full'}
								label={`Email Address #${idx + 1}`}
								errors={errors}
								onChange={(e) => handleChange(idx, e, email.fakeId)}
								value={email.value || ''}
								id={`${idx}`}
								ttgMaxLength={250}
							/>
							{values.length > min && (
								<div className={'ml-2'} style={{ marginTop: '23px' }}>
									<AppButton
										remixIcon
										className='btn rounded-full bg-gray-100 h-100'
										icon='delete-bin-line'
										type='button'
										iconClass='text-red-500 text-lg font-medium leading-5 cursor-pointer'
										onClick={() => {
											let newValues = [...values];

											if (UtilityService.isEmailDuplicated(values, email.value, idx)) {
												clearErrors(
													`tmp_email_${
														values.filter(
															({ value }, index) => value === email.value && index !== idx
														)[0].fakeId
													}`
												);
											}

											onRemove(email.fakeId, Math.max(limit - 1, newValues.length - 1));
											newValues.splice(idx, 1);
											onChange(newValues);
										}}
									/>
								</div>
							)}
						</div>
					</div>
				);
			})}
			{errors[name] && errors[name].message && (
				<span className={'text-sm text-red-500'}>{errors[name].message}</span>
			)}
		</>
	);
};

export default SingleEmailInput;
