import { tools_printer, tools_video, tools_mic } from '../images';
import { wg_team_application } from '../files';
import config from '../constants/config';

export const ToolsData = [
	{
		'en': {
			iconUrl: tools_printer,
			title: 'Print Resources:',
			links: [
				{
					title: 'Working Genius Team Application Exercise',
					url: wg_team_application,
				},
				{
					title: 'Introduction to Working Genius Article',
					url: 'https://files.tablegroup.com/wp-content/uploads/2022/09/29021808/Introduction-to-The-Working-Genius-1.pdf',
				},
				{
					title: 'Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2022/09/29014215/Reviewing-Your-Results.pdf',
				},
				{
					title: 'Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/08/15101902/Pairings-Descriptions-with-Crave-and-Crush-V6-2024.pdf',
				},
				{
					title: 'Team Map',
					url: 'https://files.tablegroup.com/wp-content/uploads/2023/07/03023622/Fillable_Team_Map_20230629.pdf',
				},
				{
					title: 'Church Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/04/03083409/Rollout-Guide-Churches-2024-04-02.pdf'
				},
				{
					title: 'Corporate Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28063721/Working-Genius-Corporate-Rollout-Guide-2024-02-20.pdf'
				},
				{
					title: 'Students: Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070204/Student-Reviewing-Your-Results-3-19-2024.pdf'
				},
				{
					title: 'Students: Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070213/Student-Pairing-Descriptions-V8.pdf'
				},
				{
					title: 'Students: Discussion Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28093806/Working_Genius_Student_Discussion_Guide.pdf'
				}
			],
		},
		'es': {
			iconUrl: tools_printer,
			title: 'Print Resources:',
			links: [
				{
					title: 'Working Genius Team Application Exercise',
					url: wg_team_application,
				},
				{
					title: 'Introduction to Working Genius Article',
					url: 'https://files.tablegroup.com/wp-content/uploads/2022/09/29021808/Introduction-to-The-Working-Genius-1.pdf',
				},
				{
					title: 'Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28071446/Working-Genius-Reviewing-Your-Results_ESP.pdf',
				},
				{
					title: 'Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28071731/Working-Genius-Pairings-Overview_ESP.pdf',
				},
				{
					title: 'Team Map',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/08/14151557/Working-Genius-Using-a-Team-Map-Fillable-ESP.pdf',
				},
				{
					title: 'Church Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/04/03083409/Rollout-Guide-Churches-2024-04-02.pdf'
				},
				{
					title: 'Corporate Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28063721/Working-Genius-Corporate-Rollout-Guide-2024-02-20.pdf'
				},
				{
					title: 'Students: Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070204/Student-Reviewing-Your-Results-3-19-2024.pdf'
				},
				{
					title: 'Students: Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070213/Student-Pairing-Descriptions-V8.pdf'
				},
				{
					title: 'Students: Discussion Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28093806/Working_Genius_Student_Discussion_Guide.pdf'
				}
			],
		},
		'de': {
			iconUrl: tools_printer,
			title: 'Print Resources:',
			links: [
				{
					title: 'Working Genius Team Application Exercise',
					url: wg_team_application,
				},
				{
					title: 'Introduction to Working Genius Article',
					url: 'https://files.tablegroup.com/wp-content/uploads/2022/09/29021808/Introduction-to-The-Working-Genius-1.pdf',
				},
				{
					title: 'Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28071615/Working-Genius-Reviewing-Your-Resultsde.pdf',
				},
				{
					title: 'Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28071909/Working-Genius-Pairings-Overview_DE.pdf',
				},
				{
					title: 'Team Map',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/08/14151635/Working-Genius-Using-a-Team-Map-Fillable-DE.pdf',
				},
				{
					title: 'Church Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/04/03083409/Rollout-Guide-Churches-2024-04-02.pdf'
				},
				{
					title: 'Corporate Rollout Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28063721/Working-Genius-Corporate-Rollout-Guide-2024-02-20.pdf'
				},
				{
					title: 'Students: Reviewing Your Results',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070204/Student-Reviewing-Your-Results-3-19-2024.pdf'
				},
				{
					title: 'Students: Working Genius Pairings Overview',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28070213/Student-Pairing-Descriptions-V8.pdf'
				},
				{
					title: 'Students: Discussion Guide',
					url: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28093806/Working_Genius_Student_Discussion_Guide.pdf'
				}
			],
		}

	},
	{
		'en': {
			iconUrl: tools_video,
			title: 'Videos:',
			links: [
				{
					title: 'Livestream: Working Genius Launch Event',
					url: '/live',
				},
				{
					title: 'Pat Lencioni on the 6 Types of Working Genius',
					url: 'https://vimeo.com/467920771',
				},
				{
					title: 'Deep Dive Conversation with Pat Lencioni and Cody Thompson',
					url: 'https://vimeo.com/468187719',
				},
			],
		},
		'es': {
			iconUrl: tools_video,
			title: 'Videos:',
			links: [
				{
					title: 'Livestream: Working Genius Launch Event',
					url: '/live',
				},
				{
					title: 'Pat Lencioni on the 6 Types of Working Genius',
					url: 'https://vimeo.com/467920771',
				},
				{
					title: 'Deep Dive Conversation with Pat Lencioni and Cody Thompson',
					url: 'https://vimeo.com/468187719',
				},
			],
		},
		'de': {
			iconUrl: tools_video,
			title: 'Videos:',
			links: [
				{
					title: 'Livestream: Working Genius Launch Event',
					url: '/live',
				},
				{
					title: 'Pat Lencioni on the 6 Types of Working Genius',
					url: 'https://vimeo.com/467920771',
				},
				{
					title: 'Deep Dive Conversation with Pat Lencioni and Cody Thompson',
					url: 'https://vimeo.com/468187719',
				},
			],
		}

	},
	{
		'en': {
			iconUrl: tools_mic,
			title: 'Podcasts:',
			links: [
				{
					title: 'The Working Genius Podcast With Patrick Lencioni',
					url: `${config.base_url}/wg-podcast-post`,
				},
				{
					title: 'What is Your Genius?',
					url: `${config.ttg_url}/64-what-is-your-genius/`,
				},
				{
					title: 'No Joy, No Genius',
					url: `${config.base_url}/wg-podcast-post/2-no-joy-no-genius`
				},
				{
					title: 'Three Phases',
					url: `${config.base_url}/wg-podcast-post/5-the-three-phases`,
				},
				{
					title: 'Personality vs. Productivity',
					url: `${config.base_url}/wg-podcast-post/8-personality-vs-productivity `,
				},
				{
					title: 'Genius of Meetings',
					url: `${config.base_url}/wg-podcast-post/4-the-genius-of-meetings`,
				},
				{
					title: 'Your Genius and Your Team',
					url: `${config.ttg_url}/65-your-genius-and-your-team/`,
				},
				{
					title: 'Your Working Genius and Your Family',
					url: `${config.ttg_url}/73-your-working-genius-and-your-family/`,
				},
			],
		},
		'es': {
			iconUrl: tools_mic,
			title: 'Podcasts:',
			links: [
				{
					title: 'The Working Genius Podcast With Patrick Lencioni',
					url: `${config.base_url}/wg-podcast-post`,
				},
				{
					title: 'What is Your Genius?',
					url: `${config.ttg_url}/64-what-is-your-genius/`,
				},
				{
					title: 'No Joy, No Genius',
					url: `${config.base_url}/wg-podcast-post/2-no-joy-no-genius`
				},
				{
					title: 'Three Phases',
					url: `${config.base_url}/wg-podcast-post/5-the-three-phases`,
				},
				{
					title: 'Personality vs. Productivity',
					url: `${config.base_url}/wg-podcast-post/8-personality-vs-productivity `,
				},
				{
					title: 'Genius of Meetings',
					url: `${config.base_url}/wg-podcast-post/4-the-genius-of-meetings`,
				},
				{
					title: 'Your Genius and Your Team',
					url: `${config.ttg_url}/65-your-genius-and-your-team/`,
				},
				{
					title: 'Your Working Genius and Your Family',
					url: `${config.ttg_url}/73-your-working-genius-and-your-family/`,
				},
			],
		},
		'de': {
			iconUrl: tools_mic,
			title: 'Podcasts:',
			links: [
				{
					title: 'The Working Genius Podcast With Patrick Lencioni',
					url: `${config.base_url}/wg-podcast-post`,
				},
				{
					title: 'What is Your Genius?',
					url: `${config.ttg_url}/64-what-is-your-genius/`,
				},
				{
					title: 'No Joy, No Genius',
					url: `${config.base_url}/wg-podcast-post/2-no-joy-no-genius`
				},
				{
					title: 'Three Phases',
					url: `${config.base_url}/wg-podcast-post/5-the-three-phases`,
				},
				{
					title: 'Personality vs. Productivity',
					url: `${config.base_url}/wg-podcast-post/8-personality-vs-productivity `,
				},
				{
					title: 'Genius of Meetings',
					url: `${config.base_url}/wg-podcast-post/4-the-genius-of-meetings`,
				},
				{
					title: 'Your Genius and Your Team',
					url: `${config.ttg_url}/65-your-genius-and-your-team/`,
				},
				{
					title: 'Your Working Genius and Your Family',
					url: `${config.ttg_url}/73-your-working-genius-and-your-family/`,
				},
			],
		}
	},
];
