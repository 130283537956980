import React from 'react';
import { getQueryParams } from '../../../services/UtilityService';
import { useLocation } from '@reach/router';
import RosterService from '../../../services/RosterService';
import { navigate } from 'gatsby';
import Layout from '../../../components/Layout';

const Roster = () => {
	const location = useLocation();
	const queryParams = getQueryParams(location.search);

	React.useEffect(() => {
		RosterService.getAssessmentId(queryParams.id)
			.then((id) => {
				navigate(`/client/assessment/?id=${id}`, { replace: true });
			})
			.catch(() => {
				navigate(`/client/`, { replace: true });
			});
	}, [queryParams.id]);

	return (
		<Layout>
			<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		</Layout>
	);
};

export default Roster;
