import React from 'react';
import { resources_hero } from '../../images';

const Hero = () => {
	return (
		<div className={'resources-hero-container'}>
			<div className='flex flex-col md:flex-row container'>
				<div className='flex flex-col md:w-1/4 text-center md:text-left justify-center'>
					<h1
						className={
							'text-2xl lg:text-4xl text-primary-white font-normal my-4 text-center md:text-left leading-none'
						}
					>
						Working Genius <br />
						Resources
					</h1>
					<p className={'text-primary-white my-4 '}>
						Whether you're looking to use Working Genius with your team, in your organization, or even in
						your family, Pat Lencioni and the Table Group have created tools, articles, and podcasts that
						will help you leverage this tool in all aspects of your life.
					</p>
				</div>
				<div className='flex md:w-1/2'>
					<img src={resources_hero} alt='' className={'mx-auto object-contain'} />
				</div>
			</div>
		</div>
	);
};

export default Hero;
