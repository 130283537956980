import React from 'react';
import AppTabTitle from '../AppTabTitle';
import ToolkitFooter from './ToolkitFooter';

const ToolkitLayout = ({ children, title = 'The 6 Types of Working Genius: Toolkit' }) => {
	return (
		<div className={'wg-toolkit-layout'}>
			<AppTabTitle title={title} />
			{children}
			<ToolkitFooter />
		</div>
	);
};

export default ToolkitLayout;
