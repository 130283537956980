import React from 'react';
import AppSwitch from '../AppSwitch';
import EnablePermissionsForm from './EnablePermissionsForm';
import GiveAccessForm from './GiveAccessForm';
import PermissionsTable from './PermissionsTable';

export const renderPermissionOptions = () => {
    return (
        <>
            <p className='text-lg leading-10 font-normal text-gray-800 mt-6' >
                You have the following access options to offer to additional users. To view the list of all permissions, click the button below.
            </p>
            <ul className='text-lg leading-10 font-normal text-gray-800 list-disc ml-6'>
                <li><span className='font-medium'>Admin Access</span> “Has access to the full functionality of the account.”</li>
                <li><span className='font-medium'>Limited Access</span> “Has access to partial functionality of the account.”</li>
            </ul>
        </>
    )
}

const Permissions = ({ enabled, onSuccess }) => {
    const [isOpenPermissionsForm, setIsOpenPermissionsForm] = React.useState(false);
    const [enabledForm, setEnabledForm] = React.useState(enabled);
    return (
        <>
            <p className='text-lg leading-10 font-normal text-gray-800 my-4'>
                This feature will allow you to give users access to your account by using their own login
                credentials. They will receive an email notification and be able to access your account once they
                log in. Users who do not have a Working Genius account yet will need to create one once they
                receive this email.{' '}
                {!enabledForm && 'In order to use this feature, you have to enable the toggle first.'}
            </p>
            {!enabledForm ? <div className='flex flex-row py-6 border-t-1 border-b-1 border-gray-200 px-4'>
                <AppSwitch
                    value={isOpenPermissionsForm}
                    onChange={(val) => {
                        setIsOpenPermissionsForm(val)
                    }}
                />
                <div className='ml-4'>
                    <p className={'text-lg text-primary font-medium'}>
                        Enable this feature
                    </p>
                    <p className={'text-base text-primary font-normal'}>
                        By enabling this toggle, you will have the opportunity to use this feature.
                    </p>
                </div>
            </div> :
                <GiveAccessForm onSuccess={onSuccess} />
            }
            {renderPermissionOptions()}
            <PermissionsTable />
            <EnablePermissionsForm isOpen={isOpenPermissionsForm} onClose={() => setIsOpenPermissionsForm(false)} enablePermissions={setEnabledForm} />
        </>
    )
}

export default Permissions