import React from 'react';
import { report_hr } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Wonder tend to think and say:',
		items: [
			'"I just don\'t think this is the way it should be."',
			'"Couldn\'t this be better?"',
			'"Why do we settle for things to be like this?"',
			'"Maybe we should think about this a little more."',
		],
	},
	{
		title: 'What others think and say about people with the W genius:',
		items: [
			"\"She's always asking 'why' and 'why not'.\"",
			'"He\'s never quite happy with the way things are."',
			'"She\'s a deeper thinker than you know."',
			'"Uh, oh. He\'s off dreaming again."',
		],
	},
];

const Wonder = ({}) => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF WONDER</h2>

			<p>
				People with the Genius of Wonder naturally ponder the need for change in a given situation and can't
				help but question why things shouldn't or couldn't be better. They are both curious about and bothered
				by unmet needs, unfulfilled potential or unrealized aspirations. Moreover, they are quite capable of
				sitting in their wonder, idealistically contemplating an issue or problem for relatively long periods of
				time.
			</p>

			<p>
				This is one of the types of genius that few people - especially the people who have it - recognize as a
				genius at all. Few people recognize this natural tendency as a gift, to mention nothing of it being a
				required type of genius in the world of work. One of the reasons why the W genius is easily dismissed is
				that it often “happens” before any observable, concrete work begins. This is ironic because without it
				on a team or project, the needs of customers, employees, or the world might go unnoticed. In other
				words, W is often the very inspiration for starting a new project, program, initiative, or even a
				company.
			</p>

			<img src={report_hr} className='hr' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Wonder;
