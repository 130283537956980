import React from 'react';
import { useForm } from 'react-hook-form';
import AppInput from '../AppInput';
import AppModal from '../AppModal';
import AppButton from '../AppButton';
import ReCaptcha from '../ReCaptcha';
import AppIcon from '../AppIcon';
import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import { navigate } from 'gatsby';
import { emailRegex } from '../../services/UtilityService';

const EmailModal = ({ isOpen, setOpenModal }) => {
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			background: 'transparent',
		},
	};

	const [loading, setLoading] = React.useState(false);
	const captchaRef = React.useRef('captcha');

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch
	} = useForm();
	const onClose = () => setOpenModal(false);

	const navigateTo = (exists, email) => {
		const encodedEmail = encodeURIComponent(email);
		const encodedRedirect = encodeURIComponent('/client/dca-resources?type=dca');

		const loginUrl = `/client/login?redirect=${encodedRedirect}&type=dca-private-certification-registration&email=${encodedEmail}`;
        const registerUrl = `/client/register?redirect=${encodedRedirect}&type=dca-private-certification-registration&email=${encodedEmail}`;

		exists ? navigate(loginUrl, {replace: true}) : navigate(registerUrl, {replace: true});
	}

	const onSubmit = async (payload) => {
		const captcha = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		CustomerService.verifyEmail(payload?.email, captcha)
			.then((exists) => navigateTo(exists, payload?.email))
			.catch((e) => NotificationService.error('Error', e.message))
			.finally(() => setLoading(false));
	};

	return (
		<AppModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
			<div className={'dca-modal'}>
				<div className={'w-full'}>
					<span className={'flex flex-row justify-end pt-1'}>
						<AppIcon
							icon={'cancel'}
							className={'text-gray-700 text-2xl cursor-pointer'}
							onClick={onClose}
						/>
					</span>
				</div>

				<div className={'px-6'}>
					<div className={'flex flex-col items-center px-3 pb-2'}>
						<span className={'text-lg font-medium text-center pb-3'}>Access Pre-Work</span>
						<p className={'text-sm font-normal text-center'}>
							Please enter your email address to access your licenses and pre-work.
						</p>
					</div>

					<form
						className='w-full'
						autoComplete='autocomplete_off_hack_xfr4!k'
						onSubmit={handleSubmit(onSubmit)}
					>
						<AppInput
							{...register('email', {
								pattern: {
									value: emailRegex,
								},
								maxLength: 96,
							})}
							ttgMaxLength={96}
							label='Email address'
							errors={errors}
							onBlur={(e) => {
								setValue('email', e.target.value.trim());
							}}
						/>

						<AppButton
							type='submit'
							className={'btn btn-primary w-full my-6'}
							text={'Continue'}
							loading={loading}
							disabled={!watch('email')}
							id={'wg-verify-btn'}
						/>

						<ReCaptcha ref={captchaRef} />
					</form>
				</div>
			</div>
		</AppModal>
	);
};

export default EmailModal;
