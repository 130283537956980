import React from 'react';
import Select from 'react-select';
import AppLabel from './AppLabel';
import AppTooltip from './AppTooltip';
import renderErrorMessage from '../constants/ErrorMessages';

const AppDropdown = React.forwardRef(({ label, name, containerClassName, shouldShowErrorMessage = false, errors, ...props }) => {
	const renderContent = (
		<div className={`${containerClassName ? containerClassName : ''}`}>
			<AppLabel label={label} customClassName={`${shouldShowErrorMessage && errors && errors[name] ? 'text-error' : ''}`} />
			<Select {...props} id={'wg-select'} classNamePrefix='filter' className={`app-dropdown ${props.className}`} />
			<span className={`app-input-error-message ${shouldShowErrorMessage ? 'visible' : 'invisible'}`}>
				{errors && errors[name] && renderErrorMessage(errors[name].type, props, '', errors[name].message)}
			</span>
		</div>
	);

	return props.tooltipText ? (
		<AppTooltip
			content={() => <p className={'text-white text-center text-tiny font-normal px-3'}>{props.tooltipText}</p>}
			type={'small'}
		>
			{renderContent}
		</AppTooltip>
	) : (
		renderContent
	);
});

export default AppDropdown;
