import React from 'react';

const AppVideoModal = ({ videoUrl }) => (
	<div className={'relative bg-transparent'} style={{ width: '900px', maxWidth: '100%' }}>
		<div className={'absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-gray-300'}>
			<div className={'flex flex-1 justify-center content-center items-center h-full'}>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		</div>
		<div
			style={{
				position: 'relative',
				padding: '56.25% 0 0 0',
			}}
		>
			<iframe
				id='player-iframe'
				title='video-player-iframe'
				src={videoUrl}
				allow='autoplay; fullscreen'
				allowFullScreen
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
				}}
			/>
		</div>
	</div>
);

export default AppVideoModal;
