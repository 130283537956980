import React from 'react';
import AppButton from '../../AppButton';
import { certification_broll, certification_mp4, certified_badge } from '../../../images';

const CertificationHero = ({ redirectTo }) => {
	return (
		<div className='flex flex-col-reverse lg:flex-row'>
			<div className='flex-1 hidden lg:flex justify-center p-8 lg:p-0 relative'>
				<video aria-hidden={true} autoPlay={true} muted={true} loop={true} id='certification-hero-video' className='object-cover'>
					<source src={certification_broll} type='video/webm' />
					<source src={certification_mp4} type='video/mp4' />
				</video>
				<img className='absolute bottom-0 left-0 p-12 lg:p-8' src={certified_badge} alt='Certfied' width='200' />
			</div>
			<div className='flex-1 text-center lg:text-left px-12 pt-12 pb-8 flex flex-col justify-center items-center lg:items-start'>
				<h1 className='text-3xl text-primary font-semibold mb-5'>
					Become a Working Genius Expert
				</h1>
				<p className='text-lg text-primary font-normal mb-5'>
					If you want to teach and apply Working Genius - Certification is for you.
				</p>
				<p className='text-lg text-primary font-normal mb-5'>
					Gain confidence and experience in this <b className='font-semibold'>two-day virtual session</b> designed
					by Patrick Lencioni and The Table Group.
				</p>
				<div className='flex flex-col mb-5'>
					<p className='text-lg text-primary font-semibold'>Every session is:</p>
					<ul className='certification-ul'>
						<li>Interactive</li>
						<li>Fast-paced</li>
						<li>Practical</li>
					</ul>
				</div>
				<p className='text-lg text-primary font-normal mb-5'>
					You'll walk away with real tools and resources after two engaging
					sessions with our Master Facilitators to help you fully-leverage
					Working Genius in your context.
				</p>
				<AppButton
					className={'btn btn-primary w-56 self-center'}
					onClick={redirectTo}
					text={'Get certified'}
					id={'wg-certification-register-now-btn'}
				/>
			</div>
		</div>
	);
};

export default CertificationHero;
