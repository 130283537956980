import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';

const AWAIT_SECONDS = 10;

const NotFoundPage = () => {
	useEffect(() => {
		const redirectionTimer = setTimeout(() => navigate('/'), AWAIT_SECONDS * 1000);
		return () => {
			clearTimeout(redirectionTimer);
		};
	}, []);

	return (
		<Layout title={'File Not Found | The 6 Types of Working Genius'}>
			<div className='container mx-auto p-6'>
				<h2 className='text-2xl md:text-5xl text-primary font-bold my-6'>Page Not Found</h2>
				<p className='text-lg text-primary font-normal mb-4'>
					We’re sorry. The page you requested cannot be found. The address may have been typed incorrectly or
					the page may have been moved.
				</p>
				<p className='text-lg text-primary font-normal mb-4'>
					You will be automatically redirected to the 6 Geniuses homepage after ten seconds. If the problem
					persists, please contact our office at 925.299.9700 or via email to{' '}
					<a href='mailto:workinggenius@tablegroup.com'>workinggenius@tablegroup.com</a>.
				</p>
				<p className='text-lg text-primary font-normal mb-4'>Thank you.</p>
			</div>
		</Layout>
	);
};

export default NotFoundPage;
