import React from 'react';
import AppScrollableNavigation from '../../components/ScrollableNavigation';
import AppFAQ from '../../components/AppFAQ';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import Scrollspy from 'react-scrollspy';
import {
	AssessmentQuestions,
	PurchasingWorkingGenius,
	GettingStarted,
	GroupAndIndividual,
	StudentAssessmentQuestions,
	PreCertification,
} from '../../constants/FaqData';

const FaqNavigation = () => {
	return (
		<div className={'faq-navigation'}>
			<p className={'title'}>Navigation:</p>
			<nav id='faqnav-id'>
				<Scrollspy
					className='faqnav-link-list flex flex-col'
					items={[
						'faq-assessment-questions',
						'faq-purchasing-wg',
						'faq-getting-started',
						'faq-group-individual',
						'faq-student-assessment',
						'faq-pre-certification',
					]}
					currentClassName='active'
					offset={-60}
				>
					<a className='item' href='#faq-assessment-questions' id={'wg-faq-assessment-questions-link'}>
						<span className='faqnav-link-text'>Assessment Questions</span>
					</a>
					<a className='item' href='#faq-purchasing-wg' id={'wg-faq-purchasing-wg-link'}>
						<span className='faqnav-link-text'>Purchasing the Assessment</span>
					</a>
					<a className='item' href='#faq-getting-started' id={'wg-faq-getting-started-link'}>
						<span className='faqnav-link-text'>Getting Started (after the purchase)</span>
					</a>
					<a className='item' href='#faq-group-individual' id={'wg-faq-group-individual-link'}>
						<span className='faqnav-link-text'>Group and Individual Assessments</span>
					</a>
					<a className='item' href='#faq-student-assessment' id={'wg-faq-student-assessment-link'}>
						<span className='faqnav-link-text'>Student Assessment Questions</span>
					</a>
					<a className='item' href='#faq-pre-certification' id={'wg-faq-pre-certification-link'}>
						<span className='faqnav-link-text'>Pre-Certification FAQ</span>
					</a>
				</Scrollspy>
			</nav>
		</div>
	);
};

const Faq = () => {
	return (
		<WorkshopLayout
			title={'Frequently Asked Questions | The 6 Types of Working Genius'}
			description={'Read our Frequently Asked Questions about the 6 Types of Working Genius'}
		>
			<h1 className='hidden'>The 6 Types of Working Genius Frequently Asked Questions</h1>
			<AppScrollableNavigation
				containerClassName={'faq-navigation-container'}
				wrapperClassName={'container pt-8'}
				navigation={<FaqNavigation />}
			>
				<div id={'faq-assessment-questions'}>
					<AppFAQ
						items={AssessmentQuestions}
						title={'Assessment Questions'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
				<div id={'faq-purchasing-wg'}>
					<AppFAQ
						items={PurchasingWorkingGenius}
						title={'Purchasing the Assessment'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
				<div id={'faq-getting-started'}>
					<AppFAQ
						items={GettingStarted}
						title={'Getting Started (after the purchase)'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
				<div id={'faq-group-individual'}>
					<AppFAQ
						items={GroupAndIndividual}
						title={'Group and Individual Assessments'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
				<div id={'faq-student-assessment'}>
					<AppFAQ
						items={StudentAssessmentQuestions}
						title={'Student Assessment Questions'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
				<div id={'faq-pre-certification'}>
					<AppFAQ
						items={PreCertification}
						title={'Pre-Certification FAQ'}
						numbered={false}
						closedQuestions={true}
					/>
				</div>
			</AppScrollableNavigation>
		</WorkshopLayout>
	);
};

export default Faq;
