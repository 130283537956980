import React, { useState } from 'react';
import StepWizard from 'react-step-wizard';
import Layout from '../../../components/Layout';
import { scrollToTop } from '../../../services/UtilityService';
import { AssessmentInformation, ConfirmationStep, SelectType } from '../../../components/assessments/setup';
import { AssessmentModeEnum } from '../../../constants/AssessmentsData';

const Setup = () => {
	const [mode, setMode] = useState(AssessmentModeEnum.STANDARD);
	const [shareReport, setShareReport] = useState(false);
	const onStepChange = () => {
		scrollToTop();
	};

	return (
		<Layout containerClass={'overflow-x-hidden'}>
			<StepWizard isLazyMount={true} onStepChange={onStepChange}>
				<SelectType setMode={setMode} />
				<AssessmentInformation mode={mode} setShareReport={setShareReport}/>
				<ConfirmationStep shareReport={shareReport}/>
			</StepWizard>
		</Layout>
	);
};

export default Setup;
