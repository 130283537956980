import config from '../constants/config';
export const NavBarItemDisplay = {
	All: 'all',
	Web: 'web',
	Mobile: 'mobile',
};

const ApplicationGuideMenuItems = (enabled) =>
	enabled
		? [
			{
				link_to: '/application-guide-product',
				link_name: 'Application Guide',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-application-guide-item',
				display: NavBarItemDisplay.All,
			},
		]
		: [];

const TraineeMenuItems = (enabled) =>
	enabled
		? [
			{
				link_to: '/client/trainee/',
				link_name: 'Trainees',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-trainee-item',
				display: NavBarItemDisplay.All,
			},
		]
		: [];

const CertificationMenuItems = (enabled, alumni = false) => [
	{
		link_to: enabled ? '' : '/client/certification/',
		link_name: 'Certification',
		class_name: 'nav-link',
		active_class_name: 'active-link',
		id: 'wg-navbar-certification-item',
		display: NavBarItemDisplay.All,
		...(enabled
			? {
				sublinks: [
					{
						link_to: '/client/certification/',
						link_name: 'Certification Resources',
						class_name: 'nav-sublink',
						active_class_name: 'active-link',
						id: 'wg-navbar-certification-resources-item',
					},
					{
						link_to: 'https://working-genius-facilitator-network.mn.co/feed',
						link_name: 'Mighty Networks',
						class_name: 'nav-sublink',
						active_class_name: 'active-link',
						id: 'wg-navbar-mighty-networks-item',
						target: '_blank',
					},
					{
						link_to: '/client/certification/prework/',
						link_name: 'Prework Resources',
						class_name: 'nav-sublink',
						active_class_name: 'active-link',
						id: 'wg-navbar-certification-prework-item',
					},
					config.alumni_intensive_feature ? {
						link_to: alumni ? '/client/certification/#alumni_intensives' : '/client/alumni-intensives/',
						link_name: 'Alumni Intensives',
						class_name: 'nav-sublink',
						active_class_name: 'active-link',
						id: 'wg-navbar-alumni-intensives-item',
					} : null
				],
			}
			: {}),
	},
];

const DefaultMenuItems = (enabled, ttgOrders, ttgTools, switchData) =>
	enabled
		? [
			{
				link_to: '/client/',
				link_name: 'Assessments',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-assessments-item',
				display: NavBarItemDisplay.All,
			},
			{
				link_to: '/client/tools/',
				link_name: 'Tools',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-tools-item',
				display: NavBarItemDisplay.All,
			},
			{
				link_to: '/store',
				link_name: 'Store',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-tools-item',
				display: NavBarItemDisplay.Mobile,
			},
			{
				link_to: '/client/account/',
				link_name: 'Account',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-account-item',
				display: NavBarItemDisplay.Mobile,
			},
			{
				link_to: '/client/licenses/',
				link_name: 'Licenses',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-licenses-item',
				display: NavBarItemDisplay.Mobile,
			},
			...(switchData.enabled
				? [
					{
						link_name: switchData.link_name,
						id: 'wg-navbar-switch-account',
						display: NavBarItemDisplay.Mobile,
						onClick: switchData.onClick,
					},
				]
				: []),
			{
				link_to: '/client/request-assessments',
				link_name: 'Request for Assessments',
				id: 'wg-navbar-request-assessment',
				class_name: 'nav-link',
				display: NavBarItemDisplay.Mobile,
			},
			{
				link_to: '/client/permissions',
				link_name: 'Permissions',
				id: 'wg-navbar-permissions',
				class_name: 'nav-link',
				display: NavBarItemDisplay.Mobile,
			},
			...(ttgTools
				? [
					{
						link_name: 'Tablegroup Tools',
						id: 'wg-navbar-tablegroup-tools-item',
						display: NavBarItemDisplay.Mobile,
						tablegroup_url: 'select',
					},
				]
				: []),
			...(ttgOrders
				? [
					{
						link_name: 'Orders',
						display: NavBarItemDisplay.Mobile,
						tablegroup_url: 'client/account/orders',
					},
				]
				: []),
			{
				link_to: '/client/assessment/contact/',
				link_name: 'Contact Us',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-contact-us-item',
				display: NavBarItemDisplay.Mobile,
			},
			{
				link_to: '',
				link_name: 'Logout',
				class_name: 'nav-link',
				active_class_name: '',
				logout: true,
				id: 'wg-navbar-logout-item',
				display: NavBarItemDisplay.Mobile,
			},
		]
		: [];

export const MenuItems = (logged, certification, trainee, applicationGuide, ttgOrders, ttgTools, switchData, alumni = false) =>
	!logged
		? [
			{
				link_to: 'mailto:support@workinggenius.com',
				link_name: 'Contact Us',
				class_name: 'nav-link',
				active_class_name: 'active-link',
				id: 'wg-navbar-contact-us-item',
				display: NavBarItemDisplay.All,
			},
		]
		: [
			...ApplicationGuideMenuItems(applicationGuide),
			...TraineeMenuItems(trainee),
			...CertificationMenuItems(certification, alumni),
			...DefaultMenuItems(logged, ttgOrders, ttgTools, switchData),
		];
