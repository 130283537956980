import ApiService from './ApiService';

export const getTraineeResources = () => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/trainee_resources`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export default {
	getTraineeResources,
};
