import React from 'react';
import { Link } from 'gatsby';
import { wg_home_certification, request_graphic, wg_team_application_guide } from '../../images';

const HomeTeam = () => {
	return (
		<div className='home-team-section'>
			<div className='container'>
				<h2 className='team-title'>Working Genius &#38; Your Team</h2>
				<hr />
				<div className='flex flex-col-reverse items-center md:flex-row lg:px-16 py-8'>
					<div className='flex-1'>
						<img src={request_graphic} alt='Working Genius Workshop' />
					</div>
					<div className='flex-1 flex flex-col justify-center'>
						<div className='p-8 md:pl-12'>
							<h3 className='text-2xl text-primary font-semibold pb-4 lg:pb-8'>
								Request a Working Genius Workshop
							</h3>
							<p className='home-team-p'>
								A Table Group Master Facilitator guides your team through an interactive workshop to
								help you leverage and apply all the facets of Working Genius.
							</p>
							<Link to={'/workshop'} className={'home-team-link'} id={'wg-homepage-inquire-link'}>
								Inquire today
							</Link>
						</div>
					</div>
				</div>
				<div className='flex flex-col items-center md:flex-row lg:px-16 py-4'>
					<div className='flex-1 flex flex-col justify-center'>
						<div className='p-8 md:pl-0'>
							<h3 className='text-2xl text-primary font-semibold pb-4 lg:pb-8'>
								Get Certified in Working Genius
							</h3>
							<p className='home-team-p'>
								Become a Certified Working Genius Facilitator with Patrick Lencioni and the Table Group.
							</p>
							<Link
								to={'/client/certification'}
								className={'home-team-link'}
								id={'wg-homepage-learnm-more-link'}
							>
								Learn more
							</Link>
						</div>
					</div>
					<div className='flex-1'>
						<img src={wg_home_certification} alt='Working Genius Certification' />
					</div>
				</div>
				<div className='flex flex-col-reverse items-center md:flex-row lg:px-16 py-8 mt-0 md:mt-10'>
					<div className='flex-1'>
						<img src={wg_team_application_guide} alt='Working Genius TAG' />
					</div>
					<div className='flex-1 flex flex-col justify-center'>
						<div className='p-8 md:pl-12'>
							<h3 className='text-2xl text-primary font-semibold pb-4 lg:pb-8'>
								Working Genius Team Application Guide
							</h3>
							<p className='home-team-p'>
								The Team Application Guide was designed to help your team adopt Working Genius.
							</p>
							<Link
								to={'/teamapplicationguide'}
								className={'home-team-link'}
								id={'wg-homepage-inquire-link'}
							>
								Learn More
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeTeam;
