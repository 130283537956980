import React from 'react';
import { Link } from 'gatsby';
import { logo, resources_dropdown_header_image, widget_bg_about_dropdown, wg_students_logo } from '../../images';

const HomepageHeader = ({ workshopItem = true, studentLogo = false }) => {
	return (
		<div className={'wg-main-layout-header'}>
			<div className='content'>
				<div className='nav-header'>
					{studentLogo ? <Link to='/'>
						<img src={wg_students_logo} alt='Working Genius' />
					</Link> : <Link to='/'>
						<img src={logo} alt='Working Genius' />
					</Link>}
				</div>
				<div className={'nav'}>
					<div className='dropdown'>
						<Link to={'/about'} className={`nav-item dropbtn`} id={'wg-homepage-about-wg-link-id'}>
							About
						</Link>
						<div className='dropdown-content'>
							<div className='container border-t-1 flex flex-row py-8'>
								<div className='flex-1 flex items-center justify-center px-6 border-r-1'>
									<p className='text-primary text-lg font-normal text-left px-4'>
										Working Genius is the fastest and simplest way to discover your gifts and
										transform your work. Find out more today.
									</p>
								</div>
								<div className='flex-1 flex flex-col justify-center px-8 text-left'>
									<Link to={'/about#wg-about-how-it-started-id'} className='dropdown-nav-item'>
										How it started
									</Link>
									<Link to={'/about#wg-about-6-types-id'} className='dropdown-nav-item'>
										The 6 types
									</Link>
									<Link to={'/about#wg-about-the-book-id'} className='dropdown-nav-item'>
										The book
									</Link>
									<Link
										to={'/about#wg-about-what-makes-it-different-id'}
										className='dropdown-nav-item'
									>
										What makes it different
									</Link>
								</div>
								<div className='flex-initial pr-4'>
									<img width='400' src={widget_bg_about_dropdown} alt='Working Genius' />
								</div>
							</div>
						</div>
					</div>
					<Link to={'/about/assessment'} className={`nav-item`} id={'wg-homepage-book-id'}>
						The Assessment
					</Link>
					<Link to={'/client/certification/'} className={`nav-item`} id={'wg-homepage-get-certified-id'}>
						Get certified
					</Link>
					{!!workshopItem && (
						<Link to={'/workshop'} className={`nav-item`} id={'wg-homepage-workshop-id'}>
							Request a workshop
						</Link>
					)}
					<div className='dropdown'>
						<Link to={'/resources'} className={`nav-item dropbtn`} id={'wg-homepage-reources-link-id'}>
							Resources
						</Link>
						<div className='dropdown-content'>
							<div className='container border-t-1 flex flex-row py-8'>
								<div className='flex-1 flex items-center justify-center px-6 border-r-1'>
									<p className='text-primary text-lg font-normal text-left px-2'>
										Whether you are looking to use Working Genius with your team, in your
										organization or even in your family, Pat Lencioni and the Table Group have
										created tools, articles and podcasts that will help you leverage this tool in
										all aspects of your life.
									</p>
								</div>
								<div className='flex-1 flex flex-col justify-center px-8 text-left'>
									<Link className='dropdown-nav-item' to='/resources'>
										TEAM RESOURCES
									</Link>
									<Link className='dropdown-nav-item' to='/resources#podcasts'>
										FEATURED PODCASTS
									</Link>
									<Link className='dropdown-nav-item' to='/resources#additional-resources'>
										ADDITIONAL RESOURCES
									</Link>
								</div>
								<div className='flex flex-col justify-center pr-4'>
									<img width='400' src={resources_dropdown_header_image} alt='Working Genius' />
								</div>
							</div>
						</div>
					</div>
					<Link to={'/wg-podcast-post'} className={`nav-item`} id={'wg-homepage-get-certified-id'}>
						PODCAST
					</Link>

				</div>
			</div>
		</div>
	);
};

export default HomepageHeader;
