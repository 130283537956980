import React from 'react';

const GeneratedMessage = ({ createTeamMap, resetTeamMap }) => {
	return (
		<div className='TeamMapBuilder__generatedMessage'>
			<p>
				Your download will begin shortly. If you do not see a downloaded file, please{' '}
				<a className='btn-underline' onClick={() => createTeamMap()} id={'wg-teammap-download-link'}>
					click here
				</a>
			</p>
			<p className='mt-10'>
				<a className='btn-link-secondary' onClick={() => resetTeamMap()} id={'wg-teammap-reset-link'}>
					click here to reset
				</a>
			</p>
		</div>
	);
};

export default GeneratedMessage;
