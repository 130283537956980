import React from 'react';
import { report_hr } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Invention tend to think and say:',
		items: [
			'"Hey, I\'ve got an idea."',
			'"What about this?"',
			'"Here\'s something that might work for you."',
			'"Please let me try to come up with a solution for that."',
		],
	},
	{
		title: 'What others think and say about people with the I genius:',
		items: [
			'"He\'s a genius."',
			'"Where does she come up with these ideas?"',
			'"He always has a new idea or solution, even if we don\'t ask for one."',
			'"She\'s so creative. She\'ll think of something new."',
		],
	},
];

const Invention = () => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF INVENTION</h2>

			<p>
				People with the Genius of Invention thrive on creativity. They are naturally drawn to coming up with
				ideas, seemingly out of nowhere, to solve the problems or address the needs that they or others
				identify. They are most frequently identified as "geniuses" in the most conventional way that the word
				is used, because what they do is so central to (though not completely sufficient for) discovery and
				innovation.
			</p>

			<p>
				It seems that this particular genius is more common than one might imagine. That's because invention is
				certainly not limited to technical solutions or scientific discovery but can apply to the generation of
				new ideas around almost anything, from creating a new program for customers, designing a better office,
				or even planning a unique family vacation. Having said that, it is limited to a certain kind of
				creativity that involves originality, and without the I genius, organizations and teams suffer from a
				lack of novel thinking and innovation.
			</p>

			<img src={report_hr} className='hr' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Invention;
