import React from 'react';
import { navigate } from 'gatsby';

import RequestAssessmentService from '../../../services/RequestAssessment';
import NotificationService from '../../../services/NotificationService';
import SelectEmails from '../../../components/request-assessment/SelectEmails';
import SearchEmails from '../../../components/request-assessment/SearchEmails';

const RequestAssessment = ({ step, setStep }) => {
	const [loading, setLoading] = React.useState(false);
	const [completedAssessments, setCompletedAssessments] = React.useState([]);
	const [inCompletedAssessments, setInCompletedAssessments] = React.useState([]);

	const save = (payload) => {
		setLoading(true);
		const emails = payload.emails.map((email) => email.value);
		RequestAssessmentService.getTakersList(emails)
			.then((data) => {
				setCompletedAssessments(data.completed);
				setInCompletedAssessments(
					data.incomplete.map((el, i) => ({
						id: i,
						email: el.email,
					}))
				);
				setStep(1);
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			})
			.finally(() => setLoading(false));
	};

	const request = (group, selected) => {
		setLoading(true);

		RequestAssessmentService.requestAssessments(selected, group)
			.then(() => {
				NotificationService.info(
					'Success',
					`Your request has been sent to the assessment taker${selected.length === 1 ? '' : 's'}`
				);
				navigate('/client/request-assessments?tab=history');
			})
			.catch(() => {
				NotificationService.error('Oops', 'Something went wrong, please try again');
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className='mt-4 pt-2'>
			<h1 className='text-primary text-4xl font-semibold mt-6'>Request Working Genius Assessment Results</h1>
			<p className='text-base leading-6 font-medium text-gray-600 mt-4'>
				Search for assessment results by entering the email address(es) of the individual(s) in the fields
				below.
			</p>

			{step === 0 && <SearchEmails save={save} loading={loading} />}
			{step === 1 && (
				<SelectEmails
					loading={loading}
					completedAssessments={completedAssessments}
					incompleteAssessments={inCompletedAssessments}
					onContinue={request}
				/>
			)}
		</div>
	);
};

export default RequestAssessment;
