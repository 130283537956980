import React from 'react';
import { person_attending_certification } from '../../../images';

const AboutCertification = () => {
    return (
        <div className='container'>
            <div className='flex flex-col md:flex-row mt-4 py-6'>
                <div className='flex flex-1 flex-col justify-center mx-2 lg:mx-12'>
                    <h2 className='text-3xl md:text-4xl text-primary leading-none mb-6'>
                        About the Certification
                    </h2>
                    <p className='text-primary text-base mb-6'>
                        Learn first-hand how to leverage the Working Genius and help companies,
                        individuals, and teams tap into one another's talents and passions, strategically
                        staff projects and programs, significantly improve productivity and morale, and reach full potential.
                    </p>
                    <ul className='certification-ul md:pl-6'>
                        <li>Two day, virtual session</li>
                        <li>$2,495</li>
                        <li>Fast-paced and interactive</li>
                    </ul>
                </div>
                <div className='flex flex-1 m-8'>
                    <img src={person_attending_certification} className={'object-contain'} alt='' />
                </div>
            </div>
        </div>
    );
};

export default AboutCertification;
