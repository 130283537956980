import React from 'react';

const MarriageIsALotOfWork = () => {
	return (
		<>
			<div className={'resource-article-content'}>
				<p>
					The title of this article is not meant to be cynical in the slightest. It's just a statement of
					fact. So much of what we do in the course of our daily married lives would qualify as “work.”
					Preparing meals. Paying bills. Navigating parenthood. Planning vacations. Financial planning.
					Coaching teams. Doing yardwork.
				</p>
				<p>
					In the most practical sense, marriage really is a lot of work. And so, in order to make our
					marriages vibrant and healthy, we need to address the idea of work compatibility.
				</p>
				<p>
					Compatibility is nothing new. When we get married, we think and talk a lot about it. Spiritual and
					religious compatibility is important, so that we can be equally yoked. We talk about personality
					compatibility in terms of extraversion and introversion and other similar attributes.
				</p>
				<p>
					But what about how we fit with our husband or wife in the field of ‘getting things done?' I'm
					convinced that it is one of the greatest sources of stress and conflict in marriage, creating
					unnecessary guilt, judgement and even resentment. I'm happy to say that there is a solution.
				</p>
				<p>
					A few years ago, I inadvertently came up with a model for understanding the gifts that God gave me
					as they relate to work, as well as the gifts that He didn't give me. It eventually developed into a
					universal model and assessment that we refer to as The Six Types of Working Genius.
				</p>
				<p>
					There are six required activities or skills involved in any work endeavor, whether that work is
					taking place in an office, a school, a church or at home. For everyone I've ever met, two of those
					six skills are sources of joy and energy and natural talent. We call these our Working Geniuses. Two
					others are sources of misery, and they drain us of our joy and energy. We call these our Working
					Frustrations. Finally, lying in the middle of these two extremes are our Working Competencies,
					things we can do fairly well, but which don't necessarily feed us the way our working geniuses do.
				</p>
				<p>
					Let's quickly summarize the Six Types of Working Genius so that the rest of this article makes more
					sense.
				</p>
				<p>
					<strong>The Genius of Wonder</strong> involves identifying potential, asking questions, pondering
					issues and observing the world around us, all in the name of curiosity and reflection. Every
					company, every project, every endeavor begins because someone says “maybe there's a better way” or
					“is this the best we can do?” or “does anyone else feel like something's not quite right?” Some
					people do this quite naturally, and do it well, even if the people around them don't realize that it
					represents a real genius.
				</p>
				<p>
					<strong>The Genius of Invention</strong> is all about coming up with new ideas and solutions. People
					with this genius are drawn toward origination, creativity and ingenuity in the truest sense of those
					words, even with little direction and context. Though every type is a genius, these are the people
					who are most often referred to as ‘geniuses' because many of their ideas seem to come out of thin
					air.
				</p>
				<p>
					<strong>The Genius of Discernment</strong> is related to instinct, intuition, and uncanny judgment.
					People with this genius have a natural ability to assess an idea or situation, even without a lot of
					data or expertise. Using pattern recognition and gut feel, they are able to provide valuable advice
					and feedback around most subjects in a way that transcends their levels of specific knowledge or
					information.
				</p>
				<p>
					<strong>The Genius of Galvanizing</strong> is about rallying, motivating and provoking people to
					take action around an idea or an initiative. People with this genius are naturally inclined to
					inspire and enlist others to get involved in an endeavor. They don't mind persuading people to
					rethink or change their plans in order to embark on something worthwhile.
				</p>
				<p>
					<strong>The Genius of Enablement</strong> involves providing people with support and assistance in
					the way that it is needed. People with this genius are adept at responding to the needs of others
					without conditions or restrictions. They are naturally inclined to help others accomplish their
					goals, and even anticipating what that person might need before they ask. People with the genius of
					Enablement are often unaware that this is a genius at all.
				</p>
				<p>
					<strong>The Genius of Tenacity</strong> is about the satisfaction of pushing things across the
					finish line to completion. People with this genius are not only capable of, but naturally inclined
					to, finish projects and ensure that they are completed according to specification. They get energy
					pushing through obstacles and seeing the impact of their work, and they find joy in crossing tasks
					off their list and getting closure.
				</p>
				<p>
					Okay, back to marriage. It doesn't take much imagination to understand that the working geniuses of
					a husband and wife are going to have a profound impact on how they manage their lives, run their
					family, and go about enjoying themselves. If spouses don't know or understand or appreciate one
					another's areas of genius – and frustration! – they are going to have a hard time giving one another
					grace, and instead they'll fall into the trap of judgment and guilt.
				</p>
				<p>Let me provide an example. I'll start with myself, and my wife, Laura.</p>
				<p>
					Since there are only six Working Geniuses, the most that any two people in a marriage can cover
					collectively are four. That assumes that they each have different geniuses. Between Laura and me, we
					share a genius, so we only have three.
				</p>
				<p>
					Laura’s geniuses are Wonder and Invention. These are the first two geniuses in the sequence, which
					means they occur relatively early in the process of work. Laura likes to ponder big things and ask
					possibility-oriented questions, and that is one of the things I loved about her when we met. She
					also likes to come up with new ideas, which is particularly fun for us.
				</p>
				<p>
					That’s because my geniuses are Invention and Discernment. I also love to come up with new ideas, and
					it’s fun to do that with Laura. We have invented games, written songs, conceived of businesses and
					books together. I also like to evaluate ideas or plans, applying my intuition and instincts to
					determine whether an idea would be workable or not.
				</p>
				<p>
					Okay, so far so good. Laura and I have the first three geniuses covered. The problem is, the
					remaining three are not.
				</p>
				<p>
					What’s worse, both of us share one particular Working Frustration: Tenacity. In a nutshell, that
					means that neither Laura nor I get satisfaction from finishing things. We lose interest in projects
					and ideas after the initial phases are over. Managing details and fighting through obstacles are not
					God-given talents for us. Which isn’t a big thing in marriage, unless of course you own a home, have
					children, have to pay bills, and do a hundred other detail-oriented things that must be completely
					finished, and worse yet, on time.
				</p>
				<p>
					Unfortunately for Laura, she took on the bulk of the work relating to administration of our family,
					and it was drudgery for her. And it is absolutely understandable and inevitable that she doesn’t
					enjoy it. It drains her, and prevents her from doing something that suits her gifts and that would
					allow her to flourish. And Laura will be the first to tell you that she isn’t great at all the
					details.
				</p>
				<p>
					Now, not understanding the concept of genius and frustration, I was often annoyed by the inevitable
					dropped balls, late fees, and scheduling surprises. I am not proud of this. It became a regular
					source of tension and conflict for us.
				</p>
				<p>Fortunately, it only went on for 28 years.</p>
				<p>
					Yes, without any context around the nature of work and our respective geniuses and frustrations, it
					was just too easy for judgment and guilt to take hold. To be fair, I had been suggesting that Laura
					get help for the majority of those 28 years. But each time I mentioned it, she refused, feeling that
					it would be a sign of failure or weakness. Guilt.
				</p>
				<p>
					Today, things are different. Knowing that neither she (nor I) has been given the gift of Tenacity,
					she is finally welcoming outside help. And I am able to give her the grace she deserves when issues
					come up, having considerably more empathy for her situation.
				</p>
				<p>
					It’s worth noting that Laura and I both recognize that all spouses and parents have to do things
					that we don’t enjoy. That is part of parenting and family life. But we also realize that when we
					find ourselves constantly having to do the very things that frustrate us, it is a recipe for
					burn-out.
				</p>
				<p>So what is a married couple to do with Working Genius? Here are a few important suggestions.</p>
				<p>
					First, take the assessment (it takes ten minutes) and review the results together. Take a moment to
					digest the ramifications of what you learn about yourselves and one another.
				</p>
				<p>
					Second, look back on your marriage and apply the insights of Working Genius to the best and most
					difficult challenges of your lives together. How does your new understanding of your geniuses,
					competencies and frustrations explain what happened? This should be an enlightening and liberating
					experience.
				</p>
				<p>
					Third, identify opportunities to adjust roles and responsibilities as co-parents and co-workers in
					the family to better fit your geniuses, as much as possible. And if you’re in a position to do so,
					consider outsourcing tasks or projects that frustrate, either by seeking the help of friends or
					hiring outsiders. More importantly, lean into activities that bring you energy and joy.
				</p>
				<p>
					Fourth, consider using Working Genius to better understand your children, and your parenting styles.
					How have your respective strengths and weaknesses impacted your children, for better or for worse?
					Thank God for it all. Don’t beat yourselves up for the inevitable struggles you’ve had, especially
					as they relate to having to work in your areas of frustration. As a friend of mine likes to say,
					accept the idea that our children are shaped by our inevitable limitations, and that they are
					resilient.
				</p>
				<p>
					Fifth and finally, take the time to review your results, and those of your children, as a family.
					Allow everyone to consider how their geniuses and frustrations impact the family.
				</p>
				<p>
					Spending just an hour or two on Working Genius can change a marriage in profound ways as we come to
					accept and celebrate the gifts that God gives us, and doesn’t give us. It leads to more grace and
					appreciation, and less unnecessary and inappropriate guilt and judgment. And that’s certainly worth
					an hour or two.
				</p>
			</div>
			<div className='article-footer'>
				<span>&copy; 2022</span>
			</div>
		</>
	);
};

export default MarriageIsALotOfWork;
