import React from 'react';
import { WGpodcast } from '../../images';
import ResourcesService from '../../services/ResourcesService';

const Podcast = () => {
    const renderPodcastLink = (text, url) => (
        <div className={'border-1 border-primary hover:border-secondary text-primary hover:text-secondary rounded-md p-2 cursor-pointer'}>
            <a target={'_blank'} className={'font-semibold text-md uppercase p-2'} href={url}>
                {text}
            </a>
        </div>
    );

    return (
        <div className='students-podcast-section'>
            <div className='container'>
                <div
                    className={'flex flex-col md:flex-row items-center rounded-lg shadow-lg mb-5 bg-white p-6'}
                >
                    <img src={WGpodcast} alt='pat-podcast' width='300' className='rounded-md' />

                    <div className={'flex-1 text-primary p-6'}>
                        <h3 className={'font-normal pb-5 text-2xl md:text-4xl'}>The Working Genius Podcast with Patrick Lencioni</h3>
                        <p className={'pb-5 text-xl'}>What is your Working Genius? The Working Genius podcast is designed to
                            help people idendtify their natural gifts and find joy and fulfillment in their work and life.</p>
                        <div className='flex flex-col lg:flex-row items-center'>
                            <span className='text-lg text-primary font-semibold pr-6 py-3'>View all podcasts: </span>
                            <div className='flex flex-col lg:flex-row gap-4'>
                                {renderPodcastLink('Apple', ResourcesService.APPLE_PODCAST_URL)}
                                {renderPodcastLink('Spotify', ResourcesService.SPOTIFY_PODCAST_URL)}
                                {renderPodcastLink('Google', ResourcesService.GOOGLE_PODCAST_URL)}
                                <div className={'border-1 border-secondary bg-secondary hover:border-primary hover:bg-primary text-white hover:text-white rounded-md p-2 cursor-pointer'}>
                                    <a target={'_blank'} className={'font-semibold text-md uppercase p-2'} href={'/wg-podcast-post'}>
                                        Podcast page
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default Podcast;
