import React, { useEffect } from 'react';
import debounce from 'debounce-promise';

import AppButton from '../AppButton';
import AppModal from '../AppModal';
import AppRIIcon from '../AppRIIcon';
import AppLabel from '../AppLabel';
import AppAutocomplete from '../AppAutocomplete';
import AssessmentAutocompleteOption from '../assessments/AssessmentAutocompleteOption';
import AssessmentsService from '../../services/AssessmentsService';
import AppTooltip from '../AppTooltip';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ChooseAssessmentModal = ({ isOpen, onClose, onConfirm, loading, assessments }) => {
	const selectRef = React.useRef();
	const [selectedModalType, setSelectedModalType] = React.useState('');
	const [selectedGroup, setSelectedGroup] = React.useState(null);
	const options = [
		{
			value: 'individual',
			label: 'Individual',
			icon: 'user-fill',
			disabled: assessments.length > 1,
		},
		{
			value: 'group',
			label: 'Group',
			icon: 'team-fill',
		},
	];

	useEffect(() => {
		setSelectedModalType(assessments.length > 1 ? 'group' : 'individual');
	}, [isOpen]);

	const getGroups = async (search) => {
		return AssessmentsService.getGroupsForMove({ search, type: selectedModalType });
	};

	const handleSave = () => {
		onClose();
		onConfirm(
			{
				assessment_id: selectedGroup?.id,
				type: selectedModalType,
				name:
					selectedModalType === 'group'
						? selectedGroup.value ?? selectedGroup.group
						: `${assessments[0]?.fname} ${assessments[0]?.lname}`,
			},
			assessments
		);
	};

	const renderOption = (option, optionKey) => (
		<>
			<AppRIIcon icon={option.icon} className={'text-6xl text-secondary m-2'} />
			<p className='text-base leading-6 font-medium'>{option.label}</p>
			<input
				disabled={option.disabled}
				className={option.disabled ? 'cursor-pointer' : 'cursor-not-allowed'}
				type='radio'
				value={option.value}
				name={optionKey}
				checked={option.value === selectedModalType}
				onChange={() => !option.disabled && setSelectedModalType(option.value)}
			/>
		</>
	);

	return (
		<AppModal id={'app-confirm-close-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
			<div className={`bg-white p-6 h-full`} style={{ maxWidth: '600px' }}>
				<h1 className={'flex flex-1 justify-center text-primary text-lg leading-6 font-medium'}>
					Choose where do you want this assessment to be listed as
				</h1>
				<div className={'flex flex-row mx-10 text-center pt-4'}>
					{options.map((option, optionKey) => {
						return (
							<div
								className={`flex-1 flex flex-col items-center ${
									option.disabled
										? 'cursor-not-allowed bg-gray-300 mx-2'
										: 'cursor-pointer hover:bg-blue-100 py-4 mx-2'
								}`}
								key={option.value}
								onClick={() => !option.disabled && setSelectedModalType(option.value)}
							>
								{option.disabled ? (
									<AppTooltip
										content={() => (
											<p className={'text-white text-center text-tiny font-normal w-48'}>
												{
													'You can only move the assessments to a group when requesting multiple assessments simultaneously.'
												}
											</p>
										)}
										wrapperClass={'flex flex-col items-center py-4'}
										type={'small'}
									>
										{renderOption(option, optionKey)}
									</AppTooltip>
								) : (
									renderOption(option, optionKey)
								)}
							</div>
						);
					})}
				</div>
				{selectedModalType === 'group' && (
					<div className='my-4'>
						<hr className='slide-over-break' />

						<AppLabel
							label={'Search or Create Groups'}
							labelTooltip={
								'You can choose between existing groups assessments, or create a new group to move selected assessment into this group. This action will not use any of your licenses.'
							}
							labelIcon={'info-circled'}
						/>
						<AppAutocomplete
							innerRef={selectRef}
							placeholder={'Search or Create...'}
							creatable={true}
							allowCreateWhileLoading={false}
							option={AssessmentAutocompleteOption}
							getOptionValue={(option) => option.id}
							getOptionLabel={(option) => (
								<div className={'flex flex-row items-center z-50'}>
									<AppRIIcon
										icon={
											option?.type === 'individual' || selectedModalType === 'individual'
												? 'user-fill'
												: 'team-fill'
										}
										className={'text-secondary text-2xl mr-2'}
									/>
									<p>
										{option.type === 'individual'
											? `${option.fname} ${option.lname}`
											: option.group ?? option.value}
									</p>
								</div>
							)}
							loadOptions={debounce(getGroups, 500)}
							isClearable={true}
							cacheOptions={false}
							noOptionsMessage={() => 'Start typing to search...'}
							onChange={(i) => {
								setSelectedGroup(i ?? null);
							}}
							isDisabled={false}
							maxMenuHeight={220}
							menuPortalTarget={document.body}
							styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
						/>
					</div>
				)}

				<div className='flex my-4 justify-end'>
					<AppButton
						text='Cancel'
						id='wg-cancel-target-btn'
						className={'btn btn-secondary mr-4'}
						loading={loading}
						onClick={onClose}
					/>
					<AppButton
						onClick={handleSave}
						text={'Send Request'}
						className={'btn btn-primary'}
						id={'wg-lang-selection-modal-btn'}
						disabled={selectedModalType === 'group' && !selectedGroup}
						loading={loading}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default ChooseAssessmentModal;
