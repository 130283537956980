import React from 'react';

const AssessmentRequestNotification = ({ notification, changeNotificationReadStatus }) => {
	return (
		<div onClick={() => changeNotificationReadStatus()}>
			<p className={'text-lg font-medium mb-2 mr-2'} dangerouslySetInnerHTML={{ __html: notification.title }} />
			<p
				className={'text-normal mb-2 mr-2'}
				style={{ wordBreak: 'break-word' }}
				dangerouslySetInnerHTML={{ __html: notification.text }}
			/>
			<span
				className={'link text-secondary hover:text-primary underline cursor-pointer'}
				onClick={() =>
					changeNotificationReadStatus(
						`/client/request-assessments?tab=history&id=${notification.request_id}`
					)
				}
			>
				View Details
			</span>
		</div>
	);
};

export default AssessmentRequestNotification;
