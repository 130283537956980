import React from 'react';
import AppButton from '../AppButton';

const WGResourceItem = ({ item }) => {
	return (
		<div className={'flex flex-col shadow-md bg-white p-12 hover:shadow-xl'}>
			<div className='flex flex-1 flex-col'>
				<h3 className={'text-xl text-primary font-bold'}>{item.title}</h3>
				{item?.author && <h4 className={'text-14 text-primary'}>{item?.author}</h4>}
				<div className='border-secondary border-t-4 my-4' />
				<p className={'text-primary'}>{item.description}</p>
			</div>
			<div className='flex'>
				<AppButton
					className={'btn btn-dark mt-16'}
					text={item.ctaText}
					onClick={() => {
						window.open(item.link);
					}}
				/>
			</div>
		</div>
	);
};

export default WGResourceItem;
