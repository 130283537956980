import React from 'react';
import {
	report_overview_gear_d,
	report_overview_gear_e,
	report_overview_gear_g,
	report_overview_gear_i,
	report_overview_gear_t,
	report_overview_gear_w,
} from '../../../../images';

const Overview = ({ assessment }) => {
	return (
		<div className='Page__content'>
			<div className='callout'>
				<h1>An Overview of Working Genius</h1>

				<p>
					<span className='uppercase'>{assessment.participant.fname}</span>, the process you&rsquo;re about to
					go through takes a few minutes, but it&rsquo;s pretty simple. Everyone has natural talents and gifts
					when it comes to work. As it turns out, there are six different types of gifts that are required of
					any group of people trying to get something done. Those types are described in the chart below.
				</p>

				<p>
					Two of those six types come naturally to you, meaning that you are good at them <i>and</i> that they
					give you energy and joy. We call these your areas of <b>Working Genius</b>. Two of them are neither
					natural nor energizing for you, and most likely, you aren&rsquo;t particularly good at doing them.
					We call these your areas of <b>Working Frustration</b>. Finally, two types fall in between; you can
					do them fairly well, maybe even very well, but you don&rsquo;t derive great joy or energy from them.
					These are your areas of <b>Working Competency</b>.
				</p>

				<p>
					The purpose of this report is to help you identify your areas of Working Genius, Competency and
					Frustration, and to allow you to make adjustments in your life that increase your work satisfaction,
					raise your level of effectiveness, and increase the likelihood of sustainable success. We&rsquo;re
					genuinely excited for you to experience the relief and joy that this can bring to you.
				</p>
			</div>

			<h2>THE SIX TYPES</h2>

			<div className='geniuses'>
				<div className='genius'>
					<div className='gear'>
						<img src={report_overview_gear_w} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Wonder:</b> the gift of pondering the possibility of greater potential and
							opportunity in a given situation.
						</span>
					</div>
				</div>

				<div className='genius'>
					<div className='gear flex justify-end'>
						<img src={report_overview_gear_i} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Invention:</b> the gift of creating original and novel ideas and solutions.
						</span>
					</div>
				</div>

				<div className='genius'>
					<div className='gear'>
						<img src={report_overview_gear_d} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Discernment:</b> the gift of intuitively and instinctively evaluating ideas
							and situations.
						</span>
					</div>
				</div>

				<div className='genius'>
					<div className='gear flex justify-end'>
						<img src={report_overview_gear_g} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Galvanizing:</b> the gift of rallying, inspiring and organizing others to
							take action.
						</span>
					</div>
				</div>

				<div className='genius'>
					<div className='gear'>
						<img src={report_overview_gear_e} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Enablement:</b> the gift of providing encouragement and assistance for an
							idea or project.
						</span>
					</div>
				</div>

				<div className='genius'>
					<div className='gear flex justify-end'>
						<img src={report_overview_gear_t} alt={''} />
					</div>
					<div className='text'>
						<span>
							<b>The Genius of Tenacity:</b> the gift of pushing projects or tasks to completion to
							achieve results.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Overview;
