import React from 'react';

const GeniusIndividual = () => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h1>Putting Your Genius to Work as an Individual</h1>

				<p className='padded'>
					<b>Adjusting Your Responsibilities in a Current Job</b>
					<br />
					If you&rsquo;re in a job that isn&rsquo;t allowing you to operate within your areas of Working
					Genius or Competency, talk to the people you work for and help them understand how you could
					contribute more to the organization. Though it makes sense for your own satisfaction, approach the
					situation in terms of how you can be more valuable to the people around you&mdash;customers or
					coworkers. And don&rsquo;t be afraid to show that by shifting your job slightly or greatly, your
					passion for your work would increase. Most managers want to help their employees find more passion
					in what they do.
				</p>

				<p className='padded'>
					<b>Getting a New Job</b>
					<br />
					When researching or interviewing for jobs, it is critical to ask questions about whether the work
					will fit your Working Geniuses, or at least your Working Competencies. No job will be perfect, and
					everyone will have to do unpleasant work from time to time. But pursuing a job or talking someone
					into hiring you for a job that makes you frustrated never turns out well as it will lead to misery
					and likely failure.
				</p>

				<p className='padded'>
					<b>Choosing a Career or Job</b>
					<br />
					Too much attention around career decisions focuses on choosing a particular industry or function.
					&ldquo;You should work in high tech,&rdquo; or &ldquo;I think you would be great in
					marketing.&rdquo; In reality, the nature of the job itself is more important than the industry or
					function. For instance, there are great jobs in almost any industry for people with any of the six
					types of Working Genius, and there are miserable jobs in those same industries for every type. What
					matters much more than where you work or what your title is, is what the job itself entails and
					whether it aligns with your Working Geniuses, or at the very least, your Working Competencies.
				</p>
			</div>
		</div>
	);
};

export default GeniusIndividual;
