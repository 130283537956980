import React from 'react';
import AppButton from '../AppButton';

const CTA = ({ handleOpenModal, loading }) => {
	return (
		<div className={'dca-cta-section'}>
			<div className={'dca-cta-section-container'}>
				<span className={'dca-cta-section-gear-list'}>
					<ul>
						<li>
							20 Complimentary Working Genius Licenses
						</li>

						<li>
							Patrick Lencioni's Pre-Work Videos
						</li>
					</ul>
				</span>

				<span className={'prework-button-container'}>
					<AppButton
						id={'dca-cta-button'}
						onClick={() => handleOpenModal('cta')}
						loading={loading}
						className={'btn btn-secondary prework-button'}
						text={'ACCESS PRE-WORK'}
					/>
				</span>
			</div>
		</div>
	);
};

export default CTA;
