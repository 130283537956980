import React from 'react';

const AppTour = ({ steps, cookie }) => {
	/*
	const { setIsOpen, setSteps, setCurrentStep } = useTour();

	React.useEffect(() => {
		setCurrentStep(0);
		setTimeout(() => {
			const cookie_value = Cookies.get(cookie);
			if (!cookie_value) {
				setIsOpen(true);
				Cookies.set(cookie, 'seen', { expires: 365 * 30 });
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 100);
			}
		}, 1000);
	}, []);

	React.useEffect(() => {
		setSteps(steps);
	}, [steps.length]);
	*/

	return null;
};

export default AppTour;
