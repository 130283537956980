import React from 'react';
import { report_gear } from '../../../../images';
import PageHeader from './PageHeader';

const AsATeam = () => {
    const renderGear = (text) => {
        return (
            <div className={'flex flex-row my-3'}>
                <img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
                <div>
                    <p>{text}</p>
                </div>
            </div>
        );
    };

    return (
        <div className='Application'>
            <PageHeader title={'ANWENDUNG'} />
            <div className={'container mt-6'}>
                <div className='font-bold uppercase mt-12'>NUTZEN SIE WORKING GENIUS ALS TEAM</div>
                <div className={'ml-8 mr-20'}>
                    <p className={'mt-4'}>
                        Teams, die sich dieses Modell zu eigen machen, können ein höheres Maß an Vertrauen erleben, die
                        Arbeitsmoral und Produktivität steigern, bessere Meetings abhalten und erfolgreicher dabei sein,
                        die richtigen Mitarbeiter einzustellen.
                    </p>

                    <p className='italic font-bold mt-8 mb-4'>Vier Schritte, um das Modell des Working Genius als Team zu nutzen:</p>

                    <p className={'mb-6'}>
                        <span className='font-bold uppercase text-16 pr-1'>SCHRITT 1:</span>
                        Lassen Sie alle Teilnehmer das{' '}
                        <a
                            href='https://player.vimeo.com/video/750402175'
                            target={'_blank'}
                            className={'text-secondary underline'}
                        >
                            Working Genius Assessment
                        </a>{' '}
                        gemeinsam durchführen (10 Minuten).
                    </p>

                    <p className={'mb-2'}>
                        <span className='font-bold uppercase text-16 pr-1'>SCHRITT 2:</span>
                        Setzen Sie sich zusammen, um sich gegenseitig Ihre Ergebnisse mitzuteilen (15 - 30 Minuten).
                    </p>

                    <div className='mr-12'>
                        {renderGear(
                            'Lassen Sie jeden die Bereiche des Berichts hervorheben, die für ihn besonders aufschlussreich und überzeugend waren.'
                        )}
                        {renderGear('Lassen Sie sie auch die Beschreibung ihrer Working-Genius-Kombinationen lesen.')}
                        {renderGear(
                            'Für weiterführende Gespräche können Sie folgende Fragen stellen: Wo können Sie bei Ihrer\n' +
                            'derzeitigen Tätigkeit Ihre Geniuses einsetzen? Wo erleben Sie bei Ihrer derzeitigen Tätigkeit\n' +
                            'Frust oder Burnout?'
                        )}
                    </div>

                    <p className={'my-6'}>
                        <span className='font-bold uppercase text-16 pr-1'>SCHRITT 3:</span> Schauen Sie sich die{' '}
                        <a
                            href='https://vimeo.com/738968732/faa866884b'
                            target={'_blank'}
                            className={'text-secondary underline'}
                        >
                            Team Map
                        </a>{' '}
                        an, die die gemeinsamen Ergebnisse Ihrer Working Genius Assessments darstellt. Die Team Map
                        zeigt eine Momentaufnahme des Working Genius und der Working Frustration jedes Mitglieds. Mit
                        dieser Map können Sie erkennen, wo die Stärken des Teams liegen und wo es Lücken gibt, die
                        Ihre Herausforderungen in der Vergangenheit erklären könnten. Die Team Map hilft Ihnen auch dabei,
                        Möglichkeiten für Anpassungen zu erkennen, die zu mehr Produktivität und Erfüllung führen. Auf
                        Seite 10 finden Sie ein Beispiel für eine Team Map sowie eine Analyse (15 - 30 Minuten).
                    </p>

                    <p className={'mb-6'}>
                        <span className='font-bold uppercase text-16 pr-1'>SCHRITT 4:</span> Besprechen Sie, wie Sie Ihre Rollen neu organisieren können, um die Working
                        Geniuses aller im Team zu nutzen und die Zeit zu reduzieren, die Sie in Ihren Working Frustrations
                        arbeiten. Ziehen Sie in Erwägung, Mitarbeiter aus anderen Abteilungen „auszuleihen“ oder sogar
                        neue Mitarbeiter einzustellen, um Bereiche zu besetzen, in denen Ihnen das nötige Genius fehlt
                        (30 - 60 Minuten).
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AsATeam;
