import React from 'react';
import { Link, navigate } from 'gatsby';
import AppRIIcon from './../AppRIIcon';
import ToolkitMenuItem from './ToolkitMenuItem';
import { team_application_guide_logo } from '../../images';

const Sidebar = ({ activeTab, setActiveTab }) => {
	return (
		<div className='toolkit-sidebar flex flex-col justify-between'>
			<div>
				<div className='toolkit-header'>
					<Link to='/'>
						<img src={team_application_guide_logo} alt='Working Genius' width='300' />
					</Link>
				</div>
				<div className='menu-list'>
					<ToolkitMenuItem title='Welcome' id='tab1' activeTab={activeTab} setActiveTab={setActiveTab} />
					<ToolkitMenuItem
						title='1. Sending &amp; Gathering Assessments'
						id='tab2'
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
					<ToolkitMenuItem
						title='2. Reading a Team Map'
						id='tab3'
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
					<ToolkitMenuItem
						title='3. Leading a Team Discussion'
						id='tab4'
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
					<ToolkitMenuItem
						title={'4. Working Genius for Meetings, Hiring & Staffing'}
						id='tab5'
						activeTab={activeTab}
						setActiveTab={setActiveTab}
					/>
				</div>
			</div>
			<div className='sidebar-back-btn' onClick={() => navigate('/client/')}>
				<AppRIIcon
					icon='arrow-left-line'
					className='text-white bg-primary p-2 rounded-full font-semibold mr-4'
				/>
				Back to Assessments
			</div>
		</div>
	);
};

export default Sidebar;
