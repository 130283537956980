import React, { useContext, useEffect, useState } from 'react';
import ScrollToTop from 'react-scroll-to-top';

import PodcastHeaderSection from '../../components/podcast/PodcastHeaderSection';
import PodcastItem from '../../components/podcast/PodcastItem';
import PodcastNavigation from '../../components/podcast/PodcastNavigation';
import { PodcastPageContext } from './Podcast';
import AppRIIcon from '../../components/AppRIIcon';
import AppButton from '../../components/AppButton';
import { podcastHelpers } from '../../components/podcast/podcastHelpers';

const PodcastPage = ({ type }) => {
	const { podcasts, categories } = useContext(PodcastPageContext);
	const { SortEnum, tileItems } = podcastHelpers;

	const [selectedFilter, setSelectedFilter] = useState('');
	const [selectedCategory, setSelectedCategory] = useState('all');
	const [renderedPodcasts, setRenderedPodcasts] = useState(podcasts || []);
	const [renderMobileView, setRenderMobileView] = useState(false);

	useEffect(() => {
		const handleWindowResize = () => {
			setRenderMobileView(window?.innerWidth < 768);
		};

		window?.addEventListener('resize', handleWindowResize);

		return () => {
			window?.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	useEffect(() => {
		if (podcasts.length > 0) {
			const sortedPodcasts = getSortedPodcasts(selectedFilter ? selectedFilter : SortEnum.NEWEST);
			selectedCategory === 'all'
				? setRenderedPodcasts([...addTiles([...sortedPodcasts], tileItems)])
				: categorizePodcasts(sortedPodcasts, selectedCategory);
		}
	}, [podcasts, selectedCategory]);

	const addTiles = (podcastsItems, tileItems) => {
		let randomIndex = Math.ceil(Math.random() * (podcasts.length - 1));
		tileItems.forEach((tile) => {
			let index = tile.tileIndex ?? (randomIndex + 2);
			podcastsItems.splice(index, 0, tile);
			randomIndex = Math.ceil(Math.random() * (podcasts.length - 1));
		});
		return podcastsItems;
	};

	const getSortedPodcasts = (sortType) => {
		if (sortType === SortEnum.NEWEST) {
			return (
				podcasts &&
				podcasts
					.filter(
						(podcast) =>
							podcast?.tags?.find((tag) => tag.slug === selectedCategory) || selectedCategory === 'all'
					)
					.sort((podcast_1, podcast_2) => Date.parse(podcast_2.date) - Date.parse(podcast_1.date))
			);
		}

		return (
			podcasts &&
			podcasts
				.filter(
					(podcast) =>
						podcast?.tags?.find((tag) => tag.slug === selectedCategory) || selectedCategory === 'all'
				)
				.sort((podcast_1, podcast_2) => Date.parse(podcast_1.date) - Date.parse(podcast_2.date))
		);
	};

	const sortPodcasts = (sortType) => {
		const sortedPodcasts = getSortedPodcasts(sortType);

		setRenderedPodcasts([...addTiles(sortedPodcasts, tileItems)]);
		setSelectedFilter(sortType);
	};

	const categorizePodcasts = (podcasts, category) => {
		const categorizedPodcasts = podcasts.filter((podcast) => podcast?.tags?.find((tag) => tag.slug === category));
		setRenderedPodcasts([...addTiles(categorizedPodcasts, tileItems)]);
	};

	const renderTile = (tile, key) => (
		<div
			key={key}
			className={'flex flex-col justify-between bg-secondary shadow-md hover:shadow-xl p-8 md:p-6 lg:p-10'}
		>
			<div>
				<div className={'flex flex-row justify-center items-center'}>
					<img src={tile.image} alt='' className={'w-3/4'} />
				</div>

				{tile.body}
			</div>

			<div className={'flex items-start pt-6'}>
				<AppButton
					text={tile.buttonText ?? 'Learn More'}
					onClick={() => window.open(tile.url)}
					className={`${tile.buttonText ? 'btn btn-dark rounded-sm' : 'tile-button'} w-full lg:w-1/2`}
				/>
			</div>
		</div>
	);

	const renderMobileHeader = () => (
		<div className={'flex flex-col md:hidden'}>
			<div className={'podcast-page-content mb-4'}>
				<PodcastHeaderSection type={type} />
			</div>
			<PodcastNavigation
				type={type}
				categories={categories}
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
			/>
		</div>
	);

	const renderPodcastItems = (
			<div className='grid grid-cols-1 md:grid-cols-2 gap-8 my-3 md:my-8'>
				{renderedPodcasts.length > 0 &&
					renderedPodcasts?.map((item, key) => {
						if (item?.isTile) return renderTile(item, key);
						return <PodcastItem key={item?.id} id={item?.category?.slug} item={item} />;
					})}
			</div>
		);

	return (
		<div className={'flex flex-col md:flex-row justify-evenly container pt-8 my-4 md:my-6 lg:my-8'}>
			{renderMobileView ? renderMobileHeader() : null}

			<div className={'hidden md:flex'}>
				<PodcastNavigation
					type={type}
					categories={categories}
					selectedCategory={selectedCategory}
					setSelectedCategory={setSelectedCategory}
				/>
			</div>

			<div className={'podcast-page-content'}>
				<div className={'hidden md:flex'}>{!renderMobileView ? <PodcastHeaderSection type={type} /> : null}</div>

				<div
					className={
						'flex flex-row justify-start items-center gap-3 pt-0 md:pt-6 pb-6 md:pb-2 ml-8 md:ml-12 mt-0 md:mt-3'
					}
				>
					<span className={'font-semibold text-primary text-sm uppercase'}>Order By:</span>
					<span
						className={`order-by-item ${selectedFilter === SortEnum.OLDEST ? 'selected' : ''}`}
						onClick={() => sortPodcasts(SortEnum.OLDEST)}
					>
						Oldest
					</span>
					<span
						className={`order-by-item ${selectedFilter === SortEnum.NEWEST ? 'selected' : ''}`}
						onClick={() => sortPodcasts(SortEnum.NEWEST)}
					>
						Newest
					</span>
				</div>

				{renderPodcastItems}
			</div>

			<div className='hidden lg:block'>
				<ScrollToTop
					smooth
					width='40'
					top='200'
					component={<AppRIIcon icon={'arrow-up-line'} className={'text-secondary text-2xl'} />}
					className={'home-scroll-top-bottom'}
				/>
			</div>
		</div>
	);
};

export default PodcastPage;
