import React from 'react';
import { report_hr_sm } from '../../../../images';
import PrintUl from './PrintUl';
import GeniusCheckboxes from './GeniusCheckboxes';

const items = [
	{
		title: 'What people with the Genius of Enablement tend to think and say:',
		items: [
			'"I\'ve heard enough. I\'m in."',
			'"This is exciting. Sign me up."',
			'"Let me know how I can help."',
			'"I want to help make this successful."',
		],
	},
	{
		title: 'What others think and say about people with the E genius:',
		items: [
			'"She\'s the best team player we have."',
			'"I don\'t know what we’d do if he weren\'t part of this."',
			'"Make sure she understands what she’s getting into."',
			'"He\'ll come on board once we explain it to him."',
		],
	},
];

const Enablement = ({}) => {
	return (
		<div className='Page__content'>
			<h4>Qualitative Review (continued)</h4>

			<h2>THE GENIUS OF ENABLEMENT</h2>

			<p>
				People who have the Genius of Enablement (by the way, enablement is <i>not</i> a bad word at all, even
				if it's often associated with people who support others in their dysfunctions or addictions) are
				naturally inclined to offer their enthusiastic support to a person who needs assistance with a project,
				function or idea. They understand that nothing happens if people don't step up to make something happen,
				and they have a knack for emerging at the right moment, and just as important, in the right way. In
				fact, they don't insist on doing things on their own terms.
			</p>

			<p>
				Those with this genius will have the hardest time admitting it, for two reasons. First, as I mentioned
				above, they incorrectly interpret the word as something negative. Second, they often downplay their
				gift, seeing it as just being nice, or even as a weakness associated with being a pushover! They
				mistakenly consider what they do to be ordinary and easy, rather than a gift that eludes many of their
				peers, and which is critical to the success of any endeavor.
			</p>

			<p>
				Ironically, as much as people with the E genius tend to downplay this talent, they are usually quick to
				acknowledge that it fits them, as are others who value and appreciate it for the right reasons.
			</p>

			<img src={report_hr_sm} className='hr hr-sm' alt={''} />

			{items.map((item, key) => (
				<div key={key}>
					<h3>{item.title}</h3>

					<PrintUl items={item.items} />
				</div>
			))}

			<GeniusCheckboxes />
		</div>
	);
};

export default Enablement;
