import React from 'react';
import { wg_meeting_pov_1 } from '../../../images';

const items = [
	"Learn best practices for growing your business",
	'Understand the Table Group approach to "Naked Consulting"',
	"Get practical tools and tips for retaining clients and getting new clients",
	"Hear real life examples of coaches and consultants who are building thriving businesses",
	"Develop an action plan that you can immediately put to work"
];

const DuringTraining = () => {
	return (
		<div className='container'>
			<div className='flex flex-col lg:flex-row mt-4 py-12'>
				<div className='during-training-image flex flex-1 m-2 w-10/12'>
					<img src={wg_meeting_pov_1} className={'object-contain'} alt=''/>
				</div>
				<div className='flex flex-3 flex-col justify-center mx-12 w-auto'>
					<h2 className='text-xl text-primary font-bold leading-none mb-4'>
						In This Session You Will:
					</h2>
					<ul className='alumni-intensives'>
						{items.map((item, key) => {
							return (
								<li className={'text-lg mt-2'} key={key}>
									{item}
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DuringTraining;
