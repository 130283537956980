import React from 'react';
import Modal from 'react-modal';
import { Link } from 'gatsby';
import AppIcon from '../AppIcon';
import { event_november_popup } from '../../images';

Modal.setAppElement('body');

const MeetingsPopup = () => {
    const [modalIsOpen, setIsOpen] = React.useState(true);

    React.useEffect(() => {
        if (modalIsOpen) {
            document.body.classList.remove('ReactModal__Body--open');
        }
    }, [modalIsOpen]);

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onClose={() => setIsOpen(false)}
                onRequestClose={() => setIsOpen(false)}
                contentLabel='Meetins Popup'
                className='careers-modal'
                overlayClassName='careers-overlay'
                shouldFocusAfterRender={false}
            >
                <div className='careers-popup-content'>
                    <button className={'absolute right-0 top-0 mr-4 mt-4 z-50'} onClick={() => setIsOpen(false)}>
                        <AppIcon icon={'cancel'} className={'text-2xl text-primary'} />
                    </button>
                    <div className='flex flex-col items-center'>
                        <img
                            className={'relative p-2'}
                            src={event_november_popup}
                            alt='At the Whiteboard Meetings Event'
                            width='350'
                        />
                    </div>
                    <div className='flex flex-col justify-center items-center mt-4 mb-6'>
                        <p className='text-primary text-base font-semibold tracking-wide mb-4'>
                            November 8th at 11:00AM Pacific Time <br />
                            <span className={'font-normal'}>A Free, Virtual Event</span>
                        </p>
                        <Link to={'/atthewhiteboardmeetingsreg'} className={'btn btn-primary w-56'}>
                            Register Today
                        </Link>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default MeetingsPopup;
