import React from 'react';
import AppInput from '../AppInput';

const AssessmentsFilters = ({ filters, setFilters }) => {
	return (
		<div className={'flex flex-1'}>
			<div className='flex flex-1 mr-4'>
				<AppInput
					id={'wg-roster-table-search'}
					containerClassName={'no-margin w-full mr-4'}
					value={filters.search}
					placeholder={'Search'}
					onChange={(e) => setFilters('search', e.target.value)}
					showErrorMessage={false}
				/>
			</div>
		</div>
	);
};
export default AssessmentsFilters;
