import React from 'react';

const AppFAQ = ({ items, title, numbered = true, closedQuestions = false }) => {
	const [tab, setTab] = React.useState(closedQuestions ? -1 : 0);

	return (
		<div className={'container mx-auto pt-6 px-0 bg-white'}>
			{title ? (
				<h2 className={'text-2xl text-center font-semibold text-primary'}>{title}</h2>
			) : (
				<h2 className={'text-2xl text-center font-thin'}>FAQ</h2>
			)}
			<div className='ttg-faq-tabs'>
				{items.map((item, key) => {
					return (
						<div
							className='ttg-faq-tab'
							key={key}
							id={`faq-tab-${key}`}
							onClick={() => setTab(tab === key ? -1 : key)}
						>
							<input
								type='radio'
								name={`faq-tab`}
								className={'ttg-faq-input'}
								value={key}
								checked={tab === key}
								readOnly
							/>
							<h3>
								<label className='ttg-faq-tab-label hover:text-secondary' htmlFor={`faq-tab-${key}`}>
									{numbered ? key + 1 + '.' : ''} {item.title}
								</label>
							</h3>

							<div
								id={`faq-answer-${key}`}
								className='ttg-faq-tab-content'
								dangerouslySetInnerHTML={{ __html: item.answer }}
								onClick={(e) => e.stopPropagation()}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AppFAQ;
