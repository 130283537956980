import React from 'react';
import { report_gear } from '../../../../images';
import PageHeader from './PageHeader';

const AsATeam = () => {
	const renderGear = (text) => {
		return (
			<div className={'flex flex-row my-3'}>
				<img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
				<div>
					<p>{text}</p>
				</div>
			</div>
		);
	};

	return (
		<div className='Application'>
			<PageHeader title={'APLICACIÓN'} />
			<div className={'container mt-12'}>
				<div className='font-bold uppercase mt-12'>CÓMO USAR WORKING GENIUS EN UN EQUIPO</div>
				<div className={'ml-8 mr-20'}>
					<p className={'mt-4'}>
						Los equipos que abrazan este modelo logran experimentar mayores niveles de confianza,
						incrementan su moral y productividad, tienen mejores reuniones y son más exitosos a la hora de
						contratar a las personas correctas.
					</p>

					<p className='italic font-bold mt-8 mb-4'>Cuatro pasos para usar Working Genius como un equipo:</p>

					<p className={'mb-6'}>
						<span className='font-bold uppercase text-16 pr-1'>PASO 1:</span>
						Pídeles a todos que hagan la evaluación de{' '}
						<a
							href='https://player.vimeo.com/video/750402175'
							target={'_blank'}
							className={'text-secondary underline'}
						>
							Working Genius
						</a>{' '}
						en grupo (10 minutos).
					</p>

					<p className={'mb-2'}>
						<span className='font-bold uppercase text-16 pr-1'>PASO 2:</span> Siéntense juntos para
						compartir sus resultados entre ustedes (15 - 30 minutos).
					</p>

					<div className='mr-12'>
						{renderGear(
							'Pídeles a todos que resalten las áreas del informe que fueron especialmente reveladoras y confrontadoras.'
						)}
						{renderGear('Pídeles que lean las descripciones de sus Pares únicos de Working Genius.')}
						{renderGear(
							'Para profundizar la conversación, hazles las siguientes preguntas: En tu trabajo\n' +
							'actual, ¿dónde eres capaz de contribuir usando tus áreas de genialidad? En tu trabajo\n' +
							'actual, ¿dónde estás experimentando frustración o agotamiento?'
						)}
					</div>

					<p className={'my-6'}>
						<span className='font-bold uppercase text-16 pr-1'>PASO 3:</span> Revisa el{' '}
						<a
							href='https://vimeo.com/738968732/faa866884b'
							target={'_blank'}
							className={'text-secondary underline'}
						>
							Mapa
						</a>{' '}
						de equipo que presenta los resultados colectivos de su evaluación de Working Genius. El Mapa de
						equipo muestra un panorama general de las áreas de genialidad y de frustración de cada miembro.
						A partir de este mapa, serás capaz de identificar las áreas de fortaleza del equipo y las
						brechas que podrían explicar los desafíos que han experimentado en el pasado. También los
						ayudará a identificar oportunidades para hacer ajustes que les permitan incrementar la
						productividad y la satisfacción. En la página 10 se encuentra un ejemplo de un Mapa de equipo y
						su análisis.
					</p>

					<p className={'mb-6'}>
						<span className='font-bold uppercase text-16 pr-1'>PASO 4:</span> Conversen sobre cómo podrían
						reorganizar sus funciones para aprovechar las áreas de genialidad de cada uno y reducir el
						tiempo que pasan en sus áreas de frustración. Consideren tomar “prestadas” personas de otros
						departamentos o incluso contratar personas nuevas para atender áreas en las que carecen de las
						genialidades necesarias (30 - 60 minutos).
					</p>
				</div>
			</div>
		</div>
	);
};

export default AsATeam;
