import React from 'react';
import BuilderPersonRow from './BuilderPersonRow';
import BuilderGroupRow from './BuilderGroupRow';

const BuilderTable = (props) => {
	const people = props.resultIds.map((id) => {
		let result = props.resultInfo[id];

		if (!result) {
			return null;
		}

		if (result.type === 'individual') {
			let resultInfo = result.results[0];

			return (
				<BuilderPersonRow
					assessment={id}
					selectedMembers={props.selectedMembers}
					handleSelect={props.handleSelect}
					key={id}
					{...resultInfo}
				/>
			);
		} else {
			return (
				<BuilderGroupRow
					assessmentName={result.group}
					members={result.results}
					memberInfo={props.resultInfo}
					handleSelect={props.handleSelect}
					selectedMembers={props.selectedMembers}
					handleGroupAdd={props.handleGroupAdd}
					key={id}
					assmentID={id}
				/>
			);
		}
	});

	return (
		<div className='mx-0'>
			<table className='mx-auto BuilderTable BuilderTable--group'>
				{people}
				{!!props.hasNextPage && !props.loadingMore && (
					<div className='flex justify-center items-center mt-4'>
						<a
							className={'cursor-pointer font-bold text-primary underline'}
							onClick={props.loadMore}
							id={'wg-teammap-load-more-btn'}
						>
							Load More
						</a>
					</div>
				)}
			</table>
		</div>
	);
};

export default BuilderTable;
