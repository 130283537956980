import React from 'react';
import { useForm, FormProvider, useFormState } from 'react-hook-form';
import { isEmpty } from 'lodash';

import AppButton from '../AppButton';
import AppSlideOver from '../AppSlideOver';
import GiveAccessFields from './GiveAccessFields';
import PermissionsTable from './PermissionsTable';
import { renderPermissionOptions } from './Permissions';
import GiveAccessConfirmationModal from './GiveAccessConfirmationModal';

import CustomerService from '../../services/CustomerService';
import PermissionsService from '../../services/PermissionsService';
import NotificationService from '../../services/NotificationService';

const AddEditPermissionSlideOver = ({ isOpen, onClose, onSuccess, user }) => {
    const [loading, setLoading] = React.useState(false);
    const [existingCustomer, setExistingCustomer] = React.useState(false);
    const [confirmGiveAccessModal, setConfirmGiveAccessModal] = React.useState(false);
    const [formPayload, setFormPayload] = React.useState({ email: '', permission: '' });

    const methods = useForm();
    const { control, reset, handleSubmit } = methods;
    const { dirtyFields } = useFormState({ control });

    React.useEffect(() => {
        if (isOpen) {
            reset({
                email: user?.customers_email_address || '',
                permission: user
                    ? [{ label: 'Admin', value: 'ADMIN' }, { label: 'Limited', value: 'LIMITED' }]
                        .find(role => role.value === user.role) || null
                    : null,
            });
        } else {
            reset({ email: '', permission: null });
        }
    }, [isOpen, user, reset]);

    const handleFormSubmit = async (payload) => {
        setLoading(true);
        setFormPayload(payload);
        try {
            if (user?.target_customers_id) {
                await PermissionsService.update(user.target_customers_id, { role: payload.permission.value });
                NotificationService.success('Permission request updated!');
                onSuccess();
                onClose();
            } else {
                const exists = await CustomerService.verifyEmail(payload.email);
                setExistingCustomer(exists);
                setConfirmGiveAccessModal(true);
            }
        } catch (error) {
            NotificationService.error('Oops', error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = async () => {
        setLoading(true);
        const registerPayload = {
            customers_email_address: formPayload.email,
            role: formPayload.permission.value,
        };

        try {
            await PermissionsService.requestPermission(registerPayload);
            NotificationService.success('Permission request sent!');
            reset({ email: '', permission: '' });
            setConfirmGiveAccessModal(false);
            onSuccess();
            onClose();
        } catch (error) {
            NotificationService.error('Oops', error.message);
        } finally {
            setLoading(false);
        }
    };

    const headerText = user?.customers_email_address
        ? `Edit ${user.customers_name}`
        : 'Give access to users';

    const subHeaderText = user?.customers_email_address
        ? `Assign a new access level to ${user.customers_name} based on the information in the access level table.`
        : 'Give users access by using their email address and the permission options listed below.';

    const confirmText = existingCustomer
        ? `Are you sure you wish to give <span class='capitalize'>${formPayload.permission.label} access</span> to <b>${formPayload.email}</b>? <br/><br /> <b>Note:</b> They will receive a request by email. Once they accept it, they will be able to access your account.`
        : `Looks like <b>${formPayload.email}</b> does not have an account. <br /><br /> Do you wish to proceed with sending them an invitation to create an account and give ${formPayload.permission.label} Access to your account?`;

    return (
        <AppSlideOver isOpen={isOpen} closeModal={onClose} containerClassName='edit-permission-slide-modal'>
            <div className='flex flex-col w-full'>
                <div className='slide-over-header'>
                    <span className='text-lg font-medium leading-7'>{headerText}</span>
                    <span className='text-md text-gray-500 font-thin'>{subHeaderText}</span>
                </div>
                <div className='p-6'>
                    <FormProvider {...methods}>
                        <form
                            className='w-full flex flex-col lg:flex-row items-start'
                            autoComplete='autocomplete_off_hack_xfr4!k'
                            onSubmit={handleSubmit(handleFormSubmit)}
                            id='give-access-form-id'
                        >
                            <GiveAccessFields disabledEmail={!!user?.target_customers_id} />
                        </form>
                    </FormProvider>
                    {renderPermissionOptions()}
                    <PermissionsTable />
                    <hr className='slide-over-break' />
                    <div className='flex justify-center my-4'>
                        <AppButton
                            loading={loading}
                            type='submit'
                            className='btn btn-primary w-full mr-4'
                            text={user?.customers_email_address ? 'Save' : 'Give Access'}
                            form='give-access-form-id'
                            disabled={isEmpty(dirtyFields)}
                            id='wg-edit-assessment-save-btn'
                        />
                        <AppButton
                            type='button'
                            className='btn btn-secondary w-full'
                            onClick={onClose}
                            disabled={loading}
                            text='Cancel'
                            id='wg-edit-assessment-cancel-btn'
                        />
                    </div>
                </div>
            </div>
            <GiveAccessConfirmationModal
                loading={loading}
                isOpen={confirmGiveAccessModal}
                closeModal={() => setConfirmGiveAccessModal(false)}
                onConfirm={handleConfirm}
                text={confirmText}
                confirmText={existingCustomer ? 'Give Access' : 'Send Invitation and Give Access'}
            />
        </AppSlideOver>
    );
};

export default AddEditPermissionSlideOver;
