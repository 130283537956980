import React from 'react';
import CoverPage from './de/CoverPage';
import Overview from './de/Overview';
import Footer from './de/Footer';
import Results from './de/Results';
import Geniuses from './de/Geniuses';
import WGPairings from './de/WGPairings';
import WhatsNext from './de/WhatsNext';
import AsATeam from './de/AsATeam';
import TeamMapAnalyze from './de/TeamMapAnalyze';
import GoingDeeper from './de/GoingDeeper';
import UniquePairingV2 from './de/UniquePairingV2';

const GermanTemplate = ({ assessment }) => {
    return (
        <div id={'wg-assessment-report'} className={'wg-pdf-assessment-report-v2'}>
            <section role='region' className='Page'>
                <CoverPage assessment={assessment} />
            </section>
            <section role='region' className='Page'>
                <Overview assessment={assessment} />
                <Footer page={1} />
            </section>
            <section role='region' className='Page'>
                <Results assessment={assessment} />
                <Footer page={2} />
            </section>
            <section role='region' className='Page'>
                <Geniuses
                    assessment={assessment}
                    type={'genius'}
                    title={'Ihre Working Geniuses'}
                />
                <Footer page={3} />
            </section>
            <section role='region' className='Page'>
                <Geniuses
                    assessment={assessment}
                    type={'competency'}
                    title={'Ihre Working Competencies'}
                />
                <Footer page={4} />
            </section>
            <section role='region' className='Page'>
                <Geniuses assessment={assessment} type={'frustration'} title={'Ihre Working Frustrations'} />
                <Footer page={5} />
            </section>
            <section role='region' className='Page'>
                <UniquePairingV2 assessment={assessment} />
                <Footer page={6} />
            </section>
            <section role='region' className='Page'>
                <WGPairings assessment={assessment} />
                <Footer page={7} />
            </section>
            <section role='region' className='Page'>
                <WhatsNext />
                <Footer page={8} />
            </section>
            <section role='region' className='Page'>
                <AsATeam />
                <Footer page={9} />
            </section>
            <section role='region' className='Page'>
                <TeamMapAnalyze />
                <Footer page={10} />
            </section>
            <section role='region' className='Page'>
                <GoingDeeper />
                <Footer page={11} />
            </section>
        </div>
    );
};

export default GermanTemplate;
