import React from 'react';
import { useEffect, useState } from 'react';
import AssessmentsService from '../../services/AssessmentsService';
import WgIcon from '../WgIcon';
import { navigate } from '@reach/router';
import AppLabel from '../AppLabel';
import AppRIIcon from '../AppRIIcon';

const DuplicatedInAssessment = (roster) => {
	const [duplicatedInAssessment, setDuplicatedInAssessment] = useState([]);
	const [loading, setLoading] = useState(false);
	const getDuplicatedInAssessment = () => {
		setLoading(true);
		AssessmentsService.getDuplicatedInAssessments(roster).then((response) => {
			setDuplicatedInAssessment(response.data);
		}).finally(() => {
			setLoading(false);
		});
	}

	useEffect(() => {
		getDuplicatedInAssessment();
	}, []);

	const renderContent = (data) => {
		return (
			<>
				<div className={'flex flex-1 flex-row cursor-pointer'} id={`wg-duplicated-in-assessment-${data.id}`}>
					<div className='flex flex-1 flex-row items-center justify-start py-2 border-b border-gray-200 hover:text-secondary'>
						<WgIcon
							item={data}
							className={`text-2xl ${data.assessment_mode === 'student' ? 'mr-1' : ''}`}
						/>
						<span
							className={'m-0 underline'}
							onClick={() => navigate(`/client/assessment/?id=${data.assessment_id}`, { replace: true })}
						>
							{data.group}
						</span>
					</div>
				</div>
			</>
		);
	};

	if (loading) {
		return (
			<div className={'flex flex-1 justify-center mb-3'}>
				<AppRIIcon icon={'loader-4-line'} className={'action-icon animate-spin loading text-3xl text-primary'} />
			</div>
		);
	}

	return (
		<div>
			{duplicatedInAssessment && duplicatedInAssessment.length > 0 ? (
				<div className={'pb-2'}>
					<AppLabel
						label={'Duplicated in:'}
						labelIcon={'info-circled'}
						customClassName={'pb-2 border-b border-gray-200'}
					/>
					{duplicatedInAssessment.map((assessment) => renderContent(assessment))}
				</div>
			) : null}
		</div>
	);
};

export default DuplicatedInAssessment;
