import React from 'react';
import AppRIIcon from '../AppRIIcon';

const Questions = () => {
	return (
		<div className={'dca-license-section'}>
			<span className={'text-2xl md:text-3xl font-medium'}>Questions?</span>

			<span className={'text-lg pt-6'}>
					Because our partner, Dale Carnegie, is delivering your certification, please direct any questions to the following:
				</span>
			
			<span className={'flex flex-row items-center pt-6'}>
				<AppRIIcon icon={'mail-line'} className={'text-2xl text-primary'} />
				<a href={'mailto:customer.care@dalecarnegie.com'} className={'text-base font-normal break-all pl-2'}>customer.care@dalecarnegie.com</a>
			</span>
		</div>
	);
};

export default Questions;
