import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';
import AssessmentsService from '../../services/AssessmentsService';
import { navigate } from 'gatsby';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const DeleteRosterModal = ({ roster, isOpen, closeModal, onComplete, isDuplicate }) => {
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');

	const _delete = () => {
		setLoading(true);
		AssessmentsService.deleteRoster(roster.id, roster.assessment, isDuplicate).then(
			({ data }) => {
				onComplete(data);
				setLoading(false);
				if (data?.deleted) navigate(`/client/`, { replace: true });
			},
			(error) => {
				setError(error.message);
				setLoading(false);
			}
		);
	};

	const renderModalContent = () =>
		isDuplicate ? (
			<>
				<p className='text-lg text-primary font-medium my-4'>Delete Duplicated Assessment</p>
				<p className='text-md'>Are you sure you want to delete the duplicated assessment from this group?</p>
				<p className='text-md py-4'><span className={'font-medium'}>Note:</span> Deleting the duplicated assessment will not affect the original one.</p>
			</>
		) : (
			<>
				<p className='text-2xl text-primary font-medium my-4'>Confirm Delete</p>
				<p className='text-lg'>Are you sure you wish to delete this participant?</p>
			</>
		);

	return (
		<AppModal
			id={'app-delete-roster-modal-id'}
			isOpen={isOpen}
			style={customStyles}
			onRequestClose={() => {
				setError('');
				closeModal();
			}}
		>
			<div className='max-w-screen-sm bg-white py-4 px-8'>
				{renderModalContent()}
				{!!error && <p className={'text-tiny text-error'}>{error}</p>}
				<div className='flex justify-end my-4'>
					<AppButton
						className={'btn btn-secondary mr-4'}
						text={'Cancel'}
						onClick={closeModal}
						disabled={loading}
						id={'wg-delete-participant-cancel-btn'}
					/>
					<AppButton
						text={'Delete'}
						onClick={_delete}
						loading={loading}
						id={'wg-delete-participant-delete-btn'}
					/>
				</div>
			</div>
		</AppModal>
	);
};
export default DeleteRosterModal;
