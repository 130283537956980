import React, { useState } from 'react';
import Layout from '../components/Layout';
import LiveBeforeSubmit from '../components/livestream/LiveBeforeSubmit';
import LiveAfterSubmit from '../components/livestream/LiveAfterSubmit';
import WorkingGeniusTypes from '../components/homepage/WorkingGeniusTypes';
import Cookies from 'js-cookie';
import DiscoverGenius from '../components/livestream/DiscoverGenius';

const Live = () => {
	const COOKIE_NAME = 'SHOWRECORDINGONLY';
	const cookie = Cookies.get(COOKIE_NAME);
	const [showLive, setShowLive] = useState(!!cookie);

	const onSubmit = (data) => {
		Cookies.set(COOKIE_NAME, true, { expires: 182 });
		setShowLive(true);
	};

	return (
		<Layout title='Livestream | The 6 Types of Working Genius'>
			{showLive ? <LiveAfterSubmit /> : <LiveBeforeSubmit onSubmit={onSubmit} />}
			<DiscoverGenius />
			<WorkingGeniusTypes />
		</Layout>
	);
};

export default Live;
