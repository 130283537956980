import React from 'react';
import AppAudioControls from './AppAudioControl';
import moment from 'moment';

const AppAudioPlayer = ({ title, subtitle, description, image, audioSrc, customClassName = '', mobile = false }) => {
	// State
	const [trackProgress, setTrackProgress] = React.useState(0);
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [speed, setSpeed] = React.useState(1);

	// Refs
	const audioRef = React.useRef(new Audio(audioSrc));
	const intervalRef = React.useRef();

	// Destructure for conciseness
	const { duration } = audioRef.current;

	const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
	const trackStyling = `-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777)) `;

	const startTimer = () => {
		clearInterval(intervalRef.current);

		intervalRef.current = setInterval(() => {
			if (!audioRef.current.ended) {
				setTrackProgress(audioRef.current.currentTime);
			}
		}, [1000]);
	};

	const onScrub = (value) => {
		// Clear any timers already running
		clearInterval(intervalRef.current);
		audioRef.current.currentTime = value;
		setTrackProgress(audioRef.current.currentTime);
	};

	const onScrubEnd = () => {
		// If not already playing, start
		if (!isPlaying) {
			setIsPlaying(true);
		}
		startTimer();
	};

	const onSpeedChange = () => {
		if (speed >= 2) setSpeed(0.5);
		else setSpeed(speed + 0.5);
	}

	const toPrevTrack = () => {
		audioRef.current.currentTime = Math.max(audioRef.current.currentTime - 60, 0);
	};

	const toNextTrack = () => {
		audioRef.current.currentTime = Math.min(audioRef.current.currentTime + 60, duration);
	};

	React.useEffect(() => {
		if (isPlaying) {
			audioRef.current.play();
			startTimer();
		} else {
			audioRef.current.pause();
		}
	}, [isPlaying]);

	React.useEffect(() => {
		// Pause and clean up on unmount
		return () => {
			audioRef.current.pause();
			clearInterval(intervalRef.current);
		};
	}, []);

	React.useEffect(() =>{
		const audio = audioRef.current;
		audio.playbackRate = speed;
	},[speed])

	const formatTime = (seconds) => {
		const time = moment().startOf('day').add(seconds, 'seconds').format('mm:ss');
		return <span className={'text-tiny text-white w-10'}>{time}</span>;
	};

	const renderHeader = () => {
		if(mobile)
		return (
			<div className={'flex flex-col'}>
				<div className={'flex flex-1 flex-col xl:flex-row'}>
					<div className={'flex-1 pb-2'}>
						<p className='text-white text-xl lg:text-2xl'>{title}</p>
					</div>
					<div className={'flex flex-col text-white'}>
						<p className='block xl:hidden text-white font-thin'>{subtitle}</p>
						<AppAudioControls
							isPlaying={isPlaying}
							onPrevClick={toPrevTrack}
							onNextClick={toNextTrack}
							onSpeedChange={onSpeedChange}
							speed={speed}
							onPlayPauseClick={setIsPlaying}
						/>
					</div>
				</div>
				<h2 className='hidden xl:block text-white font-thin'>{subtitle}</h2>
			</div>

		)

		return (
			<div className={'flex flex-1 flex-col md:flex-row'}>
				<div className={'flex-1'}>
					<p className='text-white text-3xl'>{title}</p>
				</div>
				<div className={'text-white'}>
					<AppAudioControls
						isPlaying={isPlaying}
						onPrevClick={toPrevTrack}
						onNextClick={toNextTrack}
						onSpeedChange={onSpeedChange}
						speed={speed}
						onPlayPauseClick={setIsPlaying}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className={`${customClassName ? customClassName : ''} wg-audio-player w-full md:w-2/3 flex-col md:flex-row `}>

			{  image &&
				<div className='flex justify-center items-center md:items-start'>
					<img className='w-40 h-40 rounded-full object-cover' src={image} alt={``} />
				</div>
			}

			<div className={`flex flex-1 flex-col ml-4`}>
				{renderHeader()}
				<div className={'flex flex-col justify-end'}>
					<p className='text-white font-thin'>{description}</p>
					<div className={'flex flex-row justify-center items-center'}>
						{formatTime(trackProgress)}
						<input
							type='range'
							value={trackProgress}
							step='1'
							min='0'
							max={duration ? duration : `${duration}`}
							className='w-full mx-2 cursor-pointer'
							onChange={(e) => onScrub(e.target.value)}
							onMouseUp={onScrubEnd}
							onKeyUp={onScrubEnd}
							style={{ background: trackStyling }}
						/>
						{formatTime(duration)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AppAudioPlayer;
