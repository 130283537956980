import ApiService from './ApiService';
import UtilityService, { serializeQueryString } from './UtilityService';

export const list = (filters) => {
	return new Promise((resolve, reject) => {
		const queryParams = serializeQueryString(filters);
		ApiService.get(`v1/rosters?${queryParams}`)
			.then(({ data }) => {
				data.data = data.data.map((item) => {
					if (
						item.complete &&
						item.new_wc1 == null &&
						(item.wc1.includes('(tie)') ||
							item.wc2.includes('(tie)') ||
							item.wf1.includes('(tie)') ||
							item.wf2.includes('(tie)') ||
							item.wg1.includes('(tie)') ||
							item.wg2.includes('(tie)'))
					) {
						item.hasTies = true;
					}
					return item;
				});
				resolve(data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const generateReports = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`v1/roster/generateReports`, payload, { responseType: 'blob' })
			.then(({ data }) => {
				UtilityService.downloadFileFromBuffer(
					data,
					`assessment-${payload.assessment_id}.zip`,
					'application/x-zip-compressed'
				);
				resolve();
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const getAssessmentId = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.get(`v1/roster/assessment?roster_id=${id}`)
			.then(({ data }) => {
				resolve(data.assessment_id);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const updateWidget = (payload) => {
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/roster/update_widget`, payload).then(resolve, reject);
	});
};

const prepareNewResults = (results) => {
	const pushNew = (items, val) => {
		if (val) {
			items.push(val);
		}
		return items;
	};
	let items = [];
	items = pushNew(items, results[0].props[0]);
	items = pushNew(items, results[0].props[1]);
	items = pushNew(items, results[1].props[0]);
	items = pushNew(items, results[1].props[1]);
	items = pushNew(items, results[2].props[0]);
	items = pushNew(items, results[2].props[1]);
	return items;
};

const find = (email, captcha = null) => {
	return new Promise((resolve, reject) => {
		ApiService.post(`/v1/roster/find`, { email, captcha }).then(({ data }) => {
			resolve({
				_assessments: data.rosters,
				_hasAccount: data.existing_user,
			});
		}, reject);
	});
};

const changeName = (payload) => {
	const { assessment_id, roster_id, first_name, last_name } = payload;
	return new Promise((resolve, reject) => {
		ApiService.put(`/v1/roster/${assessment_id}/${roster_id}`, { first_name, last_name }).then(resolve, reject);
	});
};

export default {
	list,
	generateReports,
	getAssessmentId,
	updateWidget,
	prepareNewResults,
	find,
	changeName,
};
