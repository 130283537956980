import React from 'react'
import AppButton from '../AppButton';
import config from '../../constants/config';
import { ttg_icon, certification_store_logo, workshop_store_logo } from '../../images';

const WhatElseWeOffer = () => {
    return (
        <div className='py-8'>
            <div className='container'>
                <h2 className='font-2xl xl:text-3xl text-gray-800 text-center'>What Else We Offer</h2>
                <hr className='my-4' />
                <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'}>
                    <div className={'bg-primary store-item-back'}>
                        <img src={certification_store_logo} className='mx-2 mb-4' alt='The Table Group' width={30} />
                        <h4 className={'title mb-4'}>Certification</h4>
                        <p className={'description'}>
                            If you want to teach and apply Working Genius - Certification is for you.
                        </p>
                        <p className={'description my-4'}>
                            Gain confidence and experience in this two-day virtual session designed by Patrick Lencioni and The Table Group.
                        </p>
                        <AppButton
                            onClick={() => {
                                window.open('/client/certification');
                            }}
                            className={'btn btn-primary h-12 w-full mt-auto'}
                            text={'Learn more'}
                        />
                    </div>
                    <div className={'bg-primary store-item-back '}>
                        <img src={workshop_store_logo} className='mx-2 mb-4' alt='The Table Group' width={30} />
                        <h4 className={'title mb-4'}>Workshops</h4>
                        <p className={'description'}>
                            The fastest and easiest way to maximize your team's productivity and fulfillment.
                        </p>
                        <p className={'description my-4'}>
                            We help you master and apply Working Genius with your team.
                        </p>
                        <AppButton
                            onClick={() => {
                                window.open('/workshop');
                            }}
                            className={'btn btn-primary h-12 w-full mt-auto'}
                            text={'Learn more'}
                        />
                    </div>
                    <div className={'bg-primary store-item-back '}>
                        <img src={ttg_icon} className='mx-2 mb-4' alt='The Table Group' width={30} />
                        <h4 className={'title mb-4'}>Table Group</h4>
                        <p>
                            Successful companies.<br />
                            Cohesive teams.<br />
                            Engaged employees.<br />
                            We can help you get there.
                        </p>
                        <AppButton
                            onClick={() => window.open(`${config.ttg_url}`)}
                            className={'btn btn-primary h-12 w-full mt-auto'}
                            text={'Learn more'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatElseWeOffer;