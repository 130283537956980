import {
	podcast_at_the_table,
	podcast_book,
	podcast_certification,
	podcast_the_assessment,
	podcast_tile_assessment,
	podcast_tile_audio,
	podcast_tile_certification,
	podcast_tile_workshop,
} from '../../images';
import React from 'react';
import config from '../../constants/config';

const TypeEnum = {
	DETAILS: 'details',
	MAIN: 'main',
};

const SortEnum = {
	NEWEST: 'newest',
	OLDEST: 'oldest',
};

const relatedItems = [
	{
		img: podcast_book,
		title: 'The Book',
		button: 'Order Now',
		url: `${config.base_url}/book/`,
	},
	{
		img: podcast_at_the_table,
		title: 'At The Table Podcast',
		button: 'Listen Now',
		url: `${config.ttg_url}/at-the-table/`,
	},
	{
		img: podcast_certification,
		title: 'Certification',
		button: 'Learn More',
		url: `${config.base_url}/certification/`,
	},
	{
		img: podcast_the_assessment,
		title: 'The Assessment',
		button: 'Take Now',
		url: `${config.base_url}/about/assessment`,
	},
];

const tileItems = [
	{
		image: podcast_tile_assessment,
		body: (
			<>
				<h2 className={'text-white text-xl font-semibold my-4'}>Take the Assessment Today</h2>
				<p className={'text-white'}>
					The Six Types of Working Genius assessment is the fastest way to discover your natural gifts and
					thrive at work.
				</p>
			</>
		),
		url: `${config.base_url}/about/assessment`,
		isTile: true,
	},
	{
		image: podcast_tile_certification,
		body: (
			<>
				<h2 className={'text-white text-xl font-semibold my-4'}>Get Certified in Working Genius</h2>
				<p className={'text-white'}>
					Learn first-hand how to help companies, individuals, and teams maximize The Working Genius.
				</p>
			</>
		),
		url: `${config.base_url}/certification/`,
		isTile: true,
	},
	{
		image: podcast_tile_workshop,
		body: (
			<>
				<h2 className={'text-white text-xl font-semibold my-4'}>Interested in a Workshop</h2>
				<p className={'text-white'}>
					A Table Group Master Facilitator guides your team through an interactive workshop to help you
					leverage and apply all the facets of Working Genius.
				</p>
			</>
		),
		url: `${config.base_url}/workshop/`,
		isTile: true,
	},
	{
		image: podcast_tile_audio,
		body: (
			<>
				<h2 className={'text-white text-xl md:text-3xl font-semibold my-4'}>Where should I start?</h2>
				<p className={'text-white text-lg md:text-xl'}>
					The best episodes to get you started on your Working Genius journey.
				</p>
			</>
		),
		url: `${config.base_url}/resources#podcasts`,
		isTile: true,
		tileIndex: 1,
		buttonText: 'Listen now',
	},
];

const getAudioDuration = (audioSrc, setAudioDuration) => {
	const audio = document.createElement('audio');
	audio.src = audioSrc;

	audio.addEventListener('loadedmetadata', () => {
		setAudioDuration(Math.round(audio.duration / 60));
	});
};

export const podcastHelpers = {
	TypeEnum,
	SortEnum,
	relatedItems,
	tileItems,
	getAudioDuration,
};
