import React from 'react';
import {
	qualitative_bars,
	qualitative_checkboxes,
	qualitative_equals,
	qualitative_gear_li,
	qualitative_plus,
} from '../../../../images';

const QuantitativeReview = ({ assessment }) => {
	return (
		<div className='Page__content'>
			<h1>Qualitative Review</h1>

			<p>
				<span className={'uppercase'}>{assessment.participant.fname}</span>, if your results don&rsquo;t
				resonate with you, that&rsquo;s okay. This section will help you make a clear and final decision, and
				just as importantly, it will allow you to really understand the six types.
			</p>

			<p>
				At the end of each of the following six descriptions, choose the response option that best aligns with
				your reaction to that type. Pay special attention to the wording associated with each choice.
			</p>

			<p>So, even if your results were extremely clear, take the time to read through this section.</p>

			<div className='math'>
				<div>
					<div className='box'>
						<h4>
							QUANTITATIVE
							<br />
							RESULTS
						</h4>
						<img src={qualitative_bars} alt={''} />
					</div>
					<span className='pagetag'>pg. 2</span>
				</div>

				<div className='plus'>
					<img src={qualitative_plus} />
				</div>

				<div>
					<div className='box qual'>
						<h4>
							QUALITATIVE
							<br />
							REVIEW
						</h4>
						<img src={qualitative_checkboxes} />
					</div>
					<span className='pagetag'>pg. 4-9</span>
				</div>

				<div className='equals'>
					<img src={qualitative_equals} />
				</div>

				<div>
					<div className='box final'>
						<h4>
							FINAL
							<br />
							DETERMINATION
						</h4>
						<img src={qualitative_gear_li} />
						<img src={qualitative_gear_li} />
						<img src={qualitative_gear_li} />
					</div>
					<span className='pagetag'>pg. 10</span>
				</div>
			</div>
		</div>
	);
};

export default QuantitativeReview;
