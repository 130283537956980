import React from 'react';
import Result from './Result';

const ActualResult = ({ result, className, backgroundColor }) => {
	if (result) {
		return <Result letter={result[0]} className={className} backgroundColor={backgroundColor} />;
	}
	return null;
};

export default ActualResult;
