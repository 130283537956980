import React from 'react';
import Layout from '../../../components/Layout';
import Permissions from '../../../components/permissions/Permissions';
import { useUser } from '../../../hooks/UserProvider';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import UserManagementList from '../../../components/permissions/UserManagementList';

const Index = () => {
    const [enabled, setEnabled] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const { user, setUser } = useUser();

    React.useEffect(() => {
        setEnabled(user.has_permissions_enabled);
    }, []);

    return (
        <Layout title='The 6 Types of Working Genius: Permissions'>
            <div className='container m-4'>
                <h1 className='text-primary text-4xl font-medium mt-6'>Permissions</h1>
                <Tabs selectedIndex={selectedIndex} onSelect={setSelectedIndex}>
                    {!!enabled && (
                        <TabList>
                            <Tab className={'react-tabs__tab wg-licenses-tab mt-6'}>User Management</Tab>
                            <Tab className={'react-tabs__tab wg-account-orders-tab mt-6'}>Permission Options</Tab>
                        </TabList>
                    )}
                    {!!enabled && (
                        <TabPanel>
                            <UserManagementList />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <Permissions
                            enabled={user.has_permissions_enabled}
                            onSuccess={() => {
                                setEnabled(true);
                                setSelectedIndex(0);
                            }}
                        />
                    </TabPanel>
                </Tabs>
            </div>
        </Layout>
    );
};

export default Index;
