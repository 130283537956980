import React from 'react';
import { Link, navigate } from 'gatsby';
import { team_application_guide_logo } from '../../images';
import UtilityService from '../../services/UtilityService';
import AppIcon from './../AppIcon';
import AppModal from './../AppModal';
import AppRIIcon from './../AppRIIcon';

const HamburgerMenu = ({ setActiveTab }) => {
	const [openModal, setOpenModal] = React.useState(false);

	const onClickLink = (id) => {
		setTimeout(function () {
			setActiveTab(id);
			UtilityService.scrollToTop();
		}, 500);
		setOpenModal(false);
	};

	return (
		<nav className={'navbar toolkit-navbar'}>
			<div className='nav-header p-4'>
				<Link to='/application-guide-product'>
					<img src={team_application_guide_logo} alt='Toolkit logo' />
				</Link>
				<button className='toolkit-btn' onClick={() => setOpenModal(true)} id={'wg-toolkit-hamburger-btn'}>
					<AppIcon icon='align-justify' className='burger-menu' />
				</button>
			</div>

			<AppModal isOpen={openModal} className='nav-modal' overlayClassName='overlay'>
				<div className={'modal-header'}>
					<button onClick={() => setOpenModal(false)} className='close-btn'>
						<AppIcon icon='cancel' className='modal-cancel' />
					</button>
				</div>

				<div className={'modal-nav-links'}>
					<div className={'modal-nav-link'} onClick={() => onClickLink('tab1')}>
						Welcome
					</div>
					<div className={'modal-nav-link'} onClick={() => onClickLink('tab2')}>
						1. Sending &amp; Gathering Assessments
					</div>
					<div className={'modal-nav-link'} onClick={() => onClickLink('tab3')}>
						2. Reading a Team Map
					</div>
					<div className={'modal-nav-link'} onClick={() => onClickLink('tab4')}>
						3. Leading a Team Discussion
					</div>
					<div className={'modal-nav-link'} onClick={() => onClickLink('tab5')}>
						Working Genius for Meetings, Hiring &amp; Staffing
					</div>
					<div
						className={'modal-nav-link'}
						onClick={() => {
							setTimeout(function () {
								navigate('/client/');
								UtilityService.scrollToTop();
							}, 500);
							setOpenModal(false);
						}}
					>
						<AppRIIcon
							icon='arrow-left-line'
							className='text-white bg-primary p-2 rounded-full font-semibold mr-4'
						/>
						Back to Assessments
					</div>
				</div>
			</AppModal>
		</nav>
	);
};

export default HamburgerMenu;
