const craves = {
	consideration: {
		title: 'Erwägen. ',
		description:
			'Sie wollen, dass andere wirklich über die Dinge nachdenken, die sie hinterfragen, und dass ihre Überlegungen gehört werden. “Hey, das ist eine großartige Frage.”',
	},
	freedom: {
		title: 'Freiheit. ',
		description: 'Sie wollen Freiheit haben, ohne Zwänge und Beschränkungen zu schaffen und zu erfinden.',
	},
	trust: {
		title: 'Vertrauen. ',
		description: 'Sie wollen, dass Sie ihrem Urteil, ihren Instinkten und ihrer Einschätzung der Dinge vertrauen.',
	},
	reaction: {
		title: 'Reaktion. ',
		description:
			'Sie wollen Engagement und die Bestätigung, dass die Sache, für die sie eintreten, real ist und sich lohnt.',
	},
	appreciation: {
		title: 'Würdigung. ',
		description:
			'Sie möchten geschätzt werden für ihren eigenen Wert aber suchen nicht unbedingt nach Anerkennung, Beachtung, oder Lob. „Das hat einen großen Unterschied gemacht. Du hast wirklich geholfen.”',
	},
	clarity: {
		title: 'Klarheit. ',
		description:
			'Sie wollen Fristen und Vorgaben. “Sag mir genau, was du brauchst, und dann lass mich das tun, was notwendig ist, um die Aufgabe abzuschließen.“',
	},
};

const crushes = {
	being_dismissed: {
		title: 'Abgetan werden. ',
		description:
			'Sie möchten nicht hören “Wen kümmert’s!?” oder “Wir haben jetzt keine Zeit, darüber nachzudenken.”',
	},
	constraint: {
		title: 'Beschränkung. ',
		description: 'Sie mögen keine einengenden Grenzen, die verbieten, über den Tellerrand hinauszuschauen.',
	},
	prove_it: {
		title: '“Beweise es.” ',
		description: 'Sie möchten nicht argumentieren müssen, damit die Leute ihrem Bauchgefühl glauben.',
	},
	apathy: {
		title: 'Gleichgültigkeit. ',
		description:
			'Sie hassen es, wenn niemand auf ihre Aufrufe zum Handeln reagiert oder wenn ihr Enthusiasmus auf Schweigen stößt.',
	},
	being_overlooked: {
		title: 'Übersehen werden. ',
		description:
			'Sie mögen es nicht, wenn ihre Bemühungen als selbstverständlich angesehen werden - wenn man nicht anerkennt, dass sie geholfen haben, oder wenn man die Auswirkungen ihrer Arbeit ignoriert.',
	},
	ambiguity: {
		title: 'Mehrdeutigkeit. ',
		description: 'Sie mögen keine unklaren Regeln und Anweisungen oder Erwartungen, die sich plötzlich ändern.',
	},
};

const HE = {
	sort: 1,
	title: 'Der kreative Träumer',
	brief: 'Ein leidenschaftlicher Idealist mit einem endlosen Strom von großen Fragen und Ideen, die es zu berücksichtigen gilt. Fühlt sich ganz in seinen Ideen versunken wohl.',
	description:
		'Menschen mit dieser Kombination schöpfen wahre Freude und Energie daraus, die Welt um sie herum zu betrachten und eine Fülle neuer Ideen zu entwickeln.  Im Allgemeinen sind sie idealistisch und zukunftsorientiert, und sie begeistern sich für neue Möglichkeiten, die unnötige Grenzen durchbrechen.  Sie legen nicht so viel Wert auf Praktikabilität, Konzentration oder Umsetzung wie auf Idealismus und Einfallsreichtum.  Das führt manchmal zu Stress oder Chaos in ihrem Umfeld, wo sie eigentlich Realismus und Umsetzbarkeit suchen.',
	crave: [craves.consideration, craves.freedom],
	crush: [crushes.being_dismissed, crushes.constraint],
};

const EI = {
	sort: 2,
	title: 'Der abwägende Erfinder',
	brief: 'Jemand, der kreativ, intuitiv und selbstbewusst neue Ideen entwickelt. Nutzt Instinkt und integratives Denken, um echte Probleme zu lösen.',
	description: `Menschen mit dieser Kombination haben viel Energie und Freude daran, ihre Intuition und Instinkthaftigkeit zu nutzen, um neue Ideen von Grund auf zu entwickeln. Sie sind gut darin, integratives Denken und Mustererkennung anstelle von Daten und Fachwissen einzusetzen.  Daher sind sie besonders gut in der Lage, Probleme zu lösen und praktikable Lösungen zu finden, und zwar mit besonders großem Erfolg.  Sie können manchmal ungeduldig und übermütig erscheinen, wenn sie mit ihren Ideen auf Widerstand stoßen, und sie verlieren oft das Interesse, nachdem die Anfangsphase des Erfindens vorbei ist.`,
    crave: [craves.freedom, craves.trust],
	crush: [crushes.constraint, crushes.prove_it],
};

const IU = {
	sort: 3,
	title: 'Der umsichtige Partner',
	brief: 'Ein intuitiver, einfühlsamer Berater und Teamplayer. Selbstlos und mitfühlend, wenn es darum geht, anderen das zu geben, was sie brauchen, und zwar auf die richtige Weise.',
	description: `Menschen mit dieser Paarung schöpfen wahre Freude und Energie daraus, dass sie anderen auf der Grundlage ihrer Intuition und ihres Instinkts kluge und einfühlsame Hilfe leisten.  Sie sind der Inbegriff von Teamplayern, die auf selbstlose Weise außergewöhnliche, rechtzeitige und scharfsinnige Ratschläge geben.  Sie zögern manchmal, andere zu drängen, ihren Rat zu befolgen, und können anderen gegenüber nachgiebig sein, wenn sie Widerstand spüren.  Diese Tendenzen können dazu führen, dass sie sich nicht ausreichend gewürdigt fühlen.`,
    crave: [craves.trust, craves.appreciation],
	crush: [crushes.prove_it, crushes.being_overlooked],
};

const HI = {
	sort: 4,
	title: 'Der nachdenkliche Berater',
	brief: 'Ein aufmerksamer, einsichtsvoller und differenzierter Berater. Langsam, wenn es darum geht, Gewissheit zu äußern, aber tiefgründig in Bezug auf Weisheit, Intuition und Vernunft.',
	description: `Menschen mit dieser Kombination empfinden echte Freude und Befriedigung, wenn sie ihre Intuition und ihren Instinkt einsetzen, um Ideen und Pläne zu bewerten und anderen ein durchdachtes Feedback zu geben.  Sie neigen dazu, äußerst aufmerksam und tiefgründig zu denken, und drücken ihre Erkenntnisse mit wirkungsvollen Worten aus.  Aufgrund ihrer Neigung zu Details sind sie manchmal zögerlich, wenn es darum geht, Gewissheit oder Vertrauen in ihr eigenes Urteilsvermögen auszudrücken.`,
	crave: [craves.consideration, craves.trust],
	crush: [crushes.being_dismissed, crushes.prove_it],
};

const EM = {
	sort: 5,
	title: 'Der inspirierende Innovator',
	brief: 'Ein begeisterungsfähiger und überzeugender Entwickler und Förderer neuer Ideen. Vereint Neugierde und Zuversicht mit ansteckendem Enthusiasmus.',
	description: `Menschen mit dieser Kombination schöpfen wahre Freude und Energie aus dem Entwickeln neuer Ideen und wenn sie andere vom Wert einer Idee überzeugen können.  Sie sind überschwänglich und enthusiastisch und lassen sich von anfänglichen Widerständen nicht so leicht abschrecken. Sie genießen die Herausforderung, ihre Ideen mit anderen zu teilen, um sie zum Handeln zu bewegen.  Sie können vorschnell Ideen kommunizieren, noch bevor sie sich die Zeit genommen haben, die Zweckmäßigkeit einer Idee vollständig zu prüfen. So können sie anfangs als etwas übertrieben in ihrem Ansatz wahrgenommen werden.`,
    crave: [craves.freedom, craves.reaction],
	crush: [crushes.constraint, crushes.apathy],
};

const IB = {
	sort: 6,
	title: 'Der urteilsstarke Vollender',
	brief: 'Ein zuverlässiger, umsichtiger und zielstrebiger Macher. Eine einzigartige Kombination aus praktischer Dringlichkeit und intuitivem Urteilsvermögen.',
	description: `Menschen mit dieser Kombination schöpfen wahre Freude und Energie daraus, die richtigen Dinge effizient und effektiv zu erledigen. Dabei setzen sie ihr Urteilsvermögen ein, um Zeit- und Ressourcenverschwendung zu vermeiden.  Sie erledigen viel, indem sie die höchsten Prioritäten festlegen und sich selbst an hohe Standards bei der Ausführung halten.  Sie sind umsichtig, konzentriert und fleißig.  Sie haben oft unnötige Angst vor der Möglichkeit, zu versagen oder mit anderen zusammenzuarbeiten, die ihre hohen Standards nicht teilen, wenn sie auf einen Abschluss hinarbeiten.`,
    crave: [craves.trust, craves.clarity],
	crush: [crushes.prove_it, crushes.ambiguity],
};

const HM = {
	sort: 7,
	title: 'Der aufmerksame Motivator',
	brief: 'Ein begeisterungsfähiger, neugieriger und enthusiastischer Fürsprecher für Ideen und Menschen. Eine einzigartige Kombination aus Eifer und Vorsicht.',
	description: `Menschen mit dieser Kombination schöpfen wahre Freude und Energie aus der Betrachtung des Potenzials in der Welt um sie herum, und sie ermuntern und ermutigen andere leidenschaftlich.  Sie sind idealistisch und enthusiastisch.  Diese Kombination führt dazu, dass sie manchmal ein Gefühl von Impulsivität oder Unberechenbarkeit erleben und vermitteln, da sie hin und her schwanken zwischen leidenschaftlichem Handeln und vorsichtigem Nachdenken.`,
	crave: [craves.consideration, craves.reaction],
	crush: [crushes.being_dismissed, crushes.apathy],
};

const EU = {
	sort: 8,
	title: 'Der flexible Gestalter',
	brief: 'Ein Entwickler neuer Ideen als Reaktion auf die Anforderungen anderer. Eine einzigartige Kombination aus Kreativität und Flexibilität.',
	description: 'Menschen mit dieser Kombination schöpfen wahre Freude und Energie daraus, wenn sie neue Lösungen und Ideen bieten können, die den speziellen Bedürfnissen anderer dienen.  Sie haben die seltene Fähigkeit, ihre Leidenschaft für Erfindungsreichtum mit der Fähigkeit in Einklang zu bringen, sich auf die Wünsche der Menschen einzustellen, denen sie dienen.  Sie sind kreativ, flexibel, offen für Feedback und es ist einfach, mit ihnen zu arbeiten.  In ihrem Wunsch, es anderen recht zu machen, passen sie sich zu schnell an und vermeiden es, für ihre eigenen Ideen einzutreten.  Dies kann dazu führen, dass sie sich geringgeschätzt fühlen.',
	crave: [craves.freedom, craves.appreciation],
	crush: [crushes.constraint, crushes.being_overlooked],
};

const MU = {
	sort: 9,
	title: 'Der enthusiastische Ermutiger',
	brief: 'Ein warmherziger und positiver Mensch. Unterstützt und inspiriert schnell Menschen, die Energie oder Bestärkung brauchen.',
	description: `Menschen mit dieser Kombination schöpfen Freude und Energie daraus, andere zu inspirieren und zu unterstützen, damit sie wachsen, sich verbessern und sich selbst gut fühlen.  Sie sind positiv, überschwänglich und optimistisch und zeigen gerne ihre Begeisterung über das Potenzial anderer.  Sie sind vollendete Cheerleader, im wahrsten Sinne des Wortes.  In ihrem Wunsch, positiv zu sein, sind sie manchmal zu schnell bereit, andere zu bestätigen oder ungerechtfertigtes Lob auszusprechen, was dazu führen kann, dass sie abgewiesen oder als naiv oder unaufrichtig wahrgenommen werden.`,
    crave: [craves.reaction, craves.appreciation],
	crush: [crushes.apathy, crushes.being_overlooked],
};

const HU = {
	sort: 10,
	title: 'Der idealistische Unterstützer',
	brief: 'Ein loyaler, bescheidener und aufmerksamer Mitarbeiter. Berücksichtigt sorgfältig die Anforderungen anderer und zögert, Aufmerksamkeit einzufordern.',
	description:
		'Menschen mit dieser Kombination empfinden echte Freude und schöpfen Energie, wenn sie Anliegen entscheidend unterstützen können, die mit ihren Werten und ihrem Zugehörigkeitsgefühl übereinstimmen.  Sie sind oft bescheiden in Bezug auf ihre Fähigkeiten, loyal und uneigennützig und erweisen sich als äußerst wertvolle, pflegeleichte Teammitglieder.  Aufgrund ihrer Tiefgründigkeit und ihres Wunsches, es anderen recht zu machen, können sie manchmal übermäßig respektvoll sein und zögern oft, Veränderungen zu initiieren, da sie es vorziehen, anderen den ersten Schritt zu überlassen.',
	crave: [craves.consideration, craves.appreciation],
	crush: [crushes.being_dismissed, crushes.being_overlooked],
};

const EB = {
	sort: 11,
	title: 'Der methodische Architekt',
	brief: 'Ein präziser, zuverlässiger und planvoller Problemlöser. Eine einzigartige Kombination aus innovativem Denken und praktischer Implementierung.',
	description: `Menschen mit dieser Kombination haben Freude und Energie daran, praktische Lösungen für Probleme zu entwickeln, die auf Einfallsreichtum, Rationalität und Präzision beruhen.  Sie arbeiten gleichmäßig und zuverlässig, wenn sie neue Ideen entwickeln, und lassen sich nicht so schnell von Möglichkeiten ablenken, die sich als logistisch nicht machbar erweisen könnten.  In ihrem verbissenen Streben nach einer praktikablen Lösung können sie manchmal die Nuancen einer bestimmten Situation oder auch die Bedürfnisse anderer übersehen.`,
	crave: [craves.freedom, craves.clarity],
	crush: [crushes.constraint, crushes.ambiguity],
};

const MB = {
	sort: 12,
	title: 'Der überzeugende Beweger',
	brief: 'Eine außerordentliche Führungspersönlichkeit. Bereit, andere zu motivieren und zu erinnern und sich selbst einzubringen, um sicherzustellen, dass die Arbeit erledigt wird.',
	description: `Menschen mit dieser Kombination schöpfen Freude und Energie daraus, Dinge zu erledigen und andere dazu zu bringen, mit ihnen etwas zu erledigen.  Es macht ihnen nichts aus, Hindernisse zu überwinden, und haben sogar kein Problem damit, von Zeit zu Zeit gesellschaftliche Regeln zu ignorieren, wenn dies erforderlich ist, um etwas zu Ende zu bringen.  Sie sind Menschen mit hoher Überzeugung und hohen Ansprüchen.  Verständlicherweise können sie manchmal als aufdringlich empfunden werden und sind oft ungeduldig, wenn es um zu viel Planung und Brainstorming geht; sie ziehen es vor, sich sofort an die Arbeit zu machen.`,
    crave: [craves.reaction, craves.clarity],
	crush: [crushes.apathy, crushes.ambiguity],
};

const HB = {
	sort: 13,
	title: 'Der gründliche Realisierer',
	brief: 'Ein gründlicher, praktischer und sorgfältiger Mitarbeiter. Eine einzigartige Kombination aus beständiger Zuverlässigkeit und ruhiger Tiefe.',
	description: `Menschen mit dieser Kombination empfinden echte Freude und Befriedigung, wenn sie Dinge gründlich und zuverlässig erledigen, solange sie den Sinn und die Bedeutung ihres Tuns nachvollziehen können.  Sie sind eine geheimnisvolle Mischung aus Sachlichkeit und Wissbegierde, Aktion und Reflexion.  Aufgrund der Divergenz zwischen Hinterfragen und Beharrlichkeit (sie befinden sich an den entgegengesetzten Enden des Arbeitsprozesses) empfinden sie inmitten eines Projekts oft Angst und projizieren diese, indem sie ein fast überwältigendes Bedürfnis verspüren, langsamer zu agieren und Fragen zu stellen, die mögliche Probleme abmildern könnten.`,
	crave: [craves.consideration, craves.clarity],
	crush: [crushes.being_dismissed, crushes.ambiguity],
};

const IM = {
	sort: 14,
	title: 'Der intuitive Mobilisierer',
	brief: 'Ein selbstbewusster Entscheidungsträger mit Instinkt. Fähig, Situationen schnell und genau einzuschätzen und Menschen zum Handeln zu bewegen.',
	description: `Menschen mit dieser Kombination haben viel Freude und Energie daran, Ideen und Situationen instinktiv zu bewerten und schnell den richtigen Weg nach vorne zu erkennen.  Sie treffen in der Regel schnelle und erstaunlich genaue Einschätzungen, oft ohne spezifisches Wissen oder Daten, und bringen andere geschickt dazu, ihnen und ihren Handlungsempfehlungen zuzustimmen.  Aufgrund ihrer Fähigkeiten und ihres Selbstbewusstseins können sie in ihren Erklärungen manchmal leichtfertig sein und ungeduldig oder abweisend wirken, wenn sie aufgefordert werden, entscheidende Details zu berücksichtigen, die für die Umsetzung erforderlich sind.`,
	crave: [craves.trust, craves.reaction],
	crush: [crushes.prove_it, crushes.apathy],
};

const UB = {
	sort: 15,
	title: 'Der loyale Umsetzer',
	brief: 'Ein reaktionsschneller, zuverlässiger Macher. Ist bereit, sich zu engagieren, wenn etwas benötigt wird, und entschlossen, Verpflichtungen zu erfüllen.',
	description: `Menschen mit dieser Kombination schöpfen Freude und Energie aus der Erledigung von Aufgaben und aus dem Eingehen auf die Bedürfnisse anderer, während die Dinge erledigt werden.  Sie sind schnell zur Stelle, wenn man sie zum Handeln auffordert, und legen gleichermaßen Wert auf Reaktionsbereitschaft und Zuverlässigkeit.  Sie sind pflegeleichte Mitarbeiter, die sich selbst für die Einhaltung ihrer Versprechen verantwortlich fühlen.  Es fällt ihnen schwer, „Nein“ zu Anfragen zu sagen, sind häufig überengagiert, neigen zu Burn-out und mangelnder Wertschätzung.`,
    crave: [craves.appreciation, craves.clarity],
	crush: [crushes.being_overlooked, crushes.ambiguity],
};

const getAbility = (ability) => {
    const mapping = {
        Wonder: 'Hinterfragen',
        Invention: 'Erfinden',
        Discernment: 'Intuition',
        Galvanizing: 'Motivation',
        Enablement: 'Unterstützen',
        Tenacity: 'Beharrlichkeit',
    };

    return mapping[ability];
};

const getCategory = (category) => {
    const mapping = {
        genius: 'Genius',
        competency: 'Competency',
        frustration: 'Frustration',
    };

    return mapping[category];
};

const getUniquePairing = (a, b) => {
    const sortWord = 'WIDGET';
    const first_letter = a[0];
    const second_letter = b[0];
    const first_idx = sortWord.indexOf(first_letter);
    const second_idx = sortWord.indexOf(second_letter);
    const mapping = { W: 'H', I: 'E', D: 'I', G: 'M', E: 'U', T: 'B' };
    return first_idx < second_idx
        ? `${mapping[first_letter]}${mapping[second_letter]}`
        : `${mapping[second_letter]}${mapping[first_letter]}`;
};

const ReportUniquePairingsDE = {
    genius_color: '#00a049',
    competency_color: '#fbc331',
    frustration_color: '#c06e60',
    getUniquePairing,
    getAbility,
    getCategory,
    UniqueParings: {
        HE,
        EI,
        IU,
        HI,
        EM,
        IB,
        HM,
        EU,
        MU,
        HU,
        EB,
        MB,
        HB,
        IM,
        UB,
    },
    Results: {
        Genius: (a, b, color) =>
            `Ihre Bereiche des Working Genius sind <span class="font-semibold" style="color: ${color}">${a}</span> und <span class="font-semibold" style="color: ${color}">${b}</span>.`,
        Competency: (a, b, color) =>
            `Ihre Bereiche der Working Competency sind <span class="font-semibold" style="color: ${color}">${a}</span> und <span class="font-semibold" style="color: ${color}">${b}</span>.`,
        Frustration: (a, b, color) =>
            `Ihre Bereiche der Working Frustration sind <span class="font-semibold" style="color: ${color}">${a}</span> und <span class="font-semibold" style="color: ${color}">${b}</span>.`,
        Hinterfragen: {
            genius: `Sie sind gut darin und haben Spaß daran, über mehr Möglichkeiten und größere Chancen in einer bestimmten Situation nachzudenken.`,
            competency: `Sie sind in der Lage und haben keine Probleme damit, über mehr Möglichkeiten und größere Chancen in einer bestimmten Situation nachzudenken.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freude daraus, über mehr Möglichkeiten und größere Chancen in einer bestimmten Situation nachzudenken.`,
        },
        Erfinden: {
            genius: `Sie sind gut darin und haben Spaß daran, originelle und neuartige Ideen und Lösungen zu entwickeln.`,
            competency: `Sie sind in der Lage und haben kein Problem damit, originelle und neuartige Ideen und Lösungen zu entwickeln.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freude daraus, originelle und neuartige Ideen und Lösungen zu entwickeln.`,
        },
        Intuition: {
            genius: `Sie sind gut darin und haben Spaß daran, Ihre Intuition und Ihren Instinkt einzusetzen, um Ideen oder Pläne zu bewerten und zu beurteilen.`,
            competency: `Sie sind in der Lage und haben kein Problem damit, Ihre Intuition und Ihren Instinkt einzusetzen, um Ideen oder Pläne zu bewerten und zu beurteilen.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freude daraus, Ihre Intuition und Ihren Instinkt einzusetzen, um Ideen oder Pläne zu bewerten und zu beurteilen.`,
        },
        Motivation: {
            genius: `Sie sind gut darin und haben Spaß daran, Menschen für ein Projekt, eine Aufgabe oder eine Idee zu gewinnen und sie zum Handeln zu bewegen.`,
            competency: `Sie sind in der Lage und haben kein Problem damit, Menschen für ein Projekt, eine Aufgabe oder eine Idee zu gewinnen und sie zum Handeln zu bewegen.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freude daraus, Menschen für ein Projekt, eine Aufgabe oder eine Idee zu gewinnen und sie zum Handeln zu bewegen.`,
        },
        Unterstützen: {
            genius: `Sie sind gut darin und haben Spaß daran, andere bei Projekten und Ideen zu ermutigen und zu unterstützen.`,
            competency: `Sie sind in der Lage und haben kein Problem damit, andere bei Projekten und Ideen zu ermutigen und zu unterstützen.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freudedaraus, andere bei Projekten und Ideen zu ermutigen und zu unterstützen.`,
        },
        Beharrlichkeit: {
            genius: `Sie sind gut darin und haben Spaß daran, Projekte und Aufgaben bis zum Abschluss voranzutreiben, um sicherzustellen, dass die gewünschten Ergebnisse erzielt werden.`,
            competency: `Sie sind in der Lage und haben kein Problem damit, Projekte und Aufgaben bis zum Abschluss voranzutreiben, um sicherzustellen, dass die gewünschten Ergebnisse erzielt werden.`,
            frustration: `Sie sind nicht von Natur aus begabt und/oder schöpfen keine Energie und Freude daraus, Projekte und Aufgaben bis zum Abschluss voranzutreiben, um sicherzustellen, dass die gewünschten Ergebnisse erzielt werden.`,
        },
    },
    Geniuses: {
        genius: {
            description: (a, b, color) =>
                `Nach Ihrem Assessment verfügen Sie über das Genius <span class="font-bold uppercase" style="color: ${color}">${a}</span> und <span class="font-bold uppercase" style="color: ${color}">${b}</span>, die im Folgenden beschrieben werden. Das sind die Tätigkeiten, die Ihnen Freude, Energie und Leidenschaft geben. Deshalb sind Sie in diesen Bereichen ziemlich geschickt.`,
            note: ``,
        },
        competency: {
            description: (a, b, color) =>
                `Nach Ihrem Assessment sind Ihre Working Competencies <span class="font-bold uppercase" style="color: ${color}">${a}</span> und <span class="font-bold uppercase" style="color: ${color}">${b}</span>, die im Folgenden beschrieben werden. Das sind die Tätigkeiten, die Sie weder ganz schlecht noch ganz hervorragend finden. Es ist wichtig, dass Sie sich über Ihre Competencies im Klaren sind, denn die meisten Menschen können in diesen Bereichen eine Zeit lang recht gut arbeiten. Sie werden jedoch irgendwann erschöpft sein, wenn Sie keine Gelegenheit haben, Ihre wahren Geniuses zu anzuwenden.`,
            note: `Man sollte wissen, dass Menschen ziemlich gut bei Tätigkeiten im Bereich ihrer Working Competency werden, entweder weil sie durch ihre berufliche Laufbahn oder ihre Lebensumstände dazu gezwungen sind, oder weil sie einen starken Drang haben, in fast allem, was sie tun, hervorragende Leistungen zu erbringen. Diese Menschen werden jedoch nicht die gleiche Art von Freude und Energie aus diesen Tätigkeiten schöpfen, und sie sind auch nicht so natürlich begabt darin im Vergleich zu den Bereichen ihres Working Genius. Außerdem kann es zu Verbitterung und Burnout führen, zu lange in den Bereichen der eigenen Working Competency zu arbeiten.`,
        },
        frustration: {
            description: (a, b, color) =>
                `Nach Ihrem Assessment sind Ihre Bereiche der Frustration <span class="font-bold uppercase" style="color: ${color}">${a}</span> und <span class="font-bold uppercase" style="color: ${color}">${b}</span>, die im Folgenden beschrieben werden. Es ist aus zwei Gründen besonders wichtig, diese Bereiche zu kennen. Erstens sollten Sie vermeiden, zu viel Zeit mit Aktivitäten in diesen Bereichen zu verbringen, da dies in der Regel zu Ermüdung und Unzufriedenheit führt. Zweitens möchten Sie vermeiden, sich unnötig zu schämen oder schuldig zu fühlen, weil Sie in diesen Bereichen nicht auf natürliche Weise begabt sind.`,
            note: `Beachten Sie, dass manche Menschen Tätigkeiten, die in ihre Bereiche der Working Frustrations fallen, sehr gut beherrschen, entweder weil sie durch ihre berufliche Laufbahn oder durch Lebensumstände dazu gezwungen wurden, oder weil sie ein grenzenloses Verlangen haben, in fast allem, was sie tun, hervorragende Leistungen zu erbringen. Allerdings schöpfen diese Menschen keine wirkliche Freude und Energie aus diesen Tätigkeiten und sind darin nicht so natürlich begabt wie in den Bereichen ihres Working Genius. Mehr noch, wenn sie lange Zeit in den eigenen Bereichen der Frustration arbeiten, führt das zu Verzweiflung und Burnout.`,
        },
        Wonder: `Zum Genius des Hinterfragens gehört, dass man grübelt und Fragen stellt, dass man über die Gründe nachdenkt, warum die Dinge so sind, wie sie sind. Menschen mit diesem Genius schöpfen Freude und Energie daraus, ihre Umgebung aufmerksam zu beobachten und sich zu fragen, ob es nicht einen anderen oder besseren Weg geben könnte. Im Vergleich zu anderen Geniuses ist das Hinterfragen nicht immer gleich zu erfassen, da es sich um einen größtenteils inneren Prozess handelt. Dennoch beginnt fast jede neue Initiative, jedes Programm oder Projekt damit, dass jemand etwas hinterfragt hat. Zu den Vorteilen dieses Genius gehört es, wichtige Fragen zu stellen, die Menschen dazu zu bringen, Annahmen zu überdenken, wo dies nötig sein könnte, sowie den Status quo in Frage zu stellen.`,
        Invention: `Das Genius des Erfindens zeichnet sich dadurch aus, neuartige Ideen und Lösungen zu präsentieren, um Probleme zu lösen und Fragen anzugehen. Erfinden umfasst Kreativität und unkonventionelles Denken, oft mit wenigen Vorgaben. Menschen mit diesem Genius sind zuversichtlich und lassen sich von einem Problem anregen, für das es keine offensichtliche Lösung gibt. Sie schöpfen Energie und Freude aus der Möglichkeit, eine neue Idee als Erste zu entwickeln. Die Vorteile dieses Genius sind in der Gesellschaft allgemein bekannt und umfassen alles, was mit Innovation, neuartigem Denken und kreativen Problemlösungen zu tun hat.`,
        Discernment: `Das Genius der Intuition bedeutet, in einer Vielzahl von Situationen auf der Grundlage von Instinkt und Einsicht fundierte Urteile zu fällen. Es geht um Mustererkennung und integratives Denken, nicht so sehr um Fachwissen, Kenntnisse oder Daten. Menschen mit Intuition haben ein Händchen dafür und schöpfen Energie und Freude daraus einzuschätzen, ob eine Idee gut ist oder nicht, oder ob sie noch verbessert und geändert werden muss. Intuition hat viele Vorteile, auch wenn sie wegen des instinktiven Charakters der Gabe manchmal schwer zu benennen sind. Dazu gehört zum Beispiel, die besten Ideen sorgfältig auszuwählen, zu bewerten und zu erkennen und die zu verhindern, die nicht gut sind, oder die auszuarbeiten die noch nicht ausgereift sind.`,
        Galvanizing: `Das Genius der Motivation besteht darin, Menschen zu mobilisieren und zu motivieren, häufig für Projekte, Ideen oder Initiativen. Menschen mit der Gabe der Motivation schöpfen Freude und Energie daraus, andere zu inspirieren und zu bewegen, ein Projekt anzugehen, selbst wenn es bedeutet, sie davon zu überzeugen, ihre Pläne zu überdenken oder zu ändern. Motivation ist ziemlich einfach zu erkennen, da es sich um eine wahrnehmbare und oft öffentliche Tätigkeit handelt. Zu den Vorteilen dieses Genius gehört es, die Begeisterung der Menschen für das Potenzial eines Programms oder einer Idee zu wecken, indem Energie und Dynamik aufgebaut werden.`,
        Enablement: `Das Genius des Unterstützens besteht darin, auf die Aufforderung zum Handeln zu reagieren und anderen direkt die bedingungslose Unterstützung und Hilfe zukommen zu lassen, die sie benötigen. Menschen mit diesem Genius schöpfen Energie und Freude daraus, anderen die nötige Unterstützung zu bieten, um etwas in Gang zu setzen, und sorgen für den Zusammenhalt, um das Projekt durchzuführen. Obwohl Menschen mit dem Genius des Unterstützens oft ideale Teammitglieder sind, unterschätzen sie manchmal die Bedeutung ihres Genius, weil sie fälschlicherweise glauben, sie seien einfach nur zugänglich oder „nett“. Die Vorteile dieses Genius bestehen in einer besseren Arbeitsmoral und einer größeren Wertschätzung und Unterstützung anderer Menschen.`,
        Tenacity: `Beim Genius der Beharrlichkeit geht es um die Durchführung. Das bedeutet, Dinge zu erledigen, Ergebnisse zu erzielen und die gewünschte Wirkung einer Aufgabe oder eines Projekts zu erreichen. Menschen mit Beharrlichkeit schöpfen tiefe Freude und Energie daraus, Aufgaben auf einer Liste abzuhaken und zu wissen, dass sie die Standards für die Ausführung erfüllt haben. Sie sind außerdem gut darin, Hindernisse zu überwinden. Die Vorteile dieses Genius bestehen darin sicherzustellen, dass Ziele erreicht und Projekte rechtzeitig abgeschlossen werden, auch wenn unvermeidliche Probleme auftreten.`,
    },
};

export default ReportUniquePairingsDE;
