import React from 'react';
import AppIcon from '../AppIcon';

const mappedLanguages = {
	en: {
		w: 'w',
		i: 'i',
		d: 'd',
		g: 'g',
		e: 'e',
		t: 't',
	},
	es: {
		w: 'p',
		i: 'c',
		d: 'd',
		g: 'i',
		e: 'f',
		t: 't',
	},
	de: {
		w: 'h',
		i: 'e',
		d: 'i',
		g: 'm',
		e: 'u',
		t: 'b',
	},
};

const ColorGearImage = ({ type, color, height = '45px', width = '45px', style, children, lang = 'en' }) => {
	return (
		<div className='wg-gear-no-margin' style={{ position: 'relative', height, width, ...style }}>
			<AppIcon icon={'wg-gear'} style={{ fontSize: height, width, color }} />
			<div className={'flex absolute top-0 right-0 bottom-0 left-0 items-center justify-center content-center'}>
				{type && type[0] ? (
					<span
						style={{
							color,
							fontSize: `calc((${height} / 2) - 5px)`,
							fontWeight: 'bold',
							paddingTop: '2px',
						}}
					>
						{mappedLanguages[lang][type[0].toLowerCase()].toUpperCase()}
					</span>
				) : (
					children
				)}
			</div>
		</div>
	);
};

export default ColorGearImage;
