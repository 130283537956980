import React from 'react';
import AppTabTitle from '../../components/AppTabTitle';
import HomeNavHamburger from '../homepage/HomeNavHamburger';
import HomeFooter from '../homepage/HomeFooter';
import PublicTopBar from '../homepage/PublicTopBar';
import HomepageHeader from '../homepage/HomepageHeader';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import config from '../../constants/config';
import { normalizePathName } from '../../services/UtilityService';

const AboutLayout = ({ children, title = 'The 6 Types of Working Genius: About', description }) => {
	const location = useLocation();

	return (
		<div className={`flex flex-col min-h-screen`}>
			<Helmet>
				<link rel='canonical' href={`${config.base_url}${normalizePathName(location.pathname)}`} />
			</Helmet>
			<AppTabTitle title={title} description={description} />
			<PublicTopBar />
			<HomepageHeader />
			<HomeNavHamburger />
			<div className={`flex flex-1 flex-col`}>{children}</div>
			<HomeFooter />
		</div>
	);
};

export default AboutLayout;
