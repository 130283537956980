import React from 'react';
import AppButton from '../AppButton';
import CustomerService from '../../services/CustomerService';

const HomeJoinTeam = () => {
	return (
		<div className='home-join-team'>
			<div className='container'>
				<div className='home-join-team-content'>
					<p className='home-join-team-h2'>
						Understand your gifts,
						<br />
						your frustrations & your team.
						<br />
						Join the movement today!
					</p>
					<AppButton
						onClick={() => CustomerService.addToCart('add_license', true)}
						className={'btn btn-primary'}
						text={'Buy The Assessment'}
						id={'wg-home-take-assessment-btn'}
					/>
				</div>
			</div>
		</div>
	);
};

export default HomeJoinTeam;
