import React from 'react';
import { live_webinar, at_the_whiteboard_heading, at_the_whiteboard_live_placeholder } from '../../images';

const CareersEventHero = ({ streamHTML }) => {
	return (
		<div className='at-the-whiteboard-hero-section'>
			<div className='container'>
				<div className='webinar-container'>
					<div className='relative flex flex-col items-center'>
						<img className={'live-webinar-badge'} src={live_webinar} alt='' width='180' />
						<img className={'relative p-4'} src={at_the_whiteboard_heading} alt='' width='580' />
					</div>
					<div className='flex flex-col justify-center items-center my-6'>
						<p className='text-white text-xl font-normal tracking-wide'>May 17th at 11:00AM Pacific Time</p>
					</div>
					<div dangerouslySetInnerHTML={{ __html: streamHTML }} />
					{!streamHTML && (
						<div className='w-full lg:w-4/5 mx-auto bg-white flex flex-col items-center justify-center my-8'>
							<img src={at_the_whiteboard_live_placeholder} alt='At the Whiteboard live Event' />
							<div className={'whiteboard-placeholder-content'}>
								<span>
									Live Webinar will begin <br /> at 11 AM PT/ 2 PM ET
								</span>
								<span className={'pt-3'}>
									Re-Broadcast Viewing Options: <br /> 7 PM PT May 17th / 7 AM PT May 18th
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CareersEventHero;
