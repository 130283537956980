import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import { emailRegex, passwordRegex } from '../../services/UtilityService';

import AppInput from '../AppInput';
import Navigation from './Navigation';
import ReCaptcha from "../ReCaptcha";

const SignInDetailsStep = ({ nextStep, registerData, setRegisterData }) => {
    const [loading, setLoading] = React.useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control,
    } = useForm();

    const { isSubmitted } = useFormState({ control });

    const captchaRef = React.useRef('captcha');

    const trimInputValue = (name, e) => {
        setValue(name, e.target.value.trim(), {
            shouldValidate: isSubmitted,
            shouldDirty: true,
        });
    };

    const _next = async (signinData) => {
        const captcha = await captchaRef.current.getCaptchaValue();
        setLoading(true);

        CustomerService.verifyEmail(signinData?.email, captcha)
            .then((exists) => {
                if (exists) {
                    NotificationService.error('Error', 'An account already exists with this email address.');
                } else {
                    setRegisterData({ ...registerData, ...signinData });
                    nextStep();
                };
            })
            .catch((e) => NotificationService.error('Error', e.message))
            .finally(() => setLoading(false));
    };

    return (
        <div className='flex-col'>
            <form
                onSubmit={(e) => handleSubmit(_next)(e)}
                autoComplete='autocomplete_off_hack_xfr4!k'
            >
                <div>
                    <AppInput
                        {...register('email', {
                            required: true,
                            pattern: {
                                value: emailRegex,
                            },
                            maxLength: 96,
                        })}
                        ttgMaxLength={96}
                        label={'Email address'}
                        errors={errors}
                        onBlur={(e) => trimInputValue('email', e)}
                    />
                    <AppInput
                        label={'Password'}
                        type={'password'}
                        errors={errors}
                        {...register('password', {
                            required: true,
                            pattern: {
                                value: passwordRegex,
                                message:
                                    'Password must contain at least 8 characters, 1 upper case character, 1 lowercase character and 1 number.',
                            },
                        })}
                    />

                    <AppInput
                        label={'Confirm password'}
                        type={'password'}
                        errors={errors}
                        {...register('confirm_password', {
                            required: true,
                            validate: (value) => value === getValues('password') || 'Passwords do not match',
                        })}
                    />

                    <ReCaptcha ref={captchaRef} />
                </div>
                <Navigation canGoBack={false} nextText='Next' nextClass='btn btn-primary w-full' loading={loading} />
            </form>
        </div>
    );
};

export default SignInDetailsStep;
