import React from 'react';
import { certification_pat } from '../../../images';

const PatQuote = () => {
	return (
		<div className={'flex bg-primary justify-center items-center content-center py-12 certification-pat-quote'}>
			<div className='container flex mx-auto flex-col items-center justify-center'>
				<div className='flex justify-center my-4'>
					<img src={certification_pat} alt='Patrick Lencioni' width='200' />
				</div>
				<div className='flex flex-col mx-auto mb-4 w-full lg:w-4/5'>
					<p className='text-white text-xl text-center'>
						"Nothing we've done at the Table Group has had such an immediate and transformative impact on
						companies, teams, and families as the Working Genius Model."
					</p>
					<p className='text-white text-center font-medium mt-8'>–PATRICK LENCIONI, AUTHOR AND CREATOR OF</p>
					<p className='text-white text-center italic font-thin mt-1'>THE SIX TYPES OF WORKING GENIUS</p>
				</div>
			</div>
		</div>
	);
};

export default PatQuote;
