import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import Layout from '../../../components/Layout';
import UtilityService, { formatDate, getQueryParams } from '../../../services/UtilityService';
import DetailsHeader from '../../../components/assessments/DetailsHeader';
import { clipboard } from '../../../images';
import AppTable from '../../../components/AppTable';
import rosterFilters from '../../../components/assessments/RosterFilters';
import AppCopyToClipboard from '../../../components/AppCopyToClipboard';
import AppCopyToClipboardMobile from '../../../components/AppCopyToClipboardMobile';
import { list } from '../../../services/RosterService';
import AssessmentsService, { details } from '../../../services/AssessmentsService';
import AppButton from '../../../components/AppButton';
import DownloadReport from '../../../components/DownloadReport';
import Error from '../../../components/assessments/Error';
import DeleteRosterModal from '../../../components/assessments/DeleteRosterModal';
import AssessmentAction from '../../../components/assessments/AssessmentAction';
import ShareAssessment from '../../../components/assessments/ShareAssessment';
import NotificationService from '../../../services/NotificationService';
import RosterActions from '../../../components/assessments/RosterActions';
import MoveAssessment from '../../../components/assessments/MoveAssessment';
import ResultsRow from '../../../components/assessments/ResultsRow';
import AssessmentAppIcon from '../../../components/assessments/AssessmentAppIcon';
import TeammapService from '../../../services/TeammapService';
import ActionFeedbackModal from '../../../components/assessments/ActionFeedbackModal';
import EditResultsModal from '../../../components/assessments/edit/EditResultsModal';
import RosterTour from '../../../components/tours/RosterTour';
import SelectReportLanguageModal from '../../../components/assessments/SelectReportLanguageModal';
import BreakTheTiesModal from '../../../components/assessments/BreakTheTiesModal';
import RequestShare from '../../../components/assessments/RequestShare';
import { AssessmentModeEnum } from '../../../constants/AssessmentsData';
import { useUser } from '../../../hooks/UserProvider';
import RosterActionsDropdown from './RosterActionsDropdown';
import EditNameOnAssessment from '../../../components/assessments/edit/EditNameOnAssessment';

const initialFilters = {
	search: '',
	sort_key: 'created',
	sort_dir: 'desc',
	page: 0,
	limit: 25,
};

const languageMappings = {
	en: 'English',
	es: 'Español',
	de: 'Deutsch',
};

const Index = () => {
	const location = useLocation();
	const queryParams = getQueryParams(location.search);
	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [loadingAssessment, setLoadingAssessment] = useState(true);
	const [loadingViewOriginal, setLoadingViewOriginal] = useState(null);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [isExporting, setIsExporting] = useState(false);
	const [filters, setFilters] = useState(initialFilters);
	const [assessment, setAssessment] = useState({});
	const [originalAssessment, setOriginalAssessment] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const [roster, setRoster] = useState({
		meta: {},
		data: [],
	});
	const [viewReport, setViewReport] = useState(false);
	const [loadingReport, setLoadingReport] = useState(null);
	const [isDeletingRoster, setIsDeletingRoster] = useState(false);
	const [selectedRoster, setSelectedRoster] = useState({});
	const [generateReportData, setGenerateReportData] = useState({});

	const [shareAssessment, setShareAssessment] = useState(false);
	const [shareAssessmentItem, setShareAssessmentItem] = useState({});

	const [requestShare, setRequestShare] = useState(false);
	const [requestShareItem, setRequestShareItem] = useState({});

	const [moveAssessment, setMoveAssessment] = useState(false);
	const [moveAssessmentItem, setMoveAssessmentItem] = useState({});

	const [isEditingAssessmentResults, setIsEditingAssessmentResults] = useState(false);
	const [isEditingAssessmentName, setIsEditingAssessmentName] = useState(false);
	const [changeNameItem, setChangeNameItem] = useState({});

	const [isDuplicate, setIsDuplicate] = useState(false);

	const [shouldSelectLanguage, setShouldSelectLanguage] = useState(false);
	const [breakTheTies, setBreakTheTies] = useState(false);
	const { user, hasPermission } = useUser();

	useEffect(() => {
		getRoster();
		getAssessment();
	}, [user]);

	const onShareAssessment = (index, emails, item) => {
		AssessmentsService.shareAssessment({ email: emails[index], roster_ids: [item.id], assessment_id: item.assessment })
			.then(() => {
				NotificationService.success(`Assessment successfully shared with <b>${emails[index]}</b>`);
				const result = roster.data.find((obj) => obj.id === item.id);
				result.shared_to = result.shared_to ? `${result.shared_to},${emails[index]}` : emails[index];
			})
			.catch((error) => {
				NotificationService.error(error.message, null, { duration: Infinity });
			})
			.finally(() => {
				setShareAssessment(false);
				if (emails[index + 1]) {
					onShareAssessment(index + 1, emails, item);
				}
			});
	};

	const onRequestShare = (item) => {
		AssessmentsService.requestShare({ roster_id: item.id, assessment_id: item.assessment })
			.then(() => {
				NotificationService.success(`Share request successfully sent to <b>${item.email}</b>!`);
			})
			.catch((error) => {
				NotificationService.error(error.message, null, { duration: Infinity });
			})
			.finally(() => {
				setRequestShare(false);
			});
	};

	const getRoster = (_filters = filters, loader = setLoading, merge = false) => {
		loader(true);
		list({
			..._filters,
			assessment_id: queryParams.id,
		})
			.then((data) => {
				const _roster = merge
					? {
						meta: data.meta,
						data: [...roster.data, ...data.data],
					}
					: data;
				setRoster(_roster);
			})
			.finally(() => loader(false));
	};

	const onViewOriginalAssessmentClick = (rosterId) => {
		AssessmentsService.getOriginalRosterAssessmentId({ roster_id: rosterId })
			.then((response) => {
				if (response.original_assessment_id) {
					navigate(`/client/assessment/?id=${response.original_assessment_id}`, { replace: true });
				}
			})
			.catch((error) => {
				NotificationService.error('Oops', error.message);
			})
			.finally(() => {
				setLoadingViewOriginal(null);
			});
	};

	const onShareActionClick = React.useCallback((item) => {
		if (item.allow_share === 1 || item.email === user.customers_email_address) {
			setShareAssessmentItem(item);
			setShareAssessment(true);
		} else {
			setRequestShareItem(item);
			setRequestShare(true);
		}
	}, [user.customers_email_address])

	const onRosterDownload = (item) => {
		if (
			item.roster_mode === AssessmentModeEnum.STANDARD &&
			(assessment.multi_language || assessment.assessment_mode === AssessmentModeEnum.STUDENT)
		) {
			setSelectedRoster(item);
			setShouldSelectLanguage(true);
		} else {
			setGenerateReportData({
				roster_id: item.id,
				assessment_id: item.assessment,
				name: `${item.fname} ${item.lname}`,
			});
			if (item.hasTies) {
				setBreakTheTies(true);
			} else setViewReport(true);
		}
	};

	const getAssessment = () => {
		setLoading(true);
		details(queryParams.id)
			.then((assmnt) => {
				setAssessment(assmnt);
				setOriginalAssessment(assmnt);
			})
			.catch(() => {
				setErrorMessage("We're sorry, but we couldn't find that assessment.");
			})
			.finally(() => setLoadingAssessment(false));
	};

	const onSendReport = (item) => {
		setLoadingReport(item.id);
		AssessmentsService.sendReport({ roster_id: item.id, assessment_id: item.assessment, email: item.email })
			.then(() => {
				NotificationService.success(
					`Successfully sent the report to <b>${item.email ? item.email : 'user'}</b>.`
				);
			})
			.catch((error) => {
				NotificationService.error(error.message);
			})
			.finally(() => {
				setLoadingReport(null);
			});
	};

	const actions = {
		header: () => <p className={'md:ml-2'}>Actions</p>,
		key: 'actions',
		sortable: false,
		className: 'md:w-1/6 lg:w-72',
		render: (item) => {
			if (!item.completed) {
				return (
					<AssessmentAction
						actionId={'wg-assessment-delete-action'}
						data-wg={'wg-delete-roster-action'}
						icon='delete-bin-line'
						tooltipText={'Delete Assessment Report'}
						onClick={() => {
							setSelectedRoster(item);
							setIsDeletingRoster(true);
						}}
						iconClass={'trash-icon'}
						disabled={!hasPermission('WG_ROSTER.DELETE')}
					/>
				);
			}
			if (item.original_roster_id) {
				return (
					<>
						<AssessmentAction
							actionId={'wg-assessment-share-action'}
							data-wg={'wg-share-roster-action'}
							loading={loadingViewOriginal === item.id}
							icon='eye-line'
							tooltipText={'View original assessment'}
							onClick={() => {
								setLoadingViewOriginal(item.id);
								onViewOriginalAssessmentClick(item.original_roster_id);
							}}
							disabled={!hasPermission('WG_ROSTER.GET.ORIGINAL')}
						/>
						<AssessmentAction
							actionId={'wg-duplicated-assessment-download-report-action'}
							data-wg={'wg-download-duplicated-roster-action'}
							icon='file-download-line'
							tooltipText={'Download Assessment Report'}
							onClick={() => onRosterDownload(item)}
							disabled={!hasPermission('WG_ROSTER.GET.REPORT')}
						/>
						<AssessmentAction
							actionId={'wg-assessment-delete-action'}
							data-wg={'wg-delete-roster-action'}
							icon='delete-bin-line'
							tooltipText={'Delete Duplicated Assessment '}
							onClick={() => {
								setSelectedRoster(item);
								setIsDuplicate(true);
								setIsDeletingRoster(true);
							}}
							iconClass={'trash-icon'}
							disabled={!hasPermission('WG_ROSTER.DUPLICATE.DELETE')}
						/>
					</>
				);
			}
			return (
				<>
					<div className="flex flex-row items-center gap-2">
						<AssessmentAction
							actionId={'wg-assessment-download-report-action'}
							data-wg={'wg-download-roster-action'}
							icon='download-2-line'
							tooltipText={'Download Assessment Report'}
							onClick={() => onRosterDownload(item)}
							disabled={!hasPermission('WG_ROSTER.GET.REPORT')}
						/>
						<RosterActionsDropdown
							item={item}
							loadingReport={loadingReport}
							onSendReport={onSendReport}
							hasPermission={hasPermission}
							onShareActionClick={onShareActionClick}
							setIsDuplicate={setIsDuplicate}
							setIsEditingAssessmentResults={setIsEditingAssessmentResults}
							setMoveAssessment={setMoveAssessment}
							setMoveAssessmentItem={setMoveAssessmentItem}
							setSelectedRoster={setSelectedRoster}
							user={user}
							setIsEditingAssessmentName={setIsEditingAssessmentName}
							setChangeNameItem={setChangeNameItem}
						/>
					</div>
				</>
			);
		},
	};

	const columns = React.useMemo(() => {
		const initial = [
			{
				header: () => <p className={'md:ml-10'}>Name</p>,
				key: 'fullname',
				render: (item) => {
					return (
						<div className={'flex flex-row items-center'}>
							<AssessmentAppIcon
								className={'hidden md:inline text-3xl text-secondary justify-center'}
								item={item}
							/>
							<div className={'ml-2'}>
								<p id={'wg-assessment-details-fullname'} className='text-primary'>
									{item.fname} {item.lname}
								</p>
								<p className='text-gray-500 ellipse-text w-48 xl:w-auto'>{item.email}</p>
							</div>
						</div>
					);
				},
			},
			{
				header: 'Date taken',
				className: 'md:w-14/100 xl:w-48',
				key: 'created',
				render: (item) => {
					return <p className='text-primary'>{formatDate(item.started ? item.started : item.created)}</p>;
				},
			},
			{
				header: 'Status',
				key: 'completed',
				className: 'md:w-12/100',
				render: (item) => {
					return <p className='text-primary font-bold'>{item.completed ? 'Complete' : 'Incomplete'}</p>;
				},
			},
		];

		if (assessment.multi_language) {
			initial.push({
				header: 'Language',
				key: 'language',
				sortable: false,
				className: 'md:w-1/10',
				render: (item) => {
					return languageMappings[item.language] || '';
				},
			});
		}

		initial.push({
			header: 'Results',
			key: 'results',
			sortable: false,
			className: 'md:w-12/100 xl:w-40',
			render: (item) => {
				if (item.completed) {
					return <ResultsRow member={item} />;
				} else {
					return '';
				}
			},
		});

		if (assessment.type === 'group' || assessment.complete) {
			initial.push(actions);
		}
		return initial;
	}, [assessment, actions]);

	if (!queryParams.id) {
		navigate('/client/', { replace: true });
		return null;
	}

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir: sort_dir,
			page: 0,
		};
		setFilters(newFilters);
		getRoster(newFilters);
	};

	const onPageChange = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getRoster(newFilters);
	};

	const onLoadMore = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getRoster(newFilters, setLoadingMore, true);
	};

	const onSearch = () => {
		getRoster({
			...filters,
			page: 0,
		});
	};

	const onReset = () => {
		setFilters(initialFilters);
		getRoster(initialFilters);
	};

	const onWidgetUpdate = (newWidget) => {
		const rosterCopy = UtilityService.clone(roster);
		const idx = rosterCopy.data.findIndex((d) => d.id === selectedRoster.id);
		rosterCopy.data[idx] = {
			...rosterCopy.data[idx],
			...newWidget,
		};
		setRoster(rosterCopy);
		setIsEditingAssessmentResults(false);
	};

	const updateName = (fname, lname) => {
		const rosterCopy = UtilityService.clone(roster);
		const idx = rosterCopy.data.findIndex((d) => d.id === changeNameItem.id);
		rosterCopy.data[idx] = {
			...rosterCopy.data[idx],
			fname,
			lname,
			fullname: `${fname} ${lname}`,
		};
		setRoster(rosterCopy);
		setAssessment({ ...assessment, fname, lname, fullname: `${fname} ${lname}` });
	};

	const renderContent = () => {
		if (loadingAssessment) {
			return (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			);
		}

		if (errorMessage) {
			return <Error message={errorMessage} />;
		}

		const completedAssessments = parseInt(assessment.num_scores);
		const rosterTableData = roster.data;
		const rosterTableMeta = roster.meta;

		const hasIncompleteAssessments =
			rosterTableMeta.totalResults > completedAssessments && completedAssessments > 0;

		return (
			<div className='container mx-auto'>
				<DetailsHeader
					assessment={assessment}
					onEdit={setAssessment}
					hasIncompleteAssessments={hasIncompleteAssessments}
					roster={assessment.type === 'individual' && roster.data[0]}
					onRosterUpdate={updateName}
					setChangeNameItem={setChangeNameItem}
				/>
				<hr className={'bg-primary text-primary h-1 my-12'} />
				{(assessment.type === 'group' || user.customers_email_address !== assessment.roster_email) && (
					<div className={'mx-4'}>
						<h1 className='text-primary text-2xl md:text-3xl font-medium md:font-bold text-center md:text-left mb-4'>
							Assessment Link
						</h1>
						<div className={'hidden md:flex md:flex-row'}>
							<img className={'w-24 h-24 object-contain mx-auto'} src={clipboard} alt='' />
							<div className={'w-full'}>
								<h1 className='text-primary text-2xl font-bold text-center md:text-left'>
									Copy and paste the following link:
								</h1>
								<h1 className='text-error text-xl font-bold'>
									<AppCopyToClipboard
										value={`https://${window.location.hostname}/assessment/${assessment.slug}`}
										id={'wg-copy-paste-assessment-link'}
									/>
								</h1>
							</div>
						</div>
						<div className={'w-full md:hidden'}>
							<AppCopyToClipboardMobile
								text={'COPY ASSESSMENT LINK'}
								value={`https://${window.location.hostname}/assessment/${assessment.slug}`}
								id={'wg-copy-paste-assessment-link-mobile'}
							/>
						</div>
					</div>
				)}
				{assessment.type === 'individual' &&
					user.customers_email_address === assessment.roster_email &&
					!assessment.complete && (
						<AppButton
							text={'Take This Assessment Now'}
							onClick={() =>
								navigate(`/assessments/take?a=${assessment.slug}&r=${assessment.roster_slug}`)
							}
							id={'wg-take-assessment-now-btn'}
							disabled={!hasPermission('WG_ASSESSMENT.TAKE.POST')}
						/>
					)}
				<AppTable
					selectable={true}
					title={'Roster'}
					renderFilters={rosterFilters}
					renderBulkActions={(props) => (
						<RosterActions
							{...props}
							assessment={assessment}
							assessment_id={assessment.id}
							assessment_name={assessment.fullname}
							hasIncompleteAssessments={hasIncompleteAssessments}
							onMoveComplete={() => {
								getRoster();
								getAssessment();
							}}
							onSingleAssessmentShare={onShareActionClick}
						/>
					)}
					actions={[
						{
							id: 'wg-clientpage-export-results-btn',
							tooltip:
								completedAssessments > 0
									? 'This will export all the group results in an excel file.'
									: 'No one has completed the assessment yet.',
							text: 'Export Results',
							disabled: completedAssessments === 0,
							onClick: () => {
								setIsExporting(true);
								setShowFeedbackModal(true);
								TeammapService.create({
									assessment_id: assessment.id,
									xls: true,
									name: assessment.group ? assessment.group : assessment.fullname,
								}).then(
									() => {
										setIsExporting(false);
									},
									(error) => {
										NotificationService.error(error.message);
										setIsExporting(false);
										setShowFeedbackModal(false);
									}
								);
							},
						},
						{
							id: 'wg-clientpage-create-teammap-btn',
							tooltip:
								'This will generate the Team Map Report based on the people currently on the team.',
							text: 'Create team map',
							onClick: () => {
								navigate(`/client/teammap/?id=${assessment.id}`);
							},
						},
					]}
					disableSelection={(item) => !item.complete}
					columns={columns}
					data={rosterTableData}
					loading={loading}
					onSort={onSort}
					filters={filters}
					setFilters={setFilters}
					onPageChange={onPageChange}
					onLoadMore={onLoadMore}
					loadingMore={loadingMore}
					onSearch={onSearch}
					onReset={onReset}
					pagination={rosterTableMeta}
				/>
				<DownloadReport isOpen={viewReport} closeModal={() => setViewReport(false)} ids={generateReportData} />
				<DeleteRosterModal
					isOpen={isDeletingRoster}
					isDuplicate={isDuplicate}
					closeModal={() => setIsDeletingRoster(false)}
					roster={selectedRoster}
					onComplete={(response) => {
						setIsDeletingRoster(false);
						setTimeout(() => {
							setIsDuplicate(false);
						}, 200);
						if (!response.deleted) {
							getRoster();
							getAssessment();
						}
					}}
				/>
				<ShareAssessment
					isOpen={shareAssessment}
					onClose={() => {
						setShareAssessment(false);
						setTimeout(() => {
							setShareAssessmentItem({});
						}, 200);
					}}
					item={shareAssessmentItem}
					onShare={onShareAssessment}
				/>
				<RequestShare
					isOpen={requestShare}
					onClose={() => {
						setRequestShare(false);
						setTimeout(() => {
							setRequestShareItem({});
						}, 200);
					}}
					item={requestShareItem}
					onRequestShare={onRequestShare}
					requestStatusPayload={{
						roster_id: requestShareItem.id,
						assessment_id: requestShareItem.assessment,
					}}
				/>
				<MoveAssessment
					isOpen={moveAssessment}
					isDuplicate={isDuplicate}
					redirectOnDelete={true}
					isGroupAssessment={assessment.type === 'group'}
					onClose={(res) => {
						setMoveAssessment(false);
						setTimeout(() => {
							setMoveAssessmentItem({});
							setIsDuplicate(false);
						}, 200);
						if (res && res.reload) {
							getRoster();
							getAssessment();
						}
					}}
					item={{
						...moveAssessmentItem,
						roster_id: moveAssessmentItem.id,
						id: moveAssessmentItem.assessment,
					}}
				/>
				<EditResultsModal
					isOpen={isEditingAssessmentResults}
					roster={selectedRoster}
					closeModal={() => setIsEditingAssessmentResults(false)}
					onUpdate={(newData) => onWidgetUpdate(newData)}
				/>
				<ActionFeedbackModal
					isOpen={showFeedbackModal}
					title={isExporting ? 'Exporting...' : 'Exported!'}
					message={
						isExporting
							? "Please wait while we're processing your request..."
							: 'Reports exported successfully!'
					}
					loading={isExporting}
					closeModal={() => setShowFeedbackModal(false)}
				/>
				<SelectReportLanguageModal
					isOpen={shouldSelectLanguage}
					onClose={() => setShouldSelectLanguage(false)}
					onConfirm={(language) => {
						setShouldSelectLanguage(false);
						setGenerateReportData({
							roster_id: selectedRoster.id,
							assessment_id: selectedRoster.assessment,
							name: `${selectedRoster.fname} ${selectedRoster.lname}`,
							language: language,
						});
						setViewReport(true);
					}}
					languageTaken={selectedRoster.language}
				/>
				<BreakTheTiesModal
					isOpen={breakTheTies}
					onClose={() => setBreakTheTies(false)}
					onConfirm={() => {
						setBreakTheTies(false);
						setViewReport(true);
					}}
				/>
				<RosterTour />
				<EditNameOnAssessment
					item={changeNameItem}
					isOpen={isEditingAssessmentName}
					closeModal={() => setIsEditingAssessmentName(false)}
					updateName={updateName}
				/>
			</div>
		);
	};

	return <Layout>{renderContent()}</Layout>;
};

export default Index;
