import React from 'react';
import AppInput from '../AppInput';
import AppSelect from '../AppSelect';
import AppButton from '../AppButton';
import { useForm } from 'react-hook-form';
import { emailRegex } from '../../services/UtilityService';
import { wg_live_screenshot } from '../../images';

const LiveBeforeSubmit = ({ onSubmit }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({});

	const options = [
		{
			value: 'executive',
			label: 'Executive',
		},
		{
			value: 'hr',
			label: 'HR',
		},
		{
			value: 'team leader',
			label: 'Team Leader',
		},
		{
			value: 'team member',
			label: 'Team Member',
		},
		{
			value: 'consultant',
			label: 'Consultant',
		},
	];

	return (
		<div className='container mx-auto'>
			<h1 className='text-primary text-center text-xl md:text-4xl font-bold mt-8'>
				Enter your information to access
				<br /> the 30-minute livestream speech
			</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={'w-full flex flex-wrap justify-center lg:justify-between mt-8'}>
					<AppInput
						{...register('firstname', {
							required: true,
						})}
						placeholder={'first name *'}
						containerClassName={'live-input'}
						errors={errors}
					/>
					<AppInput
						{...register('lastname', {
							required: true,
						})}
						placeholder={'last name *'}
						containerClassName={'live-input'}
						errors={errors}
					/>
					<AppInput
						{...register('email', {
							required: true,
							pattern: {
								value: emailRegex,
							},
						})}
						placeholder={'e-mail address *'}
						containerClassName={'live-input'}
						errors={errors}
					/>
					<AppSelect
						{...register('role', {
							required: true,
						})}
						placeholder={'-- select a role * --'}
						containerClassName={'live-input'}
						options={options}
						errors={errors}
						renderText={({ label }) => label}
					/>
					<AppInput
						{...register('company')}
						placeholder={'company'}
						containerClassName={'live-input'}
						errors={errors}
					/>
				</div>
				<div className={'flex justify-center lg:justify-end mx-12'}>
					<AppButton
						type='submit'
						className={'btn btn-primary mb-4'}
						text={'Submit'}
						id={'wg-live-before-submit-btn'}
					/>
				</div>
			</form>
			<div className='live-before-submit my-4 md:my-8 mx-0 md:mx-12'>
				<div className='video-image-placeholder mx-auto w-full md:w-4/5'>
					<img src={wg_live_screenshot} alt='Pat Speaking' />
					<p className='video-image-placeholder-text hidden sm:block'>
						Please enter your information above to view the Working Genius recording.
					</p>
				</div>
			</div>
		</div>
	);
};

export default LiveBeforeSubmit;
