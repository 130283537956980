import React from 'react';
import { navigate } from "gatsby";

import AppCopyToClipboard from "../AppCopyToClipboard";
import {useUser} from "../../hooks/UserProvider";

const Developer = () => {
    const { user } = useUser();

    return (
        <>
            <div className={'mb-4'}>
                <p>Developer Key</p>
                <div className={'hidden md:flex md:flex-row'}>
                    <div className={'w-full'}>
                        <h1 className='text-error text-xl font-bold'>
                            <AppCopyToClipboard
                                value={`${user.working_genius_token}`}
                                id={'wg-copy-paste-developer-key'}
                            />
                        </h1>
                    </div>
                </div>
            </div>
            <div className={'mb-4'}>
                <p>API Documentation</p>
                <div className={'hidden md:flex md:flex-row'}>
                    <div className={'w-full'}>
                        <a
                            id={'details-header-back-button'}
                            className={'cursor-pointer text-secondary underline font-bold text-lg'}
                            onClick={() => {
                                navigate('/docs-api');
                            }}
                        >
                            Documentation &gt;
                        </a>
                    </div>
                </div>
            </div>
            <div className={'mb-4'}>
                <p>API Terms of Use</p>
                <div className={'hidden md:flex md:flex-row'}>
                    <div className={'w-full'}>
                        <a
                            id={'details-header-back-button'}
                            className={'cursor-pointer text-secondary underline font-bold text-lg'}
                            onClick={() => {
                                navigate('/terms-api');
                            }}
                        >
                            API Terms &gt;
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Developer;
