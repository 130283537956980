import React from 'react';

const FullPagination = ({ totalPages, totalResults, elements }) => {
	if (!totalPages || totalPages == 0) {
		return null;
	}

	return (
		<div id={'wg-table-pagination'} className={'flex flex-col md:flex-row my-4'}>
			<div className='flex flex-1'>
				<p className={'text-primary font-thin'}>
					<span>Displaying </span>
					<span className='font-bold'> {elements} </span>
					<span>of</span>
					<span className='font-bold'> {totalResults} </span>
				</p>
			</div>
		</div>
	);
};

export default FullPagination;
