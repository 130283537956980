import React from 'react';
import { workshop_includes_icons } from '../../images';

const WorkshopIncludes = () => {
	const listElements = [
		<h3>
			Meet your <br /> Master Facilitator
		</h3>,
		<h3>
			Pick Your <br /> Session Date
		</h3>,
		<h3>
			Attend Working <br /> Genius Workshop
		</h3>,
	];
	return (
		<div className='workshop-includes-section'>
			<div className='container'>
				<div className='flex flex-col lg:flex-row items-center'>
					<div className='flex-1 text-center'>
						<h2 className='text-2xl md:text-3xl text-primary font-medium my-4'>A Workshop is:</h2>
						<p className='text-base lg:text-lg text-primary my-4 w-4/5 mx-auto'>
							The perfect starting point for any team looking to leverage Working Genius and maximize
							productivity, clarity, and fulfillment in their work. Our workshop is designed to be highly
							interactive and fast-paced and our Master Facilitators leave teams with actionable and
							practical tools.
						</p>
					</div>
					<div className='flex-1'>
						<div className={`flex flex-col text-center`}>
							<h2 className={'text-center text-primary text-2xl md:text-3xl font-medium pt-8 pb-4'}>
								How It Works:
							</h2>
							<div className={'flex flex-col items-center justify-center'}>
								<img src={workshop_includes_icons} alt='widget' className='w-4/5' />
								<div className={'flex flex-col sm:flex-row w-full'}>
									{listElements.map((el, indx) => (
										<div className={'flex-1 text-base text-primary font-semibold m-3'} key={indx}>
											{el}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WorkshopIncludes;
