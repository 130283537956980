import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

const exportToExcel = (fileData, fileName, fileExtension = 'xlsx') => {
	const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

	const ws = XLSX.utils.json_to_sheet(fileData);
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: fileExtension, type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	FileSaver.saveAs(data, fileName + '.' + fileExtension);
};

const ExcelService = {
	exportToExcel,
};

export default ExcelService;
