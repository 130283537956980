import React from 'react';
import AppButton from '../AppButton';
import {resource_pat_podcast, podcast_at_the_table} from '../../images';

const PodcastResourceItem = ({ item }) => {
	return (
		<div className={'flex flex-col shadow-md bg-white p-12'}>
			<div className='flex flex-1 flex-col'>
				<div className={'flex flex-row md:flex-col lg:flex-row'}>
					{item.atTheTablePodcast ? (
						<img src={podcast_at_the_table} alt='' className={'w-24 h-24 object-cover mr-4'} />
					) : (
						<img src={resource_pat_podcast} alt='' className={'w-24 h-24 object-cover mr-4'} />
					)}
					<div className={'flex flex-1 flex-col pt-0 md:pt-3 lg:pt-0'}>
						<h3 className={'text-xl text-primary tracking-wider'}>{item.episode}:</h3>
						<h4 className={'text-xl text-primary font-bold'}>{item.title}</h4>
						<div className='border-secondary border-t-4 my-4' />
					</div>
				</div>
				<p className={'text-primary my-4'} dangerouslySetInnerHTML={{ __html: item.description }} />
			</div>
			<div className='flex flex-col sm:flex-row md:flex-col xl:flex-row items-start gap-4'>
				<AppButton
					className={'btn btn-dark mr-4'}
					text={'Listen now'}
					onClick={() => {
						window.open(item.link);
					}}
				/>

				{item.pdfLink && (
					<AppButton
						className={'btn btn-dark'}
						text={'Download pdf'}
						onClick={() => {
							window.open(item.pdfLink);
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default PodcastResourceItem;
