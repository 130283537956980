import React from 'react';
import { report_gear, WidgetReportV2 } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const WhatsNext = () => {
	const renderGear = (text) => {
		return (
			<div className={'flex flex-row my-3'}>
				<img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
				<div>
					<p>{text}</p>
				</div>
			</div>
		);
	};

	return (
		<div className='Application'>
			<PageHeader title={'APLICACIÓN'} />

			<div className={'container mt-12'}>
				<PageTitle title={'¿Cuál es el siguiente paso?'} className={'mt-7'} />
				<PageTitle title={'¿Cómo aplicar Working Genius?'} className={'mt-8'} />
				<div className='mr-25'>
					<p className={'mt-4'}>
						Es doloroso ver cómo hay muchas personas en el mundo que están estancadas en trabajos que no se
						alinean con sus genialidades o que quizá desempeñan funciones que los obligan a vivir dentro de
						sus áreas de frustración. Es aún peor saber que muchos de ellos no saben por qué se sienten
						miserables. Los individuos que abrazan plenamente el poder de este modelo pueden experimentar
						mayores niveles de autoconciencia, incrementar sus niveles de alegría y productividad, además de
						dejar de culparse y juzgarse a sí mismos.
					</p>

					<p className={'mt-4'}>
						La manera en la que uses tus resultados de Working Genius dependerá de dónde te encuentres en tu
						vida y trabajo, y la razón por la que decidiste hacer la evaluación en primer lugar. Estas son
						algunas posibilidades:
					</p>

					<div className='font-bold uppercase mt-6 mb-2'>TRABAJA A PARTIR DE TU GENIALIDAD</div>
					<div className={'ml-8'}>
						<p>Evalúa tu vida personal y profesional.</p>
						{renderGear(
							'Identifica cómo puedes inclinarte más efectivamente hacia las genialidades en las que eres Sobresaliente y menos a las genialidades que te Frustran.'
						)}
						{renderGear(
							'Considera la manera en la que programas tu trabajo y cómo puedes abordar las actividades relacionadas con tus áreas de competencia y de frustración cuando tienes más energía.'
						)}
						{renderGear(
							'Identifica y señala cuando estés trabajando fuera de tus áreas de genialidad para que estés consciente y seas intencional con relación a tus niveles de energía.'
						)}
						{renderGear(
							'Apóyate en personas cercanas para que te den su opinión y te ayuden en las áreas que estén fuera de tus genialidades.'
						)}
					</div>

					<div className='font-bold uppercase mt-6 mb-2'>COMPARTE TUS RESULTADOS CON OTROS</div>
					<div className={'ml-8'}>
						<p>
							Imagina cuánto podrían mejorar tus relaciones si todos entendieran con claridad las áreas de
							genialidad y de frustración de cada quien.
						</p>
						{renderGear(
							'Comienza por contarles a otras personas (familiares, amigos, compañeros de trabajo, miembros del equipo) lo que descubriste sobre ti mismo.'
						)}
						{renderGear(
							'Invítalos a hacer la evaluación de Working Genius y revisen sus resultados juntos.'
						)}
						{renderGear(
							'Conversen sobre cómo podrían ayudarse mutuamente a permanecer en sus áreas de genialidad y evitar la frustración.'
						)}
						{renderGear(
							'Ofréceles ayuda a otras personas a través de las genialidades en las que eres sobresaliente.'
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhatsNext;
