import React from 'react';
import AppIcon from '../AppIcon';
import UtilityService from '../../services/UtilityService';

const SelectedMemberRow = (props) => {
	const handleRemoveMember = (props) => {
		let member = {
			assessment: props.assessment,
			fname: props.fname,
			lname: props.lname,
			email: props.email,
			id: props.id,
			type: props.type,
			completed: props.completed,
		};
		props.handleRemove(member);
	};

	return (
		<tr className='BuilderTable__row fade-in'>
			<td className='BuilderTable__row__infocell'>
				<table className='BuilderTable__selectedInnerTable'>
					<tr>
						<td className='BuilderTable__assessmentInfo__controls'>
							<button
								onClick={() => handleRemoveMember(props)}
								className='BuilderTable__assessmentInfo__controlBtn Button btn-link'
								id={'wg-teammap-person-remove-btn'}
							>
								<span className='ToolBar__Tools'>
									<AppIcon icon='left' /> Remove
								</span>
							</button>
						</td>

						<td id={'wg-team-map-assessment-info'} className={`BuilderTable__assessmentInfo`}>
							{props.fname} {props.lname}
							<span className='BuilderTable__assessmentInfo__email'>{props.email}</span>
							<span className='BuilderTable__assessmentInfo__created'>
								{UtilityService.formatDate(props.completed)}
							</span>
						</td>
					</tr>
				</table>
			</td>
		</tr>
	);
};

export default SelectedMemberRow;
