import AppButton from '../AppButton';
import AppModal from '../AppModal';
import React from 'react';
import AppIcon from '../AppIcon';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ConfirmMapCreationModal = ({ isOpen, closeModal, onConfirm }) => {
	return (
		<AppModal id={'app-add-licenses-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='relative max-w-screen-md bg-white px-8'>
				<button
					className={'absolute right-0 top-0 mr-4 mt-4'}
					id={'wg-confirm-teammap-close-btn'}
					onClick={closeModal}
				>
					<AppIcon icon={'cancel'} className={'text-2xl'} />
				</button>
				<p className='uppercase text-2xl text-primary text-center font-medium py-4'>
					MAXIMUM GENIUSES IN A GROUP REACHED
				</p>
				<p className='text-center'>
					The team map you are about to create has at least one genius group that exceeds 12 people. If you
					wish to continue click “Create PDF”, an editable team map will be generated as blank. You can also
					download all results as an excel file.
				</p>
				<div className='flex justify-end py-4 justify-center'>
					<AppButton text={'CREATE PDF'} onClick={() => onConfirm(false)} id={'wg-confirm-teammap-pdf-btn'} />
					<AppButton
						className={'btn btn-secondary ml-4'}
						text={'EXPORT EXCEL'}
						onClick={() => onConfirm(true)}
						id={'wg-confirm-teammap-excel-btn'}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default ConfirmMapCreationModal;
