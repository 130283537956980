import React from 'react';

import AppButton from '../AppButton';
import { widget_hr, live_webinar } from '../../images';
import CountdownPlayer from '../event/CountdownPlayer';

const EventHero = ({ streamHTML }) => {
	const eventDate = new Date('2023-01-25T19:00:00Z');
	return (
		<div className='fyf-event-hero-section'>
			<div className='container flex flex-col'>
				<div className='flex flex-col items-center justify-center text-center'>
					<h1 className='text-2xl lg:text-4xl text-white font-normal'>
						Welcome to The Working Genius Exclusive Webinar
					</h1>
					<img className={'w-1/3 my-6'} src={widget_hr} alt='' />
					<p className='text-white text-lg font-semibold tracking-widest mb-8'>
						Premieres January 25th at 11AM PT / 2PM ET
					</p>
					<div dangerouslySetInnerHTML={{ __html: streamHTML }} className='w-full' />

					{!streamHTML && <CountdownPlayer eventDate={eventDate} waitingTitle='Webinar will begin here in' />}

					<div className='flex flex-col justify-center'>
						<p className='text-white text-lg font-semibold my-4 tracking-widest'>What's next?</p>
						<div className='flex flex-col lg:flex-row items-center justify-center'>
							<AppButton
								className='btn btn-primary m-2'
								onClick={() =>
									window.open('https://workinggenius.me/gettingnakedwebinar')
								}
								text='Purchase Getting Naked by Patrick Lencioni'
								id='wg-event-getting-naked-btn'
							/>
						</div>
					</div>
				</div>
				<div className='live-webinar-content'>
					<div className='live-webinar-icon'>
						<img src={live_webinar} alt='Live webinar' width={200} />
					</div>
					<div className='live-webinar-description'>
						<span className='text-base md:text-xl text-white font-normal'>
							Pat will be sharing about one of his favorite topics - naked consulting. This approach to
							developing business and serving clients has served Pat and the Table Group for years. Pat
							wrote about it in his book Getting Naked. This topic is perfect for anybody looking to
							garner interest in Working Genius in their company or consulting practice.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventHero;
