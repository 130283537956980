import React from 'react';
import NotificationItem from './NotificationsItem';

const NotificationsList = ({ notifications, onStatusChange, toggleWidgetVisibility }) => {
	return (
		<div>
			{notifications.map((notification, index) => {
				return (
					<NotificationItem
						notification={notification}
						key={index}
						onStatusChange={(forceRead) => onStatusChange(notification, forceRead)}
						toggleWidgetVisibility={toggleWidgetVisibility}
					/>
				);
			})}
		</div>
	);
};

export default NotificationsList;
