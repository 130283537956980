import { components } from 'react-select';
import React from 'react';
import AppRIIcon from '../AppRIIcon';
import WgIcon from './../WgIcon';

const AssessmentAutocompleteOption = ({ data, ...props }) => {
	let icon = 'team-fill';
	let label = data.group;
	if (data.__isNew__) {
		icon = 'add-circle-fill';
	} else if (data.type !== 'group') {
		icon = 'user-fill';
		label = `${data.fname} ${data.lname}`;
	}

	return (
		<components.Option {...props}>
			<div
				className={'flex flex-1 flex-row px-1 cursor-pointer'}
				id={data.__isNew__ ? 'wg-move-create-option' : `wg-move-option-${data.id}`}
			>
				<div className='flex flex-1 flex-row items-center justify-start'>
					{
						data.__isNew__ ?
							<AppRIIcon
								icon={'add-circle-fill'}
								className={`text-secondary text-2xl mx-2 ${props.isSelected ? 'text-white' : ''}`} />
							:
							<WgIcon item={data} className={`text-2xl ${data.assessment_mode === 'student' ? 'mx-1' : ''} ${props.isSelected ? 'text-white' : ''}`} />
					}
					<p className={'m-0'}>{data.__isNew__ ? `Create "${data.value}"` : label}</p>
				</div>
			</div>
		</components.Option >
	);
};

export default AssessmentAutocompleteOption;
