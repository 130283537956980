import React from 'react';
import { WidgetReportV2Deutsch } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const Overview = () => {
    return (
        <div className='OverviewGerman'>
            <PageHeader title={'ÜBERBLICK'} />

            <div className={'container brief'}>
                <PageTitle title={'Ein kurzer Überblick über das "Working Genius"-Modell'} />
                <p>
                    Jeder Mensch hat natürliche, in die Wiege gelegte Talente, wenn es um die Arbeit geht. Es gibt sechs verschiedene Arten
                    von Talenten oder "Geniuses", und jedes von ihnen ist erforderlich zur Bewältigung jeglicher Art von Unternehmung.
                </p>
                <p>
                    Jeder von uns hat zwei Bereiche, die unsere wahren Geniuses oder Gaben sind. Das sind die Tätigkeiten, die uns
                    Freude, Energie und Leidenschaft geben. Wir nennen dies unsere Bereiche des  <span className='font-bold'>Working Genius</span>. Zwei der sechs
                    Typen nennen wir unsere <span className='font-bold'>Working Frustrations</span>, also Tätigkeiten, die uns Freude und Energie rauben. Die meisten
                    von uns haben keine großen Fähigkeiten in diesen Bereichen. Die letzten beiden sind die <span className='font-bold'>Working Competencies</span>,
                    das sind Tätigkeiten, die uns weder erfüllen noch auslaugen und die wir für einen begrenzten Zeitraum recht gut
                    ausführen können.
                </p>
                <p>
                    Die Sechs Typen des Working Genius bilden ein ineinandergreifendes Modell, eine umfassende Methode zur
                    Bewältigung jeglicher Art von Arbeit. Jede Art von Genius empfängt und/oder gibt etwas an die benachbarten Typen,
                    wodurch ein natürlicher Arbeitsablauf entsteht, der mit dem Hinterfragen beginnt und mit der Beharrlichkeit seinen
                    Zielpunkt erreicht. Das nachstehende Modell zeigt den Aufbau der sechs Typen und enthält eine kurze Definition
                    jedes Genius.
                </p>
            </div>

            <div className='container six-types'>
                <h1 className='title'>DIE SECHS TYPEN</h1>

                <hr />

                <img src={WidgetReportV2Deutsch} alt='' className={'w-2/3 mx-auto px-4'} />

                <p>
                    <strong>HINTERFRAGEN:</strong> erkennt die Notwendigkeit von Verbesserungen oder Veränderungen.
                </p>
                <p>
                    <strong>ERFINDEN:</strong> bestätigt die Bedeutung dieses Bedürfnisses und entwickelt eine Idee oder Lösung.
                </p>
                <p>
                    <strong>INTUITION:</strong> schätzt den Wert und die Durchführbarkeit der Idee oder Lösung ein.
                </p>
                <p>
                    <strong>MOTIVATION:</strong> erzeugt Begeisterung und Aktion für die Idee oder Lösung.
                </p>
                <p>
                    <strong>UNTERSTÜTZEN:</strong> initiiert Unterstützung und hilft bei der Implementierung der Idee oder Lösung.
                </p>
                <p>
                    <strong>BEHARRLICHKEIT:</strong> setzt sich dafür ein, dass die Idee oder Lösung umgesetzt wird und die gewünschten Ergebnisse erzielt werden.
                </p>
            </div>
        </div>
    );
};

export default Overview;
