import React from 'react';

import AppButton from '../../components/AppButton';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import EventHero from '../../components/event8november/EventHero';
import EventPat from '../../components/event8november/EventPat';
import RegisterForm from '../../components/event8november/RegisterForm';
import { its_free_down_left_arrow } from '../../images';

const Event = () => {
    const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

    return (
        <WorkshopLayout title={'The 6 Types of Working Genius: At the Whiteboard Meetings'}>
            <EventHero />
            <div className='bg-secondary p-6'>
                <div className='flex flex-col lg:flex-row items-center justify-center text-center mx-auto'>
                    <p className='text-white text-base md:text-2xl font-medium leading-8'>
                        Register today and receive a free digital <br /> resource for better meetings when you attend.
                    </p>
                    <div className='flex flex-row items-center'>
                        <AppButton
                            id={'wg-at-the-table-meetings-register-now-btn'}
                            className={'btn w-56 ml-6 mr-2'}
                            onClick={() => setOpenRegisterModal(true)}
                            text={'Register now'}
                        />
                        <img src={its_free_down_left_arrow} alt='its free' width='150' />
                    </div>
                </div>
            </div>
            <EventPat />
            <RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
        </WorkshopLayout>
    );
};

export default Event;
