import React from 'react';
import { CoverBreakLine, CoverLogoV2, TTGWhiteReportLogo } from '../../../../images';

const CoverPage = ({ assessment }) => {
	const preparedBy = () => {
		if (!assessment.participant.company_logo) {
			return null;
		}

		return (
			<div className='prepared-by'>
				<div className='ttg-container'>
					<p>Prepared by:</p>
					<img src={assessment.participant.company_logo} alt={''} />
				</div>
			</div>
		);
	};

	return (
		<div className='Cover'>
			<div className='top py-8 mx-auto'>
				<div className={'w-2/3 mx-auto'}>
					<p className={'top-title'}>Assessment Report</p>
				</div>
			</div>

			<div className='mt-6'>
				<img src={CoverLogoV2} alt={''} className={'w-3/4 mx-auto px-5'} />
			</div>

			<div className='gears my-12'>
				<img src={CoverBreakLine} alt={''} />
			</div>

			<p className='tagline'>
				{assessment.participant.fname} {assessment.participant.lname}
				{assessment.participant.lname[assessment.participant.lname.length - 1] === 's' ? '' : '’s'} Working
				Genius Revealed
			</p>

			<div className='footer'>
				<div className='gear'>
					<div className='dot'>
						<p>
							DISCOVER THE <br />
							GIFTS YOU BRING <br /> TO WORK
						</p>
					</div>
				</div>
			</div>

			{preparedBy()}

			<div className='powered-by'>
				<div className='ttg-container'>
					<p>Powered by:</p>
					<img src={TTGWhiteReportLogo} alt={''} />
				</div>
			</div>
		</div>
	);
};

export default CoverPage;
