import React from 'react';
import Layout from '../../../components/Layout';
import AssessmentsService from '../../../services/AssessmentsService';
import NotificationService from '../../../services/NotificationService';
import { getQueryParams } from '../../../services/UtilityService';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Error from '../../../components/assessments/Error';
import AppButton from '../../../components/AppButton';

const RequestShareVerification = () => {
	const [loadingResponse, setLoadingResponse] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState('');
	const [success, setSuccess] = React.useState(false);

	const location = useLocation();
	const { assessment_id, roster_id, token = '' } = getQueryParams(location.search);

	const verifyTokenIntegrity = () => {
		AssessmentsService.getRequestShareValidity({ assessment_id, roster_id, token })
			.then(() => {
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				setError(e.message);
			});
	};

	React.useEffect(() => {
		verifyTokenIntegrity();
	}, []);

	const sendResponse = (payload) => {
		setLoadingResponse(true);
		AssessmentsService.sendRequestShareResponse(payload)
			.then((result) => {
				setLoadingResponse(false);
				setSuccess(true);
				NotificationService.success(result.data.message);
				setTimeout(() => {
					navigate('/');
				}, 5000);
			})
			.catch((e) => {
				setLoadingResponse(false);
				setError(e.message);
			});
	};

	const renderContent = () => {
		if (loading) {
			return (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			);
		}
		if (error) {
			return <Error message={error} />;
		}
		if (success) {
			return (
				<div className='mt-8 text-center'>
					<p className={'text-primary mb-6'}>
						Thank you for your response. <br /> You will be redirected to our homepage.
					</p>
				</div>
			);
		}
		return (
			<div className={'mt-8 text-center'}>
				<h1 className={'text-xl text-primary font-semibold mb-6'}>Confirm allow share request</h1>
				<p className={'text-primary mb-6'}>Please confirm your allow share request</p>

				<div className='flex items-center justify-center'>
					<AppButton
						onClick={() => sendResponse({ allow_share: 1, assessment_id, roster_id, token })}
						className={'btn btn-primary'}
						text={'Allow to share'}
						id={'wg-request-share-allow-btn'}
						disabled={loadingResponse}
					/>
					<AppButton
						onClick={() => sendResponse({ allow_share: 0, assessment_id, roster_id, token })}
						className={'btn btn-secondary ml-2'}
						text={'Deny'}
						id={'wg-request-share-deny-btn'}
						disabled={loadingResponse}
					/>
				</div>
			</div>
		);
	};

	return <Layout>{renderContent()}</Layout>;
};

export default RequestShareVerification;
