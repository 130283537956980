import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';

import AppButton from '../../AppButton';
import AppRIIcon from '../../AppRIIcon';
import LineBreak from '../edit/LineBreak';
import CustomerService from '../../../services/CustomerService';
import ReCaptcha from '../../ReCaptcha';
import AssessmentsService from '../../../services/AssessmentsService';
import NotificationService from '../../../services/NotificationService';
import { student_add, student_assessment_icon, student_email } from '../../../images';
import Results from './student/Results';
import Geniuses from './student/Geniuses';
import ReportWithheld from './student/ReportWithheld';
import {useUser} from "../../../hooks/UserProvider";

const ConfirmationStep = ({ assessment, assessmentId, rosterId, nextStep, studentMode = false }) => {

	if (assessment?.redirect_url && assessment?.source === 'API') {
		window.location.href = assessment.redirect_url;
	}

	const [loading, setLoading] = React.useState(false);
	const [feedback, setFeedback] = React.useState({
		success: false,
		error: false,
		icon: '',
		iconColor: '',
		title: '',
		description: '',
		info: '',
		ctaText: '',
		ctaAction: '',
	});
	const captchaRef = React.useRef('captcha');
	const { user } = useUser();

	const { t } = useTranslation();

	const renderMessage = () => {
		if (assessment.send) {
			if (studentMode) {
				return 'Thank you for completing The 6 Types of Working Genius Assessment for Students. A copy of your report will be e-mailed to you shortly.';
			}
			return t('assessment.take.confirmation.description_send');
		}
		return t('assessment.take.confirmation.description_no_send', {
			main_email: assessment.main_email,
		});
	};

	const renderStudentMessage = () => {
		return <div className='student-assessment-complete-message'>
			<img src={student_email} alt={'student mail image'} width='80' />
			<p className='text-white text-center font-semibold text-base md:text-lg lg:text-2xl my-4 w-4/5'>
				{assessment.send &&
					'Thank you for completing The 6 Types of Working Genius Assessment for Students. A copy of your report will be e-mailed to you shortly.'}
			</p>
		</div>
	};

	const onDone = () => {
		navigate(user && user.id ? '/client/' : '/');
	};

	const createAccount = async (resend = false) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		CustomerService.invite({
			roster_id: assessment.roster_id,
			assessment_id: assessment.assessment_id,
			captcha: recaptchaValue,
		})
			.then(() => {
				setFeedback({
					success: true,
					error: false,
					icon: 'mail-send-line',
					iconColor: 'text-green-600',
					title: resend
						? t('assessment.take.confirmation.create_account.resent_title')
						: t('assessment.take.confirmation.create_account.sent_title'),
					description: t('assessment.take.confirmation.create_account.sent_description'),
					info: t('assessment.take.confirmation.create_account.not_received'),
					ctaText: t('assessment.take.confirmation.create_account.resend_invite'),
					ctaAction: () => createAccount(true),
				});
			})
			.catch(() => {
				setFeedback({
					success: false,
					error: true,
					icon: 'mail-close-line',
					iconColor: 'text-red-600',
					title: t('assessment.take.confirmation.create_account.not_sent_title'),
					description: t('assessment.take.confirmation.create_account.not_sent_description'),
					info: t('assessment.take.confirmation.create_account.not_received'),
					ctaText: t('assessment.take.confirmation.create_account.resend_invite'),
					ctaAction: () => createAccount(true),
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const onAddToAccount = () => {
		setLoading(true);
		AssessmentsService.addToExistingAccount({
			assessment_id: assessmentId,
			roster_id: rosterId,
		})
			.then(() => {
				nextStep();
			})
			.catch((error) => {
				NotificationService.error('Error', error.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const renderFooter = () => {
		if (assessment.add_to_existing_account && assessment.send) {
			return (
				<div className='flex justify-center flex-col'>
					<div className='my-4'>
						<LineBreak />
					</div>
					<span className={'text-primary text-2xl text-center font-bold mb-4'}>
						{t('assessment.take.confirmation.add_to_existing_account.title')}
					</span>
					<span className='text-center text-primary'>
						{t('assessment.take.confirmation.add_to_existing_account.description')}
					</span>
					<div className={'flex flex-col justify-center text-center mx-auto max-w-sm pt-4'}>
						<AppButton
							id='wg-add-to-account'
							text={t('assessment.take.confirmation.add_to_existing_account.button')}
							onClick={onAddToAccount}
							loading={loading}
						/>
						<span className='text-tiny text-gray-500 pt-2'>
							{t('assessment.take.confirmation.add_to_existing_account.button_description')}
						</span>
					</div>
					<span className={'text-center text-secondary cursor-pointer pt-4'} onClick={() => navigate('/')}>
						{t('assessment.take.confirmation.add_to_existing_account.skip')}
					</span>
				</div>
			);
		}

		if (assessment.has_account) {
			return (
				<div className='flex justify-center my-4'>
					<AppButton text={t('done')} onClick={onDone} id={'wg-assessment-done-btn'} />
				</div>
			);
		}

		return (
			<div className={'text-center'}>
				<div className='my-8'>
					<LineBreak />
				</div>
				<h1 className={'text-primary text-xl text-center font-bold mb-4'}>
					{t('assessment.take.confirmation.create_account.title')}
				</h1>
				<p className={'text-primary my-4'}>{t('assessment.take.confirmation.create_account.description')}</p>
				<AppButton
					loading={loading}
					className={'btn btn-primary w-96 max-w-full'}
					text={t('assessment.take.confirmation.create_account.button')}
					onClick={() => createAccount()}
				/>
				<p className={'text-tiny text-gray-500 mt-4 w-96 max-w-full mx-auto'}>
					{t('assessment.take.confirmation.create_account.button_description')}
				</p>
				<p className={'text-secondary mt-4 cursor-pointer'} onClick={onDone}>
					{t('assessment.take.confirmation.create_account.skip')}
				</p>
			</div>
		);
	};

	if (feedback.success || feedback.error) {
		return (
			<div className={'flex flex-col justify-center mx-4'}>
				<AppRIIcon icon={feedback.icon} className={`${feedback.iconColor} text-6xl text-center pb-4 mt-6`} />
				<h1 className={'text-primary text-xl text-center font-bold mb-4'}>{feedback.title}</h1>
				<p className='text-primary text-center w-160 max-w-full mx-auto'>{feedback.description}</p>
				{feedback.info && <p className={'text-center text-secondary mt-10 mb-1'}>{feedback.info}</p>}
				<AppButton
					loading={loading}
					className={'btn btn-primary w-96 max-w-full mx-auto'}
					text={feedback.ctaText}
					onClick={feedback.ctaAction}
				/>
				<ReCaptcha ref={captchaRef} />
			</div>
		);
	}


	const renderStudentVersion = () => {
		return <div className={'flex flex-col justify-center mx-4'}>
			<div className='student-assessment-complete-section'>
				<img src={student_assessment_icon} alt='Student Assessment' width='100' />
				<h1 className={'text-white text-2xl lg:text-4xl font-bold my-4 text-center'}>
					{t('assessment.take.confirmation.title')}
				</h1>
			</div>
			{assessment.send ?
				<>
					{assessment.assessment_results &&
						<div className='flex flex-col lg:flex-row items-stretch justify-center gap-6 my-6'>
							<Results results={assessment.assessment_results} />
							<Geniuses results={assessment.assessment_results} />
						</div>
					}
					<div className='bg-primary py-12'>
						{renderStudentMessage()}
						{renderStudentFooter()}
					</div>
				</> :
				<ReportWithheld administrator={assessment.main_email} onDone={onDone} />
			}
		</div>
	}

	const renderStudentFooter = () => {
		if (assessment.add_to_existing_account && assessment.send) {
			return (
				<div className='flex justify-center flex-col mt-6'>
					<img src={student_add} alt='Add account' width='50' className='mx-auto' />
					<span className={'text-white text-2xl text-center font-bold my-4'}>
						Add to Your Account
					</span>
					<span className='text-center text-white'>
						It looks like you already have a Working Genius account. <br /> Would you like to add this assessment to your account as well?
					</span>
					<div className={'flex flex-col justify-center items-center text-center mx-auto max-w-sm pt-4'}>
						<AppButton
							id='wg-add-to-account'
							text={t('assessment.take.confirmation.add_to_existing_account.button')}
							onClick={onAddToAccount}
							loading={loading}
							className={'btn btn-student my-4 w-56'}
						/>
						<span className='text-tiny text-gray-500 pt-2'>
							{t('assessment.take.confirmation.add_to_existing_account.button_description')}
						</span>
					</div>
					<span className={'text-center text-white cursor-pointer pt-4 underline font-semibold'} onClick={() => navigate('/')}>
						{t('assessment.take.confirmation.add_to_existing_account.skip')}
					</span>
				</div>
			);
		}

		if (assessment.has_account) {
			return (
				<div className='flex justify-center my-4'>
					<AppButton text={t('done')} onClick={onDone} id={'wg-assessment-done-btn'} className='btn btn-student' />
				</div>
			);
		}

		return (
			<div className={'text-center mt-6 mx-4'}>
				<img src={student_add} alt='Add account' width='50' className='mx-auto' />
				<h1 className={'text-white text-xl text-center font-bold my-4'}>
					Create a FREE account
				</h1>
				<p className={'text-white my-4 md:mx-auto w-9/10 md:w-3/5'}>
					If you would like to create a <span className='font-semibold'>FREE Working Genius account</span>, you can easily share your assessment results with other Working Genius users from the Administrator Portal. The Working Genius portal allows you to utilize our Team Map tools, Summary Articles, Working Genius Podcast links and updates all in one place. It only takes a few seconds...
				</p>
				<AppButton
					loading={loading}
					className={'btn btn-student max-w-full'}
					text={t('assessment.take.confirmation.create_account.button')}
					onClick={() => createAccount()}
				/>
				<p className={'text-tiny text-gray-500 mt-4 w-96 max-w-full mx-auto'}>
					{t('assessment.take.confirmation.create_account.button_description')}
				</p>
				<p className={'text-white font-semibold text-lg mt-4 cursor-pointer underline'} onClick={onDone}>
					{t('assessment.take.confirmation.create_account.skip')}
				</p>
			</div>
		);
	};

	return (
		<>
			{studentMode ? renderStudentVersion() :
				<div className={'flex flex-col mx-4'}>
					<AppRIIcon icon={'clipboard-line'} className={'text-green-500 text-6xl text-center pb-4 mt-6'} />
					<h1 className={'text-primary text-3xl text-center font-bold mb-4'}>
						{t('assessment.take.confirmation.title')}
					</h1>
					<p
						className='text-primary text-center'
						id={'take-assessment-confirmation-message'}
						dangerouslySetInnerHTML={{ __html: renderMessage() }}
					/>
					{renderFooter()}
				</div>}
			<ReCaptcha ref={captchaRef} />
		</>
	);
};

export default ConfirmationStep;
