import React from 'react';
import { report_gear, WidgetReportV2 } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const WhatsNext = () => {
    const renderGear = (text) => {
        return (
            <div className={'flex flex-row my-3'}>
                <img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
                <div>
                    <p>{text}</p>
                </div>
            </div>
        );
    };

    return (
        <div className='Application'>
            <PageHeader title={'ANWENDUNG'} />

            <div className={'container mt-6'}>
                <PageTitle title={'Was kommt als Nächstes? Anwendung des Working Genius'} className={'mt-2'} />
                <div className='mr-25'>
                    <p className={'mt-4'}>
                        Es ist schmerzhaft, sich vorzustellen, dass es viele Menschen auf der Welt gibt, die in Jobs feststecken, die nicht mit
                        ihrem Working Geniuses übereinstimmen, oder vielleicht sogar in Rollen, die sie zwingen, im Bereich ihrer Frustration
                        zu arbeiten. Noch schlimmer ist es, dass viele von ihnen gar nicht wissen, warum sie unglücklich sind. Menschen, die
                        sich die Möglichkeiten dieses Modells zu eigen machen, können mehr Selbsterkenntnis erfahren, mehr Freude und
                        Erfüllung erleben, ihre Produktivität steigern und unnötige Schuldgefühle und abschätzige Urteile abbauen.
                    </p>

                    <p className={'mt-4'}>
                        Wie Sie die Ergebnisse Ihres Working Genius nutzen, hängt davon ab, wo Sie in Ihrer Arbeit und in Ihrem Leben stehen
                        und warum Sie sich entschieden haben, das Assessment überhaupt zu machen. Hier sind einige Möglichkeiten:
                    </p>

                    <div className='font-bold uppercase mt-4 mb-2'>ARBEITEN SIE MIT IHREM GENIUS.</div>
                    <div className={'ml-8'}>
                        <p>Schätzen Sie Ihr persönliches und berufliches Leben ein.</p>
                        {renderGear(
                            'Finden Sie heraus, wie Sie Ihre Working Geniuses effektiver nutzen und weniger in Ihren Working Frustrations arbeiten.'
                        )}
                        {renderGear(
                            'Überlegen Sie, wie Sie Ihre Arbeit planen damit Sie mit Competency oder Frustration verbundene Tätigkeiten dann angehen können, wenn Sie mehr Energie haben.'
                        )}
                        {renderGear(
                            'Ermitteln und benennen Sie, wann Sie außerhalb der Bereiche Ihrer Geniuses arbeiten, damit Sie bewusst und zielgerichtet mit Ihrer Energie umgehen können.'
                        )}
                        {renderGear(
                            'Bitten Sie Ihr Umfeld um Anregungen und um Unterstützung in Bereichen außerhalb Ihrer Geniuses.'
                        )}
                    </div>

                    <div className='font-bold uppercase mt-6 mb-2'>TEILEN SIE IHRE ERGEBNISSE MIT ANDEREN.</div>
                    <div className={'ml-8'}>
                        <p>
                            Stellen Sie sich vor, wie sich Ihre Beziehungen verbessern könnten, wenn Sie die Geniuses und
                            Frustrations des anderen besser verstehen würden.
                        </p>
                        {renderGear(
                            'Beginnen Sie damit, anderen (Familie, Freunden, Kollegen, Teammitgliedern) zu erzählen, was Sie über sich selbst herausgefunden haben.'
                        )}
                        {renderGear(
                            'Laden Sie sie ein, das Working Genius Assessment zu absolvieren, und besprechen Sie Ihre Ergebnisse miteinander.'
                        )}
                        {renderGear(
                            'Besprechen Sie, wie Sie sich gegenseitig helfen können, Ihre Geniuses zu nutzen und Ihre Frustrations zu vermeiden.'
                        )}
                        {renderGear(
                            'Bieten Sie an, Ihre Working Geniuses einzusetzen, um anderen zu helfen.'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatsNext;
