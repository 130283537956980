import React from 'react';

const AppText = ({ text, ...props }) => {
	return (
		<p {...props} className={`app-normal-text ${props.className ? props.className : ''}`}>
			{text}
		</p>
	);
};

export default AppText;
