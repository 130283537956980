import React from 'react';
import { cert_section_1, prework_complete_step, prework_incomplete_step } from '../../../images';

const steps = [
	'register',
	'complete <br/> prework',
	'complete online certification session',
	'access post-training resources',
];

const Hero = ({ customer }) => {
	const status_id = customer.wg_certification_status.status_id;

	const renderSvg = (key) => {
		return (
			<img className={'w-12 h-12 z-10'} src={key < status_id ? prework_complete_step : prework_incomplete_step} />
		);
	};

	const renderStep = (step, key) => {
		return (
			<div
				className={`hero-step ${key < status_id ? 'completed' : 'not-completed'} ${
					key === steps.length - 1 ? 'last' : ''
				}`}
				key={key}
			>
				{renderSvg(key)}
				<p
					className={'uppercase text-xl mt-6'}
					dangerouslySetInnerHTML={{ __html: step }}
					style={{ color: key < status_id ? '#6284C0' : 'white' }}
				/>
				{key === 1 && (
					<span className='tooltiptext'>Complete the below steps in order to complete this item.</span>
				)}
			</div>
		);
	};

	return (
		<div
			className={'text-center py-24'}
			style={{
				backgroundImage: `url(${cert_section_1})`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className={'mx-4'}>
				<h1 className={'text-5xl text-white'}>Certification Process</h1>
				<span className={'text-white'}>
					Become a Certified Working Genius Facilitator with Patrick Lencioni and the Table Group.
				</span>
			</div>
			<div className={'prework-stepper'}>
				{steps.map((step, key) => {
					return renderStep(step, key);
				})}
			</div>
		</div>
	);
};

export default Hero;
