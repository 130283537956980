import React from 'react';

import PolicyContent from '../../components/privacy/PolicyContent';
import { privacyPolicyConstants, termsOfServiceConstants } from '../../components/privacy/policyConstants';
import PolicyHero from '../../components/privacy/PolicyHero';

const PolicyPage = ({ type }) => {
	const { hero, content } = type === 'privacy' ? privacyPolicyConstants : termsOfServiceConstants;

	return (
		<>
			<PolicyHero heroData={hero} />
			<PolicyContent contentData={content} />
		</>
	);
};

export default PolicyPage;
