import React from 'react';

const LicensePreWork = () => {
	return (
			<div className={'dca-license-section'}>
				<span className={'text-2xl md:text-3xl font-medium'}>Working Genius Licenses & Pre-Work</span>

				<span className={'text-lg pt-6'}>
					We are thrilled that you are registered for Working Genius Certification delivered by Dale Carnegie!
					We provide 20 complimentary Working Genius Licenses as part of your course offering, along with
					required Pre-Work videos to review before your session. Your next step is to create a Working Genius
					account (or login to your current account) in order to access your licenses and pre-work.
				</span>
			</div>
	);
};

export default LicensePreWork;
