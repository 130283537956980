import React from "react";
import AppRIIcon from "../../components/AppRIIcon";
import AppButton from "../../components/AppButton";
import {navigate} from "gatsby";
import {useTranslation} from "react-i18next";

const AddToAccount = () => {
    const { t } = useTranslation();

    return (
        <div className={'flex flex-col justify-center items-center mx-4 mt-10'}>
            <AppRIIcon icon={'clipboard-line'} className={`text-green-500 text-6xl text-center pb-4 mt-6`}/>
            <span className={'text-primary text-xl text-center font-bold mb-2'}>{t('assessment.take.confirmation.add_to_existing_account.success')}</span>
            <span className='text-primary text-center w-160 max-w-full mx-auto'>{t('assessment.take.confirmation.add_to_existing_account.success_description')}</span>
            <AppButton
                className={'btn btn-primary w-96 max-w-full mx-auto mt-8'}
                text={t('assessment.take.confirmation.add_to_existing_account.my_account_button')}
                onClick={() => navigate('/client')}
            />
        </div>
    )
}

export default AddToAccount;