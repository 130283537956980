import React, { useState, useEffect, useRef, useCallback } from 'react';

import Header from './Header';
import TeamMapFooter from '../../assessments/teammap/TeamMapFooter';

const PodcastNotes = ({ podcast }) => {
	const containerRef = useRef(null);
	const showNotes = podcast?.content;

	const [pages, setPages] = useState([]);

	const pageHeight = 845;

	const measureHeight = (content) => {
		const styledDiv = document.createElement('div');
		styledDiv.innerHTML = content;
		styledDiv.style.visibility = 'hidden';
		styledDiv.style.width = '37rem';
		styledDiv.className = 'wg-podcast-pdf-page-content';

		document.body.appendChild(styledDiv);

		const height = styledDiv.clientHeight;

		document.body.removeChild(styledDiv);

		return height;
	};

	const splitSectionsIntoPages = useCallback(() => {
		const sections = showNotes.split('\n').filter((section) => section.trim() !== '');

		let currentPage = '';
		const pdfPages = [];

		sections.forEach((section) => {
			const newPageContent = `${currentPage}\n${section}`;
			const sectionHeight = measureHeight(newPageContent);

			if (sectionHeight < pageHeight) {
				currentPage = newPageContent;
			} else {
				pdfPages.push(currentPage.trim());
				currentPage = section;
			}
		});

		pdfPages.push(currentPage.trim());
		setPages(pdfPages);
	}, [showNotes]);

	useEffect(() => {
		if (!showNotes) return;
		splitSectionsIntoPages();
	}, [showNotes, splitSectionsIntoPages]);

	return (
		<div id={'wg-podcast-pdf'} className={'wg-podcast-pdf'} ref={containerRef}>
			{pages.map((pageContent, index) => (
				<section key={index} role='region' className='Page'>
					<Header title={podcast?.title} />

					<div className={'PageContent px-16 py-4'}>
						<span className={'wg-podcast-pdf-page-content'}>
							{index === 0 && <h1 className={'show_notes_title'}>Show Notes</h1>}
							<span dangerouslySetInnerHTML={{ __html: pageContent }} />
						</span>
					</div>

					<TeamMapFooter />
				</section>
			))}
		</div>
	);
};

export default PodcastNotes;
