export const texts = {
    standard: {
        group: {
            title: 'Group Assessment Setup',
            organization: 'Company',
            organizationPrompt: 'Please enter the company name',
            note: 'We now offer the Working Genius assessment and report in English, Spanish and German. The assessment taker can choose the language they prefer when they begin the assessment. If an assessment taker selects to take the assessment in a specific language, they will receive their results in that language as well. Please note, as the administrator console owner you are able to download all reports in the language of your choice.'
        },
        individual: {
            title: 'Individual Assessment Setup',
            organization: 'Company',
            organizationPrompt: 'Please enter the company name',
            note: 'We now offer the Working Genius assessment and report in English, Spanish and German. The assessment taker can choose the language they prefer when they begin the assessment. If an assessment taker selects to take the assessment in a specific language, they will receive their results in that language as well. Please note, as the administrator console owner you are able to download all reports in the language of your choice.',
        },
        welcome:
            'Thank you for taking time to complete The Six Types of Working Genius Assessment. Before you begin, take a moment to consider what type of work is most energizing for you.\n' +
            'This assessment is the simplest and most practical productivity tool to discover your gifts and put your natural talents to use at work.\n' +
            '\n',
    },
    student: {
        group: {
            title: 'Student Group Assessment Setup',
            organization: 'School',
            organizationPrompt: 'Please enter the school name',
            note: 'Working Genius Assessment for Students is available only in English.',
        },
        individual: {
            title: 'Student Individual Assessment Setup',
            organization: 'School',
            organizationPrompt: 'Please enter the school name',
            note: 'Working Genius Assessment for Students is available only in English.',
        },
        welcome:
            'Thank you for taking time to complete The Six Types of Working Genius Assessment for Students. Before you begin, consider the joy and fulfillment you experience during activities you do at school or home.\n' +
            'This assessment is the simplest and most practical productivity tool to discover your gifts and put your natural talents to use.\n' +
            '\n',
    },
};