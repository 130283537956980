import React from 'react';
import { meetings_illustrative, new_pat_plain, spread_icon } from '../../images';

const EventPat = () => {
    return (
        <div className='meetings-pat'>
            <div className='container'>
                <div className='meetings-pat-content'>
                    <div className='meetings-pat-cover flex flex-col items-center'>
                        <img src={meetings_illustrative} alt='Meetings' width='320' />
                    </div>
                    <div className='meetings-pat-text text-center py-6 lg:w-1/3'>
                        <h2 className='text-base md:text-lg text-primary font-normal'>
                            Watch with friends, clients,
                            <br />
                            &amp; co-workers.
                        </h2>
                        <img src={spread_icon} width='30' className='my-4 mx-auto' />
                        <h2 className='text-base lg:text-lg text-primary font-semibold uppercase text-center leading-1 tracking-wide'>
                            Exclusive Broadcast time <br />
                            November 8th at <br />
                            11 am pacific time <br />2 pm eastern time
                        </h2>
                    </div>
                </div>
            </div>
            <div className='meetings-pat-mobile flex items-center justify-center lg:hidden my-4'>
                <img src={new_pat_plain} alt='Patrick Lencion' width='320' />
            </div>
        </div>
    );
};

export default EventPat;
