import React from 'react';
import { patrick_plain, ttg_logo_event } from '../../images';

const EventPat = () => {
	return (
		<div className='event-pat-section'>
			<div className='container'>
				<div className='event-pat-content'>
					<div className='event-pat-text py-10 w-full lg:w-2/3'>
						<h2 className='text-2xl md:text-4xl text-primary font-normal my-4'>About Patrick Lencioni</h2>
						<p className='text-primary font-lg my-4'>
							Pat is one of the founders of The Table Group and is the pioneer of the organizational
							health movement. He is the author of 13 books which have sold over 6 million copies and been
							translated into more than 30 languages.
						</p>
						<p className='text-primary font-lg my-4'>
							As President of the Table Group, Pat spends his time speaking and writing about leadership,
							teamwork, and organizational health and consulting with executives and their teams.
						</p>
						<p className='text-primary font-lg my-4'>
							Prior to founding the firm in 1997, Pat worked at Bain & Company, Oracle Corporation and
							Sybase. Pat lives in the Bay Area with his wife and four boys.
						</p>
						<div className='flex flex-col items-center justify-center md:items-start mt-8'>
							<a
								href='https://www.tablegroup.com/about/'
								target='_blank'
								rel='noreferrer'
								className={'btn btn-primary mr-0 lg:mr-2'}
								id={'wg-event-learn-more-btn'}
							>
								Learn more about the table group
							</a>
							<img
								width='200'
								src={ttg_logo_event}
								alt='Working Geniuses Primary Logo'
								className='my-8'
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='event-pat-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
		</div>
	);
};

export default EventPat;
