const craves = {
	consideration: {
		title: 'Consideration. ',
		description:
			'They want people to genuinely consider what they are wondering about and for their questions to be heard. "Hey, that\'s a great question."',
	},
	freedom: {
		title: 'Freedom. ',
		description: 'They want the freedom to create and invent without constraints or restrictions.',
	},
	trust: {
		title: 'Trust. ',
		description: 'They want you to trust their judgment, instincts, and assessment of things.',
	},
	reaction: {
		title: 'Reaction. ',
		description:
			'They want engagement, and confirmation that the thing they are advocating for is real and worthwhile.',
	},
	appreciation: {
		title: 'Appreciation. ',
		description:
			'They want to be acknowledged for their inherent value, but they are not necessarily seeking recognition, attention, or credit. "That made a big difference. You really helped."',
	},
	clarity: {
		title: 'Clarity. ',
		description:
			'They want deadlines and parameters. "Tell me exactly what you need, then let me do what is necessary to complete the task.”',
	},
};

const crushes = {
	being_dismissed: {
		title: 'Being dismissed. ',
		description: 'They dislike hearing, "Who cares!?" or "We don\'t have time to stop and think about that."',
	},
	constraint: {
		title: 'Constraint. ',
		description: "They dislike restrictive parameters or being forced to stay 'inside the box.'",
	},
	prove_it: {
		title: '"Prove it." ',
		description: 'They dislike having to argue for people to believe what their gut is telling them.',
	},
	apathy: {
		title: 'Apathy. ',
		description:
			'They hate it when no one responds to their calls to action, or when their enthusiasm is met with silence.',
	},
	being_overlooked: {
		title: 'Being overlooked. ',
		description:
			"They dislike it when their efforts are taken for granted—when you don't acknowledge that they have helped, or if you ignore the impact of their work.",
	},
	ambiguity: {
		title: 'Ambiguity. ',
		description: 'They dislike unclear standards and directions, or expectations that suddenly change.',
	},
};

const WI = {
	sort: 1,
	title: 'The Creative Dreamer',
	brief: 'A passionate idealist with an endless stream of big questions and ideas to consider. Comfortable with their head in the clouds.',
	description:
		'People with this pairing derive real joy and energy from contemplating the world around them and generating a fountain of new ideas. Generally idealistic and future-oriented, they are passionate about new possibilities that break through unnecessary boundaries.  They don’t value practicality, focus or implementation as much as idealism and ingenuity.  This sometimes leads to stress or chaos among people around them who might be looking for realism and actionability.',
	studentBrief:
		'Curious and idealistic. Often asks big questions and enjoys coming up with new ideas. Comfortable with their head in the clouds.',
	studentDescription:
		'You are curious and idealistic. You often ask big questions and enjoy coming up with new ideas. You are comfortable with your head in the clouds.',
	studentPotentialChallenges: 'Because practicality and focus are not their top priorities, it may lead to some stress or chaos for those around them who are more focused on getting things done.',
	studentBriefPoints: [
		'People with this pairing get joy and energy from dreaming up possibilities.',
		'They are always looking to the future.',
		'They’re passionate about new ideas that break through boundaries.'
	],
	crave: [craves.consideration, craves.freedom],
	crush: [crushes.being_dismissed, crushes.constraint],
};

const WD = {
	sort: 4,
	title: 'The Contemplative Counselor',
	brief: 'A thoughtful, insightful, and nuanced advisor. Slow to declare certainty but deep in wisdom, intuition, and rationale.',
	description:
		'People with this pairing derive real joy and satisfaction from using their intuition and instincts to evaluate ideas and plans, and to provide others with thoughtful feedback.  They tend to be extremely observant and deep in their thinking, and express their insights using impactful words.  Because of their proclivity to nuance, they can sometimes be hesitant to declare certainty or express confidence in their own judgments.',
	studentBrief:
		'Reflective and insightful. Thoughtfully approaches problems and enjoys giving sound advice. Takes time to carefully evaluate ideas and situations.',
	studentDescription:
		'You are reflective and insightful. You thoughtfully approach problems and enjoy giving sound advice. You take time to carefully evaluate ideas and situations.',
	studentPotentialChallenges: 'Because they’re inclined to see the nuances in situations, they sometimes hold back from being certain or overly confident in their judgments.',
	studentBriefPoints: [
		'People with this pairing get joy and energy in using their intuition and instincts to assess ideas and plans.',
		'They’re observant thinkers, diving deep into their thoughts and expressing their insights with impactful words.',
		'They enjoy offering thoughtful feedback.'
	],
	crave: [craves.consideration, craves.trust],
	crush: [crushes.being_dismissed, crushes.prove_it],
};

const WG = {
	sort: 7,
	title: 'The Philosophical Motivator',
	brief: 'An excitable, curious, and enthusiastic proponent of ideas and people. A unique combination of eagerness and thoughtful reflection.',
	description:
		'People with this pairing derive real joy and energy from contemplating potential in the world around them, passionately exhorting and encouraging others.  They are idealistic and enthusiastic.  Their combination leads them to sometimes experience and portray a sense of impulsivity or unpredictability, as they go back and forth between passionate action and cautious reflection.',
	studentBrief:
		'Enthusiastic and reflective. Enjoys exploring possibilities and rallying others around them. Possesses a unique combination of eagerness and thoughtful reflection.',
	studentDescription:
		'You are enthusiastic and reflective. You enjoy exploring possibilities and rallying others around them. You possess a unique combination of eagerness and thoughtful reflection.',
	studentPotentialChallenges: 'Because they swing between action and caution, they can seem impulsive and unpredictable.',
	studentBriefPoints: [
		'People with this pairing get joy and energy from pondering the potential in the world around them and communicating their excitement to others.\n',
		'They are idealistic and full of passion.',
		'They exhibit an interesting blend of prudence and enthusiasm.'
	],
	crave: [craves.consideration, craves.reaction],
	crush: [crushes.being_dismissed, crushes.apathy],
};

const WE = {
	sort: 10,
	title: 'The Idealistic Supporter',
	brief: 'A loyal, modest, and thoughtful contributor. Carefully considers others’ needs and reluctant to demand attention.',
	description:
		'People with this pairing derive real joy and energy from providing meaningful support to causes that align with their idealistic values and sense of belonging.  They are often modest about their abilities, loyal and selfless, proving to be extremely valuable, low-maintenance team members.  Because of their depth and desire to please others, they can sometimes be overly deferential and often hesitate to initiate change, preferring to let others make the first move.',
	studentBrief:
		'Reflective and considerate. Thoughtfully contributes without demanding attention. Enjoys providing others with meaningful support.',
	studentDescription:
		'You are reflective and considerate. You thoughtfully contribute without demanding attention. You enjoy providing others with meaningful support.',
	studentPotentialChallenges: 'Because they’re pleasers and deep thinkers, they might be hesitant to take the lead or initiate change and often prefer to let others make the first move.',
	studentBriefPoints: [
		'People with this pairing get joy and energy in backing causes that align with their idealistic values and sense of belonging.',
		'They are loyal, selfless, and modest about their abilities.',
		'They are valuable team members.'
	],
	crave: [craves.consideration, craves.appreciation],
	crush: [crushes.being_dismissed, crushes.being_overlooked],
};

const WT = {
	sort: 13,
	title: 'The Careful Implementer',
	brief: 'A thorough, practical, and careful contributor. A unique combination of steady reliability and quiet depth.',
	description:
		'People with this pairing derive real joy and satisfaction from getting things done in a thorough and reliable way, as long as they understand the rationale and importance of what they are doing.  They are a mysterious mix of practicality and inquisitiveness, action, and reflection.  As a result of the divergence between Wonder and Tenacity (they are at opposite ends of the process of work), they often find themselves experiencing and projecting angst in the midst of a project, feeling an almost overwhelming need to slow down and ask questions that might alleviate potential problems.',
	studentBrief:
		'Inquisitive and practical. Spends time reflecting before taking action. Possesses a rare combination of wanting to get things done and slowing down to consider things deeply.',
	studentDescription:
		'You are inquisitive and practical. You spend time reflecting before taking action. You possess a rare combination of wanting to get things done and slowing down to consider things deeply.',
	studentPotentialChallenges: 'Because their talents are at opposite ends of the work process (Wonder and Tenacity), they might experience moments of angst towards the end of a project. Because of this, they often feel an overwhelming need to slow down and go back up to Wonder.',
	studentBriefPoints: [
		'People with this pairing find joy and energy in getting things done thoroughly and reliably, as long as they understand the ‘why’ behind what they’re doing.',
		'They are a fascinating blend of practicality and curiosity.',
		'They are simultaneously reflective and action-oriented.'
	],
	crave: [craves.consideration, craves.clarity],
	crush: [crushes.being_dismissed, crushes.ambiguity],
};

const ID = {
	sort: 2,
	title: 'The Discriminating Ideator',
	brief: 'A creative, intuitive, and confident generator of new ideas. Uses instinct and integrative thinking to solve real problems. ',
	description:
		'People with this pairing get real energy and joy from using their intuition and instinctive ability to come up with new ideas from scratch, and they are good at using integrative thinking and pattern recognition rather than data and expertise.  As a result, they are particularly effective in solving problems and coming up with workable, practical solutions with a particularly high level of success.  They can sometimes appear impatient and over-confident when they receive pushback around their ideas, and they often lose interest after the initial phase of Invention is over.',
	studentBrief:
		'Creative and intuitive. Enjoys coming up with ideas from scratch. Uses good judgment to find unique solutions to problems.',
	studentDescription:
		'You are creative and intuitive. You enjoy coming up with ideas from scratch. You use good judgment to find unique solutions to problems.',
	studentPotentialChallenges: 'Because they’re so effectively innovative, they might seem a bit impatient or overly confident when they receive pushback around their ideas, and they tend to lose interest in the work once the initial inventive phase is done.',
	studentBriefPoints: [
		'People with this pairing get joy and energy when using their intuition and instinct to create new and original ideas.',
		'They excel in integrative thinking and pattern recognition, leaning more on intuition and creativity than data or expertise.',
		'They’re effective in problem-solving and coming up with practical solutions with a high success rate.'
	],
	crave: [craves.freedom, craves.trust],
	crush: [crushes.constraint, crushes.prove_it],
};

const IG = {
	sort: 5,
	title: 'The Evangelizing Innovator',
	brief: 'An excitable and convincing generator and promoter of new ideas. Combines curiosity and confidence with infectious enthusiasm.',
	description:
		'People with this pairing derive real joy and energy from generating new ideas and convincing others of an idea’s merit.  Exuberant and enthusiastic, they are not easily deterred by initial pushback, and enjoy the challenge of sharing ideas to convince others to act.  They can be hasty to start selling ideas before taking time to fully consider the prudence of an idea, and can be initially perceived as slightly over-the-top in their approach.',
	studentBrief:
		'Creative and enthusiastic. Enjoys promoting new ideas and convincing others to get involved. Easily excited.',
	studentDescription:
		'You are creative and enthusiastic. You enjoy promoting new ideas and convincing others to get involved. You are easily excited.',
	studentPotentialChallenges: 'Because they are so excited to hit the ground running, they may come across as a bit over- the-top, careless or even disingenuous.',
	studentBriefPoints: [
		'People with this pairing get joy and energy when they’re brainstorming and convincing others of the merit of their ideas.',
		'They’re full of enthusiasm and not easily thrown off by the first bit of resistance.',
		'They thrive on the challenge of getting others on board.'
	],
	crave: [craves.freedom, craves.reaction],
	crush: [crushes.constraint, crushes.apathy],
};

const IE = {
	sort: 8,
	title: 'The Adaptable Designer',
	brief: 'A generator of new ideas in response to the needs of others. A unique combination of creativity and flexibility.',
	description:
		'People with this pairing derive real joy and energy from providing new solutions and ideas that serve the unique needs of others.  They have the rare ability to balance their passion for ingenuity with the capacity to adjust to the desires of the people they are serving.  They are creative, flexible, open to feedback, and easy to work with.  In their desire to please others, they are too quick to accommodate and avoid advocating for their own ideas.  This can leave them feeling undervalued.',
	studentBrief:
		'Creative and flexible. Enjoys generating new ideas and responding to the needs of others. Easily adapts to feedback and new information.',
	studentDescription:
		'You are creative and flexible. You enjoy generating new ideas and responding to the needs of others. You easily adapt to feedback and new information.',
	studentPotentialChallenges: 'Because they’re so eager to make others happy, they sometimes rush to accommodate without pushing or properly advocating for their own ideas.',
	studentBriefPoints: [
		'People with this pairing get joy and energy creating new solutions that perfectly fit the needs of others.',
		'They’ve got an amazing skill of blending their passion for inventing with the ability to tune in to what people really want.',
		'They are creative, flexible, and open to feedback.'
	],
	crave: [craves.freedom, craves.appreciation],
	crush: [crushes.constraint, crushes.being_overlooked],
};

const IT = {
	sort: 11,
	title: 'The Methodical Architect',
	brief: 'A precise, reliable, and planful solver of problems. A unique combination of innovative thinking and practical implementation.',
	description:
		'People with this pairing get real joy and energy from designing practical solutions to problems that are based on ingenuity, rationale, and precision.  They work in a steady and reliable way when generating new ideas, and are not easily distracted by possibilities that might prove logistically unfeasible.  In their dogged pursuit of a workable solution, they can sometimes miss the nuance of a given situation or overlook human needs.',
	studentBrief:
		'Innovative and practical. Enjoys designing and implementing specific solutions to problems. Places high value on detail and quality.',
	studentDescription:
		'You are innovative and practical. You enjoy designing and implementing specific solutions to problems. You place high value on detail and quality.',
	studentPotentialChallenges: 'In their quest for a viable solution, they might occasionally miss the subtleties or nuances of a situation or overlook the needs of others.',
	studentBriefPoints: [
		'People with this pairing get joy and energy when they’re crafting practical solutions based on ingenuity, reason, and precision.',
		'They work in a steady and reliable way when generating new ideas.',
		'They’re focused on ensuring that a plan or project is logistically feasible.'
	],
	crave: [craves.freedom, craves.clarity],
	crush: [crushes.constraint, crushes.ambiguity],
};

const DG = {
	sort: 14,
	title: 'The Intuitive Activator',
	brief: 'An instinctive and confident decision-maker. Able to assess situations quickly and accurately, and marshal people to act.',
	description:
		'People with this pairing get real joy and energy from instinctually evaluating ideas and situations, and quickly identifying the right course of action to take.  They generally make quick and surprisingly accurate assessments, often without specific knowledge or data, and artfully rally others to agree with them and their bias for action.  Because of their skills and confidence, they can sometimes be cavalier in their declarations and may appear impatient or dismissive when made to consider critical details required for implementation.',
	studentBrief:
		'Decisive and confident. Enjoys evaluating situations, identifying solutions, and motivating people to act. Instinctively rallies others.',
	studentDescription:
		'You are decisive and confident. You enjoy evaluating situations, identifying solutions, and motivating people to act. You instinctively rally others.',
	studentPotentialChallenges: 'Because they’re so confident and ready to charge ahead, they can be overly bold in their declarations and may seem impatient or dismissive when it comes to figuring out the details required for implementation.',
	studentBriefPoints: [
		'People with this pairing get joy and energy from trusting their instincts as they evaluate ideas and situations.\n',
		'They’re natural decision-makers, quickly figuring out the right course of action.',
		'They make fast and surprisingly accurate assessments, and they’re good at rallying others to get on board with their bias for action.'
	],
	crave: [craves.trust, craves.reaction],
	crush: [crushes.prove_it, crushes.apathy],
};

const DE = {
	sort: 3,
	title: 'The Insightful Collaborator',
	brief: 'An intuitive, empathic advisor and team player. Selfless and compassionate in providing others with what they need in the right way.',
	description:
		'People with this pairing derive real joy and energy from providing others with astute and empathic assistance, based on their intuition and instinct.  They are quintessential team players, bringing together exceptional, timely and shrewd counsel, in a selfless manner.  They sometimes hesitate to push people to heed their advice, and can be deferential to others when they sense resistance.  These tendencies can often leave them feeling underappreciated.',
	studentBrief:
		'Perceptive and helpful. Enjoys evaluating situations and sharing insights. Uses intuition to provide others with sound advice and assistance in just the right way.',
	studentDescription:
		'You are perceptive and helpful. You enjoy evaluating situations and sharing insights. You use intuition to provide others with sound advice and assistance in just the right way.',
	studentPotentialChallenges: 'Because they are so considerate, they might be hesitant and deferential to others when they sense resistance around their insights.',
	studentBriefPoints: [
		'People with this pairing get joy and energy in offering others perceptive and empathetic help based on their instincts and intuition.',
		'They’re always ready with exceptional and timely advice in a selfless manner.',
		'They are valuable team players.'
	],
	crave: [craves.trust, craves.appreciation],
	crush: [crushes.prove_it, crushes.being_overlooked],
};

const DT = {
	sort: 6,
	title: 'The Judicious Accomplisher',
	brief: 'A reliable, prudent, and focused doer. A unique combination of practical urgency and intuitive judgment. ',
	description:
		'People with this pairing derive real joy and energy from getting the right things done efficiently and effectively, and using their judgement to avoid wasting time and resources.  They get a lot done by identifying the highest priorities and holding themselves to high standards of execution.  They are prudent, focused, and diligent.  They often feel unnecessary angst about the possibility of failing or when working with others who do not share their high standards in driving for closure.',
	studentBrief:
		'Sensible and disciplined. Knows exactly what needs to be done and is determined to follow through. Possesses a unique combination of intuition and urgency.',
	studentDescription:
		'You are sensible and disciplined. You know exactly what needs to be done and determined to follow through. You possess a unique combination of intuition and urgency.',
	studentPotentialChallenges: 'Because they’re committed to excellence, they might sometimes feel a bit anxious about the possibility of failure, especially when working with others who may not share their high standards.',
	studentBriefPoints: [
		'People with this pairing get joy and energy in getting the right things done efficiently and effectively.',
		'They place high value on productivity and are adept at identifying top priorities.',
		'They are all about being focused and diligent, holding themselves to high standards of execution.'
	],
	crave: [craves.trust, craves.clarity],
	crush: [crushes.prove_it, crushes.ambiguity],
};

const GE = {
	sort: 9,
	title: 'The Enthusiastic Encourager',
	brief: 'A warm and positive affirmer of others. Quick to support and inspire people who need energy or reassurance.',
	description:
		'People with this pairing derive joy and energy from inspiring and supporting others to grow, improve and feel good about themselves.  They are positive, exuberant, and optimistic, gladly demonstrating their excitement about the potential of others.  They are consummate cheerleaders, in the truest sense of the word.  In their desire to be positive, they can sometimes be too quick to affirm others or provide unwarranted praise, leaving them to be potentially dismissed or perceived as naïve or insincere.',
	studentBrief:
		'Energetic and supportive. Enjoys motivating and affirming others. Eager to provide inspiration or reassurance where needed.',
	studentDescription:
		'You are energetic and supportive. You enjoy motivating and affirming others. You are eager to provide inspiration and reassurance where needed.',
	studentPotentialChallenges: 'Because they’re so keen on being positive, they might sometimes be a bit quick to affirm or offer unwarranted praise, which could lead others to dismiss them as overly optimistic or insincere.',
	studentBriefPoints: [
		'People with this pairing get joy and energy by inspiring and supporting others to grow, improve, and feel good about themselves.',
		'They’re exceedingly positive and exuberant.',
		'They are optimistic “cheerleaders” who enjoy spreading enthusiasm.'
	],
	crave: [craves.reaction, craves.appreciation],
	crush: [crushes.apathy, crushes.being_overlooked],
};

const GT = {
	sort: 12,
	title: 'The Assertive Driver',
	brief: 'A taskmaster extraordinaire. Willing to push and remind others, and dive in themselves, to ensure that things get done.',
	description:
		'People with this pairing derive joy and energy from getting things done, as well as rallying others to get things done with them.  They don’t mind blowing through obstacles and are even comfortable ignoring social protocols from time to time when it is required to push something through to completion.  They are people of high conviction and standards.  Understandably, they can sometimes be seen as pushy, and are often impatient with too much planning and brainstorming, preferring to get right to work.',
	studentBrief:
		'Disciplined and bold. Confidently rallies others to take action. Derives energy from eliminating obstacles and getting things done.',
	studentDescription:
		'You are disciplined and bold. You confidently rally others to take action. You derive energy from eliminating obstacles and getting things done.',
	studentPotentialChallenges: 'Because they’re so focused on action, they might come off as pushy or uncaring.',
	studentBriefPoints: [
		'People with this pairing get joy and energy in not just getting things done but also rallying others to join them.\n',
		'They are go-getters who don’t mind bulldozing through obstacles.',
		'They are fine with bending social rules if it means pushing projects through to completion.'
	],
	crave: [craves.reaction, craves.clarity],
	crush: [crushes.apathy, crushes.ambiguity],
};

const ET = {
	sort: 15,
	title: 'The Loyal Finisher',
	brief: 'A responsive, dependable doer. Willing to step up when something is needed and determined to fulfill their commitments.',
	description:
		'People with this pairing derive joy and energy from completing tasks and responding to the needs of others in the pursuit of getting things done.  They are quick to answer the call to action, equally valuing responsiveness and reliability.  They are low-maintenance contributors who hold themselves accountable for delivering on their promises.  They have a hard time saying ‘no’ to requests and find themselves over-committed and prone to burnout and lack of appreciation.',
	studentBrief:
		'Responsive and dependable. Enjoys helping others and getting things done. Quick to volunteer and determined to fulfill commitments and achieve goals.',
	studentDescription:
		'You are responsive and dependable. You enjoy helping others and getting things done. You are quick to volunteer and determined to fulfill commitments and achieve goals.',
	studentPotentialChallenges: 'Because they’re so responsible and eager to help, they might find it hard to say ‘no’ to requests. This can sometimes lead them to be over-committed, and prone to burnout or feeling underappreciated.',
	studentBriefPoints: [
		'People with this pairing get joy and energy by checking off tasks and responding to the needs of others.',
		'They are quick to help, and they value being reliable and responsive.',
		'They are low-maintenance contributors who always hold themselves accountable for delivering on their promises.'
	],
	crave: [craves.appreciation, craves.clarity],
	crush: [crushes.being_overlooked, crushes.ambiguity],
};

const getUniquePairing = (a, b) => {
	const sortWord = 'WIDGET';
	const first_letter = a[0];
	const second_letter = b[0];
	const first_idx = sortWord.indexOf(first_letter);
	const second_idx = sortWord.indexOf(second_letter);
	return first_idx < second_idx ? `${first_letter}${second_letter}` : `${second_letter}${first_letter}`;
};

const ReportService = {
	getUniquePairing,
	genius_color: '#00a049',
	competency_color: '#fbc331',
	frustration_color: '#c06e60',
	Results: {
		Genius: (a, b, color) =>
			`Your areas of Working Genius are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Competency: (a, b, color) =>
			`Your areas of Working Competency are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Frustration: (a, b, color) =>
			`Your areas of Working Frustration are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Wonder: {
			genius: `You are naturally gifted at and derive energy and joy from pondering the possibility of greater potential and opportunity in a given situation.`,
			competency: `You are capable of and don’t mind pondering the possibility of greater potential and opportunity in a given situation.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from pondering the possibility of greater potential and opportunity in a given situation.`,
		},
		Invention: {
			genius: `You are naturally gifted at and derive energy and joy from creating original and novel ideas and solutions.`,
			competency: `You are capable of and don’t mind creating original and novel ideas and solutions.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from creating original and novel ideas and solutions.`,
		},
		Discernment: {
			genius: `You are naturally gifted at and derive energy and joy from using your intuition and instincts to evaluate and assess ideas or plans.`,
			competency: `You are capable of and don’t mind using your intuition and instincts to evaluate and assess ideas or plans.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from using your intuition and instincts to evaluate and assess ideas or plans.`,
		},
		Galvanizing: {
			genius: `You are naturally gifted at and derive energy and joy from rallying people and inspiring them to take action around ideas, projects, or tasks.`,
			competency: `You are capable of and don’t mind rallying people and inspiring them to take action around ideas, projects, or tasks.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from rallying people and inspiring them to take action around ideas, projects, or tasks.`,
		},
		Enablement: {
			genius: `You are naturally gifted at and derive energy and joy from providing others with encouragement and assistance for projects and tasks.`,
			competency: `You are capable of and don’t mind providing others with encouragement and assistance for projects and tasks.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from providing others with encouragement and assistance for projects and tasks.`,
		},
		Tenacity: {
			genius: `You are naturally gifted at and derive energy and joy from pushing projects and tasks through to completion to ensure that the desired results are achieved.`,
			competency: `You are capable of and don’t mind pushing projects and tasks through to completion to ensure that the desired results are achieved.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from pushing projects and tasks through to completion to ensure that the desired results are achieved.`,
		},
	},
	StudentResults: {
		Genius: (a, b, color) =>
			`Your areas of Working Genius are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Competency: (a, b, color) =>
			`Your areas of Working Competency are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Frustration: (a, b, color) =>
			`Your areas of Working Frustration are <span class="font-semibold" style="color: ${color}">${a}</span> and <span class="font-semibold" style="color: ${color}">${b}</span>.`,
		Wonder: {
			genius: `You are naturally gifted at and derive energy and joy from pondering the possibility of greater potential and opportunity in a given situation.`,
			competency: `You are capable of and don’t mind pondering the possibility of greater potential and opportunity in a given situation.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from pondering the possibility of greater potential and opportunity in a given situation.`,
		},
		Invention: {
			genius: `You are naturally gifted at and derive energy and joy from creating original ideas and solutions.`,
			competency: `You are capable of and don’t mind creating original ideas and solutions.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from creating original ideas and solutions.`,
		},
		Discernment: {
			genius: `You are naturally gifted at and derive energy and joy from using your intuition and instincts to evaluate ideas or plans.`,
			competency: `You are capable of and don’t mind using your intuition and instincts to evaluate ideas or plans.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from using your intuition and instincts to evaluate ideas or plans.`,
		},
		Galvanizing: {
			genius: `You are naturally gifted at and derive energy and joy from rallying people and inspiring them to take action around ideas or projects.`,
			competency: `You are capable of and don’t mind rallying people and inspiring them to take action around ideas or projects.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from rallying people and inspiring them to take action around ideas or projects.`,
		},
		Enablement: {
			genius: `You are naturally gifted at and derive energy and joy from providing others with encouragement and assistance for projects and tasks.`,
			competency: `You are capable of and don’t mind providing others with encouragement and assistance for projects and tasks.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from providing others with encouragement and assistance for projects and tasks.`,
		},
		Tenacity: {
			genius: `You are naturally gifted at and derive energy and joy from pushing projects and tasks through to completion.`,
			competency: `You are capable of and don’t mind pushing projects and tasks through to completion.`,
			frustration: `You aren’t naturally gifted at and don’t derive energy and joy from pushing projects and tasks through to completion.`,
		},
	},
	Geniuses: {
		genius: {
			description: (a, b, color) =>
				`According to your Assessment, you have the Genius of <span class="font-bold uppercase" style="color: ${color}">${a}</span> and <span class="font-bold uppercase" style="color: ${color}">${b}</span>, which are described below. These are the activities that give you joy, energy, and passion. As a result, you are quite skilled in these areas.`,
			note: ``,
		},
		competency: {
			description: (a, b, color) =>
				`According to your assessment, your areas of competency are <span class="font-bold uppercase" style="color: ${color}">${a}</span> and <span class="font-bold uppercase" style="color: ${color}">${b}</span>, which are described below. These are the activities that you find neither completely miserable nor completely joyful. It’s important that you understand your competencies, because most people can operate in these areas fairly well for a while. However, you will eventually grow weary if you don't have the opportunity to exercise your true geniuses.`,
			note: `Some people become quite good at activities that fall within their areas of Working Competency, either because career or life circumstances forced them to do so, or because they have a strong drive to excel at almost anything they do. However, those people will not derive the same kind of joy and energy from—and are not as naturally gifted in—those activities compared to their areas of Working Genius. Moreover, spending too much time working in one’s areas of competency can eventually lead to exasperation and burnout.`,
		},
		frustration: {
			description: (a, b, color) =>
				`According to your assessment, your areas of frustration are <span class="font-bold uppercase" style="color: ${color}">${a}</span> and <span class="font-bold uppercase" style="color: ${color}">${b}</span> which are described below. It’s particularly important to know your frustrations for two reasons. First, you will want to avoid spending too much time doing activities in these areas because this usually leads to weariness and dissatisfaction. Second, you will want to avoid feeling unnecessarily shameful or guilty about not being naturally gifted in these areas.`,
			note: `Keep in mind that some people can become adept at activities that fall within their areas of Working Frustration, either because career or life circumstances have forced them to do so, or because they have a boundless desire to excel at almost anything they do. However, those people do not derive real joy and energy from—and are not as naturally gifted in—those activities compared to their areas of Working Genius. Moreover, spending any <span class="italic">significant</span> amount of time in one’s areas of frustration will lead to exasperation and burnout.`,
		},
		Wonder: `The Genius of Wonder involves pondering and asking questions, contemplating the reasons why things are the way they are.  People with this genius derive joy and energy from thoughtfully observing the environment around them and wondering whether there might be a different or better way.  Compared to other geniuses, Wonder is not the most observable genius, because it is a mostly internal process.  However, almost every new initiative, program, or project begins because someone wonders.  The benefits of this genius include asking big questions, prompting people to consider assumptions that may need to be questioned, and challenging the status quo.`,
		Invention: `The Genius of Invention is about coming up with novel ideas and solutions to solve problems and address issues.  Invention involves creativity and original thinking, often with little direction.  People with this genius are confident and inspired by a problem that has no apparent solution, and they derive energy and joy from getting the opportunity to take a first crack at coming up with a new idea.  The benefits of this genius are generally well-understood in society, and include anything related to innovation, novel thinking, and original problem-solving. `,
		Discernment: `The Genius of Discernment involves making sound judgments relying on instinct and intuition across a wide variety of situations.  It entails pattern recognition and integrative thinking, rather than expertise, knowledge, or data.  As such, people with Discernment have a knack for and derive energy and joy from evaluating whether or not an idea is sound, or if it requires further tweaking and adjustments. The benefits of Discernment are many, even if they are somewhat difficult to pinpoint as a result of the intuitive nature of the gift.  They include curating, evaluating, and identifying the best ideas, and avoiding the ones that aren’t good, or refining those that aren’t yet ready.`,
		Galvanizing: `The Genius of Galvanizing is about rallying and motivating people, often around projects, ideas, or initiatives.  People with the gift of Galvanizing derive joy and energy from inspiring and persuading others to take action to get things moving, even if it involves convincing them to rethink or change their plans.  Galvanizing is relatively easy to identify as it is an observable and often public activity. The benefits of this genius include nurturing people’s excitement about potential by building energy and momentum around a program, or idea.`,
		Enablement: `The Genius of Enablement involves answering the call to action and seamlessly providing others with the unconditional support and assistance they need. People with this genius get energy and joy from providing others with the support they need to get something started, and by providing the human glue required to hold it together.  Even though people with Enablement are often ideal team members, they sometimes discount the importance of their genius, mistakenly believing that they are simply amenable or “nice.” The benefits of this genius include higher morale and greater interpersonal appreciation and support.`,
		Tenacity: `The Genius of Tenacity is about execution.  It involves getting things done, achieving results, and realizing the desired impact of a task or project. People with Tenacity derive real joy and energy from crossing tasks off a list and knowing that they met the standards for completion.  They are also comfortable pushing through obstacles. The benefits of this genius revolve around making sure that goals are met and projects are completed on time, even when inevitable problems surface.`,
	},
	UniqueParings: {
		WI,
		WD,
		WG,
		WE,
		WT,
		ID,
		IG,
		IE,
		IT,
		DG,
		DE,
		DT,
		GE,
		GT,
		ET,
	},
};

export default ReportService;
