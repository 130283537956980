import React, { useState } from 'react';
import AppButton from '../AppButton';
import RegisterForm from './RegisterForm';
import { widget_hr, live_webinar } from '../../images';

const WebinarHero = () => {
	const [openRegisterModal, setOpenRegisterModal] = useState(false);

	return (
		<div className='webinar-hero-section'>
			<div className='container'>
				<div className='webinar-container'>
					<img className={'live-webinar-badge'} src={live_webinar} alt='' width='200' />
					<h1 className='text-2xl lg:text-4xl text-white font-bold my-4'>
						Teamwork Simplified <br />
						with Patrick Lencioni
					</h1>
					<p className='text-white text-lg font-semibold tracking-widest'>January 25th at 11AM PT / 2PM ET</p>
					<div className='flex flex-col justify-center my-8'>
						<AppButton
							className={'btn btn-primary w-56'}
							onClick={() => setOpenRegisterModal(true)}
							text={'Register now'}
							id={'wg-launch-event-register-now-btn'}
						/>
					</div>
					<h1 className='text-2xl lg:text-3xl lg:w-1/2 xl:w-2/5 text-white font-medium'>
						For Certified Working Genius Facilitators and their Guests
					</h1>
					<img className={'w-1/3 my-6'} src={widget_hr} alt='' />
					<h3 className='text-white text-lg lg:text-xl font-thin my-6'>
						This is perfect for leaders looking to:
					</h3>
					<div className='webinar-list'>
						<span>Re-engage their team in 2023</span>
						<span>Address inefficiencies in meetings</span>
						<span>Increase productivity and team cohesion</span>
					</div>
				</div>
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default WebinarHero;
