import React from 'react';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import WebinarHero from '../../components/exclusivewebinar/WebinarHero';
import WebinarPat from '../../components/exclusivewebinar/WebinarPat';
import LaunchEventTypes from '../../components/launchevent/LaunchEventTypes';
import WebinarBook from '../../components/exclusivewebinar/WebinarBook';

const Event = () => {
	return (
		<WorkshopLayout
			title={'The 6 Types of Working Genius: Exclusive Webinar'}
			workshopItem={false}
		>
			<WebinarHero />
			<WebinarPat />
			<WebinarBook />
			<LaunchEventTypes />
		</WorkshopLayout>
	);
};

export default Event;
