import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { isNull, isEmpty } from 'lodash';

import AppButton from '../AppButton';
import AppSlideOver from '../AppSlideOver';
import AppInput from '../AppInput';
import AppTextarea from '../AppTextarea';
import AppRIIcon from '../AppRIIcon';
import AssessmentsService from '../../services/AssessmentsService';
import AppSwitch from '../AppSwitch';
import CompanyLogoPreviewModal from './CompanyLogoPreviewModal';
import AppFileUploader from '../AppFileUploader';
import AppLabel from '../AppLabel';
import NotificationService from '../../services/NotificationService';
import config from '../../constants/config';
import MultipleEmailInput from './MultipleEmailInput';
import UtilityService from '../../services/UtilityService';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';
import { useUser } from '../../hooks/UserProvider';
import RosterService from '../../services/RosterService';

const EditAssessmentModal = ({ isOpen, assessment, closeModal, roster, onRosterUpdate}) => {
	const { user } = useUser();
	const [loading, setLoading] = React.useState(false);
	const [previewCompanyLogo, setPreviewCompanyLogo] = React.useState(false);
	const [companyLogo, setCompanyLogo] = React.useState(null);
	const [existingCompanyLogo, setExistingCompanyLogo] = React.useState(assessment.company_logo_url);
	const [formValues, setFormValues] = React.useState({
		group: assessment.group ?? '',
		company: assessment.company ?? '',
		fname: assessment.fname ?? '',
		lname: assessment.lname ?? '',
		welcome: assessment.welcome ?? '',
		send: assessment.send === 0,
		multi_language: !!assessment.multi_language,
		notify: !!assessment.notify,
		form_company_logo: assessment.company_logo_url ?? null,
		cc_emails: assessment.cc_emails
			? assessment.cc_emails?.split(',').map((val) => ({
				label: val,
				value: val,
				isValidEmail: UtilityService.isValidEmail(val),
				fakeId: UtilityService.generateRandomString(30),
			}))
			: [],
	});

	const certified = user.wg_certification_status?.status_id > 0;
	const isStudent = assessment.assessment_mode === AssessmentModeEnum.STUDENT;
	const multipleRef = React.useRef();

	const {
		getValues,
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
		clearErrors,
		setError,
		watch,
		control,
	} = useForm({
		defaultValues: formValues,
	});

	React.useEffect(() => {
		if (isOpen) {
			reset(formValues);
			setExistingCompanyLogo(assessment.company_logo_url);
			clearErrors();
			setValue('fname', assessment.fname);
			setValue('lname', assessment.lname);
		}
	}, [isOpen]);

	React.useEffect(() => {
		if (!getValues('notify')) {
			setValue('cc_emails', []);
		} else {
			setValue('cc_emails', formValues.cc_emails);
		}
	}, [getValues('notify')]);

	const { isSubmitted, dirtyFields } = useFormState({ control });

	const handleUpdate = (payload) => {
		AssessmentsService.update(assessment.id, payload)
			.then(() => {
				let company_logo_url = null;
				AssessmentsService.details(assessment.id)
					.then((response) => {
						company_logo_url = response.company_logo_url;
						if (!isNull(response.company_logo)) {
							setExistingCompanyLogo(response.company_logo_url);
							setValue('form_company_logo', response.company_logo_url);
						}
					})
					.finally(() => {
						closeModal({
							saved: true,
							assessment: {
								...assessment,
								...payload,
								send: payload.send ? 0 : 1,
								company_logo_url,
							},
						});
					});
				if(roster){
					RosterService.changeName({
						assessment_id: assessment.id,
						roster_id: roster.id,
						first_name: payload.fname,
						last_name: payload.lname,
					}).then(() => {
						onRosterUpdate(payload.fname, payload.lname);
					})
				}
				setFormValues(getValues());
			})
			.then(() => {
				NotificationService.success('Assessment details successfully updated');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const save = (payload) => {
		setLoading(true);

		if (certified && companyLogo) {
			AssessmentsService.getCompanyLogoSignedUrl(assessment.slug, companyLogo.name)
				.then(({ key, url }) => {
					return AssessmentsService.uploadCompanyLogo(key, url, companyLogo);
				})
				.then(({ key }) => {
					handleUpdate({
						...payload,
						company_logo: key,
						cc_emails: payload.cc_emails?.map(({ value }) => value).join(','),
					});
				})
				.catch(() => {
					NotificationService.error('Notice', 'Company Logo could not be uploaded. Please try again!');
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			const newCompanyLogo = assessment.company_logo && existingCompanyLogo ? assessment.company_logo : null;
			handleUpdate({
				...payload,
				company_logo: newCompanyLogo,
				cc_emails: payload.cc_emails?.map(({ value }) => value).join(','),
			});
		}
	};

	const existingCompanyLogoPreview = () => {
		return (
			<div
				key={existingCompanyLogo}
				className='relative flex items-center justify-between p-4 border-gray-400 border-1'
			>
				<img src={existingCompanyLogo} alt='' style={{ width: 'auto', maxHeight: '48px' }} />
				<AppRIIcon
					icon={'close-fill'}
					className={'text-grey-600 text-2xl cursor-pointer mx-2'}
					onClick={() => {
						setExistingCompanyLogo(null);
						setCompanyLogo(null);
						setValue('form_company_logo', null, { shouldDirty: true });
					}}
				/>
			</div>
		);
	};

	return (
		<AppSlideOver id={'app-confirm-close-modal-id'} isOpen={isOpen} closeModal={closeModal}>
			<div className={`bg-white py-4 px-8 ${config.name}`}>
				<h1 className={'flex flex-1 text-xl text-center text-primary font-medium mb-4'}>Edit Assessment</h1>
				<form onSubmit={handleSubmit(save)}>
					{assessment.type === 'group' && (
						<AppInput
							{...register('group', {
								required: true,
							})}
							errors={errors}
							label={'Group Name *'}
						/>
					)}

					{assessment.type === 'group' && (
						<AppInput
							{...register('company', {
								required: !isStudent,
							})}
							errors={errors}
							label={isStudent ? 'School Name' : 'Company Name *'}
						/>
					)}

					{assessment.type !== 'group' && (
						<AppInput
							{...register('fname', {
								required: true,
							})}
							errors={errors}
							label={'First Name *'}
						/>
					)}

					{assessment.type !== 'group' && (
						<AppInput
							{...register('lname', {
								required: true,
							})}
							errors={errors}
							label={'Last Name *'}
						/>
					)}

					{assessment.type !== 'group' && (
						<AppInput
							{...register('company')}
							errors={errors}
							label={`${isStudent ? 'School' : 'Company'} Name`}
						/>
					)}

					{certified && (
						<div className='flex flex-col mb-4'>
							<AppLabel
								label='Company Logo'
								labelTooltip={`The company logo will appear on the PDF report of each team member taking this assessment. Upon uploading your company logo click "Preview" under the upload box in order to view the logo on the assessment report before finishing assessment set up.`}
							/>
							{existingCompanyLogo ? (
								existingCompanyLogoPreview()
							) : (
								<AppFileUploader
									onDone={(file) => {
										setCompanyLogo(file);
										setValue('form_company_logo', URL.createObjectURL(file), { shouldDirty: true });
									}}
									onRemove={() => {
										setCompanyLogo(null);
										setValue('form_company_logo', null, { shouldDirty: true });
									}}
								/>
							)}

							{(companyLogo || existingCompanyLogo) && (
								<div className='flex flex-col'>
									<CompanyLogoPreviewModal
										isOpen={previewCompanyLogo}
										closeModal={() => setPreviewCompanyLogo(false)}
										srcUrl={existingCompanyLogo}
										srcFile={companyLogo}
									/>
									<span
										className='text-primary text-sm font-semibold cursor-pointer p-1 mt-2 hover:bg-blue-100'
										style={{
											width: 'max-content',
										}}
										onClick={() => setPreviewCompanyLogo(true)}
									>
										Preview
									</span>
								</div>
							)}
						</div>
					)}

					<AppTextarea
						{...register('welcome')}
						errors={errors}
						label={'Welcome message'}
						labelTooltip={'This message will display on the initial assessment page'}
					/>

					<div className='flex flex-col '>
						<p className='text-lg text-primary font-medium mt-4 mb-6'>Options for group / team setup</p>
						<div className='flex flex-1 flex-col'>
							<div
								className='flex flex-row mt-3'
								id={'wg-create-assessment-no-automatic-reports-container'}
							>
								<AppSwitch
									errors={errors}
									value={watch('send')}
									onChange={(val) => {
										setValue('send', val, {
											shouldDirty: true,
										});
									}}
									id={'wg-account-language-selection'}
								/>
								<div className='ml-2'>
									<p className={'text-primary font-semibold'}>
										Do not automatically send reports to participants.
									</p>
								</div>
							</div>

							<div
								className='flex flex-col mt-5'
								id={'wg-create-assessment-notify-on-completion-container'}
							>
								<AppSwitch
									label={
										<p className='text-primary font-semibold'>
											Notify me as participants complete the assessment
										</p>
									}
									errors={errors}
									value={watch('notify')}
									onChange={(val) => {
										setValue('notify', val, {
											shouldDirty: true,
										});
									}}
									id={'wg-create-assessment-notify-on-completion'}
								/>

								{getValues('notify') && (
									<>
										<p className='text-xs leading-4 font-normal text-gray-600 mt-5'>
											As each team member completes the assessment, an email will be sent to the
											main contact and any specified additional contacts. This e-mail will provide
											a summary of which team members have completed the assessment. Enter up to 3
											email address of the people who should receive status updates.
										</p>
										<div className='short-form short-form-full'>
											<MultipleEmailInput
												name={'cc_emails'}
												ref={multipleRef}
												label={
													<p className='text-sm leading-5 font-normal text-gray-800 mb-1 mt-5'>
														Additional Contacts (Optional)
													</p>
												}
												onChange={(members) => {
													setValue('cc_emails', members, { shouldDirty: true });
												}}
												values={watch('cc_emails')}
												placeholder={
													<p className='text-sm leading-5 font-normal text-gray-500'>
														Enter emails separated by a comma “,”
													</p>
												}
												errors={errors}
												setError={setError}
												clearErrors={clearErrors}
												isSubmitted={!!isSubmitted}
												min={0}
												limit={3}
											/>
										</div>
									</>
								)}
							</div>
						</div>
						<hr className='slide-over-break' />
						<div className='flex justify-center my-4'>
							<AppButton
								loading={loading}
								type={'submit'}
								className={'btn btn-primary w-full mr-4'}
								text={'Save'}
								disabled={isEmpty(dirtyFields)}
								id={'wg-edit-assessment-save-btn'}
							/>
							<AppButton
								type={'button'}
								className={'btn btn-secondary'}
								onClick={closeModal}
								disabled={loading}
								text={'Cancel'}
								id={'wg-edit-assessment-cancel-btn'}
							/>
						</div>
					</div>
				</form>
			</div>
		</AppSlideOver>
	);
};

export default EditAssessmentModal;
