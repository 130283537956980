import React from 'react';

import {useUser} from "../hooks/UserProvider";

export const AppLogout = ({ className, label }) => {
	const {logout} = useUser();

	return (
		<span className={className} onClick={logout}>
			{label}
		</span>
	);
};
