import React, { useState } from 'react';
import AppButton from '../AppButton';
import RegisterForm from './RegisterForm';
import { widget_hr_primary, live_webinar, meetings_event_heading_white, ttg_wg_logos } from '../../images';

const EventHero = () => {
    const [openRegisterModal, setOpenRegisterModal] = useState(false);

    return (
        <div className='at-the-whiteboard-meetings-hero-section'>
            <div className='container'>
                <div className='webinar-container'>
                    <div className='relative flex flex-col items-center'>
                        <img className={'live-webinar-badge'} src={live_webinar} alt='' width='180' />
                        <img className={'relative p-4'} src={meetings_event_heading_white} alt='' width='580' />
                    </div>
                    <div className='flex flex-col justify-center items-center my-8 lg:mb-44'>
                        <p className='text-white text-xl font-normal tracking-wide mb-4'>
                            <span className='font-semibold block'>November 8th at 11:00AM Pacific Time</span>
                            A Free, Virtual Event
                        </p>
                        <AppButton
                            className={'btn btn-primary w-56'}
                            onClick={() => setOpenRegisterModal(true)}
                            text={'Register now'}
                            id={'wg-launch-event-register-now-btn'}
                        />
                    </div>
                    <div className='bg-white rounded-lg w-4/5 mx-auto flex flex-col items-center py-12 px-6'>
                        <img className={'relative pb-6'} src={ttg_wg_logos} alt='' width='580' />
                        <h1 className='text-base md:text-2xl leading-8 lg:w-1/2 text-primary font-medium px-4'>
                            Join Patrick Lencioni as he explores the four types of meetings, increasing
                            meeting effectiveness, and how Working Genius can help
                            meetings be less terrible (and more productive!).
                        </h1>
                        <div className='at-the-whiteboard-widget-hr'>
                            <img className={'w-1/2 my-6 mx-auto px-8'} src={widget_hr_primary} alt='' />
                        </div>
                        <h3 className='text-primary text-lg lg:text-xl font-semibold'>
                            This event is perfect for anyone:
                        </h3>
                        <div className='at-the-whiteboard-list'>
                            <span>Leading a meeting</span>
                            <span>Attending a meeting</span>
                            <span>Tired of meetings</span>
                        </div>
                    </div>
                </div>
            </div>
            <RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
        </div>
    );
};

export default EventHero;
