import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import { navigate } from 'gatsby';
import { USER_PERMISSIONS } from '@ttg/app-interfaces-lib';

import { getCustomerData, logout, resetPassword, switchToOriginalUser } from '../services/CustomerService';
import NotificationService from '../services/NotificationService';
import { setAuthCookie } from '../services/UtilityService';
import { useNotifications } from './NotificationProvider';
import AppSwitchingProfile from '../components/AppSwitchingProfile';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const [loading, setLoading] = useState(true);
	const [switching, setSwitching] = useState(false);
	const { loadNotifications } = useNotifications();

	const loadUser = useCallback(() => {
		setLoading(true);

		return getCustomerData()
			.then((customer) => setUser(customer))
			.catch(() => setUser(null))
			.finally(() => {
				setLoading(false);
				setLoaded(true);
			});
	},[]);

	const hasPermission = useCallback((action) => {
		if (!user?.original_customer) {
			return true;
		}

		const permissions = USER_PERMISSIONS[user?.original_customer?.permission_role];

		return permissions[action];
	}, [user]);

	const _logout = async () => {
		setUser(null);
		setLoading(true);
		setLoaded(false);

		navigate(`/client/login?logout=true`);

		logout(user?.id)
			.then(() => {
				setLoading(false);
				setAuthCookie('');
			})
			.finally(() => setLoading(false));
	};

	const _resetPassword = (token, password, new_password) =>
		resetPassword({ token, password, new_password }).then((u) => setUser(u));

	const _switchToOriginalUser = () => {
		setSwitching(true);

		switchToOriginalUser()
			.then(() => {
				loadUser();
				loadNotifications();
				navigate(`/client`);
			})
			.catch((error) => {
				console.error('Error switching user', error);
				NotificationService.error('Oops', 'Something went wrong, please try again');
			})
			.finally(() => setSwitching(false));
	};

	useEffect(() => {
		loadUser();
	}, [loadUser]);

	//To refresh customer data on tab focus
	//Might have been updated on other tabs
	useEffect(() => {
		function onVisibilityChange() {
			if (document.visibilityState === 'visible' && window.location.pathname !== '/checkout-view') {
				loadUser();
				loadNotifications();
			}
		}
		window.addEventListener('visibilitychange', onVisibilityChange);
		return () => window.removeEventListener('visibilitychange', onVisibilityChange);
	}, [user, loadNotifications, loadUser]);

	return (
		<UserContext.Provider
			value={{
				user,
				loadUser,
				setUser,
				loading,
				hasPermission,
				logout: _logout,
				resetPassword: _resetPassword,
				switchToOriginalUser: _switchToOriginalUser,
				loaded,
			}}
		>
			{!loaded && loading ? (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			) : (
				<>
					{children}
					{switching && <AppSwitchingProfile />}
				</>
			)}
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);

export const UserContextLayout = ({ children }) => <UserProvider>{children}</UserProvider>;
