import React from 'react';
import { ttg_logo_white, widget_model } from '../../images';

const WorkingGeniusTypes = () => {
	return (
		<div className='wg-types-section bg-workinggenius pt-12 pb-8'>
			<div className='container mx-auto'>
				<h3 className='text-white text-center font-semibold homepage-heading'>The 6 Types of Working Genius</h3>
				<div className='widget-model-container mx-auto my-12'>
					<img className='w-11/12 md:w-9/12 mx-auto' src={widget_model} alt='Working Geniuses Primary Logo' />
				</div>
				<div className='ttg-logo-white-container flex justify-start mt-4 mb-10'>
					<img className='ttg-logo-white' src={ttg_logo_white} alt='Working Geniuses Primary Logo' />
				</div>
			</div>
		</div>
	);
};

export default WorkingGeniusTypes;
