import React from 'react';

const LiveAfterSubmit = () => {
	return (
		<div className='container mx-auto'>
			<div className='my-8 mx-auto' style={{ position: 'relative', padding: '56.25% 0 0 0', width: '80%' }}>
				<iframe
					id='liverecording'
					tyle='display: block; opacity: 1;'
					src='https://player.vimeo.com/video/474014021?h=c1a7630f36&amp;'
					frameBorder='0'
					allow='autoplay; fullscreen'
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
					allowFullScreen=''
				/>
			</div>
		</div>
	);
};

export default LiveAfterSubmit;
