import React, { useState } from 'react';
import AppIcon from '../AppIcon';
import AppButton from '../AppButton';
import AppModal from '../AppModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
    },
};

const RemoveAccessModal = ({ loading, isOpen, onClose, onRemove, title, text, confirmText, cancelText }) => {
    return (
        <AppModal isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
            <div className='bg-white p-6' style={{ maxWidth: '650px' }}>
                <h1 className={'flex flex-1 text-lg text-primary font-medium'}>{title}</h1>
                <div className='absolute right-0 top-0 mt-4 mr-4'>
                    <AppIcon
                        icon={'cancel'}
                        className={'text-primary text-2xl cursor-pointer hover:text-secondary'}
                        onClick={onClose}
                    />
                </div>
                <p className={'text-sm text-primary my-5'} dangerouslySetInnerHTML={{ __html: text }} />
                <div className='flex justify-end'>
                    <AppButton
                        className={'btn btn-secondary mr-2'}
                        text={cancelText}
                        onClick={onClose}
                        disabled={loading}
                    />
                    <AppButton
                        text={confirmText}
                        onClick={onRemove}
                        loading={loading}
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default RemoveAccessModal;
