import React from 'react';
import { report_gear, WidgetReportV2 } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const WhatsNext = () => {
	const renderGear = (text) => {
		return (
			<div className={'flex flex-row my-3'}>
				<img src={report_gear} alt='' className={'w-5 h-5 mr-2'} style={{ filter: 'brightness(0.1)' }} />
				<div>
					<p>{text}</p>
				</div>
			</div>
		);
	};

	return (
		<div className='Application'>
			<PageHeader title={'Application'} />

			<div className={'container mt-12'}>
				<PageTitle title={'What’s Next: Applying Working Genius'} className={'mt-8'} />

				<div className='mr-20'>
					<p className={'mt-4'}>
						It is painful to think that there are many people in the world who are stuck in jobs that don’t
						align with their Working Geniuses, or perhaps even roles that force them to live within their
						frustrations. It’s even worse to know that many of them don’t know why they are miserable.
						Individuals who fully embrace the power of this model can experience greater levels of
						self-awareness, increase their joy, fulfillment, and productivity, and decrease unnecessary
						guilt and judgment.
					</p>

					<p className={'mt-4'}>
						How you use your Working Genius results will depend on where you are in your work and life, and
						why you decided to take the assessment in the first place. Here are some possibilities:
					</p>

					<div className='font-bold uppercase mt-12 mb-2'>WORK FROM YOUR GENIUS</div>
					<div className={'ml-8'}>
						<p>Evaluate your personal and professional life.</p>
						{renderGear(
							'Identify how you can more effectively lean into your Working Geniuses and do less in your Working Frustrations.'
						)}
						{renderGear(
							'Consider how you schedule your work and how you might tackle competency- or frustration-related activities when you have more energy.'
						)}
						{renderGear(
							'Identify and call out when you are doing work outside your geniuses so that you can be aware and intentional about your energy levels.'
						)}
						{renderGear(
							'Lean on those around you for input and ask for help in areas outside your geniuses.'
						)}
					</div>

					<div className='font-bold uppercase mt-12 mb-2'>SHARE YOUR RESULTS WITH OTHERS</div>
					<div className={'ml-8'}>
						<p>
							Imagine how your relationships could improve if you clearly understood one another’s
							geniuses and frustrations.
						</p>
						{renderGear(
							'Start by telling others (family, friends, co-workers, team members) what you discovered about yourself.'
						)}
						{renderGear(
							'Invite them to take the Working Genius Assessment and review your results with one another.'
						)}
						{renderGear(
							'Discuss how you might help one another use your geniuses and avoid your frustrations.'
						)}
						{renderGear('Offer to use your Working Geniuses to help others.')}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WhatsNext;
