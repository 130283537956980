import React from 'react';
import AppButton from '../AppButton';
import FullPagination from './FullPagination';
import AppIcon from '../AppIcon';
import AppCheckBox from '../AppCheckBox';
import AppTooltip from '../AppTooltip';

const AppTable = ({
	title,
	subTitle,
	loading,
	loadingMore,
	data,
	noDataMessage = `We're sorry, there were no results.`,
	columns,
	pagination,
	renderFilters,
	filters,
	setFilters,
	onSort,
	onLoadMore,
	onSearch,
	onReset,
	wrapperClassname,
	selectable = false,
	identifier = 'id',
	renderBulkActions,
	disableSelection,
	actions = [],
	onSearchVisible = true,
	preSelected = [],
}) => {
	const [selected, onSelect] = React.useState(preSelected);

	React.useEffect(() => {
		if (!loading && data) {
			const newIdentifiers = data.map((i) => i[identifier]);
			const newSelected = selected.filter((s) => newIdentifiers.indexOf(s) > -1);
			onSelect(newSelected);
		}
	}, [loading, data]);

	const renderPagination = () => {
		if (!pagination) {
			return null;
		}
		return <FullPagination {...pagination} elements={data ? data.length : 0} />;
	};

	const renderColHeader = (column, item) => {
		if (typeof column.header === 'string') {
			return `${column.header}`;
		}
		return column.header(column, item);
	};

	const renderHeaders = () => {
		return columns.map((col) => {
			return (
				<p
					id={`wg-header-col-${col.key}`}
					key={col.key}
					onClick={() =>
						col.header &&
						col.sortable !== false &&
						onSort(col, filters.sort_dir === 'asc' && col.key === filters.sort_key ? 'desc' : 'asc')
					}
					className={`flex uppercase font-bold text-primary text-sm lg:text-base md:mr-2 ${
						col.className ? col.className : 'flex-1'
					} ${col.sortable !== false ? 'cursor-pointer' : ''}`}
				>
					{renderColHeader(col)}
					{filters && filters.sort_key === col.key && (
						<AppIcon
							style={{ marginTop: '2px' }}
							icon={filters.sort_dir === 'asc' ? 'up-open' : 'down-open'}
						/>
					)}
				</p>
			);
		});
	};

	const renderRow = (item) => {
		return columns.map((column, key) => {
			return (
				<div
					key={key}
					className={`flex py-2 pl-6 md:py-0 md:pl-0 md:mr-2 items-center overflow-ellipsis ${
						column.className ? column.className : 'flex-1'
					}`}
				>
					{column.header && (
						<span className={'md:hidden w-24 uppercase font-semibold text-primary'}>
							{renderColHeader(column, item)}{' '}
						</span>
					)}
					<span>{column.render(item)}</span>
				</div>
			);
		});
	};

	const _onSearch = (e) => {
		e.preventDefault();
		onSearch();
	};

	const renderLoadMore = () => {
		if (pagination && pagination.page + 1 < pagination.totalPages) {
			if (loadingMore) {
				return (
					<div id={'wg-table-loading-more'} className={'flex items-center justify-center my-4 link'}>
						<div
							id={'table-loader'}
							style={{ height: '30px' }}
							className={'flex flex-1 justify-center content-center items-center'}
						>
							<div className='spinner'>
								<div className='double-bounce1' />
								<div className='double-bounce2' />
							</div>
						</div>
					</div>
				);
			}
			return (
				<div className={'flex items-center justify-center my-4 link'}>
					<a
						className={'cursor-pointer font-bold text-primary underline'}
						onClick={() => onLoadMore(pagination.page + 1)}
						id={'wg-load-more-btn'}
					>
						Load More
					</a>
				</div>
			);
		}
		return null;
	};

	const onItemSelect = (id, deselect = false, item) => {
		if (item && disableSelection) {
			if (disableSelection(item)) {
				return;
			}
		}
		let newSelection = [...selected];
		if (id === 'all') {
			if (deselect) {
				newSelection = [];
			} else {
				newSelection = data.filter((r) => !disableSelection(r)).map((r) => r[identifier]);
			}
		} else {
			const idx = newSelection.indexOf(id);
			if (idx > -1) {
				newSelection.splice(idx, 1);
			} else {
				newSelection.push(id);
			}
		}
		onSelect(newSelection);
	};

	return (
		<div className={'m-4 ' + wrapperClassname}>
			{title && (
				<div className={'flex flex-col mb-2'}>
					<h1 id={'wg-table-title'} className={'text-primary text-4xl font-semibold mt-6'}>
						{title}
					</h1>
					{subTitle && (
						<span id={'wg-table-subtitle'} className='text-base leading-6 font-medium text-gray-600 mt-2'>
							{subTitle}
						</span>
					)}
				</div>
			)}

			{!!renderFilters && (
				<form id={'wg-table-form'} onSubmit={_onSearch}>
					<div className='flex flex-col lg:flex-row'>
						<div className='flex flex-1 flex-col md:flex-row items-start md:items-center'>
							<div className='flex flex-1 w-full'>
								{renderFilters({
									filters,
									setFilters: (key, value) => {
										setFilters((d) => ({
											...d,
											[key]: value,
										}));
									},
								})}
							</div>
							<div className='flex mt-4 md:mt-0 h-full'>
								{onReset && (
									<AppButton
										id={'wg-table-reset-button'}
										type={'button'}
										className={'btn btn-secondary w-1/2 md:w-auto '}
										text={'Reset'}
										onClick={onReset}
									/>
								)}
								{onSearchVisible && (
									<AppButton
										id={'wg-table-search-button'}
										type={'submit'}
										text={'Search'}
										className={`btn btn-primary ml-4 w-1/2 md:w-auto`}
									/>
								)}
							</div>
						</div>
						<div className='flex flex-col md:flex-row justify-center'>
							{actions && actions.length > 0 && <div className={'mx-4 empty-space-on-purpose'} />}
							{actions.map((action, index) => (
								<div key={index}>
									<AppTooltip
										content={() => (
											<p className={'text-white text-center font-normal text-tiny'}>
												{action.tooltip}
											</p>
										)}
										type={'small'}
									>
										<AppButton
											id={action.id}
											type={'button'}
											className={
												action.className
													? action.className
													: 'btn btn-primary md:ml-4 mt-4 lg:mt-0 w-full md:w-auto'
											}
											text={action.text}
											onClick={action.onClick}
											style={{ height: '44px' }}
											loading={action.loading}
											disabled={!!action.disabled}
										/>
									</AppTooltip>
								</div>
							))}
						</div>
					</div>
				</form>
			)}
			{renderPagination()}

			{selectable && (
				<div
					className={'md:hidden flex items-center border-2 border-gray-300 px-4 pt-1 mb-4'}
					data-wg='wg-table-select-all-mobile'
				>
					<AppCheckBox
						id={'wg-table-checkbox-all'}
						checked={selected.length === data.length}
						onChange={() => onItemSelect('all', selected.length === data.length)}
						label={'Select all'}
					/>
				</div>
			)}

			{selectable > 0 && (
				<div className={`flex flex-col sm:flex-row ${data.length === 0 ? 'mt-4' : ''}`}>
					<div className={'flex items-center border-2 border-gray-300 px-4 py-1'}>
						<div
							id={'wg-table-checkbox-unselect-all'}
							className={`flex flex-row items-center cursor-pointer ${
								selected.length > 0 ? 'cursor-pointer' : 'cursor-not-allowed'
							}`}
							onClick={() => {
								if (selected.length > 0) {
									onItemSelect('all', true);
								}
							}}
						>
							<p
								className={`flex justify-center items-center w-5 h-5 border-default text-white mr-2 ${
									selected.length > 0 ? 'bg-secondary' : 'bg-gray-300'
								}`}
							>
								{selected.length > 0 ? '-' : ''}
							</p>
							<span>{selected.length} Selected</span>
						</div>
					</div>
					{renderBulkActions && (
						<div className={'mt-4 ml-0 sm:mt-0 sm:ml-4'}>
							{renderBulkActions({ selected, data, identifier })}
						</div>
					)}
				</div>
			)}

			<div className={'hidden md:flex flex-1 border-t-1 md:border-b-1 border-primary py-4 mt-2 app-table-head'}>
				{selectable && data.length > 0 && (
					<div data-wg='wg-table-select-all'>
						<AppCheckBox
							id={'wg-table-checkbox-all'}
							checked={selected.length === data.length}
							onChange={() => onItemSelect('all', selected.length === data.length)}
						/>
					</div>
				)}
				{renderHeaders()}
			</div>

			{loading && (
				<div
					id={'table-loader'}
					style={{ minHeight: '100px' }}
					className={'flex flex-1 justify-center content-center items-center'}
				>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			)}

			<div className={'relative app-table-body'}>
				{!loading &&
					data &&
					data.map((item, key) => {
						return (
							<div
								key={key}
								className={`flex flex-1 flex-col md:flex-row border-1 border-gray-300 md:border-0 md:border-b-1 md:border-primary md:py-4 my-4 md:my-0 md:bg-white ${
									selectable && selected.indexOf(item[identifier]) > -1 ? 'bg-gray-300' : 'bg-white'
								}`}
							>
								{selectable && (
									<div
										key={key}
										className={`flex justify-end p-2 md:p-0 md:mr-2 items-center `}
										data-wg='wg-table-select-row'
									>
										<AppCheckBox
											id={'wg-table-checkbox'}
											checked={selected.indexOf(item[identifier]) > -1}
											onChange={() => onItemSelect(item[identifier], false, item)}
											disabled={!!disableSelection && disableSelection(item)}
										/>
									</div>
								)}
								{renderRow(item)}
							</div>
						);
					})}
				{!loading && (!data || data.length === 0) && (
					<div>
						<div id={'wg-table-no-data'} className={'text-primary text-center py-4'}>
							{noDataMessage}
						</div>
					</div>
				)}
			</div>
			{renderLoadMore()}
		</div>
	);
};

export default AppTable;
