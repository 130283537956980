import React from 'react';
import CoverPage from './student/CoverPage';
import Overview from './student/Overview';
import Footer from './student/Footer';
import Results from './student/Results';
import WGPairings from './student/WGPairings';
import Applying from './student/Applying';
import Descriptions from './student/Descriptions';
import PairingDescription from './student/PairingDescription';

const StudentTemplate = ({ assessment }) => {
    return (
        <div id={'wg-assessment-report'} className={'wg-pdf-assessment-report-v2'}>
            <section role='region' className='Page'>
                <CoverPage assessment={assessment} />
            </section>
            <section role='region' className='Page'>
                <Overview assessment={assessment} />
                <Footer page={1} />
            </section>
            <section role='region' className='Page'>
                <Results assessment={assessment} />
                <Footer page={2} />
            </section>
            <section role='region' className='Page'>
                <PairingDescription assessment={assessment} />
                <Footer page={3} />
            </section>
            <section role='region' className='Page'>
                <WGPairings assessment={assessment} />
            </section>
            <section role='region' className='Page'>
                <Applying />
                <Footer page={5} />
            </section>
            <section role='region' className='Page'>
                <Descriptions assessment={assessment} />
                <Footer page={6} />
            </section>
        </div>
    );
};

export default StudentTemplate;
