import React from 'react';
import OutsideAlerter from './OutsideAlerter';

const AppMoreOptions = ({ actions = [], children }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className='flex justify-center'>
			<div>
				<div className='more-options-container'>
					<OutsideAlerter handleClickOutsideFunction={() => setIsOpen(false)}>
						<div className={'cursor-pointer more-icon-container'} onClick={() => setIsOpen(!isOpen)}>
							{children}
						</div>
						<div className={`action-container ${isOpen ? '' : 'hidden'}`}>
							{actions.map(({ text, onClick }, index) => (
								<div
									key={index}
									className={'cursor-pointer'}
									onClick={() => {
										setIsOpen(false);
										onClick();
									}}
								>
									{text}
								</div>
							))}
						</div>
					</OutsideAlerter>
				</div>
			</div>
		</div>
	);
};

export default AppMoreOptions;
