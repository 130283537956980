import React from 'react';
import AppButton from './AppButton';

const AppCopyToClipboardMobile = ({ value, text, ...props }) => {
	const [copied, setCopied] = React.useState(false);

	const copyText = () => {
		setCopied(true);
		navigator.clipboard.writeText(value);
	};

	return (
		<AppButton
			className={'btn btn-primary w-full'}
			text={copied ? 'Successfully copied' : text}
			icon={copied ? 'ok-circled' : 'copy-paste'}
			iconClass={'app-copy-paste-icon'}
			onClick={() => copyText()}
			{...props}
		/>
	);
};

export default AppCopyToClipboardMobile;
