import React from 'react';
import { ttg_logo_white, phone, mail } from '../../images';
import config from '../../constants/config';

const HomepageFooter = () => {
	return (
		<div className='homepage-footer-section'>
			<div className='flex flex-col justify-center mx-auto container h-full'>
				<div className='mt-auto'>
					<h3 className='homepage-heading'>
						Questions? <br /> Contact us today<span className='text-secondary'>.</span>
					</h3>
					<div className='footer-contact flex flex-col lg:flex-row justify-center items-end px-4 mt-12'>
						<div className='my-4 mx-auto text-xl'>
							<p className='font-bold uppercase text-center'>Corporate Headquarters</p>
							<address>
								<p className='contact-address text-xl text-center lg:text-left mt-4 italic'>
									The Table Group, Inc
									<br />
									1211 Newell Ave, Suite 150
									<br />
									Walnut Creek, CA 94596
								</p>
							</address>
						</div>

						<div className='mx-auto my-4 contact-phone'>
							<img className='w-full contact-phone-icon' src={phone} alt='Phone Icon' />
							<a href='tel:+19252999700' className='contact-link' id={'wg-homepage-footer-phone-link'}>
								925.299.9700
							</a>
						</div>

						<div className='mx-auto my-4 contact-mail'>
							<img className='w-100 contact-mail-icon' src={mail} alt='Mail Icon' />
							<a
								href='mailto:support@workinggenius.com'
								className='contact-link'
								id={'wg-homepage-footer-mail-link'}
							>
								support@workinggenius.com
							</a>
						</div>
					</div>
				</div>
				<div className='ttg-logo-white-container flex justify-start mx-auto lg:mx-0 mt-auto mb-10'>
					<a href={config.ttg_url} target='_blank' rel='noreferrer' id={'wg-homepage-footer-ttg-link'}>
						<img className='ttg-logo-white' src={ttg_logo_white} alt='Working Geniuses Primary Logo' />
					</a>
				</div>
			</div>
		</div>
	);
};

export default HomepageFooter;
