import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';

import AppInput from '../AppInput';
import AppModal from '../AppModal';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import AppRIIcon from '../AppRIIcon';
import AppDropdown from '../AppDropdown';

import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import { useUser } from '../../hooks/UserProvider';

const EnablePermissionsForm = ({ isOpen, onClose, enablePermissions }) => {
	const { user, setUser } = useUser();
	const initialFormValues = {
		company_size:
			CustomerService.CustomerCompanySizes.find((size) => size.value === user.customers_company_size) ?? null,
		industry:
			CustomerService.CustomerIndustries.find((industry) => industry.value === user.customers_industry) ?? null,
		company: user.company ?? null,
		role: CustomerService.CustomerBusinessRoles.find((role) => role.value === user.role) ?? null,
	};
	const customStyles = {
		content: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			background: 'transparent',
			maxHeight: '95vh',
		},
	};

	const [loading, setLoading] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
		control,
	} = useForm({
		defaultValues: initialFormValues,
	});

	const { isDirty } = useFormState({ control });

	useEffect(() => {
		if (!isOpen) {
			reset();
			setLoading(false);
		}
		clearErrors();
	}, [isOpen]);

	const trimInputValue = (name, e) => setValue(name, e.target.value.trim());

	const onSubmit = async (payload) => {
		setLoading(true);
		const registerPayload = {
			customers_permissions_enabled: 1,
			company: payload.company,
			role: payload.role?.value || 'OTHER',
			customers_company_size: payload.company_size?.value || 'NOT_SPECIFIED',
			customers_industry: payload.industry?.value || 'OTHER',
		};
		CustomerService.update(registerPayload)
			.then((result) => {
				setUser((u) => ({
					...u,
					company: result.company,
					has_permissions_enabled: result.customers_permissions_enabled ? 1 : 0,
					role: result.role,
					customers_industry:
						CustomerService.CustomerIndustries.find(
							(industry) => industry.value === result.customers_industry
						) ?? null,
					customers_company_size:
						CustomerService.CustomerCompanySizes.find(
							(size) => size.value === result.customers_company_size
						) ?? null,
				}));
				NotificationService.success('Form submitted successfully!', 'You can now give access to other users.');
				enablePermissions(true);
			})
			.catch((result) => NotificationService.error('Oops', result.message))
			.finally(() => {
				setLoading(false);
				onClose();
			});
	};

	const dropdownStyles = {
		option: (provided, { isFocused }) => ({
			...provided,
			backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
			color: 'text-gray-700',
		}),
		menuPortal: (base) => ({ ...base, zIndex: 9999 }),
	};

	const renderFormContent = () => (
		<form className='w-full' autoComplete='autocomplete_off_hack_xfr4!k' onSubmit={handleSubmit(onSubmit)}>
			<AppInput
				{...register('company', {
					required: true,
					maxLength: 64,
				})}
				containerClassName={'flex-1'}
				ttgMaxLength={64}
				label='Company Name'
				errors={errors}
				onBlur={(e) => trimInputValue('company', e)}
			/>
			<Controller
				control={control}
				name={'role'}
				rules={{ required: true }}
				render={({ field, formState }) => (
					<AppDropdown
						id={'wg-permissions-role-select'}
						name={'wg-permissions-role-select'}
						{...field}
						label={'Role'}
						isClearable
						options={CustomerService.CustomerBusinessRoles}
						errors={formState.errors}
						containerClassName={'flex-1 mb-4'}
						shouldShowErrorMessage
						styles={dropdownStyles}
						maxMenuHeight={250}
						menuPortalTarget={document.body}
					/>
				)}
			/>
			<Controller
				control={control}
				name={'company_size'}
				rules={{ required: true }}
				render={({ field, formState }) => (
					<AppDropdown
						id={'wg-permissions-company-size-select'}
						name={'wg-permissions-company-size-select'}
						{...field}
						label={'Company Size'}
						isClearable
						options={CustomerService.CustomerCompanySizes}
						errors={formState.errors}
						containerClassName={'flex-1 mb-4'}
						shouldShowErrorMessage
						styles={dropdownStyles}
						maxMenuHeight={220}
						menuPortalTarget={document.body}
					/>
				)}
			/>
			<Controller
				control={control}
				name={'industry'}
				rules={{ required: true }}
				render={({ field, formState }) => (
					<AppDropdown
						id={'wg-permissions-industry-select'}
						name={'wg-permissions-industry-select'}
						{...field}
						label={'Industry'}
						isClearable
						options={CustomerService.CustomerIndustries}
						errors={formState.errors}
						containerClassName={'flex-1 mb-4'}
						shouldShowErrorMessage
						styles={dropdownStyles}
						maxMenuHeight={220}
						menuPortalTarget={document.body}
					/>
				)}
			/>

			<div className={'flex flex-col md:flex-row mt-4'}>
				<AppButton
					type='button'
					className={'btn btn-secondary w-full md:mr-4 my-2'}
					text={'Cancel'}
					onClick={onClose}
				/>
				<AppButton
					type='submit'
					className={'btn btn-primary w-full my-2'}
					loading={loading}
					text={'Submit'}
					disabled={!isDirty}
				/>
			</div>
		</form>
	);

	return (
		<AppModal isOpen={isOpen} closeTimeout={0} onRequestClose={onClose} style={customStyles}>
			<div className='bg-white p-8' style={{ maxWidth: '500px' }}>
				<div className={'flex flex-col items-center justify-center text-center mb-4'}>
					<AppRIIcon icon={'user-settings-line'} className={'text-4xl text-secondary leading-none'} />
					<h1 className={'text-lg text-center text-primary font-medium mb-3'}>Permissions</h1>
					<p className={'text-sm text-gray-500 leading-5 font-normal'}>
						Please fill out the following information in order to enable the ability for other users to
						access your account.
					</p>
				</div>
				<div className='absolute right-0 top-0 mt-8 mr-4'>
					<AppIcon
						icon={'cancel'}
						className={'text-gray-500 text-2xl cursor-pointer hover:text-secondary'}
						onClick={onClose}
					/>
				</div>
				{renderFormContent()}
			</div>
		</AppModal>
	);
};

export default EnablePermissionsForm;
