import React from 'react';
import { Link } from 'gatsby';
import AppButton from '../AppButton';
import Event6Types from '../event/Event6Types';
import AppVideoModal from '../AppVideoModal';

const Home6Types = () => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	return (
		<div className='pb-12'>
			<Event6Types longTitle={true} leftPositioned={true} />
			<div className='flex flex-col lg:flex-row items-center justify-center'>
				<Link to={'/about'} className={'btn btn-primary m-2'} id={'wg-home-6-types-about-btn'}>
					Learn more about working genius
				</Link>
				<AppButton
					onClick={() => setVimeoUrl('https://player.vimeo.com/video/575386322?h=27dd77ef5e&amp;')}
					text={'Watch the overview'}
					className={'btn btn-secondary m-2'}
					id={'wg-home-6-types-overview-btn'}
				/>
			</div>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default Home6Types;
