import React from 'react';
import AppIcon from './AppIcon';
import AppTooltip from './AppTooltip';

const AppLabel = ({ label, hasError, labelTooltip, labelIcon = 'help-circled', customClassName= '' }) => {
	if (!label) {
		return null;
	}
	return (
		<div className={`app-input-label ${hasError ? 'error' : ''}`}>
			<div className={`${customClassName ? customClassName : 'flex flex-1 flex-row'}`}>
				{label}
				{!!labelTooltip && (
					<AppTooltip
						content={() => <p className={'text-white text-center'}>{labelTooltip}</p>}
						placement={'top'}
						type={'small'}
					>
						<AppIcon icon={labelIcon} />
					</AppTooltip>
				)}
			</div>
		</div>
	);
};

export default AppLabel;
