import React, { useState } from 'react';
import { about_the_assessment_team_map } from '../../../images';
import AppButton from '../../AppButton';
import AppVideoModal from '../../AppVideoModal';

const AssessmentAboutResults = () => {
	const [vimeoUrl, setVimeoUrl] = useState(null);

	return (
		<div className='bg-gray-80'>
			<div className={'flex flex-col md:flex-row items-center container'}>
				<div className={'flex-1 pb-5'}>
					<img src={about_the_assessment_team_map} alt='team-map' />
				</div>
				<div className={'flex-1 flex flex-col items-start py-6'}>
					<h2 className={'text-2xl md:text-4xl text-primary text-center pb-8'}>Team Results at a Glance</h2>
					<p className={'text-primary pb-6 w-full md:w-10/12'}>
						A Team Map displays a quick snapshot of each member's Working Genius and Working Frustration.
						From this map, you'll be able to identify areas of strength and areas of missing Genius as well
						as opportunities to make adjustments that will lead to increased productivity and fulfillment.
					</p>
					<div className={'flex flex-row'}>
						<a
							className={'btn btn-secondary text-blue-700 mr-3'}
							href='https://files.tablegroup.com/wp-content/uploads/2022/09/23033842/final_team_map.pdf'
							target='_blank'
						>
							Sample team map
						</a>
						<AppButton
							text='Watch the Video'
							className={'btn btn-secondary text-blue-700'}
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/750403647?h=6102028612')}
						/>
					</div>
				</div>
				{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
			</div>
		</div>
	);
};

export default AssessmentAboutResults;
