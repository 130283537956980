import React from 'react';
import { book_3d, cert_logo_es } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const GoingDeeper = () => {
	return (
		<div className='GoingDeeper'>
			<PageHeader title={'APLICACIÓN'} />
			<div className={'container mt-10'}>
				<PageTitle title={'¿Quieres profundizar más?'} />

				<p className={'my-5'}>
					Hacer la evaluación de Working Genius es el primer paso para descubrir tus talentos y experimentar cómo puede este modelo transformar tu trabajo, tu equipo y tu vida. El equipo de Working Genius ha desarrollado diversos recursos para ayudar a individuos, equipos y organizaciones a aprovechar este modelo tanto a nivel personal como profesional. Descubre cómo funciona Working Genius en reuniones, contrataciones, gestión de proyectos, productividad, relaciones, familia y más con los siguientes recursos:
				</p>

				<div className={'grid grid-cols-1 gap-4 mx-12'}>
					<div className={'spanish-item-container'} style={{ paddingLeft: '60px' }}>
						<img src={book_3d} alt='Book image' style={{ minHeight: '220px', maxHeight: '220px' }} />
						<div className='flex flex-col items-center justify-center px-8'>
							<p className={'title'}>El libro</p>
							<a href={'https://www.empresaactiva.com/los-6-talentos-laborales'} className={'btn btn-primary btn-small'}>
								CÓMPRALO AHORA
							</a>
						</div>
					</div>
					<div className={'spanish-item-container'}>
						<img src={cert_logo_es} alt='Certification image' style={{ minHeight: '200px', maxHeight: '200px' }} />
						<div className='flex flex-col items-center justify-center px-8'>
							<p className={'title'}>Certificación</p>
							<a href={'https://www.dalecarnegie.com/es/courses/6578'} className={'btn btn-primary btn-small'}>
								CONOCE MÁS
							</a>
						</div>
					</div>
				</div>
			</div>
		</div >
	);
};

export default GoingDeeper;
