import React from 'react';

const CompletedAssessmentNotification = ({ notification, changeNotificationReadStatus }) => {
	const renderLink = () => {
		return (
			<span
				className={'link text-secondary hover:text-primary underline cursor-pointer'}
				onClick={() => changeNotificationReadStatus(`/client/assessment_roster/?id=${notification.roster_id}`)}
			>
				Link
			</span>
		);
	};

	return (
		<>
			<p className={'text-lg font-medium mb-2 mr-2'}>Assessment Completed</p>
			<p className={'text-normal mb-2 mr-2'} style={{ wordBreak: 'break-word' }}>
				The following participant{' '}
				{notification.group && (
					<>
						for <strong>{notification.assessment_name}</strong> at <strong>{notification.company}</strong>
					</>
				)}{' '}
				{!notification.shared && 'has completed the Working Genius Assessment:'}
				{notification.shared && 'has shared a previous Working Genius Assessment with you:'}
				<br />
				<strong>{notification.roster_name}</strong> (<strong>{notification.roster_email}</strong>)
				<br />
				To view or download the PDF of the results, click the link below <br />
				{renderLink()}
			</p>
			{notification.shared && (
				<p className='text-normal text-primary mb-2'>
					<strong>*Note:</strong> This participant has shared previous results. One license has been added
					back to your account.
				</p>
			)}
		</>
	);
};

export default CompletedAssessmentNotification;
