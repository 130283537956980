import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';

import AppText from '../../components/AppText';
import AppInput from '../../components/AppInput';
import AppButton from '../../components/AppButton';
import AppDropdown from '../../components/AppDropdown';
import AppLabel from '../../components/AppLabel';
import Layout from '../../components/Layout';
import Error from '../../components/assessments/Error';
import AssessmentErrorModal from '../../components/assessments/AssessmentErrorModal';
import AppIcon from '../../components/AppIcon';
import LanguageSelectionModal from '../../components/assessments/LanguageSelectionModal';
import SharePreviousAssessmentModal from '../../components/assessments/take/SharePreviousAssessmentModal';
import AppCheckBox from '../../components/AppCheckBox';
import AssessmentsService, {
	getAssessmentBySlug,
	getAssessmentDetails,
	sharePreviousAssessment,
} from '../../services/AssessmentsService';
import { emailRegex } from '../../services/UtilityService';
import NotificationService from '../../services/NotificationService';
import AppRadioGroup from '../../components/AppRadioGroup';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';

const TakeAssessment = ({ slug, slug2, slug3, slug4 }) => {
	const options = [
		{
			value: 'en',
			label: 'English (US)',
			icon: 'hello-en',
		},
		{
			value: 'es',
			label: 'Español',
			icon: 'hola-es',
		},
		{
			value: 'de',
			label: 'Deutsch',
			icon: 'halo-de',
		},
	];

	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [assessment, setAssessment] = useState({});
	const [error, setError] = useState({});
	const [errorModal, setErrorModal] = useState('');
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = React.useState(options[0]);
	const [languageModal, setLanguageModal] = useState(true);
	const [previousAssessments, setPreviousAssessments] = useState();
	const [optIn, setOptIn] = useState(null);

	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		clearErrors,
	} = useForm();

	useEffect(() => {
		getAssessmentBySlug(AssessmentsService.prepareSlug(slug, slug2, slug3, slug4))
			.then(setAssessment)
			.catch((error) => setError(error))
			.finally(() => setLoading(false));
	}, []);

	const _onSubmit = (payload) => {
		setSaving(true);
		payload.first_name = payload?.first_name || assessment?.first_name;
		payload.last_name = payload?.last_name || assessment?.last_name;
		payload.email = payload?.email?.toLowerCase() || assessment?.email?.toLowerCase()
		payload.opt_in = Boolean(payload.opt_in);

		getAssessmentDetails(assessment.id, null, payload, selectedLanguage.value)
			.then((response) => {
				if (!response.previous_assessments || assessment?.source === 'API') {
					return navigate(`/assessments/take?a=${assessment.id}&r=${response.id}`);
				}

				setAssessment((a) => ({
					...a,
					roster_id: response.id,
					allow_share: payload.allow_share,
					opt_in: payload.opt_in,
				}));
				setPreviousAssessments(response.previous_assessments);
			})
			.catch((error) => {
				setErrorModal(error.code);
				setIsErrorModalOpen(true);
			})
			.finally(() => setSaving(false));
	};

	const _onContinueTake = (existing_assessment) => {
		if (!existing_assessment) {
			return navigate(`/assessments/take?a=${assessment.id}&r=${assessment.roster_id}`);
		}

		setSaving(true);
		sharePreviousAssessment(
			assessment.id,
			assessment.roster_id,
			existing_assessment.assessment,
			existing_assessment.id,
			assessment.allow_share
		)
			.then(() => {
				NotificationService.success(
					t('assessment.take.welcome.previous_assessments.shared_title'),
					t('assessment.take.welcome.previous_assessments.shared_message')
				);
				navigate(`/client/login`);
			})
			.catch((error) => {
				setErrorModal(error.code);
				setIsErrorModalOpen(true);
			})
			.finally(() => {
				setSaving(false);
				setPreviousAssessments(null);
			});
	};

	if (loading) {
		return (
			<div className='relative top-0 left-0 right-0 bottom-0 h-screen flex justify-center items-center'>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		);
	}

	const renderContent = () => {
		if (error && error.code) {
			return <Error message={t(`assessment.take.welcome.error.${error.code}`)} />;
		} else {
			return (
				<div
					id={'wg-take-group-assessment'}
					className={'container flex flex-col mx-4 md:mx-auto'}
					style={{ maxWidth: '640px' }}
				>
					<h1 className='text-primary font-medium text-2xl mt-4'>{t('assessment.take.welcome.title') + (assessment?.assessment_mode === 'student' ? ' for Students' : '')}</h1>
					<AppText text={assessment.welcome} style={{ fontSize: '1rem', marginTop: '1rem' }} />

					<form
						className={'w-full mt-6'}
						onSubmit={handleSubmit(_onSubmit)}
						autoComplete='autocomplete_off_hack_xfr4!k'
					>
						{assessment?.source !== 'API' &&
							<>
								<AppInput
									label={t('assessment.take.welcome.first_name')}
									placeholder={t('assessment.take.welcome.first_name_placeholder')}
									errors={errors}
									{...register('first_name', {
										required: t('assessment.take.welcome.first_name_required'),
										maxLength: 32,
									})}
									ttgMaxLength={32}
									onBlur={(e) => {
										setValue('first_name', e.target.value.trim());
									}}
								/>
								<AppInput
									label={t('assessment.take.welcome.last_name')}
									placeholder={t('assessment.take.welcome.last_name_placeholder')}
									errors={errors}
									{...register('last_name', {
										required: t('assessment.take.welcome.last_name_required'),
										maxLength: 32,
									})}
									ttgMaxLength={32}
									onBlur={(e) => {
										setValue('last_name', e.target.value.trim());
									}}
								/>
								<AppInput
									{...register('email', {
										required: t('assessment.take.welcome.email_required'),
										pattern: {
											value: emailRegex,
											message: t('assessment.take.welcome.email_regex'),
										},
									})}
									label={t('assessment.take.welcome.email')}
									placeholder={t('assessment.take.welcome.email_placeholder')}
									errors={errors}
									onBlur={(e) => {
										setValue('email', e.target.value.trim());
									}}
								/>
								<AppInput
									{...register('confirm_email', {
										required: t('assessment.take.welcome.email_required'),
										validate: (value) => {
											const currentValue = value ? value.toLowerCase() : '';
											const emailValue = getValues('email');
											const lowerEmail = emailValue ? emailValue.toLowerCase() : '';
											return currentValue === lowerEmail || t('assessment.take.welcome.email_match');
										},
										pattern: {
											value: emailRegex,
											message: t('assessment.take.welcome.email_regex'),
										},
									})}
									label={t('assessment.take.welcome.confirm_email')}
									placeholder={t('assessment.take.welcome.confirm_email_placeholder')}
									errors={errors}
									onPaste={(e) => {
										e.preventDefault();
										return false;
									}}
									onBlur={(e) => {
										setValue('confirm_email', e.target.value.trim());
									}}
									labelTooltip={t('assessment.take.welcome.confirm_email_tooltip')}
									labelIcon={'info-circled'}
								/>
								{(!!assessment.multi_language && assessment.assessment_mode === AssessmentModeEnum.STANDARD) && (
									<AppDropdown
										placeholder={'Please select the language you want to complete the assessment'}
										label={t('assessment.take.welcome.select_language')}
										value={selectedLanguage}
										options={options}
										getOptionValue={(option) => option.value}
										getOptionLabel={(option) => (
											<div className={'flex flex-row items-center cursor-pointer'}>
												<AppIcon icon={option.icon} />
												<p>{option.label}</p>
											</div>
										)}
										styles={{
											option: (provided, { isFocused }) => {
												return {
													...provided,
													backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
												};
											},
										}}
										isSearchable={false}
										cacheOptions={false}
										hideSelectedOptions={true}
										onChange={(i) => {
											i18n.changeLanguage(i.value);
											setSelectedLanguage(i ? i : {});
											if (Object.keys(errors).length > 0) {
												document.getElementById('wg-slug-submit-btn').click();
											}
										}}
										containerClassName={'mb-4'}
									/>
								)}
							</>
						}

						<div className={'flex flex-col mb-3'}>
							<AppLabel
								label={
									<Trans i18nKey={'assessment.take.welcome.opt_in'}>
										I’d like to receive helpful content from{' '}
										<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.workinggenius.com/'>
											Working Genius
										</a>{' '}
										and{' '}
										<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.tablegroup.com/'>
											The Table Group
										</a>
									</Trans>
								}
								labelIcon={'info-circled'}
								hasError={!!errors['opt_in']}
								customClassName={'inline-block pb-1'}
							/>
							<AppRadioGroup
								{...register('opt_in', {
									required: t('assessment.take.welcome.is_required'),
								})}
								name={'opt_in'}
								errors={errors}
								value={optIn}
								options={[
									{
										label: t('assessment.take.welcome.opt_in_yes'),
										value: 1,
									},
									{
										label: t('assessment.take.welcome.opt_in_no'),
										value: 0,
									},
								]}
								onChange={(selectedOption) => {
									setOptIn(selectedOption);
									setValue('opt_in', selectedOption);
									clearErrors('opt_in');
								}}
							/>
						</div>

						{assessment?.source !== 'API' &&
							<AppCheckBox
								{...register('allow_share')}
								errors={errors}
								label={() => {
									return (
										<AppLabel
											label={t('assessment.take.welcome.allow_share')}
											labelIcon={'info-circled'}
											labelTooltip={t('assessment.take.welcome.confirm_allow_share_tooltip')}
										/>
									);
								}}
								id={'wg-take-assessment-allow-share-from-team-owner'}
								containerId={'wg-take-assessment-allow-share-from-team-owner-container'}
							/>
						}
						<div className='flex justify-between my-4'>
							<div />
							<AppButton
								type={'submit'}
								text={t('assessment.take.welcome.submit')}
								loading={saving}
								id={'wg-slug-submit-btn'}
							/>
						</div>
					</form>
					<AssessmentErrorModal
						isOpen={isErrorModalOpen}
						error={t(`assessment.take.welcome.error.${errorModal ? errorModal : 'UNDEFINED_ERROR'}`)}
						closeModal={() => setIsErrorModalOpen(false)}
					/>
				</div>
			);
		}
	};

	return (
		<Layout title={'Assessment | The Six Types of Working Genius'}>
			{((!!assessment.multi_language && assessment.assessment_mode === AssessmentModeEnum.STANDARD) || !!error.multi_language) && (
				<LanguageSelectionModal
					isOpen={languageModal}
					setSelectedLanguage={setSelectedLanguage}
					closeModal={() => setLanguageModal(false)}
				/>
			)}
			{!!previousAssessments && (
				<SharePreviousAssessmentModal
					loading={saving}
					onContinue={_onContinueTake}
					closeModal={() => setPreviousAssessments(null)}
					isOpen={!!previousAssessments}
					assessments={previousAssessments}
					laguage={selectedLanguage}
				/>
			)}
			{renderContent()}
		</Layout>
	);
};

export default TakeAssessment;
