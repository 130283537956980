import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';

import Layout from '../../../components/Layout';
import AppInput from '../../../components/AppInput';
import AppButton from '../../../components/AppButton';
import AppErrorMessage from '../../../components/AppErrorMessage';
import AppText from '../../../components/AppText';
import { passwordRegex } from '../../../services/UtilityService';
import { verifyLink } from '../../../services/CustomerService';
import { getQueryParams } from '../../../services/UtilityService';
import { useUser } from '../../../hooks/UserProvider';

const Reset = () => {
	const { resetPassword } = useUser();
	const [loading, setLoading] = useState(false);
	const [loadingScreen, setLoadingScreen] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const location = useLocation();
	const { token } = getQueryParams(location.search);

	useEffect(() => {
		verifyLink(token)
			.then(() => {
				setLoadingScreen(false);
			})
			.catch((error) => {
				navigate('/client/login/reminder', {
					state: {
						error: 'Your password reset link has expired or has already been used. Try resetting your password again.',
					},
				});
			})
			.finally(() => {
				setLoadingScreen(false);
			});
	}, []);

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	if (!token) {
		navigate('/client/login/', { replace: true });
		return null;
	}

	const _reset = (data) => {
		setErrorMessage('');
		setLoading(true);

		resetPassword(token, data.password, data.confirm_password)
			.then(() => {
				navigate('/client/', { replace: true });
			})
			.catch((error) => {
				navigate('/client/login/reminder/', {
					state: {
						error: 'Your password reset link has expired or has already been used. Try resetting your password again.',
					},
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	if (loadingScreen) {
		return (
			<div id={'wg-prework-loading'} className='relative h-screen'>
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<Layout>
			<div className={'container mx-auto'}>
				<div className='md:shadow-md sm:w-full md:w-1/2 lg:w-5/12 xl:w-2/5 md:mx-auto my-12 px-8 md:px-12 py-8'>
					<AppErrorMessage message={errorMessage} />
					<h1 className='uppercase text-secondary text-center text-4xl font-semibold mb-4 leading-none'>
						PASSWORD RESET
					</h1>
					<AppText
						text={'Please enter and confirm your new password in the fields provided below.'}
						className='text-center mb-6'
					/>
					<form
						className={'w-full'}
						onSubmit={handleSubmit(_reset)}
						autoComplete='autocomplete_off_hack_xfr4!k'
					>
						<AppInput
							label={'New password'}
							type={'password'}
							placeholder={'Please enter a new password'}
							errors={errors}
							{...register('password', {
								required: true,
								pattern: {
									value: passwordRegex,
									message:
										'Password must contain at least 8 characters, 1 upper case character, 1 lowercase character and 1 number.',
								},
							})}
						/>

						<AppInput
							label={'Confirm new password'}
							type={'password'}
							placeholder={'Please confirm your new password'}
							errors={errors}
							{...register('confirm_password', {
								required: true,
								validate: (value) => value === getValues('password') || 'Passwords do not match',
							})}
						/>

						<AppButton
							type='submit'
							className={'btn btn-primary w-full mb-4'}
							text={'Submit'}
							loading={loading}
							id={'wg-reset-pass-submit-btn'}
						/>
					</form>
				</div>
			</div>
		</Layout>
	);
};

export default Reset;
