import React from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import CustomerService from '../../services/CustomerService';
import NotificationService from '../../services/NotificationService';
import { getQueryParams, zipCodeRegex } from '../../services/UtilityService';
import ReCaptcha from '../ReCaptcha';
import { useCountries } from '../../hooks/useCountries';
import { useZones } from '../../hooks/useZones';

import AppInput from '../AppInput';
import AppRadioGroup from '../AppRadioGroup';
import AppDropdown from '../AppDropdown';
import Navigation from './Navigation';
import AppLabel from '../AppLabel';
import { useUser } from '../../hooks/UserProvider';
import { useNotifications } from '../../hooks/NotificationProvider';

const AddressDetailsStep = ({ previousStep, optionalAddress = true, registerData }) => {
	const captchaRef = React.useRef('captcha');
	const { setUser } = useUser();
	const { loadNotifications } = useNotifications();
	const { loadingCountries, countries } = useCountries();
	const { loadingZones, zones, getZones } = useZones();

	const location = useLocation();
	const { redirect, source } = getQueryParams(location.search);

	const [optIn, setOptIn] = React.useState(false);
	const [registering, setRegistering] = React.useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		clearErrors,
	} = useForm({
		defaultValues: {
			country_code: {
				label: !optionalAddress && 'United States',
				value: !optionalAddress && 'US',
			},
		},
	});
	const { isSubmitted } = useFormState({ control });

	React.useEffect(() => {
		if (!optionalAddress) {
			getZones('US');
		}
	}, []);

	const _next = async (payload) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setRegistering(true);
		const { country_code, zone_code, ...normalizedPayload } = payload;

		CustomerService.register({
			captcha: recaptchaValue,
			...registerData,
			...normalizedPayload,
			role: registerData.role?.value ?? 'OTHER',
			opt_in: optIn,
			...(country_code?.value ? { country_code: payload.country_code?.value } : {}),
			...(zone_code?.value ? { zone_code: payload.zone_code?.value } : {}),
		})
			.then((user) => {
				setUser(user);
				loadNotifications();
				if (redirect) {
					return navigate(decodeURIComponent(redirect).replace('//', '/'), { replace: true });
				}

				navigate('/client/', { replace: true });
			})
			.catch((e) => NotificationService.error(e.message))
			.finally(() => setRegistering(false));
	};

	const renderStateInput = () => {
		if (zones && zones.length > 0) {
			return (
				<Controller
					control={control}
					name='zone_code'
					rules={{ required: !optionalAddress && 'You must select a state', maxLength: 32 }}
					render={({ field, formState }) => (
						<AppDropdown
							{...field}
							id={'wg-signup-state-select'}
							loading={loadingZones}
							label={
								<span>
									State{' '}
									{optionalAddress && <span className='text-gray-400 font-thin'>(Optional)</span>}
								</span>
							}
							ttgMaxLength={32}
							options={zones}
							errors={formState.errors}
							containerClassName={'mb-4'}
							styles={{
								option: (provided, { isFocused }) => {
									return {
										...provided,
										backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
										color: 'text-gray-700',
									};
								},
							}}
						/>
					)}
				/>
			);
		}
		return null;
	};

	const renderOptInLabel = () => (
		<div>
			I’d like to receive helpful content from{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.workinggenius.com/'>
				Working Genius
			</a>{' '}
			and{' '}
			<a className={'text-secondary hover:text-blue-700'} target='_blank' href='https://www.tablegroup.com/'>
				The Table Group
			</a>
		</div>
	);

	const trimInputValue = (name, e) => {
		setValue(name, e.target.value.trim(), {
			shouldValidate: isSubmitted,
			shouldDirty: true,
		});
	};

	return (
		<div className='flex-col'>
			<form onSubmit={handleSubmit(_next)} autoComplete='autocomplete_off_hack_xfr4!k'>
				<div className='mb-6'>
					<AppInput
						label={
							<span>
								Street Address{' '}
								{optionalAddress && <span className='text-gray-400 font-thin'>(Optional)</span>}
							</span>
						}
						errors={errors}
						{...register('street', {
							required: !optionalAddress && 'You must enter a street address',
							maxLength: 64,
							minLength: 5,
						})}
						ttgMaxLength={64}
						minLength={5}
						onBlur={(e) => trimInputValue('street', e)}
					/>
					<AppInput
						label={
							<span>
								Address Line 2 <span className='text-gray-400 font-thin'>(Optional)</span>
							</span>
						}
						errors={errors}
						{...register('suburb', {
							maxLength: 64,
						})}
						ttgMaxLength={64}
						onBlur={(e) => trimInputValue('suburb', e)}
					/>
					<Controller
						control={control}
						name='country_code'
						rules={{ required: !optionalAddress && 'You must select a country', maxLength: 3 }}
						render={({ field, formState }) => (
							<AppDropdown
								id={'wg-signup-country-select'}
								{...field}
								label={
									<span>
										Country{' '}
										{optionalAddress && <span className='text-gray-400 font-thin'>(Optional)</span>}
									</span>
								}
								ttgMaxLength={3}
								options={countries}
								errors={formState.errors}
								loading={loadingCountries}
								onChange={(e) => {
									getZones(e.value);
									setValue('zone_code', { label: '', value: '' });
									field.onChange(e);
								}}
								containerClassName={'mb-4'}
								styles={{
									option: (provided, { isFocused }) => {
										return {
											...provided,
											backgroundColor: isFocused ? 'bg-blue-200 !important' : 'white !important',
											color: 'text-gray-700',
										};
									},
								}}
							/>
						)}
					/>
					{renderStateInput()}
					<AppInput
						label={
							<span>
								City {optionalAddress && <span className='text-gray-400 font-thin'>(Optional)</span>}
							</span>
						}
						errors={errors}
						{...register('city', {
							required: !optionalAddress && 'You must enter a city',
							minLength: 3,
							maxLength: 32,
						})}
						minLength={3}
						ttgMaxLength={32}
						onBlur={(e) => trimInputValue('city', e)}
					/>
					<AppInput
						label={
							<span>
								Postal Code/Zip{' '}
								{optionalAddress && <span className='text-gray-400 font-thin'>(Optional)</span>}
							</span>
						}
						errors={errors}
						{...register('postcode', {
							required: !optionalAddress && 'You must enter a Postal Code/Zip',
							maxLength: 10,
							minLength: 4,
							pattern: {
								value: zipCodeRegex,
								message: 'You must enter a valid Postal Code/Zip',
							},
						})}
						ttgMaxLength={10}
						minLength={4}
						onBlur={(e) => trimInputValue('postcode', e)}
					/>

					<div className={'flex flex-col mb-3'}>
						<AppLabel
							label={renderOptInLabel()}
							labelIcon={'info-circled'}
							hasError={!!errors['opt_in']}
							customClassName={'inline-block pb-1'}
						/>
						<AppRadioGroup
							{...register('opt_in', {
								required: 'is required',
							})}
							name={'opt_in'}
							errors={errors}
							value={optIn}
							options={[
								{
									label: 'Yes',
									value: 1,
								},
								{
									label: 'No',
									value: 0,
								},
							]}
							onChange={(selectedOption) => {
								setOptIn(selectedOption);
								setValue('opt_in', selectedOption);
								clearErrors('opt_in');
							}}
						/>
					</div>

					<ReCaptcha ref={captchaRef} />
				</div>

				<Navigation
					backText='Back'
					backClass='btn btn-secondary sm:w-full md:w-1/2'
					onBack={previousStep}
					nextText='Create'
					nextClass='btn btn-primary sm:w-full md:w-1/2'
					loading={registering}
				/>
			</form>
		</div>
	);
};

export default AddressDetailsStep;
