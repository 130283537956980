import React from 'react';
import { Helmet } from 'react-helmet';

const AppTabTitle = ({ title, description }) => {
	return (
		<Helmet>
			<title>{title ? title : 'The Six Types of Working Genius'}</title>
			<meta name='title' content={title ? title : 'The Six Types of Working Genius'} data-react-helmet='true' />
			<meta
				name='description'
				content={
					description
						? description
						: 'From the author of The Five Dysfunctions of a Team comes the Working Genius Assessment. The fastest and simplest way to discover your natural talents at work'
				}
				data-react-helmet='true'
			/>
		</Helmet>
	);
};

export default AppTabTitle;
