import React from 'react';
import { certification_table_update } from '../../../images';

const items = [
	'Interpret team maps, analyze gaps, and advise solutions',
	'Enhance team meetings and increase productivity',
	'Analyze challenges and advise solutions',
	'Anticipate common objections',
	'Coach leaders towards increased self-awareness',
];

const DuringTraining = () => {
	return (
		<div className='container'>
			<div className='flex flex-col md:flex-row mt-4 py-12'>
				<div className='flex flex-1 flex-col justify-center mx-12'>
					<h2 className='text-3xl text-primary leading-none mb-4'>
						Our Master Facilitators Equip You To:
					</h2>
					<ul className='pl-3 list-disc'>
						{items.map((item, key) => {
							return (
								<li className={'text-primary mt-2'} key={key}>
									{item}
								</li>
							);
						})}
					</ul>
				</div>
				<div className='flex flex-1 m-2'>
					<img src={certification_table_update} className={'object-contain'} alt='' />
				</div>
			</div>
		</div>
	);
};

export default DuringTraining;
