export default {
	Article: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094649/s2CT0wwz-article.svg',
	Event: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094648/P3DcfU9i-liveevent.svg',
	News: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094649/s2CT0wwz-article.svg',
	Podcast: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094646/DBPjXMm6-podcast.svg',
	Video: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094650/video.svg',
	Material: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094647/s6oec2Av-libtool.svg',
	Resource: 'https://de7pikzj4hvyk.cloudfront.net/wp-content/uploads/2021/04/08094647/s6oec2Av-libtool.svg',
	AlumniIntensive: 'https://files.tablegroup.com/wp-content/uploads/2024/07/29031555/Working-Genius-Intensives-Asset-2.webp',
	AlumniIntensiveResource: 'https://files.tablegroup.com/wp-content/uploads/2024/07/29031555/Working-Genius-Intensives-Asset-2.webp',
};
