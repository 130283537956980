import React from 'react';

const SharedAssessmentNotification = ({ notification, changeNotificationReadStatus }) => {
	return (
		<>
			<p className={'text-lg font-medium mb-2 mr-2'}>Report Shared</p>
			<p className={'text-normal mb-2 mr-2'} style={{ wordBreak: 'break-word' }}>
				A Working Genius Assessment report of <strong>{notification.roster_name}</strong> (
				<strong>{notification.roster_email}</strong>) has been shared by: <br />
				<strong>
					{notification.shared_by_fname} {notification.shared_by_lname}
				</strong>{' '}
				(<strong>{notification.shared_by_email}</strong>)
				<br />
				To view or download the PDF of the results, click the link below.
				<br />
				<span
					className={'link text-secondary hover:text-primary underline cursor-pointer'}
					onClick={() =>
						changeNotificationReadStatus(`/client/assessment_roster/?id=${notification.roster_id}`)
					}
				>
					Link
				</span>
			</p>
		</>
	);
};

export default SharedAssessmentNotification;
