import React from 'react';
import Layout from '../../../../components/Layout';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';

const SaveDetails = () => {
	const location = useLocation();

	return (
		<Layout title={'The 6 Types of Working Genius: Administrator Console'}>
			<div className='container mx-auto flex flex-col justify-center items-center'>
				<h2 className='text-2xl md:text-5xl text-primary font-bold my-4'>
					{location.state.title ? location.state.title : 'Something went wrong'}
				</h2>
				<p className='text-lg text-primary font-normal mb-4'>
					{location.state.text ? location.state.text : 'Please try again.'}
				</p>
				<Link to={'/client/account'} className='btn btn-primary'>
					Done
				</Link>
			</div>
		</Layout>
	);
};

export default SaveDetails;
