import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import ReportWidgetDeSvg from '../../../../images/assessments/report/img/widget_gears_de.inline.svg';
import ReportUniquePairingsDE from '../../../../constants/ReportUniquePairingsDE';

const UniquePairingV2 = ({ assessment }) => {
	const pairing = React.useMemo(() => {
		const key = ReportUniquePairingsDE.getUniquePairing(
			assessment.customResults.genius1,
			assessment.customResults.genius2
		);
		const reverse = key.split('').reverse().join('');
		return {
			key: key,
			reverse: reverse,
			info: ReportUniquePairingsDE.UniqueParings[key] ?? ReportUniquePairingsDE.UniqueParings[reverse],
		};
	}, []);

	return (
		<div className='UniquePairing'>
			<PageHeader title={'BESCHREIBUNG DER KOMBINATION'} />

			<div className='container en'>
				<div className='flex flex-row gap-3 pt-3 pb-2 mt-1'>
					<div className='flex flex-1 flex-col'>
						<PageTitle title={'Ihre spezielle Kombination'} className={'mb-1 pairing-title'} />
						<div className={'pairing-container without-border'}>
							<span className={'information'}>
								<h2 className={'key text-lg'}>
									{pairing.key} | {pairing.reverse}
								</h2>
								<p className={'title bold'}>{pairing.info.title}</p>
							</span>
						</div>

						<p className={'text-12 pt-1 pr-6'}>
							Jeder der Genius-Typen ist zwar für sich genommen wichtig, aber die Kombination der beiden
							Working Geniuses kann ebenso aufschlussreich sein, wenn es darum geht, Ihre ganz speziellen
							Talente und Gaben zu erkennen. Im Folgenden finden Sie eine Beschreibung Ihrer Kombination,
							die Verknüpfung von{' '}
							<span
								className='uppercase font-bold'
								style={{ color: ReportUniquePairingsDE.genius_color }}
							>
								{ReportUniquePairingsDE.getAbility(assessment.customResults.genius1)}
							</span>{' '}
							und{' '}
							<span
								className='uppercase font-bold'
								style={{ color: ReportUniquePairingsDE.genius_color }}
							>
								{ReportUniquePairingsDE.getAbility(assessment.customResults.genius2)}
							</span>
							.
						</p>
					</div>

					<div
						className={`flex-1 genius ${assessment.customResults.genius1} ${assessment.customResults.genius2}`}
					>
						<ReportWidgetDeSvg />
					</div>
				</div>
				<p className={'text-12'}> {pairing.info.description}</p>

				<div className={'crave_container de'}>
					<span className={'crave_items_container de'}>
						<div className={'crave_item'}>
							<span className={'title'}>SEHNT SICH NACH:</span>
							{pairing.info.crave.map((crave) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crave.title}</span>
									{crave.description}
								</p>
							))}
						</div>

						<div className={'crave_item'}>
							<span className={'title'}>NIEDERGESCHLAGEN DURCH:</span>
							{pairing.info.crush.map((crush) => (
								<p className={'list'}>
									<span className={'font-bold'}>{crush.title}</span>
									{crush.description}
								</p>
							))}
						</div>
					</span>

					<p className={'italic font-bold text-center text-12 text-gray-800 pb-2'}>
						Auf der folgenden Seite finden Sie kurze Beschreibungen zu allen 15 Kombinationen.
					</p>
				</div>
			</div>
		</div>
	);
};

export default UniquePairingV2;
