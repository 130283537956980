import React from 'react';

import Tabs from './Tabs';
import NotificationsList from './NotificationsList';
import NoData from './NoData';
import WGTeamNotificationsList from './WGTeamNotificationsList';
import NotificationService from '../../services/NotificationService';
import AppIcon from '../AppIcon';
import UtilityService from '../../services/UtilityService';
import { useNotifications } from '../../hooks/NotificationProvider';
import { useUser } from '../../hooks/UserProvider';

const TYPES = {
	today: 'today',
	older: 'older',
	wg: 'wg',
};

const Notifications = ({ toggleWidgetVisibility }) => {
	const [activeTab, setActiveTab] = React.useState(0);
	const [markingAsRead, setMarkingAsRead] = React.useState(false);
	const {
		notifications: { today, later, wg, unreadLocal, unreadGlobal, unreadAll },
		loading,
		setNotifications,
	} = useNotifications();
	const { hasPermission } = useUser();
	const _setNotifications = (key, data) => setNotifications((all) => ({ ...all, [key]: data }));

	const onStatusChange = (notification, callback, type, forceRead) => {
		const _today = UtilityService.clone([...today]);
		const _later = UtilityService.clone([...later]);
		const _wg = UtilityService.clone([...wg]);
		if (type === TYPES.today) {
			const i = _today.findIndex(({ id }) => notification.id === id);
			_today[i].read = forceRead !== undefined ? forceRead : !_today[i].read;
			callback(_today);
		} else if (type === TYPES.older) {
			const i = _later.findIndex(({ id }) => notification.id === id);
			_later[i].read = forceRead !== undefined ? forceRead : !_later[i].read;
			callback(_later);
		} else {
			const i = _wg.findIndex(({ id }) => notification.id === id);
			_wg[i].read = !_wg[i].read;
			callback(_wg);
		}
		const unReadCount = [..._today, ..._later, ..._wg].filter(({ read }) => !read).length;
		const unreadLocalCount = [..._today, ..._later, ..._wg].filter(
			({ type, read }) => type === 'local' && !read
		).length;
		const unreadGlobalCount = [..._today, ..._later, ..._wg].filter(
			({ type, read }) => type === 'global' && !read
		).length;

		_setNotifications('unreadAll', unReadCount);
		_setNotifications('unreadLocal', unreadLocalCount);
		_setNotifications('unreadGlobal', unreadGlobalCount);
	};

	const markAllAsRead = () => {
		setMarkingAsRead(true);

		NotificationService.markAllAsRead()
			.then(() => {
				setNotifications((all) => ({
					...all,
					today: NotificationService.setNotificationsAsRead(all.today),
					later: NotificationService.setNotificationsAsRead(all.later),
					wg: NotificationService.setNotificationsAsRead(all.wg),
					unreadAll: 0,
					unreadLocal: 0,
					unreadGlobal: 0,
				}));
			})
			.finally(() => {
				setMarkingAsRead(false);
			});
	};

	const handleKeyDownMarkAllAsRead = (e) => {
		switch (e.key) {
			case "Enter":
			case " ":
				markAllAsRead();
				break;
			default:
				return;
		}
		e.stopPropagation();
		e.preventDefault();
	};

	const renderLoading = (
		<div id={'wg-notifications-loader'} className={'flex items-center justify-center'}>
			<AppIcon icon={'loading'} className={'animate-spin'} />
		</div>
	);

	const renderMarkAllAsRead = () => {
		if (!hasPermission('NOTIFICATION.PUT')) return null;
		if (markingAsRead) {
			return renderLoading;
		}
		if (unreadAll > 0) {
			return (
				<span
					id={'wg-notifications-mark-all-as-read'}
					onClick={markAllAsRead}
					tabIndex={0}
					role="button"
					onKeyDown={handleKeyDownMarkAllAsRead}
					className='flex text-gray-600 cursor-pointer hover:underline'
				>
					Mark all as read
				</span>
			);
		}
		return null;
	};

	const renderContent = () => {
		if (activeTab === 0) {
			return (
				<div className={'mx-4'}>
					<div className={'flex justify-end my-4'}>{renderMarkAllAsRead()}</div>
					<WGTeamNotificationsList
						notifications={wg}
						onStatusChange={(notification) =>
							onStatusChange(notification, (c) => _setNotifications('wg', c), TYPES.wg)
						}
					/>
					<div className='flex flex-1 border-b-2 border-gray-300 mt-4' />
					<div className='flex items-center justify-center mt-4 mb-4'>{renderFooter()}</div>
				</div>
			);
		}

		if (today.length + later.length === 0) {
			return loading ? (
				<div className='my-4'>{renderLoading}</div>
			) : (
				<NoData
					icon={'notification-4-line'}
					message={'You have no notifications from the last days.'}
					className={'my-4'}
				/>
			);
		}

		return (
			<div className={'mx-4'}>
				{today.length > 0 && (
					<div className={`flex my-4 ${today.length === 0 ? 'justify-end' : ''}`}>
						<p className='flex flex-1 text-gray-600 font-medium'>TODAY</p>
						{renderMarkAllAsRead()}
					</div>
				)}
				<NotificationsList
					notifications={today}
					toggleWidgetVisibility={toggleWidgetVisibility}
					onStatusChange={(notification, forceRead) =>
						onStatusChange(notification, (c) => _setNotifications('today', c), TYPES.today, forceRead)
					}
				/>
				{later.length > 0 && (
					<div className={'flex my-4'}>
						<p className='flex flex-1 text-gray-600 font-medium'>OLDER</p>
						{today.length === 0 && renderMarkAllAsRead()}
					</div>
				)}
				<NotificationsList
					notifications={later}
					toggleWidgetVisibility={toggleWidgetVisibility}
					onStatusChange={(notification, forceRead) =>
						onStatusChange(notification, (c) => _setNotifications('later', c), TYPES.older, forceRead)
					}
				/>
				<div className='flex flex-1 border-b-2 border-gray-300 mt-4' />
				<div className='flex items-center justify-center mt-4 mb-4'>{renderFooter()}</div>
			</div>
		);
	};

	const renderFooter = () => {
		if (loading) {
			return renderLoading;
		}

		return <NoData icon={'flag-line'} message={"That's all of your notifications from the last days."} />;
	};

	return (
		<div>
			<div className={'pt-4 px-4'}>
				<p className={'text-lg mb-6 block'}>Notifications</p>
				<Tabs
					active={activeTab}
					tabs={[
						{ title: 'Working Genius Team', unread: unreadGlobal },
						{ title: 'Assessments', unread: unreadLocal },
					]}
					onClick={setActiveTab}
				/>
			</div>

			<div className='scrollable-content'>{renderContent()}</div>
		</div>
	);
};

export default Notifications;
