import React from 'react';
import { navigate } from 'gatsby';
import { CartDetailsView } from '@ttg/ui-checkout-lib';

import ShoppingCartDetailsLayout from '../../components/shopping-cart-details/ShoppingCartLayout';
import { useUser } from '../../hooks/UserProvider';

const ShoppingCartDetails = () => {
	const { user, hasPermission } = useUser();
	const onContinueShopping = () => (window.location.href = '/store');
	const onCheckoutClick = () => (window.location.href = '/checkout-view');

	if (!hasPermission('CHECKOUT')) {
		navigate('/not-authorized');
		return null;
	}

	return (
		<ShoppingCartDetailsLayout>
			<CartDetailsView
				userId={user?.id || undefined}
				onCheckoutClick={onCheckoutClick}
				onContinueShopping={onContinueShopping}
			/>
		</ShoppingCartDetailsLayout>
	);
};

export default ShoppingCartDetails;
