import React from 'react';
import AppIcon from '../AppIcon';
import BuilderPersonRow from './BuilderPersonRow';
import TeammapService from '../../services/TeammapService';
import AppRIIcon from '../AppRIIcon';

const BuilderGroupRow = (props) => {
	const [showAll, setShowAll] = React.useState(true);
	const allAdded = TeammapService.areGroupMembersAdded(props.members, props.selectedMembers);

	const handleToggle = () => {
		setShowAll(!showAll);
	};

	const handleAddGroup = () => {
		props.handleGroupAdd(props.assmentID);
		setShowAll(true);
	};

	const groupMembers = props.members ? props.members : [];

	const people = groupMembers.map((person, idx) => {
		return (
			<BuilderPersonRow
				assessment={props.assmentID}
				handleSelect={props.handleSelect}
				selectedMembers={props.selectedMembers}
				key={idx}
				group={true}
				type='group'
				{...person}
			/>
		);
	});

	const showMembers = showAll ? (
		<tr>
			<td className='BuilderTable__row__groupPerson'>
				<tr className='BuilderTable__row__groupPerson__layout'>{people}</tr>
			</td>
		</tr>
	) : (
		''
	);

	const addButton = (
		<button
			onClick={() => handleAddGroup()}
			className='BuilderTable__assessmentInfo__controlBtn Button btn-link'
			id={'wg-teammap-group-add-btn'}
		>
			<span className='ToolBar__Tools'>
				Add <AppIcon icon='right' />
			</span>
		</button>
	);

	return (
		<>
			<tr className='BuilderTable__row__group fade-in'>
				<td className='BuilderTable__row BuilderTable__row--grouprow'>
					<div className='flex flex-row items-center justify-between ellipse-text'>
						<p
							id={'wg-teammap-name'}
							className={`BuilderTable__assessmentInfo__assmtName icon__before--group ellipse-text flex items-center ${
								allAdded ? 'allAdded' : ''
							}`}
						>
							<AppRIIcon className={'text-3xl text-secondary justify-center'} icon={'team-fill'} />
							{props.assessmentName}
						</p>

						{!allAdded ? addButton : ''}

						<button
							onClick={() => handleToggle()}
							className='BuilderTable__assessmentIno__controlBtn expand-btn px-0'
							id={'wg-teammap-toggle-btn'}
						>
							<span className='BuilderTable__row__icon'>
								{showAll ? (
									<AppIcon icon='down-open' className={'text-2xl text-secondary'} />
								) : (
									<AppIcon icon='right-open' className={'text-2xl text-secondary'} />
								)}
							</span>
						</button>
					</div>
				</td>
			</tr>

			{showMembers}
		</>
	);
};

export default BuilderGroupRow;
