import React from 'react';
import { book_3d, book_amazon, book_bam, book_barnes } from '../../images';

const LaunchEventBook = () => {
	return (
		<div className='launch-event-book-section' id='wg-launch-event-book-section-id'>
			<div className='container'>
				<div className='flex flex-col lg:flex-row items-center justify-center xl:w-2/3 mx-auto'>
					<img src={book_3d} alt='Book' width='250' className='launch-event-book-img' />
					<div className='launch-event-book-text px-4'>
						<p className='text-primary text-2xl font-normal'>
							Pat's latest fable tells the story of Bull Brooks, an entrepreneur, husband, and father who
							sets out to solve his own frustration at work and stumbles into a powerful new way of
							thinking.
						</p>
						<div className='flex flex-col justify-center items-start lg:items-center py-8'>
							<h4 className='text-base text-primary font-semibold uppercase'>Pre-order the book</h4>
							<div className='book-links'>
								<a
									href='https://www.amazon.com/Types-Working-Genius-Understand-Frustrations/dp/1637743297'
									className='book-link'
									target='_blank'
								>
									<img src={book_amazon} alt='Amazon' />
								</a>
								<a
									href='https://www.booksamillion.com/p/Types-Working-Genius/Patrick-M-Lencioni/9781637743294?id=7130420786913'
									className='book-link'
									target='_blank'
								>
									<img src={book_bam} alt='Bam' />
								</a>
								<a
									href='https://www.barnesandnoble.com/w/the-6-types-of-working-genius-patrick-m-lencioni/1141358162'
									className='book-link'
									target='_blank'
								>
									<img src={book_barnes} alt='BarnesNoble' />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LaunchEventBook;
