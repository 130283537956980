import React from 'react';
import { useForm } from 'react-hook-form';
import AppButton from '../AppButton';
import AppInput from '../AppInput';
import AppSlideOver from '../AppSlideOver';
import { clipboard } from '../../images';
import CustomerService from '../../services/CustomerService';
import AssessmentsService from '../../services/AssessmentsService';
import NotificationService from '../../services/NotificationService';
import { emailRegex } from '../../services/UtilityService';
import BulkShareDetails from './BulkShareDetails';
import { useUser } from '../../hooks/UserProvider';

const BulkShareAssessments = ({ isOpen, onClose, assessment, selectedItems = [] }) => {
    const { user } = useUser();
    const [loading, setLoading] = React.useState(false);
    const [isOpenDetails, setIsOpenDetails] = React.useState(false);
    const [email, setEmail] = React.useState(null);
    const [groupedAssessments, setGroupedAssessments] = React.useState(null);
    const [rosterIdsToShare, setRosterIdsToShare] = React.useState([]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        reset,
    } = useForm();

    const groupAssessments = React.useCallback((selectedAssessments, email) => {
        const assessmentsAllowedToShare = [];
        const assessmentsNotAllowedToShare = [];
        const assessmentsAlreadyShared = [];

        selectedAssessments.forEach((assessment) => {
            if (assessment.shared_to?.includes(email)) {
                assessmentsAlreadyShared.push(assessment.email);
            } else {
                setRosterIdsToShare((prevRosterIds) => [...prevRosterIds, assessment.id]);
                if (assessment.allow_share || assessment.email === user.customers_email_address) {
                    assessmentsAllowedToShare.push(assessment.email);
                } else {
                    assessmentsNotAllowedToShare.push(assessment.email);
                }
            }
        });

        return { assessmentsAllowedToShare, assessmentsNotAllowedToShare, assessmentsAlreadyShared };
    }, []);

    const onBulkShareAssessment = (payload) => {
        setLoading(true);
        AssessmentsService.bulkShareAssessment(payload)
            .then(() => {
                NotificationService.success(`Success!`);
            })
            .catch((error) => {
                NotificationService.error(error.message, null, { duration: Infinity });
            })
            .finally(() => {
                onClose();
                setIsOpenDetails(false);
                setLoading(false);
            });
    };

    React.useEffect(() => {
        if (isOpen) {
            reset();
            setGroupedAssessments(null);
            setEmail(null);
            setLoading(false);
            setRosterIdsToShare([]);
        }
    }, [isOpen, reset]);

    const onSubmit = ({ email }) => {
        setLoading(true);
        CustomerService.verifyEmail(email)
            .then((exists) => {
                if (exists) {
                    setIsOpenDetails(exists);
                    const grouped = groupAssessments(selectedItems, email);
                    setGroupedAssessments(grouped);
                    setEmail(email);
                } else
                    setError('email', {
                        type: 'validate',
                        message: `this email does not have an account.`,
                    });
            })
            .catch((e) => NotificationService.error('Error', e.message))
            .finally(() => setLoading(false));
    };
    return (
        <AppSlideOver isOpen={isOpen} closeModal={onClose}>
            <div className='share-assessment-slide-over'>
                <div className='share-assessment-info'>
                    <p className='share-assessment-heading'>Share Assessments</p>
                    <p className='share-assessment-text'>
                        To share a copy of your assessments' results with another Working Genius user, please enter
                        their email below. The reports will remain in your console and a copy will be sent to their
                        console as well.
                    </p>
                </div>

                <div className='assessment-info flex flex-col items-center'>
                    <img className={'object-contain mb-4'} width='40' src={clipboard} alt='' />
                    <p className='share-assessment-heading text-base'>{assessment.group}</p>
                    <p className='share-assessment-text'>Group Assessment</p>
                </div>
                <hr className='slide-over-break' />

                <p className='share-assessment-heading mt-6'>Share Selected Assessments With</p>
                <form id={'wg-bulk-share-form'} onSubmit={handleSubmit(onSubmit)}>
                    <AppInput
                        {...register(`email`, {
                            required: true,
                            pattern: {
                                value: emailRegex,
                                message: 'should be a valid email',
                            },
                            maxLength: 96,
                        })}
                        placeholder={'Please enter email address'}
                        errors={errors}
                    />
                </form>
                <hr className='slide-over-break' />

                <div className='flex justify-end my-4'>
                    <AppButton
                        type={'submit'}
                        text={'Share assessment'}
                        className={'btn btn-primary btn-share-assessment w-full'}
                        id={'wg-share-assessment-with-share-btn'}
                        form={'wg-bulk-share-form'}
                        disabled={loading}
                    />
                    <AppButton
                        type={'button'}
                        className={'btn btn-secondary ml-2'}
                        onClick={onClose}
                        text={'Cancel'}
                        id={'wg-share-assessment-with-cancel-btn'}
                    />
                </div>
            </div>
            <BulkShareDetails
                isOpen={isOpenDetails}
                onClose={() => setIsOpenDetails(false)}
                loading={loading}
                selectedAssessments={groupedAssessments}
                intro={`You are sharing the selected reports from <b>${assessment.group}</b> group to <b>${email}</b>.`}
                onShare={() => onBulkShareAssessment({ assessment_id: assessment.id, email: email, roster_ids: rosterIdsToShare })}
            />
        </AppSlideOver>
    );
};

export default BulkShareAssessments;
