import React from 'react';
import AppIcon from './AppIcon';
import AppRIIcon from './AppRIIcon';
import AppTooltip from './AppTooltip';

const AppButton = ({
	text,
	tooltipText,
	tooltipWrapperClass,
	remixIcon = false,
	icon,
	iconClass,
	iconLeft = false,
	loading = false,
	className = 'btn btn-primary',
	...props
}) => {
	const _onClick = (e) => {
		if (loading || props.disabled) {
			if (e) {
				e.preventDefault();
			}
		} else if (props.onClick) {
			props.onClick(e);
		}
	};

	const iconElement = icon && (remixIcon ? (
		<AppRIIcon icon={icon} className={iconClass} />
	) : (
		<AppIcon icon={icon} className={iconClass} />
	));

	const textElement = <span className={loading ? 'invisible' : 'visible'} style={{ verticalAlign: 'middle' }}>{text}</span>;

	const renderOrder = iconLeft ? [iconElement, textElement] : [textElement, iconElement];

	const renderButton = () => {
		return (
			<button {...props} className={className} onClick={_onClick} disabled={props.disabled || loading}>
				<div className={'relative'}>
					{renderOrder}
					{loading && (
						<p className='absolute top-0 left-0 right-0 bottom-0'>
							<AppIcon icon={'loading'} className={'animate-spin'} />
						</p>
					)}
				</div>
			</button>
		);
	};

	if (!tooltipText) {
		return renderButton();
	}

	return (
		<div className={tooltipWrapperClass}>
			<AppTooltip
				content={() => <p className={'text-white text-center text-12 font-normal'}>{tooltipText}</p>}
				type={'small'}
			>
				{renderButton()}
			</AppTooltip>
		</div>
	);
};

export default AppButton;
