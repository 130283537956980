import React from 'react';
import AppButton from '../AppButton';
import { book_cover, patrick_plain, spread_icon } from '../../images';
import RegisterForm from './RegisterForm';
import UtilityService from '../../services/UtilityService';

const LaunchEventPat = () => {
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);

	return (
		<div className='book-hero'>
			<div className='container'>
				<div className='book-hero-content'>
					<div className='book-hero-cover flex flex-col items-center'>
						<img src={book_cover} alt='' width='250' />
					</div>
					<div className='book-hero-text text-center py-10'>
						<h2 className='text-xl md:text-2xl text-primary font-normal'>
							Too many people are frustrated <br />
							and unfulfilled in their work.
						</h2>
						<h2 className='text-xl md:text-2xl text-primary font-normal'>
							Working Genius is changing that.
						</h2>
						<img src={spread_icon} width='50' className='my-8 mx-auto' />
						<h2 className='text-lg text-primary font-semibold uppercase'>Join us to find out how</h2>
						<h2 className='text-lg text-primary font-semibold uppercase'>
							Tuesday, september 27th 12pm pt / 3pm et
						</h2>
						<div className='flex flex-col lg:flex-row items-center justify-center md:justify-around mt-8'>
							<AppButton
								onClick={() => setOpenRegisterModal(true)}
								className={'btn btn-primary mr-0 lg:mr-2'}
								text={'Register now'}
								id={'wg-register-now-pat-btn'}
							/>
							<a
								href='#wg-launch-event-book-section-id'
								className='btn btn-secondary mt-4 lg:mt-0'
								id={'wg-pre-order-book-pat-btn'}
							>
								Order the book
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='book-hero-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default LaunchEventPat;
