import React from 'react';
import AppButton from '../../AppButton';
import AppIcon from '../../AppIcon';
import AppModal from '../../AppModal';
import { customStyles } from '../../../styles/modal';

const SelectStudentTypeModal = ({ isOpen, closeModal, onTypeSelected, licenses }) => {
    return (
        <AppModal
            id={'app-select-student-type-modal-id'}
            isOpen={isOpen}
            style={customStyles}
            onRequestClose={closeModal}
        >
            <div className={'bg-white p-6'} style={{ maxWidth: '600px' }}>
                <div className='absolute right-0 top-0 mt-4 mr-4'>
                    <AppIcon
                        icon={'cancel'}
                        className={'text-gray-400 text-xl cursor-pointer hover:text-secondary'}
                        onClick={closeModal}
                    />
                </div>
                <h1 className={'text-gray-800 text-lg leading-6 font-medium pb-2'}>
                    Send student assessment to others
                </h1>
                <p className={'text-gray-500 text-sm leading-5 font-normal mb-6'}>
                    Choose if you want to send the student assessment to a group or individual.
                </p>
                <div className='flex flex-col md:flex-row gap-6'>
                    <div className='assessment-type-tile' style={{ padding: '1rem 0.5rem' }}>
                        <AppIcon icon='student-group' className='text-3xl text-student' />
                        <p className='description' style={{ fontSize: '14px', margin: '0.5rem 0' }}>
                            Send an assessment to multiple students at one time.
                        </p>
                        <AppButton
                            text={'Student group'}
                            onClick={() => onTypeSelected('group')}
                            className={'btn btn-primary w-full mt-auto'}
                            disabled={licenses < 2}
                            tooltipText={licenses < 2 ? 'You need a minimum of 2 licenses in your account to activate this option.' : undefined}
                            tooltipWrapperClass={'w-full mt-auto'}
                        />
                    </div>
                    <div className='assessment-type-tile' style={{ padding: '1rem 0.5rem' }}>
                        <AppIcon icon='student' className='text-3xl text-student' />
                        <p className='description' style={{ fontSize: '14px', margin: '0.5rem 0' }}>
                            Send an assessment to one student at a time.
                        </p>
                        <AppButton
                            text={'Student individual'}
                            onClick={() => onTypeSelected('individual')}
                            className={'btn btn-primary w-full mt-auto'}
                        />
                    </div>
                </div>
            </div>
        </AppModal>
    );
};

export default SelectStudentTypeModal;
