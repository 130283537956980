import React from 'react';
import {
	report_team_map_analyze,
	report_team_map_analyze_es,
	team_map_analyze,
	team_map_large_report_arrow,
	team_map_small_report_arrow,
} from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const TeamMapAnalyze = () => {
	return (
		<div className='Application'>
			<PageHeader title={'APLICACIÓN'} />
			<div className={'container mt-12'}>
				<PageTitle title={'Análisis de un Mapa de equipo'} />

				<p className={'mt-5 text-12 leading-snug'}>Estas son algunas preguntas con las que pueden comenzar:</p>

				<ol className={'list-decimal ml-4'}>
					<li className={'pl-2'}>¿Cuáles son las fortalezas de nuestro equipo?</li>
					<li className={'pl-2'}>¿Cuáles son las brechas de nuestro equipo?</li>
					<li className={'pl-2'}>
						¿Cómo explica este mapa los fracasos o éxitos que hemos tenido como equipo en el pasado?
					</li>
					<li className={'pl-2'}>
						¿Hay algo que debamos ajustar o reconsiderar sobre nuestro próximo proyecto o prioridad, para
						maximizar nuestra efectividad?
					</li>
				</ol>

				<div className={'grid grid-cols-8 mt-6 gap-4 -mx-4'}>
					<div className={'col-span-2 explanation-container'}>
						<p>
							Como solo un miembro es sobresaliente en la genialidad del Pensamiento y para tres personas
							es su área de frustración, este equipo debe asegurarse de que está tomándose el tiempo
							suficiente para hacer las preguntas correctas e identificar los problemas correctos antes de
							pasar a la acción.
						</p>
						<p>
							Este equipo debería preguntarse:
							<ul>
								<li>¿Estamos aprovechando El don de Yasmine para nuestros proyectos e iniciativas?</li>
								<li>¿Realmente estamos buscando la solución al problema correcto?</li>
							</ul>
						</p>
						<div className={'report-arrow left'}>
							<img src={team_map_small_report_arrow} alt='' />
						</div>
					</div>
					<div className={'col-span-4'}>
						<img src={report_team_map_analyze_es} alt='' className={'shadow-md mb-8'} />
					</div>
					<div className={'col-span-2 explanation-container'}>
						<p>
							Este equipo tiene una fuerte representación en la genialidad de la Influencia. Ellos saben
							cómo elevar la energía y crear ímpetu, pero necesitan tener cuidado para no reunir a los
							demás en torno a las iniciativas equivocadas.
						</p>
						<p>
							Este equipo debería preguntarse:
							<ul>
								<li>
									En el pasado, ¿hemos influenciado ideas que no habíamos discernido por completo?
								</li>
								<li>
									¿Seguimos generando entusiasmo en torno a las mismas ideas e iniciativas sin
									tomarnos el tiempo para pensar o crear?
								</li>
								<li>
									¿Cómo nos aseguramos de que estamos enfocando nuestro don de la Influencia solamente
									en torno a nuestras iniciativas más importantes?
								</li>
							</ul>
						</p>
						<div className={'report-arrow right'}>
							<img src={team_map_small_report_arrow} alt='' />
						</div>
					</div>
				</div>
				<div className='mx-12 mt-4'>
					<div className='explanation-container'>
						<p>
							Este equipo carece de Discernimiento y puede tener problemas para evaluar, refinar o elegir
							las ideas correctas.
						</p>
						<p>
							Este equipo debería preguntarse:
							<ul>
								<li>
									¿Cómo explica esta falta de Discernimiento nuestras frustraciones o fracasos
									pasados?
								</li>
								<li>
									¿Cómo podemos asegurarnos de aprobar las ideas e iniciativas correctas antes de
									comenzar a implementarlas?
								</li>
								<li>
									¿Podemos contratar o tomar prestado a alguien con esta genialidad para que nos ayude
									a tomar decisiones?
								</li>
							</ul>
						</p>
						<div className={'report-arrow large'}>
							<img src={team_map_large_report_arrow} alt='' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TeamMapAnalyze;
