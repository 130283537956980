import React, { useMemo } from 'react';
import debounce from 'lodash.debounce';
import BuilderTable from './BuilderTable';
import TeammapService from '../../services/TeammapService';

const ResultsetAll = (props) => {
	const [query, setQuery] = React.useState('');

	const changeHandler = (value) => {
		getTeamMapData(value);
	};

	const debouncedChangeHandler = useMemo(() => {
		return debounce(changeHandler, 300);
	}, []);

	const getTeamMapData = (query) => {
		TeammapService.all({
			search: query,
		}).then(({ _resultsetIDs, _resultsetInfo, _meta }) => {
			props.setResultsetIDs(_resultsetIDs);
			props.setResultsetInfo(_resultsetInfo);
			props.setMeta(_meta);
		});
	};

	const onInputChange = (event) => {
		setQuery(event.target.value);
		debouncedChangeHandler(event.target.value);
	};

	return (
		<div className='ResultsetAll'>
			<h2 className='tabletitle'>Select Assessments to Add to Team Map</h2>
			<div>
				<div className='builder-input RawHTML mx-4 mt-4'>
					<input
						className='InputText'
						type='text'
						name='search'
						id='myInput'
						onChange={onInputChange}
						placeholder='Search'
						value={query}
					/>
					{!!query && (
						<a
							className='text-secondary font-semibold underline my-2 hover:text-primary cursor-pointer float-right'
							onClick={() => {
								setQuery('');
								getTeamMapData('');
							}}
							id={'wg-teammap-search-clear-results-btn'}
						>
							Clear Results
						</a>
					)}
				</div>
			</div>
			<BuilderTable
				handleGroupAdd={props.handleGroupAdd}
				handleSelect={props.handleSelect}
				resultIds={props.resultIds}
				resultInfo={props.resultInfo}
				selectedMembers={props.selectedMembers}
				loadMore={() => props.loadMore(query)}
				loadingMore={props.loadingMore}
				hasNextPage={props.hasNextPage}
			/>
		</div>
	);
};

export default ResultsetAll;
