import React, { useState } from 'react';
import AppButton from '../AppButton';
import RegisterForm from './RegisterForm';
import { webinar_teammap, patrick_plain, spread_icon } from '../../images';

const WebinarPat = () => {
	const [openRegisterModal, setOpenRegisterModal] = useState(false);

	return (
		<div className='book-hero'>
			<div className='container'>
				<div className='book-hero-content'>
					<div className='book-hero-cover flex flex-col items-center'>
						<img src={webinar_teammap} alt='Team Map' width='250' />
					</div>
					<div className='book-hero-text text-center py-10 lg:w-4/10'>
						<h2 className='text-base md:text-xl text-primary font-normal'>
							Hear Patrick Lencioni explain how his <br />
							newest model, The Six Types of Working <br />
							Genius, can fix frustration with teams,
							<br />
							meetings, and productivity.
						</h2>
						<img src={spread_icon} width='50' className='my-8 mx-auto' />
						<h2 className='text-lg text-primary font-semibold uppercase'>Join us</h2>
						<h2 className='text-lg text-primary font-semibold uppercase'>
							Wednesday, January 25th from 11am-12pm PT
						</h2>
						<div className='flex items-center justify-center mt-8'>
							<AppButton
								onClick={() => setOpenRegisterModal(true)}
								className={'btn btn-primary'}
								text={'Register now'}
								id={'wg-webinar-register-now-pat-btn'}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='book-hero-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default WebinarPat;
