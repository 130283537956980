import React from 'react';
import { ttg_powered_logo, ttg_logo_white } from '../images';

const AppFooter = ({ theme = 'light' }) => {
	return (
		<footer className={`page-footer ${theme}`}>
			<div className='client-footer'>
				<a href='https://www.tablegroup.com/' target='_blank' rel='noreferrer'>
					<img src={theme === 'light' ? ttg_powered_logo : ttg_logo_white} alt='The Table Group' />
				</a>
			</div>
		</footer>
	);
};

export default AppFooter;
