import React from 'react';
import AppIcon from './AppIcon';
import AppTooltip from './AppTooltip';

const AppCopyToClipboard = ({ value, ...props }) => {
	const toCopyRef = React.useRef();
	const [copied, setCopied] = React.useState(false);

	const copyText = () => {
		setCopied(true);
		toCopyRef.current.select();
		document.execCommand('copy');
	};

	return (
		<div className={'flex flex-row cursor-pointer'} onClick={copyText}>
			<div className={'flex-1'}>
				<AppTooltip
					type={'small'}
					placement={'bottom-start'}
					content={() => {
						const text = copied ? 'Successfully copied to clipboard' : 'Copy to clipboard';
						return <p className={'text-white text-tiny'}>{text}</p>;
					}}
					wrapperClass={'flex flex-row items-center'}
				>
					<AppIcon icon={copied ? 'ok-circled' : 'copy-paste'} />
					<input
						ref={toCopyRef}
						className={'w-full cursor-pointer focus:outline-none font-semibold'}
						type='text'
						value={value}
						onChange={(e) => e.preventDefault()}
						{...props}
					/>
				</AppTooltip>
			</div>
		</div>
	);
};

export default AppCopyToClipboard;
