import React from 'react';
import { useForm } from 'react-hook-form';
import AppButton from '../AppButton';
import AppCheckBox from '../AppCheckBox';
import AppInput from '../AppInput';
import AppModal from '../AppModal';
import AppRIIcon from '../AppRIIcon';
import { widget_launch_background } from '../../images';
import { emailRegex, phoneNumberRegex } from '../../services/UtilityService';
import NotificationService from '../../services/NotificationService';
import WebinarService from '../../services/WebinarService';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
    },
};

const RegisterForm = ({ isOpen, onClose }) => {
    const [loading, setLoading] = React.useState(false);
    const [registered, setRegistered] = React.useState(false);
    const [selected, setSelected] = React.useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    React.useEffect(() => {
        if (!isOpen) {
            reset();
            setLoading(false);
            setSelected(false);
            setTimeout(() => {
                setRegistered(false);
            }, 500);
        }
    }, [isOpen]);

    const _save = (payload) => {
        setLoading(true);
        WebinarService.register({ ...payload, type: 'AtTheWhiteBoard' }).then(
            () => {
                setRegistered(true);
                setLoading(false);
            },
            (error) => {
                NotificationService.error(error.message);
                setLoading(false);
            }
        );
    };

    const renderContent = () => {
        if (registered) {
            return (
                <div className='flex flex-col text-center md:mt-16 p-4'>
                    <h1 className={'text-xl md:text-4xl text-primary font-normal'}>
                        Thank you for registering. <br className='hidden lg:block' />
                        Looking forward to seeing <br className='hidden lg:block' />
                        you on November 8th, 2023!
                    </h1>
                    <p className={'text-lg text-primary font-semibold my-4 md:my-10'}>Check your email for details.</p>
                </div>
            );
        }
        return (
            <div>
                <h1 className={'text-2xl md:text-4xl text-primary font-normal'}>Register Now</h1>

                <form className={'flex flex-1 flex-col mt-4 workshop-form'} onSubmit={handleSubmit(_save)}>
                    <div className='flex flex-col md:flex-row md:gap-x-2'>
                        <div className='flex flex-1'>
                            <AppInput
                                {...register('first_name', {
                                    required: true,
                                })}
                                errors={errors}
                                placeholder={'First Name'}
                                containerClassName={'mb-2 mr-2 w-1/2'}
                            />
                            <AppInput
                                {...register('last_name', {
                                    required: true,
                                })}
                                errors={errors}
                                placeholder={'Last Name'}
                                containerClassName={'mb-2 w-1/2'}
                            />
                        </div>
                    </div>
                    <div className='flex flex-1'>
                        <AppInput
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: emailRegex,
                                    message: 'should be a valid email',
                                },
                                maxLength: 96,
                            })}
                            className={''}
                            errors={errors}
                            placeholder={'Email'}
                            containerClassName={'mb-2 w-full'}
                        />
                    </div>
                    <div className='flex flex-1'>
                        <AppInput
                            {...register('phone', {
                                required: selected,
                                maxLength: 32,
                                pattern: {
                                    value: phoneNumberRegex,
                                },
                            })}
                            placeholder={'Phone number'}
                            errors={errors}
                            containerClassName={'mb-2 w-full'}
                            label={" "}
                            labelTooltip={"This applies to US phone numbers only."}
                        />
                    </div>
                    <div className='flex flex-1 mt-4'>
                        <AppCheckBox
                            {...register('reminders')}
                            errors={errors}
                            label={() => {
                                return (
                                    <p className={'text-primary'}>
                                        I would like to receive text reminders for the event
                                    </p>
                                );
                            }}
                            onChange={(e) => {
                                setSelected(!selected);
                            }}
                        />
                    </div>
                    <div className={'flex justify-end my-4'}>
                        <AppButton text={'Submit'} loading={loading} />
                    </div>
                </form>
            </div>
        );
    };

    return (
        <AppModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
            <div
                className={'flex items-center justify-center relative p-12'}
                style={{
                    maxWidth: '1000px',
                    backgroundImage: `url(${widget_launch_background})`,
                    border: '1px solid #263860',
                }}
            >
                <button onClick={onClose} className={'absolute right-0 top-0 mr-1 mt-1'}>
                    <AppRIIcon icon='close-fill' className='text-2xl text-primary' />
                </button>
                <div className='flex items-center justify-center flex-col p-4 bg-white md:mx-16 shadow-xl'>
                    {renderContent()}
                </div>
            </div>
        </AppModal>
    );
};

export default RegisterForm;
