import React from 'react';

import Event6Types from '../../components/event/Event6Types';
import EventHero from '../../components/fixyourfrustration/EventHero';
import EventPat from '../../components/event/EventPat';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import ApiService from '../../services/ApiService';

const Event = () => {
	const [streamHTML, setStreamHTML] = React.useState(' ');

	const getStreamLinks = React.useCallback(() => {
		ApiService.get('/v1/fyf/live').then(({ data }) => {
			setStreamHTML(data?.url?.live);
		});
	}, []);

	React.useEffect(() => {
		getStreamLinks();
	}, []);

	return (
		<WorkshopLayout
			title='The 6 Types of Working Genius: Exclusive Webinar'
			workshopItem={false}
			footerWorkshopItem={true}
		>
			<EventHero streamHTML={streamHTML} />
			<EventPat />
			<Event6Types />
		</WorkshopLayout>
	);
};

export default Event;
