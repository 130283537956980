import React from 'react';
import { wg_logo } from '../../../images';

const TeamMapHeader = () => {
	return (
		<div className='PageHeader'>
			<img width='256' src={wg_logo} />
		</div>
	);
};

export default TeamMapHeader;
