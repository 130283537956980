import ApiService from './ApiService';

const list = () => {
    return ApiService.get(`v1/user/permissions`).then(({ data }) => {
        return data;
    });
};

const update = (id, payload) => {
    return ApiService.put(`v1/user/permission/${id}`, payload).then(({ data }) => {
        return data;
    });
};

const deletePermission = (id) => ApiService.delete(`/v1/user/permission/${id}`).then((response) => response.data);

const requestPermission = (payload) => {
    return ApiService.post(`v1/user/permission/request`, payload).then(({ data }) => {
        return data;
    });
};

const deleteRequest = (payload) => {
    return ApiService.delete(`v1/user/permission/request`, { data: payload }).then(({ data }) => {
        return data;
    });
};

const verifyPermission = (payload) => {
    return ApiService.put(`v1/user/permission/request`, payload).then(({ data }) => {
        return data;
    });
};

const getPermissionsAccess = () => {
    return ApiService.get(`v1/user/permissions/access`).then(({ data }) => {
        return data;
    });
};

export default {
    list,
    update,
    deletePermission,
    requestPermission,
    deleteRequest,
    verifyPermission,
    getPermissionsAccess,
};
