import React, { useEffect } from 'react';

import { useLocation } from '@reach/router';
import { getQueryParams } from '../../../../services/UtilityService';
import AppButton from '../../../../components/AppButton';
import Layout from '../../../../components/Layout';
import Error from '../../../../components/assessments/Error';
import RequestAssessment from '../../../../services/RequestAssessment';
import NotificationService from '../../../../services/NotificationService';
import { navigate } from 'gatsby';
import ReCaptcha from '../../../../components/ReCaptcha';

const RequestAssessmentShareVerification = () => {
	const [loadingResponse, setLoadingResponse] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [error, setError] = React.useState('');
	const [success, setSuccess] = React.useState(false);

	const location = useLocation();
	const { type, token = '', bulk, redirect = '' } = getQueryParams(decodeURI(location.search));
	const captchaRef = React.useRef('captcha');

	useEffect(() => {
		verifyTokenIntegrity();
	}, []);

	const verifyTokenIntegrity = async () => {
		RequestAssessment.verifyRequestToken(token)
			.then(() => {
				setLoading(false);
			})
			.catch((e) => {
				setError(e.message);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleAccept = async () => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoadingResponse(true);
		RequestAssessment.acceptRequestShare(token, !!bulk, recaptchaValue)
			.then(() => {
				setLoadingResponse(false);
				setSuccess(true);
				NotificationService.success('Success!', 'Assessment shared successfully.');
			})
			.catch((e) => {
				setError(e.message);
			})
			.finally(() => {
				setLoadingResponse(false);

				if(redirect){
					navigate(redirect)
				}
			});
	};

	const handleDecline = async () => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoadingResponse(true);
		RequestAssessment.declineRequestShare(token, !!bulk, recaptchaValue)
			.then(() => {
				setLoadingResponse(false);
				setSuccess(true);
				NotificationService.success('Success!', 'Assessment share declined!');
			})
			.catch((e) => {
				setError(e.message);
			})
			.finally(() => {
				setLoadingResponse(false);
			});
	};

	const renderHeader = (type) => {
		if (type === 'accept')
			return (
				<div>
					<h1 className={'text-xl text-primary font-semibold mb-6'}>Confirm allow share request</h1>
					<p className={'text-primary mb-6'}>Please confirm your allow share request</p>
				</div>
			);

		if (type === 'decline')
			return (
				<div>
					<h1 className={'text-xl text-primary font-semibold mb-6'}>Decline share request</h1>
					<p className={'text-primary mb-6'}>Click the button to decline request to share</p>
				</div>
			);
	};

	const renderContent = () => {
		if (loading) {
			return (
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			);
		}

		if (success)
			return (
				<div className={'flex flex-col items-center container mt-8'}>
					<p className={'text-xl md:text-lg text-primary mb-6'}>Thank you for your response.</p>
					<AppButton
						text={'Go to Account'}
						onClick={() => navigate('/client')}
						className={'btn btn-primary w-56 mt-4'}
					/>
				</div>
			);

		if (error) return <Error message={error} />;

		return (
			<div className={'mt-8 text-center'}>
				{renderHeader(type)}
				<div className='flex items-center justify-center'>
					{type === 'accept' && (
						<AppButton
							onClick={handleAccept}
							className={'btn btn-primary'}
							text={'Allow to share'}
							id={'wg-request-share-allow-btn'}
							disabled={loadingResponse}
						/>
					)}
					{type === 'decline' && (
						<AppButton
							onClick={handleDecline}
							className={'btn btn-primary ml-2'}
							text={'Deny'}
							disabled={loadingResponse}
						/>
					)}
					<ReCaptcha ref={captchaRef} />
				</div>
			</div>
		);
	};

	return <Layout>{renderContent()}</Layout>;
};

export default RequestAssessmentShareVerification;
