import React from 'react';
import { ebook, new_ttg_logo_white } from '../../images';
import AppButton from '../AppButton';
import { addToCart } from '../../services/CustomerService';

const HomeHero = () => {
	const items = ['work.', 'team.', 'life.'];
	const [index, setIndex] = React.useState(0);
	const itemRef = React.useRef();

	const shuffle = () => {
		const newIndex = (index + 1) % 3;
		setIndex(newIndex);
		itemRef.current.animate(
			{
				opacity: [0, 1, 0],
			},
			3000
		);
	};

	React.useEffect(() => {
		const intervalID = setInterval(shuffle, 3000);
		return () => clearInterval(intervalID);
	}, [shuffle]);

	return (
		<div className='hero-section' id='home-hero'>
			<div className='container'>
				<div className='home-hero-content'>
					<img src={ebook} alt='The Working Genius eBook' width='400' />
					<div className='flex flex-col justify-center items-center md:items-start gap-6 md:mb-3'>
						<h1 className='text-2xl lg:text-5xl text-primary font-normal'>
							Discover your gifts. <br />
							Transform your{' '}
							<span ref={itemRef} style={{ opacity: 1 }} className='hero-changing-word'>
								{items[index]}
							</span>
						</h1>
						<div className='grid grid-cols-2 md:gap-12 gap-6 md:ml-2 justify-center items-center'>
							<ul className='list-disc flex flex-col gap-2 text-primary font-medium md:text-base text-xs'>
								<li>Maximize Joy & Fulfillment</li>
								<li>Increase Productivity</li>
								<li>Build Effective Teams</li>
							</ul>
							<ul className='list-disc flex flex-col gap-2 text-primary font-medium md:text-base text-xs'>
								<li>Lead Better Meetings</li>
								<li>Improve Hiring</li>
								<li>Boost Morale</li>
							</ul>
						</div>
						<div className='flex flex-col lg:flex-row'>
							<AppButton
								id={'wg-home-hero-buy-assessment-btn'}
								className={'btn btn-primary m-2'}
								text={'Buy the Assessment Now'}
								onClick={() => {
									addToCart('add_license', true);
								}}
								style={{ width: '280px' }}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='flex flex-col bg-secondary items-center justify-center text-center md:py-8 p-4 w-full mt-10 md:gap-3 gap-1'>
				<h2 className='text-lg md:text-3xl text-white self-center'>
					The fastest growing assessment for building better teams.
				</h2>
				<img src={new_ttg_logo_white} alt='The Table Group' width='200' />
			</div>
		</div>
	);
};

export default HomeHero;
