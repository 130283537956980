import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import AppButton from '../components/AppButton';
import AppRIIcon from '../components/AppRIIcon';

const NotAuthorizedPage = () => {
    return (
        <Layout title={'File Not Authorized | The 6 Types of Working Genius'}>
            <div className='flex flex-col items-center justify-center'>
                <AppRIIcon
                    icon='alert-line'
                    className='flex items-center justify-center bg-red-100 text-red-600 text-2xl w-16 h-16 rounded-full my-4'
                />
                <h2 className='text-2xl md:text-3xl leading-9 text-gray-800 font-semibold mb-4'>Page Not Authorized</h2>
                <p className='text-lg text-gray-600 font-normal mb-4'>You are not authorized to view this page</p>
                <AppButton
                    className={'btn btn-primary'}
                    text={'Go back to Assessments'}
                    onClick={() => navigate('/client')}
                />
            </div>
        </Layout>
    );
};

export default NotAuthorizedPage;
