import React from 'react';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import UsingAdminConsole from '../../components/using-admin-console/UsingAdminConsole';

const UsingAdministratorConsole = () => (
	<WorkshopLayout title={`The 6 Types of Working Genius: Using Admin Console`}>
		<UsingAdminConsole />
	</WorkshopLayout>
);

export default UsingAdministratorConsole;
