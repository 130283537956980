import React from 'react';
import AppButton from '../AppButton';
import CustomerService from '../../services/CustomerService';
import { family_with_student, newly_released } from '../../images';

const StudentsHero = () => {
    return (
        <div className='container'>
            <div className='flex flex-col-reverse lg:flex-row pb-12'>
                <div className='flex-1 justify-center p-8'>
                    <img src={family_with_student} alt='Workshop image' />
                </div>
                <div className='flex-1 flex flex-col justify-center items-center text-center px-12'>
                    <img src={newly_released} alt='Newly released' width='300' />
                    <h1 className='text-2xl lg:text-3xl text-primary font-normal text-center my-4' style={{ maxWidth: '450px' }}>
                        The fastest and simplest way to <span className='font-semibold'>discover your gifts</span> and <span className='font-semibold'>thrive in school and life</span>.
                    </h1>
                    <AppButton
                        className={'btn btn-student'}
                        onClick={() => CustomerService.addToCart('add_student_license', true)}
                        text={'Buy the assessment now'}
                    />
                </div>
            </div>
        </div>
    );
};

export default StudentsHero;