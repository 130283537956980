import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import PodcastService from '../../../services/PodcastService';
import PodcastNotes from '../../../components/podcast/pdf/PodcastNotes';
import { getQueryParams } from '../../../services/UtilityService';

const PodcastPdf = () => {
	const [podcast, setPodcast] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const location = useLocation();
	const { slug } = getQueryParams(location.search);

	useEffect(() => {
		PodcastService.getPodcasts()
			.then((response) => {
				const selectedPodcast = response.find((item) => item.slug === slug);
				setPodcast(selectedPodcast)
			})
			.catch(({ message }) => {
				console.error('Error getting podcasts:', message);
				setError(message);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [slug]);

	const renderContent = () => {
		if (loading || error) {
			return null;
		}

		return <PodcastNotes podcast={podcast} />;
	};

	return (
		<>
			<Helmet>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta httpEquiv='X-UA-Compatible' content='ie=edge' />
				<link rel='stylesheet' href='/assessments/report/styles/pdf.css' />
				<title>The 6 Types of Working Genius Podcast Pdf</title>
			</Helmet>
			{renderContent()}
		</>
	);
};

export default PodcastPdf;
