import React from 'react';
import {APITermsOfServiceConstants} from "./policyConstants";
import PolicyHero from "./PolicyHero";
import PolicyContent from "./PolicyContent";

const APITermsofServiceContent = () => {
    const {hero, content} = APITermsOfServiceConstants;
    return (
        <>
            <PolicyHero heroData={hero}/>
            <PolicyContent contentData={content}/>
        </>
    );
}
export default APITermsofServiceContent;
