import React, { useEffect } from 'react';
import LineBreak from './LineBreak';
import AppSlideOver from '../../AppSlideOver';
import AppButton from '../../AppButton';
import AppInput from '../../AppInput';
import RosterService from '../../../services/RosterService';
import NotificationService from '../../../services/NotificationService';
import { useForm } from 'react-hook-form';

const EditNameOnAssessment = ({ item, isOpen, closeModal, updateName }) => {
	const [loading, setLoading] = React.useState(false);
	const { register, setValue, handleSubmit, formState } = useForm({
		defaultValues: {
			fname: item.fname,
			lname: item.lname,
		},
	});
	const { isDirty } = formState;

	useEffect(() => {
		if (isOpen) {
			setValue('fname', item.fname, { shouldDirty: false});
			setValue('lname', item.lname, { shouldDirty: false});
		}
	}, [isOpen]);

	const onSubmit = (data) => {
		setLoading(true);
		RosterService.changeName({
			assessment_id: item.assessment,
			roster_id: item.id,
			first_name: data.fname,
			last_name: data.lname,
		})
			.then(() => {
				closeModal();
				NotificationService.success(
					`Successfully changed the name on the report to <b>${data.fname} ${data.lname}</b>.`
				);
				updateName(data.fname, data.lname);
			})
			.catch(() => {
				NotificationService.error(`Failed to change the name. Please try again later.`);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<AppSlideOver id={'wg-edit-assessment-name-modal'} isOpen={isOpen} closeModal={closeModal}>
			<div className='share-assessment-slide-over'>
				<div className='share-assessment-info'>
					<p className='share-assessment-heading'>Edit Name on Assessment </p>
					<p className='share-assessment-text'>Edit the name of the person in the assessment.</p>
					<LineBreak />
					<form onSubmit={handleSubmit(onSubmit)}>
						<AppInput
							{...register('fname', { required: true })}
							label='First Name'
							placeholder='Enter first name'
						/>
						<AppInput
							{...register('lname', { required: true })}
							label='Last Name'
							placeholder='Enter last name'
						/>

						<p className='font-semibold'>Email</p>
						<p>{item.email}</p>
						<LineBreak />
						<div className='flex justify-between gap-2'>
							<AppButton
								disabled={!isDirty}
								text='Save'
								type={'submit'}
								className={'btn btn-primary w-full mr-2'}
								loading={loading}
							/>
							<AppButton
								type='button'
								text='Cancel'
								onClick={closeModal}
								className={'btn btn-secondary w-full ml-2'}
							/>
						</div>
					</form>
				</div>
			</div>
		</AppSlideOver>
	);
};

export default EditNameOnAssessment;
