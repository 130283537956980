import React from 'react'
import AppButton from '../AppButton'

const DownloadResource = ({ title, file = '' }) => {
    return (
        <div className='download-resource-button'>
            <p className='title'>{title}</p>
            <AppButton
                className={'btn btn-primary w-full'}
                text={'Download now'}
                onClick={() => {
                    window.open(file, '_blank');
                }}
                remixIcon
                icon={'download-2-line'}
                iconClass={'mx-2 mt-2 text-xl align-middle'}
                iconLeft={true}
            />
        </div>
    )
}

export default DownloadResource