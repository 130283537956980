import React from 'react';
import { widget_list_icon } from '../../images';

const PermissionsTable = () => {
    const permissionsData = [
        { permission: 'Generate a Team Map', admin: true, limited: true },
        { permission: 'Move Assessment Report', admin: true, limited: true },
        { permission: 'Duplicate Assessment', admin: true, limited: true },
        { permission: 'Create and Edit Groups', admin: true, limited: true },
        { permission: 'Send Assessment Links', admin: true, limited: true },
        { permission: 'Request Assessments', admin: true, limited: false },
        { permission: 'Download Results in PDF format', admin: true, limited: false },
        { permission: 'Change Name on an Assessment', admin: true, limited: false },
        { permission: 'Edit Assessment Results', admin: true, limited: false },
        { permission: 'Purchase Licenses', admin: true, limited: false },
        { permission: 'Transfer Licenses', admin: true, limited: false },
        { permission: 'Access Invoices/Receipts', admin: true, limited: false },
        { permission: 'Access to Team Application Guide (if purchased)', admin: true, limited: false },
    ];

    const [viewAllPermissions, setViewAllPermissions] = React.useState(false);

    const renderPermissionsTable = () => {
        return (
            <table id='permissions-table'>
                <thead>
                    <tr>
                        <th>Permissions</th>
                        <th>Admin</th>
                        <th>Limited</th>
                    </tr>
                </thead>
                <tbody>
                    {permissionsData.map((permission, index) => (
                        <tr key={index}>
                            <td>{permission.permission}</td>
                            <td>{permission.admin ? <img src={widget_list_icon} width='20' /> : ''}</td>
                            <td>{permission.limited ? <img src={widget_list_icon} width='20' /> : ''}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className='ttg-permissions-tab' onClick={() => setViewAllPermissions(!viewAllPermissions)}>
            <input
                type='radio'
                name={'permissions-tab'}
                className={'ttg-permissions-input'}
                checked={!!viewAllPermissions}
                readOnly
            />
            <label
                className='ttg-permissions-tab-label hover:text-secondary'
                htmlFor={`permissions-tab`}
            >
                View all permissions
            </label>
            <div className='ttg-permissions-tab-content' onClick={(e) => e.stopPropagation()}>
                {renderPermissionsTable()}
            </div>
        </div>
    );
};

export default PermissionsTable;
