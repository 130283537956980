import React, { createContext, useContext, useEffect, useState } from 'react';

export const AssessmentContext = createContext();

export const AssessmentProvider = ({ children }) => {
	const [teamMap, setTeamMap] = useState({
		label: '',
		loading: true,
		error: false,
		endText: '',
		members: [],
	});
	const [assessment, setAssessment] = useState(null);
	const [assessmentType, setAssessmentType] = useState(null);
	const [loading, setLoading] = useState(true);

	const loadTeamMap = () => {
		setLoading(true);
	};

	useEffect(() => {
		loadTeamMap();
	}, []);

	return (
		<AssessmentContext.Provider
			value={{ teamMap, loading, setTeamMap, setAssessment, assessment, assessmentType, setAssessmentType }}
		>
			{children}
		</AssessmentContext.Provider>
	);
};

export const useAssessments = () => useContext(AssessmentContext);

export const AssessmentContextLayout = ({ children }) => <AssessmentProvider>{children}</AssessmentProvider>;
