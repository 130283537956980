import React, { useState, useEffect } from 'react';
import AppIcon from '../AppIcon';
import AppModal from '../AppModal';
import OrdersService from '../../services/OrdersService';
import { formatDate } from '../../services/UtilityService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'white',
		maxHeight: '600px',
		maxWidth: '900px',
		width: '100%',
	},
};
const OrderDetails = ({ isOpen, closeModal, orderId }) => {
	const [order, setOrder] = useState({});
	const [loading, setLoading] = useState(false);

	const getOrder = () => {
		if (orderId) {
			setLoading(true);
			OrdersService.details(orderId)
				.then(setOrder)
				.catch(() => closeModal())
				.finally(() => setLoading(false));
		}
	};

	useEffect(() => {
		getOrder();
	}, [orderId]);

	return (
		<AppModal id={'order-modal'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='modal-content bg-white'>
				<div className='modal-header'>
					<h5 className='modal-title' id='order-modal-title'>
						Order Details
					</h5>
					<button onClick={() => closeModal()} className='close-btn'>
						<AppIcon icon='cancel' className='modal-cancel' />
					</button>
				</div>
				{loading && (
					<div
						style={{ minHeight: '500px', minWidth: '850px' }}
						className={'flex flex-1 justify-center content-center items-center'}
					>
						<div className='spinner'>
							<div className='double-bounce1' />
							<div className='double-bounce2' />
						</div>
					</div>
				)}
				{!loading && (
					<div>
						<div className='modal-body'>
							<div className='order-details'>
								<div className='container'>
									<div className='order-table'>
										<table>
											<tbody>
												<tr>
													<td>
														<b>Order ID</b>
													</td>
													<td>{order?.order_details?.orders_id}</td>
												</tr>
												<tr>
													<td>
														<b>Date of Order</b>
													</td>
													<td>{formatDate(order?.order_details?.date_purchased)}</td>
												</tr>
												<tr>
													<td>
														<b>Order Status</b>
													</td>
													<td>{order?.order_details?.status}</td>
												</tr>
												<tr>
													<td style={{ width: '1%' }}>
														<b>Payment Method</b>
													</td>
													<td>{order?.order_details?.payment_method}</td>
												</tr>
												{order?.order_details?.is_po ? (
													<>
														<tr>
															<td>
																<b>Purchase Order Number</b>
															</td>
															<td>{order?.order_details?.po_number}</td>
														</tr>
														<tr>
															<td>
																<b>Authorized by</b>
															</td>
															<td>{order?.order_details?.po_authorizer}</td>
														</tr>
													</>
												) : (
													<></>
												)}
											</tbody>
										</table>
									</div>

									<h4 className='font-semibold'>Products Summary and Billing</h4>

									<p>
										Below is a summary of all products contained within this order as well as the
										billing details.
									</p>

									<div className='order-table'>
										<table>
											<tbody>
												{order?.product_details &&
													order?.product_details.map((product, idx) => (
														<tr key={idx}>
															<td style={{ width: '1%' }}>
																<b>{product.products_quantity}</b>
															</td>
															<td id={'wg-order-product-name'}>{product.products_name}</td>
															<td
																align='right'
																style={{ textAlign: 'right', width: '1%' }}
															>
																${parseFloat(product.final_price).toFixed(2)}
															</td>
														</tr>
													))}
												{order?.ot_subtotal && (
													<tr>
														<td colSpan='2' style={{ textAlign: 'right' }}>
															<b>{order?.ot_subtotal.title}</b>
														</td>
														<td style={{ textAlign: 'right' }}>
															{order?.ot_subtotal.text}
														</td>
													</tr>
												)}

												{order?.ot_discount && (
													<tr>
														{order?.ot_discount.title && (
															<td colSpan='2' style={{ textAlign: 'right' }}>
																<b>{order?.ot_discount.title}</b>
															</td>
														)}
														{order?.ot_discount.text && (
															<td style={{ textAlign: 'right' }}>
																{order?.ot_discount.text}
															</td>
														)}
													</tr>
												)}

												{order?.ot_shipping && (
													<tr>
														<td colSpan='2' style={{ textAlign: 'right' }}>
															<b>{order?.ot_shipping.title}</b>
														</td>
														<td style={{ textAlign: 'right' }}>
															{order?.ot_shipping.text}
														</td>
													</tr>
												)}

												{order?.ot_tax && (
													<tr>
														{order?.ot_tax.title && (
															<td colSpan='2' style={{ textAlign: 'right' }}>
																<b>{order?.ot_tax.title}</b>
															</td>
														)}
														{order?.ot_tax.text && (
															<td style={{ textAlign: 'right' }}>{order?.ot_tax.text}</td>
														)}
													</tr>
												)}

												{order?.ot_total && (
													<tr>
														<td colSpan='2' style={{ textAlign: 'right' }}>
															<b>
																{order?.ot_total.title
																	? order.ot_total.title
																	: 'Total:'}
															</b>
														</td>
														<td style={{ textAlign: 'right' }}>
															{order?.ot_total.text ? order?.ot_total.text : '$0.00'}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>

									<div className='margin-bottom'>
										<h4 className='font-semibold'>Address Information</h4>
										<p>Below is a summary of the address information on file for this order.</p>
									</div>
									<div className='margin-bottom-2 flex flex-col md:flex-row'>
										<div className='address flex-1'>
											<h5 className='font-semibold'>Billing Address</h5>
											<div id={'wg-order-billing-address'}>
												<div>{order?.order_details?.billing_name}</div>
												<div>{order?.order_details?.billing_company}</div>
												<div>{order?.order_details?.billing_street_address}</div>
												<div>{order?.order_details?.billing_suburb}</div>
												<div>{order?.order_details?.billing_city}</div>
												<div>{order?.order_details?.billing_state}</div>
												<div>{order?.order_details?.billing_postcode}</div>
												<div>{order?.order_details?.billing_country}</div>
											</div>
										</div>
										{!!order?.order_details?.delivery_city && (
											<div className='address flex-1'>
												<h5 className='font-semibold'>Delivery Address</h5>
												<div id={'wg-order-shipping-address'}>
													<div>{order?.order_details?.delivery_name}</div>
													<div>{order?.order_details?.delivery_street_address}</div>
													<div>{order?.order_details?.delivery_suburb}</div>
													<div>{order?.order_details?.delivery_city}</div>
													<div>{order?.order_details?.delivery_state}</div>
													<div>{order?.order_details?.delivery_postcode}</div>
													<div>{order?.order_details?.delivery_country}</div>
												</div>
											</div>
										)}
									</div>

									<h4 className='font-semibold'>Order Status History</h4>
									<p>
										Below is a brief summary of the status history for this order, as well as any
										comments associated with each (if applicable).
									</p>

									<div className='order-table'>
										<table>
											<tbody>
												{order?.history &&
													order?.history.map((record, idx) => (
														<tr key={idx}>
															<td style={{ width: '1%' }}>
																<b>{formatDate(record.date_added)}</b>
															</td>
															<td>{record.status}</td>
															<td width='100%'>{record.comments}</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>

						<div className='modal-footer'>
							<button
								type='button'
								className='btn text-secondary border-none float-right'
								onClick={() => closeModal()}
								id={'wg-order-details-done-btn'}
							>
								Done
							</button>
						</div>
					</div>
				)}
			</div>
		</AppModal>
	);
};

export default OrderDetails;
