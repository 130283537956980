import ApiService from './ApiService';
import config from '../constants/config';

const ResourceItems = {
	wg: [
		{
			title: 'Introduction to Working Genius',
			description:
				'An introduction and overview of the Six Types of Working Genius model and its impact and implications for individuals and teams.',
			ctaText: 'Download pdf',
			link: 'https://files.tablegroup.com/wp-content/uploads/2022/09/21090156/Introduction-to-The-Working-Genius.pdf',
		},
		{
			title: 'The Impact on Teams',
			description:
				'Understand the implications of when a genius is missing on a team and learn the best way to quickly identify any gaps through the use of a Team Map.',
			ctaText: 'Download pdf',
			link: 'https://files.tablegroup.com/wp-content/uploads/2023/03/17111207/Impact-on-Teams.pdf',
		},
		{
			title: 'Team Map Template',
			description:
				"When you purchase Working Genius for your team, you can create a high-impact visual of your team's geniuses and frustrations using a Team Map (this tool is included in your Working Genius Administrator Console). Click below to download an example.",
			ctaText: 'Download pdf',
			link: 'https://files.tablegroup.com/wp-content/uploads/2022/09/20012530/Blank-Working-Genius-Team-Map-Resource.pdf',
		},
		{
			title: 'Guide to Corporate Rollout ',
			description:
				"The Six Types of Working Genius is simple and easy to apply. Companies that use Working Genius across their organization experience lower turnover, increased morale, and get more done in less time.",
			ctaText: 'Download pdf',
			link: 'https://files.tablegroup.com/wp-content/uploads/2024/02/28063721/Working-Genius-Corporate-Rollout-Guide-2024-02-20.pdf',
		},
		{
			title: 'Working Genius & Your Church',
			description:
				"The Six Types of Working Genius is fast and simple to apply. Churches that use Working Genius with staff and volunteers get more done in less time, have lower turnover, and increase morale.",
			ctaText: 'Download pdf',
			link: 'https://files.tablegroup.com/wp-content/uploads/2024/04/03083409/Rollout-Guide-Churches-2024-04-02.pdf',
		}
	],
	podcasts: [
		{
			episode: 'EPISODE 2',
			title: 'No Joy, No Genius',
			description:
				'Can you be skilled in an area that doesn’t give you joy and energy?  Definitely.  Does that mean it should be considered one of your Geniuses?  Definitely not.  In this episode, Pat and the Working Genius team dive into the importance of fulfillment and joy in determining what your Geniuses are.',
			link: `${config.base_url}/wg-podcast-post/2-no-joy-no-genius`,
		},
		{
			episode: 'EPISODE 5',
			title: 'The Three Phases',
			description:
				"We've always thought of work as ideation and implementation, but the Working Genius model sheds light on an important middle phase that is often overlooked.  This week, the Working Genius team talks about the three phases, and the elevation of each Genius as it relates to landing a plane.",
			link: `${config.base_url}/wg-podcast-post/5-the-three-phases`,
		},
		{
			episode: 'EPISODE 8',
			title: 'Personality vs Productivity',
			description:
				'What exactly is it that differentiates the Six Types of Working Genius from other personality type assessments?  The team talks this week about how the Working Genius assessment is actually more of a productivity tool than a personality tool.',
			link: `${config.base_url}/wg-podcast-post/8-personality-vs-productivity`,
		},
		{
			episode: 'EPISODE 4',
			title: 'The Genius of Meetings',
			description:
				'Every meeting requires certain Geniuses.  This week, Pat and the Working Genius team discuss the different types of meetings, and how to identify which Geniuses are appropriate and necessary.',
			link: `${config.base_url}/wg-podcast-post/4-the-genius-of-meetings`,
		},
		{
			episode: 'EPISODE 25',
			title: 'All the Pairings...Finally. ',
			description:
				'Buckle up!  This week, the Working Genius team reveals all of the pairings in our longest episode yet.',
			link: `${config.base_url}/wg-podcast-post/25-all-the-pairings-finally`,
		},
		{
			episode: 'EPISODE 38',
			title: 'What Each Genius Craves',
			description: `<div>
								<p>Each of the Six Types craves something different from others.  This week, Pat, Cody, Beau and Tracy identify the one thing that each Genius type needs to feel valued, and explain why this is important for teams.</p>
								</br>
								<p>To order Pat’s newest book: <a href="https://tinyurl.com/workinggeniusbook" target="_blank">tinyurl.com/workinggeniusbook</a></p>
								</br>
								<p>To take the Six Types of Working Genius...</p>
					</div>`,
			link: `${config.base_url}/wg-podcast-post/38-what-each-genius-craves`,
		},
	],
	articles: [
		{
			title: 'Marriage is a Lot of Work',
			author: 'By Author Patrick Lencioni',
			description:
				'The title of this article is not meant to be cynical in the slightest. It\'s just a statement of fact. So much of what we do in the course of our daily married lives would qualify as "work."',
			slug: 'marriage-is-a-lot-of-work',
			pdfLink:
				'https://files.tablegroup.com/wp-content/uploads/2022/11/07072523/Working-Genius-Article-Marriage-is-a-Lot-of-Work.pdf',
		},
	],
	additional_resources: [
		{
			title: 'Working Genius Social Graphics',
			description:
				'If you want to share about Working Genius, Your Genius Results, or the book- feel free to download any of the free graphics to use on social media.',
			ctaText: 'Download',
			link: 'https://www.dropbox.com/sh/khop99fx1nlejyl/AADfGzwdr3eQd_e1ANqHbmZia?e=1&st=2ldtyokm&dl=0',
		},
		{
			title: 'Marriage is a Lot of Work',
			author: 'By Patrick Lencioni',
			ctaText: 'Read More',
			description:
				'The title of this article is not meant to be cynical in the slightest. It\'s just a statement of fact. So much of what we do in the course of our daily married lives would qualify as "work."',
			link: '/resources/article/details?id=marriage-is-a-lot-of-work'
		},
	],
};

const getArticleBySlug = (slug) => {
	return ResourceItems.articles.find((item) => item.slug === slug);
};

const getDCAResources = () => new Promise((resolve, reject) =>ApiService.get(`v1/resource/dca-private-certification-registration`).then(resolve, reject));

const ResourcesService = {
	APPLE_PODCAST_URL:
		'https://podcasts.apple.com/ph/podcast/the-working-genius-podcast-with-patrick-lencioni/id1553105854',
	SPOTIFY_PODCAST_URL: 'https://open.spotify.com/show/3raC053GF5mtkq6Y1klpRU',
	GOOGLE_PODCAST_URL: 'https://podcasts.google.com/feed/aHR0cDovL2ZlZWRzLmxpYnN5bi5jb20vMzEyMzQxL3Jzcw?hl=en',
	TEAM_RESOURCES_ID: 'wg-team-resources-container',
	PODCAST_RESOURCES_ID: 'wg-podcast-resources-container',
	ARTICLES_RESOURCES_ID: 'wg-articles-resources-container',
	ADDITIONAL_RESOURCES_ID: 'wg-additional-resources-container',
	items: ResourceItems,
	getArticleBySlug,
	getDCAResources,
};

export default ResourcesService;
