import React from 'react';
import PageHeader from './PageHeader';
import ColorGearImage from '../../ColorGearImage';
import ReportUniquePairingsES from '../../../../constants/ReportUniquePairingsES';
import PageTitle from './PageTitle';

const Overview = ({ assessment }) => {
	const renderGenii = (genii, color) => {
		return (
			<>
				{genii.map((g, key) => {
					return (
						<div className='result' key={key}>
							<div className='gear'>
								<ColorGearImage
									type={g.label}
									color={color}
									height={'50px'}
									width={'50px'}
									style={{ marginLeft: '45px' }}
									lang={'es'}
								/>
							</div>
							<div className='text'>
								<span
									dangerouslySetInnerHTML={{
										__html: ReportUniquePairingsES.Results[
											ReportUniquePairingsES.getAbility(g.label)
										][g.level],
									}}
								/>
							</div>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<div className='QuantitativeResults'>
			<PageHeader title={'Resultados'} />
			<div className='container'>
				<PageTitle title={'Tus resultados'} className={'mt-16'} />
				<p className={'font-semibold text-14 mt-4 mb-6'}>
					{assessment.participant.fname}, la siguiente información resume los resultados de tu evaluación.
				</p>

				<div className='resultset genius'>
					<h2 className={'text-xl font-bold'}>GENIALIDADES EN LAS QUE ERES SOBRESALIENTE:</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: ReportUniquePairingsES.Results.Genialidades(
								ReportUniquePairingsES.getAbility(assessment.customResults.genius1),
								ReportUniquePairingsES.getAbility(assessment.customResults.genius2),
								ReportUniquePairingsES.genius_color
							),
						}}
					/>

					{renderGenii(
						[assessment.results.genii[0], assessment.results.genii[1]],
						ReportUniquePairingsES.genius_color
					)}
				</div>

				<div className='resultset competency'>
					<h2 className={'text-xl font-bold'}>GENIALIDADES EN LAS QUE ERES COMPETENTE:</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: ReportUniquePairingsES.Results.Competente(
								ReportUniquePairingsES.getAbility(assessment.customResults.competency1),
								ReportUniquePairingsES.getAbility(assessment.customResults.competency2),
								ReportUniquePairingsES.competency_color
							),
						}}
					/>

					{renderGenii(
						[assessment.results.genii[2], assessment.results.genii[3]],
						ReportUniquePairingsES.competency_color
					)}
				</div>

				<div className='resultset frustration'>
					<h2 className={'text-xl font-bold'}>GENIALIDADES QUE TE FRUSTRAN:</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: ReportUniquePairingsES.Results.Frustran(
								ReportUniquePairingsES.getAbility(assessment.customResults.frustration1),
								ReportUniquePairingsES.getAbility(assessment.customResults.frustration2),
								ReportUniquePairingsES.frustration_color
							),
						}}
					/>

					{renderGenii(
						[assessment.results.genii[4], assessment.results.genii[5]],
						ReportUniquePairingsES.frustration_color
					)}
				</div>
			</div>
		</div>
	);
};

export default Overview;
