import React, {useCallback, useEffect, useState} from 'react';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useForm } from 'react-hook-form';

import Layout from '../../../components/Layout';
import AppInput from '../../../components/AppInput';
import AppButton from '../../../components/AppButton';
import { login } from '../../../services/CustomerService';
import AppErrorMessage from '../../../components/AppErrorMessage';
import AppText from '../../../components/AppText';
import { emailRegex, getQueryParams } from '../../../services/UtilityService';
import AppRIIcon from '../../../components/AppRIIcon';
import { useUser } from '../../../hooks/UserProvider';
import {useNotifications} from "../../../hooks/NotificationProvider";

const Login = () => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const location = useLocation();
	const { confirm, redirect, logout, type, email } = getQueryParams(location.search);
	const { user, setUser } = useUser();
	const { loadNotifications } = useNotifications();
	const decodeEmail = decodeURIComponent(email);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm();

	const onSuccess = useCallback((u) => {
		setUser(u);
		loadNotifications();

		if (redirect) {
			return navigate(decodeURIComponent(redirect).replace('//', '/'), { replace: true });
		}

		navigate('/client/', { replace: true });
	}, [setUser, loadNotifications, navigate, redirect]);

	useEffect(() => {
		if (user && user.id && !logout) {
			onSuccess(user);
		}
		if (email) setValue('email', decodeEmail);
	}, []);

	const _login = (credentials) => {
		setErrorMessage('');
		setLoading(true);
		login({ ...credentials, type })
			.then(
				(u) => onSuccess(u),
				() => {
					setErrorMessage(
						"We're sorry, but there was an error with your Email/ Password combination. Please try again."
					);
				}
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const renderFooter = () => (
		<div className='flex flex-col items-center my-6'>
			<div className='text-sm text-gray-500 font-normal '>
				Don't have an account?{' '}
				<Link
					to={`/client/signup${redirect ? `?redirect=${redirect}` : ''}`}
					className='text-secondary font-medium'
					id={'wg-login-signup-link'}
				>
					Create Account
				</Link>
			</div>
			{redirect === '/checkout/' || redirect?.includes('checkout-view') ? (
				<span
					className={'flex flex-col items-center text-secondary font-medium my-4 cursor-pointer'}
					onClick={() => window.open('/shopping-cart-view', '_self')}
					id={'wg-login-cart-link'}
				>
					Back to Shopping Cart
				</span>
			) : (
				<div className='md:shadow-md sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto my-4'>
					<div
						className='flex flex-row items-center justify-center cursor-pointer text-white bg-secondary hover:bg-darkenSecondary px-4 py-2'
						onClick={() => navigate('/client/find')}
					>
						<div className={'relative'}>
							<AppRIIcon icon={'survey-line'} className={'text-3xl'} />
							<div className={'absolute'} style={{ bottom: '8px', right: '8px' }}>
								<AppRIIcon icon={'search-2-line'} className={'text-10'} />
							</div>
						</div>
						<p className={'text-lg ml-4 font-medium'}>Find Your Assessment</p>
					</div>
				</div>
			)}
		</div>
	);

	return (
		<Layout title={'Login | The 6 Types Of Working Genius'}>
			<div className={'container mx-auto'}>
				<div className='md:shadow-md sm:w-full md:w-3/4 lg:w-5/12 xl:w-1/3 md:mx-auto mt-12 px-4 md:px-6 py-8'>
					<AppErrorMessage id={'wg-login-error-message'} message={errorMessage} />
					{!confirm && (
						<h1 className='uppercase text-secondary text-center text-4xl font-semibold mb-4 leading-none'>
							ADMINISTRATOR LOGIN
						</h1>
					)}
					{confirm && (
						<h1 className='uppercase text-secondary text-center text-3xl font-semibold mb-4 leading-none'>
							{confirm ? 'LOGIN WITH THE NEW EMAIL ADDRESS' : 'ADMINISTRATOR LOGIN'}
						</h1>
					)}
					{!confirm && (
						<AppText
							text={
								"If you already established an account, login below to access your Administrator's Console."
							}
							className='text-center mb-6'
						/>
					)}
					<form
						className={'flex flex-col w-full'}
						onSubmit={handleSubmit(_login)}
						autoComplete='autocomplete_off_hack_xfr4!k'
					>
						<AppInput
							{...register('email', {
								required: true,
								pattern: {
									value: emailRegex,
								},
							})}
							label={confirm ? 'New email address' : 'Email address'}
							errors={errors}
							onBlur={(e) => {
								setValue('email', e.target.value.trim());
							}}
							disabled={type}
						/>
						<AppInput
							label={'Password'}
							type={'password'}
							errors={errors}
							{...register('password', {
								required: true,
							})}
						/>
						<Link
							to={'/client/login/reminder/'}
							className={'text-secondary self-center'}
							id={'wg-login-remember-pass-link'}
						>
							<span className={'font-medium text-base'}>Forgot your password?</span>
						</Link>
						<AppButton
							type='submit'
							className={'btn btn-primary w-full my-4'}
							text={'Login'}
							loading={loading}
							id={'wg-login-submit-btn'}
						/>
					</form>
				</div>
				{renderFooter()}
			</div>
		</Layout>
	);
};

export default Login;