import React from 'react';

import AppButton from '../AppButton';
import { dca_wg_logo } from '../../images';

const Hero = ({ handleOpenModal, loading }) => {
	return (
		<div className='dca-hero'>
			<div className={'dca-hero-container'}>
				<div className={'dca-hero-logo'}>
					<img src={dca_wg_logo} alt='DCA WG Logo' />
				</div>

				<span className={'text-2xl text-white text-center font-semibold pt-9 pb-3'}>
					WORKING GENIUS CERTIFICATION
				</span>

				<span className={'text-xl text-white text-center font-medium pb-2'}>
					Delivered by our partner, Dale Carnegie
				</span>

				<div className={'pt-6 pb-2'}>
					<AppButton
						id={'dca-hero-button'}
						onClick={() => handleOpenModal('hero')}
						loading={loading}
						className={'btn btn-primary dca-hero-button'}
						text={'ACCESS PRE-WORK'}
					/>
				</div>
			</div>
		</div>
	);
};

export default Hero;
