import React from 'react';
import { book_best_seller_3d } from '../../images';
import AppButton from '../AppButton';
import { navigate } from '@reach/router';
import { addToCart } from '../../services/CustomerService';

const AboutBook = () => {
	return (
		<div className='about-book-6-types' id='wg-about-the-book-id'>
			<div className='container'>
				<div className='about-book-6-types-content'>
					<div className='about-book-6-types-img flex-1'>
						<img
							width={300}
							height={300}
							src={book_best_seller_3d}
							alt='The 6 Types of the Working Genius'
						/>
					</div>
					<div className='about-book-6-types-text flex-1'>
						<h2 className='about-book-h3-bold text-center md:text-left'>The National Bestseller</h2>

						<h3 className='about-book-h4-normal font-semibold text-center md:text-left'>
							By Patrick Lencioni
						</h3>

						<h3 className='about-book-h4 font-semibold text-center md:text-left'>NEW!</h3>

						<p className='about-book-p pb-1'>
							Whether you're a team leader or entrepreneur, a front-line employee or executive, or a
							school teacher or stay-at-home parent, The Six Types of Working Genius will help you find
							more joy, fulfillment and success in your work and your life.
						</p>

						<AppButton text={'Learn More'} onClick={() => navigate('/book')} />

						<p className='about-book-p pt-8'>
							"The Working Genius changed how I think about myself and my team. It's a game changer for
							how to hire and develop employees. Crazy!"
						</p>

						<h4 className='font-medium text-14 text-center md:text-left'>
							MICHELLE RASHID, SENIOR VICE PRESIDENT,
						</h4>

						<h4 className='font-medium text-14 text-center md:text-left'>
							CULTURE AND HUMAN RESOURCES, VIRTUOSO
						</h4>

						<h4 className='font-medium text-14 text-center md:text-left pt-6 pb-1'>ORDER THE BOOK</h4>

						<div className='about-book-links'>
							<a className='btn btn-white cursor-pointer' onClick={() => addToCart('add_wg_book', true)}>
								Add to cart
							</a>
							<a
								href='https://www.amazon.com/Types-Working-Genius-Understand-Frustrations/dp/1637743297'
								className='btn btn-white'
								target='_blank'
							>
								Amazon
							</a>
							<a
								href='https://www.booksamillion.com/p/Types-Working-Genius/Patrick-M-Lencioni/9781637743294?id=7130420786913'
								className='btn btn-white'
								target='_blank'
							>
								Bam
							</a>
							<a
								href='https://www.barnesandnoble.com/w/the-6-types-of-working-genius-patrick-m-lencioni/1141358162'
								className='btn btn-white'
								target='_blank'
							>
								Barnes & Noble
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutBook;
