import React from 'react';

const ToolkitMenuItem = ({ id, title, activeTab, setActiveTab, subtitle = '' }) => {
	const handleClick = () => {
		setActiveTab(id);
	};

	return (
		<div onClick={handleClick} className={`menu-item ${activeTab === id ? 'active' : ''}`}>
			{title}
			<br />
			{subtitle}
		</div>
	);
};

export default ToolkitMenuItem;
