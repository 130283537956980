import React from 'react';
import AppButton from '../AppButton';
import WorkshopForm from './WorkshopForm';

const WorkshopExploratory = () => {
	const [openWgModal, setOpenWgModal] = React.useState(false);

	return (
		<div className={'container bg-white flex flex-col p-10 pb-4 items-center'}>
			<AppButton
				text={'Schedule an Exploratory Call'}
				className={'btn workshop-secondary-btn'}
				onClick={() => setOpenWgModal(true)}
			/>
			<WorkshopForm
				isOpen={openWgModal}
				onClose={() => setOpenWgModal(false)}
				title={'Request an Exploratory Call'}
				type={'exploratory_call'}
			/>
		</div>
	);
};

export default WorkshopExploratory;
