import React from 'react';
import { Link } from 'gatsby';
import { gears, logo } from '../../images';
import { addToCart } from '../../services/CustomerService';

const HomeStickyNav = () => {
	const [navScroll, setNavScroll] = React.useState(false);

	const listenScrollEvent = () => {
		window.scrollY > 100 ? setNavScroll(true) : setNavScroll(false);
	};

	React.useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);

		return () => {
			window.removeEventListener('scroll', listenScrollEvent);
		};
	}, []);

	return (
		<div className={`navbar wg-home-banner hidden ${navScroll ? 'lg:block' : ''}`}>
			<div className='navbar-content'>
				<div className='nav-header'>
					<Link to='/'>
						<img src={logo} alt='Working Genius' />
					</Link>
				</div>

				<div className={'nav-links'}>
					<img src={gears} alt='Working Genius gears' width='100' className='mx-4' />
					<a
						className={`banner-btn`}
						id={'wg-sticky-nav-take-assessment-id'}
						onClick={() => addToCart('add_license', true)}
					>
						{'Buy the assessment'}
					</a>
				</div>
			</div>
		</div>
	);
};

export default HomeStickyNav;
