import React from 'react';

const Result = ({ letter, className, backgroundColor }) => {
	return (
		<div
			className={`flex items-center justify-center text-white w-8 h-8 rounded-full ${className}`}
			style={{ backgroundColor }}
		>
			{letter}
		</div>
	);
};

export default Result;
