import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';
import {
    popup_book,
    WGpodcastPNG,
    student_pairings_description,
    student_discussion_guide,
    student_report_qr,
} from '../../../../images';

const Applying = () => {
    const resources = [
        {
            img: popup_book,
            title: 'The Book',
            text: 'Buy now',
            link: 'https://www.workinggenius.com/book',
        },
        {
            img: WGpodcastPNG,
            title: 'The Podcast',
            text: 'Listen now',
            link: 'https://www.workinggenius.com/wg-podcast-post',
        },
        {
            img: student_pairings_description,
            title: 'Genius Pairings',
            text: 'Download',
            link: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28064610/Student_Pairing_Descriptions.pdf',
        },
        {
            img: student_discussion_guide,
            title: 'Discussion Guide',
            text: 'Download',
            link: 'https://files.tablegroup.com/wp-content/uploads/2024/03/28093806/Working_Genius_Student_Discussion_Guide.pdf',
        },
    ];
    const renderResource = (resources) => {
        return (
            <>
                {resources.map((resource, key) => {
                    return (
                        <div className='flex-1 flex flex-col items-center justify-end px-2 py-3' key={key} style={{ backgroundColor: '#EFEFEF' }}>
                            <img src={resource.img} alt='' style={{ maxHeight: '140px' }} />
                            <h3 className='text-base capitalize font-semibold mt-3 mb-2'>{resource.title}</h3>
                            <a href={resource.link} className={'btn btn-primary btn-small item-button'}>
                                {resource.text}
                            </a>
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div className='ApplyingStudent'>
            <PageHeader title={'Application'} />
            <div className='container'>
                <PageTitle title={'Applying Working Genius'} className={'mt-8'} />
                <p className={'text-14 mt-2 mb-4'}>
                    Understanding your Working Genius results can have a huge impact on the joy, fulfillment, and
                    success you experience in every area of your life. Many people wait too long to discover their unique
                    gifts, which often leads to frustration, confusion, and burnout.
                </p>
                <p className={'text-14 my-4'}>
                    Here are two simple steps for how to begin applying Working Genius and shortcut your way to better
                    decision making.
                </p>
                <p className={'text-14 mt-1 ml-6'}>
                    <span className='font-bold'>Step one:</span> Discuss your results with a person in your life who knows you well—a parent, teacher,
                    coach, or peer.
                </p>
                <p className={'text-14 mt-2 ml-6'}>
                    <span className='font-bold'>Step two:</span> Think about the ways your Working Genius results can help shape your future
                    educational and career pursuits, as well as your relationships.
                </p>

                <h2 className={'text-2xl font-bold uppercase mt-5'}>Other resources</h2>

                <p className={'text-12 font-normal mt-2 mb-6'}>
                    Taking the Working Genius Assessment is the first step in discovering your gifts and experiencing how
                    it can transform your life. The Working Genius team has created several resources for you to go
                    deeper. Discover how Working Genius applies to everything from teamwork and productivity to
                    relationships with family and friends.
                </p>

                <div className='flex flex-row gap-3 mb-6'>
                    {renderResource(resources)}
                </div>

                <img src={student_report_qr} alt={'resources_qr'} />
            </div>
        </div>
    );
};

export default Applying;
