import React from 'react';
import { WidgetReportV2Spanish } from '../../../../images';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const Overview = () => {
	return (
		<div className='OverviewSpanish'>
			<PageHeader title={'RESUMEN'} />

			<div className={'container brief'}>
				<PageTitle title={'Un breve resumen de Working Genius'} />

				<p>
					Todos tenemos talentos naturales, dados por Dios, en lo que respecta al trabajo. Resulta que existen
					seis tipos diferentes de talentos o genialidades y cada una de ellas es necesaria para llevar a cabo
					cualquier tarea.
				</p>
				<p>
					Cada uno de nosotros tiene áreas que son consideradas como verdaderas genialidades o dones. Estas
					son actividades que nos alegran, energizan y apasionan. A estas áreas las llamamos{' '}
					<span className='font-bold'>Genialidades en las que eres sobresaliente</span>. Dos de los seis tipos
					son lo que llamamos <span className='font-bold'>Genialidades que te frustran</span>, actividades que
					te roban la alegría y la energía. La mayoría de nosotros no somos muy habilidosos en esas áreas. Las
					últimas dos son las áreas a las que nos referimos como{' '}
					<span className='font-bold'>Genialidades en las que eres competente</span>, actividades que no te
					energizan ni te drenan y que puedes hacer bastante bien por un periodo de tiempo limitado.
				</p>
				<p>
					Los seis tipos de Working Genius conforman un modelo interdependiente, un proceso integral para
					llevar a cabo cualquier tipo de trabajo. Cada tipo de genialidad recibe y le ofrece algo a los tipos
					adyacentes, lo que crea un flujo de trabajo natural que comienza con el Pensamiento y termina con la
					Tenacidad. El siguiente modelo describe los seis tipos de genialidades e incluye una pequeña
					definición de cada una de ellas.
				</p>
			</div>

			<div className='container six-types'>
				<h1 className='title'>LOS SEIS TIPOS</h1>

				<hr />

				<img src={WidgetReportV2Spanish} alt='' className={'w-2/3 mx-auto p-4'} />

				<p>
					<strong>PENSAMIENTO</strong>: identifica la necesidad de mejorar o cambiar.
				</p>
				<p>
					<strong>CREATIVIDAD</strong>: confirma la importancia de esa necesidad y genera una idea o solución.
				</p>
				<p>
					<strong>DISCERNIMIENTO</strong>: evalúa el mérito y factibilidad de la idea o solución.
				</p>
				<p>
					<strong>INFLUENCIA</strong>: genera entusiasmo y acción en torno a la idea o solución.
				</p>
				<p>
					<strong>FACILITACIÓN</strong>: comienza a generar el apoyo necesario y ayuda en la implementación de
					la idea o solución.
				</p>
				<p>
					<strong>TENACIDAD</strong>: se compromete a asegurar que la idea o solución se ejecute y se alcance
					el resultado deseado.
				</p>
			</div>
		</div>
	);
};

export default Overview;
