import React from 'react';
import LineBreak from './LineBreak';
import Results from './Results';
import ActualResult from './ActualResult';
import UtilityService from '../../../services/UtilityService';
import AppButton from '../../AppButton';
import AppTooltip from '../../AppTooltip';

const MobileEdit = ({ roster, results, options, newResults, loading, onReset, closeModal, toggleOption, onSubmit }) => {
	const renderToggle = (option, key, res, resKey) => {
		const isSelected = res.props.indexOf(option) > -1;
		return (
			<div className='flex flex-1' key={key}>
				<AppTooltip
					type={'small'}
					content={() => <p className={'text-white text-center text-tiny font-normal'}>{option}</p>}
					placement={'bottom'}
				>
					<div
						id={`wg-edit-option-${key}`}
						className={`flex items-center justify-center w-8 h-8 rounded-full cursor-pointer`}
						style={{ backgroundColor: isSelected ? res.color : '#E5E7EB' }}
						onClick={() => toggleOption(option, resKey)}
					>
						<span className={`${isSelected ? 'text-white' : 'text-gray-500'}`}>{option[0]}</span>
					</div>
				</AppTooltip>
			</div>
		);
	};

	return (
		<div className='share-assessment-slide-over'>
			<div className='share-assessment-info'>
				<p className='share-assessment-heading'>Edit Assessment Results</p>
				<p className='share-assessment-text'>
					If after reviewing your report you would like to adjust your results, please make your selections
					below.
				</p>
			</div>

			<LineBreak />
			<div className='flex flex-1 flex-col mb-4'>
				<span className={'mb-2'}>Your original results</span>
				<Results results={roster} />
			</div>
			{!!roster.new_wg1 && (
				<div className='flex flex-1 flex-col mb-4'>
					<span className={'mb-2'}>Your current results</span>
					<Results results={roster} isNew={true} />
				</div>
			)}
			<LineBreak />
			{results.map((res, key) => {
				return (
					<div className={'flex flex-col'} key={key}>
						<div className='flex mt-4 mb-2'>
							<div className={'flex flex-1 flex-col'}>
								<p>{res.title}</p>
								<p className={'text-gray-500 text-tiny'}>{res.description}</p>
							</div>
						</div>
						<div className='flex'>
							{options.map((option, opKey) => {
								return renderToggle(option, opKey, res, key);
							})}
						</div>
					</div>
				);
			})}
			<LineBreak />
			{newResults.length > 0 && (
				<>
					<div className='flex flex-1'>
						<div className='flex flex-1 flex-col mr-4'>
							<span className={'mb-2'}>Your updated results</span>
							<div className='flex flex-row'>
								<ActualResult
									result={results[0].props[0]}
									className={'mr-2'}
									backgroundColor={UtilityService.geniusColor}
								/>
								<ActualResult
									result={results[0].props[1]}
									className={'mr-4'}
									backgroundColor={UtilityService.geniusColor}
								/>
								<ActualResult
									result={results[1].props[0]}
									className={'mr-2'}
									backgroundColor={UtilityService.competencyColor}
								/>
								<ActualResult
									result={results[1].props[1]}
									className={'mr-4'}
									backgroundColor={UtilityService.competencyColor}
								/>
								<ActualResult
									result={results[2].props[0]}
									className={'mr-2'}
									backgroundColor={UtilityService.frustrationColor}
								/>
								<ActualResult
									result={results[2].props[1]}
									className={'mr-4'}
									backgroundColor={UtilityService.frustrationColor}
								/>
							</div>
						</div>
					</div>
					<LineBreak />
				</>
			)}
			<div className='flex flex-col'>
				<div className='flex'>
					<AppButton
						id={`wg-edit-clear-results`}
						text={'Clear selection'}
						className={'btn btn-secondary w-full'}
						loading={loading}
						onClick={onReset}
						disabled={newResults.length === 0}
					/>
				</div>
				<LineBreak />
				<div className='flex flex-1 '>
					<div className='flex flex-1'>
						<AppButton
							id={`wg-edit-save-results`}
							text={'Save'}
							className={'btn btn-primary w-full mr-2'}
							onClick={onSubmit}
							disabled={newResults.length !== 6}
							loading={loading}
						/>
					</div>
					<div className='flex flex-1'>
						<AppButton
							id={`wg-edit-cancel-results`}
							text={'Cancel'}
							className={'btn btn-secondary w-full ml-2'}
							onClick={closeModal}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MobileEdit;
