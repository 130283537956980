import React from 'react';
import AppButton from '../AppButton';
import { student_discussing } from '../../images';

const FreeDiscussionGuide = () => {
    return (
        <div className='students-free-discussion'>
            <div className='container'>
                <div className=' flex flex-col lg:flex-row items-center'>
                    <div className='students-free-discussion-img flex-1'>
                        <img src={student_discussing} alt='The 6 Types of the Working Genius Students' />
                    </div>
                    <div className='students-free-discussion-text flex-1'>
                        <h3 className='text-3xl font-medium'>FREE Discussion Guide</h3>

                        <h4 className='font-semibold'>NEW!</h4>

                        <p className='pb-1'>
                            A guide designed for parents, coaches, and teachers to facilitate meaningful discussions with students about their natural gifts and areas of frustration.
                        </p>
                        <a
                            className={'btn btn-student'}
                            href='https://files.tablegroup.com/wp-content/uploads/2024/03/28093806/Working_Genius_Student_Discussion_Guide.pdf'
                            target='_blank'
                        >
                            Download now
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FreeDiscussionGuide;
