import React, { useState } from 'react';
import AppButton from '../../AppButton';
import AssessmentsService from '../../../services/AssessmentsService';
import { useTranslation } from 'react-i18next';
import AssessmentErrorModal from '../AssessmentErrorModal';

const Questions = ({
	assessmentId,
	rosterId,
	step,
	totalQuestionSteps,
	allQuestions,
	questions,
	responses,
	nextStep,
	previousStep,
	onAnswer,
	setTies,
	setAssessmentResponse,
	studentMode,
}) => {
	const [submitted, setSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);
	const [errorModal, setErrorModal] = useState('');
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

	const { t } = useTranslation();

	const onNext = () => {
		const firstNotAnswered = questions.findIndex((q) => q.answer === -1);
		if (firstNotAnswered > -1) {
			setSubmitted(true);
			const violation = document.getElementById(`question-${questions[firstNotAnswered].sort}`);
			window.scrollTo({
				top: violation.offsetTop,
				behavior: 'smooth',
			});
		} else {
			setLoading(true);
			const _payload = allQuestions
				.filter((q) => q.answer > -1)
				.map((q) => ({
					name: `q${q.id}`,
					value: q.answer,
				}));
			AssessmentsService.updateProgress(assessmentId, rosterId, step, _payload, totalQuestionSteps === step)
				.then((response) => {
					if (response.ties) {
						setTies(response.ties, nextStep);
					} else {
						setAssessmentResponse(response, nextStep);
					}
				})
				.catch((e) => {
					setLoading(false);
					setErrorModal(e.code ? e.code : e.message.code);
					setIsErrorModalOpen(true);
				});
		}
	};

	return (
		<div className={'flex flex-col mx-4'}>
			{questions.map(({ question, answer, id, sort }, key) => {
				return (
					<div className={'wg-question'} key={key} id={`question-${sort}`}>
						<h1
							className={`question-title ${submitted && answer === -1 ? 'error' : ''}`}
							dangerouslySetInnerHTML={{ __html: studentMode ? question : t(`assessment.take.questions.${sort}`) }}
						/>
						<div className={'options-container'}>
							{responses.map((option, optionKey) => {
								return (
									<div
										className={`option-container ${optionKey === 0 ? 'first' : ''}`}
										key={option.value}
										onClick={() => onAnswer(id, option.value)}
									>
										<input
											type='radio'
											value={option.value}
											name={id}
											checked={option.value === answer}
											onChange={() => onAnswer(id, option.value)}
										/>
										<p className={'ml-2 md:text-lg text-primary'}>
											{studentMode ? option.answer : t(`assessment.take.responses.${option.value}`)}
										</p>
									</div>
								);
							})}
						</div>
					</div>
				);
			})}
			<div className='flex justify-between my-4'>
				<AppButton
					className={'btn btn-secondary'}
					text={t('back')}
					onClick={previousStep}
					disabled={loading}
					id={'wg-assessment-questions-back-btn'}
				/>
				<AppButton
					text={t('next')}
					onClick={onNext}
					loading={loading}
					id={'wg-assessment-questions-next-btn'}
				/>
			</div>
			<AssessmentErrorModal
				isOpen={isErrorModalOpen}
				error={t(`assessment.take.welcome.error.${errorModal ? errorModal : 'UNDEFINED_ERROR'}`)}
				closeModal={() => setIsErrorModalOpen(false)}
			/>
		</div>
	);
};

export default Questions;
