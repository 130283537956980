import React from 'react';
import { useState } from 'react';
import { avatar } from '../../images';

const SharedEmail = ({ email }) => {
	return (
		<>
			<div className='shared-with-item flex flex-row items-center'>
				<img className={'object-contain mr-4'} width='32' src={avatar} alt='' />
				<p className='text-14 font-medium'>{email}</p>
			</div>
			<hr className='slide-over-break' />
		</>
	);
};

const SharedWith = ({ emails }) => {
	const [sharedEmails, setSharedEmails] = useState(emails.split(','));
	return (
		<>
			<p className='share-assessment-heading mt-6'>Shared with</p>
			<hr className='slide-over-break' />
			{sharedEmails &&
				sharedEmails.map((email, i) => {
					return <SharedEmail key={i} email={email} />;
				})}
		</>
	);
};

export default SharedWith;
