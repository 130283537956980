import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import config from '../constants/config';

const ReCaptcha = React.forwardRef(({ position = 'bottomright', hidden = true, className = '' }, ref) => {
	const captchaRef = React.useRef('captcha');
	const language = localStorage.getItem('wglang') || 'en';

	React.useImperativeHandle(ref, () => ({
		async getCaptchaValue(onFailOrCancel) {
			if (!config.captcha_enabled) return null;
			return await captchaRef.current
				?.executeAsync()
				.then((token) => {
					return token;
				})
				.catch(() => {
					if (onFailOrCancel) onFailOrCancel();
				})
				.finally(() => {
					captchaRef.current.reset();
				});
		},
	}));

	if (!config.captcha_enabled) return <div ref={ref}></div>;

	return (
		<div ref={ref} className={`${className}`}>
			<ReCAPTCHA
				ref={captchaRef}
				sitekey={config.captcha_site_key}
				hl={language}
				size='invisible'
				badge={position}
				hidden={hidden}
			/>
		</div>
	);
});

export default ReCaptcha;
