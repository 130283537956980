import React, { useEffect, useState } from 'react';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import AppModal from '../AppModal';
import i18n from 'i18next';
import config from '../../constants/config';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const options = [
	{
		value: 'en',
		label: 'English (US)',
		icon: 'hello-en',
	},
	{
		value: 'es',
		label: 'Español',
		icon: 'hola-es',
	},
	{
		value: 'de',
		label: 'Deutsch',
		icon: 'halo-de',
	},
];

const LanguageSelectionModal = ({ isOpen, setSelectedLanguage, closeModal }) => {
	const [loading, setLoading] = useState(false);
	const [selectedModalLanguage, setSelectedModalLanguage] = useState(options[0]);

	const save = () => {
		setLoading(true);
		i18n.changeLanguage(selectedModalLanguage.value);
		setSelectedLanguage({ ...selectedModalLanguage });
		closeModal();
	};

	return (
		<AppModal id={'app-confirm-close-modal-id'} isOpen={isOpen} style={customStyles}>
			<div className={`bg-white p-6 ${config.name}`} style={{ maxWidth: '600px' }}>
				<h1 className={'flex flex-1 justify-center text-primary text-lg leading-6 font-medium pb-4'}>
					Select Language
				</h1>
				<div className={'flex flex-col justify-around'}>
					{options.map((option, optionKey) => {
						return (
							<div
								className={'flex flex-row items-center justify-between p-3 cursor-pointer hover:bg-blue-100'}
								key={option.value}
								onClick={() => setSelectedModalLanguage(option)}
							>
								<div style={{ width: '250px' }}>
									<input
										className={'cursor-pointer inline'}
										type='radio'
										value={option.value}
										name={optionKey}
										checked={option.value === selectedModalLanguage.value}
										onChange={() => setSelectedModalLanguage(option)}
									/>
									<span className='text-base text-gray-900 leading-6 font-medium mx-2'>{option.label}</span>
								</div>
								<AppIcon icon={option.icon} className={'text-2xl text-primary m-2 '} />
							</div>
						);
					})}
				</div>
				<div className='flex my-4'>
					<AppButton
						onClick={save}
						text={'CONFIRM'}
						className={'btn btn-primary w-full'}
						id={'wg-lang-selection-modal-btn'}
						disabled={selectedModalLanguage.value === ''}
						loading={loading}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default LanguageSelectionModal;
