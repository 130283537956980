import React from 'react';

const PolicyContent = ({ contentData }) => (
	<div className={'privacy-content py-8'}>
		{contentData.map((sectionData) => (
			<div className={'w-full md:w-8/12'}>
				<h2 className={'font-medium text-xl text-primary py-2'}>{sectionData?.title}</h2>
				<span className={'py-2'}>{sectionData?.content}</span>
			</div>
		))}
	</div>
);

export default PolicyContent;
