import React from 'react';
import AppIcon from '../AppIcon';
import { patrick_plain } from '../../images';

const HomePat = () => {
	return (
		<div className='home-pat-section' id={'home-pat-section-id'}>
			<div className='container'>
				<div className='home-pat-content'>
					<div className='home-pat-text text-white text-center py-10 mx-auto w-full lg:w-2/3'>
						<p className='text-xl md:text-2xl leading-8 my-4'>
							"This groundbreaking new model and assessment have revolutionized the way I look at work and
							teams. I am excited for everyone to understand their gifts and frustrations so they can
							better experience fulfillment and dignity in their work."
						</p>
						<p className='text-2xl leading-10 my-8'>
							And it only takes <span className='font-bold'>10 minutes</span>.
						</p>
						<p className='text-2xl my-8'>&#8212;</p>
						<p className='text-lg font-semibold tracking-wider'>
							<AppIcon icon={'wg-gear'} className={'text-secondary text-xl'} /> PATRICK LENCIONI, <br />
							Author &#38; Creator of The 6 Types of Working Genius
						</p>
					</div>
				</div>
			</div>
			<div className='home-pat-mobile flex items-center justify-center lg:hidden my-4'>
				<img src={patrick_plain} alt='Patrick Lencion' width='320' />
			</div>
		</div>
	);
};

export default HomePat;
