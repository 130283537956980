import React from 'react';
import AppIcon from './AppIcon';

const map = {
	error: {
		icon: 'cancel',
		bg: 'error',
	},
	success: {
		icon: 'ok',
		bg: 'success',
	},
};

const AppFeedbackMessage = ({ message, type = 'error', ...props }) => {
	if (!message) {
		return null;
	}

	const { icon, bg } = map[type];

	return (
		<div className={`flex flex-row bg-${bg}-message rounded-lg p-4 mb-4 shadow-md`} {...props}>
			<div className={'mr-4'}>
				<div className={`flex justify-center items-center content-center w-5 h-5 rounded-full bg-${bg}`}>
					<AppIcon icon={icon} className={`text-${bg}-message text-tiny`} />
				</div>
			</div>
			<span className={`text-${bg}`}>{message}</span>
		</div>
	);
};

export default AppFeedbackMessage;
