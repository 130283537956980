import React from 'react';
import AppIcon from './AppIcon';
import AppModal from './AppModal';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: 0,
	},
};

const AppVideoModal = ({ url, closeModal }) => {
	return (
		<AppModal id={'app-vimeo-modal-id'} isOpen={!!url} style={customStyles} onRequestClose={closeModal}>
			<div className={'relative bg-transparent'} style={{ width: '900px', maxWidth: '100%' }}>
				<div className={'absolute top-0 right-0 left-0 bottom-0 w-full h-full bg-gray-300'}>
					<div className={'flex flex-1 justify-center content-center items-center h-full'}>
						<div className='spinner'>
							<div className='double-bounce1' />
							<div className='double-bounce2' />
						</div>
					</div>
				</div>
				<div className='bg-primary relative h-12'>
					<div className='flex justify-end h-12 items-center mr-2'>
						<AppIcon
							icon={'cancel'}
							className={'text-white text-2xl cursor-pointer'}
							onClick={closeModal}
						/>
					</div>
				</div>
				<div
					style={{
						position: 'relative',
						padding: '56.25% 0 0 0',
					}}
				>
					<iframe
						id='player-iframe'
						title='video-player-iframe'
						src={url}
						frameBorder='0'
						allow='autoplay; fullscreen'
						allowFullScreen
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
						}}
					/>
				</div>
			</div>
		</AppModal>
	);
};

export default AppVideoModal;
