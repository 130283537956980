import React from 'react';
import { wg_logo } from '../../../images';

const Header = ({ title }) => (
	<div className='PageHeader'>
		<span className={'text-white text-2xl font-semibold'}>{title}</span>
		<img width='256' src={wg_logo} />
	</div>
);

export default Header;