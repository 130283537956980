import React, { useEffect, useState } from 'react';
import OrdersFilters from './OrdersFilters';
import OrderDetails from './OrderDetails';
import UtilityService from '../../services/UtilityService';
import AppTable from '../AppTable';
import OrdersService from '../../services/OrdersService';

const OrdersTable = ({ ordersCount }) => {
	const initialFilters = {
		search: '',
		sort_key: 'purchased',
		sort_dir: 'desc',
		page: 0,
		limit: 25,
	};

	const [loading, setLoading] = useState(true);
	const [loadingMore, setLoadingMore] = useState(false);
	const [filters, setFilters] = useState(initialFilters);
	const [orders, setOrder] = useState({
		meta: {},
		data: [],
	});

	const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
	const [modalOrderID, setModalOrderId] = useState();

	useEffect(() => {
		getOrder();
	}, []);

	const getOrder = (_filters = filters, loader = setLoading, merge = false) => {
		loader(true);
		OrdersService.list(_filters)
			.then((data) => {
				const _orders = merge
					? {
							meta: data.meta,
							data: [...orders.data, ...data.data],
					  }
					: data;
				setOrder(_orders);
			})
			.finally(() => loader(false));
	};

	const columns = [
		{
			header: 'Date',
			key: 'purchased',
			render: (item) => {
				return <p className='text-primary'>{UtilityService.formatDate(item.purchased)}</p>;
			},
		},
		{
			header: 'Order Id',
			key: 'id',
			render: (item) => {
				return (
					<a
						id={`wg-table-order-item-${item.id}`}
						onClick={() => {
							setIsOrderDetailsModalOpen(true);
							setModalOrderId(item.id);
						}}
						className={'underline cursor-pointer text-primary'}
					>
						{item.id}
					</a>
				);
			},
		},
		{
			header: 'Amount',
			key: 'total',
			render: (item) => {
				return (
					<p className='text-primary'>
						$
						{Number(parseFloat(item.total).toFixed(2)).toLocaleString('en', {
							minimumFractionDigits: 2,
						})}
					</p>
				);
			},
		},
		{
			header: 'Status',
			key: 'status',
			render: (item) => {
				return <p className='text-primary font-semibold'>{item.status}</p>;
			},
		},
	];

	const onSort = (col, sort_dir) => {
		const newFilters = {
			...filters,
			sort_key: col.key,
			sort_dir: sort_dir,
			page: 0,
		};
		setFilters(newFilters);
		getOrder(newFilters);
	};

	const onPageChange = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getOrder(newFilters);
	};

	const onLoadMore = (page) => {
		const newFilters = {
			...filters,
			page,
		};
		setFilters(newFilters);
		getOrder(newFilters, setLoadingMore, true);
	};

	const onSearch = () => {
		getOrder({
			...filters,
			page: 0,
		});
	};

	const onReset = () => {
		setFilters(initialFilters);
		getOrder(initialFilters);
	};

	return (
		<div id={'wg-orders-table'}>
			<AppTable
				wrapperClassname='m-auto'
				title='Orders'
				subTitle={`${ordersCount} purchased license${ordersCount !== 1 ? 's' : ''}`}
				renderFilters={OrdersFilters}
				columns={columns}
				data={orders.data}
				loading={loading}
				onSort={onSort}
				filters={filters}
				setFilters={setFilters}
				onPageChange={onPageChange}
				onLoadMore={onLoadMore}
				loadingMore={loadingMore}
				onSearch={onSearch}
				onReset={onReset}
				pagination={orders.meta}
			/>
			<OrderDetails
				isOpen={isOrderDetailsModalOpen}
				closeModal={() => setIsOrderDetailsModalOpen(false)}
				orderId={modalOrderID}
			/>
		</div>
	);
};

export default OrdersTable;
