import React from 'react';

const Header = ({ title, subtitle, desription, children, containerClass = '', headerNum = '', ...props }) => {
	return (
		<div className={`toolkit-header-section whitespace-pre-wrap ${containerClass}`} {...props}>
			<h1 className='title'>
				<div>{headerNum}</div>
				<div>{title}</div>
			</h1>
			<h2 className='subtitle'>{subtitle}</h2>
			<p className='description'>{desription}</p>
			{children}
		</div>
	);
};

export default Header;
