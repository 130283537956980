import React, { useState, useEffect } from 'react';
import PodcastService from '../../../services/PodcastService';

import AppModal from '../../AppModal';
import AppButton from '../../AppButton';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const DownloadReport = ({ isOpen, closeModal, podcast, captchaRef }) => {
	const [downloaded, setDownloaded] = useState(false);

	const downloadPdf = async () => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setDownloaded(false);
		PodcastService.getPodcastPdf(recaptchaValue, podcast?.slug, podcast?.title).then(() => setDownloaded(true));
	}

	useEffect(  () => {
		if (isOpen) {
			downloadPdf();
		}
	}, [isOpen]);

	return (
		<AppModal id={'app-download-report-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='flex flex-col max-w-screen-sm bg-white py-4 px-8' style={{ width: '400px' }}>
				<span className={'text-2xl text-center text-primary font-semibold'}>
					{downloaded ? 'Downloaded!' : 'Downloading...'}
				</span>
				<p className={'my-4 text-center'}>
					{downloaded
						? 'Podcast pdf downloaded successfully!'
						: "Please wait while we're processing your request..."}
				</p>

				<AppButton
					className={'btn btn-primary w-full'}
					text={'Done'}
					onClick={() => closeModal()}
					loading={!downloaded}
					id={'wg-download-report-btn'}
				/>
			</div>


		</AppModal>
	);
};

export default DownloadReport;
