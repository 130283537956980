import React from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components } from 'react-select';
import AppRIIcon from './AppRIIcon';

const AppAutocomplete = ({ creatable, innerRef, option = components.Option, ...props }) => {
	if (creatable) {
		return (
			<AsyncCreatableSelect
				{...props}
				ref={innerRef}
				className={`app-autocomplete ${props.className}`}
				components={{
					Option: option,
					DropdownIndicator: null,
					IndicatorSeparator: null,
					ClearIndicator: ({ innerProps: { ref, ...restInnerProps } }) => (
						<AppRIIcon
							icon={'close-circle-line'}
							{...restInnerProps}
							ref={ref}
							style={{ padding: '0px 5px', cursor: 'pointer' }}
						/>
					),
				}}
			/>
		);
	}
	return (
		<AsyncSelect
			{...props}
			ref={innerRef}
			className={`app-autocomplete ${props.className}`}
			components={{ Option: option }}
		/>
	);
};

export default AppAutocomplete;
