import React from 'react';
import AppRIIcon from './AppRIIcon';
import AppButton from './AppButton';
import { navigate } from 'gatsby';

const AppScrollableHeader = ({
	id,
	icon,
	title,
	subtitle,
	children,
	className,
	titleClassName,
	pdfLink,
	imgIcon,
	link = '',
	linkText = '',
}) => {
	return (
		<div className={`app-scrollable-header ${className ? className : ''}`} id={id}>
			<div className={'flex flex-1 flex-row items-center pl-6 md:pl-2 lg:pl-8 py-6 md:py-4 lg:py-8'}>
				{icon && <AppRIIcon icon={icon} />}
				{imgIcon && <img src={imgIcon} width='70' className='m-2' />}
				<div>
					<h2 className={`title ${titleClassName ? titleClassName : ''}`}>{title}</h2>
					{!!subtitle && <p className={`subtitle ${titleClassName ? titleClassName : ''}`}>{subtitle}</p>}
					{link && linkText && (
						<div className={'pl-1 w-full'} onClick={() => navigate('/wg-podcast-post')}>
							<span className={'text-secondary text-md cursor-pointer font-semibold underline hover:text-darkenSecondary'}>
								{linkText}
							</span>
						</div>
					)}
				</div>
			</div>
			{pdfLink && (
				<div className='flex self-start md:self-end'>
					<AppButton
						className={'btn btn-primary m-2'}
						text={'Download pdf'}
						onClick={() => {
							window.open(pdfLink);
						}}
					/>
				</div>
			)}
			{children}
		</div>
	);
};

export default AppScrollableHeader;
