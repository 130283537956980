import React from 'react';

import AppRIIcon from '../AppRIIcon';
import AppModal from '../AppModal';
import { report_company_logo_preview } from '../../images';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const CompanyLogoPreviewModal = ({ isOpen, closeModal, srcUrl, srcFile }) => {
	const companyLogoImageSrc = srcUrl ?? (srcFile ? URL.createObjectURL(srcFile) : null);
	return (
		<AppModal
			id='assessment-company-logo-preview-modal'
			isOpen={isOpen}
			style={customStyles}
			onRequestClose={closeModal}
		>
			<div className='bg-white shadow-xl rounded-lg p-6 pt-4'>
				<div className='flex flex-row justify-end'>
					<button onClick={() => closeModal()}>
						<AppRIIcon icon='close-fill' className='text-2xl text-gray-600' />
					</button>
				</div>
				<div className='relative shadow-lg'>
					<img className='relative' src={report_company_logo_preview} alt='' />
					<div
						className='absolute flex justify-center h-10'
						style={{ left: '10px', bottom: '8px', width: '105px' }}
					>
						<img className='max-w-[130px] max-h-10' src={companyLogoImageSrc} />
					</div>
				</div>
			</div>
		</AppModal>
	);
};

export default CompanyLogoPreviewModal;
