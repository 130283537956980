import React from 'react';
import ReportService from '../../../../services/ReportService';
import ColorGearImage from '../../ColorGearImage';

const Results = ({ results }) => {
    const renderGenii = (genii, color) => {
        return (
            <div className='flex flex-row sm:flex-col items-center'>
                {genii.map((g, key) => {
                    return (
                        <div key={key}>
                            <ColorGearImage
                                type={g.label}
                                color={color}
                                height={'50px'}
                                width={'50px'}
                                style={{ margin: '5px 10px' }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className='student-assessment-complete-results'>
            <p className={'text-primary font-semibold text-base md:text-lg mb-4'}>
                The information below summarizes the results of your assessment.
            </p>
            <div className='resultset genius flex flex-col sm:flex-row sm:items-center bg-white p-2 mb-4'>
                {renderGenii(
                    [results[0], results[1]],
                    ReportService.genius_color
                )}
                <div className='flex flex-col sm:ml-4'>
                    <h2 className={'text-base sm:text-lg xl:text-xl font-bold'}>WORKING GENIUS:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Genius(
                                results[0].label,
                                results[1].label,
                                ReportService.genius_color
                            ),
                        }}
                    />
                </div>
            </div>
            <div className='resultset competency flex flex-col sm:flex-row sm:items-center bg-white p-2 mb-4'>
                {renderGenii(
                    [results[2], results[3]],
                    ReportService.competency_color
                )}
                <div className='flex flex-col sm:ml-4'>
                    <h2 className={'text-base sm:text-lg xl:text-xl font-bold'}>WORKING COMPETENCY:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Competency(
                                results[2].label,
                                results[3].label,
                                ReportService.competency_color
                            ),
                        }}
                    />
                </div>
            </div>
            <div className='resultset frustration flex flex-col sm:flex-row sm:items-center bg-white p-2'>
                {renderGenii(
                    [results[4], results[5]],
                    ReportService.frustration_color
                )}
                <div className='flex flex-col sm:ml-4'>
                    <h2 className={'text-base sm:text-lg xl:text-xl font-bold'}>WORKING FRUSTRATION:</h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: ReportService.Results.Frustration(
                                results[4].label,
                                results[5].label,
                                ReportService.frustration_color
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Results