import React from 'react';
import AppButton from '../AppButton';
import AppRIIcon from '../AppRIIcon';

const WebinarEvent = ({ event, onRegister, isPastEvent = false }) => {
	const onClick = () => isPastEvent ? window.open(event?.event_recording) : onRegister(event);

	return (
		<div className={'webinar-event-item'}>
			<span className={'text-xl text-center text-primary font-semibold leading-7 pb-4'}>For {event?.title}</span>

			<div className={'flex flex-col'}>
				<div className='event-info items-start'>
					<div className='event-item-icon'>
						<AppRIIcon icon={'calendar-event-fill'} className={'text-2xl text-primary'} />
					</div>
					<div>
						<p className={'text-primary font-semibold text-xl'}>{event.start_date}</p>
					</div>
				</div>

				<div className='event-info items-center'>
					<div className='event-item-icon'>
						<AppRIIcon icon={'time-line'} className={'text-2xl text-primary'} />
					</div>
					<div>
						<p className={'text-primary font-semibold text-xl'}>{event.event_time}</p>
					</div>
				</div>

				<div className='event-info items-center'>
					<div className='event-item-icon'>
						<AppRIIcon icon={'map-pin-line'} className={'text-2xl text-primary'} />
					</div>
					<div>
						<p className={'text-primary font-semibold text-xl'}>{event.location || 'Virtual'}</p>
					</div>
				</div>

				<hr className={'bg-primary h-1 my-4'} />

				<AppButton
					className={'btn btn-primary mx-auto'}
					text={isPastEvent ? 'View Recording' : 'Learn More And Register'}
					onClick={onClick}
					id={'wg-webinar-event-register-btn'}
				/>
			</div>
		</div>
	);
}

export default WebinarEvent;
