import React from 'react';

import WorkshopLayout from '../../../components/workshop/WorkshopLayout';
import { DCAResources } from '../../../components/dca/index';

const DCAClaimResources = () => (
	<WorkshopLayout title={'The 6 Types of Working Genius: DCA Claim Resources'}>
		<DCAResources />
	</WorkshopLayout>
);

export default DCAClaimResources;
