import React from 'react';
import { going_deep_play, going_deep_video_1 } from '../../../../images';

const GoingDeeper = () => {
	return (
		<div className='Page__content ApplicationSection__content'>
			<div className='top'>
				<h1>APPLICATION SECTION</h1>
			</div>

			<div className='content'>
				<h1>Going Deeper</h1>

				<p>
					<b>
						If you watch just one video about the Working Genius model, make it this one. Pat explains the
						model in depth and explores the various applications in work and home life. (17 minutes)
					</b>
				</p>

				<div id='video-1' className='video'>
					<div className='bg'>
						<img src={going_deep_video_1} width='600' height='305' alt={''} />
					</div>
					<a href='https://vimeo.com/467920771' target='_blank'>
						<img src={going_deep_play} alt={''} />
					</a>
				</div>
			</div>
		</div>
	);
};

export default GoingDeeper;
