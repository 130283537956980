import React from 'react';
import AppButton from '../AppButton';
import AppIcon from '../AppIcon';
import Countdown from 'react-countdown';
import { addToCart } from '../../services/CustomerService';
import ApiService from '../../services/ApiService';

const EventHero = ({ streamHTML }) => {
	const Completionist = () => <span>The Six Types of Working Genius</span>;

	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <Completionist />;
		} else {
			return (
				<span>
					Broadcast will begin here in{' '}
					<b>
						{days} days : {hours} hours : {minutes} minutes : {seconds} seconds
					</b>
				</span>
			);
		}
	};

	return (
		<div className='event-hero-section'>
			<div className='container'>
				<div className='launch-event-hero-container flex flex-col items-center justify-center text-center'>
					<h1 className='text-2xl lg:text-4xl text-white font-normal'>
						Welcome to The Working Genius <span className='font-semibold'>Book Launch Event</span>
					</h1>
					<p className='text-white text-xl font-semibold tracking-widest mb-8'>
						On Demand Access Available through Oct 15, 2022
					</p>
					<div dangerouslySetInnerHTML={{ __html: streamHTML }} />
					{!streamHTML && (
						<div
							className='w-full md:w-3/5 mx-auto bg-white flex flex-col items-center justify-center my-8'
							style={{ height: '60vh' }}
						>
							<div className='gears-animation'>
								<div className='gears-group-1'>
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
								</div>
								<div className='gears-group-2'>
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
									<AppIcon icon={'wg-gear'} className={'gear spin'} />
								</div>
							</div>
							<div className={'flex text-lg md:text-xl text-primary justify-center mt-4'}>
								<Countdown date={new Date('2022-09-27T19:00:00Z')} renderer={renderer} />
							</div>
						</div>
					)}
					<div className='flex flex-col justify-center'>
						<p className='text-white text-lg uppercase font-semibold my-4 tracking-widest'>What's next?</p>
						<div className='flex flex-col lg:flex-row items-center justify-center'>
							<AppButton
								className={'btn btn-primary m-2'}
								onClick={() => addToCart('add_license', true)}
								text={'Take the 10-minute assessment today'}
								id={'wg-event-take-assessment-btn'}
							/>
							<a
								href='https://www.amazon.com/Types-Working-Genius-Understand-Frustrations/dp/1637743297'
								id={'wg-event-hero-order-now-btn'}
								className={'btn btn-primary relative m-2'}
								target='_blank'
								rel='noreferrer'
							>
								Buy the book
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventHero;
