import React from 'react';
import { individual_resources, team_resources, event_podcast_new } from '../../images';
import AppButton from '../AppButton';
import { navigate } from 'gatsby';

const HomeResources = () => {
	return (
		<div className='home-resources-section'>
			<div className='container'>
				<h2 className='text-2xl md:text-4xl text-white text-center font-normal my-4'>
					Working Genius Resources
				</h2>
				<div className='resources-items'>
					<div className='resources-item'>
						<img src={individual_resources} alt='Get Certified' />
						<h3 className='title'>Resources for Teams</h3>
						<AppButton
							id={'wg-home-resources-learn-more-btn'}
							className={'btn btn-primary'}
							text={'Learn more'}
							onClick={() => navigate('/resources')}
						/>
					</div>
					<div className='resources-item'>
						<img src={event_podcast_new} alt='Podcast' />
						<h3 className='title'>Podcasts</h3>
						<AppButton
							id={'wg-home-listen-now-btn'}
							className={'btn btn-primary'}
							text={'Listen now'}
							onClick={() => navigate('/resources#podcasts')}
						/>
					</div>
					<div className='resources-item'>
						<img src={team_resources} alt='Resources for Individuals' />
						<h3 className='title'>Articles</h3>
						<AppButton
							id={'wg-home-view-topics-btn'}
							className={'btn btn-primary'}
							text={'View topics'}
							onClick={() => navigate('/resources#articles')}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeResources;
