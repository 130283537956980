import React from 'react';
import AppSlideOver from '../AppSlideOver';
import { clipboard } from '../../images';
import UtilityService from '../../services/UtilityService';
import ShareAssessmentWith from './ShareAssessmentWith';
import SharedWith from './SharedWith';

const ShareAssessment = ({ isOpen, onClose, item, onShare }) => {
	return (
		<AppSlideOver isOpen={isOpen} closeModal={onClose}>
			<div className='share-assessment-slide-over'>
				<div className='share-assessment-info'>
					<p className='share-assessment-heading'>Share Assessment</p>
					<p className='share-assessment-text'>
						To share a copy of your assessment results with another Working Genius user, please enter their
						email below. The report will remain in your console and a copy will be sent to their console as
						well.
					</p>
				</div>

				<div className='assessment-info flex flex-col items-center'>
					<img className={'object-contain mb-4'} width='40' src={clipboard} alt='' />
					<p className='share-assessment-heading text-base'>{item.fname + ' ' + item.lname}</p>
					<p className='share-assessment-text'>Individual Assessment</p>
					<p className='share-assessment-text'>
						Completed at {UtilityService.formatDate(item.completed ? item.completed : item.created)}
					</p>
				</div>
				<hr className='slide-over-break' />
				{item.shared_to && <SharedWith emails={item.shared_to} />}
				<ShareAssessmentWith onCancel={onClose} onShare={onShare} item={item} />
			</div>
		</AppSlideOver>
	);
};

export default ShareAssessment;
