import React from 'react';
import AppTooltip from '../AppTooltip';
import NotificationService from '../../services/NotificationService';
import AppIcon from '../AppIcon';
import { useUser } from '../../hooks/UserProvider';

const MarkAsReadToggle = ({ notification, onComplete }) => {
	const [loading, setLoading] = React.useState(false);
	const { hasPermission } = useUser();
	const isRead = notification.read;

	const changeNotificationReadStatus = () => {
		setLoading(true);
		NotificationService.toggleMarkAsRead({ id: notification.id, read: !isRead }).then(
			() => {
				onComplete();
				setLoading(false);
			},
			(error) => {
				setLoading(false);
				NotificationService.error(error.message);
			}
		);
	};
	if (!hasPermission('NOTIFICATION.PUT')) return null;
	return (
		<div onClick={changeNotificationReadStatus} style={{ width: '12px', minWidth: '12px', marginTop: '4px' }}>
			<AppTooltip
				content={() => (
					<p className={'text-white text-center text-tiny font-normal'}>
						{isRead ? 'Mark as unread' : 'Mark as read'}
					</p>
				)}
				type={'small'}
				wrapperClass={'action-tooltip-wrapper'}
				tooltipClass={'action-tooltip'}
				placement={'left'}
			>
				{loading ? (
					<div className={'relative'} id={'wg-notifications-mark-as-read-loading'}>
						<p className='absolute top-0' style={{ right: '-2px', top: '-10px' }}>
							<AppIcon icon={'loading'} className={'animate-spin'} style={{ fontSize: '10px' }} />
						</p>
					</div>
				) : (
					<p
						className={`${isRead ? 'bg-gray-300' : 'bg-secondary'
							} hover:bg-primary w-3 h-3 rounded-full cursor-pointer`}
						style={{ right: '5px' }}
					/>
				)}
			</AppTooltip>
		</div>
	);
};

export default MarkAsReadToggle;
