import React from 'react';
import { useForm, Controller } from 'react-hook-form';

import AppModal from '../AppModal';
import AppButton from '../AppButton';
import AppDropdown from '../AppDropdown';
import AppRIIcon from '../AppRIIcon';

import AppInput from '../AppInput';
import AppTextarea from '../AppTextarea';
import ReCaptcha from '../ReCaptcha';

import { wg_logo_primary } from '../../images';
import { emailRegex, phoneNumberRegex } from '../../services/UtilityService';

import WorkshopService from '../../services/WorkshopService';
import NotificationService from '../../services/NotificationService';
import CustomerService from '../../services/CustomerService';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
		maxHeight: '95vh',
	},
};

const WorkshopForm = ({ isOpen, onClose, title = '', type }) => {
	const [loading, setLoading] = React.useState(false);
	const [registered, setRegistered] = React.useState(false);
	const captchaRef = React.useRef('captcha');

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		clearErrors,
		setValue,
		control,
	} = useForm();

	React.useEffect(() => {
		if (!isOpen) {
			reset();
			setLoading(false);
			setTimeout(() => {
				setRegistered(false);
			}, 500);
		}
	}, [isOpen]);

	const _save = async (payload) => {
		const recaptchaValue = await captchaRef.current.getCaptchaValue();
		setLoading(true);
		WorkshopService.register({
			...payload,
			form_type: type,
			team_size: payload.team_size?.value ?? null,
			role: payload.role?.value ?? 'OTHER',
			captcha: recaptchaValue,
			company_size: payload?.company_size?.value || 'NOT_SPECIFIED',
			industry: payload?.industry?.value || 'OTHER',
		})
			.then(
				() => {
					setRegistered(true);
					CustomerService.triggerGoogleTagManagerEvent(
						{
							value: 0,
							item_category: 'workshop',
						},
						'generate_lead'
					);
				},
				(error) => {
					NotificationService.error(error.message);
				}
			)
			.finally(() => {
				setLoading(false);
			});
	};

	const renderContent = () => {
		if (registered) {
			return (
				<div className='flex flex-col text-center mt-8 md:mt-16 p-4'>
					<div className='flex-1 flex justify-center items-center p-8'>
						<img src={wg_logo_primary} alt='Working Geniuses Primary Logo' width='350' />
					</div>
					<h1 className={'text-2xl md:text-3xl text-primary font-thin mt-8'}>
						We are excited for you to Maximize <br className='hidden md:block' />
						Working Genius with your team.
					</h1>
					<p className={'text-sm text-primary font-semibold my-4 md:my-10'}>
						One of our Workshop Master Facilitators will contact you directly.
					</p>
				</div>
			);
		}
		return (
			<div className='p-4'>
				<h1 className={'text-2xl leading-8 font-medium capitalize text-gray-800 mb-2'}>{title}</h1>
				<form className={'flex flex-1 flex-col workshop-form'} onSubmit={handleSubmit(_save)}>
					<div className={'flex flex-col'}>
						<div className='flex-1 flex flex-col'>
							<div className={'workshop-form-fields-container'}>
								<AppInput
									label={'First name'}
									{...register('first_name', {
										required: true,
										maxLength: 40,
									})}
									ttgMaxLength={40}
									errors={errors}
									containerClassName={'w-full'}
								/>

								<AppInput
									label={'Last Name'}
									{...register('last_name', {
										required: true,
										maxLength: 80,
									})}
									ttgMaxLength={80}
									errors={errors}
									containerClassName={'w-full'}
								/>
							</div>

							<div className='workshop-form-fields-container'>
								<AppInput
									label={'Company'}
									{...register('company', {
										required: true,
										maxLength: 255,
									})}
									ttgMaxLength={255}
									errors={errors}
									containerClassName={'w-full'}
								/>
								<AppInput
									label={'Phone number'}
									{...register('phone', {
										required: true,
										maxLength: 32,
										pattern: {
											value: phoneNumberRegex,
										},
									})}
									ttgMaxLength={32}
									errors={errors}
									containerClassName={'w-full'}
								/>
							</div>

							<div className={'workshop-form-fields-container'}>
								<AppInput
									label={'Email'}
									{...register('email', {
										required: true,
										maxLength: 80,
										pattern: {
											value: emailRegex,
											message: 'should be a valid email',
										},
									})}
									onBlur={(e) => setValue('email', e.target.value.trim())}
									className={''}
									errors={errors}
									ttgMaxLength={80}
									containerClassName={'w-full'}
								/>
								<AppInput
									label={'Address'}
									{...register('address', {
										required: true,
										maxLength: 255,
									})}
									ttgMaxLength={255}
									errors={errors}
									containerClassName={'w-full'}
								/>
							</div>

							<div className='workshop-form-fields-container'>
								<Controller
									control={control}
									name='company_size'
									rules={{ required: 'You must select a company size' }}
									render={({ field, formState }) => (
										<AppDropdown
											{...field}
											placeholder=''
											label={'Company Size'}
											options={CustomerService.CustomerCompanySizes}
											shouldShowErrorMessage={true}
											errors={formState.errors}
											containerClassName={'w-full'}
											isSearchable={false}
											styles={{
												option: (provided, { isFocused }) => {
													return {
														...provided,
														backgroundColor: isFocused
															? 'bg-blue-200 !important'
															: 'white !important',
														color: 'text-gray-700',
													};
												},
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name='industry'
									rules={{ required: 'You must select an industry' }}
									render={({ field, formState }) => (
										<AppDropdown
											{...field}
											placeholder=''
											label={'Industry'}
											options={CustomerService.CustomerIndustries}
											shouldShowErrorMessage={true}
											errors={formState.errors}
											containerClassName={'w-full'}
											isSearchable={false}
											styles={{
												option: (provided, { isFocused }) => {
													return {
														...provided,
														backgroundColor: isFocused
															? 'bg-blue-200 !important'
															: 'white !important',
														color: 'text-gray-700',
													};
												},
											}}
										/>
									)}
								/>
							</div>
							<div className='workshop-form-fields-container'>
								<Controller
									control={control}
									name='role'
									rules={{ required: 'You must select a role' }}
									render={({ field, formState }) => (
										<AppDropdown
											{...field}
											placeholder=''
											label={'Role'}
											options={CustomerService.CustomerBusinessRoles}
											shouldShowErrorMessage={true}
											errors={formState.errors}
											containerClassName={'w-full'}
											isSearchable={false}
											styles={{
												option: (provided, { isFocused }) => {
													return {
														...provided,
														backgroundColor: isFocused
															? 'bg-blue-200 !important'
															: 'white !important',
														color: 'text-gray-700',
													};
												},
											}}
										/>
									)}
								/>
								<Controller
									control={control}
									name='team_size'
									rules={{ required: 'You must select a team size' }}
									render={({ field, formState }) => (
										<AppDropdown
											placeholder=''
											{...field}
											label={'Team Size'}
											options={CustomerService.WorkshopTeamSize}
											shouldShowErrorMessage={true}
											errors={formState.errors}
											isSearchable={false}
											containerClassName={'w-full'}
											styles={{
												option: (provided, { isFocused }) => {
													return {
														...provided,
														backgroundColor: isFocused
															? 'bg-blue-200 !important'
															: 'white !important',
														color: 'text-gray-700',
													};
												},
											}}
										/>
									)}
								/>
							</div>
						</div>
					</div>

					<AppTextarea
						containerClassName={'w-full mt-2'}
						{...register('scheduled_date', {
							required: true,
							maxLength: 255,
						})}
						errors={errors}
						ttgMaxLength={255}
						label={'When would you like to have this session?'}
					/>

					<AppTextarea
						containerClassName={'w-full mt-2'}
						{...register('interest', {
							required: true,
							maxLength: 1000,
						})}
						errors={errors}
						ttgMaxLength={1000}
						label={'Share why you are interested in a workshop and why this is the right time.'}
					/>

					<p className='font-bold text-14 text-primary my-1'>
						Has Your Team Taken the Working Genius Assessment?
					</p>
					<div className='workshop-radio-button justify-start'>
						<label htmlFor='taken-assessment-yes' className={'pr-5'}>
							<input
								{...register('taken_assessment', {
									required: true,
								})}
								type='radio'
								name='taken_assessment'
								value='yes'
								id='taken-assessment-yes'
							/>
							Yes
						</label>
						<label htmlFor='taken-assessment-no' className={'pr-5'}>
							<input
								{...register('taken_assessment', {
									required: true,
								})}
								type='radio'
								name='taken_assessment'
								value='no'
								id='taken-assessment-no'
							/>
							No
						</label>
						<label htmlFor='taken-assessment-some-have' className={'pr-5'}>
							<input
								{...register('taken_assessment', {
									required: true,
								})}
								type='radio'
								name='taken_assessment'
								value='some_have'
								id='taken-assessment-some-have'
							/>
							Some Have
						</label>
					</div>
					<div className='app-input-error-message mb-1'>
						{errors.taken_assessment?.type === 'required' && 'Please select an option'}
					</div>

					<div className={'flex items-center justify-end mt-2'}>
						<AppButton
							text={'Submit'}
							loading={loading}
							className={'btn btn-primary workshop-primary-btn'}
						/>
					</div>
				</form>
			</div>
		);
	};

	return (
		<AppModal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
			<div id={'wg-workshop-modal'} className={'flex items-center justify-center relative'}>
				<button onClick={onClose} className={'absolute right-0 top-0 mr-3 mt-3'}>
					<AppRIIcon icon='close-fill' className='text-3xl text-gray-600' />
				</button>
				<div className='flex items-center justify-center flex-col p-4 bg-white shadow-xl'>
					{renderContent()}
				</div>
				<div className={'flex items-start'}>
					<ReCaptcha ref={captchaRef} className={'workshop-captcha'} hidden={false} />
				</div>
			</div>
		</AppModal>
	);
};

export default WorkshopForm;
