import React from 'react';
import { teacher_and_student } from './../../images';

const ParentsChecklist = () => {
    return (
        <div className='container parents-checklist'>
            <h2 className='title'>
                <span className='font-bold'>Parents</span>, coaches, and teachers will:
            </h2>
            <div className='flex flex-col-reverse lg:flex-row items-stretch justify-between'>
                <img src={teacher_and_student} width='400' className={'mx-auto my-4 lg:m-0'} />
                <div className='flex-1 flex items-center' style={{ backgroundColor: '#EFEFEF' }}>
                    <ul className='parents-checklist-ul'>
                        <li>
                            <span>Understand</span> their students (&amp; themselves)
                        </li>
                        <li>
                            Have <span>better conversations</span> (with less resistance)
                        </li>
                        <li>
                            <span>Guide</span> their student's future with confidence
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ParentsChecklist