import React from 'react';
import EventItem from './EventItem';

const Events = ({ title, events, onRegister, loading }) => {
	if (loading) {
		return (
			<div id={'events-loading-spinner'} className='relative'>
				<div className='absolute top-0 left-0 right-0 bottom-0 ttg-loader-bg flex justify-center items-center'>
					<div className='spinner'>
						<div className='double-bounce1' />
						<div className='double-bounce2' />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className={'flex flex-1 flex-col justify-center my-5'}>
			<h2 className={'text-center text-primary text-4xl font-thin mb-4'}>{title}</h2>
			<div className='container mx-auto'>
				<div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8'}>
					{events.map((event, key) => {
						return <EventItem key={key} event={event} onRegister={onRegister} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default Events;
