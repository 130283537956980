import React, { useState } from 'react';
import AppButton from '../AppButton';
import RegisterForm from './RegisterForm';
import { widget_hr_primary, live_webinar, at_the_whiteboard_heading } from '../../images';

const CareersHero = () => {
	const [openRegisterModal, setOpenRegisterModal] = useState(false);

	return (
		<div className='at-the-whiteboard-hero-section'>
			<div className='container'>
				<div className='webinar-container'>
					<div className='relative flex flex-col items-center'>
						<img className={'live-webinar-badge'} src={live_webinar} alt='' width='180' />
						<img className={'relative p-4'} src={at_the_whiteboard_heading} alt='' width='580' />
					</div>
					<div className='flex flex-col justify-center items-center mt-8 mb-12'>
						<p className='text-white text-xl font-normal tracking-wide mb-4'>
							May 17th at 11:00AM Pacific Time <br />A Free, Live Virtual Event
						</p>
						<AppButton
							className={'btn btn-primary w-56'}
							onClick={() => setOpenRegisterModal(true)}
							text={'Register now'}
							id={'wg-launch-event-register-now-btn'}
						/>
					</div>
					<div className='bg-white rounded-lg w-4/5 mx-auto flex flex-col items-center py-15 px-6'>
						<h1 className='text-base md:text-2xl lg:w-1/2 text-primary font-medium px-4'>
							Join Patrick Lencioni as he explores how The Six Types of Working Genius can help you better
							understand jobs, hiring and your career.
						</h1>
						<div className='at-the-whiteboard-widget-hr'>
							<img className={'w-1/2 my-6 mx-auto px-8'} src={widget_hr_primary} alt='' />
						</div>
						<h3 className='text-primary text-lg lg:text-xl font-semibold my-6'>
							This event is perfect for anyone:
						</h3>
						<div className='at-the-whiteboard-list'>
							<span>Considering a career change</span>
							<span>Hiring new staff</span>
							<span>A new graduate (or parent of one!)</span>
							<span>In transition or considering one</span>
						</div>
					</div>
				</div>
			</div>
			<RegisterForm isOpen={openRegisterModal} onClose={() => setOpenRegisterModal(false)} />
		</div>
	);
};

export default CareersHero;
