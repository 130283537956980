import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		background: 'transparent',
	},
};

const ActionFeedbackModal = ({ isOpen, closeModal, title, message, loading }) => {
	return (
		<AppModal id={'wg-action-feedback-modal-id'} isOpen={isOpen} style={customStyles} onRequestClose={closeModal}>
			<div className='max-w-screen-sm bg-white py-4 px-8' style={{ width: '400px' }}>
				<h1 className={'text-2xl text-center text-primary text-center font-semibold'}> {title} </h1>
				<p className={'my-4 text-center'}>{message}</p>
				<AppButton className={'btn btn-primary w-full'} loading={loading} text={'DONE'} onClick={closeModal} />
			</div>
		</AppModal>
	);
};

export default ActionFeedbackModal;
