import React, { useEffect, useState } from 'react';
import { Pagination, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCurrentBreakpoint } from '../services/UtilityService';

const AppSwiper = ({ className, renderTestimonial, testimonials }) => {
    const screenSlidesPerView = {
        sm: 1,
        md: 2,
        lg: 2,
        xl: 3,
    };

    const [slidesPerView, setSlidesPerView] = useState(screenSlidesPerView[getCurrentBreakpoint()]);
    const [my_swiper, set_my_swiper] = useState({});

    useEffect(() => {
        function handleResize() {
            setSlidesPerView(screenSlidesPerView[getCurrentBreakpoint()]);
        }

        window.addEventListener('resize', handleResize);
    }, []);

    const manageSlideClick = () => {
        if (my_swiper.clickedIndex == my_swiper.activeIndex - 1) {
            my_swiper.slidePrev();
        } else if (my_swiper.clickedIndex == my_swiper.activeIndex + 1) {
            my_swiper.slideNext();
        }
    };
    return (
        <Swiper
            observer
            observeParents
            onInit={(ev) => {
                set_my_swiper(ev);
            }}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={slidesPerView}
            coverflowEffect={{
                scale: 0.8,
                rotate: 0,
                stretch: 0,
                depth: 1,
                modifier: 1,
                slideShadows: true,
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={{
                clickable: true,
            }}
            modules={[EffectCoverflow, Pagination]}
            className={className}
        >
            {testimonials.map((testimonial, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div className='review-slider' onClick={() => manageSlideClick()}>
                            {renderTestimonial(testimonial)}
                        </div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    )
}

export default AppSwiper