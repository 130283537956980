import React from 'react';
import { pre_work_pat } from '../../../images';
import AppButton from '../../AppButton';

const PodcastSection = () => {
	return (
		<div
			className={
				'flex-1 flex flex-col md:flex-row md:items-center pre-work-podcast-bg pre-work-container rounded-sm shadow-lg mb-5'
			}
		>
			<div className={'flex-1 flex pt-5 pb-2 pl-4'}>
				<img src={pre_work_pat} alt='pat-podcast' className={'w-11/12'} />
			</div>

			<div className={'flex-1 px-5 pb-5 md:-ml-15 md:px-0 text-white'}>
				<div className={'font-semibold text-lg'}>WANT TO LEARN MORE?</div>
				<div className={'pb-5 sm:text-3xl md:text-4xl'}>Check out the Working Genius Podcast</div>
				<AppButton
					text={'Listen Now'}
					onClick={() => {
						window.open('https://open.spotify.com/show/3raC053GF5mtkq6Y1klpRU');
					}}
				/>
			</div>
		</div>
	);
};

export default PodcastSection;
