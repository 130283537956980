import React from 'react';

import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import APITermsofServiceContent from '../../components/privacy/APITermsofServiceContent';

const APITermsOfService = () => {
	return (
		<>
			<WorkshopLayout
				title={'API Terms & Conditions | The 6 Types Of Working Genius'}
				description={'Read our API Terms & Conditions for our website.'}
			>
				<APITermsofServiceContent />
			</WorkshopLayout>
		</>
	);
};

export default APITermsOfService;
