import React from 'react';
import { addToCart } from '../../services/CustomerService';

const BookPreorder = () => {
	return (
		<div className='book-preorder-section' id='book-preorder-id'>
			<div className='container'>
				<div className='book-preorder'>
					<h2 className='book-preorder-title'>Order the Book Today:</h2>
					<div className='book-links'>
						<a className='btn btn-white cursor-pointer'
							onClick={() => addToCart('add_wg_book', true)}>
							Add to cart
						</a>
						<a
							href='https://www.amazon.com/Types-Working-Genius-Understand-Frustrations/dp/1637743297'
							className='btn btn-white'
							target='_blank'
						>
							Amazon
						</a>
						<a
							href='https://www.booksamillion.com/p/Types-Working-Genius/Patrick-M-Lencioni/9781637743294?id=7130420786913'
							className='btn btn-white'
							target='_blank'
						>
							Bam
						</a>
						<a
							href='https://www.barnesandnoble.com/w/the-6-types-of-working-genius-patrick-m-lencioni/1141358162'
							className='btn btn-white'
							target='_blank'
						>
							Barnes & Noble
						</a>
					</div>
				</div>
			</div>
		</div>);
};

export default BookPreorder;
