import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow, Autoplay } from 'swiper';
const AssessmentAboutCarousel = () => {
	const [my_swiper, set_my_swiper] = React.useState({});

	const manageSlideClick = () => {
		if (my_swiper.clickedIndex == my_swiper.activeIndex - 1) {
			my_swiper.slidePrev();
		} else if (my_swiper.clickedIndex == my_swiper.activeIndex + 1) {
			my_swiper.slideNext();
		}
	};

	return (
		<div className='about-carousel'>
			<p className='font-semibold text-white py-4'>WORKING GENIUS IS:</p>
			<Swiper
				observer
				observeParents
				onInit={(ev) => {
					set_my_swiper(ev);
				}}
				effect={'coverflow'}
				grabCursor={true}
				centeredSlides={true}
				coverflowEffect={{
					scale: 0.8,
					rotate: 0,
					stretch: 0,
					depth: 1,
					modifier: 1,
					slideShadows: false,
				}}
				loop={true}
				pagination={{
					clickable: true,
				}}
				modules={[EffectCoverflow, Pagination, Autoplay]}
				slidesPerView={1}
				className=' text-white h-20'
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				speed={1000}
			>
				<SwiperSlide>
					<div className='flex justify-center items-center' onClick={() => manageSlideClick()}>
						<p className='font-semibold md:text-3xl text-base'>Simple</p>&nbsp;
						<p className='md:text-3xl text-base'>- Easy to understand and use.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='flex justify-center items-center' onClick={() => manageSlideClick()}>
						<p className='font-semibold md:text-3xl text-sm'>Practical</p>&nbsp;
						<p className='md:text-3xl text-sm'>- Designed for individuals and teams at work.</p>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className='flex justify-center items-center' onClick={() => manageSlideClick()}>
						<p className='font-semibold md:text-3xl text-base'>Fast</p>&nbsp;
						<p className='md:text-3xl text-base'>- Take it in 10 minutes and apply it today.</p>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	);
};

export default AssessmentAboutCarousel;
