import React from 'react';
import Header from './Header';
import NextUp from './NextUp';
import DownloadResource from './DownloadResource';
import { tag_missing_genius, tag_create_teammap_thumbnail, tag_teammap_thumbnail } from '../../images';
import AppButton from '../AppButton';
import AppVideoModal from '../AppVideoModal';
import UtilityService from '../../services/UtilityService';

const Preparing = ({ setActiveTab }) => {
	const [vimeoUrl, setVimeoUrl] = React.useState(null);

	const headerContent = {
		title: 'Reading Your Team Map',
		subtitle: 'Create a Team Map and interpret the results',
		description: `The Team Map is a visual representation of the individual Working Geniuses and frustrations on your team. It also uncovers areas of strength and times the team gets stuck. You’ll use the Team Map in your team discussion and to improve meetings and staffing.`,
	};
	return (
		<div>
			<Header
				title={headerContent.title}
				subtitle={headerContent.subtitle}
				desription={headerContent.description}
				containerClass={'wg-toolkit-preparing-container'}
			/>
			<div className='toolkit-content'>
				<p className='text-lg'>
					<b>STEP ONE: Create a Team Map</b>
				</p>
				<div className='flex flex-col'>
					<p style={{ marginTop: '0' }} className='lg:w-4/5'>
						Generate a Team Map from within your Working Genius Portal.
						<br />
						<br />
						This short video will give you instructions for how you can create a Team Map.
					</p>
					<div className='flex flex-row items-center lg:items-start'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() => setVimeoUrl('https://player.vimeo.com/video/788317021?autoplay=1')}
							id='wg-about-play-main-video'
						>
							<img src={tag_create_teammap_thumbnail} alt='Play Video Patrick' width='570' />
						</a>
					</div>
				</div>
			</div>

			<div className='toolkit-content'>
				<p className='text-lg'>
					<b>STEP TWO: Prepare for Your Team Discussion</b>
				</p>
				<div className='flex flex-col'>
					<p style={{ marginTop: '0' }}>
						Watch this video before your team meeting to better understand how to read your Team Map.
					</p>
					<div className='flex flex-row items-center justify-center mb-12'>
						<a
							className='book-video-link cursor-pointer'
							onClick={() =>
								setVimeoUrl('https://player.vimeo.com/video/803510650?h=40e68a0b3e&autoplay=1&amp;')
							}
							id='wg-about-play-main-video'
						>
							<img src={tag_teammap_thumbnail} alt='Play Video Patrick' width='570' />
						</a>
					</div>
					<div className='flex flex-col lg:flex-row items-center lg:items-start mb-8'>
						<div className='flex flex-col'>
							<span className='text-lg font-semibold'>
								Resources for reading your Team Map:
							</span>
							<span className='py-3'>
								1. Learn each team member's <span className='text-green-500 font-semibold'>geniuses</span> and {' '}
								<span className='text-red-600 font-semibold'>frustrations</span>.
							</span>
							<DownloadResource title='A description of each genius pairing' file='https://files.tablegroup.com/wp-content/uploads/2023/01/11025816/A-Description-of-each-Genius-Pairing.pdf' />
							<span className='py-3'>
								2. Identify any areas of <b>overused</b> or <b>underrepresented</b> geniuses.
							</span>
							<DownloadResource title='When teams are missing a genius' file='https://files.tablegroup.com/wp-content/uploads/2023/03/07053836/Working-Genius-When-Teams-are-Missing-a-Genius-Team-Application-1.pdf' />
						</div>
						<div className='mx-4'>
							<img src={tag_missing_genius} alt='team-map' width='430' />
						</div>
					</div>
					<div className='flex flex-col'>
						<span className='pt-3'>
							3. Note the breakdown of team's genius and frustration layout in relation
							to the Three Stages of Work and Responsive/Disruptive dynamics.
						</span>
						<div className='flex flex-col lg:flex-row items-center'>
							<div className='flex-1 lg:mr-4 my-4 w-full'>
								<DownloadResource title='Three stages of work' file='https://files.tablegroup.com/wp-content/uploads/2023/03/01073613/Working-Genius-Three-Stages-of-Work.pdf' />
							</div>
							<div className='flex-1 my-4 w-full'>
								<DownloadResource title='RESPONSIVE/DISRUPTIVE' file='https://files.tablegroup.com/wp-content/uploads/2023/03/01073614/Working-Genius-Responsive-Disruptive.pdf' />
							</div>
						</div>
					</div>
					<p><b>*You will use the team map in your upcoming team discussion.</b></p>
				</div>
			</div>

			<NextUp nextTab={'tab4'}>
				<AppButton
					className={'btn btn-primary my-4 mx-auto relative'}
					text={'Team discussion'}
					onClick={() => {
						UtilityService.scrollToTop();
						setActiveTab('tab4');
					}}
					id={'wg-toolkit-preparing-btn'}
				/>
			</NextUp>
			{!!vimeoUrl && <AppVideoModal url={vimeoUrl} closeModal={() => setVimeoUrl(null)} />}
		</div>
	);
};

export default Preparing;
