import React from 'react';
import { Link } from 'gatsby';
import Select from 'react-select';

import AppRIIcon from '../AppRIIcon';
import { getTablegroupAuthCookie } from '../../services/UtilityService';
import { ttg_icon } from '../../images';
import config from '../../constants/config';
import { useUser } from '../../hooks/UserProvider';

const DropdownType = {
	Primary: 'primary',
	Secondary: 'secondary',
};

const selectStyles = (type) => {
	return {
		control: () => ({
			display: 'none',
		}),
		menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', minWidth: 260 }),
		option: () => {
			return {
				borderBottom: `1px solid ${type === DropdownType.Primary ? '#1D2D54' : '#F3F4F6'}`,
			};
		},
		menuList: (provider) => {
			return {
				...provider,
				maxHeight: '450px',
				paddingTop: 0,
				paddingBottom: 0,
			};
		},
	};
};

const Blanket = (props) => (
	<div
		style={{
			bottom: 0,
			left: 0,
			top: 0,
			right: 0,
			position: 'fixed',
			zIndex: 1,
		}}
		{...props}
	/>
);

const getSelectItemClassnames = (type) => {
	if (type === DropdownType.Primary) {
		return 'flex flex-row items-center cursor-pointer text-white hover:text-secondary px-4 py-3';
	}

	return 'flex flex-row items-center cursor-pointer text-gray-800 hover:text-secondary px-4 py-3';
};

const AccountDropdown = ({ items, type = DropdownType.Primary }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const { logout } = useUser();
	return (
		<div className='flex items-center'>
			<AppRIIcon
				id={'wg-profile-button-id'}
				icon='user-line'
				className='cursor-pointer py-3 px-2 text-2xl leading-6 text-white hover:text-secondary'
				onClick={() => setIsOpen((prev) => !prev)}
			/>
			{isOpen && (
				<div className='relative'>
					<div
						style={{
							backgroundColor: type === DropdownType.Primary ? '#263860' : 'white',
							borderRadius: type === DropdownType.Primary ? '0' : '2px',
							marginTop: 8,
							zIndex: 60,
							right: '-6px',
							top: '16px',
						}}
						className='absolute shadow-lg'
					>
						<Select
							backspaceRemovesValue={false}
							components={{ DropdownIndicator: null, IndicatorSeparator: null }}
							controlShouldRenderValue={false}
							hideSelectedOptions={false}
							isClearable={false}
							menuIsOpen
							options={items}
							styles={selectStyles(type)}
							tabSelectsValue={false}
							isSearchable={false}
							getOptionLabel={(option) => {
								if (option.logout) {
									return (
										<span className={getSelectItemClassnames(type)} onClick={logout}>
											<AppRIIcon icon={option.icon} className='text-xl leading-5 mr-3' />
											<span className='text-l leading-5 font-medium'>{option.label}</span>
										</span>
									);
								} else if (option.tablegroup_url) {
									return (
										<span
											className={getSelectItemClassnames(type)}
											onClick={() => {
												let token = getTablegroupAuthCookie();
												window.open(
													`${config.ttg_url}/${option.tablegroup_url}/?token=${token}`
												);
											}}
										>
											<img src={ttg_icon} className='mr-3' alt='The Table Group' width={20} />
											<span className='text-l leading-5 font-medium'>{option.label}</span>
										</span>
									);
								} else if (option.onClick) {
									return (
										<span
											className={getSelectItemClassnames(type)}
											onClick={option.onClick}
										>
											<AppRIIcon icon={option.icon} className='text-xl leading-5 mr-3' />
											<span className='text-l leading-5 font-medium'>{option.label}</span>
										</span>
									);
								}
								return (
									<Link className={getSelectItemClassnames(type)} to={option.url} key={option.label}>
										<AppRIIcon icon={option.icon} className='text-xl leading-5 mr-3' />
										<span className='text-l leading-5 font-medium'>{option.label}</span>
									</Link>
								);
							}}
						/>
					</div>
				</div>
			)}
			{isOpen && <Blanket onClick={() => setIsOpen(false)} />}
		</div>
	);
};

export default AccountDropdown;
