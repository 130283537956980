import React from 'react';
import { navigate } from 'gatsby';

import ResourcesService from '../../services/ResourcesService';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import { Hero, LicensePreWork, CTA, Questions, EmailModal } from '../../components/dca/index';
import { useUser } from '../../hooks/UserProvider';

const DCAPage = () => {
	const { user } = useUser();
	const [openModal, setOpenModal] = React.useState(false);
	const [ctaButtonLoading, setCTAButtonLoading] = React.useState(false);
	const [heroButtonLoading, setHeroButtonLoading] = React.useState(false);

	const handleOpenModal = (type) => {
		if (!(user && user?.id)) {
			return setOpenModal(true);
		}

		type === 'hero' ? setHeroButtonLoading(true) : setCTAButtonLoading(true);
		ResourcesService.getDCAResources()
			.then(() => navigate('/client/dca-resources?type=dca'))
			.finally(() => (type === 'hero' ? setHeroButtonLoading(false) : setCTAButtonLoading(false)));
	};

	return (
		<WorkshopLayout title={'The 6 Types of Working Genius: DCA Certification'}>
			<Hero handleOpenModal={handleOpenModal} loading={heroButtonLoading} />
			<LicensePreWork />
			<CTA handleOpenModal={handleOpenModal} loading={ctaButtonLoading} />
			<Questions />
			<EmailModal isOpen={openModal} setOpenModal={setOpenModal} />
		</WorkshopLayout>
	);
};

export default DCAPage;
