import React from 'react';

const AppProgressBar = ({ progress }) => {
	const roundedProgress = `${Math.round(progress)}%`;

	return (
		<div className={'relative w-full bg-white border-2 border-gray-300 py-4'}>
			<div className={'absolute top-0 left-0 bg-secondary text-center h-full'} style={{ width: roundedProgress }}>
				&nbsp;
			</div>
			<div
				className={
					'absolute flex top-0 left-0 right-0 bottom-0 w-full justify-center content-center items-center'
				}
			>
				<span className={`bg-transparent font-medium text-tiny text-primary`}>{roundedProgress}</span>
			</div>
		</div>
	);
};

export default AppProgressBar;
