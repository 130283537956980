import React from 'react';

const AppScrollableNavigation = ({ navigation, children, containerClassName, wrapperClassName }) => {
	return (
		<div className={`${containerClassName}`}>
			<div className={`relative flex flex-col md:flex-row gap-8 ${wrapperClassName}`}>
				<div className={'md:sticky top-0 h-fit'}>{navigation}</div>
				<div>{children}</div>
			</div>
		</div>
	);
};

export default AppScrollableNavigation;
