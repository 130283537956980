import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import AppRIIcon from '../components/AppRIIcon';
import UtilityService from '../services/UtilityService';

function Dropzone({ open, onDone, onRemove }) {
	const [fileUploaded, setFileUploaded] = useState(false);
	const maxFileSize = 2097152;

	const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'image/svg+xml': [],
		},
		multiple: false,
		maxFiles: 1,
		onDrop: (acceptedFiles) => {
			if (acceptedFiles.length !== 0) {
				UtilityService.resizeImage(acceptedFiles[0]).then((r) => {
					setFileUploaded(true);
					if (onDone) onDone(r);
				});
			}
		},
		maxSize: maxFileSize,
	});

	const acceptedFileItems = acceptedFiles.map((file) => (
		<div key={file.path} className='relative flex items-center justify-between p-4 border-gray-400 border-1'>
			<AppRIIcon icon='image-line' className='text-gray-600 font-semibold text-2xl mx-2' />
			{file.path.length > 20 ? `${file.path.substring(0, 20)}...` : file.path} -{' '}
			{UtilityService.niceBytes(file.size)}
			<AppRIIcon
				icon={'close-fill'}
				className={'text-grey-600 text-2xl cursor-pointer mx-2'}
				onClick={() => {
					setFileUploaded(false);
					if (onRemove) onRemove();
				}}
			/>
		</div>
	));

	const fileRejectionItems = fileRejections.map(({ file, errors }) => (
		<li key={file.path} className='text-error'>
			{file.path.length > 20 ? `${file.path.substring(0, 20)}...` : file.path} -{' '}
			{UtilityService.niceBytes(file.size)}
			<ul>
				{errors.map((e) => (
					<li key={e.code}>
						{e.code === 'file-too-large'
							? `File is larger than ${UtilityService.niceBytes(maxFileSize)}`
							: e.message}
					</li>
				))}
			</ul>
		</li>
	));

	return (
		<div>
			{fileUploaded ? (
				acceptedFileItems
			) : (
				<div {...getRootProps({ className: 'dropzone' })}>
					<input className='input-zone' {...getInputProps()} />
					<div className='text-center'>
						<AppRIIcon icon='upload-cloud-2-line' className='block text-5xl text-gray-400 font-medium' />
						{isDragActive ? (
							<div className='dropzone-content'>Release to drop the files here</div>
						) : (
							<div className='dropzone-content'>
								<span onClick={open} className='text-secondary font-medium text-base cursor-pointer'>
									Upload Logo
								</span>{' '}
								or drag and drop
								<p className='text-center font-light text-12'>
									PNG, JPG, SVG up to {UtilityService.niceBytes(maxFileSize)}
								</p>
							</div>
						)}
					</div>
				</div>
			)}
			<aside>
				<ul>{fileRejectionItems}</ul>
			</aside>
		</div>
	);
}

function AppFileUploader({ onDone, onRemove }) {
	return (
		<div className='app-fileuploader'>
			<Dropzone onDone={onDone} onRemove={onRemove} />
		</div>
	);
}
export default AppFileUploader;
