import React from 'react';
import Layout from '../../../components/Layout';
import { navigate } from 'gatsby';
import { getQueryParams } from '../../../services/UtilityService';
import { useLocation } from '@reach/router';
import InitTeamMap from '../../../components/teammap/InitTeamMap';
import CreateTeamMap from '../../../components/teammap/CreateTeamMap';

const TeamMap = () => {
	const location = useLocation();
	const queryParams = getQueryParams(location.search);

	if (!queryParams.id) {
		navigate('/client/', { replace: true });
		return null;
	}

	const renderContent = () => {
		if (queryParams.builder) {
			return <CreateTeamMap id={queryParams.id} />;
		}
		return <InitTeamMap id={queryParams.id} />;
	};

	return <Layout>{renderContent()}</Layout>;
};

export default TeamMap;
