import React from 'react';
import UtilityService from '../../services/UtilityService';

const BookJoinLaunchTeam = () => {
	return (
		<div className='book-join-launch-team'>
			<div className='container'>
				<div className='book-join-launch-team-content'>
					<p className='text-2xl md:text-4xl font-medium'>
						Understand your gifts,
						<br />
						your frustrations &amp; your team.
						<br />
						Join the movement today!
					</p>
					<a href='#book-preorder-id' className='btn btn-primary mt-4' id={'wg-pre-order-book-join-btn'}>
						{UtilityService.isEventLiveDatePassed() ? `Order the book` : `Pre-order the book`}
					</a>
				</div>
			</div>
		</div>
	);
};

export default BookJoinLaunchTeam;
