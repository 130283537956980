import ApiService from './ApiService';
import CustomerService from './CustomerService';
import { setAuthCookie } from './UtilityService';

const verifyLicenseClaimInvite = (token) => {
	return ApiService.get(`v1/license/verify_token?token=${token}`).then(({ data }) => {
		return data;
	});
};

const registerClaimLicense = (payload) => {
	return ApiService.post(`v1/license/register`, payload).then((response) => {
		const { token, customer } = response.data;

		setAuthCookie(token);
		return CustomerService.prepareCustomer(customer);
	});
};

const list = (payload) => {
	return ApiService.get(
		`v1/licenses?${Object.keys(payload)
			.map((key) => `${key}=${encodeURIComponent(payload[key])}`)
			.join('&')}`
	).then(({ data }) => {
		return data;
	});
};

const usage = () => {
	return ApiService.get(`v1/license/usage`).then(({ data }) => {
		return data;
	});
};

const transfer = (payload) => {
	return ApiService.post(`v1/license/transfer`, payload).then(({ data }) => {
		return data;
	});
};

const cancelTransfer = (payload) => {
	return ApiService.delete(`v1/license/transfer`, { data: payload }).then(({ data }) => {
		return data;
	});
};

export default {
	verifyLicenseClaimInvite,
	registerClaimLicense,
	list,
	usage,
	transfer,
	cancelTransfer,
};
