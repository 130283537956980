import React from 'react';
import { navigate } from 'gatsby';
import AppTable from '../AppTable';
import AppButton from '../AppButton';
import AppTooltip from '../AppTooltip';
import { formatDate } from '../../services/UtilityService';
import TeammapService from '../../services/TeammapService';
import ConfirmMapCreationModal from './ConfirmMapCreationModal';
import AppErrorMessage from '../AppErrorMessage';
import AppIcon from '../AppIcon';

const InitTeamMap = ({ id }) => {
	const [people, setPeople] = React.useState({});
	const [download, setDownload] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [shouldConfirmCreation, setShouldConfirmCreation] = React.useState({
		isOpen: false,
		message: '',
	});
	const [errorMessage, setErrorMessage] = React.useState('');

	const getPeople = () => {
		setLoading(true);
		TeammapService.list({
			assessment_id: id,
			sort_key: 'fullname',
			sort_dir: 'desc',
			limit: 5000,
		})
			.then(setPeople)
			.finally(() => setLoading(false));
	};

	React.useEffect(() => {
		getPeople();
	}, []);

	const createTeamMap = (acknowledged = false, asExcel = false) => {
		setDownload(true);
		TeammapService.create({
			assessment_id: id,
			acknowledged,
			name: people.group,
			xls: asExcel,
		})
			.then(() => {
				setShouldConfirmCreation({
					isOpen: false,
					message: '',
				});
				setDownload(false);
			})
			.catch((error) => {
				if (error.code === 'TEAM_MAP_WARNING') {
					setShouldConfirmCreation({
						isOpen: true,
						message: error.message,
						name: error.name,
					});
				} else {
					setErrorMessage(error.message);
				}
				setDownload(false);
			});
	};

	let columns = [
		{
			header: 'Name',
			key: 'fullname',
			sortable: false,
			className: 'md:justify-center md:flex-1',
			render: (item) => {
				return <p id={'wg-teammap-name-column'} className='text-primary'>{item.fullname}</p>;
			},
		},
		{
			header: 'Date taken',
			key: 'created',
			sortable: false,
			className: 'md:justify-center md:flex-1',
			render: (item) => {
				return <p className='text-primary'>{formatDate(item.completed)}</p>;
			},
		},
	];

	if (loading) {
		return (
			<div
				id={'wg-team-map-loading'}
				style={{ minHeight: '100px' }}
				className={'flex flex-1 justify-center content-center items-center'}
			>
				<div className='spinner'>
					<div className='double-bounce1' />
					<div className='double-bounce2' />
				</div>
			</div>
		);
	}

	return (
		<div className='container mx-auto pt-8' style={{ maxWidth: '1140px' }}>
			<a
				className='text-secondary text-xl font-semibold underline my-9 hover:text-primary cursor-pointer'
				onClick={() => navigate(-1)}
				id={'wg-teammap-back-link'}
			>
				{'< back'}
			</a>
			{people.data && people.data.length > 0 && <h1 className='team-map-title mt-6'>{people.group}</h1>}
			{!!people.group && people.data.length > 0 && (
				<p className='team-map-p mt-4 text-left'>
					The following team members will be included in the team map.
				</p>
			)}
			{people && !people.group && people.data?.length !== 0 && people.data?.length < 2 && (
				<p className='text-14 font-thin text-left leading-7 tracking-wide'>
					You can only generate a team map with two or more team members. To add additional team members to
					this map, select the "Add Team Members" button below.
				</p>
			)}
			{!loading && people.data.length === 0 && (
				<div className={'flex flex-col justify-center items-center'}>
					<p className='team-map-p my-6 text-center'>
						Nobody has completed an assessment under this group yet.
					</p>
					<AppButton
						onClick={() => navigate(`/client/teammap/?builder=true&id=${id}`)}
						text={'Create a custom team map'}
						id={'wg-create-custom-teammap-btn'}
					/>
				</div>
			)}
			{(loading || people.data.length > 0) && (
				<div className='team-map-container'>
					<div className='team-map-container-layout'>
						<AppTable
							columns={columns}
							data={people.data}
							loading={loading}
							wrapperClassname={people.data?.length > 12 ? 'scroll-list' : ''}
						/>

						<div className='flex flex-wrap flex-col md:flex-row justify-center items-center'>
							<AppButton
								onClick={() => createTeamMap()}
								text={'Create team map'}
								loading={download}
								disabled={!people || !people.data || people.data.length < 2}
								id={'wg-create-teammap-btn'}
							/>
							<a
								className='text-secondary text-lg font-semibold underline hover:text-primary ml-6 cursor-pointer'
								onClick={() => navigate(`/client/teammap/?builder=true&id=${id}`, { replace: true })}
								id={'wg-add-remove-team-member-btn'}
							>
								Add or Remove Team Members
							</a>
							<AppTooltip
								placement={'right'}
								content={() => {
									return (
										<p className={'text-white w-56'}>
											Customize a team map with anyone who has used a license and is complete.
										</p>
									);
								}}
							>
								<AppIcon className={'text-primary'} icon={'info-circled'} />
							</AppTooltip>
						</div>
						{!!download && (
							<p className='team-map-p my-6'>
								Your download will begin shortly.
								<br />
								If you do not see a downloaded file, please{' '}
								<a
									className='text-secondary underline font-semibold hover:text-primary cursor-pointer'
									onClick={() => createTeamMap()}
									id={'wg-teammap-download-link'}
								>
									Click Here
								</a>
								.
							</p>
						)}
						<div className={'m-4'}>
							{!!errorMessage && (
								<AppErrorMessage message={'An error occurred while creating your team map.'} />
							)}
						</div>
					</div>
				</div>
			)}
			<ConfirmMapCreationModal
				isOpen={shouldConfirmCreation.isOpen}
				message={shouldConfirmCreation.message}
				closeModal={() =>
					setShouldConfirmCreation((s) => ({
						...s,
						name: null,
						isOpen: false,
					}))
				}
				onConfirm={(asExcel) => {
					setShouldConfirmCreation((s) => ({
						...s,
						isOpen: false,
					}));
					createTeamMap(true, asExcel);
				}}
			/>
		</div>
	);
};

export default InitTeamMap;
