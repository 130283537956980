import React from 'react';
import PageHeader from './PageHeader';
import ReportService from '../../../../services/ReportService';
import ReportWidgetSvg from '../../../../images/assessments/report/img/widget_gears.inline.svg';

const PairingDescription = ({ assessment }) => {
	const pairing = React.useMemo(() => {
		const key = ReportService.getUniquePairing(assessment.customResults.genius1, assessment.customResults.genius2);
		return {
			key: key,
			reverse: key.split('').reverse().join(''),
			info: ReportService.UniqueParings[key],
		};
	}, []);

	return (
		<div className='UniquePairing'>
			<PageHeader title={'Pairing Description'} />

			<div className='container en'>
				<div className={'bordered-title'}>{pairing.info.title}</div>
				<div className='flex flex-row items-center gap-4 pt-4 pb-2'>
					<div className='flex flex-1 flex-col'>
						<p className={'text-2xl font-bold'}>
							{pairing.key} | {pairing.reverse}
						</p>

						<span className={'flex flex-col gap-1 my-4 text-xl font-bold'}>
							<p>The Genius Of {assessment.customResults.genius1}</p>
							<p>The Genius Of {assessment.customResults.genius2}</p>
						</span>

						<span>{pairing.info.studentBrief}</span>
					</div>

					<div className={`flex-1 genius ${assessment.customResults.genius1} ${assessment.customResults.genius2}`}>
						<ReportWidgetSvg />
					</div>
				</div>

				<div className={'flex flex-col mb-2 pr-4'}>
					{pairing.info.studentBriefPoints.map((point) => (
						<p className={'list student brief_points'}>{point}</p>
					))}
				</div>

				<div className={'crave_container student'}>
					<span className={'crave_items_container student'}>
						<div className={'crave_item student'}>
							<span className={'title pl-8'}>POTENTIAL CHALLENGES:</span>
							<p className={'list student'}>
								<span>{pairing.info.studentPotentialChallenges}</span>
							</p>
						</div>

						<div className={'crave_item student'}>
							<span className={'title pl-8'}>THEY CRAVE:</span>
							{pairing.info.crave.map((crave) => (
								<p className={'list student'}>
									<span className={'font-bold'}>{crave.title}</span>
									{crave.description}
								</p>
							))}
						</div>

						<div className={'crave_item student'}>
							<span className={'title pl-8'}>THEY ARE CRUSHED BY:</span>
							{pairing.info.crush.map((crush) => (
								<p className={'list student'}>
									<span className={'font-bold'}>{crush.title}</span>
									{crush.description}
								</p>
							))}
						</div>
					</span>
				</div>
			</div>
		</div>
	);
};

export default PairingDescription;
