import React from 'react';
import { about_the_assessment_schedule } from '../../../images';
import AppButton from '../../AppButton';
import CustomerService from '../../../services/CustomerService';

const AssessmentAboutBuyNow = () => (
	<div className={'bg-primary'}>
		<div className={'flex flex-col md:flex-row items-center container'}>
			<div className={'flex-1 flex flex-col items-center text-white p-10'}>
				<h2 className={'text-2xl md:text-4xl text-center pb-4'}>Buy the Assessment</h2>
				<h3 className={'font-semibold pb-3'}>$25 Per Person</h3>
				<span className={'font-thin text-center pb-6'}>Buy for yourself, a friend, or your team</span>
				<AppButton
					text={'Buy Now'}
					onClick={() => CustomerService.addToCart('add_license', true)}
					className={'w-40 btn btn-primary'}
				/>
			</div>
			<div className={'flex-1 px-5 py-8'}>
				<img src={about_the_assessment_schedule} alt='schedule' />
			</div>
		</div>
	</div>
);

export default AssessmentAboutBuyNow;
