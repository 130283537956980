import React from 'react';

const PolicyHero = ({ heroData }) => (
	<div className={'flex privacy-hero-bg'}>
		<div className={'flex flex-col max-w-6xl'}>
			<h1 className={'text-3xl md:text-5xl font-medium'}>{heroData.header}</h1>
			<span className={'text-md md:text-lg font-thin pt-5'}>{heroData.subHeader}</span>
		</div>
	</div>
);

export default PolicyHero;
