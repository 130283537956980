import React from 'react';

import LaunchEventAbout from '../../components/launchevent/LaunchEventAbout';
import LaunchEventBook from '../../components/launchevent/LaunchEventBook';
import LaunchEventHero from '../../components/launchevent/LaunchEventHero';
import LaunchEventPat from '../../components/launchevent/LaunchEventPat';
import LaunchEventReviews from '../../components/launchevent/LaunchEventReviews';
import LaunchEventTypes from '../../components/launchevent/LaunchEventTypes';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';

const LaunchEvent = () => {
	return (
		<WorkshopLayout title={'The 6 Types of Working Genius: Launch Event'}>
			<LaunchEventHero />
			<LaunchEventPat />
			<LaunchEventReviews />
			<LaunchEventAbout />
			<LaunchEventBook />
			<LaunchEventTypes />
		</WorkshopLayout>
	);
};

export default LaunchEvent;
