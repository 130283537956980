import React from 'react';
import SelectedMemberRow from './SelectedMemberRow';

const SelectedMembers = ({ teamName, setTeamName, ...props }) => {
	const results = props.members_ids;

	let selectedMemberInfo = props.selectedMembers;
	let selectedMembers;

	if (results.length > 0) {
		selectedMembers = results.map((rosterID, idx) => {
			return (
				<SelectedMemberRow
					selected='true'
					assessmentID={selectedMemberInfo[rosterID].assessment}
					handleRemove={props.handleRemove}
					key={selectedMemberInfo[rosterID].lname + '-' + idx}
					{...selectedMemberInfo[rosterID]}
				/>
			);
		});
	} else {
		selectedMembers = <p>Please click on individuals on the left to add to your selected members list.</p>;
	}

	return (
		<div className='SelectedMembers'>
			<h2 className='tabletitle'>
				Team Map Roster <span id={'wg-team-map-counter'} className='counter'>{props.memberCounter}</span>
			</h2>

			<div className='mt-3 mx-0'>
				<div className='mx-auto builder-input RawHTML'>
					<input
						className='InputText'
						type='text'
						name='teamname'
						value={teamName}
						onChange={(e) => setTeamName(e.target.value)}
						placeholder='Insert Team Map Group Name'
					/>
				</div>
			</div>

			<div className='mx-0'>
				<table className='mx-auto BuilderTable BuilderTable--selected'>{selectedMembers}</table>
			</div>
		</div>
	);
};

export default SelectedMembers;
