import React, { useState } from 'react';
import ScrollToTop from 'react-scroll-to-top';

import AppRIIcon from '../../components/AppRIIcon';
import AppFAQ from '../../components/AppFAQ';
import WorkshopFacilitator from '../../components/workshop/WorkshopFacilitator';
import WorkshopHero from '../../components/workshop/WorkshopHero';
import WorkshopIncludes from '../../components/workshop/WorkshopIncludes';
import WorkshopLayout from '../../components/workshop/WorkshopLayout';
import WorkshopRequest from '../../components/workshop/WorkshopRequest';
import WorkshopForm from '../../components/workshop/WorkshopForm';
import { wg_gear } from '../../images';
import { WorkshopFaq } from '../../constants/FaqData';
import WorkshopExploratory from '../../components/workshop/WorkshopExploratory';

const Workshop = () => {
	const [openFormModal, setOpenFormModal] = useState(false);
	const onWorkWithUsClick = () => setOpenFormModal(!openFormModal);

	return (
		<WorkshopLayout
			description={
				'Book a workshop for your team with one of our Master Facilitators. Workshops can be Virtual or In-person to best fit your team.'
			}
		>
			<div id={'wg-workshop-page'}>
				<div className='hidden lg:block'>
					<ScrollToTop
						smooth
						width='40'
						component={<AppRIIcon icon={'arrow-up-line'} className={'text-secondary text-2xl'} />}
						style={{ borderRadius: '50%' }}
					/>
				</div>
				<WorkshopHero onWorkWithUsClick={onWorkWithUsClick} />
				<div className='text-xl md:text-25 text-primary text-center w-full md:w-3/5 xl:w-2/5 py-12 px-4 mx-auto'>
					<p className='my-4'>
						The fastest and easiest way to maximize your team's <b>productivity</b> and <b>fulfillment</b>.
					</p>
					<p className='my-4'>
						We help you <b>master</b> and <b>apply</b> the Working Genius with your team.
					</p>
				</div>
				<WorkshopFacilitator />
				<WorkshopIncludes />
				<WorkshopRequest onWorkWithUsClick={onWorkWithUsClick} />
				<div className='bg-primary py-16 px-8'>
					<div className={'flex flex-col items-center justify-center'}>
						<img src={wg_gear} width={20} alt={'wg_gear'} />
						<p
							className='text-xl text-white font-thin italic text-center leading-8 my-2'
							style={{ maxWidth: '600px' }}
						>
							"Taylor's facilitation of our Working Genius Assessment was the perfect kick-off to our
							off-site retreat, allowing us to more deeply understand the roles we each play working in
							and on our growing business."
						</p>

						<p className='text-12 text-white font-medium uppercase tracking-widest my-6'>
							{' '}
							— Jackie Wohleber, Rust Belt Recruiting{' '}
						</p>
					</div>
				</div>
				<WorkshopExploratory />
				<WorkshopForm
					isOpen={openFormModal}
					title={'Work With Us'}
					onClose={onWorkWithUsClick}
					type={'workshop'}
				/>
				<div id={'workshop-faq'} className={'w-full md:w-9/12 mx-auto'}>
					<AppFAQ items={WorkshopFaq} title={`FAQs`} numbered={false} />
				</div>
			</div>
		</WorkshopLayout>
	);
};

export default Workshop;
