import React from 'react';
import WidgetSVG from '../../images/widget_svg.inline.svg';
import WidgetEsSVG from '../../images/widget_svg_es.inline.svg'
import WidgetDeSVG from '../../images/widget_svg_de.inline.svg'
import {
	gear_w_updated,
	gear_i_updated,
	gear_d_updated,
	gear_g_updated,
	gear_e_updated,
	gear_t_updated,
	gear_p_updated,
	gear_c_updated,
	gear_f_updated,
	gear_h_updated,
	gear_m_updated,
	gear_u_updated,
	gear_b_updated,
} from '../../images';
import AppIcon from '../AppIcon';

const Event6Types = ({ leftPositioned = false, longTitle = false }) => {
	const [selectedType, setSelectedType] = React.useState('wonder');
	const [showInfo, setShowInfo] = React.useState(true);
	const [language, setLanguage] = React.useState(localStorage.getItem('wglang') || 'en');

	const handleStorageChange = React.useCallback((e) => {
		setLanguage(localStorage.getItem('wglang') || 'en');
	}, []);

	React.useEffect(() => {
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [handleStorageChange]);

	const changeActiveClass = () => {
		const items = document.querySelectorAll('.widget-element');
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			item.classList.remove('active');
		}
	};

	React.useEffect(() => {
		const wonderElement = document.querySelector('.widget-element.wonder');
		wonderElement.addEventListener('click', () => {
			changeActiveClass();
			wonderElement.classList.add('active');
			setSelectedType('wonder');
		});

		const inventionElement = document.querySelector('.widget-element.invention');
		inventionElement.addEventListener('click', () => {
			changeActiveClass();
			inventionElement.classList.add('active');
			setSelectedType('invention');
		});

		const discernmentElement = document.querySelector('.widget-element.discernment');
		discernmentElement.addEventListener('click', () => {
			changeActiveClass();
			discernmentElement.classList.add('active');
			setSelectedType('discernment');
		});

		const galvanizingElement = document.querySelector('.widget-element.galvanizing');
		galvanizingElement.addEventListener('click', () => {
			changeActiveClass();
			galvanizingElement.classList.add('active');
			setSelectedType('galvanizing');
		});

		const enablementElement = document.querySelector('.widget-element.enablement');
		enablementElement.addEventListener('click', () => {
			changeActiveClass();
			enablementElement.classList.add('active');
			setSelectedType('enablement');
		});

		const tenacityElement = document.querySelector('.widget-element.tenacity');
		tenacityElement.addEventListener('click', () => {
			changeActiveClass();
			tenacityElement.classList.add('active');
			setSelectedType('tenacity');
		});
	}, [language]);

	React.useEffect(() => {
		changeActiveClass();
		const wonderElement = document.querySelector('.widget-element.wonder');
		wonderElement.classList.add('active');
		setSelectedType('wonder');
	}, [language]);

	const gears = {
		en: {
			gear_w: gear_w_updated,
			gear_i: gear_i_updated,
			gear_d: gear_d_updated,
			gear_g: gear_g_updated,
			gear_e: gear_e_updated,
			gear_t: gear_t_updated,
		},
		es: {
			gear_w: gear_p_updated,
			gear_i: gear_c_updated,
			gear_d: gear_d_updated,
			gear_g: gear_i_updated,
			gear_e: gear_f_updated,
			gear_t: gear_t_updated,
		},
		de: {
			gear_w: gear_h_updated,
			gear_i: gear_e_updated,
			gear_d: gear_i_updated,
			gear_g: gear_m_updated,
			gear_e: gear_u_updated,
			gear_t: gear_b_updated,
		}
	}
	return (
		<div className='event-types-section'>
			<div className='container'>
				<h2 className='types-title'>
					The <span className='font-bold'>6</span> Types {longTitle ? ' of Working Genius' : ''}
				</h2>
				<hr className='my-4 w-full' />
				<div
					className={`flex flex-col-reverse ${leftPositioned ? ' lg:flex-row-reverse' : ' lg:flex-row'
						} items-center justify-center`}
				>
					<div className='event-types flex-1 p-4 lg:py-8'>
						{selectedType === 'wonder' && (
							<div className='event-type'>
								<img src={gears[language].gear_w} width='80' alt='Wonder' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Wonder</h3>
									The natural gift of pondering the possibility of greater potential and opportunity
									in a given situation.
								</div>
							</div>
						)}
						{selectedType === 'invention' && (
							<div className='event-type'>
								<img src={gears[language].gear_i} width='80' alt='Invention' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Invention</h3>
									The natural gift of creating original and novel ideas and solutions.
								</div>
							</div>
						)}
						{selectedType === 'discernment' && (
							<div className='event-type'>
								<img src={gears[language].gear_d} width='80' alt='Discernemnt' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Discernment</h3>
									The natural gift of intuitively and instinctively evaluating ideas and situations.
								</div>
							</div>
						)}
						{selectedType === 'galvanizing' && (
							<div className='event-type'>
								<img src={gears[language].gear_g} width='80' alt='Galvanizing' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Galvanizing</h3>
									The natural gift of rallying, inspiring and organizing others to take action.
								</div>
							</div>
						)}
						{selectedType === 'enablement' && (
							<div className='event-type'>
								<img src={gears[language].gear_e} width='80' alt='Enablement' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Enablement</h3>
									The natural gift of providing encouragement and assistance for an idea or project.
								</div>
							</div>
						)}
						{selectedType === 'tenacity' && (
							<div className='event-type'>
								<img src={gears[language].gear_t} width='80' alt='Tenacity' />
								<div className='type-description'>
									<h3 className='type-name'>The Genius of Tenacity</h3>
									The natural gift of pushing projects or tasks to completion to achieve results.
								</div>
							</div>
						)}
						{showInfo && (
							<div className='widget-informational-box'>
								<div className='arrow-up'></div>
								<p className='box-text hidden lg:block'>
									Select a Working Genius type to the {leftPositioned ? ' left' : ' right'} to learn
									more.
								</p>
								<p className='box-text block lg:hidden'>
									Select a Working Genius type above to learn more.
								</p>
								<div className='box-cancel'>
									<AppIcon
										icon={'cancel'}
										className={'text-gray-700 text-2xl cursor-pointer hover:text-gray-900'}
										onClick={() => setShowInfo(false)}
									/>
								</div>
							</div>
						)}
					</div>
					<div className='flex-1 w-full px-4 py-8'>
						<div className='widget-en-svg'>
							{language === 'en' && <WidgetSVG />}
						</div>
						<div className='widget-es-svg'>
							{language === 'es' && <WidgetEsSVG />}
						</div>
						<div className='widget-es-svg'>
							{language === 'de' && <WidgetDeSVG />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Event6Types;
