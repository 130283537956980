import React from 'react';
import Countdown from 'react-countdown';

import AppIcon from '../AppIcon';

const Completionist = () => <span>The Six Types of Working Genius</span>;

const CountdownPlayer = ({ eventDate = new Date(), waitingTitle = '' }) => {
	const renderer = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return <Completionist />;
		} else {
			return (
				<span>
					{waitingTitle}{' '}
					<b>
						{days} days : {hours} hours : {minutes} minutes : {seconds} seconds
					</b>
				</span>
			);
		}
	};

	return (
		<div
			className='cowntdown-player w-full md:w-4/5 mx-auto bg-white flex flex-col items-center justify-center my-8'
			style={{ height: '60vh' }}
		>
			<div className='gears-animation'>
				<div className='gears-group-1'>
					<AppIcon icon='wg-gear' className='gear spin' />
					<AppIcon icon='wg-gear' className='gear spin' />
					<AppIcon icon='wg-gear' className='gear spin' />
				</div>
				<div className='gears-group-2'>
					<AppIcon icon='wg-gear' className='gear spin' />
					<AppIcon icon='wg-gear' className='gear spin' />
					<AppIcon icon='wg-gear' className='gear spin' />
				</div>
			</div>
			<div className='flex text-lg md:text-xl text-primary justify-center mt-4'>
				<Countdown date={eventDate} renderer={renderer} />
			</div>
		</div>
	);
};

export default CountdownPlayer;
