import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { getQueryParams } from '../../services/UtilityService';
import AssessmentsService from '../../services/AssessmentsService';
import StudentTemplate from '../../components/assessments/report/StudentTemplate';
import EnglishTemplate from '../../components/assessments/report/EnglishTemplate';
import SpanishTemplate from '../../components/assessments/report/SpanishTemplate';
import EnglishTemplateV2 from '../../components/assessments/report/EnglishTemplateV2';
import GermanTemplate from '../../components/assessments/report/GermanTemplate';
import { AssessmentModeEnum } from '../../constants/AssessmentsData';

const Report = () => {
	const [assessment, setAssessment] = useState({});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const location = useLocation();

	useEffect(() => {
		AssessmentsService.getReportData(getQueryParams(location.search))
			.then(setAssessment)
			.catch(() => setError(true))
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const renderContent = () => {
		if (loading || error) {
			return null;
		}
		const { language } = getQueryParams(location.search);
		if (assessment.participant.roster_mode === AssessmentModeEnum.STUDENT)
			return <StudentTemplate assessment={assessment} />;
		if (language === 'es') {
			return <SpanishTemplate assessment={assessment} />;
		}
		if (language === 'de') {
			return <GermanTemplate assessment={assessment} />;
		}
		if (!assessment.hasTies) {
			return <EnglishTemplateV2 assessment={assessment} />;
		}
		return <EnglishTemplate assessment={assessment} />;
	};

	return (
		<>
			<Helmet>
				<meta charSet='UTF-8' />
				<meta name='viewport' content='width=device-width, initial-scale=1.0' />
				<meta httpEquiv='X-UA-Compatible' content='ie=edge' />
				<link rel='stylesheet' href='/assessments/report/styles/pdf.css' />
				<title>The 6 Types of Working Genius Assessment Report</title>
			</Helmet>
			{renderContent()}
		</>
	);
};

export default Report;
