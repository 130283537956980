import React from 'react';
import { about_the_assessment_desk, about_the_assessment_reports } from '../../../images';
import AppButton from '../../AppButton';
import CustomerService from '../../../services/CustomerService';
import AssessmentAboutCarousel from './AssessmentAboutCarousel';

const AssessmentAboutBuy = () => (
	<div className={'bg-gray-custom'}>
		<div className={'flex flex-col'}>
			<div className={'container flex flex-col md:flex-row items-center md:py-12 py-4 '}>
				<div className={'flex-1 flex flex-col items-start p-5'}>
					<h1 className={'text-2xl md:text-4xl text-primary'}>About the Assessment</h1>
					<p className={'flex flex-col text-primary text-14 pt-4'}>
						<span>
							The Six Types of Working Genius assessment is the fastest and simplest way to discover your
							natural gifts and thrive at work.
						</span>

						<span className={'pt-6 pb-8'}>
							This 10-minute assessment and custom report provides detailed insights about your areas of
							Working Genius along with an easy-to-use application section that will help you leverage
							this information in your work, on your team and in your life. Discover your genius and live
							into your gifts today!
						</span>
					</p>
					<AppButton
						text={'Buy the assessment now'}
						onClick={() => CustomerService.addToCart('add_license', true)}
					/>
				</div>
				<div className={'flex-1 px-5 md:px-10'}>
					<img src={about_the_assessment_desk} alt='desk' />
				</div>
			</div>
			<AssessmentAboutCarousel />
			<div className={'container flex flex-col md:flex-row items-center pl-8 pb-6'}>
				<div className={'flex-1'}>
					<img src={about_the_assessment_reports} alt='reports' />
				</div>
				<div className={'flex-1 px-5 md:px-12'}>
					<h2 className={'text-lg text-primary font-semibold'}>Assessment Report Includes:</h2>
					<div className={'application-check-list'}>
						<span>Personalized Report</span>
						<span>Description of your Working Geniuses, Working Competencies, Working Frustrations</span>
						<span>Application Section</span>
						<span>Team Map Analysis Example</span>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default AssessmentAboutBuy;
