import React from 'react';

const AppFlipCard = ({ cardFront, cardBack, key }) => {
    const [isFlipped, setIsFlipped] = React.useState(false);

    const handleClick = () => {
        setIsFlipped(!isFlipped);
    };


    return (
        <div className={`flip-card ${isFlipped ? 'flipped' : ''}`} onClick={handleClick}>
            <div className='flip-card-front'>
                {cardFront}
            </div>
            <div className='flip-card-back'>
                {cardBack}
            </div>
        </div>
    );
};

export default AppFlipCard;
