import ApiService from './ApiService';

const getTakersList = (emails) =>
	ApiService.get(
		`v1/assessments/request?${emails.map((email) => `emails=${encodeURIComponent(email)}`).join('&')}`
	).then((response) => response.data);

const requestAssessments = (selected, group) =>
	ApiService.post(`v1/assessments/request`, {
		name: group.name,
		assessment_id: group?.assessment_id,
		type: group.type,
		selected,
	}).then((response) => response.data);

export const verifyRequestToken = (token) =>
	ApiService.post(`/v1/assessments/request/verify_token`, {
		token: token,
	}).then((response) => response.data);

export const acceptRequestShare = (token, bulk, captcha) =>
	ApiService.post(`/v1/assessments/request/accept?captcha=${captcha}`, {
		token: token,
		bulk,
	}).then((response) => response.data);

export const declineRequestShare = (token, bulk, captcha) =>
	ApiService.post(`/v1/assessments/request/decline?captcha=${captcha}`, {
		token: token,
		bulk,
	}).then((response) => response.data);

export const getRequestHistory = (payload) =>
	ApiService.get(
		`v1/assessments/requests?${Object.keys(payload)
			.map((key) => `${key}=${encodeURIComponent(payload[key])}`)
			.join('&')}`
	).then((response) => response.data);

export const getAssessmentDetails = (id) =>
	ApiService.get(`v1/assessments/requests/${id}`).then((response) => response.data);

export const sendRequestReminder = (id, roster_id) =>
	ApiService.post(`/v1/assessments/requests/${id}/${roster_id}`).then((response) => response.data);

export const cancelRequestAssessment = (id, roster_id) =>
	ApiService.delete(`/v1/assessments/requests/${id}/${roster_id}`).then((response) => response.data);

export default {
	getTakersList,
	requestAssessments,
	verifyRequestToken,
	acceptRequestShare,
	declineRequestShare,
	getRequestHistory,
	getAssessmentDetails,
	sendRequestReminder,
	cancelRequestAssessment,
};
