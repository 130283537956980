import React, {useCallback, useMemo} from 'react';
import { CheckoutView } from '@ttg/ui-checkout-lib';

import config from '../../constants/config';
import CheckoutLayout from '../../components/checkout/CheckoutLayout';
import { useUser } from '../../hooks/UserProvider';

const CheckoutDetails = () => {
	const { user, loadUser } = useUser();
	const onEditCart = () => (window.location.href = '/shopping-cart-view');
	const onCompleteOrder = useCallback((currentInvoice) => {
		if(window.gtag) {
			window.gtag('event', 'conversion', {
				send_to: 'AW-1042091124/3Y9mCL668JEYEPSY9PAD',
				value: (currentInvoice?.total || 0) / 100,
				currency: 'USD',
				transaction_id: currentInvoice?.id || '',
			});
		}
		loadUser();
	},[loadUser]);

	const userDTO = useMemo(
		() =>
			user
				? {
						id: user.id,
						firstName: user.first_name,
						lastName: user.last_name,
						stripeCustomerId: user.stripe_customer_id,
						hasTeamApplicationGuide: user?.show_team_application_guide || false,
						allowPO: user?.allow_po || false,
				  }
				: null,
		[user]
	);

	return (
		<CheckoutLayout>
			<CheckoutView
				onCompleteOrder={onCompleteOrder}
				user={userDTO}
				stripeKey={config.stripe_p_key}
				onEditCard={onEditCart}
			/>
		</CheckoutLayout>
	);
};

export default CheckoutDetails;
