import React from 'react';

const Tabs = ({ active, tabs, onClick }) => {
	return (
		<div className={'flex flex-row'}>
			{tabs.map((tab, index) => {
				return (
					<div
						id={`wg-notification-tab-${index}`}
						className={`border-b-2 px-2 ${active === index ? 'border-primary' : 'border-gray-300'}`}
						key={index}
					>
						<p
							className={`text-normal leading-5 font-medium text-center cursor-pointer h-8 ${
								active === index ? 'text-primary' : 'text-gray-300'
							}`}
							onClick={() => onClick(index)}
						>
							{tab.title}
							{tab.unread > 0 && (
								<span
									className={`inline-block rounded-sm text-12 font-medium w-6 ml-2 text-white ${
										active === index ? 'bg-primary' : 'bg-gray-300'
									}`}
								>
									{tab.unread}
								</span>
							)}
						</p>
					</div>
				);
			})}
			<div className='flex flex-1 border-b-2 border-gray-300' />
		</div>
	);
};

export default Tabs;
