import React from 'react';
import PageHeader from './PageHeader';
import ReportUniquePairingsES from '../../../../constants/ReportUniquePairingsES';
import WidgetSVG from '../../../../images/report-widget-es.inline.svg';
import PageTitle from './PageTitle';

const Geniuses = ({ assessment, type, title }) => {
	return (
		<div className='SpecificResults'>
			<PageHeader title={'Resultados'} />

			<div className='container'>
				<PageTitle title={title} className={'mt-8'} />
				<p
					className={'block text-12 my-4'}
					dangerouslySetInnerHTML={{
						__html: ReportUniquePairingsES.Geniuses[type].description(
							ReportUniquePairingsES.getAbility(assessment.customResults[`${type}1`]),
							ReportUniquePairingsES.getAbility(assessment.customResults[`${type}2`]),
							ReportUniquePairingsES[`${type}_color`]
						),
					}}
				></p>
				<div
					className={`widget ${type} ${assessment.customResults[`${type}1`]} ${
						assessment.customResults[`${type}2`]
					}`}
					style={{ padding: '0' }}
				>
					<div className='mx-auto w-3/5'>
						<WidgetSVG />
					</div>
				</div>
				<div className='flex flex-row gap-4 mt-4'>
					<div className={'flex flex-1 flex-col'}>
						<p
							className='text-14 uppercase font-bold'
							style={{ color: ReportUniquePairingsES[`${type}_color`] }}
						>
							{ReportUniquePairingsES.getAbility(assessment.customResults[`${type}1`])}
						</p>
						<p
							className={'text-12 leading-normal font-medium'}
							dangerouslySetInnerHTML={{
								__html: ReportUniquePairingsES.Geniuses[assessment.customResults[`${type}1`]],
							}}
						/>
					</div>
					<div className={'flex flex-1 flex-col'}>
						<p
							className='text-14 uppercase font-bold'
							style={{ color: ReportUniquePairingsES[`${type}_color`] }}
						>
							{ReportUniquePairingsES.getAbility(assessment.customResults[`${type}2`])}
						</p>
						<p
							className={'text-12 leading-normal font-medium'}
							dangerouslySetInnerHTML={{
								__html: ReportUniquePairingsES.Geniuses[assessment.customResults[`${type}2`]],
							}}
						/>
					</div>
				</div>
				{!!ReportUniquePairingsES.Geniuses[type].note && (
					<div className='mt-4'>
						<p className='text-14 uppercase font-bold'>NOTA</p>
						<p
							className={'text-12 font-medium'}
							dangerouslySetInnerHTML={{ __html: ReportUniquePairingsES.Geniuses[type].note }}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Geniuses;
