import React from 'react'
import Product from './Product'

const Products = ({ title, products }) => {
    return (
        <div className='py-8'>
            <div className='container'>
                <h2 className='font-2xl xl:text-3xl text-gray-800 text-center'>{title}</h2>
                <hr className='mt-4 mb-6' />
                <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'}>
                    {products.map((product, key) => {
                        return <Product key={key} product={product} />;
                    })}
                </div>
            </div>
        </div>
    )
}

export default Products