import React from 'react';

import PreWorkLayout from '../../../components/workshop/prework/PreWorkLayout';
import PreWorkHeroSection from '../../../components/workshop/prework/PreWorkHeroSection';
import AssessmentSection from '../../../components/workshop/prework/AssessmentSection';
import ReviewSection from '../../../components/workshop/prework/ReviewSection';
import VideoSection from '../../../components/workshop/prework/VideoSection';
import PodcastSection from '../../../components/workshop/prework/PodcastSection';

const PreWork = () => {
	return (
		<PreWorkLayout>
			<PreWorkHeroSection />
			<div className={'pre-work-layout-content'}>
				<AssessmentSection />
				<ReviewSection />
				<VideoSection />
				<PodcastSection />
			</div>
		</PreWorkLayout>
	);
};

export default PreWork;
