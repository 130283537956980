import React from 'react';
import ColorGearImage from '../../ColorGearImage';

const genius_color = '#00a049';
const competency_color = '#fbc331';
const frustration_color = '#c06e60';

const tie_gears = [0, 1, 2, 3, 4, 5];

const QuantitativeResults = ({ assessment }) => {
	const renderGenii = (genii, color) => {
		return (
			<>
				{genii.map((g, key) => {
					if (g.level === 'tie') {
						return (
							<div className='result wg-genii-with-tie' key={key}>
								<div className='gears'>
									<div className={g.matches.length < 6 ? `tie-layout-${g.matches.length}` : ''}>
										<div className='genii'>
											{tie_gears.map((index) => {
												if (g.matchIndexes.indexOf(index) > -1) {
													return (
														<div className={`genius genius-${index + 1}`} key={index}>
															<ColorGearImage
																type={assessment.results.genii[index].label}
																color={color}
																height={'21px'}
																width={'21px'}
															/>
														</div>
													);
												}
												return <div className={`genius genius-${index + 1}`} key={index} />;
											})}
										</div>
									</div>
								</div>
								<div className='text'>
									<span dangerouslySetInnerHTML={{ __html: g.text_translated }} />
								</div>
							</div>
						);
					}
					return (
						<div className='result' key={key}>
							<div className='gear'>
								<ColorGearImage type={g.label} color={color} style={{ marginLeft: '45px' }} />
							</div>
							<div className='text'>
								<span dangerouslySetInnerHTML={{ __html: g.text_translated }} />
							</div>
						</div>
					);
				})}
			</>
		);
	};

	return (
		<div className='Page__content'>
			<h1>
				{assessment.participant.fname}&rsquo;
				{assessment.participant.fname[assessment.participant.fname.length - 1] !== 's' ? 's' : ''} Quantitative
				Results
			</h1>
			<div className='top'>
				<div
					className='preamble'
					dangerouslySetInnerHTML={{ __html: assessment.results.mapreamble_translated }}
				/>

				<div className='key'>
					<div className='key-item genius'>
						<div className='gear'>
							<ColorGearImage height={'30px'} width={'30px'} color={genius_color} />
						</div>
						<b>Working Genius</b>
					</div>

					<div className='key-item competency'>
						<div className='gear'>
							<ColorGearImage height={'30px'} width={'30px'} color={competency_color} />
						</div>
						<b>Working Competency</b>
					</div>

					<div className='key-item frustration'>
						<div className='gear'>
							<ColorGearImage height={'30px'} width={'30px'} color={frustration_color} />
						</div>
						<b>Working Frustration</b>
					</div>
				</div>
			</div>

			<h2>WHAT YOUR QUANTITATIVE RESULTS INDICATE</h2>

			<div className='resultset genius'>
				<h2>WORKING GENIUS:</h2>
				{!!assessment.results.section.genius.text_translated && (
					<p dangerouslySetInnerHTML={{ __html: assessment.results.section.genius.text_translated }} />
				)}

				{renderGenii([assessment.results.genii[0], assessment.results.genii[1]], genius_color)}
			</div>

			<div className='resultset competency'>
				<h2>WORKING COMPETENCY:</h2>
				{!!assessment.results.section.competency.text_translated && (
					<p dangerouslySetInnerHTML={{ __html: assessment.results.section.competency.text_translated }} />
				)}

				{renderGenii([assessment.results.genii[2], assessment.results.genii[3]], competency_color)}
			</div>

			<div className='resultset frustration'>
				<h2>WORKING FRUSTRATION:</h2>
				{!!assessment.results.section.frustration.text_translated && (
					<p dangerouslySetInnerHTML={{ __html: assessment.results.section.frustration.text_translated }} />
				)}

				{renderGenii([assessment.results.genii[4], assessment.results.genii[5]], frustration_color)}
			</div>
		</div>
	);
};

export default QuantitativeResults;
