import React, { useState } from 'react';
import { navigate } from 'gatsby';

import AppButton from '../../AppButton';
import CertificationService from '../../../services/CertificationService';
import { useUser } from '../../../hooks/UserProvider';

const PreWorkItem = ({ item, nextStep, onMediaClick, previousStep, currentStep, totalSteps, onItemComplete }) => {
	const [loading, setLoading] = useState(false);
	const { setUser } = useUser();

	const isLastStep = currentStep === totalSteps;

	const onCheck = () => {
		setLoading(true);
		if (item.completed) {
			return nextStep();
		}

		CertificationService.completePreWorkItem(item.id)
			.then(({ status_id }) => {
				onItemComplete();
				if (isLastStep) {
					setUser((u) => ({
						...u,
						wg_certification_status: { ...u.wg_certification_status, status_id },
					}));

					if (status_id === 4) {
						navigate('/client/certification');
					}

					return;
				}

				nextStep();
			})
			.finally(() => setLoading(false));
	};

	return (
		<div className={'container flex flex-1 flex-col mx-auto my-8'}>
			<div className={'flex flex-1 flex-col md:flex-row shadow-md p-8'}>
				<div className={'flex flex-1 mr-6 items-center'}>
					<div className='content'>
						<h1 className={'text-2xl text-primary font-semibold'}>{item.title}</h1>
						<p className={'text-primary'}>{item.description}</p>
					</div>
				</div>
				<div className={'flex md:w-6/12 mr-4 md:mr-0 cursor-pointer'}>
					<img
						id={'wg-prework-img'}
						className={'object-contain'}
						src={item.resource_preview_image_url}
						alt=''
						onClick={() => onMediaClick(item)}
					/>
				</div>
			</div>
			<div className='flex justify-between my-6'>
				<div>
					{currentStep !== 1 && (
						<AppButton
							className={'btn btn-secondary'}
							text={'Back'}
							onClick={previousStep}
							disabled={loading}
							id={'wg-prework-back-button'}
						/>
					)}
				</div>
				<div>
					{(!isLastStep || !item.completed) && (
						<AppButton
							id={'wg-prework-check'}
							text={item.completed ? 'Next' : 'Check'}
							onClick={onCheck}
							loading={loading}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PreWorkItem;
