import React from 'react';
import { book_3d } from '../../images';

const WebinarBook = () => {
	return (
		<div className='webinar-book-section'>
			<div className='container'>
				<div className='flex flex-col lg:flex-row items-center justify-center'>
					<div className='text-center w-full lg:w-3/5 xl:w-1/2 py-8 px-4'>
						<h2 className='text-xl font-normal'>
							"This is going to be bigger than The Five Dysfunction of a Team. Working Genius is a true
							game-changer."
							<br />
							<br />- Jon Gordon, Bestselling Author of <span className='italic'>The Energy Bus</span>
						</h2>
					</div>
					<div className='flex-col items-center justify-center'>
						<img src={book_3d} alt='' width='130' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WebinarBook;
