import React, { useCallback, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import { SidebarCartView, RouteNames, useCheckout } from '@ttg/ui-checkout-lib';

import NotificationService from '../../services/NotificationService';
import AccountDropdown from '../navbar/AccountDropdown';
import { useUser } from '../../hooks/UserProvider';

const notTranslatedRoutes = [
	'/teamapplicationguide',
	'/workshop',
	'/resources',
	'/checkout-view',
	'/wg-podcast-post',
	'/teamworkquiz',
	'/webinars',
];

const PublicTopBar = () => {
	const { user, hasPermission } = useUser();
	const {
		screensSize: { isMobileView },
	} = useCheckout();

	const location = useLocation();
	const language = localStorage.getItem('wglang') || 'en';

	const userMenuItems = [
		...(hasPermission('USER.PUT')
			? [{
				url: '/client/account/',
				label: 'My Account',
				icon: 'user-line',
			},] : []),
		{
			url: '/client/',
			label: 'Assessments',
			icon: 'file-text-line',
		},
		...(hasPermission('TTG_TOOLS')
			? [
				{
					label: 'Tablegroup Tools',
					tablegroup_url: 'select',
				},
			]
			: []),
		...(hasPermission('TTG_ORDERS')
			? [
				{
					label: 'Orders',
					tablegroup_url: 'client/account/orders',
				},
			]
			: []),
		{
			url: '/client/assessment/contact/',
			label: 'Contact Us',
			icon: 'phone-line',
		},
		{
			label: 'Logout',
			icon: 'logout-box-r-line',
			logout: true,
		},
	];

	useEffect(() => {
		notTranslatedRoutes.forEach((route) => {
			if (location.pathname.includes(route) && language !== 'en') {
				NotificationService.info('Page cannot be translated', 'This page in only available in English', {
					duration: 5000,
				});
			}
		});
	}, []);

	const getHeaderTitle = () => {
		return (
			<Link to='/students' id='wg-homepage-alertbar-book-link' className='text-white'>
				The Student Assessment is here! Click to learn more.
			</Link>
		);
	};

	const onCheckoutClick = () => (window.location.href = '/checkout-view');
	const onViewCartClick = () => (window.location.href = '/shopping-cart-view');
	const onContinueShopping = () => (window.location.href = '/store');
	const sidebarCartRef = useRef(null);

	const shouldOpenSidebar = () =>
		!(
			window.location.href.includes(RouteNames.cartDetails) ||
			window.location.href.includes(RouteNames.checkout) ||
			isMobileView
		);

	window.addItemToCart = (id, variationId) =>
		hasPermission('CHECKOUT') && sidebarCartRef.current.onAddPreviewItem(id, variationId);
	window.logoutClick = (userId) => localStorage.removeItem('usersCart-WG');

	const getSidebarCartView = useCallback(() => {
		return (
			<SidebarCartView
				onCheckoutClick={onCheckoutClick}
				onViewCartClick={onViewCartClick}
				onContinueShopping={onContinueShopping}
				onOpenClick={!shouldOpenSidebar() ? onViewCartClick : undefined}
				userId={user?.id ? user.id : undefined}
				ref={sidebarCartRef}
			/>
		);
	}, [user]);

	const getTopRightNavigation = () => {
		if (user && user.id) {
			return (
				<div className='flex'>
					<AccountDropdown items={userMenuItems} />
					{hasPermission('CHECKOUT') && getSidebarCartView()}
				</div>
			);
		}
		return (
			<>
				<Link to='/client/login/' id='wg-homepage-alertbar-login-link' className='homepage-top-bar-login-link'>
					Login
				</Link>
				{hasPermission('CHECKOUT') && getSidebarCartView()}
			</>
		);
	};

	return (
		<div id='wg-homepage-top-bar' className='homepage-top-bar'>
			<div className='flex flex-1 flex-col md:flex-row container justify-between items-center mx-auto'>
				<div className='py-3 text-center md:text-left'>{getHeaderTitle()}</div>
				<div className='hidden md:flex items-center'>
					<Link to='/store' id='wg-homepage-alertbar-store-link' className='homepage-top-bar-store-link'>
						Store
					</Link>
					{getTopRightNavigation()}
				</div>
			</div>
		</div>
	);
};

export default PublicTopBar;
