import React from 'react';
import PublicTopBar from '../../homepage/PublicTopBar';
import HomepageHeader from '../../homepage/HomepageHeader';
import AppTabTitle from '../../AppTabTitle';
import ToolkitFooter from '../../toolkit/ToolkitFooter';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import HomeNavHamburger from '../../homepage/HomeNavHamburger';
import config from '../../../constants/config';
import { normalizePathName } from '../../../services/UtilityService';

const PreWorkLayout = ({ children, title = 'The 6 Types of Working Genius: Workshop', customer }) => {
	const location = useLocation();

	return (
		<div className={`flex flex-col min-h-screen`}>
			<Helmet>
				<link rel='canonical' href={`${config.base_url}${normalizePathName(location.pathname)}`} />
			</Helmet>
			<AppTabTitle title={title} />
			<PublicTopBar customer={customer} />
			<HomepageHeader />
			<HomeNavHamburger customer={customer} />
			<div className={`flex flex-1 flex-col`}>{children}</div>
			<ToolkitFooter />
		</div>
	);
};

export default PreWorkLayout;
