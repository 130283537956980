import React from 'react';
import { ReportGearsHeader } from '../../../../images';

const PageHeader = ({ title }) => {
    return (
        <div className={'page-header'}>
            <img src={ReportGearsHeader} alt='' />
            <p className='title'>{title}</p>
        </div>
    );
};

export default PageHeader;
