import React from 'react';
import AppModal from '../AppModal';
import AppButton from '../AppButton';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: 'transparent',
        maxHeight: '85vh',
    },
};

const EmailList = ({ emails = [] }) => {
    const [visibleEmails, setVisibleEmails] = React.useState(6);

    const loadMoreEmails = () => {
        setVisibleEmails((prevVisibleEmails) => prevVisibleEmails + 6);
    };

    return (
        <div className='wg-bulk-share-emails-list'>
            <ul>
                {emails.slice(0, visibleEmails).map((email, index) => (
                    <li key={index} className='w-1/2 p-1 text-gray-700 ml-6'>
                        {email}
                    </li>
                ))}
            </ul>
            {visibleEmails < emails.length && (
                <button onClick={loadMoreEmails} className='text-medium text-secondary cursor-pointer py-2'>
                    Load More
                </button>
            )}
        </div>
    );
};

const EmailSection = ({ title, emails }) => (
    <div className='flex flex-col justify-around mb-6'>
        <p dangerouslySetInnerHTML={{ __html: title }} />
        <EmailList emails={emails} />
    </div>
);

const BulkShareDetails = ({ isOpen, onClose, intro, selectedAssessments, onShare, loading }) => {
    const {
        assessmentsNotAllowedToShare = [],
        assessmentsAllowedToShare = [],
        assessmentsAlreadyShared = [],
    } = selectedAssessments || {};

    const assessmentsNotAllowedToShareCount = assessmentsNotAllowedToShare.length;
    const assessmentsAllowedToShareCount = assessmentsAllowedToShare.length;
    const assessmentsAlreadySharedCount = assessmentsAlreadyShared.length;

    const buttonText = assessmentsNotAllowedToShareCount > 0 ? 'Send Request and share' : 'Share';

    return (
        <AppModal id='wg-bulk-share-modal-id' isOpen={isOpen} style={customStyles} onRequestClose={onClose}>
            <div className='bg-white p-6 text-sm font-normal text-gray-600' style={{ maxWidth: '690px' }}>
                <h1 className='flex text-primary text-lg leading-6 font-medium'>Share Assessments</h1>
                <p className='pb-8' dangerouslySetInnerHTML={{ __html: intro }} />

                {assessmentsAllowedToShareCount > 0 && (
                    <EmailSection
                        title='These assessments will be automatically shared:'
                        emails={assessmentsAllowedToShare}
                    />
                )}

                {assessmentsAlreadySharedCount > 0 && (
                    <EmailSection
                        title='The following assessments have already been shared:'
                        emails={assessmentsAlreadyShared}
                    />
                )}

                {assessmentsNotAllowedToShareCount > 0 && (
                    <>
                        <EmailSection
                            title='The following users have not allowed you to share their assessments. <br/> A request to share will be sent to these emails. Once accepted, the assessments will be automatically shared.'
                            emails={assessmentsNotAllowedToShare}
                        />
                        <p>Do you want to send a request to share to these emails?</p>
                    </>
                )}

                <div className='flex flex-col sm:flex-row mt-4 justify-end'>
                    <AppButton
                        onClick={onClose}
                        text='Cancel'
                        className='btn btn-secondary my-2 sm:mr-4'
                        loading={loading}
                    />
                    {assessmentsNotAllowedToShareCount + assessmentsAllowedToShareCount > 0 && (
                        <AppButton
                            onClick={onShare}
                            disabled={loading}
                            text={buttonText}
                            className='btn btn-primary my-2'
                            loading={loading}
                        />
                    )}
                </div>
            </div>
        </AppModal>
    );
};

export default BulkShareDetails;
